import { UseAlertsState } from '../../types/useAlertsState.types';
import { UseAlertContext } from '../../types/useAlertContext.types';
import { IconName } from '@rehau/shared/enums';

export const alertsStateMock: UseAlertsState = {
  dialogSettings: {
    title: 'title',
    textContent: 'textContent',
  },
  dialogWithFormSettings: {
    title: 'title',
    form: null,
  },
  smallDialogSettings: {
    title: 'title',
    text: 'text',
  },
  dialogWithIconSettings: {
    title: 'title',
    description: 'textContent',
    icon: IconName.AdminBlock,
  },
  alertSettings: [
    {
      content: 'content',
    },
  ],
};

export const alertsDispatchMock: UseAlertContext = (): void => undefined;
