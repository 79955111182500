export enum HeatingAndCoolingTypeEnum {
  Gas = 'gas',
  Oil = 'oil',
  Wood = 'wood',
  HeatingAndCoolingAirPump = 'heating_and_cooling_air_pump',
  AirPump = 'air_pump',
  HeatingAndCoolingGeothermalPump = 'heating_and_cooling_geothermal_pump',
  GeothermalPump = 'geothermal_pump',
  DistrictHeating = 'district_heating',
  Pellets = 'pellets',
  Photovoltaic = 'photovoltaic',
  SolarPanels = 'solar_panels',
  HotWaterTank = 'hot_water_tank',
  Electric = 'electric',
}
