import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { Icon } from '../icon/icon.component';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';

import { NumericValueProps } from './rating.types';

export const StarsContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

export const CustomIcon: StyledComponent<typeof Icon> = styled(Icon)`
  & + & {
    margin-left: 5px;
  }
`;

export const NumericValuesContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NumericValue: StyledComponent<'p', NumericValueProps> = styled.p`
  font-size: 15px;
  line-height: 18px;
  color: ${({ color }: NumericValueProps): Color => color ?? Color.Grey};
  margin-left: 4px;

  ${styleWhenTrue(
    prop('isFirst'),
    css`
      margin-left: 0;
      margin-right: 4px;
      font-weight: ${FontWeight.Black};
    `
  )}
`;
