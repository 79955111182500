export enum CompaniesListColumnsEnum {
  Id = 'id',
  Name = 'name',
  Status = 'status',
  Country = 'countryLabel',
  RegionalManager = 'regionalManagerFullName',
  CrmId = 'crmId',
  ActivationLinks = 'activationLinks',
  Partner = 'partner',
}
