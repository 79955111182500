import styled from 'styled-components';

import { StyledComponent } from '../../types/styledComponent.types';
import { Color } from '../../enums/color.enum';
import { BoxShadow } from '../../enums/boxShadow.enum';
import { ZIndex } from '../../enums/zIndex.enum';
import { DatePicker } from '../datePicker/datePicker.component';

import { OptionProps } from './filtersDropdown.types';

export const Container: StyledComponent<'div'> = styled.div`
  position: relative;
`;

export const Header: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
`;

export const Label: StyledComponent<'div'> = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: ${Color.Grey};
`;

export const Value: StyledComponent<'button'> = styled.button`
  margin-left: 15px;
  margin-right: 10px;
`;

export const OptionsContainer: StyledComponent<'div'> = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: ${BoxShadow.LongGrey};
  border-radius: 2px;
  background-color: ${Color.Grey};
  width: 100%;
  z-index: ${ZIndex.Minimum};
  margin-top: 10px;
  min-width: 224px;
`;

export const Options: StyledComponent<'div'> = styled.div`
  padding: 18px;
`;

export const OptionContainer: StyledComponent<'div'> = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

export const Option: StyledComponent<'button', OptionProps> = styled.button<OptionProps>`
  font-size: 14px;
  line-height: 17px;
  color: ${Color.White};
  display: flex;
  ${({ $checked }: OptionProps): string => $checked ? `
    ${RadioButton} {
      background-color: ${Color.White};
    }
  ` : ''}
`;

export const RadioButtonContainer: StyledComponent<'div'> = styled.div`
  width: 11px;
  height: 11px;
  flex-shrink: 0;
  border: 1px solid ${Color.White};
  border-radius: 50%;
  margin-right: 16px;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RadioButton: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Transparent};
  border-radius: 50%;
  width: 5px;
  height: 5px;
  flex-shrink: 0;
`;

export const OptionLabel: StyledComponent<'div'> = styled.div`
  text-align: left;
`;

export const InputsContainer: StyledComponent<'div'> = styled.div`
  margin-top: 16px;
`;

export const InputContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 100%;

  & + & {
    margin-top: 8px;
  }
`;

export const InputLabel: StyledComponent<'div'> = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${Color.White};
  margin-right: 12px;
`;

export const StyledDatePicker: StyledComponent<typeof DatePicker> = styled(DatePicker)`
  min-width: 0;
  width: 120px;
  background-color: ${Color.White};
  height: 44px;
`;

export const ValueButton: StyledComponent<'button'> = styled.button`
  display: flex;
  align-items: center;
`;
