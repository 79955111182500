import { BaseAbstractContainer } from '@rehau/shared/forms/baseAbstract.container';
import { FormsTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { componentTypes } from '@rehau/shared/forms/types';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { Config } from '@rehau/shared/models/countryConfig';
import { InfoBox } from '@rehau/shared/forms/elements';

export abstract class AbstractForm extends BaseAbstractContainer {
  public elements: componentTypes[];
  public frontendComponent: FrontendComponentEnum;
  public type: FormsTypeEnum;
  public title: string;
  public subTitle?: string;
  public nextButtonLabel?: string;
  public infoBox?: InfoBox;

  constructor(
    type: FormsTypeEnum,
    title: string,
    values: ObjectIteratorInterface,
    countryConfig?: Config,
    elements: componentTypes[] = [],
    subTitle?: string,
    nextButtonLabel?: string,
    infoBox?: InfoBox
  ) {
    super();
    this.title = title;
    this.type = type;
    this.elements = elements;
    this.subTitle = subTitle;
    this.nextButtonLabel = nextButtonLabel;
    this.infoBox = infoBox;
    this.setValues(values);
    if (countryConfig && Object.keys(countryConfig).length) {
      this.updateCountryConfig(countryConfig);
    }
  }

  public addElement(element: componentTypes, condition?: boolean, name?: string): this {
    if (condition !== false) {
      if (name) {
        element.setName(name);
      }
      this.elements.push(element);
    }

    return this;
  }
}
