export enum FormContainerTypeEnum {
  ComponentsContainer = 'ComponentsContainer',
  DecisionWithChildrenContainer = 'DecisionWithChildrenContainer',
  FieldsContainer = 'FieldsContainer',
  StaticElementsContainer = 'StaticElementsContainer',
  MixedContainer = 'MixedContainer',
  PopupContainer = 'PopupContainer',
  VariantContainer = 'VariantContainer',
  PopupWithClickNumberWithIconContainer = 'PopupWithClickNumberWithIconContainer',
  ChooseInstallerContainer = 'ChooseInstallerContainer',
  ChooseLocationContainer = 'ChooseLocationContainer',
  SelectItemsContainer = 'SelectItemsContainer',
  FieldsAndComponentsContainer = 'FieldsAndComponentsContainer',
  CalculationChoicesBoxContainer = 'CalculationChoicesBoxContainer',
  SummaryContainer = 'SummaryContainer',
  CheckboxWithParamsContainer = 'CheckboxWithParamsContainer',
  ConditionalAvailableContainer = 'ConditionalAvailableContainer',
}
