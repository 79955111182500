export enum FrontendImagesEnum {
  WaterReducerWithFilter = '/images/setup/water_reducer_with_filter.png',
  TeeInstallation = '/images/setup/tee_installation.png',
  LinearInstallation = '/images/setup/linear_installation.png',
  RingInstallation = '/images/setup/ring_installation.png',
  ExhaustFanVentilation = '/images/setup/exhaust_fan_ventilation.png',
  MechanicalVentilation = '/images/setup/mechanical_ventilation.png',
  FrameBidet = '/images/setup/frame_bidet.png',
  FrameSink = '/images/setup/frame_sink.png',
  FrameUrinal = '/images/setup/frame_urinal.png',
  FrameWc = '/images/setup/frame_wc.png',
  RaupianoLight = '/images/setup/raupiano_light.png',
  RaupianoPlus = '/images/setup/raupiano_plus.png',
  ReguardPromoImage = '/images/calculator/reguard_promo_image.png',
  ResourcePromoImage = '/images/calculator/resource_promo_image.png',
}
