import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  findPathByName,
  KeyValueString,
  SuccessOrFailScreen,
  useCountryConfig,
  UseCountryConfig,
  useRedirect,
  UseRedirectOutput,
  useRoutesInfo,
  UseState
} from '@rehau-fe/ui/src';
import { ErrorMessageEnum, WebsitePathNameEnum } from '@rehau/shared/enums';
import { RouteInfo } from '@rehau/shared/models/countryConfig';
import { checkAccountActivationStatus } from '@rehau-fe/app/api';
import { ApiErrorModel } from '@rehau/shared/models/responses';

import { ActivityStatus } from './activateAccount.enums';
import { Container } from './activateAccount.styled';

export const ActivateAccount: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const handleRedirect: UseRedirectOutput = useRedirect();
  const routesArray: RouteInfo[] = useRoutesInfo();
  const { token }: KeyValueString = useParams();
  const [status, setStatus]: UseState<ActivityStatus> = useState<ActivityStatus>(ActivityStatus.Loading);
  const [requestError, setRequestError]: UseState<string> = useState<string>('');
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();
  const supportEmail: string = isConfigAvailable ? config.variables.website['support_email'] as string : '';

  useEffect(
    (): void => {
      checkAccountActivationStatus(token)
        .then((): void => {
          setStatus(ActivityStatus.Success);
        })
        .catch((error: ApiErrorModel): void => {
          if (error.message === ErrorMessageEnum.token_expired || error.message === ErrorMessageEnum.token_not_found) {
            setRequestError('rehau.website.activate_account_view.outdated');
          } else {
            setRequestError(error.message);
          }
          setStatus(ActivityStatus.Fail);
        });
    },
    []
  );

  const goToSignInView: () => void = (): void => {
    handleRedirect(findPathByName(WebsitePathNameEnum.SignIn, routesArray), WebsitePathNameEnum.SignIn);
  };

  return (
    <Container>
      {status === ActivityStatus.Success && (
        <SuccessOrFailScreen
          title={t('rehau.website.activate_account_view.active_account')}
          description={t('rehau.website.activate_account_view.fill_offer')}
          buttonLabel={t('rehau.website.activate_account_view.sign_in')}
          handleButtonClick={goToSignInView}
        />
      )}
      {status === ActivityStatus.Fail && (
        requestError === 'rehau.website.activate_account_view.outdated' ? (
          <SuccessOrFailScreen
            title={t('rehau.website.activate_account_view.something_wrong')}
            description={t(requestError).replace(/\[supportEmail\]/g, config.variables.website['support_email'] as string)}
            success={false}
            secondaryButtonLabel={t('rehau.website.activate_account_view.send_email')}
            secondaryButtonClick={(): void => handleRedirect(`mailto: ${supportEmail}`)}
          />
        ) : (
          <SuccessOrFailScreen
            title={t('rehau.website.activate_account_view.something_wrong')}
            description={t(requestError)}
            success={false}
            buttonLabel={t('rehau.website.activate_account_view.sign_in')}
            handleButtonClick={goToSignInView}
          />
        )
      )}
    </Container>
  );
};
