import { ValidationAbstract } from '@rehau/shared/forms/validations/validation.abstract';
import { ErrorMessageEnum } from '@rehau/shared/enums';

export class NumberValidation extends ValidationAbstract {
  constructor(
    minValue?: number,
    maxValue?: number,
    minValueMessage?: ErrorMessageEnum,
    maxValueMessage?: ErrorMessageEnum,
    isRequiredMessage?: ErrorMessageEnum
  ) {
    super(
      { minValue, maxValue },
      { minValue: minValueMessage, maxValue: maxValueMessage, isRequired: isRequiredMessage }
    );
  }
}
