// tslint:disable:max-file-line-count
import { SetupConfigKeyEnum } from '@rehau/shared/enums/countryConfig';
import {
  CountryCodeEnum,
  CountryLocaleEnum,
  CurrencyPositionEnum,
  ExternalPathNameEnum,
  FileTypeEnum,
  IconName,
  ProductTableRowFormat,
  TimezoneEnum,
  WebsitePathNameEnum,
} from '../../../../../../shared/enums';
import { Config } from '../../../../../../shared/models/countryConfig';
import { Color } from '../../enums/color.enum';

export const countryConfigMock: Config = {
  code: CountryCodeEnum.de,
  name: 'Germany',
  locale: CountryLocaleEnum.de_DE,
  dateLocale: 'de',
  timezone: TimezoneEnum.Berlin,
  timezoneHourDifference: 2,
  distanceUnit: 'km',
  currency: {
    name: 'EUR',
    symbol: '€',
    position: CurrencyPositionEnum.Left,
  },
  countries: [
    { name: 'Polska', code: CountryCodeEnum.pl },
    { name: 'Germany', code: CountryCodeEnum.de },
  ],
  validators: {
    postalCode: '^(d{4,6})$',
    taxId: '^[A-Za-z]{2,4}(?=.{2,12}$)[-s0-9]*(?:[a-zA-Z][-s0-9]*){0,2}$',
    phone: '^[d]{4,13}$',
  },
  settings: {
    houseSizeS: 1,
    houseSizeSM: 80,
    houseSizeML: 200,
    houseSizeL: 350,
    normalRadius: 50,
    maximumRadius: 500,
    extraDistanceCost: 0,
  },
  map: {
    centroid: {
      lat: 51.5167,
      lng: 9.9167,
    },
    scale: 5,
    geoJson: '/static/maps/germany/country.json',
  },
  fileValidators: {
    [FileTypeEnum.Logo]: {
      mimetypes: ['image/jpg', 'image/jpeg', 'image/png'],
      formats: ['.jpg', '.jpeg', '.png'],
      fileSize: {
        min: 0,
        max: 1048576,
      },
      resolution: {
        width: {
          min: 0,
          max: 1024,
        },
        height: {
          min: 0,
          max: 1024,
        },
      },
    },
    [FileTypeEnum.CalculationPhotos]: {
      mimetypes: ['image/jpg', 'image/jpeg', 'image/png'],
      formats: ['.jpg', '.jpeg', '.png'],
      fileSize: {
        min: 0,
        max: 10485760,
      },
      resolution: {},
    },
  },
  geoLobsLayerId: null,
  storageObjectsBaseUrl: '',
  defaultValuesEnabled: false,
  variables: {
    setup: {
      code: 'setupTestCommon',
      'defaultValue.rating.amount': 1,
      [SetupConfigKeyEnum.ReSourceEnable]: false,
      [SetupConfigKeyEnum.ReGuardEnable]: false,
      [SetupConfigKeyEnum.HeatingAndCoolingSolarPanelsEnable]: true,
      [SetupConfigKeyEnum.HeatingAndCoolingPhotovoltaicEnable]: true,
      [SetupConfigKeyEnum.VentilationFanExhaustEnable]: true,
      [SetupConfigKeyEnum.VentilationMechanicalEnable]: true,
      [SetupConfigKeyEnum.WaterForDrinkingInstallationCirculationInstallationEnable]: true,
      [SetupConfigKeyEnum.WaterForDrinkingInstallationFreshWaterEnable]: true,
      [SetupConfigKeyEnum.WaterForDrinkingInstallationWaterSoftenerEnable]: true,
    },
    website: {
      code: 'setupWebsiteCommon',
    },
    calculator: {},
    products: {
      code: 'products',
    },
  },
  menuConfigs: {
    setupMenu: {
      offers: {
        order: 0,
        title: 'rehau.setup.header_menu.offers',
      },
      configurator: {
        order: 1,
        title: 'rehau.setup.header_menu.configurator',
      },
      profile: {
        order: 2,
        title: 'rehau.setup.header_menu.profile',
      },
    },
    mainMenu: {
      configurator: {
        order: 0,
        title: 'rehau.setup.header_menu.configurator',
      },
      about: {
        order: 1,
        title: 'rehau.setup.header_menu.about',
      },
      products: {
        order: 2,
        title: 'rehau.setup.header_menu.products',
        groups: {
          products: {
            header: '',
            order: 0,
            children: {
              heatInstallation: {
                order: 0,
                title: 'rehau.main_menu.heat_installation',
                icon: IconName.HeatDistribution,
              },
              waterDistribution: {
                order: 1,
                title: 'rehau.main_menu.waterDistribution',
                icon: IconName.DrinkingWaterInstallation,
              },
              sewage: {
                order: 2,
                title: 'rehau.main_menu.sewage',
                icon: IconName.Pipes,
              },
            },
          },
          smartTechnology: {
            header: 'rehau.main_menu.smart_technology',
            order: 1,
            children: {
              reGuard: {
                order: 0,
                title: 'rehau.main_menu.reguard',
                icon: IconName.Reguard,
              },
              reSource: {
                order: 1,
                title: 'rehau.main_menu.resource',
                icon: IconName.Resource,
              },
            },
          },
        },
      },
    },
    footerMenu: {
      products: {
        order: 0,
        title: 'rehau.footer_menu.products',
        groups: {
          products: {
            header: '',
            order: 0,
            children: {
              heatInstallation: { order: 0, title: 'rehau.footer_menu.heat_installation' },
              waterDistribution: { order: 1, title: 'rehau.footer_menu.water_distribution' },
              sewage: { order: 2, title: 'rehau.footer_menu.sewage' },
            },
          },
        },
      },
    },
    footerBottomBarMenu: {
      impressum: { order: 0, title: 'rehau.footer_bottom_bar_menu.impressum' },
      privacyPolicy: { order: 1, title: 'rehau.footer_bottom_bar_menu.privacy_policy' },
    },
    adminMenu: {
      adminOffers: { order: 0, title: 'rehau.admin.main_menu.offers' },
      adminManagementRegistrations: { order: 1, title: 'rehau.admin.main_menu.management' },
    },
  },
  footerDetails: {
    contactDetails: [
      'REHAU Gesellschaft m.b.H',
      'Industriestraße 17',
      'A-2353 Guntramsdorf',
      '[00800-2236-2236](tel:0080022362236)',
      '[service.at@support.rehau.com](mailto:service.at@support.rehau.com)',
    ],
    socials: {
      twitter: 'https://twitter.com/',
      fb: 'https://www.facebook.com/',
      youtube: 'https://www.youtube.com/',
      linkedin: 'https://linkedin.com/',
    },
  },
  websiteViewsConfigs: {
    contact: {
      content: [
        [
          'Industriestraße 17',
          'A-2353 Guntramsdorf',
          'Telefon: [00800-2236-2236](tel:0080022362236)',
          'E-Mail: [verw.soe@rehau.com](mailto:verw.soe@rehau.com)',
        ],
        ['Firmenbuchnummer: FN 96104 f', 'Handelsgericht: Wr. Neustadt', 'UID-Nr.: ATU63212106'],
      ],
      links: [
        {
          name: ExternalPathNameEnum.PrivacyPolicy,
          label: 'rehau.website.contact_view.privacy_policy_link_label',
        },
      ],
    },
    privacyPolicy: {
      content: [
        [
          'Cieszymy się, że korzystasz z REHAU@HOME. Bardzo poważnie traktujemy ochronę Twoich danych osobowych. Bezpieczeństwo Twoich danych osobowych, takich jak nazwisko, adres, numer telefonu lub e-mail jest dla nas ważną kwestią, którą uwzględniamy w naszych procesach biznesowych. Naszą działalność internetową prowadzimy zgodnie z przepisami o ochronie i bezpieczeństwie danych. Poniżej dowiesz się, jakie informacje zbieramy i jak nimi operujemy.',
        ],
        [
          '### Organy odpowiedzialne',
          "1. REHAU Sp. z o.o. homesupport.PL@rehau.com\n2. Nazwa firmy [Zamiennie '\Firma\'] @Mail [Zamiennie: '\E-mail do zapytań\']",
        ],
      ],
    },
    installerRegulations: {
      content: [
        [
          '### Header',
        ],
      ],
    },
    customerRegulations: {
      content: [
        [
          '### Header',
        ],
      ],
    },
  },
  productTables: {
    water: {
      name: 'water',
      columns: [
        {
          name: 'classic',
          label: 'rehau.global.classic',
          icon: IconName.TeeInstallation,
          color: Color.Green,
        },
        {
          name: 'premium',
          label: 'rehau.global.premium',
          icon: IconName.RingInstallation,
          color: Color.Black,
        },
      ],
      annotations: [
        {
          name: 'optional',
          value: 'rehau.product_tables.water.annotations.optional',
        },
      ],
      rows: [
        {
          name: 'highestMaterialQuality',
          label: 'rehau.product_tables.water.rows.highes_material_quality',
          format: ProductTableRowFormat.Boolean,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: true,
            },
            {
              name: 'premium',
              value: true,
            },
          ],
        },
        {
          name: 'safety',
          label: 'rehau.product_tables.water.rows.safety',
          format: ProductTableRowFormat.Rating,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: 5,
            },
            {
              name: 'premium',
              value: 5,
            },
          ],
        },
        {
          name: 'video',
          label: 'rehau.product_tables.water.rows.see_video',
          format: ProductTableRowFormat.Video,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: '',
            },
            {
              name: 'premium',
              value: '',
            },
          ],
        },
      ],
    },
    heating: {
      name: 'water',
      columns: [
        {
          name: 'classic',
          label: 'rehau.global.classic',
          icon: IconName.TeeInstallation,
          color: Color.Green,
        },
        {
          name: 'premium',
          label: 'rehau.global.premium',
          icon: IconName.RingInstallation,
          color: Color.Black,
        },
      ],
      annotations: [
        {
          name: 'optional',
          value: 'rehau.product_tables.water.annotations.optional',
        },
      ],
      rows: [
        {
          name: 'highestMaterialQuality',
          label: 'rehau.product_tables.water.rows.highes_material_quality',
          format: ProductTableRowFormat.Boolean,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: true,
            },
            {
              name: 'premium',
              value: true,
            },
          ],
        },
        {
          name: 'safety',
          label: 'rehau.product_tables.water.rows.safety',
          format: ProductTableRowFormat.Rating,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: 5,
            },
            {
              name: 'premium',
              value: 5,
            },
          ],
        },
        {
          name: 'video',
          label: 'rehau.product_tables.water.rows.see_video',
          format: ProductTableRowFormat.Video,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: '',
            },
            {
              name: 'premium',
              value: '',
            },
          ],
        },
      ],
    },
    sewage: {
      name: 'water',
      columns: [
        {
          name: 'classic',
          label: 'rehau.global.classic',
          icon: IconName.TeeInstallation,
          color: Color.Green,
        },
        {
          name: 'premium',
          label: 'rehau.global.premium',
          icon: IconName.RingInstallation,
          color: Color.Black,
        },
      ],
      annotations: [
        {
          name: 'optional',
          value: 'rehau.product_tables.water.annotations.optional',
        },
      ],
      rows: [
        {
          name: 'highestMaterialQuality',
          label: 'rehau.product_tables.water.rows.highes_material_quality',
          format: ProductTableRowFormat.Boolean,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: true,
            },
            {
              name: 'premium',
              value: true,
            },
          ],
        },
        {
          name: 'safety',
          label: 'rehau.product_tables.water.rows.safety',
          format: ProductTableRowFormat.Rating,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: 5,
            },
            {
              name: 'premium',
              value: 5,
            },
          ],
        },
        {
          name: 'video',
          label: 'rehau.product_tables.water.rows.see_video',
          format: ProductTableRowFormat.Video,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: '',
            },
            {
              name: 'premium',
              value: '',
            },
          ],
        },
      ],
    },
    reguard: {
      name: 'water',
      columns: [
        {
          name: 'classic',
          label: 'rehau.global.classic',
          icon: IconName.TeeInstallation,
          color: Color.Green,
        },
        {
          name: 'premium',
          label: 'rehau.global.premium',
          icon: IconName.RingInstallation,
          color: Color.Black,
        },
      ],
      annotations: [
        {
          name: 'optional',
          value: 'rehau.product_tables.water.annotations.optional',
        },
      ],
      rows: [
        {
          name: 'highestMaterialQuality',
          label: 'rehau.product_tables.water.rows.highes_material_quality',
          format: ProductTableRowFormat.Boolean,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: true,
            },
            {
              name: 'premium',
              value: true,
            },
          ],
        },
        {
          name: 'safety',
          label: 'rehau.product_tables.water.rows.safety',
          format: ProductTableRowFormat.Rating,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: 5,
            },
            {
              name: 'premium',
              value: 5,
            },
          ],
        },
        {
          name: 'video',
          label: 'rehau.product_tables.water.rows.see_video',
          format: ProductTableRowFormat.Video,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: '',
            },
            {
              name: 'premium',
              value: '',
            },
          ],
        },
      ],
    },
    resource: {
      name: 'water',
      columns: [
        {
          name: 'classic',
          label: 'rehau.global.classic',
          icon: IconName.TeeInstallation,
          color: Color.Green,
        },
        {
          name: 'premium',
          label: 'rehau.global.premium',
          icon: IconName.RingInstallation,
          color: Color.Black,
        },
      ],
      annotations: [
        {
          name: 'optional',
          value: 'rehau.product_tables.water.annotations.optional',
        },
      ],
      rows: [
        {
          name: 'highestMaterialQuality',
          label: 'rehau.product_tables.water.rows.highes_material_quality',
          format: ProductTableRowFormat.Boolean,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: true,
            },
            {
              name: 'premium',
              value: true,
            },
          ],
        },
        {
          name: 'safety',
          label: 'rehau.product_tables.water.rows.safety',
          format: ProductTableRowFormat.Rating,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: 5,
            },
            {
              name: 'premium',
              value: 5,
            },
          ],
        },
        {
          name: 'video',
          label: 'rehau.product_tables.water.rows.see_video',
          format: ProductTableRowFormat.Video,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: '',
            },
            {
              name: 'premium',
              value: '',
            },
          ],
        },
      ],
    },
    temperatureRegulator: {
      name: 'water',
      columns: [
        {
          name: 'classic',
          label: 'rehau.global.classic',
          icon: IconName.TeeInstallation,
          color: Color.Green,
        },
        {
          name: 'premium',
          label: 'rehau.global.premium',
          icon: IconName.RingInstallation,
          color: Color.Black,
        },
      ],
      annotations: [
        {
          name: 'optional',
          value: 'rehau.product_tables.water.annotations.optional',
        },
      ],
      rows: [
        {
          name: 'highestMaterialQuality',
          label: 'rehau.product_tables.water.rows.highes_material_quality',
          format: ProductTableRowFormat.Boolean,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: true,
            },
            {
              name: 'premium',
              value: true,
            },
          ],
        },
        {
          name: 'safety',
          label: 'rehau.product_tables.water.rows.safety',
          format: ProductTableRowFormat.Rating,
          annotations: [],
          data: [
            {
              name: 'classic',
              value: 5,
            },
            {
              name: 'premium',
              value: 5,
            },
          ],
        },
      ],
    },
  },
};
