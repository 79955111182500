import styled, { css } from 'styled-components';
import { prop, propEq } from 'ramda';

import { StyledComponent } from '../../types/styledComponent.types';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { BoxShadow } from '../../enums/boxShadow.enum';
import { ZIndex } from '../../enums/zIndex.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { styleWhenTrue } from '../../utils/style.utils';
import { ValidationBar } from '../validationBar/validationBar.component';
import { ValidationStatus } from '../../enums/validationStatus.enum';
import { BaseLabel } from '../../theme';

import { ReactComponent as PinSvg } from './images/pin.svg';
import SearchSvg from './images/search.svg';
import { ContainerProps, InputWrapperProps, MarkerProps } from './chooseLocationInput.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div`
  min-height: 306px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Color.BgGrey};
  transition: 0.3s;

  ${styleWhenTrue(prop('loaded'), css`
    background-color: ${Color.Transparent};
  `)}
`;

export const Label: StyledComponent<typeof BaseLabel> = styled(BaseLabel)`
  margin-bottom: 10px;
  margin-left: 1px;
  word-break: break-word;
  max-width: 610px;
  line-height: 1;
`;

export const InputWrapper: StyledComponent<'div', InputWrapperProps> = styled.div`
  width: 340px;
  height: 44px;
  border: 1px solid ${Color.GreyStroke};
  padding: 0 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transition: 0.3s;
  ${styleWhenTrue(
    propEq('validationStatus', ValidationStatus.Valid),
    css`
      border: 1px solid ${Color.Green};
    `
  )}

  @media ${Breakpoint.Tablet} {
    width: 100%;
    min-width: 340px;
  }

  @media ${Breakpoint.Mobile} {
    min-width: unset;
    max-width: 100%;
  }

  :hover {
    box-shadow: ${BoxShadow.BtnGreyShadow};
  }
`;

export const Input: StyledComponent<'input'> = styled.input`
  font-weight: ${FontWeight.Bold};
  width: 100%;
  font-size: 18px;
  padding: 0 5px 0 0;
  display: flex;
  align-items: center;
  min-width: 0;
  background-image: ${`url(${SearchSvg})`};
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  padding-right: 25px;

  &::placeholder {
    font-size: 18px;
    font-weight: ${FontWeight.Regular};
  }
`;

export const Wrapper: StyledComponent<'div'> = styled.div`
  position: relative;
  width: 100%;
`;

export const Dropdown: StyledComponent<'div'> = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  border-radius: 5px;
  background-color: ${Color.White};
  box-shadow: ${BoxShadow.BtnGreyShadow};
  z-index: ${ZIndex.LocationDropdown};
  padding: 9px 0;
  min-width: 340px;

  @media ${Breakpoint.Tablet} {
    width: 100%;
  }
`;

export const Option: StyledComponent<'div'> = styled.div`
  padding: 5px 14px;
  cursor: pointer;

  ${styleWhenTrue(prop('active'), css`
    background-color: ${Color.BgGrey};
  `)}
`;

export const OptionText: StyledComponent<'span'> = styled.span`
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.24px;
`;

export const MapContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: 250px;
  margin-top: 10px;
  border: 1px solid ${Color.GreyStroke};
  position: relative;
`;

export const Marker: StyledComponent<typeof PinSvg, MarkerProps> = styled(PinSvg)`
  position: relative;
  top: -40px;
  left: -20px;
  pointer-events: none;
`;

export const MarkerPlaceholder: StyledComponent<typeof PinSvg> = styled(PinSvg)`
  position: absolute;
  top: calc(50% - 10px);
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: ${ZIndex.Minimum};
`;

export const LocationButton: StyledComponent<'button'> = styled.button`
  width: 32px;
  height: 32px;
  position: absolute;
  left: 10px;
  top: 10px;
  background-color: ${Color.White};
  border-radius: 4px;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  z-index: ${ZIndex.Minimum};
  display: flex;
  align-items: center;
  justify-content: center;
  transform: 0.3s;
  ${styleWhenTrue(prop('disabled'), css`
    background-color: ${Color.GreyStroke};
  `)}
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  width: 100%;
`;
