import styled, { StyledComponent } from 'styled-components';

import { Color } from '../../enums/color.enum';
import { hexToRgba } from '../../utils/colors.utils';
import { FontWeight } from '../../enums/fontWeight.enum';
import { Markdown } from '../markdown/markdown.component';

export const Container: StyledComponent<typeof Markdown, {}, {}, never> = styled(Markdown)`
  font-size: 18px;
  line-height: 22px;
  color: ${Color.ValidationFont};
  padding: 11px 15px 15px;
  border: 1px solid ${Color.GreyStroke};
  background-color: ${hexToRgba(Color.BgGrey, 0.7)};
  a {
    color: ${Color.Grey};
  }
  strong {
    font-weight: ${FontWeight.Bold};
  }
`;

export const Label: StyledComponent<'div', {}, {}, never> = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: ${Color.Black};
  margin-bottom: 8px;
`;
