import React, { useLayoutEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { useTimelineAnimation } from '@rehau-fe/ui/src/hooks/useTimelineAnimation';
import { UseTimelineAnimationOutput } from '@rehau-fe/ui/src/types/useTimelineAnimation.types';
import { Color, UseState } from '@rehau-fe/ui/src';
import { IconName } from '../../../../../../../../shared/enums';

import { Box, BoxContainer, BoxesWrapper, BoxTitle, StyledIcon, StyledSectionHeader, Wrapper } from './iconBoxes.styled';
import { IconBoxesProps } from './iconBoxes.types';

export const IconBoxes: React.FC<IconBoxesProps> = (props: IconBoxesProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    title = t('rehau.website.home_view.icon_boxes.title'),
    buildingDetailsLabel = t('rehau.website.home_view.icon_boxes.building_details_label'),
    configurationLabel = t('rehau.website.home_view.icon_boxes.configuration_label'),
    installerLabel = t('rehau.website.home_view.icon_boxes.installer_label'),
  }: IconBoxesProps = props;
  const { firstBoxRef, secondBoxRef, thirdBoxRef }: UseTimelineAnimationOutput = useTimelineAnimation();
  const [_, setSize]: UseState<[number, number]> = useState([0, 0]);

  useLayoutEffect(
    (): (() => void) => {
      function updateSize(): void {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();

      return (): void => window.removeEventListener('resize', updateSize);
    },
    []
  );

  return (
    <Wrapper>
      <StyledSectionHeader>{title}</StyledSectionHeader>
      <BoxesWrapper>
        <Box
          data-testid='icon-box-1'
          ref={firstBoxRef}
          height={firstBoxRef.current?.getBoundingClientRect().height}
          width={firstBoxRef.current?.getBoundingClientRect().width}
        >
          <StyledIcon name={IconName.Corridors} color={Color.Pink} size={80} />
          <BoxContainer>
            <BoxTitle>{buildingDetailsLabel}</BoxTitle>
          </BoxContainer>
        </Box>
        <Box
          data-testid='icon-box-2'
          ref={secondBoxRef}
          height={firstBoxRef.current?.getBoundingClientRect().height}
          width={firstBoxRef.current?.getBoundingClientRect().width}
        >
          <StyledIcon name={IconName.Installer} color={Color.Pink} size={80} />
          <BoxContainer>
            <BoxTitle>{installerLabel}</BoxTitle>
          </BoxContainer>
        </Box>
        <Box
          data-testid='icon-box-3'
          ref={thirdBoxRef}
          height={firstBoxRef.current?.getBoundingClientRect().height}
          width={firstBoxRef.current?.getBoundingClientRect().width}
        >
          <StyledIcon name={IconName.Reguard} color={Color.Pink} size={80} />
          <BoxContainer>
            <BoxTitle>{configurationLabel}</BoxTitle>
          </BoxContainer>
        </Box>
      </BoxesWrapper>
    </Wrapper>
  );
};
