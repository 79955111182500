import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { ClickNumberWithIconField } from '../../../../../../../shared/forms/elements';
import { FieldElement, FieldElementProps } from '../types';
import { QuantityBar } from '../../../components/quantityBar/quantityBar.component';

export const ClickNumberWithIconFieldElement: FieldElement<ClickNumberWithIconField> = (
  props: FieldElementProps<ClickNumberWithIconField>
): JSX.Element => {
  const { component, save, setValues }: FieldElementProps<ClickNumberWithIconField> = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <QuantityBar
      label={t(component.label || '')}
      icon={component.icon}
      tooltip={component.tooltip}
      handleSave={save}
      component={component}
      setValues={setValues}
    />
  );
};
