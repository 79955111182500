import React from 'react';
import ReactDOM from 'react-dom';

import '@rehau-fe/ui/src/translations';
import '@rehau-fe/ui/src/theme/css/icons.css';
import '@rehau-fe/ui/src/theme/css/fonts.css';

import App from './app/app';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
