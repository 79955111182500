export enum EmailTypeEnum {
  AcceptedHotLeadOffer = 'acceptedHotLeadOffer',
  AcceptedWarmLeadOffer = 'acceptedWarmLeadOffer',
  RejectedOffer = 'rejectedOffer',
  RejectedOfferReminder = 'rejectedOfferReminder',
  ConfirmEmail = 'confirmEmail',
  WarmLeadNotificationForInstaller = 'warmLeadNotificationForInstaller',
  HotLeadEmailVerified = 'hotLeadEmailVerified',
  WarmLeadEmailVerified = 'warmLeadEmailVerified',
  OrderCallToInstaller = 'orderCallToInstaller',
  OptionalFieldsFulfilled = 'optionalFieldsFulfilled',
  InstallerContactNotification = 'installerContactNotification',
  NoContactFromInstaller = 'noContactFromInstaller',
  QuickSummary = 'quickSummary',
  Summary = 'summary',
  HotLeadSummary = 'hotLeadSummary',
  OrderCallToCustomer = 'orderCallToCustomer',
  ExternalInstaller = 'externalInstaller',
  ExternalInstallerSummary = 'externalInstallerSummary',
  InstallerRegistration = 'installerRegistration',
  InstallerRegistrationAccept = 'installerRegistrationAccept',
  ChangePassword = 'changePassword',
  ChangePasswordConfirmation = 'changePasswordConfirmation',
  UnverifiedColdNotification = 'unverifiedColdNotification',
  VerifiedWarmNotification = 'verifiedWarmNotification',
  UnverifiedHotFirstNotification = 'unverifiedHotFirstNotification',
  UnverifiedHotSecondNotification = 'unverifiedHotSecondNotification',
  UnverifiedWarmFirstNotification = 'unverifiedWarmFirstNotification',
  UnverifiedWarmSecondNotification = 'unverifiedWarmSecondNotification',
  WarmLeadNotificationForAdministration = 'warmLeadNotificationForAdministration',
  VerifiedWarmSecondNotification = 'verifiedWarmSecondNotification',
  DeclineAgreementNotification = 'declineAgreementNotification',
  NoApprovalAgreementNotificationForInstaller = 'noApprovalAgreementNotificationForInstaller',
  NoApprovalAgreementNotificationForAdministration = 'noApprovalAgreementNotificationForAdministration',
  InstallerRegistrationReject = 'installerRegistrationReject',
  EmailChangeByAdmin = 'emailChangeByAdmin',
  PasswordChangeByAdmin = 'passwordChangeByAdmin',
  EmailAndPasswordChangeByAdmin = 'emailAndPasswordChangeByAdmin',
  EmailChange = 'emailChange',
  DeployDayNotification = 'deployDayNotification',
  BeforeDeployDayNotification = 'beforeDeployDayNotification',
  InstallerRegistrationAcceptPromoSetups = 'installerRegistrationAcceptPromoSetups',
  CompanyFirstSetupPublished = 'companyFirstSetupPublished',
}
