import styled from 'styled-components';

import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { InnerContainer, OuterContainer } from '../../../../theme/containers.styled';
import { StyledComponent } from '../../../../types/styledComponent.types';

export const Container: StyledComponent<'div'> = styled.div``;

export const TableContainer: StyledComponent<typeof OuterContainer> = styled(OuterContainer)`
  padding-bottom: 100px;

  @media ${Breakpoint.Tablet} {
    margin: 0;
    width: 100%;
    padding: 0 40px 60px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0 20px 80px;
  }
`;

export const TableWrapper: StyledComponent<typeof InnerContainer> = styled(InnerContainer)`
  display: block;
`;
