import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { SelectItemField } from '../../../../../../../shared/forms/elements';
import { FieldComponent, FieldComponentWrapper } from '../fieldComponent.component';
import { FieldElement, FieldElementProps } from '../types';
import { SelectItemsContainer } from '@rehau/shared/forms/components';
import { MultiSelectBox } from '../../../components/select/multiSelectBox/multiSelectBox.component';
import { isSharedElementAvailable } from '../../../utils/isSharedElementAvailable.utils';
import { getFieldContainerType } from '../utils/getFieldContainerType';

export const SelectItemsBoxFieldElement: FieldElement<SelectItemsContainer> = (
  props: FieldElementProps<SelectItemsContainer>
): JSX.Element | null => {
  const { component, save, saveMethod, setValues }: FieldElementProps<SelectItemsContainer> = props;
  const { t }: TransProps<never> = useTranslation();
  const optionsAvaiable: boolean = component.isAnyFieldAvailable();

  return optionsAvaiable ? (
    <MultiSelectBox
      label={t(component.title)}
      component={component}
      handleSave={save}
      setValues={setValues}
    >
      {component.elements
        .filter((innerComponent: SelectItemField): boolean => isSharedElementAvailable(innerComponent))
        .map((innerComponent: SelectItemField, index: number): JSX.Element => (
        <FieldComponentWrapper className={getFieldContainerType(innerComponent)} key={`${innerComponent.label}_${index}`}>
          <FieldComponent innerComponent={innerComponent} saveMethod={saveMethod} setValues={setValues} />
        </FieldComponentWrapper>
      ))}
    </MultiSelectBox>
  ) : null;
};
