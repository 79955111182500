import React from 'react';

import { Image } from './fullWidthImage.styled';
import { FullWidthImageProps } from './fullWidthImage.types';

export const FullWidthImage: React.FC<FullWidthImageProps> = (props: FullWidthImageProps): JSX.Element => {
  const { className, src, alignTop }: FullWidthImageProps = props;

  return (
    <Image
      className={className}
      src={src}
      data-testid='full-width-image'
      alignTop={alignTop}
    />
  );
};
