import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { Color } from '../../enums/color.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';

import { CheckContainerProps } from './check.types';

export const CheckContainer: StyledComponent<'div', CheckContainerProps> = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ color }: CheckContainerProps): Color => color ?? Color.Green};
  display: flex;
  justify-content: center;
  align-items: center;

  ${styleWhenTrue(
    prop('disabled'),
    css`
      background-color: ${Color.GreyStroke};
    `
  )}
`;
