import styled from 'styled-components';

import { BoxShadow } from '../../../../enums/boxShadow.enum';
import { Color } from '../../../../enums/color.enum';
import { StyledComponent } from '../../../../types/styledComponent.types';

import { CheckCircleProps } from './serviceRow.types';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & + & {
    margin-top: 15px;
  }

  :last-child {
    margin-bottom: 0;
  }
`;

export const CheckTitleContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const CheckCircle: StyledComponent<'div', CheckCircleProps> = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: ${({ isActive }: CheckCircleProps): Color => isActive ? Color.Green : Color.GreyStroke};
  box-shadow: ${({ isActive }: CheckCircleProps): string => isActive ? BoxShadow.BtnGreenShadow : 'none'};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  flex-shrink: 0;
`;

export const ServiceTitle: StyledComponent<'p'> = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${Color.Grey};
`;
