import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import {
  EmailScreen,
  FieldComponent,
  findPathByName,
  FormStatus,
  Step,
  useCountryConfig,
  UseCountryConfig,
  useRedirect,
  UseRedirectOutput,
  useRoutesInfo,
  UseState,
} from '@rehau-fe/ui/src';
import { RouteInfo } from '@rehau/shared/models/countryConfig';
import { RemindPasswordForm } from '@rehau/shared/forms/password/remindPassword.form';
import { BaseAbstractContainer, componentTypes, fieldValueType } from '@rehau/shared/forms';
import { ValidationBar } from '@rehau-fe/ui/src/components/validationBar/validationBar.component';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { remindPassword } from '@rehau-fe/app/api/requests/registration.request';
import { WebsitePathNameEnum } from '@rehau/shared/enums';
import { ApiErrorModel } from '@rehau/shared/models/responses';

import { Container } from './remindPassword.styled';

export const RemindPassword: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { config }: UseCountryConfig = useCountryConfig();
  const remindPasswordForm: RemindPasswordForm = useMemo((): RemindPasswordForm => new RemindPasswordForm({}, config), []);
  const [status, setStatus]: UseState<FormStatus> = useState<FormStatus>(FormStatus.Idle);
  const [requestError, setRequestError]: UseState<string> = useState<string>('');
  const [email, setEmail]: UseState<string> = useState<string>('');
  const routesArray: RouteInfo[] = useRoutesInfo();
  const handleRedirect: UseRedirectOutput = useRedirect();

  const submitForm: () => void = (): void => {
    if (status === FormStatus.Loading) {
      return;
    }
    const validationSuccess: boolean = (remindPasswordForm as BaseAbstractContainer).validate();
    if (validationSuccess) {
      const data: ObjectIteratorInterface<fieldValueType> = (remindPasswordForm as BaseAbstractContainer).getValues();
      setEmail(data['email'] as string);
      setStatus(FormStatus.Loading);
      remindPassword(data)
        .then((): void => {
          setStatus(FormStatus.Success);
        })
        .catch((error: ApiErrorModel): void => {
          setStatus(FormStatus.Error);
          setRequestError(error.message);
        });
    } else {
      setStatus(FormStatus.Error);
    }
  };

  const redirectToLoginPage: () => void = (): void => {
    handleRedirect(
      findPathByName(WebsitePathNameEnum.SignIn, routesArray),
      WebsitePathNameEnum.SignIn
    );
  };

  const redirectToHomePage: () => void = (): void => {
    handleRedirect(
      findPathByName(WebsitePathNameEnum.Home, routesArray),
      WebsitePathNameEnum.Home
    );
  };

  return (
    <>
      {status !== FormStatus.Success ? (
        <Container>
          <Step
            title={t('rehau.website.remind_password_view.title')}
            nextButtonLabel={t('rehau.website.remind_password_view.remind_button')}
            handleNextClick={submitForm}
            loading={status === FormStatus.Loading}
            previousButtonLabel={t('rehau.website.remind_password_view.go_back_button')}
            shouldShowPreviousButton
            handlePreviousClick={redirectToLoginPage}
          >
            {remindPasswordForm.elements.map(
              (component: componentTypes, index: number): JSX.Element => (
                <FieldComponent
                  innerComponent={component}
                  setValues={(remindPasswordForm as BaseAbstractContainer).setValues.bind(component)}
                  key={index}
                />
              )
            )}
            <ValidationBar errorsArray={requestError ? [{ message: requestError }] : []} />
          </Step>
        </Container>
      ) : (
        <EmailScreen
          title={t('rehau.website.remind_password_view.success_title')}
          description={t('rehau.website.remind_password_view.success_description', { email })}
          buttonLabel={t('rehau.website.remind_password_view.back_to_home_button_label')}
          handleButtonClick={redirectToHomePage}
          hasButton
        />
      )}
    </>
  );
};
