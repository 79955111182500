export enum AdditionalServicesEnum {
  Reguard = 'reguard',
  Resource = 'resource',
  SolarSystem = 'solar_system',
  Photovoltaic = 'photovoltaic',
  FreshWaterModule = 'fresh_water_module',
  DecalcificationModule = 'decalcification_module',
  CirculationInstallation = 'circulation_installation',
  Ventilation = 'ventilation',
}
