import React, { forwardRef, useRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';

import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { FullWidthImage } from '../../components/fullWidthImage/fullWidthImage.component';
import { Tabs } from '../../components/tabs/tabs.component';
import { TextWithImage } from '../../components/textWithImage/textWithImage.component';
import { TextWithColumns } from '../../components/textWithColumns/textWithColumns.component';
import { Legend } from '../../components/legend/legend.component';
import { ColumnType } from '../../components/textWithColumns/textWithColumns.enums';
import { IconItems } from '../../components/iconItems/iconItems.component';
import { TextWithIcon } from '../../components/textWithIcon/textWithIcon.component';
import { ImageWithColumns } from '../../components/imageWithColumns/imageWithColumns.component';
import { useActiveTab } from '../../hooks/useActiveTab.hooks';
import AppImage from '../../images/reguardApp.jpg';
import SystemImage from '../../images/system.png';
import WaterSensorImage from '../../images/waterSensor.png';
import FamilyImage from '../../images/familyComingOut.jpg';
import InstallationImage from '../../images/installation.jpg';
import AwardWinnerImage from '../../images/awardWinner.jpg';
import HandWithSensorImage from '../../images/handWithSensor.jpg';
import HandWithSensorMobileImage from '../../images/handWithSensorMobile.jpg';
import ZWaveImage from '../../images/zWavePlus.jpg';
import HandWithCoinsImage from '../../images/handWithCoins.jpg';
import ClockImage from '../../images/clock.png';
import ThumbImage from '../../images/thumb.png';
import ConnectionImage from '../../images/connection.png';
import HouseImage from '../../images/house.png';
import WaterLeakImage from '../../images/waterLeak.jpg';
import LeakMobileImage from '../../images/leakMobile.jpg';
import OldPipeImage from '../../images/oldPipe.jpg';
import PipeMobileImage from '../../images/pipeMobile.jpg';
import WaterTapImage from '../../images/waterTap.jpg';
import WaterTapMobileImage from '../../images/waterTapMobile.jpg';
import PhonesImage from '../../images/phones.jpg';
import ValveImage from '../../images/valve.jpeg';
import SensorImage from '../../images/waterSensor.jpeg';
import GateImage from '../../images/gate.jpeg';
import AppColumnImage1 from '../../images/app1.jpg';
import AppColumnImage2 from '../../images/app2.jpg';
import AppColumnImage3 from '../../images/app3.jpg';
import { ReactComponent as PhoneSvg } from '../../images/phone.svg';
import { ReactComponent as WifiSvg } from '../../images/wifi2.svg';
import { ReactComponent as CrossSvg } from '../../images/cross.svg';
import { UseActiveTab, UseCalculatorNavigate } from '../../products.types';
import { sectionVisibilityThreshold } from '../../products.constants';
import { ConfiguratorPreview } from '../../../../components/configuratorPreview/configuratorPreview.component';
import { useCalculatorNavigate } from '../../hooks/useCalculatorNavigate.hook';
import { ProductPopupContext } from '../../../../contexts/productPopup.context';
import { OptionTab } from '../../components/tabs/tabs.types';

import { SectionId } from './reGuard.enums';
import { Container } from './reGuard.styled';
import { ReGuardProps } from './reGuard.types';

export const ReGuard: React.FC<ReGuardProps> = forwardRef((
  props: ReGuardProps, ref: React.ForwardedRef<{}>
): JSX.Element => {
  const { className, tabsOnTop, setTabsOnTop, isInPopup = false }: ReGuardProps = props;
  const { t }: TransProps<never> = useTranslation();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const [activeTabId, setActiveTabId]: UseActiveTab = useActiveTab('');
  const { navigateToCalculator }: UseCalculatorNavigate = useCalculatorNavigate();
  const advantagesRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const systemRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const actionRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const appRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const sensorRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const safetyRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const installationRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const calculatorRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);

  const getCurrentRefByActiveId: (activeTab: string) => React.RefObject<InView & HTMLDivElement> =
    (activeTab: string): React.RefObject<InView & HTMLDivElement> => {
      switch (activeTab) {
        case SectionId.Advantages:
          return advantagesRef;
        case SectionId.System:
          return systemRef;
        case SectionId.Action:
          return actionRef;
        case SectionId.App:
          return appRef;
        case SectionId.Sensor:
          return sensorRef;
        case SectionId.Safety:
          return safetyRef;
        case SectionId.Installation:
          return installationRef;
        default:
          return calculatorRef;
      }
    };

  const handleScroll: (tabId: string) => void = (tabId: string): void => {
    const activeRef: React.RefObject<InView & HTMLDivElement> = getCurrentRefByActiveId(tabId);
    activeRef.current?.node?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ProductPopupContext.Provider value={{ isInPopup }}>
      <Container className={className} data-testid='reguard-product' ref={ref as React.RefObject<HTMLDivElement>}>
        <FullWidthImage src={AppImage} />
        <TextWithImage
          sectionTitle={t('rehau.products.reguard.header.title')}
          text={t('rehau.products.reguard.header.content')}
          imageSrcList={[AwardWinnerImage]}
        />
        <Tabs
          activeId={activeTabId}
          options={[
            { label: t('rehau.products.reguard.tabs.advantages'), id: SectionId.Advantages },
            { label: t('rehau.products.reguard.tabs.system'), id: SectionId.System },
            { label: t('rehau.products.reguard.tabs.action'), id: SectionId.Action },
            { label: t('rehau.products.reguard.tabs.app'), id: SectionId.App },
            { label: t('rehau.products.reguard.tabs.water_sensor'), id: SectionId.Sensor },
            { label: t('rehau.products.reguard.tabs.safety'), id: SectionId.Safety },
            { label: t('rehau.products.reguard.tabs.installation'), id: SectionId.Installation },
            !isInPopup ? { label: t('rehau.products.heat.tabs.calculator'), id: SectionId.Calculator } : {} as OptionTab,
          ]}
          isOnTop={tabsOnTop}
          setOnTop={setTabsOnTop}
          handleScroll={handleScroll}
        />
        <InView
          as='div'
          threshold={isMobile ? 0.1 : 0.2}
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Advantages)}
          id={SectionId.Advantages}
          ref={advantagesRef}
        >
          <IconItems
            title={t('rehau.products.reguard.advantages.title')}
            imageSrc={HandWithSensorImage}
            imageSrcMobile={HandWithSensorMobileImage}
            itemsData={[
              {
                imageSrc: ClockImage,
                title: t('rehau.products.reguard.advantages.installation_title'),
                text: t('rehau.products.reguard.advantages.installation_content'),
              },
              {
                imageSrc: ThumbImage,
                title: t('rehau.products.reguard.advantages.safety_title'),
                text: t('rehau.products.reguard.advantages.safety_content'),
              },
              {
                imageSrc: ConnectionImage,
                title: t('rehau.products.reguard.advantages.control_title'),
                text: t('rehau.products.reguard.advantages.control_content'),
              },
              {
                imageSrc: HouseImage,
                title: t('rehau.products.reguard.advantages.for_all_title'),
                text: t('rehau.products.reguard.advantages.for_all_content'),
              },
            ]}
            rowsTitle={t('rehau.products.reguard.advantages.rows_title')}
            rowsData={[
              {
                imageSrc: WaterLeakImage,
                imageSrcMobile: LeakMobileImage,
                title: t('rehau.products.reguard.advantages.damages_title'),
                text: t('rehau.products.reguard.advantages.damages_content'),
              },
              {
                imageSrc: OldPipeImage,
                imageSrcMobile: PipeMobileImage,
                title: t('rehau.products.reguard.advantages.pipes_title'),
                text: t('rehau.products.reguard.advantages.pipes_content'),
              },
              {
                imageSrc: WaterTapImage,
                imageSrcMobile: WaterTapMobileImage,
                title: t('rehau.products.reguard.advantages.leaks_title'),
                text: t('rehau.products.reguard.advantages.leaks_content'),
              },
            ]}
          />
        </InView>
        <InView
          as='div'
          threshold={isMobile ? 0.15 : 0.3}
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.System)}
          id={SectionId.System}
          ref={systemRef}
        >
          <ImageWithColumns
            title={t('rehau.products.reguard.system.title')}
            imageSrc={SystemImage}
            columnsData={[
              {
                title: t('rehau.products.reguard.system.valve_title'),
                text: t('rehau.products.reguard.system.valve_content'),
                imageSrc: ValveImage,
              },
              {
                title: t('rehau.products.reguard.system.sensor_title'),
                text: t('rehau.products.reguard.system.sensor_content'),
                imageSrc: SensorImage,
              },
              {
                title: t('rehau.products.reguard.system.gate_title'),
                text: t('rehau.products.reguard.system.gate_content'),
                imageSrc: GateImage,
              },
              {
                title: t('rehau.products.reguard.system.app_title'),
                text: t('rehau.products.reguard.system.app_content'),
                imageSrc: PhonesImage,
              },
            ]}
          />
        </InView>
        <InView
          as='div'
          threshold={isMobile ? 0.3 : sectionVisibilityThreshold}
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Action)}
          id={SectionId.Action}
          ref={actionRef}
        >
          <TextWithColumns
            columnsTitle={t('rehau.products.reguard.action.title')}
            columnType={ColumnType.IconBox}
            darkBackground
            columnsData={[
              {
                text: t('rehau.products.reguard.action.detector_content'),
                value: <WifiSvg />,
              },
              {
                text: t('rehau.products.reguard.action.app_content'),
                value: <PhoneSvg />,
              },
              {
                text: t('rehau.products.reguard.action.avoid_content'),
                value: <CrossSvg />,
              },
            ]}
          />
        </InView>
        <InView
          as='div'
          threshold={isTablet ? 0.2 : sectionVisibilityThreshold}
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.App)}
          id={SectionId.App}
          ref={appRef}
        >
          <TextWithColumns
            title={t('rehau.products.reguard.app.title')}
            columnType={ColumnType.Image}
            columnsData={[
              {
                title: t('rehau.products.reguard.app.all_in_one_title'),
                text: t('rehau.products.reguard.app.all_in_one_content'),
                imageSrc: AppColumnImage1,
              },
              {
                title: t('rehau.products.reguard.app.up_to_date_title'),
                text: t('rehau.products.reguard.app.up_to_date_content'),
                imageSrc: AppColumnImage2,
              },
              {
                title: t('rehau.products.reguard.app.saving_title'),
                text: t('rehau.products.reguard.app.saving_content'),
                imageSrc: AppColumnImage3,
              },
            ]}
          />
          <TextWithIcon
            title={t('rehau.products.reguard.app.subtitle')}
            text={t('rehau.products.reguard.app.subcontent')}
            icon={ZWaveImage}
          />
        </InView>
        <InView
          as='div'
          threshold={sectionVisibilityThreshold}
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Sensor)}
          id={SectionId.Sensor}
          ref={sensorRef}
        >
          <TextWithImage
            title={t('rehau.products.reguard.water_sensor.title')}
            text={t('rehau.products.reguard.water_sensor.content')}
            imageSrcList={[WaterSensorImage]}
            hasWideText
            darkBackground
          />
        </InView>
        <InView
          as='div'
          threshold={isMobile ? 0.15 : 0.3}
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Safety)}
          id={SectionId.Safety}
          ref={safetyRef}
        >
          <TextWithImage
            title={t('rehau.products.reguard.safety.title')}
            text={t('rehau.products.reguard.safety.content')}
            imageSrcList={[FamilyImage]}
          />
          <TextWithIcon
            title={t('rehau.products.reguard.safety.subtitle')}
            text={t('rehau.products.reguard.safety.subcontent')}
            icon={HandWithCoinsImage}
          />
        </InView>
        <InView
          as='div'
          threshold={sectionVisibilityThreshold}
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Installation)}
          id={SectionId.Installation}
          ref={installationRef}
        >
          <TextWithImage
            title={t('rehau.products.reguard.installation.title')}
            text={t('rehau.products.reguard.installation.content')}
            imageSrcList={[InstallationImage]}
          />
          <Legend
            options={[
              t('rehau.products.reguard.legend.house_size_design'),
              t('rehau.products.reguard.legend.tap_water_demage_link'),
              t('rehau.products.reguard.legend.internet_smartphone_connection'),
            ]}
          />
        </InView>
        {!isInPopup && (
          <InView
            as='div'
            onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Calculator)}
            id={SectionId.Calculator}
            threshold={sectionVisibilityThreshold}
            ref={calculatorRef}
          >
            <ConfiguratorPreview buttonOnClick={navigateToCalculator} />
          </InView>
        )}
      </Container>
    </ProductPopupContext.Provider>
  );
});
