export enum ProductGroupEnum {
  All = 'all',
  PowerSupply = 'powerSupply',
  HeatDistribution = 'heatDistribution',
  TemperatureControl = 'temperatureControl',
  DrinkingWaterInstallation = 'drinkingWaterInstallation',
  DrinkingWaterDistribution = 'drinkingWaterDistribution',
  BathroomFittings = 'bathroomFittings',
  Sewage = 'sewage',
  Ventilation = 'ventilation',
}
