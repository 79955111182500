import styled from 'styled-components';
import Picker from 'react-datepicker';

import { StyledComponent } from '../../types/styledComponent.types';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { InputContainer } from '../input/inputContainer/inputContainer.component';

export const Container: StyledComponent<'div'> = styled.div``;

export const DatePickerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const StyledInputContainer: StyledComponent<typeof InputContainer> = styled(InputContainer)`
  padding: 0;
`;

export const StyledPicker: StyledComponent<typeof Picker> = styled(Picker)`
  height: 42px;
  width: 100%;
  padding-right: 25px;
  color: ${Color.Grey};
  font-size: 14px;
  line-height: 17px;

  ::placeholder {
    font-weight: ${FontWeight.Regular};
  }
`;
