import { prop } from 'ramda';
import styled, { css, StyledComponent } from 'styled-components';

import { ZIndex } from '../../enums/zIndex.enum';
import { styleWhenTrue } from '../../utils/style.utils';

import { BackgroundProps } from './loader.types';

export const Background: StyledComponent<'div', {}, BackgroundProps, never> = styled.div`
  position: fixed;
  z-index: ${ZIndex.Loader};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  ${styleWhenTrue(prop('isVisible'), css`
    opacity: 1;
    pointer-events: initial;
  `)};
`;
