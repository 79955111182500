import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { StyledComponent } from '../../types/styledComponent.types';
import { ExpandedStepsMenu } from '../expandedStepsMenu/expandedStepsMenu.component';

export const Container: StyledComponent<'div'> = styled.div``;

export const StyledExpandedStepsMenu: StyledComponent<typeof ExpandedStepsMenu> = styled(ExpandedStepsMenu)`
  margin-bottom: 40px;
`;
