import { Color } from '../enums/color.enum';

export const hexToRgba: (hex: string, alpha: number) => string = (hex: string, alpha: number = 1): string => {
  // tslint:disable-next-line: no-any
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;

    // tslint:disable-next-line: no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')}, ${alpha})`;
  }
  throw new Error('Bad Hex');
};

export const findColorByName: (name: string) => Color = (name: string): Color => {
  const colorEntry: [key: string, color: Color] | undefined = Object.entries(Color).find(
    (entry: [key: string, color: string]): boolean => entry[0] === name
  );

  return colorEntry ? colorEntry[1] : Color.Black;
};
