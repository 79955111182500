import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { MultiSelectField, SelectOption } from '../../../../../../../shared/forms/elements';
import { DropdownController } from '../../dropdown/dropdownController/dropdownController.component';
import { Dropdown } from '../../dropdown/dropdown.component';
import { FieldElement, FieldElementProps } from '../types';
import { MultiselectDropdown } from '../../multiselectDropdown/multiselectDropdown.component';

export const MultiselectFieldElement: FieldElement<MultiSelectField> = (props: FieldElementProps<MultiSelectField>): JSX.Element => {
  const { component, save, setValues }: FieldElementProps<MultiSelectField> = props;
  const { t }: TransProps<never> = useTranslation();
  const allowedOptions: SelectOption[] = component.allowedOptions.map(
    (option: SelectOption): SelectOption => {
      option.label = t(option.label);

      return option;
    }
  );

  return (
    <MultiselectDropdown
      items={allowedOptions}
      label={t(component.label || '')}
      component={component}
      handleSave={save}
      setValues={setValues}
    >
      <Dropdown />
    </MultiselectDropdown>
  );
};
