import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { Breakpoint } from '../../../enums/breakpoint.enum';
import { Color } from '../../../enums/color.enum';
import { GridWidth } from '../../../enums/gridWidth.enum';
import { StyledComponent } from '../../../types/styledComponent.types';
import { styleWhenTrue } from '../../../utils/style.utils';
import { Price } from '../../../theme/price/price.component';
import { Icon } from '../../icon/icon.component';
import { Bar, BarLabel } from '../selectBar.styled';
import { Tooltip } from '../../tooltip/tooltip.component';

import { ContentWrapperProps, RowProps, StyledPriceProps } from './selectBarWithProgress.types';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  max-width: ${GridWidth.Desktop};
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  ${Breakpoint.Mobile} {
    margin-right: 20px;
  }
`;

export const Content: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
`;

export const IconLabelWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  min-width: 400px;
  width: 50%;
`;

export const ContentWrapper: StyledComponent<'div', ContentWrapperProps> = styled.div<ContentWrapperProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  ${styleWhenTrue(
    prop('withoutProgressBar'),
    css`
      max-width: 60%;
      width: unset;
    `
  )}

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
  }
`;

export const Row: StyledComponent<'div', RowProps> = styled.div<RowProps>`
  width: calc(100% - 90px);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 27px 0;
  margin-left: 90px;
  border-top: 1px solid ${Color.GreyStroke};
  ${styleWhenTrue(
    prop('withoutProgressBar'),
    css`
      padding: 0;
    `
  )}

  :first-child {
    width: 100%;
    margin-left: 0;
    padding: 27px 0;
    border: none;

    ${styleWhenTrue(
      prop('withoutProgressBar'),
      css`
        padding: 0;
      `
    )}
  }

  @media ${Breakpoint.Mobile} {
    width: 100%;
    margin-left: 0;
  }
`;

export const Column: StyledComponent<'div'> = styled.div`
  display: flex;

  @media ${Breakpoint.Mobile} {
    align-items: center;
  }
`;

export const Label: StyledComponent<typeof BarLabel> = styled(BarLabel)`
  margin-left: 20px;
  max-width: 300px;

  @media ${Breakpoint.Tablet} {
    margin-left: 0;
    max-width: 250px;
  }
`;

export const StyledPrice: StyledComponent<typeof Price, StyledPriceProps> = styled(Price)<StyledPriceProps>`
  margin-right: 10px;
  margin-left: 10px;

  ${styleWhenTrue(
    prop('withoutProgressBar'),
    css`
      margin-right: 60px;

      @media ${Breakpoint.Tablet} {
        margin-right: 40px;
      }
    `
  )}

  @media ${Breakpoint.Mobile} {
    margin-right: 0;
    align-items: flex-end;
  }
`;

export const StyledTooltip: StyledComponent<typeof Tooltip> = styled(Tooltip)`
  margin-left: 20px;
`;

export const Currency: StyledComponent<'span'> = styled.span``;

export const StyledBar: StyledComponent<typeof Bar> = styled(Bar)`
  min-height: 100px;
  padding: 5px 20px;

  @media ${Breakpoint.Mobile} {
    height: unset;
    align-items: flex-start;
    margin-right: 0;
    padding: 0 20px;
  }

  ${styleWhenTrue(
    prop('hasTooltip'),
    css`
      margin-right: 20px;
    `
  )}

  ${styleWhenTrue(
    prop('active'),
    css`
      border: 1px solid ${Color.Green};
    `
  )}
`;
