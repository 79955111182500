import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { FontWeight } from '../../../../enums/fontWeight.enum';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { styleWhenTrue } from '../../../../utils/style.utils';
import { Color } from '../../../../enums/color.enum';

import { ContainerProps, TextProps } from './title.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div`
  overflow: hidden;
  margin-bottom: 15px;

  ${styleWhenTrue(prop('isHeader'), css`
    margin-bottom: 15px;
  `)}
`;

export const Text: StyledComponent<'h4', TextProps> = styled.h4`
  font-size: 28px;
  line-height: 32px;
  font-weight: ${FontWeight.Black};
  word-break: break-word;
  box-sizing: border-box;

  ${styleWhenTrue(prop('large'), css`
    font-size: 36px;
    line-height: 48px;
  `)}

  ${styleWhenTrue(prop('isHeader'), css`
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.77px;
    color: ${Color.Green};
    text-transform: uppercase;
  `)}

  ${styleWhenTrue(prop('small'), css`
    font-size: 24px;
    line-height: 28px;
    font-weight: ${FontWeight.Bold};
    letter-spacing: 0.27px;
  `)}
`;
