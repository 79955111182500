export enum ZIndex {
  Hidden = '-1',
  Zero = '0',
  Minimum = '1',
  TableRow = '2',
  MultiSelectDropdown = '3',
  LocationDropdown = '4',
  FixedScrollTopArrow = '5',
  FixedX = '6',
  ChartBoxTooltip = '7',
  MobileInfoBox = '18',
  ContextMenu = '19',
  DropdownMenu = '46',
  SlideInnerBarBoundaryLeft = '50',
  SlideInnerBarBoundaryRight = '60',
  SlideInnerBarBoundaryLeftOnTop = '70',
  AdminNav = '80',
  SortSettingsPopup = '82',
  AdminUserBox = '85',
  Tooltip = '100',
  ErrorScreen = '200',
  Navigation = '299',
  MainNavbar = '300',
  IconTrigger = '302',
  Alerts = '303',
  SmallDialog = '304',
  Overlay = '305',
  OfferDialog = '306',
  ProductVideo = '306',
  ProductVideoControls = '307',
  PhotoWithModal = '308',
  OrderCallPopup = '310',
  SlidingPanel = '315',
  EditUserPopup = '316',
  Alert = '317',
  DialogWithIcon = '319',
  Dialog = '320',
  PricesDialog = '321',
  ChangeOffersManagementPopup = '322',
  ExpandedStepsNavigation = '330',
  SideMenu = '331',
  Explainer = '390',
  FullscreenPopup = '400',
  StickyTeaser = '500',
  MainNavbarModal = '600',
  CustomTooltip = '999',
  Loader = '1000',
  CalculatorPopup = '2147483647',  // max z-index value
}
