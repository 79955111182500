import React from 'react';

import { FieldElement, FieldElementProps } from '../types';
import { RadiusContainer as RadiusContainerType } from '@rehau/shared/forms/components';
import { RadiusContainer } from '../../input/radiusContainer/radiusContainer.component';

export const RadiusContainerFieldElement: FieldElement<RadiusContainerType> = (
  props: FieldElementProps<RadiusContainerType>
): JSX.Element => {
  const { component, setValues }: FieldElementProps<RadiusContainerType> = props;

  return (
    <RadiusContainer
      component={component}
      setValues={setValues}
    />
  );
};
