import { IconName, SocialNameEnum } from '../../../../../../shared/enums';

import { SocialsConfigType } from './footer.types';

export const socialsConfig: SocialsConfigType = {
  [SocialNameEnum.Fb]: { icon: IconName.Facebook, size: 24 },
  [SocialNameEnum.Youtube]: { icon: IconName.Youtube, size: 26 },
  [SocialNameEnum.Linkedin]: { icon: IconName.Linkedin, size: 26 },
  [SocialNameEnum.Twitter]: { icon: IconName.Twitter, size: 26 },
};
