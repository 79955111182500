import styled from 'styled-components';

import { Markdown } from '../../../markdown/markdown.component';
import { BoxShadow } from '../../../../enums/boxShadow.enum';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Color } from '../../../../enums/color.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { ZIndex } from '../../../../enums/zIndex.enum';
import { StyledComponent } from '../../../../types/styledComponent.types';

import { WrapperProps } from './slidingPanel.types';

export const Wrapper: StyledComponent<'div', WrapperProps> = styled.div<WrapperProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 478px;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  box-sizing: border-box;
  padding: 18px 30px 0;
  background-color: ${Color.White};
  transform: translateX(100%);
  z-index: ${ZIndex.SlidingPanel};
  overflow-y: auto;
  @media ${Breakpoint.Additional480} {
    padding: ${({ $hasButtonOnBottom }: WrapperProps): string => $hasButtonOnBottom ? '0 20px 40px' : '0 20px'};
    width: 100%;
  }
  transition: 0.3s ease;
  ${({ $isOpen }: WrapperProps): string => $isOpen ? 'transform: translateX(0);' : ''}
  ${({ $hasButtonOnBottom }: WrapperProps): string => $hasButtonOnBottom
    ? `
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 40px;
    `
    : ''
  }
`;

export const TopSectionWithContent: StyledComponent<'div'> = styled.div``;

export const TopSection: StyledComponent<'div'> = styled.div`
  border-bottom: 1px solid ${Color.GreyStroke};
  padding: 20px 0 18px;
`;

export const Content: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 25px;

  & + button {
    margin-bottom: 20px;
  }
`;

export const Header: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-weight: ${FontWeight.Bold};
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 20px;
`;

export const Text: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;
  word-break: break-word;
  font-weight: ${FontWeight.Light};
  color: ${Color.Grey};

  a {
    color: ${Color.Black};
    font-weight: ${FontWeight.Bold};
  }
`;
