import React, { RefObject } from 'react';

import { useProductPopupContext } from '../../../../hooks/useProductPopupContext.hook';
import { ProductPopupContextType } from '../../../../types/productPopupContextType.types';
import { useReveal } from '../../../../hooks/animations/useReveal';

import { Container, Text } from './title.styled';
import { TitleProps } from './title.types';

export const Title: React.FC<TitleProps> = (props: TitleProps): JSX.Element => {
  const {
    className,
    children,
    large = false,
    small = false,
    isHeader = false,
  }: TitleProps = props;
  const { isInPopup }: ProductPopupContextType = useProductPopupContext();
  const textRef: RefObject<HTMLDivElement> = useReveal(isInPopup);

  return (
    <Container className={className} isHeader={isHeader}>
      <Text
        ref={textRef}
        large={large}
        small={small}
        isHeader={isHeader}
      >
        {children}
      </Text>
    </Container>
  );
};
