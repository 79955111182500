import { RefObject, useEffect } from 'react';

type Event = MouseEvent | TouchEvent;

export const useOnClickOutside: <T extends HTMLElement = HTMLElement>(
  refs: RefObject<T>[],
  handler: (event: Event) => void
) => void = <T extends HTMLElement = HTMLElement>(
  refs: RefObject<T>[],
  handler: (event: Event) => void
): void => {
  useEffect(
    (): () => void => {
      const listener: (event: Event) => void = (event: Event): void => {
        const shouldHandle: boolean = refs
          .map((ref: RefObject<T>): boolean => {
            const element: T | null = ref?.current;

            return (!element || element.contains((event?.target as Node) || null));
          })
          .filter((status: boolean): boolean => !status)
          .length === refs.length;
        if (shouldHandle) {
          handler(event);
        }
      };
      document.addEventListener(`mousedown`, listener);
      document.addEventListener(`touchstart`, listener);

      return (): void => {
        document.removeEventListener(`mousedown`, listener);
        document.removeEventListener(`touchstart`, listener);
      };
    },
    [refs, handler]
  );
};
