import { prop } from 'ramda';
import styled, { css, StyledComponent } from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { styleWhenTrue } from '../../utils/style.utils';
import { FontWeight } from '../../enums/fontWeight.enum';
import { AdditionalInfo } from '../additionalInfo/additionalInfo.component';
import { Markdown } from '../markdown/markdown.component';

import { ComponentsContainerProps, LabelContainerProps } from './fieldsContainer.types';

export const Collapse: StyledComponent<'div', {}, {}, never> = styled.div`
  & + & {
    margin-top: 60px;
  }

  @media ${Breakpoint.Mobile} {
    & + & {
      margin-top: 30px;
    }
  }
`;

export const FieldWrapper: StyledComponent<'div', {}, {}, never> = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  & + & {
    margin-top: 60px;
  }

  & + ${Collapse} {
    margin-top: 60px;
  }
  @media ${Breakpoint.Mobile} {
    flex-direction: column;

    & + ${Collapse} {
      margin-top: 30px;
    }

    & + & {
      margin-top: 30px;
    }
  }
`;

export const LabelContainer: StyledComponent<'div', {}, LabelContainerProps, never> = styled.div<LabelContainerProps>`
  width: 100%;
  max-width: 290px;
  margin-right: 70px;
  ${styleWhenTrue(prop('$smallMargin'), css`
    margin-right: 30px;
  `)};

  @media ${Breakpoint.Tablet} {
    max-width: 190px;
  }

  @media ${Breakpoint.Mobile} {
    max-width: unset;
    margin-bottom: 15px;
  }
`;

export const Title: StyledComponent<typeof Markdown, {}, {}, never> = styled(Markdown)`
  font-weight: ${FontWeight.Regular};
  font-size: 20px;
  line-height: 24px;
  color: ${Color.Black};
`;

export const Subtitle: StyledComponent<'div', {}, {}, never> = styled.div`
  font-weight: ${FontWeight.Regular};
  font-size: 18px;
  line-height: 24px;
  color: ${Color.Grey};
  margin-top: 10px;
`;

export const ComponentWrapper: StyledComponent<'div', {}, {}, never> = styled.div``;

export const ComponentsContainer: StyledComponent<'div', {}, ComponentsContainerProps, never> = styled.div<ComponentsContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ $fullSizeWidth }: ComponentsContainerProps): string => $fullSizeWidth ? '610px' : '340px'};
`;

export const TopAdditionalInfo: StyledComponent<typeof AdditionalInfo, {}, {}, never> = styled(AdditionalInfo)`
  margin-bottom: 30px;
`;

export const InfoContainer: StyledComponent<'div', {}, {}, never> = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media ${Breakpoint.Mobile} {
    justify-content: flex-start;
  }
`;

export const AboveEntireFieldAdditionalInfo: StyledComponent<typeof AdditionalInfo, {}, {}, never> = styled(AdditionalInfo)`
  margin: 0 0 20px 360px;
  max-width: 608px;
  width: 100%;

  @media ${Breakpoint.Tablet} {
    margin: 0 0 20px 260px;
  }

  @media ${Breakpoint.Mobile} {
    margin: 0 0 20px;
  }
`;
