export enum WebsitePathNameEnum {
  Default = 'default',
  AccessDenied = 'accessDenied',
  InstallerRegulations = 'installerRegulations',
  CustomerRegulations = 'customerRegulations',
  Calculator = 'calculator',
  CalculatorPromo = 'calculatorPromo',
  TestCalculator = 'testCalculator',
  CalculatorHash = 'calculatorHash',
  CalculatorCalculationSummary = 'calculatorCalculationSummary',
  TestCalculatorCalculationSummary = 'testCalculatorCalculationSummary',
  ExternalInstallerCalculator = 'externalInstallerCalculator',
  ExternalInstallerCalculatorHash = 'externalInstallerCalculatorHash',
  Offers = 'offers',
  OfferPreview = 'offerPreview',
  OfferEdit = 'offerEdit',
  Configurator = 'configurator',
  ConfiguratorStep = 'configuratorStep',
  Profile = 'profile',
  About = 'about',
  Products = 'products',
  ReGuard = 'reGuard',
  ReSource = 'reSource',
  SignIn = 'signIn',
  Logout = 'logout',
  MyOffer = 'myOffer',
  Registration = 'registration',
  ActivateAccount = 'activateAccount',
  Home = 'home',
  RemindPassword = 'remindPassword',
  RemindPasswordConfirmation = 'remindPasswordConfirmation',
  AdminDefault = 'adminDefault',
  AdminSignIn = 'adminSignIn',
  AdminLogout = 'adminLogout',
  AdminOffers = 'adminOffers',
  AdminOffer = 'adminOffer',
  AdminManagement = 'adminManagement',
  AdminManagementRegistrations = 'adminManagementRegistrations',
  AdminManagementRegistrationVerification = 'adminManagementRegistrationVerification',
  AdminManagementCompanies = 'adminManagementCompanies',
  AdminManagementCompanyDetails = 'adminManagementCompanyDetails',
  AdminManagementCompanyEdit = 'adminManagementCompanyEdit',
  AdminManagementUsers = 'adminManagementUsers',
  AdminManagementUserEdit = 'adminManagementUserEdit',
  AdminManagementUserAdd = 'adminManagementUserAdd',
  AdminManagementReports = 'adminManagementReports',
  AdminDashboardOffers = 'adminDashboardOffers',
  AdminDashboardProducts = 'adminDashboardProducts',
  AdminDashboardInstallers = 'adminDashboardInstallers',
  NotFound = 'notFound',
}
