import { AuthAction } from '../enums/authAction.enum';
import { AuthActionType } from '../types/authActionType.types';
import { AuthState } from '../types/authState.types';

export const authReducer: (state: AuthState, action: AuthActionType) => AuthState = (
  state: AuthState,
  action: AuthActionType
): AuthState => {
  switch (action.type) {
    case AuthAction.SignIn:
      return { ...state, ...action.payload };
    case AuthAction.SignOut:
      return { ...state, loggedUser: null };
    case AuthAction.UpdateAuthData:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
