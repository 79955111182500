import { DropdownOption } from '../tinyDropdown/tinyDropdown.types';

import { PeriodOption } from './filtersDropdown.types';

export const intervalOptions: DropdownOption[] = [
  {
    name: 'rehau.components.filters_dropdown.header.all_range',
    value: PeriodOption.All,
  },
  {
    name: 'rehau.components.filters_dropdown.header.month_range',
    value: PeriodOption.Month,
  },
  {
    name: 'rehau.components.filters_dropdown.header.week_range',
    value: PeriodOption.Week,
  },
  {
    name: 'rehau.components.filters_dropdown.header.day_range',
    value: PeriodOption.Day,
  },
];
