import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { ActiveLabel, InProgressLabel } from '../../../../theme/setupStatusLabels.styled';
import { IconName } from '../../../../../../../../shared/enums';
import { Color } from '../../../../enums/color.enum';
import { Overlay } from '../../../../theme/overlay/overlay.styled';
import { UseState } from '../../../../types/useState.types';
import { Icon } from '../../../icon/icon.component';
import { ExpandedStepsMenuPosition } from '../../expandedStepsMenu.types';

import {
  CloseButton,
  FixedMenuInnerContainer,
  Header,
  HeaderSection,
  MenuColumn,
  MenuContainer,
  MenuPosition,
  MenuSection,
  PositionIndex,
  PositionLabel,
  StyledIconWithTooltip,
} from './fixedMenu.styled';
import { FixedMenuProps } from './fixedMenu.types';

export const FixedMenu: React.FC<FixedMenuProps> = (props: FixedMenuProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    title,
    positions,
    isVisible = false,
    menuPositionWarning = t('rehau.global.verify_setup_settings'),
    onPositionClick = (): void => undefined,
    onClose = (): void => undefined,
    isSetupActive,
  }: FixedMenuProps = props;
  const [isVsisibleLocal, setIsVisibleLocal]: UseState<boolean> = useState<boolean>(isVisible);
  const middleIndex: number = Math.ceil(positions.length / 2);

  useEffect(
    (): void => {
      setIsVisibleLocal(isVisible);
    },
    [isVisible]
  );

  const handleClose: () => void = (): void => {
    setIsVisibleLocal(false);
    onClose();
  };

  const handlePositionClick: (index: number) => void = (index: number): void => {
    setIsVisibleLocal(false);
    onPositionClick(index);
  };

  return (
    <>
      <MenuContainer isVisible={isVsisibleLocal}>
        <FixedMenuInnerContainer>
          <HeaderSection>
            <Header>
              {t(title)}
              {isSetupActive ? (
                <ActiveLabel>{t('rehau.components.fixed_menu.active_label')}</ActiveLabel>
              ) : (
                <InProgressLabel>{t('rehau.components.fixed_menu.in_progress_label')}</InProgressLabel>
              )}
            </Header>
          </HeaderSection>
          <MenuSection>
            <MenuColumn>
              {positions.slice(0, middleIndex).map(
                (position: ExpandedStepsMenuPosition, index: number): JSX.Element => (
                  <MenuPosition key={position.name} onClick={(): void => handlePositionClick(index)}>
                    <PositionIndex>{index + 1}</PositionIndex>
                    <PositionLabel>{t(position.name)}</PositionLabel>
                    {position.isValid === false && (
                      <StyledIconWithTooltip
                        icon={IconName.ConflictBig}
                        tooltipContent={menuPositionWarning || ''}
                        backgroundColor={Color.Validation}
                      />
                    )}
                  </MenuPosition>
                )
              )}
            </MenuColumn>
            {positions.length > 1 && (
              <MenuColumn>
                {positions.slice(middleIndex).map(
                  (position: ExpandedStepsMenuPosition, index: number): JSX.Element => (
                    <MenuPosition key={position.name} onClick={(): void => handlePositionClick(index + middleIndex)}>
                      <PositionIndex>{index + middleIndex + 1}</PositionIndex>
                      <PositionLabel>{t(position.name)}</PositionLabel>
                      {position.isValid === false && (
                        <StyledIconWithTooltip
                          icon={IconName.ConflictBig}
                          tooltipContent={menuPositionWarning || ''}
                          backgroundColor={Color.Validation}
                        />
                      )}
                    </MenuPosition>
                  )
                )}
              </MenuColumn>
            )}
          </MenuSection>
        </FixedMenuInnerContainer>
        <CloseButton onClick={handleClose}>
          <Icon name={IconName.No} size={18} color={Color.Black} />
        </CloseButton>
      </MenuContainer>
      <Overlay isActive={isVsisibleLocal} onClick={handleClose} />
    </>
  );
};
