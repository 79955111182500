export enum StepTypeEnum {
  // AtHome
  HeatingAndCoolingStep = 'heating_and_cooling',
  HeatDistributionStep = 'heating_distribution',
  AutomationStep = 'automation',
  WaterDistributionStep = 'water_distribution',
  DrinkingWaterInstallationStep = 'drinking_water_installation',
  BathroomFittingsStep = 'bathroom_fitting',
  SewageStep = 'sewage',
  VentilationStep = 'ventilation',
  ResourceActionStep = 'resource_action',
  ReguardActionStep = 'reguard_action',
}
