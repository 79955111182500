import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { StyledComponent } from '../../../../types/styledComponent.types';
import { Color } from '../../../../enums/color.enum';
import { CircleItemsGrid } from '../circleItemsGrid/circleItemsGrid.component';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Price } from '../../../../theme';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { styleWhenTrue } from '../../../../utils/style.utils';
import { ElementsWithDash } from '../elementsWithDash/elementsWithDash.component';
import { OfferItemRowHeader } from '../offerItemRowHeader/offerItemRowHeader.component';
import { Markdown } from '../../../markdown/markdown.component';

import { DescriptionContentProps, DescriptionProps, RatingLabelProps, SimpleTypeProps } from './offerItemRow.types';

export const Container: StyledComponent<'div'> = styled.div`
  & + & {
    margin-top: 32px;
  }
`;

export const ItemTitleSection: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 30px;
  margin-top: -10px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${Color.GreyStroke};
`;

export const Name: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Bold};
  color: ${Color.Black};
`;

export const Type: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Bold};
  color: ${Color.CompanyColor};
  margin-left: 5px;

  @media ${Breakpoint.Mobile} {
    margin-left: 0;
  }
`;

export const NameWithType: StyledComponent<'div'> = styled.div`
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  line-height: 24px;
`;

export const RatingWithDescriptionSection: StyledComponent<'div'> = styled.div`
  display: flex;
  padding-left: 70px;
  margin-top: 8px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    padding-left: 0;
  }
`;

export const RatingSection: StyledComponent<'div'> = styled.div``;

export const RatingRow: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 7px;
  }

  @media ${Breakpoint.Mobile} {
    width: 100%;
    justify-content: space-between;
  }
`;

export const RatingLabel: StyledComponent<'div', RatingLabelProps> = styled.div<RatingLabelProps>`
  font-size: 14px;
  line-height: 17px;
  color: ${Color.Black};
  width: 135px;
  margin-right: 5px;

  ${styleWhenTrue(prop('withBiggerFonts'), css`
    font-size: 18px;
  `)}

  @media ${Breakpoint.Mobile} {
    width: auto;
    justify-content: space-between;
  }
`;

export const StyledCircleItemsGrid: StyledComponent<typeof CircleItemsGrid> = styled(CircleItemsGrid)`
  margin-top: 32px;
  max-width: 240px;
`;

export const Description: StyledComponent<'div', DescriptionProps> = styled.div<DescriptionProps>`
  width: 100%;
  padding-top: ${({ $withTopPadding }: DescriptionProps): number => $withTopPadding ? 32 : 0}px;
  ${RatingSection} + &,
  ${StyledCircleItemsGrid} + & {
    margin-left: 40px;
  }

  @media ${Breakpoint.Mobile} {
    ${RatingSection} + & {
      margin-left: 0;
      margin-top: 20px;
    }
  }
`;

export const SimpleType: StyledComponent<'div', SimpleTypeProps> = styled.div<SimpleTypeProps>`
  color: ${Color.Black};
  font-size: 14px;
  line-height: 20px;

  ${styleWhenTrue(prop('withBiggerFonts'), css`
    font-size: 18px;
  `)}
`;

export const DescriptionContent: StyledComponent<typeof Markdown, DescriptionContentProps> = styled(Markdown)<DescriptionContentProps>`
  color: ${Color.Grey};
  font-size: 14px;
  line-height: 22px;
  width: calc(100% - 120px);

  ${SimpleType} + & {
    margin-top: 10px;
  }

  strong {
    white-space: nowrap;
  }

  ${styleWhenTrue(prop('withBiggerFonts'), css`
    font-size: 19px;
    line-height: 25px;
  `)}

  @media ${Breakpoint.Mobile} {
    margin-top: 20px;
  }
`;

export const TooltipExternalContainer: StyledComponent<'div'> = styled.div`
  margin-top: 15px;
  margin-left: 27px;
  padding: 17px 0;
  border-top: 1px solid ${Color.GreyStroke};
  border-bottom: 1px solid ${Color.GreyStroke};
`;

export const StyledPrice: StyledComponent<typeof Price> = styled(Price)``;

export const LabelValueRow: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 19px;
  color: ${Color.Black};

  & + & {
    margin-top: 11px;
  }
`;

export const Label: StyledComponent<'div'> = styled.div``;

export const Value: StyledComponent<'div'> = styled.div``;

export const StyledOfferItemRowHeader: StyledComponent<typeof OfferItemRowHeader> = styled(OfferItemRowHeader)``;

export const StyledElementsWithDash: StyledComponent<typeof ElementsWithDash> = styled(ElementsWithDash)`
  ${StyledOfferItemRowHeader} + & {
    margin-top: 8px;

    @media ${Breakpoint.Mobile} {
      margin-top: 20px;
    }
  }

  /* stylelint-disable no-duplicate-selectors */
  ${RatingWithDescriptionSection} + & {
    margin-top: 20px;
  }
`;
