import React, { ChangeEvent, ChangeEventHandler, KeyboardEventHandler } from 'react';

import { KeyboardKey } from '../../../enums/keyboardKey.enum';
import { StyledInput, Wrapper } from '../input.styled';

import { IconWrapper, StyledIcon } from './standardInput.styled';
import { StandardInputProps } from './standardInput.types';

export const StandardInput: React.FC<StandardInputProps> = (props: StandardInputProps): JSX.Element => {
  const {
    icon,
    placeholder,
    onChange,
    onBlur,
    onSubmit,
    className,
    value,
    autocomplete,
    name,
    disabled,
    onFocus,
  }: StandardInputProps = props;

  const onKeyUp: KeyboardEventHandler = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === KeyboardKey.Enter && onSubmit) {
      onSubmit();
    }
  };

  const handleOnChange: ChangeEventHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <Wrapper className={className}>
      <IconWrapper>{!!icon && <StyledIcon name={icon} size={14} />}</IconWrapper>
      <StyledInput
        placeholder={placeholder}
        onChange={handleOnChange}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        value={value}
        autoComplete={autocomplete}
        name={name}
        disabled={disabled}
        onFocus={onFocus}
      />
    </Wrapper>
  );
};
