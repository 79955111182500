import { prop } from 'ramda';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { StyledComponent } from '../../types/styledComponent.types';
import { BoxShadow } from '../../enums/boxShadow.enum';
import { Color } from '../../enums/color.enum';
import { styleWhenTrue } from '../../utils/style.utils';
import { Breakpoint } from '../../enums/breakpoint.enum';

import { ButtonType } from './button.enums';
import { ArrowIconContainerProps, StyledButtonProps } from './button.types';

export const TextContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.2s linear;
  background-size: 222% 100%;
  background-position-x: 100%;
  padding: 10px 12px;
  font-size: 20px;
  line-height: 24px;
  transform-origin: 100% 100%;

  @media ${Breakpoint.Mobile} {
    font-size: 16px;
  }
`;

export const ArrowIconContainer: StyledComponent<'div', ArrowIconContainerProps> = styled.div<ArrowIconContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 44px;
  flex-shrink: 0;
  background-color: ${Color.Green};
  box-sizing: border-box;
  ${styleWhenTrue(
    prop('active'),
    css`
      border: 1px solid ${Color.Green};
      border-left: 0;
      background-color: ${Color.White};
    `
  )}
`;

export const ButtonStyles: {
  [ButtonType.Primary]: FlattenSimpleInterpolation;
  [ButtonType.Secondary]: FlattenSimpleInterpolation;
  [ButtonType.CallToAction]: FlattenSimpleInterpolation;
} = {
  [ButtonType.Primary]: css`
    ${TextContainer} {
      background-image: linear-gradient(to right, ${Color.Black}, ${Color.Black} 50%, ${Color.Green} 50%);
      background-color: ${Color.Green};
      color: ${Color.White};
    }
  `,

  [ButtonType.Secondary]: css`
    ${TextContainer} {
      background-image: linear-gradient(to right, ${Color.Black}, ${Color.Black} 50%, ${Color.Grey} 50%);
      background-color: ${Color.Grey};
      color: ${Color.White};
    }
    ${ArrowIconContainer} {
      background-color: ${Color.Grey};
    }
  `,

  [ButtonType.CallToAction]: css`
    box-shadow: none;
    ${TextContainer} {
      background: none;
      color: ${Color.Pink};
    }
    ${ArrowIconContainer} {
      background-color: ${Color.Transparent};
      border: 1px solid ${Color.Pink};
    }
  `,
};

export const StyledButton: StyledComponent<'button', StyledButtonProps> = styled.button<StyledButtonProps>`
  outline: none;
  border: none;
  padding: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  ${({ buttonType }: StyledButtonProps): FlattenSimpleInterpolation => ButtonStyles[buttonType]};

  @media ${Breakpoint.Hover} {
    :hover {
      ${TextContainer} {
        background-position-x: 0;
      }
    }
  }

  ${styleWhenTrue(
    prop('autoWidth'),
    css`
      width: auto;
      min-width: fit-content;
    `
  )}

  ${styleWhenTrue(
    prop('fluid'),
    css`
      width: 100%;
      max-width: none;
      text-align: left;
    `
  )}

  ${styleWhenTrue(
    prop('$loading'),
    css`
      width: 200px;
      height: 44px;
      border: 1px solid ${Color.GreyStroke};
    `
  )}

  ${styleWhenTrue(
    prop('active'),
    css`
      ${TextContainer} {
        background: ${Color.White};
        color: ${Color.Black};
        border: 1px solid ${Color.Green};
        padding: 9px 11px;
      }

      @media ${Breakpoint.Hover} {
        :hover {
          ${TextContainer} {
            color: ${Color.Black};
          }
        }
      }
    `
  )}

  ${({ minWidth }: StyledButtonProps): FlattenSimpleInterpolation | null =>
    !!minWidth
      ? css`
          width: ${minWidth}px;
          ${TextContainer} {
            text-align: left;
          }
        `
      : null}
`;
