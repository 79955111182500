export enum CalculationFieldEnum {
  // First step settings
  BuildingSize = 'building_size',
  HeatInstallation = 'heat_installation',
  HeatInstallationType = 'heat_installation_type',
  NumberOfFloors = 'number_of_floors',
  NumberOfResidents = 'number_of_residents',
  LocationLongitude = 'location_longitude',
  LocationLatitude = 'location_latitude',
  // Rooms
  Bathroom = 'bathroom',
  Wc = 'wc',
  Kitchen = 'kitchen',
  DiningRoom = 'dining_room',
  LivingRoom = 'living_room',
  Bedroom = 'bedroom',
  ChildRoom = 'child_room',
  GuestRoom = 'guest_room',
  Corridors = 'corridors',
  Office = 'office',
  StorageRoom = 'storage_room',
  DressingRoom = 'dressing_room',
  Garage = 'garage',
  // Sanitary
  Toilet = 'toilet',
  WashingToilet = 'washing_toilet',
  Urinal = 'urinal',
  Bath = 'bath',
  RainShower = 'rain_shower',
  Shower = 'shower',
  KitchenSink = 'kitchen_sink',
  SmallWashbasinColdWater = 'small_washbasin_cold_water',
  Washbasin = 'washbasin',
  Bidet = 'bidet',
  Dishwasher = 'dishwasher',
  GardenHose = 'gardenHose',
  WashingMachine = 'washing_machine',
  // Technologies: variants
  HeatDistribution = 'heat_distribution',
  RegulationSystem = 'regulation_system',
  DrinkingWaterDistribution = 'drinking_water_distribution',
  Resource = 'resource',
  Sewage = 'sewage',
  AirSystem = 'air_system',
  // Technologies: true/false
  SolarSystem = 'solar_system',
  Photovoltaic = 'photovoltaic',
  FreshWaterModule = 'fresh_water_module',
  DecalcificationModule = 'decalcification_module',
  CirculationInstallation = 'circulation_installation',
  // Customer details
  CustomerEmail = 'email',
  CustomerPhone = 'phone',
  PrivacyPolicyAgreement = 'dataAgreement',
  MarketingAgreement = 'marketingAgreement',
  PrivacyPolicyAgreementExtended = 'dataAgreementExtended',
  MarketingAgreementExtended = 'marketingAgreementExtended',
  // Order an offer details
  DateOfMontage = 'montageDate',
  ContactPreferredTime = 'preferredTime',
  // Hidden
  InstallerId = 'installer_id',
  InstallerName = 'installer_name',
  LocationLabel = 'location_label',
  // Artificially created
  CurrentStep = 'currentStep',
  // Insurance Agent
  InsuranceAgentCode = 'insuranceAgentCode',
  // ReGuard
  ExactAddress = 'exactAddress',
  Montage = 'montage',
  Reguard = 'reguard',
  ReguardSensorsActive = 'reguard_sensors_active',
  ReguardSensorsCount = 'reguard_sensors_count',
  // Products
  SerialNumber = 'serialNumber',
}
