import { QueryClient, QueryClientProvider } from 'react-query';
import React, { useEffect, useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';

import { AppConfigContext } from '../../contexts/appConfig.context';
import { CountryConfigContext } from '../../contexts/countryConfig.context';
import { RoutesContext } from '../../contexts/routes.context';
import { RouteInfo } from '@rehau/shared/models';
import { getRouteDetailsByUrl } from '@rehau/shared/utils';

import { CommonProvidersWrapperProps } from './commonProvidersWrapper.types';

export const CommonProvidersWrapper: React.FC<CommonProvidersWrapperProps> = (props: CommonProvidersWrapperProps): JSX.Element => {
  const { t, i18n }: TransProps<never> = useTranslation();
  const { countryConfig, routesArray, appConfig, children }: CommonProvidersWrapperProps = props;
  const location: Location = useLocation();
  const queryClient: QueryClient = useMemo(
    (): QueryClient => new QueryClient({
      defaultOptions: { queries: { refetchOnWindowFocus: false  } },
    }),
    []
  );

  useEffect(
    (): void => {
      const pathObject: RouteInfo | undefined = getRouteDetailsByUrl(location.pathname, routesArray);
      if (pathObject && i18n.isInitialized) {
        document.title = t(pathObject.title ?? '');
      }
    },
    [location.pathname, routesArray, i18n.isInitialized, i18n.language]
  );

  return (
    <QueryClientProvider client={queryClient}>
      <CountryConfigContext.Provider value={countryConfig}>
        <RoutesContext.Provider value={routesArray}>
          <AppConfigContext.Provider value={appConfig}>
            {children}
          </AppConfigContext.Provider>
        </RoutesContext.Provider>
      </CountryConfigContext.Provider>
    </QueryClientProvider>
  );
};
