import { FlattenSimpleInterpolation } from 'styled-components';
import { always, Arity1Fn, equals, ifElse } from 'ramda';

type StyleWhenTrue = (
  // tslint:disable-next-line: no-any
  pred: (b: any) => boolean,
  style: FlattenSimpleInterpolation
) => Arity1Fn;

type StyleIfElse = (
  // tslint:disable-next-line: no-any
  pred: (b: any) => boolean,
  styleIfTrue: FlattenSimpleInterpolation | string,
  styleIfFalse: FlattenSimpleInterpolation | string
) => Arity1Fn;

export const renderWhen: (pred: (b: true) => boolean, fn: Arity1Fn, otherwise?: Arity1Fn) => Arity1Fn = (
  pred: (b: true) => boolean,
  fn: Arity1Fn,
  otherwise: Arity1Fn = always(null)
): Arity1Fn => ifElse(pred, fn, otherwise);

export const styleWhenTrue: StyleWhenTrue = (
  // tslint:disable-next-line: no-any
  pred: (b: any) => boolean,
  style: FlattenSimpleInterpolation
  // tslint:disable-next-line: no-any
): Arity1Fn => (props: any): Arity1Fn => renderWhen(equals(true), always(style))(pred(props));

export const styleIfElse: StyleIfElse = (
  // tslint:disable-next-line: no-any
  pred: (b: any) => boolean,
  styleIfTrue: FlattenSimpleInterpolation | string,
  styleIfFalse: FlattenSimpleInterpolation | string
  // tslint:disable-next-line: no-any
): Arity1Fn => (props: any): Arity1Fn => renderWhen(equals(true), always(styleIfTrue), always(styleIfFalse))(pred(props));
