import styled from 'styled-components';

import { StyledComponent } from '../types/styledComponent.types';

export const PhotoOverlay: StyledComponent<'div'> = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(transparent, rgba(0,0,0, 0.4));
`;
