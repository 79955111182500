import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { InputController } from '../../input/inputController.component';
import { Textarea } from '../../input/textarea/textarea.component';
import { TextAreaField } from '../../../../../../../shared/forms/elements';
import { FieldElement, FieldElementProps } from '../types';
import { ContainerType } from '../../input/inputContainer/containerType.enum';
import { Autocomplete } from '@rehau/shared/enums';

export const TextareaFieldElement: FieldElement<TextAreaField> = (props: FieldElementProps<TextAreaField>): JSX.Element => {
  const { component, save, setValues }: FieldElementProps<TextAreaField> = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <>
      <InputController
        label={t(component.label || '')}
        additionalInfoContent={t(component.informationBox?.content || '')}
        additionalInfoContentInReadMore={t(component.informationBox?.readMoreContent || '')}
        tooltipProps={component.tooltip}
        containerType={ContainerType.Big}
        component={component}
        handleSave={save}
        setValues={setValues}
        disabled={component.onlyForRead || component.disabled}
        hasLettersQuantityCounter
      >
        <Textarea placeholder={t(component.placeholder || '')} autocomplete={Autocomplete.Off} />
      </InputController>
    </>
  );
};
