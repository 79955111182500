import styled, { css, StyledComponent } from 'styled-components';
import { prop } from 'ramda';

import { BoxShadow } from '../../enums/boxShadow.enum';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { ZIndex } from '../../enums/zIndex.enum';
import { InnerContainer, OuterContainer } from '../../theme/containers.styled';
import { styleWhenTrue } from '../../utils/style.utils';
import { HeaderHeight } from '../../enums/headerHeight.enum';

import { NavbarItem } from './components/navbarItem/navbarItem.component';
import { NavbarProps } from './mainNavbar.types';

export const TopPadding: StyledComponent<'div', {}, {}, never> = styled.div`
  height: ${HeaderHeight.AboveDesktop};

  @media ${Breakpoint.Desktop} {
    height: ${HeaderHeight.Desktop};
  }
`;

export const Navbar: StyledComponent<'div', {}, NavbarProps, never> = styled.div`
  box-shadow: ${BoxShadow.BtnGreyShadow};
  width: 100%;
  z-index: ${ZIndex.MainNavbar};
  transition: 0.3s;

  ${styleWhenTrue(prop('fixed'), css`
    position: fixed;
    top: -${HeaderHeight.AboveDesktop};
    left: 0;

    @media ${Breakpoint.Tablet} {
      top: -${HeaderHeight.Desktop};
    }
  `)}

  ${styleWhenTrue(prop('visible'), css`
    top: 0;

    @media ${Breakpoint.Tablet} {
      top: 0;
    }
  `)}
`;

export const Container: StyledComponent<typeof OuterContainer, {}, {}, never> = styled(OuterContainer)`
  padding: 0 20px 0 15px;
  @media ${Breakpoint.Tablet} {
    margin: 0;
    width: 100%;
  }
`;

export const Wrapper: StyledComponent<typeof InnerContainer, {}, {}, never> = styled(InnerContainer)`
  height: ${HeaderHeight.AboveDesktop};
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${Breakpoint.Desktop} {
    height: ${HeaderHeight.Desktop};
  }
`;

export const Items: StyledComponent<'div', {}, {}, never> = styled.div`
  display: flex;
  align-items: center;
  @media ${Breakpoint.Tablet} {
    display: none;
  }
`;

export const Item: StyledComponent<typeof NavbarItem, {}, {}, never> = styled(NavbarItem)`
  & + & {
    margin-left: 40px;
  }
`;

export const MenuButton: StyledComponent<'button', {}, {}, never> = styled.button`
  display: none;
  background-color: ${Color.Transparent};
  margin-right: -6px;
  @media ${Breakpoint.Tablet} {
    display: inline-block;
  }
`;
