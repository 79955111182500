import React from 'react';

import { Color } from '../../enums/color.enum';
import { Icon } from '../icon/icon.component';

import { Container, IconContainer, TooltipArrow, TooltipContainer, TooltipContent } from './iconWithTooltip.styled';
import { IconWithTooltipProps } from './iconWithTooltip.types';

export const IconWithTooltip: React.FC<IconWithTooltipProps> = (props: IconWithTooltipProps): JSX.Element => {
  const {
    icon,
    tooltipContent,
    iconSize = 12,
    iconColor = Color.Black,
    backgroundColor = Color.Transparent,
    className,
  }: IconWithTooltipProps = props;

  return (
    <Container className={className}>
      <IconContainer backgroundColor={backgroundColor} iconSize={iconSize}>
        <Icon name={icon} size={iconSize} color={iconColor} />
      </IconContainer>
      <TooltipContainer>
          <TooltipArrow />
          <TooltipContent>{tooltipContent}</TooltipContent>
        </TooltipContainer>
    </Container>
  );
};
