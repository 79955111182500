import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { MenuPositions, PathNameMenuPositionTuple, RouteInfo } from '../../../../../../shared/models/countryConfig';
import { CalculationTypeEnum, ExternalPathNameEnum, IconName, WebsitePathNameEnum } from '../../../../../../shared/enums';
import { findPathByName } from '../../utils/findPathByName.utils';
import { useRoutesInfo } from '../../hooks/useRoutesInfo.hook';
import { useCountryConfig } from '../../hooks/useCountryConfig.hook';
import { UseState } from '../../types/useState.types';
import { Logo } from '../logo/logo.component';
import { Icon } from '../icon/icon.component';
import { sortItems } from '../../utils/menuPositions/menuPositions.utils';
import { UseCountryConfig } from '../../types/useCountryConfig.types';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { CustomLink } from '../customLink/customLink.component';
import { MobileMenu } from '../mobileMenu/mobileMenu/mobileMenu.component';
import { AppConfigType } from '../../types/appConfigType.types';
import { useAppConfig } from '../../hooks/useAppConfig.hook';

import {
  Container,
  Item,
  Items,
  MenuButton,
  Navbar,
  TopPadding,
  Wrapper,
} from './mainNavbar.styled';
import { MainNavbarProps } from './mainNavbar.types';

export const MainNavbar: React.FC<MainNavbarProps> = (props: MainNavbarProps): JSX.Element => {
  const { className, fixed = false, hiddenByTabs = false }: MainNavbarProps = props;
  const { t }: TransProps<never> = useTranslation();
  const routesArray: RouteInfo[] = useRoutesInfo();
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();
  const menuConfig: MenuPositions | null = isConfigAvailable ? config.menuConfigs.mainMenu : null;
  const items: PathNameMenuPositionTuple[] = !!menuConfig ? Object.entries(menuConfig).sort(sortItems) : [];
  const mainPath: string = findPathByName(WebsitePathNameEnum.Default, routesArray);
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const [mobileMenuOpen, setMobileMenuOpen]: UseState<boolean> = useState<boolean>(false);
  const [visible, setVisible]: UseState<boolean> = useState<boolean>(true);
  const [prevScrollPosition, setPrevScrollPosition]: UseState<number> = useState<number>(0);
  const appConfig: AppConfigType = useAppConfig();

  const handleScroll: () => void = (): void => {
    const currentScrollPosition: number = window.pageYOffset;
    setVisible(
      (prevScrollPosition > currentScrollPosition)
      || currentScrollPosition < 200
    );
    setPrevScrollPosition(currentScrollPosition);
  };

  useEffect(
    (): () => void => {
      window.addEventListener('scroll', handleScroll);

      return (): void => window.removeEventListener('scroll', handleScroll);
    },
    [prevScrollPosition, visible, handleScroll]
  );

  const toggleMobileMenu: () => void = (): void => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <>
      <TopPadding />
      <Navbar
        className={className}
        data-testid='main-navbar'
        fixed={fixed}
        visible={(isTablet && mobileMenuOpen) || (visible && !hiddenByTabs)}
      >
        <Container>
          <Wrapper>
            <CustomLink to={mainPath} pathName={WebsitePathNameEnum.Default}>
              <Logo full />
            </CustomLink>
            <Items>
              {items.map(([name, data]: PathNameMenuPositionTuple): JSX.Element | false => (
                !!data && (
                  <Item
                    key={name}
                    label={t(data.title)}
                    path={name === ExternalPathNameEnum.OfferCreator
                      ? `${appConfig.creatorUrl}/${CalculationTypeEnum.Reguard}`
                      : findPathByName(name, routesArray)
                    }
                    pathName={name === ExternalPathNameEnum.OfferCreator
                      ? undefined
                      : name as WebsitePathNameEnum | ExternalPathNameEnum
                    }
                    groups={data.groups}
                    modalButtonLabel={t('rehau.main_menu.calculator')}
                    modalButtonPathName={WebsitePathNameEnum.Calculator}
                  />
                )
              ))}
            </Items>
            <MenuButton data-testid='menu-button' onClick={toggleMobileMenu}>
              {mobileMenuOpen ? (
                <Icon name={IconName.No} size={18} />
              ) : (
                <Icon name={IconName.Hamburger} size={16} />
              )}
            </MenuButton>
          </Wrapper>
        </Container>
      </Navbar>
      <MobileMenu open={mobileMenuOpen} items={items} onChoose={toggleMobileMenu} />
    </>
  );
};
