import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';
import { FontWeight } from '../../enums/fontWeight.enum';

import { HeaderProps, WrapperProps } from './advantageSection.types';

export const Wrapper: StyledComponent<'div', WrapperProps> = styled.div`
  margin-top: 80px;
  margin-bottom: 60px;

  ${styleWhenTrue(
      prop('hasSmallerMarginBottomWrapper'),
      css`
        margin-bottom: 40px;
      `
    )}

  @media ${Breakpoint.Additional1280} {
    ${styleWhenTrue(
      prop('hasBiggerPaddingWrapper'),
      css`
        padding: 0 40px;
      `
    )}
  }

  @media ${Breakpoint.Tablet} {
    padding: 0 40px;
  }

  @media ${Breakpoint.Additional480} {
    padding: 0;
    margin-top: 60px;

    ${styleWhenTrue(
      prop('hasSmallerMarginBottomWrapper'),
      css`
        margin-bottom: 25px;
      `
    )}
  }
`;

export const HeaderContainer: StyledComponent<'div', HeaderProps> = styled.div`
  width: 100%;
  margin-bottom: 25px;

  @media ${Breakpoint.Mobile} {
    margin-bottom: 40px;

    ${styleWhenTrue(
      prop('hasHeaderSmallerMargin'),
      css`
        margin-bottom: 20px;
      `
    )}
  }

  @media ${Breakpoint.Additional480} {
    padding-left: 20px;
  }
`;

export const Header: StyledComponent<'h2'> = styled.h2`
  font-size: 28px;
  line-height: 33px;
  font-weight: ${FontWeight.Black};
  width: 100%;
  word-break: break-word;
  box-sizing: border-box;

  @media ${Breakpoint.Mobile} {
    text-align: left;
    line-height: 32px;
    width: calc(100% - 30px);
    padding-right: 10px;
  }
`;

export const ElementsSection: StyledComponent<'div'> = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 2fr));
  gap: 20px;

  @media ${Breakpoint.Tablet} {
    grid-template-columns: auto auto;
  }

  @media ${Breakpoint.Mobile} {
    grid-template-columns: auto;
  }
`;
