import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { BoxWrapper, Label, Wrapper } from '../select.styled';

import { MultiSelectBoxProps } from './multiSelectBox.types';

export const MultiSelectBox: React.FC<MultiSelectBoxProps> = (props: MultiSelectBoxProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { label, children }: MultiSelectBoxProps = props;

  return (
    <Wrapper>
      {!!label && <Label>{t(label)}</Label>}
      <BoxWrapper>
        {children}
      </BoxWrapper>
    </Wrapper>
  );
};
