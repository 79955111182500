import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';

import { IconName } from '@rehau/shared/enums';
import { ValidationStatus } from '../../enums/validationStatus.enum';
import { Color } from '../../enums/color.enum';
import { UseState } from '../../types/useState.types';
import { useEffectAfterMount } from '../../hooks/useEffectAfterMount';
import { DateField } from '@rehau/shared/forms/elements';
import { UseCountryConfig } from '../../types/useCountryConfig.types';
import { useCountryConfig } from '../../hooks/useCountryConfig.hook';
import { Icon } from '../icon/icon.component';

import { DatePickerContainer, StyledInputContainer, StyledPicker } from './datePicker.styled';
import { DatePickerProps } from './datePicker.types';
import './datePicker.css';
import { loadLocale } from './datePicker.utils';

export const DatePicker: React.FC<DatePickerProps> = (props: DatePickerProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    id,
    onChoose,
    initialDate,
    label,
    disabled,
    placeholder = t('rehau.components.date_picker.placeholder'),
    component = new DateField('', '', '', '', true),
    setValues = (): void => undefined,
    className,
    minDate,
    maxDate,
    validationStatus,
    inputPadding,
  }: DatePickerProps = props;
  const [date, setDate]: UseState<Date | null> = useState(initialDate ? new Date(initialDate) : null);
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();

  useEffect(
    (): void => {
      if (isConfigAvailable) {
        loadLocale(config.dateLocale);
      }
    },
    [isConfigAvailable]
  );

  const handleDateChange: (currentDate: Date) => void = (currentDate: Date): void => {
    setDate(currentDate);
  };

  useEffectAfterMount(
    (): void => {
      if (onChoose && date) {
        onChoose(date?.getTime());
        setValues({ [component.id]: date?.getTime() });
      }
    },
    [date]
  );

  return (
    <StyledInputContainer
      validationStatus={validationStatus || (date && !disabled ? ValidationStatus.Valid : ValidationStatus.Untouched)}
      label={label}
      disabled={disabled}
      className={className}
      inputPadding={inputPadding}
    >
      <DatePickerContainer>
        <StyledPicker
          selected={date}
          onChange={handleDateChange}
          dateFormat='dd.MM.yyyy'
          minDate={minDate}
          maxDate={maxDate}
          popperPlacement='top'
          locale={config.dateLocale}
          placeholderText={placeholder}
        />
        <Icon name={IconName.Calendar} color={disabled ? Color.Grey : Color.Green} size={16} />
      </DatePickerContainer>
    </StyledInputContainer>
  );
};
