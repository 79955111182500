import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { IconName } from '../../../../../../shared/enums';
import { Color } from '../../enums/color.enum';
import { CircleLoader } from '../circleLoader/circleLoader.component';
import { ButtonWithIcon } from '../buttonWithIcon/buttonWithIcon.component';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { componentTypes } from '@rehau/shared/forms';
import { CalculationStep } from '@rehau/shared/forms/components';

import { StepProps } from './step.types';
import {
  ArrowContainer,
  BoxShadowContainer,
  ButtonsContainer,
  Container,
  Content,
  ControlsContainer,
  Counter,
  GoBackButtonContainer,
  HeaderContainer,
  InnerButtonsContainer,
  InterfaceContainer,
  NextButtonContainer,
  StepBreadcrumbs,
  StepNumber,
  StepWithIcon,
  StyledAdditionalInfo,
  StyledBreadcrumbIcon,
  StyledButton,
  StyledButtonWithIcon,
  StyledIcon,
  Subtitle,
  Title,
  TitleContainer,
} from './step.styled';
import { StepsTopNavigationTheme } from './step.enums';

export const Step: React.FC<StepProps> = (props: StepProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const isMobile480: boolean = useMediaQuery({ query: Breakpoint.Additional480 });
  const {
    title,
    subtitle,
    children,
    maxCount,
    count = 1,
    stepNames,
    stepNumber,
    handlePreviousClick = (): void => undefined,
    handleNextClick = (): void => undefined,
    handleAdditionalLeftButtonClick,
    previousButtonLabel = t('rehau.global.back'),
    nextButtonLabel = t('rehau.global.next'),
    additionalLeftButtonLabel,
    buttonsDisabled = false,
    withBottomPadding = true,
    loading = false,
    withSmallTopPadding = false,
    shouldShowNextButton = false,
    shouldShowPreviousButton = false,
    isCalculatorStep = false,
    stepsTopNavigationTheme = StepsTopNavigationTheme.WithArrows,
    additionalInfoContent,
    additionalInfoContentInReadMore,
    bottomMessageComponent,
    buttons,
    className,
    onStepNumberClick = (_: number): void => undefined,
    calculationElements,
    backButton,
  }: StepProps = props;
  const invalidElementsNames: string[] = calculationElements
    ?.filter((item: componentTypes): boolean => !item.valid)
    .map((element: componentTypes): string => (element as CalculationStep).stepName ?? '') ?? [];

  const localHandleNextClick: () => void = (): void => {
    if (!!handleNextClick) {
      handleNextClick();
    }
  };

  return (
    <Container withBottomPadding={withBottomPadding} className={className}>
      {!!backButton && (
        <StyledButtonWithIcon
          text={backButton.text}
          icon={backButton.icon}
          onClick={backButton.onClick}
          reversed
        />
      )}
      {(!!maxCount || !!title || !!subtitle) && (
        <HeaderContainer>
          {stepsTopNavigationTheme === StepsTopNavigationTheme.WithArrows && !!maxCount && maxCount > 1 && (
            <ControlsContainer>
              <ArrowContainer onClick={handlePreviousClick} disabled={count === 1}>
                <StyledIcon name={IconName.ArrowLeft} size={16} color={Color.Black} />
              </ArrowContainer>
              <Counter>{count}</Counter>
              <ArrowContainer onClick={localHandleNextClick} disabled={count === maxCount}>
                <StyledIcon name={IconName.ArrowRight} size={16} color={Color.Black} />
              </ArrowContainer>
            </ControlsContainer>
          )}
          <TitleContainer>
            {stepsTopNavigationTheme === StepsTopNavigationTheme.WithBreadcrumbs && !!stepNames && (
              <StepBreadcrumbs>
                {stepNames.map((name: string, index: number): JSX.Element => (
                  <StepWithIcon key={index}>
                    <StepNumber
                      isActive={stepNumber === index}
                      isBlocked={stepNumber !== undefined
                        && (invalidElementsNames.includes(name)
                        && (stepNumber !== index)
                        && (index > stepNumber + 1)
                        && (invalidElementsNames.includes(stepNames[index - 1]))
                      )}
                      onClick={(): void => onStepNumberClick(index)}
                    >
                      {t(name)}
                    </StepNumber>
                    {index !== stepNames.length - 1 && <StyledBreadcrumbIcon name={IconName.ArrowRight} size={8} />}
                  </StepWithIcon>
                ))}
              </StepBreadcrumbs>
            )}
            {!!title && (
              <Title
                hasTopPadding={stepsTopNavigationTheme === StepsTopNavigationTheme.None}
                data-testid='step-title'
              >
                {t(title)}
              </Title>
            )}
            {!!subtitle && <Subtitle data-testid='step-subtitle'>{t(subtitle || '')}</Subtitle>}
          </TitleContainer>
        </HeaderContainer>
      )}
      {!!additionalInfoContent && (
        <StyledAdditionalInfo content={t(additionalInfoContent)} contentInReadMore={t(additionalInfoContentInReadMore || '')} />
      )}
      <BoxShadowContainer>
        <Content withSmallTopPadding={withSmallTopPadding} withTopBorder={!additionalInfoContent} withSmallPadding={isCalculatorStep}>
          {!!children ? children : <CircleLoader color={Color.Black} />}
        </Content>

        {!buttonsDisabled && (
          <ButtonsContainer>
            <InterfaceContainer hasTopPadding={!!bottomMessageComponent}>
              {bottomMessageComponent}
              <InnerButtonsContainer>
                {buttons ? (
                  buttons
                ) : (
                  <>
                    <GoBackButtonContainer>
                      {!!handleAdditionalLeftButtonClick && !!additionalLeftButtonLabel && (
                        <ButtonWithIcon
                          icon={IconName.Mail}
                          onClick={handleAdditionalLeftButtonClick}
                          text={t(additionalLeftButtonLabel || '')}
                          data-testid='additional-left-button'
                          reversed
                        />
                      )}
                      {(count > 1 || shouldShowPreviousButton) && (
                        <ButtonWithIcon
                          icon={IconName.Back}
                          onClick={handlePreviousClick}
                          text={t(previousButtonLabel || '')}
                          data-testid='back-button'
                          reversed
                        />
                      )}
                    </GoBackButtonContainer>
                    <NextButtonContainer>
                      {(count !== maxCount || shouldShowNextButton) && (
                        <StyledButton
                          fluid={isMobile480}
                          onClick={localHandleNextClick}
                          text={t(nextButtonLabel || '')}
                          data-testid='next-button'
                          loading={loading}
                        />
                      )}
                    </NextButtonContainer>
                  </>
                )}
              </InnerButtonsContainer>
            </InterfaceContainer>
          </ButtonsContainer>
        )}
      </BoxShadowContainer>
    </Container>
  );
};
