import { MutableRefObject, useEffect, useRef } from 'react';

// tslint:disable:no-any
export const useEventListener: (eventName: string, handler: (e: any) => void, dependencies?: any[], element?: any) => void = (
  eventName: string,
  handler: (e: any) => void,
  dependencies: any = [],
  element: any = window
): void => {
  const savedHandler: MutableRefObject<any> = useRef(null);
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(
    (): void => {
      savedHandler.current = handler;
    },
    [handler]
  );

  useEffect(
    (): (() => void) | void => {
      const isSupported: boolean = element && element.addEventListener;
      if (!isSupported) {
        return;
      }
      const eventListener: any = (event: any): any => savedHandler.current(event);

      element.addEventListener(eventName, eventListener);

      return (): void => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element, ...dependencies]
  );
};
