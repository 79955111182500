export enum RegistrationsStatisticTypeEnum {
  CompanyVerified = 'company_verified',
  NotActivatedSetup = 'not_activated_setup',
  ActiveSetup = 'active_setup',
  DeactivatedSetup = 'deactivated_setup',
  CompanyBlocked = 'company_blocked',
  CompanyUnblocked = 'company_unblocked',
  CompanyDeleted = 'company_deleted',
  NotVerified = 'not_verified',
}
