import React from 'react';

import { Container, Image } from './simpleImage.styled';
import { SimpleImageProps } from './simpleImage.types';

export const SimpleImage: React.FC<SimpleImageProps> = (props: SimpleImageProps): JSX.Element => {
  const { imagePath }: SimpleImageProps = props;

  return (
    <Container>
      <Image src={imagePath} />
    </Container>
  );
};
