import { Dispatch } from 'react';

import { SlideButton } from '../slides.enums';
import { SlidesValues } from '../slide/slide.types';
import { SlidesActions } from '../enums/slidesActions.enum';
import { GetValues } from '../types/useSlides.types';
import { SlideAction } from '../types/slidesReducer.types';
import { ObjectIteratorInterface } from '../../../../../../../shared/objectIterator.interface';

export const setSlidesPositions: (
  dispatch: Dispatch<SlideAction>,
  slideButton: SlideButton,
  position: number,
  setValues: (data: ObjectIteratorInterface) => void,
  maxMeters: number
) => void = (
  dispatch: Dispatch<SlideAction>,
  slideButton: SlideButton,
  position: number,
  setValues: (data: ObjectIteratorInterface) => void,
  maxMeters: number
): void => {
  switch (slideButton) {
    case SlideButton.S:
      setValues({ houseSizeS: Math.round((maxMeters * position) / 100) });
      dispatch({ type: SlidesActions.SetSleftPosition, payload: position });
      break;
    case SlideButton.Sm:
      setValues({ houseSizeSM: Math.round((maxMeters * position) / 100) });
      dispatch({ type: SlidesActions.SetSmPositions, payload: position });
      break;
    case SlideButton.Ml:
      setValues({ houseSizeML: Math.round((maxMeters * position) / 100) });
      dispatch({ type: SlidesActions.SetMlPositions, payload: position });
      break;
    case SlideButton.L:
      setValues({ houseSizeL: Math.round((maxMeters * position) / 100) });
      dispatch({ type: SlidesActions.SetLRightPosition, payload: position });
      break;
  }
};

export const getValues: GetValues = (
  sPosition: number,
  smPosition: number,
  mlPosition: number,
  lPosition: number,
  maxMeters: number
): SlidesValues => {
  const fixedSmPosition: number = Math.round((maxMeters * smPosition) / 100);
  const fixedMlPosition: number = Math.round((maxMeters * mlPosition) / 100);
  const fixedSPosition: number = Math.round((maxMeters * sPosition) / 100);
  const fixedLPosition: number = Math.round((maxMeters * lPosition) / 100);

  return {
    s: {
      left: getWidth(fixedSPosition, maxMeters - 2, 0),
      right: getWidth(fixedSmPosition - 1, maxMeters - 2, 0),
    },
    m: {
      left: getWidth(fixedSmPosition, maxMeters - 1, 1),
      right: getWidth(fixedMlPosition - 1, maxMeters - 1, 1),
    },
    l: {
      left: getWidth(fixedMlPosition, maxMeters, 2),
      right: getWidth(fixedLPosition, maxMeters, 2),
    },
    mlFixed: fixedMlPosition,
  };
};

const getWidth: (width: number, maxWidth: number, minWidth: number) => number = (
  width: number,
  maxWidth: number,
  minWidth: number
): number => {
  if (width <= minWidth) {
    return minWidth;
  }

  if (maxWidth <= width) {
    return maxWidth;
  }

  return width;
};
