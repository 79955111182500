import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Color } from '../../../../enums/color.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { InnerContainer, OuterContainer } from '../../../../theme/containers.styled';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { styleIfElse, styleWhenTrue } from '../../../../utils/style.utils';
import { Button as ButtonBase } from '../../../button/button.component';
import { Title as TitleBase } from '../title/title.component';
import { Markdown } from '../../../markdown/markdown.component';

import {
  ButtonProps,
  ContainerProps,
  ContentProps,
  HintImageProps,
  ImageContainerProps,
  ImageProps,
  MarkdownProps,
  SectionBodyProps,
} from './textWithImage.types';

export const Container: StyledComponent<typeof OuterContainer, ContainerProps> = styled(OuterContainer)`
  padding-top: ${styleIfElse(prop('withoutTopPadding'), '0', '80px')};
  padding-bottom: 100px;

  @media ${Breakpoint.Tablet} {
    margin: 0;
    width: 100%;
    padding: ${styleIfElse(prop('withoutTopPadding'), '0', '60px')} 40px 60px;
  }

  @media ${Breakpoint.Mobile} {
    padding: ${styleIfElse(prop('withoutTopPadding'), '0', '60px')} 20px ${styleIfElse(prop('reversed'), '60px', '0')};
    margin-bottom: ${styleIfElse(prop('reversed'), '0', '60px')};
  }

  ${styleWhenTrue(prop('darkBackground'), css`
    background-color: ${Color.BgGrey};
  `)}
`;

export const Wrapper: StyledComponent<typeof InnerContainer> = styled(InnerContainer)`
  flex-direction: column;
`;

export const SectionTitle: StyledComponent<typeof TitleBase> = styled(TitleBase)`
  margin-bottom: 40px;
`;

export const SectionBody: StyledComponent<'div', SectionBodyProps> = styled.div`
  display: flex;
  flex-direction: ${styleIfElse(prop('reversed'), 'row-reverse', 'row')};
  justify-content: space-between;
  overflow: hidden;

  @media ${Breakpoint.Tablet} {
    flex-direction: ${styleIfElse(prop('imageOnTop'), 'column-reverse', 'column')};
  }
`;

export const Content: StyledComponent<'div', ContentProps> = styled.div`
  flex: 1;
  margin-right: ${styleIfElse(prop('reversed'), '0', '80px')};
  display: flex;
  flex-direction: column;
  min-width: 380px;

  ${styleWhenTrue(prop('reversed'), css`
    flex: 1.55;
  `)}

  ${styleWhenTrue(prop('wideImage'), css`
    flex: 1;
  `)}

  ${styleWhenTrue(prop('hasWideText'), css`
    flex: 2;

    @media ${Breakpoint.Desktop} {
      max-width: 540px;
    }
  `)}

  @media ${Breakpoint.Tablet} {
    margin-right: 0;
    min-width: 0;
    max-width: none;
  }
`;

export const Title: StyledComponent<typeof TitleBase> = styled(TitleBase)`
  max-width: 580px;

  @media ${Breakpoint.Tablet} {
    max-width: none;
  }
`;

export const StyledMarkdown: StyledComponent<typeof Markdown, MarkdownProps> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;

  ${styleWhenTrue(prop('hasWideText'), css`
    max-width: 675px;
  `)}

  @media ${Breakpoint.Tablet} {
    margin-bottom: 40px;
    max-width: none;
  }

  strong {
    font-weight: ${FontWeight.Bold};
  }

  ul {
    margin-top: 25px;

    li {
      :before {
        content: '- ';
      }
    }
  }
`;

export const ImageContainer: StyledComponent<'div', ImageContainerProps> = styled.div`
  flex: 1.55;
  overflow: hidden;

  ${styleWhenTrue(prop('reversed'), css`
    flex: 1;
    margin-right: 80px;
  `)}

  ${styleWhenTrue(prop('wideImage'), css`
    flex: 1.55;
  `)}

  ${styleWhenTrue(prop('hasWideText'), css`
    flex: 1;
    max-width: 330px;
  `)}

  @media ${Breakpoint.Tablet} {
    margin-right: 0;
    display: flex;
    flex-direction: column-reverse;

    ${styleWhenTrue(prop('hasWideText'), css`
      max-width: 50%;
    `)}

    ${styleWhenTrue(prop('imageOnTop'), css`
      margin-bottom: 55px;
    `)}
  }

  @media ${Breakpoint.Mobile} {
    margin: 0 -20px;

    ${styleWhenTrue(prop('reversed'), css`
      margin: 0;
    `)}

    ${styleWhenTrue(prop('hasWideText'), css`
      margin: 0 0 60px;
      width: auto;
    `)}

    ${styleWhenTrue(prop('imageOnTop'), css`
      margin-bottom: 55px;
    `)}
  }
`;

export const ImageBox: StyledComponent<'div'> = styled.div`
  width: 100%;
`;

export const Image: StyledComponent<'img', ImageProps> = styled.img`
  width: 100%;

  & + & {
    margin-top: 25px;
  }

  @media ${Breakpoint.Tablet} {
    width: ${styleIfElse(prop('reversed'), 'auto', '100%')};
    max-width: ${styleIfElse(prop('reversed'), '100%', 'auto')};

    & + & {
      margin-top: 0;
      margin-bottom: 12px;
    }
  }
`;

export const Link: StyledComponent<'a'> = styled.a`
  text-decoration: none;
  margin-top: 30px;

  & + & {
    margin-top: 15px;
  }

  @media ${Breakpoint.Tablet} {
    margin-top: -10px;

    :last-of-type {
      margin-bottom: 35px;
    }
  }
`;

export const Button: StyledComponent<typeof ButtonBase, ButtonProps> = styled(ButtonBase)`
  ${styleWhenTrue(prop('darkBackground'), css`
    background-color: ${Color.Black};
  `)}
`;

export const Hint: StyledComponent<'div'> = styled.div`
  display: flex;
  margin-top: 70px;

  @media ${Breakpoint.Tablet} {
    margin-top: 0;
    margin-bottom: 40px;
  }

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    max-width: 220px;
  }
`;

export const HintImage: StyledComponent<'img', HintImageProps> = styled.img`
  margin-right: 40px;

  ${styleWhenTrue(prop('withSecondImage'), css`
    max-width: calc(50% - 40px);

    @media ${Breakpoint.Mobile} {
      max-width: 100%;
    }
  `)}
`;

export const HintText: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: ${FontWeight.Bold};
  margin-top: 80px;
  max-width: 170px;

  @media ${Breakpoint.Mobile} {
    margin-top: 15px;
  }
`;

export const TextImage: StyledComponent<'img'> = styled.img`
  margin-top: 15px;
  margin-bottom: 38px;
  max-height: 180px;
  max-width: 320px;
`;
