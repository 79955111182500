import { CustomRegexpValidationItem, ValidationAbstract } from '@rehau/shared/forms/validations';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { ErrorMessageEnum } from '@rehau/shared/enums';

export class StringValidation extends ValidationAbstract {
  constructor(
    minLength: number | null = 2,
    maxLength: number | null = 256,
    items: CustomRegexpValidationItem[] = [],
    minLengthMessage?: ErrorMessageEnum,
    maxLengthMessage?: ErrorMessageEnum
  ) {
    let rules: ObjectIteratorInterface<number | undefined> = {};
    if (minLength) {
      rules = { minLength };
    }
    if (maxLength) {
      rules = { ...rules, maxLength };
    }
    super(rules, { minLength: minLengthMessage, maxLength: maxLengthMessage }, items);
  }
}
