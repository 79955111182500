import styled from 'styled-components';

import { Color } from '../../enums/color.enum';
import { StyledComponent } from '../../types/styledComponent.types';

export const LettersQuantity: StyledComponent<'span'> = styled.span`
  font-size: 11px;
  line-height: 14px;
  color: ${Color.Grey};
`;

export const LettersQuantityContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  white-space: nowrap;
`;
