import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { IconName } from '../../../../../../shared/enums';
import { ClickNumberField } from '../../../../../../shared/forms/elements';
import { Color } from '../../enums/color.enum';
import { Icon } from '../icon/icon.component';
import { ValidationBar } from '../validationBar/validationBar.component';

import { useQuantity } from './hooks/useQuantity';
import { UseQuantityOutput } from './hooks/useQuantity.types';
import { Button, Container, Label, Row, Sign, StyledTooltip, Value, ValueContainer, Wrapper } from './quantityInput.styled';
import { QuantityInputProps } from './quantityInput.types';

export const QuantityInput: React.FC<QuantityInputProps> = (props: QuantityInputProps): JSX.Element => {
  const {
    initValue,
    sign,
    label,
    max = 1000000000,
    min = 0,
    component = new ClickNumberField('', '', '', '', true),
    handleSave = (): void => undefined,
    setValues = (): void => undefined,
    internalComponentValidationEnabled = false,
    className,
    tooltipProps,
  }: QuantityInputProps = props;
  const {
    value,
    tempValue,
    valid,
    error,
    isTyping,
    add,
    subtract,
    handleChange,
    handleSubmit,
    handleFocus,
  }: UseQuantityOutput = useQuantity(
    min,
    max,
    handleSave,
    setValues,
    component,
    initValue,
    internalComponentValidationEnabled
  );
  const { t }: TransProps<never> = useTranslation();

  return (
    <Container className={className}>
      {!!label && <Label>{label}</Label>}
      <Row>
        <Wrapper valid={valid} data-testid='container'>
          <Button onClick={subtract} aria-label='subtract'>
            <Icon name={IconName.Minus} size={16} color={Color.Green} />
          </Button>
          <ValueContainer>
            <Value
              aria-label='value'
              value={isTyping ? tempValue : value}
              onBlur={handleSubmit}
              onChange={handleChange}
              onFocus={handleFocus}
            />
            {!!sign && <Sign aria-label='sign'>{sign}</Sign>}
          </ValueContainer>
          <Button onClick={add} aria-label='add'>
            <Icon name={IconName.Plus} size={16} color={Color.Green} />
          </Button>
        </Wrapper>
        {!!tooltipProps && <StyledTooltip tooltipSettings={tooltipProps} />}
      </Row>
      <ValidationBar
        errorsArray={internalComponentValidationEnabled && error
          ? [{ message: t(error) }]
          : component.validationMessages
        }
      />
    </Container>
  );
};
