import React, { useEffect, useRef,  useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { UseState } from '../../types/useState.types';
import { fieldValueType } from '../../../../../../shared/forms/types';
import { ValidationMessages } from '../../../../../../shared/forms/validations';
import { ObjectIteratorInterface } from '../../../../../../shared/objectIterator.interface';

import { Container, Dot, ErrorContent, ErrorItem, ErrorList } from './validationBar.styled';
import { ValidationBarProps } from './validationBar.types';

export const ValidationBar: React.FC<ValidationBarProps> = (props: ValidationBarProps): JSX.Element => {
  const { errorsArray = [], className }: ValidationBarProps = props;
  const { t }: TransProps<never> = useTranslation();
  const [isExpanded, setExpanded]: UseState<boolean> = useState<boolean>(!!errorsArray.length);
  const [height, setHeight]: UseState<number> = useState<number>(0);
  const ref: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const getValidationMessageWithParams: (message: string, params?: ObjectIteratorInterface<fieldValueType>) => string  = (
    message: string, params?: ObjectIteratorInterface<fieldValueType>
  ): string => {
    let localMessage: string = t(message);
    if (!!params) {
      Object.keys(params).forEach(
        (key: string): string => localMessage = localMessage.replace(key, t(params[key]?.toString() || ''))
      );
    }

    return localMessage;
  };

  useEffect((): void => setExpanded(!!errorsArray.length), [errorsArray]);

  const updateHeight: () => void = (): void => {
    setHeight(ref.current?.getBoundingClientRect().height ?? 0);
  };

  useEffect(
    (): (() => void) | undefined => {
      if (isExpanded) {
        window.addEventListener('resize', updateHeight);
        updateHeight();

        return (): void => window.removeEventListener('resize', updateHeight);
      }
      setHeight(0);

    },
    [isExpanded, errorsArray]
  );

  return (
    <Container className={className} style={{ height }}>
      <ErrorList ref={ref} >
        {errorsArray?.map((validationObject: ValidationMessages): JSX.Element =>
          <ErrorItem key={validationObject.message}>
            {errorsArray.length > 1 && <Dot />}
            <ErrorContent
              hasIndent={errorsArray.length > 1}
              text={getValidationMessageWithParams(validationObject.message, validationObject.params)}
            />
          </ErrorItem>
        )}
      </ErrorList>
    </Container>
  );
};
