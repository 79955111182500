import { NumberField } from '@rehau/shared/forms/elements/fields';
import { NumberValidation } from '@rehau/shared/forms/validations';
import { DistanceUnitEnum, FormFieldTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { InfoBox, Tooltip } from '@rehau/shared/forms/elements';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';

export class RangeField extends NumberField {
  public rangeUnit: DistanceUnitEnum;

  constructor(
      id: string,
      name: string,
      label: string | null,
      required: boolean,
      validation: NumberValidation = new NumberValidation(1),
      countryConfigMapping: ObjectIteratorInterface<string> = {},
      placeholder: string = '0',
      rangeUnit: DistanceUnitEnum = DistanceUnitEnum.Km,
      informationBox?: InfoBox,
      tooltip?: Tooltip
  ) {
    super(id, name, label, placeholder, required, validation, countryConfigMapping, informationBox, tooltip);
    this.type = FormFieldTypeEnum.RangeField;
    this.frontendComponent = FrontendComponentEnum.RangeField;
    this.rangeUnit = rangeUnit;
  }
}
