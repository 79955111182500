export enum BoxShadow {
  BtnGreenShadow = '0px 2px 10px rgba(60, 180, 152, 0.25)',
  BtnGreyShadow = '0px 0px 4px rgba(0,0,0,0.15)',
  LongPinkShadow = '0px 5px 20px rgba(221, 0, 96, 0.1)',
  LongGreyShadow = '0px 10px 50px rgba(117, 123, 150, 0.15)',
  LongGrey = '0px 10px 50px rgba(117, 123, 150, 0.15)',
  LongGreenShadow = '0px 5px 20px rgba(56, 165, 140, 0.15)',
  LongBlack = '0px 5px 20px rgba(127, 127, 127, 0.4);',
  ShortBlack = '0 2px 5px 0 rgba(0,0,0,.03)',
}
