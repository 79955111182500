import { RefObject } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { useEffectAfterMount } from './useEffectAfterMount';

export const useScrollLock: (ref: RefObject<HTMLElement>, lock: boolean) => void = (ref: RefObject<HTMLElement>, lock: boolean): void => {
  useEffectAfterMount(
    (): (() => void) => {
      if (lock) {
        disableBodyScroll(ref.current as HTMLElement);
      } else {
        enableBodyScroll(ref.current as HTMLElement);
      }

      return (): void => {
        clearAllBodyScrollLocks();
      };
    },
    [lock]
  );
};
