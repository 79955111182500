import styled, { StyledComponent } from 'styled-components';

import { Color } from '../../enums/color.enum';

import { StyledIconProps } from './icon.types';

export const StyledIcon: StyledComponent<'i', {}, StyledIconProps, never> = styled.i`
  display: inline-block;
  font-size: ${(data: StyledIconProps): number => data.size}px;
  color: ${(data: StyledIconProps): Color => data.color};
`;
