import styled from 'styled-components';

import { StyledComponent } from '../../types/styledComponent.types';
import { Color } from '../../enums/color.enum';
import { CustomLink } from '../customLink/customLink.component';
import { Icon } from '../icon/icon.component';
import { ZIndex } from '../../enums/zIndex.enum';

export const Container: StyledComponent<'div'> = styled.div`
  position: fixed;
  right: 0;
  bottom: 50%;
  transform: rotate(-90deg) translateX(50%);
  background-image: linear-gradient(to right, ${Color.Black}, ${Color.Black} 50%, ${Color.Green} 50%);
  transition: background-position 0.2s linear;
  background-size: 220% 100%;
  background-position-x: 100%;
  padding: 0 12px;
  height: 44px;
  transform-origin: 100% 100%;
  z-index: ${ZIndex.StickyTeaser};
  :hover {
    background-position-x: 0;
  }
`;

export const StyledCustomLink: StyledComponent<typeof CustomLink> = styled(CustomLink)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 100%;
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  transform: rotate(90deg);
`;

export const Label: StyledComponent<'div'> = styled.div`
  color: ${Color.White};
  font-size: 20px;
  line-height: 24px;
  margin-left: 8px;
`;
