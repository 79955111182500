import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { styleWhenTrue } from '../utils/style.utils';
import { StyledComponent } from '../types/styledComponent.types';

import { ScrollContainerProps } from './scrollContainer.types';

export const ScrollContainer: StyledComponent<'div', ScrollContainerProps> = styled.div<ScrollContainerProps>`
  ${styleWhenTrue(prop('isInPopup'), css`
    overflow-y: auto;
    scroll-behavior: smooth;
    height: 100vh;
  `)}
`;
