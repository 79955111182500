export enum SlidesType {
  S = 's',
  M = 'm',
  L = 'l',
}

export enum SlideSize {
  SlideWidth = 444,
  SlideWidthMobile = 333,
  SlideWidthSmallMobile = 222,
  DraggableWidth = 16,
}

export enum SlideButton {
  Sm = 'sm',
  Ml = 'ml',
  S = 's',
  L = 'l',
}
