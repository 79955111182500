import { useContext } from 'react';

import { InstallerContextType } from '../types/installerContext.types';
import { InstallerContext } from '../contexts/installer.context';

export const useInstallerContext: () => InstallerContextType | null = (): InstallerContextType | null => {
  const context: InstallerContextType = useContext(InstallerContext);
  if (!context) {
    return null;
  }

  return context;
};
