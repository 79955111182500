export enum SetupFieldTypeEnum {
  STRING = 'string',
  NUMBER = 'number',
  OPTIONS = 'options',
  EMAIL = 'email',
  PASSWORD = 'password',
  PHONE = 'phone',
  BOOLEAN = 'boolean',
  IMAGE = 'image',
}
