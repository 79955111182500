import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { InnerContainer, OuterContainer } from '../../../../theme/containers.styled';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { Color } from '../../../../enums/color.enum';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { styleWhenTrue } from '../../../../utils/style.utils';
import { Markdown } from '../../../markdown/markdown.component';

import { ContainerProps } from './textWithImageRow.types';

export const Container: StyledComponent<typeof OuterContainer, ContainerProps> = styled(OuterContainer)`
  padding-top: 100px;
  padding-bottom: 100px;

  @media ${Breakpoint.Tablet} {
    margin: 0;
    width: 100%;
    padding: 60px 40px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 60px 20px 10px;
  }

  ${styleWhenTrue(prop('darkBackground'), css`
    background-color: ${Color.BgGrey};
  `)}
`;

export const Wrapper: StyledComponent<typeof InnerContainer> = styled(InnerContainer)`
  justify-content: space-between;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
  }
`;

export const Content: StyledComponent<'div'> = styled.div`
  width: 50%;
  max-width: 420px;
  box-sizing: border-box;
  margin-right: 80px;
  min-width: 400px;

  @media ${Breakpoint.Tablet} {
    margin-right: 0;
    width: 100%;
    min-width: 0;
    max-width: none;
  }
`;

export const StyledMarkdown: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;

  strong {
    font-weight: ${FontWeight.Bold};
  }
`;

export const ImageRow: StyledComponent<'div'> = styled.div`
  max-width: 600px;
  box-sizing: border-box;
  display: flex;
  margin: 0 -2.5px;
  overflow: hidden;

  @media ${Breakpoint.Tablet} {
    max-width: none;
    width: 100%;
    margin-top: 40px;
  }

  @media ${Breakpoint.Mobile} {
    flex-wrap: wrap;
  }
`;

export const RowItem: StyledComponent<'div'> = styled.div`
  flex: 1;
  margin: 0 2.5px;
  box-sizing: border-box;

  @media ${Breakpoint.Mobile} {
    width: calc(50% - 5px);
    min-width: calc(50% - 5px);
    margin-bottom: 50px;
  }
`;

export const Image: StyledComponent<'img'> = styled.img`
  max-width: 100%;

  @media ${Breakpoint.Tablet} {
    width: 100%;
    max-width: auto;
  }
`;

export const ItemText: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: ${FontWeight.Bold};
  margin-top: 25px;
`;
