import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { BoxShadow } from '../../enums/boxShadow.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { ZIndex } from '../../enums/zIndex.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';
import { Button } from '../button/button.component';
import { ValidationBar } from '../validationBar/validationBar.component';

import { ContainerProps } from './dialogWithForm.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  position: fixed;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 620px;
  z-index: ${ZIndex.Dialog};
  opacity: 0;
  pointer-events: none;
  ${styleWhenTrue(
    prop('isVisible'),
    css`
      opacity: 1;
      pointer-events: initial;
    `
  )}

  @media ${Breakpoint.Mobile} {
    top: 0;
    left: 0;
    max-width: 100%;
    transform: none;
  }
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  padding: 30px;
  background-color: ${Color.White};
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  width: calc(100% - 60px);

  @media ${Breakpoint.Mobile} {
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
  }
`;

export const Title: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Bold};
  font-size: 24px;
  line-height: 24px;
  color: ${Color.Black};
  margin-bottom: 25px;
`;

export const ActionButton: StyledComponent<typeof Button> = styled(Button)`
  & + & {
    margin-left: 15px;
  }

  @media ${Breakpoint.Additional480} {
    & + & {
      margin-left: 0;
      margin-top: 15px;
    }
  }
`;

export const BottomBar: StyledComponent<'div'> = styled.div``;

export const ButtonsContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;

  @media ${Breakpoint.Mobile} {
    position: relative;
  }

  @media ${Breakpoint.Additional480} {
    flex-direction: column;
  }
`;

export const CloseButton: StyledComponent<'button'> = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
`;

export const FormContainer: StyledComponent<'div'> = styled.div`
  border-top: 1px solid ${Color.GreyStroke};
  border-bottom: 1px solid ${Color.GreyStroke};
  padding-top: 25px;
  padding-bottom: 60px;
  overflow-y: auto;
  box-sizing: border-box;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  max-height: 400px;
  height: 100%;

  @media ${Breakpoint.Mobile} {
    height: 100%;
    max-height: unset;
  }
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  margin-top: 15px;
`;
