import React, { useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';

import {
  MenuPositions,
  PathNameMenuPositionTuple,
  RouteInfo,
} from '../../../../../../shared/models/countryConfig';
import { useCountryConfig } from '../../hooks/useCountryConfig.hook';
import { sortItems } from '../../utils/menuPositions/menuPositions.utils';
import { findPathByName } from '../../utils/findPathByName.utils';
import { useRoutesInfo } from '../../hooks/useRoutesInfo.hook';
import { UseCountryConfig } from '../../types/useCountryConfig.types';
import { WebsiteComponentEnum, WebsitePathNameEnum } from '@rehau/shared/enums';
import { useAppConfig } from '../../hooks/useAppConfig.hook';
import { AppConfigType } from '../../types/appConfigType.types';
import { useInstallerContext } from '../../hooks/useInstallerContext.hook';
import { getRouteDetailsByUrl } from '@rehau/shared/utils';

import {
  BottomBar,
  BottomLink,
  Container,
} from './footerBottomBar.styled';
import { FooterBottomBarProps } from './footerBottomBar.types';

export const FooterBottomBar: React.FC<FooterBottomBarProps> = (props: FooterBottomBarProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { config }: UseCountryConfig = useCountryConfig();
  const { children, className }: FooterBottomBarProps = props;
  const routesArray: RouteInfo[] = useRoutesInfo();
  const { appType, domain }: AppConfigType = useAppConfig();
  const bottomMenuBarConfig: MenuPositions | null = config.menuConfigs ? config.menuConfigs.footerBottomBarMenu : null;
  const bottomMenuBarEntries: PathNameMenuPositionTuple[] = bottomMenuBarConfig ? Object.entries(bottomMenuBarConfig).sort(sortItems) : [];
  const addDomainToUrls: boolean = !!useInstallerContext()?.installerId;
  const positionsFilter: ([name]: PathNameMenuPositionTuple) => boolean = ([name]: PathNameMenuPositionTuple): boolean => {
    switch (name) {
      case WebsitePathNameEnum.InstallerRegulations:
        return appType === WebsiteComponentEnum.Setup;
      case WebsitePathNameEnum.CustomerRegulations:
        return appType === WebsiteComponentEnum.Calculator;
      default:
        return true;
    }
  };
  const location: Location = useLocation();
  const shouldRenderFooter: boolean = useMemo(
    (): boolean => {
      const pathnamesWhereHidden: WebsitePathNameEnum[] = [
        WebsitePathNameEnum.CalculatorCalculationSummary,
        WebsitePathNameEnum.TestCalculatorCalculationSummary,
      ];
      const pathObject: RouteInfo | undefined = getRouteDetailsByUrl(location.pathname, routesArray);

      return pathObject?.name
        ? !pathnamesWhereHidden.includes(pathObject.name)
        : true;
    },
    [location.pathname, routesArray]
  );

  return shouldRenderFooter ? (
    <Container data-testid='footer-bottom-bar' className={className}>
      <BottomBar data-testid='bottom-bar'>
        {bottomMenuBarEntries
          .filter(positionsFilter)
          .map(([name, data]: PathNameMenuPositionTuple): JSX.Element =>
          !!data ? (
            <BottomLink
              aria-label='bottom-menu-link'
              key={name}
              to={findPathByName(name, routesArray, undefined, addDomainToUrls ? domain : '')}
              pathName={name}
              openInNewTab
            >
              {t(data.title)}
            </BottomLink>
          ) : (
            <></>
          )
        )}
        {!!children && children }
      </BottomBar>
    </Container>
  ) : <></>;
};
