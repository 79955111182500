import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { BaseLabel } from '../../../theme';
import { Color } from '../../../enums/color.enum';
import { FontWeight } from '../../../enums/fontWeight.enum';
import { ZIndex } from '../../../enums/zIndex.enum';
import { styleWhenTrue } from '../../../utils/style.utils';
import { StyledComponent } from '../../../types/styledComponent.types';
import { Breakpoint } from '../../../enums/breakpoint.enum';

import { InnerBarProps, LeftBoundaryProps, ValueInputProps } from './slide.types';

export const Wrapper: StyledComponent<'div'> = styled.div`
  max-width: 500px;
`;

export const Label: StyledComponent<typeof BaseLabel> = styled(BaseLabel)`
  color: ${Color.Black};
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 34px;
`;

export const ValueContainer: StyledComponent<'div'> = styled.div`
  position: absolute;
  justify-content: center;
  width: 100px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
`;

export const Separtor: StyledComponent<'span'> = styled.span`
  font-weight: ${FontWeight.Black};
  color: ${Color.Black};
  font-size: 16px;
  line-height: 19px;
`;

export const AreaText: StyledComponent<'span'> = styled.span`
  position: relative;
  font-weight: ${FontWeight.Black};
  color: ${Color.Black};
  font-size: 16px;
  line-height: 19px;
`;

export const Sup: StyledComponent<'sup'> = styled.sup`
  position: absolute;
  font-size: 8px;
  top: -4px;
  right: -4px;
`;

export const ValueInput: StyledComponent<'input', ValueInputProps> = styled.input`
  font-weight: ${FontWeight.Black};
  color: ${Color.Black};
  font-size: 16px;
  line-height: 19px;
  min-width: 0;
  margin: 0;
  padding: 0;
  text-align: start;
  :first-child {
    text-align: end;
  }
  width: ${({ valueLength }: ValueInputProps): string => valueLength === 1 || valueLength === 0
    ? '12px'
    : valueLength === 2
      ? '20px'
      : '29px'
  };
`;

export const Bar: StyledComponent<'div'> = styled.div`
  position: relative;
  width: 460px;
  height: 6px;
  background-color: ${Color.GreyStroke};

  @media ${Breakpoint.Mobile} {
    width: 345px;
  }

  @media ${Breakpoint.Additional480} {
    width: 230px;
  }
`;

export const InnerBar: StyledComponent<'div', InnerBarProps> = styled.div`
  top: 0;
  left: ${(props: InnerBarProps): string => `${props.left}%`};
  right: ${(props: InnerBarProps): string => `${props.right}%`};
  position: absolute;
  height: 6px;
  background-color: ${Color.Pink};
`;

export const InnerBarBoundary: StyledComponent<'div'> = styled.div`
  content: '';
  position: absolute;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background-color: ${Color.White};
  border: 5px solid ${Color.Pink};
  cursor: pointer;
  top: 50%;
`;

export const InnerBarBoundaryRight: StyledComponent<typeof InnerBarBoundary> = styled(InnerBarBoundary)`
  transform: translateY(-50%);
  z-index: ${ZIndex.SlideInnerBarBoundaryRight};
`;

export const InnerBarBoundaryLeft: StyledComponent<typeof InnerBarBoundary, LeftBoundaryProps> = styled(InnerBarBoundary)`
  transform: translateY(-50%);
  z-index: ${ZIndex.SlideInnerBarBoundaryLeft};
  ${styleWhenTrue(
    prop('isOnTop'),
    css`
      z-index: ${ZIndex.SlideInnerBarBoundaryLeftOnTop};
    `
  )};
`;
