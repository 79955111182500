import styled, { css, StyledComponent } from 'styled-components';
import { prop } from 'ramda';

import { Icon } from '../icon/icon.component';
import { Markdown } from '../markdown/markdown.component';
import { Color } from '../../enums/color.enum';
import { styleWhenTrue } from '../../utils/style.utils';
import { FontWeight } from '../../enums/fontWeight.enum';
import { hexToRgba } from '../../utils/colors.utils';
import { InfoBoxPositionEnum, InfoBoxSizeEnum } from '@rehau/shared/enums';
import { Breakpoint } from '../../enums/breakpoint.enum';

import { HeaderProps, IconContainerProps, TextButtonProps, TextProps, TitleProps, WrapperProps } from './additionalinfo.types';

export const Wrapper: StyledComponent<'div', {}, WrapperProps, never> = styled.div`
  ${(props: WrapperProps): string => props.position === InfoBoxPositionEnum.Top
    ? 'margin-bottom: 10px'
    : 'margin-top: 25px'
  };
  background-color: ${hexToRgba(Color.Validation, 0.75)};
  border: 1px solid ${hexToRgba(Color.ValidationBorder, 0.5)};

  ${styleWhenTrue(
    prop('isWarning'),
    css`
      background-color: ${Color.ErrorBg};
      border: 1px solid ${Color.Pink};
    `
  )}
`;

export const Header: StyledComponent<'div', {}, HeaderProps, never> = styled.div`
  width: 100%;
  min-height: ${({ size }: HeaderProps): string => size === InfoBoxSizeEnum.Small ? '20px' : '46px'};
  display: flex;
  align-items: center;
  padding: ${({ size }: HeaderProps): string => size === InfoBoxSizeEnum.Small ? '5px 15px' : '10px 15px'};
  box-sizing: border-box;
  justify-content: space-between;
  word-break: break-word;
`;

export const TitleContainer: StyledComponent<'div', {}, {}, never> = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Title: StyledComponent<'div', {}, TitleProps, never> = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${Color.ValidationFont};
  display: flex;
  width: 100%;

  ${styleWhenTrue(
    prop('withBiggerFonts'),
    css`
      font-size: 18px;
      line-height: 23px;
    `
  )}
`;

export const MarkdownContent: StyledComponent<typeof Markdown, {}, {}, never> = styled(Markdown)`
  width: 100%;
`;

export const Collapse: StyledComponent<'div', {}, {}, never> = styled.div``;

export const TextContent: StyledComponent<'div', {}, {}, never> = styled.div`
  padding: 10px 45px 10px 45px;
`;

export const Text: StyledComponent<'div', {}, TextProps, never> = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${Color.ValidationFont};

  ${styleWhenTrue(
    prop('withBiggerFonts'),
    css`
      font-size: 18px;
      line-height: 21px;
    `
  )}
`;

export const TextButton: StyledComponent<'div', {}, TextButtonProps, never> = styled.div`
  font-size: 14px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.ValidationFont};
  cursor: pointer;
  margin-left: 5px;
  flex-shrink: 0;
  line-height: 17px;
  padding: 0;
  text-align: center;
  display: flex;
  align-items: center;

  ${styleWhenTrue(
    prop('withBiggerFonts'),
    css`
      font-size: 18px;
      line-height: 21px;
    `
  )}

  @media ${Breakpoint.Mobile} {
    display: none;
  }
`;

export const SmileIcon: StyledComponent<typeof Icon, {}, {}, never> = styled(Icon)`
  margin-right: 10px;
  flex-shrink: 0;
`;

export const ExtendButton: StyledComponent<'button', {}, {}, never> = styled.button`
  flex-shrink: 0;
  min-width: 25px;
  display: flex;
  justify-content: right;
  min-height: 22px;
  align-items: center;
`;

export const IconContainer: StyledComponent<'div', {}, IconContainerProps, never> = styled.div`
  cursor: pointer;
  transition: 0.4s;
  ${styleWhenTrue(
    prop('isExtended'),
    css`
      transform: rotate(180deg);
    `
  )}
`;
