import styled from 'styled-components';

import { StyledComponent } from '../../../../types/styledComponent.types';
import { Color } from '../../../../enums/color.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';

import { IconContainerProps } from './circleItemsGrid.types';

export const Container: StyledComponent<'div'> = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, 62px);
  max-width: 270px;
  width: 100%;
`;

export const CircleItemContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const IconContainer: StyledComponent<'div', IconContainerProps> = styled.div<IconContainerProps>`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border: 1px solid ${Color.GreyStroke};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $isActive }: IconContainerProps): string => $isActive ? `border-color: ${Color.CompanyColor};` : ''}
`;

export const ItemAmount: StyledComponent<'div'> = styled.div`
  margin-top: 5px;
  font-weight: ${FontWeight.Bold};
  font-size: 15px;
  line-height: 18px;
  color: ${Color.Black};
  letter-spacing: 0.160714px;
`;
