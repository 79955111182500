import React, { ChangeEvent, ChangeEventHandler, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { useEffectAfterMount } from '../../../hooks/useEffectAfterMount';
import { Tooltip } from '../../../../../../../shared/forms/elements';
import { SelectField, SelectOption } from '../../../../../../../shared/forms/elements/fields';
import { ValidationStatus } from '../../../enums/validationStatus.enum';
import { UseState } from '../../../types/useState.types';
import { ContainerType } from '../../input/inputContainer/containerType.enum';
import { Color } from '../../../enums/color.enum';
import { DropdownContainer } from '../dropdown.styled';

import { DropdownControllerProps } from './dropdownController.types';

export const DropdownController: React.FC<DropdownControllerProps> = (props: DropdownControllerProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    items,
    children,
    placeholder = t('rehau.components.dropdown.placeholder'),
    label,
    additionalInfoContent,
    additionalInfoContentInReadMore,
    component = new SelectField('', '', '', true, [new SelectOption('', '', false, new Tooltip(''))]),
    handleSave = (): void => undefined,
    setValues = (): void => undefined,
  }: DropdownControllerProps = props;
  const [selectedItem, setSelectedItem]: UseState<string> = useState<string>(component.value && Array.isArray(component.value)
    ? component.value[0].toString()
    : ''
  );
  const [validationStatus, setValidationStatus]: UseState<ValidationStatus> = useState<ValidationStatus>(
    component.valid ? ValidationStatus.Valid : ValidationStatus.Untouched
  );

  const handleChange: ChangeEventHandler = (event: ChangeEvent<HTMLSelectElement>): void => {
    setSelectedItem(event.target.value);
    setValues({ [component.id]: event.target.value });
    const validationSuccess: boolean = component.validate();
    setValidationStatus(validationSuccess ? ValidationStatus.Valid : ValidationStatus.Invalid);
    if (validationSuccess) {
      handleSave();
    }
  };

  useEffectAfterMount(
    (): void => {
      if (component.valid === undefined) {
        setValidationStatus(ValidationStatus.Untouched);
        setSelectedItem('');
      }
    },
    [component.valid]
  );

  return (
    <>
      <DropdownContainer
        validationStatus={validationStatus}
        containerType={ContainerType.Dropdown}
        label={label}
        additionalInfoContent={additionalInfoContent}
        additionalInfoContentInReadMore={additionalInfoContentInReadMore}
        validationMessages={component.validationMessages}
        inputWrapperBorderColor={Color.Black}
        disabled={component.onlyForRead || component.disabled}
      >
        {React.cloneElement(children, { selectedItem, placeholder, items, onChange: handleChange })}
      </DropdownContainer>
    </>
  );
};
