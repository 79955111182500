import { AbstractField, SelectOption } from '@rehau/shared/forms/elements/fields';
import { RequiredFieldValidation, ValidationMessages } from '@rehau/shared/forms/validations';
import { ErrorMessageEnum, FormFieldTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';

export class MultiSelectField extends AbstractField {
  public defaultValue: string[] | null;
  public allowedOptions: SelectOption[];

  constructor(
    id: string,
    name: string,
    label: string | null,
    required: boolean,
    allowedOptions: SelectOption[] = [],
    defaultValue?: string[]
  ) {
    super(
      FormFieldTypeEnum.MultiSelectField,
      FrontendComponentEnum.MultiSelect,
      id,
      name,
      label,
      null,
      new RequiredFieldValidation(),
      required
    );
    this.allowedOptions = allowedOptions;
    this.defaultValue = defaultValue || null;
    if (!!this.defaultValue) {
      this.setValue(this.defaultValue);
    }
  }

  public setValue(values: string | string[] | null): this {
    this.unselectOptions();
    if (values) {
      if (Array.isArray(values)) {
        for (const value of values) {
          this.selectOption(value);
        }
      } else {
        this.selectOption(values);
      }
    }

    return this;
  }

  public validate(): boolean {
    let valid: boolean = super.validate();
    if (this.required && !!this.value && Array.from(this.value as string[]).length === 0) {
      this.validationMessages.push(new ValidationMessages(ErrorMessageEnum.fields_required, { name: this.label }));
      this.valid = false;
      valid = false;
    }

    return valid;
  }

  public getAllowedOptionsValues(): string[] {
    return this.allowedOptions.map((allowedOption: SelectOption): string => {
      return allowedOption.value;
    });
  }

  protected selectOption(value: string): boolean {
    const selectedOption: SelectOption | null = this.findOptionByValue(value);
    if (selectedOption) {
      selectedOption.selected = true;
      if (!this.value) {
        this.value = [];
      }
      (this.value as string[]).push(value);

      return true;
    }

    return false;
  }

  protected findOptionByValue(value: string): SelectOption | null {
    return this.allowedOptions.find(
      (allowedOption: SelectOption): boolean => allowedOption.value.toString() === value?.toString()
    ) ?? null;
  }

  protected unselectOptions(): void {
    this.value = null;
    for (const allowedOption of this.allowedOptions) {
      allowedOption.selected = false;
    }
  }
}
