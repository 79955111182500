import styled from 'styled-components';

import { BoxShadow } from '../../enums/boxShadow.enum';
import { Color } from '../../enums/color.enum';
import { ZIndex } from '../../enums/zIndex.enum';
import { StyledComponent } from '../../types/styledComponent.types';

import { IconContainerProps } from './iconWithTooltip.types';

export const IconContainer: StyledComponent<'div', IconContainerProps> = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${({ backgroundColor }: IconContainerProps): Color => backgroundColor};
  padding: ${({ iconSize }: IconContainerProps): number => iconSize / 4}px;
`;

export const TooltipContainer: StyledComponent<'div'> = styled.div`
  position: absolute;
  width: 117px;
  top: calc(100% + 6px);
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  z-index: ${ZIndex.Minimum};
`;

export const TooltipArrow: StyledComponent<'div'> = styled.div`
  border-left: 6px solid ${Color.Transparent};
  border-right: 6px solid ${Color.Transparent};
  border-bottom: 6px solid ${Color.Black};
  width: 0;
  height: 0;
  margin: 0 auto;
`;

export const TooltipContent: StyledComponent<'div'> = styled.div`
  padding: 4px 8px 6px;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  background-color: ${Color.Black};
  border-radius: 2px;
  font-size: 14px;
  line-height: 17px;
  color: ${Color.White};
`;

export const Container: StyledComponent<'div'> = styled.div`
  position: relative;
  width: max-content;
  &:hover {
    ${TooltipContainer} {
      opacity: 1;
    }
  }
`;
