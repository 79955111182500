import React, { forwardRef, useRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';

import { fieldValueType } from '../../../../../../../../shared/forms/types';
import { ProductTableNameEnum } from '../../../../../../../../shared/enums';
import { ObjectIteratorInterface } from '../../../../../../../../shared/objectIterator.interface';
import { useCountryConfig } from '../../../../hooks/useCountryConfig.hook';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { ProductTable } from '../../../productTable/productTable.component';
import { ConfiguratorPreview } from '../../../configuratorPreview/configuratorPreview.component';
import { FullWidthImage } from '../../components/fullWidthImage/fullWidthImage.component';
import { Tabs } from '../../components/tabs/tabs.component';
import { TextWithColumns } from '../../components/textWithColumns/textWithColumns.component';
import { ColumnType } from '../../components/textWithColumns/textWithColumns.enums';
import { TextWithImage } from '../../components/textWithImage/textWithImage.component';
import { TextWithVideo } from '../../components/textWithVideo/textWithVideo.component';
import ManWithPipeImage from '../../images/manWithPipe.jpg';
import PipesImage from '../../images/pipes.png';
import EngineerImage from '../../images/engineer2.jpg';
import EngineerImage2 from '../../images/engineer3.jpg';
import PipeImage from '../../images/pipe.jpg';
import ClampImage from '../../images/clamp.jpg';
import ElbowImage from '../../images/elbow.jpg';
import { ReactComponent as WifiSvg } from '../../images/wifi.svg';
import { useActiveTab } from '../../hooks/useActiveTab.hooks';
import { UseActiveTab, UseCalculatorNavigate } from '../../products.types';
import { sectionVisibilityThreshold } from '../../products.constants';
import { UseCountryConfig } from '../../../../types/useCountryConfig.types';
import { OptionTab } from '../../components/tabs/tabs.types';
import { useCalculatorNavigate } from '../../hooks/useCalculatorNavigate.hook';
import { ProductPopupContext } from '../../../../contexts/productPopup.context';

import { Container, TableContainer, TableWrapper } from './sewage.styled';
import { SectionId } from './sewage.enums';
import { SewageProps } from './sewage.types';

export const Sewage: React.FC<SewageProps> = forwardRef((props: SewageProps, ref: React.ForwardedRef<{}>): JSX.Element => {
  const { className, tabsOnTop, setTabsOnTop, isInPopup = false }: SewageProps = props;
  const { t }: TransProps<never> = useTranslation();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();
  const products: ObjectIteratorInterface<fieldValueType> = isConfigAvailable ? config.variables.products : {};
  const [activeTabId, setActiveTabId]: UseActiveTab = useActiveTab('');
  const { navigateToCalculator }: UseCalculatorNavigate = useCalculatorNavigate();
  const briefRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const protectionRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const fasteningRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const efficiencyRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const montageRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const calculatorRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);

  const getCurrentRefByActiveId: (activeTab: string) => React.RefObject<InView & HTMLDivElement> =
    (activeTab: string): React.RefObject<InView & HTMLDivElement> => {
      switch (activeTab) {
        case SectionId.Brief:
          return briefRef;
        case SectionId.Protection:
          return protectionRef;
        case SectionId.Fastening:
          return fasteningRef;
        case SectionId.Efficiency:
          return efficiencyRef;
        case SectionId.Montage:
          return montageRef;
        default:
          return calculatorRef;
      }
    };

  const handleScroll: (tabId: string) => void = (tabId: string): void => {
    const activeRef: React.RefObject<InView & HTMLDivElement> = getCurrentRefByActiveId(tabId);
    activeRef.current?.node?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ProductPopupContext.Provider value={{ isInPopup }}>
      <Container className={className} data-testid='sewage-product' ref={ref as React.RefObject<HTMLDivElement>}>
        <FullWidthImage src={ManWithPipeImage} />
        <TextWithVideo
          sectionTitle={t('rehau.products.sewage.header.title')}
          text={t('rehau.products.sewage.header.content')}
          videoId={String(products['sewage.sections.introduction.videoHash'])}
        />
        <Tabs
          activeId={activeTabId}
          options={[
            { label: t('rehau.products.sewage.tabs.brief'), id: SectionId.Brief },
            { label: t('rehau.products.sewage.tabs.protection'), id: SectionId.Protection },
            { label: t('rehau.products.sewage.tabs.fastening'), id: SectionId.Fastening },
            { label: t('rehau.products.sewage.tabs.efficiency'), id: SectionId.Efficiency },
            { label: t('rehau.products.sewage.tabs.montage'), id: SectionId.Montage },
            !isInPopup ? { label: t('rehau.products.sewage.tabs.calculator'), id: SectionId.Calculator } : {} as OptionTab,
          ]}
          isOnTop={tabsOnTop}
          setOnTop={setTabsOnTop}
          handleScroll={handleScroll}
        />
        <InView
          as='div'
          threshold={isMobile ? 0.4 : sectionVisibilityThreshold}
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Brief)}
          id={SectionId.Brief}
          ref={briefRef}
        >
          <TextWithColumns
            title={t('rehau.products.sewage.brief.title')}
            columnType={ColumnType.Simple}
            darkBackground
            columnsData={[
              {
                title: t('rehau.products.sewage.brief.sound_title'),
                text: t('rehau.products.sewage.brief.sound_content'),
                value: t('rehau.products.sewage.brief.sound_value'),
              },
              {
                title: t('rehau.products.sewage.brief.isolation_title'),
                text: t('rehau.products.sewage.brief.isolation_content'),
                value: <WifiSvg />,
              },
              {
                title: t('rehau.products.sewage.brief.montage_title'),
                text: t('rehau.products.sewage.brief.montage_content'),
                value: t('rehau.products.sewage.brief.montage_value'),
              },
            ]}
          />
        </InView>
        <InView
          as='div'
          threshold={isMobile ? 0.1 : isTablet ? 0.2 : 0.65}
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Protection)}
          id={SectionId.Protection}
          ref={protectionRef}
        >
          <TextWithColumns
            title={t('rehau.products.sewage.protection.title')}
            text={t('rehau.products.sewage.protection.content')}
            columnType={ColumnType.Image}
            columnsData={[
              {
                title: t('rehau.products.sewage.protection.technology_title'),
                text: t('rehau.products.sewage.protection.technology_content'),
                imageSrc: PipeImage,
              },
              {
                title: t('rehau.products.sewage.protection.pipes_title'),
                text: t('rehau.products.sewage.protection.pipes_content'),
                imageSrc: ElbowImage,
              },
              {
                title: t('rehau.products.sewage.protection.clamp_title'),
                text: t('rehau.products.sewage.protection.clamp_content'),
                imageSrc: ClampImage,
              },
            ]}
            hint={t('rehau.products.sewage.protection.hint')}
            withFullWidthImage
          />
        </InView>
        <InView
          as='div'
          threshold={isMobile ? 0.4 : sectionVisibilityThreshold}
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Fastening)}
          id={SectionId.Fastening}
          ref={fasteningRef}
        >
          <TextWithImage
            title={t('rehau.products.sewage.fastening.title')}
            text={t('rehau.products.sewage.fastening.content')}
            imageSrcList={[EngineerImage2]}
          />
        </InView>
        <InView
          as='div'
          threshold={sectionVisibilityThreshold}
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Efficiency)}
          id={SectionId.Efficiency}
          ref={efficiencyRef}
        >
          <TextWithImage
            title={t('rehau.products.sewage.efficiency.title')}
            text={t('rehau.products.sewage.efficiency.content')}
            imageSrcList={[PipesImage]}
            reversed
          />
        </InView>
        <FullWidthImage src={EngineerImage} />
        <InView
          as='div'
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Montage)}
          id={SectionId.Montage}
          threshold={sectionVisibilityThreshold}
          ref={montageRef}
        >
          <TableContainer>
            <TableWrapper>
              <ProductTable tableType={ProductTableNameEnum.Sewage} tableTitle={t('rehau.products.sewage.table.title')} />
            </TableWrapper>
          </TableContainer>
        </InView>
        {!isInPopup && (
          <InView
            as='div'
            onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Calculator)}
            id={SectionId.Calculator}
            threshold={sectionVisibilityThreshold}
            ref={calculatorRef}
          >
            <ConfiguratorPreview buttonOnClick={navigateToCalculator} />
          </InView>
        )}
      </Container>
    </ProductPopupContext.Provider>
  );
});
