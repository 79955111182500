import React from 'react';
import YouTube from 'react-youtube';
import { useMediaQuery } from 'react-responsive';

import { UseImageAnimationOutput } from '../../../../types/useImageAnimation.types';
import { useImageAnimation } from '../../../../hooks/useImageAnimation';
import { UseTimelineAnimationOutput } from '../../../../types/useTimelineAnimation.types';
import { useTimelineAnimation } from '../../../../hooks/useTimelineAnimation';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { assignColumnRef } from '../../utils/columnRef.utils';
import { Title } from '../title/title.component';
import { useProductPopupContext } from '../../../../hooks/useProductPopupContext.hook';
import { ProductPopupContextType } from '../../../../types/productPopupContextType.types';

import {
  Column,
  ColumnImage,
  ColumnImageContainer,
  Columns,
  ColumnsTitle,
  ColumnText,
  ColumnTextWrapper,
  ColumnTitle,
  Container,
  Content,
  ContentWrapper,
  HintContainer,
  HintImage,
  Hints,
  HintText,
  SectionTitle,
  StyledMarkdown,
  Subtitle,
  Wrapper,
  YoutubeContainer,
} from './textWithVideo.styled';
import { ColumnData, Hint, TextWithVideoProps } from './textWithVideo.types';

export const TextWithVideo: React.FC<TextWithVideoProps> = (props: TextWithVideoProps): JSX.Element => {
  const {
    className,
    sectionTitle,
    title,
    text,
    videoId,
    darkBackground = false,
    subtitle,
    subcontent,
    columnsTitle,
    columnsData = [],
    hintTitle,
    hints = [],
  }: TextWithVideoProps = props;
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const { isInPopup }: ProductPopupContextType = useProductPopupContext();
  const { firstImageRef }: UseImageAnimationOutput = useImageAnimation(isInPopup);
  const timelineAnimation: UseTimelineAnimationOutput = useTimelineAnimation(isInPopup);

  return (
    <Container className={className} darkBackground={darkBackground}>
      <Wrapper>
        {!!sectionTitle && <SectionTitle large data-testid='video-section-header-title'>{sectionTitle}</SectionTitle>}
        <ContentWrapper>
          <Content>
            {!!title && <Title data-testid='video-section-title'>{title}</Title>}
            <StyledMarkdown text={text} />
          </Content>
          {!isTablet && (
            <YoutubeContainer ref={firstImageRef}>
              <YouTube videoId={videoId} />
            </YoutubeContainer>
          )}
        </ContentWrapper>
        {!!subtitle && <Subtitle data-testid='video-section-subtitle'>{subtitle}</Subtitle>}
        {!!subcontent && (
          <StyledMarkdown text={subcontent} data-testid='video-section-subcontent' />
        )}
        {isTablet && (
          <YoutubeContainer ref={firstImageRef}>
            <YouTube videoId={videoId} />
          </YoutubeContainer>
        )}
        {!!columnsTitle && <ColumnsTitle small data-testid='video-section-columns-title'>{columnsTitle}</ColumnsTitle>}
        <Columns withTitle={!!columnsTitle} imageColumns={!!columnsData.length && !!columnsData[0].imageSrc}>
          {columnsData.map((column: ColumnData, index: number): JSX.Element => {
            const columnRef: React.RefObject<HTMLDivElement> = assignColumnRef(index, timelineAnimation);

            return (
              <Column key={index} ref={columnRef} withImage={!!column.imageSrc} data-testid='video-section-column'>
                {!!column.imageSrc && (
                  <ColumnImageContainer>
                    <ColumnImage src={column.imageSrc} />
                  </ColumnImageContainer>
                )}
                <ColumnTextWrapper withImage={!!column.imageSrc}>
                  {!!column.title && <ColumnTitle>{column.title}</ColumnTitle>}
                  <ColumnText text={column.text} tagType='p' />
                </ColumnTextWrapper>
              </Column>
            );
          })}
        </Columns>
        {!!hintTitle && <ColumnsTitle small>{hintTitle}</ColumnsTitle>}
        {!!hints.length && (
          <Hints>
            {hints.map((hint: Hint, index: number): JSX.Element => (
              <HintContainer key={index} data-testid='video-section-hints'>
                <HintImage src={hint.imageSrc} />
                <HintText>{hint.text}</HintText>
              </HintContainer>
            ))}
          </Hints>
        )}
      </Wrapper>
    </Container>
  );
};
