import styled from 'styled-components';

import { Markdown } from '../../../markdown/markdown.component';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Color } from '../../../../enums/color.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { InnerContainer, OuterContainer } from '../../../../theme/containers.styled';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { Title as TitleBase } from '../title/title.component';

export const Container: StyledComponent<typeof OuterContainer> = styled(OuterContainer)`
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: ${Color.Green};

  @media ${Breakpoint.Tablet} {
    margin: 0;
    width: 100%;
    padding: 60px 40px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 60px 20px;
  }
`;

export const Wrapper: StyledComponent<typeof InnerContainer> = styled(InnerContainer)`
  justify-content: space-between;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
  }
`;

export const TextContent: StyledComponent<'div'> = styled.div`
  max-width: 675px;
  padding-right: 75px;

  @media ${Breakpoint.Desktop} {
    max-width: 540px;
  }

  @media ${Breakpoint.Tablet} {
    max-width: none;
    padding-right: 0;
  }
`;

export const Title: StyledComponent<typeof TitleBase> = styled(TitleBase)`
  color: ${Color.White};
  max-width: 580px;

  @media ${Breakpoint.Tablet} {
    max-width: none;
  }
`;

export const StyledMarkdown: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;
  color: ${Color.White};

  strong {
    font-weight: ${FontWeight.Bold};
  }
`;

export const IconContainer: StyledComponent<'div'> = styled.div`
  width: 350px;

  @media ${Breakpoint.Tablet} {
    margin-top: 36px;
  }
`;

export const Icon: StyledComponent<'img'> = styled.img`
  display: block;
  margin: 0 auto;

  @media ${Breakpoint.Tablet} {
    margin: 0;
  }
`;
