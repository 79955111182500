import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { DescriptionStatic } from '../../../../../../../shared/forms/elements';
import { FieldElement, FieldElementProps } from '../types';
import { SimpleDescriptionBoxInHorizontal } from '../../simpleDescriptionBoxInHorizontal/simpleDescriptionBoxInHorizontal.component';

export const DescriptionStaticElement: FieldElement<DescriptionStatic> = (props: FieldElementProps<DescriptionStatic>): JSX.Element => {
  const { component }: FieldElementProps<DescriptionStatic> = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <SimpleDescriptionBoxInHorizontal text={t(component.text)} label={t(component.label ?? '')} />
  );
};
