import styled from 'styled-components';

import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { TooltipTheme } from '@rehau/shared/enums';

import { TooltipButtonProps } from './tooltip.types';

export const ToolTipButton: StyledComponent<'button', TooltipButtonProps> = styled.button`
  border-radius: 50%;
  background-color: ${({ theme }: TooltipButtonProps): Color => theme === TooltipTheme.Standard ? Color.GreyStroke : Color.Validation};
  width: 22px;
  height: 22px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

export const TooltipText: StyledComponent<'p'> = styled.p`
  font-weight: ${FontWeight.Black};
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const TooltipContainer: StyledComponent<'button'> = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Container: StyledComponent<'div'> = styled.div``;
