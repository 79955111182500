import React from 'react';

import { WebsiteComponentEnum } from '@rehau/shared/enums';
import { RouteInfo } from '../../../../../../shared/models/countryConfig';
import { useAppConfig } from '../../hooks/useAppConfig.hook';
import { useRoutesInfo } from '../../hooks/useRoutesInfo.hook';
import { AppConfigType } from '../../types/appConfigType.types';
import { findComponentByName } from '../../utils/findComponentByName.utils';

import { StyledA, StyledLink } from './customLink.styled';
import { CustomLinkProps } from './customLink.types';

export const CustomLink: React.FC<CustomLinkProps> = (props: CustomLinkProps): JSX.Element => {
  const { to, className, children, pathName, onClick, openInNewTab = false, download = false }: CustomLinkProps = props;
  const { appType }: AppConfigType = useAppConfig();
  const routesArray: RouteInfo[] = useRoutesInfo();
  const isInternalPath: boolean = pathName ? findComponentByName(pathName, routesArray) === appType : false;
  const isExternalPath: boolean = pathName
    ? findComponentByName(pathName, routesArray) === WebsiteComponentEnum.ExternalAddressing
    : false;

  return (
    <>
      {isInternalPath ? (
        <StyledLink to={to} className={className} data-testid='internal-link' onClick={onClick}>
          {children}
        </StyledLink>
      ) : (
        <StyledA
          className={className}
          data-testid='external-link'
          href={to}
          onClick={onClick}
          target={isExternalPath || openInNewTab ? '_blank' : '_self'}
          download={download}
        >
          {children}
        </StyledA>
      )}
    </>
  );
};
