export enum CalculationSummaryTypeEnum {
  HeatingInstallation = 'heating_installation',
  HeatingCoolingVentilation = 'heating_cooling_ventilation',
  RehauProducts = 'rehau_products',
  WaterDistribution = 'water_distribution',
  HomeAutomation = 'home_automation',
  Sanitary = 'sanitary',
  Sewage = 'sewage',
  Montage = 'montage',
  Heating = 'heating',
  HeatDistribution = 'heat_distribution',
  Regulation = 'regulation',
  VentilationSystems = 'ventilation_systems',
  BuildingTechnology = 'building_technology',
  HeatingSource = 'heat_source',
  SolarSystem = 'solar_system',
  Photovoltaic = 'photovoltaic',
  FloorHeating = 'floor_heating',
  WaterPipes = 'water_pipes',
  FreshWaterModule = 'fresh_water_module',
  DecalcificationModule = 'decalcification_module',
  CirculationInstallation = 'circulation_installation',
  WaterReducer = 'water_reducer',
  Reguard = 'reguard',
  ReguardSensors = 'reguard_sensors',
  Resource = 'resource',
  SanitaryEquipment = 'sanitary_equipment',
  Toilet = 'sanitary_equipment_toilet',
  WashingToilet = 'sanitary_equipment_washing_toilet',
  Urinal = 'sanitary_equipment_urinal',
  Bath = 'sanitary_equipment_bath',
  RainShower = 'sanitary_equipment_rain_shower',
  Shower = 'sanitary_equipment_shower',
  KitchenSink = 'sanitary_equipment_kitchen_sink',
  SmallWashbasinColdWater = 'sanitary_equipment_small_washbasin_cold_water',
  Washbasin = 'sanitary_equipment_washbasin',
  Bidet = 'sanitary_equipment_bidet',
  WaterConnections = 'sanitary_equipment_water_connections',
  WaterConnectionsForAgd = 'sanitary_equipment_water_connections_for_agd',
  WaterConnectionsDishwasher = 'sanitary_equipment_dishwasher_connections',
  WaterConnectionsWashingMachine = 'sanitary_equipment_washing_machine_connections',
  SewagePipes = 'sewage_pipes',
  // heating installation details
  HeatingTank = 'heating_tank',
  BuiltInWarmer = 'built_in_warmer',
  HotUtilityWaterTank = 'hot_utility_water_tank',
  HydraulicSwitch = 'hydraulic_switch',
  Chimney = 'chimney',
  IntegratedDrinkingWaterHeating = 'integrated_drinking_water_heating',
  BathroomRadiators = 'bathroom_radiators',
  Maintenance = 'maintenance',
  ExtraCost = 'extra_cost',
  ExtraCostItem = 'extra_cost_item',
  ExtraLocalityCost = 'extra_locality_cost',
  ExtraLocalityCostItem = 'extra_locality_cost_item',
  Photos = 'photos',
  PhotoOfInstallation = 'photoOfInstallation',
  DetailedPhotoOfInstallation = 'detailedPhotoOfInstallation',
  PipeSystemManufacturer = 'pipeSystemManufacturer',
}
