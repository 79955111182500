import styled, { StyledComponent } from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { BoxShadow } from '../../enums/boxShadow.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { GridWidth } from '../../enums/gridWidth.enum';

export const Container: StyledComponent<'div', {}, {}, never> = styled.div`
  max-width: ${GridWidth.Desktop};
  margin: 0 auto;
  width: 100%;
  display: flex;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
  }
`;

export const StepContainer: StyledComponent<'div', {}, {}, never> = styled.div`
  background-color: ${Color.White};
  width: calc(100% - 360px);
  box-shadow: ${BoxShadow.BtnGreyShadow};
  display: flex;
  flex-direction: column;
  padding: 80px;

  @media ${Breakpoint.Tablet} {
    width: 100%;
    box-sizing: border-box;
    padding: 40px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 20px;
  }
`;

export const MenuContainer: StyledComponent<'div', {}, {}, never> = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  flex-shrink: 0;
  width: 100%;
  max-width: 320px;

  @media ${Breakpoint.Tablet} {
    max-width: 100%;
    margin-bottom: 50px;
  }
`;

export const StepTitle: StyledComponent<'div', {}, {}, never> = styled.div`
  font-weight: ${FontWeight.Bold};
  font-size: 18px;
  line-height: 22px;
  color: ${Color.Black};
  padding-bottom: 10px;
  border-bottom: 1px solid ${Color.GreyStroke};
  margin-bottom: 20px;
`;

export const StepContent: StyledComponent<'div', {}, {}, never> = styled.div``;
