import { ErrorMessageEnum } from '@rehau/shared/enums';

export const containerDefaultErrorMessagesConst: ErrorMessageEnum[] = [
  ErrorMessageEnum.all_or_null_optional_variants_required,
  ErrorMessageEnum.all_or_null_optional_variants_required_new_setup,
  ErrorMessageEnum.all_or_null_variants_required,
  ErrorMessageEnum.all_or_null_variants_required_new_setup,
  ErrorMessageEnum.variants_wrong_value,
  ErrorMessageEnum.variants_wrong_value_new_setup,
  ErrorMessageEnum.form_values_error,
];
