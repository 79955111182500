import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { ApiErrorModel } from '@rehau/shared/models/responses';
import { AppConfig } from '@rehau-fe/app/appConfig.config';
import { LocalStorageKey } from '@rehau-fe/ui/src';

export const api: AxiosInstance = axios.create({
  baseURL: AppConfig.api,
  responseType: 'json',
  headers: { token: JSON.parse(window.localStorage.getItem(LocalStorageKey.Token) || '{}') },
});

export const setAuthToken: (token: string) => void = (token: string): void => {
  const id: number = api.interceptors.request.use((request: AxiosRequestConfig): AxiosRequestConfig => {
    request.headers.token = token;

    return request;
  });
  if (id > 0) {
    api.interceptors.request.eject(id - 1);
  }
};

api.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    // tslint:disable-next-line:no-console
    console.log(response.headers['backend-id'], '|', response.request.responseURL || response.config.url);

    return response;
  },
  (error: AxiosError<ApiErrorModel>): void | ApiErrorModel => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          window.localStorage.setItem(LocalStorageKey.Token, '');
          window.localStorage.setItem(LocalStorageKey.AuthData, '');
          window.location.assign('/logout');
          break;
        case 400:
        case 403:
        case 404:
        case 409:
        case 500:
        case 501:
          throw error.response.data;
        default:
          // TODO: redirect to general error page
          break;
      }
    } else if (error.request) {
      // TODO: problems with api alert ?
      // console.warn(`The request for ${error.config.url} was made but no response was received`);
    } else {
      // TODO: redirect to general error page
    }
  }
);
