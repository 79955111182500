import React, { RefObject, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import InView from 'react-intersection-observer';

import { FullscreenPopup } from '../fullscreenPopup/fullscreenPopup.component';
import { UseState } from '../../types/useState.types';
import { ChooseVariantFromPopupField } from '../../../../../../shared/forms/elements/fields';
import { SelectBarWithType } from '../selectBar/selectBarWithType/selectBarWithType.component';
import { SelectBarOption } from '../../../../../../shared/forms/elements';
import { AtHomeCalcField, Direction, IconName, PromoPopupContentTypeEnum } from '../../../../../../shared/enums';
import { WaterDistribution } from '../products/views/waterDistribution/waterDistribution.component';
import { HeatInstallation } from '../products/views/heatInstallation/heatInstallation.component';
import { ReGuard } from '../products/views/reGuard/reGuard.component';
import { ReSource } from '../products/views/reSource/reSource.component';
import { TemperatureRegulator } from '../products/views/temperatureRegulator/temperatureRegulator.component';
import { Sewage } from '../products/views/sewage/sewage.component';
import { Color } from '../../enums/color.enum';

import { PopupContentProps, SpecialProductsProps } from './specialProducts.types';
import {
  ContentWrapper,
  Description,
  DownIcon,
  ImageContainer,
  InfoBox,
  MobileInfoBox,
  ScrollButton,
  ScrollButtonContainer,
  ScrollText,
  StyledFlatBox,
  StyledIcon,
  StyledPhotoOverlay,
  StyledScrollText,
  Title,
  VariantsContainer,
  Wrapper,
} from './specialProducts.styled';
import { FixedScrollTopArrow } from './components/fixedScrollTopArrow/fixedScrollTopArrow.component';
import { DefaultPromoContent } from './components/defaultPromoContent/defaultPromoContent.component';
import { defaultContentMap, PopupKey } from './specialProduct.config';

export const SpecialProducts: React.FC<SpecialProductsProps> = (props: SpecialProductsProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    imageTitle,
    imageDescription,
    selectTitle,
    selectSubTitle,
    boxLabel,
    position = Direction.Left,
    img,
    icon,
    options,
    isOptional = false,
    component = new ChooseVariantFromPopupField('', '', '', '', '', false),
    handleSave = (): void => undefined,
    setValues = (): void => undefined,
    optionalLabel = t('rehau.components.special_products.optional_label'),
    actionLabel = t('rehau.components.special_products.action_label'),
    warnLabel = t('rehau.components.special_products.warn_label'),
    scrollButtonLabel = t('rehau.components.special_products.scroll_button_label'),
    errorMessage = t('rehau.global.field_required'),
    additionalInfoContent,
  }: SpecialProductsProps = props;
  const contentRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const topPopupRef: RefObject<HTMLDivElement> = useRef(null);
  const [productTabsOnTop, setProductTabsOnTop]: UseState<boolean> = useState<boolean>(false);
  const [isPopupVisible, setIsPopupVisible]: UseState<boolean> = useState<boolean>(false);
  const [activeOption, setActiveOption]: UseState<string | null> = useState<string | null>(component.value! as string);

  const selectOption: (id: string) => void = (id: string): void => {
    const value: string | null = id === activeOption ? null : id;
    setValues({ [component.id]: value });
    setActiveOption(value);
    const validationSuccess: boolean = component.validate();
    if (validationSuccess) {
      handleSave();
    }
    if (id !== activeOption) {
      setIsPopupVisible(false);
    }
  };

  const closePopupHandler: () => void = (): void => {
    component.validate();
    setIsPopupVisible(false);
  };

  const openPopupHandler: () => void = (): void => {
    setIsPopupVisible(true);
  };

  const handleReadMore: () => void = (): void => {
    contentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  const getPromoContent: () => JSX.Element = (): JSX.Element => {
    const contentProps: PopupContentProps = {
      isInPopup: true,
      tabsOnTop: productTabsOnTop,
      setTabsOnTop: setProductTabsOnTop,
      ref: contentRef,
    };

    if (!component.promoPopupContentType) {
      return (
        <DefaultPromoContent
          title={t(`rehau.components.special_products.popup_${component.id}_title`)}
          description={t(`rehau.components.special_products.popup_${component.id}_desc`)}
          {...defaultContentMap[component.id as PopupKey]}
        />
      );
    }

    switch (component.promoPopupContentType) {
      case PromoPopupContentTypeEnum.DrinkingWaterInstallation:
        return <WaterDistribution {...contentProps} />;
      case PromoPopupContentTypeEnum.HeatDistribution:
        return <HeatInstallation {...contentProps} />;
      case PromoPopupContentTypeEnum.Reguard:
        return <ReGuard {...contentProps} />;
      case PromoPopupContentTypeEnum.Resource:
        return <ReSource {...contentProps} />;
      case PromoPopupContentTypeEnum.Sewage:
        return <Sewage {...contentProps} />;
      case PromoPopupContentTypeEnum.TemperatureRegulator:
        return <TemperatureRegulator {...contentProps} />;
      default:
        return <></>;
    }
  };

  const selectedOption: SelectBarOption | undefined = options.find((option: SelectBarOption): boolean => option.id === activeOption);

  return (
    <Wrapper>
      {!!img && (
        <ImageContainer url={img}>
          <StyledPhotoOverlay>
            <InfoBox boxPosition={position}>
              <Title text={imageTitle || ''} />
              <Description>{imageDescription}</Description>
            </InfoBox>
            <MobileInfoBox>
              <Title text={imageTitle || ''} />
              <Description>{imageDescription}</Description>
            </MobileInfoBox>
          </StyledPhotoOverlay>
        </ImageContainer>
      )}
      <StyledFlatBox
        label={boxLabel}
        actionLabel={actionLabel || ''}
        labelValue={component.shouldShowSelectedValue
          ? t(selectedOption?.label || '')
          : ''
        }
        optionalLabel={isOptional ? optionalLabel : ''}
        onClick={openPopupHandler}
        icon={component.shouldShowSelectedValue && selectedOption?.icon
          ? selectedOption.icon
          : icon
        }
        price={selectedOption?.price}
        warnLabel={warnLabel}
        isActive={!!selectedOption}
        hasWarning={component.valid === false}
        errorMessage={errorMessage}
      />
      <FullscreenPopup
        header={selectTitle}
        subheader={selectSubTitle}
        isVisible={isPopupVisible}
        contentPaddingDisabled={true}
        closePopupAction={closePopupHandler}
        externalRef={topPopupRef}
        hasFixedCloseButton
        additionalInfoContent={additionalInfoContent}
      >
        <VariantsContainer>
          {options.map(
            (option: SelectBarOption): JSX.Element => (
              <SelectBarWithType
                icon={option.icon}
                price={option.price}
                title={option.label}
                key={option.id}
                type={option.productTypeTranslationKey || ''}
                active={option.id === activeOption}
                handleButtonClick={(): void => selectOption(option.id)}
                hideTypeName={option.hideTypeName}
              />
            )
          )}
        </VariantsContainer>
        {!!component.promoPopupContentType && component.promoPopupContentType !== PromoPopupContentTypeEnum.Reguard && (
          <ScrollButtonContainer>
            <ScrollText>{scrollButtonLabel}</ScrollText>
            <ScrollButton onClick={handleReadMore}>
              <StyledIcon size={16} name={IconName.ArrowBig} color={Color.Pink} />
            </ScrollButton>
          </ScrollButtonContainer>
        )}
        <ContentWrapper>
          {getPromoContent()}
          <ScrollButtonContainer>
            <ScrollButton
              onClick={(): void => {
                if (!!topPopupRef && !!topPopupRef.current) {
                  topPopupRef.current.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
                }
              }}
            >
              <DownIcon size={16} name={IconName.ArrowBig} color={Color.Pink} />
            </ScrollButton>
            {!!component.promoPopupContentType && component.promoPopupContentType !== PromoPopupContentTypeEnum.Reguard && (
              <StyledScrollText>{t('rehau.components.special_products.tooltip_content')}</StyledScrollText>
            )}
          </ScrollButtonContainer>
          <FixedScrollTopArrow contentRef={topPopupRef} />
        </ContentWrapper>
      </FullscreenPopup>
    </Wrapper>
  );
};
