import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationsConfigFile from '../../../../../../build/autogenerate/translations/_metadata.json';
import { TranslationConfig } from '../types/translationConfig.types';
import { Translation } from '../types/translation.types';
import { TranslationsResources } from '../types/translationResources.types';
import { InjectedWindowValues } from '../types/injectedWindowValues.types';
import { CountryLocaleEnum } from '@rehau/shared/enums';

declare const window: InjectedWindowValues;

const translationsConfig: TranslationConfig[] = translationsConfigFile;
const translationsLanguagesCodes: string[] = [
  ...translationsConfig.map((translationConfig: TranslationConfig): string => translationConfig.locale.code),
  CountryLocaleEnum.en_US,
];

let resources: TranslationsResources = {};

const promises: Promise<void>[] = translationsLanguagesCodes.map((translationsLanguageCode: string): Promise<void> =>
  new Promise((resolve: (value: void) => void): void => {
    resources = {
      ...resources,
      ...{
        [translationsLanguageCode]: {
          translation: {},
        },
      },
    };

    import(
      `../../../../../../build/autogenerate/translations/${translationsLanguageCode}.json`
    ).then((translations: Translation): void => {
      resources[translationsLanguageCode].translation = translations.default;
      resolve();
    });
  })
);

Promise.all(promises).then((): void => {
  i18n.emit('loaded');
});

if (!!initReactI18next) {
  i18n
    .use(initReactI18next)
    .use({
      type: 'postProcessor',
      name: 'emptyKey',
      process: (value: string, keys: string[]): string => {
        if (!keys[0].match(/^([a-zA-Z0-9\_]+\.{1}[a-zA-Z0-9\_]+)+$/)) {
          return keys[0];
        }
        if (!!keys && keys[0].length !== 0 && value.length === 0) {
          return `No translation (${keys[0]})`;
        }
        if (!!keys && keys[0].length !== 0 && value.length !== 0) {
          if (window.SHOW_TRANSLATION_KEYS === 'yes') {
            return `${value}(${keys[0]})`;
          }

          return value;
        }

        return '';
      },
    })
    .init({
      resources,
      interpolation: { escapeValue: false },
      debug: false,
      lng: window.FORCE_ENGLISH_LANGUAGE
        ? CountryLocaleEnum.en_US
        : window.MAIN_LANGUAGE || CountryLocaleEnum.pl_PL,
      fallbackLng: window.FORCE_ENGLISH_LANGUAGE
        ? CountryLocaleEnum.en_US
        : window.MAIN_LANGUAGE || CountryLocaleEnum.pl_PL,
      supportedLngs: translationsLanguagesCodes,
      preload: translationsLanguagesCodes,
      postProcess: ['emptyKey'],
    });
}

export default i18n;
