import { UserRoleEnum, UserTokenStatusEnum } from '../../../../../../shared/enums';
import { AuthContextType } from '../../types/authContext.types';
import { AuthorizationModel, CompanyModel, TokenModel, UserModel } from '../../../../../../shared/models/responses';

export const authMock: AuthContextType = [
  {
    loggedUser: {
      token: {
        token: 'xxx',
        timestamp: 1617843259182,
        status: UserTokenStatusEnum.Unblocked,
      },
      user: {
        id: 1883,
        firstName: 'super',
        lastName: 'admin',
        role: UserRoleEnum.SuperAdmin,
        roleTranslationKey: 'key',
        email: 'super.admin@test.mug.pl',
      },
      company: {
        id: 1074,
        name: 'test',
        address: 'test',
        postcode: '12345',
        city: 'test',
        phone: '123',
        email: 'super.admin@test.mug.pl',
        domain: 'test',
        logo: 'test',
        color: 'test',
        details: 'test',
        description: 'test',
        crmId: 'test',
      },
      setCompany(company: CompanyModel): AuthorizationModel {
        return this;
      },
      setToken(token: TokenModel): AuthorizationModel {
        return this;
      },
      setUser(user: UserModel): AuthorizationModel {
        return this;
      },
    } as AuthorizationModel,
  },
  (): void => undefined,
];
