import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { ValidationStatus } from '../../../enums/validationStatus.enum';
import { UseState } from '../../../types/useState.types';
import { useCountryConfig } from '../../../hooks/useCountryConfig.hook';
import { UseCountryConfig } from '../../../types/useCountryConfig.types';
import { getFieldContainerType } from '../../../components/fieldComponent/utils/getFieldContainerType';
import { ValidationMessages } from '@rehau/shared/forms/validations';
import { RangeField } from '@rehau/shared/forms/elements';
import { RadiusContainer as RadiusContainerClass } from '@rehau/shared/forms/components';
import { PriceUnitInput } from '../priceUnitInput/priceUnitInput.component';
import { InputContainer } from '../inputContainer/inputContainer.component';
import { DefaultFieldProps } from '../../../types/defaultFieldProps.types';

import { Container } from './radiusContainer.styled';

export const RadiusContainer: React.FC<DefaultFieldProps<RadiusContainerClass>> = (
  props: DefaultFieldProps<RadiusContainerClass>
): JSX.Element => {
  const {
    component = new RadiusContainerClass(),
    setValues = (): void => undefined,
  }: DefaultFieldProps<RadiusContainerClass> = props;
  const { t }: TransProps<never> = useTranslation();
  const { config }: UseCountryConfig = useCountryConfig();
  const [normalRadiusValue, setNormalRadiusValue]: UseState<string> = useState('');
  const [maximumRadiusValue, setMaximumRadiusValue]: UseState<string> = useState('');
  const [normalRadiusValidationStatus, setNormalRadiusValidationStatus]: UseState<ValidationStatus> = useState<ValidationStatus>(
    ValidationStatus.Untouched
  );
  const [maximumRadiusValidationStatus, setMaximumRadiusValidationStatus]: UseState<ValidationStatus> = useState<ValidationStatus>(
    ValidationStatus.Untouched
  );
  const [normalRadiusValidationMessages, setNormalRadiusValidationMessages]: UseState<ValidationMessages[] | undefined>
    = useState<ValidationMessages[] | undefined>();
  const [maximumRadiusValidationMessages, setMaximumRadiusValidationMessages]: UseState<ValidationMessages[] | undefined>
    = useState<ValidationMessages[] | undefined>();
  const normalRadiusField: RangeField | null = component.getNormalRadiusField();
  const maximumRadiusField: RangeField | null = component.getMaximumRadiusField();

  useEffect(
    (): void => {
      setNormalRadiusValue(normalRadiusField?.value?.toString() ?? '');
      setMaximumRadiusValue(maximumRadiusField?.value?.toString() ?? '');
    },
    [normalRadiusField, maximumRadiusField]
  );

  const validateRadiusFields: () => void = (): void => {
    const validationSuccess: boolean = component.validate();
    setMaximumRadiusValidationStatus(validationSuccess ? ValidationStatus.Valid : ValidationStatus.Invalid);
    setMaximumRadiusValidationMessages(maximumRadiusField?.validationMessages);
    setNormalRadiusValidationStatus(validationSuccess ? ValidationStatus.Valid : ValidationStatus.Invalid);
    setNormalRadiusValidationMessages(normalRadiusField?.validationMessages);
  };

  const handleBlurNormalRadiusField: () => void = (): void => {
    if (normalRadiusField) {
      setValues({ [normalRadiusField.id]: normalRadiusValue });
      validateRadiusFields();
    }
  };

  const handleBlurMaximumRadiusField: () => void = (): void => {
    if (maximumRadiusField) {
      setValues({ [maximumRadiusField.id]: maximumRadiusValue });
      validateRadiusFields();
    }
  };

  return (
    <Container>
      <InputContainer
        className={getFieldContainerType(component)}
        label={t(normalRadiusField?.label ?? '')}
        validationStatus={normalRadiusValidationStatus}
        validationMessages={normalRadiusValidationMessages}
        additionalContent={t(normalRadiusField?.informationBox?.content ?? '')}
        tooltipProps={normalRadiusField?.tooltip}
        additionalInfoContentInReadMore={t(normalRadiusField?.informationBox?.readMoreContent ?? '')}
      >
        <PriceUnitInput
          currencyPosition={config.currency.position}
          areaUnit={normalRadiusField?.rangeUnit}
          areaUnitSquared={false}
          placeholder={t(normalRadiusField?.placeholder ?? '')}
          onChange={setNormalRadiusValue}
          value={normalRadiusValue}
          onBlur={handleBlurNormalRadiusField}
        />
      </InputContainer>
      {maximumRadiusField && (
        <InputContainer
          className={getFieldContainerType(component)}
          label={t(maximumRadiusField?.label ?? '')}
          validationStatus={maximumRadiusValidationStatus}
          validationMessages={maximumRadiusValidationMessages}
          additionalInfoContent={t(maximumRadiusField?.informationBox?.content ?? '')}
          additionalInfoContentInReadMore={t(maximumRadiusField?.informationBox?.readMoreContent ?? '')}
          tooltipProps={maximumRadiusField?.tooltip}
        >
          <PriceUnitInput
            currencyPosition={config.currency.position}
            areaUnit={maximumRadiusField?.rangeUnit}
            areaUnitSquared={false}
            placeholder={t(maximumRadiusField?.placeholder ?? '')}
            onChange={setMaximumRadiusValue}
            value={maximumRadiusValue}
            onBlur={handleBlurMaximumRadiusField}
          />
        </InputContainer>
      )}
    </Container>
  );
};
