import React, { useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Location } from 'history';

import { IconName, WebsitePathNameEnum } from '../../../../../../../../shared/enums';
import { MenuGroupTuple, RouteInfo } from '../../../../../../../../shared/models/countryConfig';
import { UseState } from '../../../../types/useState.types';
import { sortGroupTuples } from '../../mainNavbar.utils';
import { ItemModal } from '../itemModal/itemModal.component';
import { findPathByName } from '../../../../utils/findPathByName.utils';
import { useRoutesInfo } from '../../../../hooks/useRoutesInfo.hook';

import { NavbarItemProps } from './navbarItem.types';
import {
  ArrowIcon,
  Container,
  ItemButton,
  ItemLabel,
  ItemLink,
  ItemUnderscore,
} from './navbarItem.styled';

export const NavbarItem: React.FC<NavbarItemProps> = (props: NavbarItemProps): JSX.Element => {
  const {
    className,
    label,
    path,
    groups,
    modalButtonLabel = '',
    pathName,
    modalButtonPathName = WebsitePathNameEnum.Configurator,
  }: NavbarItemProps = props;
  const location: Location = useLocation();
  const parsedGroups: MenuGroupTuple[] | null = !!groups ? Object.entries(groups).sort(sortGroupTuples) : null;
  const [modalOpen, setModalOpen]: UseState<boolean> = useState<boolean>(false);
  const routesArray: RouteInfo[] = useRoutesInfo();
  const [isActive, setIsActive]: UseState<boolean> = useState<boolean>(false);
  const isCurrentPageInGroups: boolean = parsedGroups
    ? !!parsedGroups.map((group: MenuGroupTuple): string[] => Object.keys(group[1]?.children || {}))
        .flat()
        .find((pathname: string): boolean =>
          !!useRouteMatch({ path: findPathByName(pathname, routesArray), strict: true, sensitive: true })?.isExact
        )
    : false;

  useEffect(
    (): void => {
      setIsActive(!!groups ? isCurrentPageInGroups : path === location.pathname);
    },
    [location.pathname]
  );

  const handleOpenModal: () => void = (): void => {
    setModalOpen(true);
  };

  const handleCloseModal: () => void = (): void => {
    setModalOpen(false);
  };

  return (
    <Container className={className}>
      {!!parsedGroups ? (
        <>
          <ItemButton data-testid='item-button' onClick={handleOpenModal}>
            <ItemLabel isActive={isActive}>
              {label}
              <ArrowIcon name={IconName.ArrowDown} size={13} />
            </ItemLabel>
            <ItemUnderscore />
          </ItemButton>
          <ItemModal
            open={modalOpen}
            onClose={handleCloseModal}
            title={label}
            groups={parsedGroups}
            buttonLabel={modalButtonLabel}
            buttonPathName={modalButtonPathName}
          />
        </>
      ) : (
        <ItemLink to={path} pathName={pathName} openInNewTab={!pathName}>
          <ItemLabel isActive={isActive}>
            {label}
          </ItemLabel>
          <ItemUnderscore />
        </ItemLink>
      )}
    </Container>
  );
};
