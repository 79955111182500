import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { BaseLabel } from '../../theme';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';

import { SingleBarProps, WrapperProps } from './progressBar.types';

export const Wrapper: StyledComponent<'div', WrapperProps> = styled.div`
  display: flex;
  align-items: center;

  ${styleWhenTrue(
    prop('stretched'),
    css`
      justify-content: space-between;
      width: 100%;
    `
  )}

  @media ${Breakpoint.Mobile} {
    flex-wrap: wrap;
  }
`;

export const BarsWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

export const Label: StyledComponent<typeof BaseLabel> = styled(BaseLabel)`
  margin-right: 15px;
  max-width: 200px;
  font-size: 14px;
  margin-bottom: 3px;
`;

export const SingleBar: StyledComponent<'div', SingleBarProps> = styled.div`
  background-color: ${Color.GreyStroke};
  width: 30px;
  height: 4px;
  margin-right: 1px;

  ${styleWhenTrue(
    prop('active'),
    css`
      background-color: ${Color.Green};
    `
  )}
`;
