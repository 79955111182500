import { HeatInstallationModel } from '@rehau/shared/models/calculation/common';
import { RangeValueModel } from '@rehau/shared/models/responses/api/common';
import { CalculationFieldEnum, HeatingAndCoolingTypeEnum, HeatInstallationTypeEnum, IconName } from '@rehau/shared/enums';

export const heatInstallationOptions: HeatInstallationModel[] = [
  new HeatInstallationModel(
    HeatingAndCoolingTypeEnum.AirPump,
    HeatInstallationTypeEnum.Heating,
    'rehau.heat_installations.air_pump.label',
    'rehau.heat_installations.air_pump.tooltip',
    new RangeValueModel(4, 0, 4),
    IconName.AirHeating
  ),
  new HeatInstallationModel(
    HeatingAndCoolingTypeEnum.GeothermalPump,
    HeatInstallationTypeEnum.Heating,
    'rehau.heat_installations.geothermal_pump.label',
    'rehau.heat_installations.geothermal_pump.tooltip',
    new RangeValueModel(4, 0, 4),
    IconName.GeothermalHeating
  ),
  new HeatInstallationModel(
    HeatingAndCoolingTypeEnum.DistrictHeating,
    HeatInstallationTypeEnum.Heating,
    'rehau.heat_installations.district_heating.label',
    'rehau.heat_installations.district_heating.tooltip',
    new RangeValueModel(2, 0, 4),
    IconName.DistrictHeating
  ),
  new HeatInstallationModel(
    HeatingAndCoolingTypeEnum.Electric,
    HeatInstallationTypeEnum.Heating,
    'rehau.heat_installations.electric.label',
    'rehau.heat_installations.electric.tooltip',
    new RangeValueModel(3, 0, 4),
    IconName.Electric
  ),
  new HeatInstallationModel(
    HeatingAndCoolingTypeEnum.Pellets,
    HeatInstallationTypeEnum.Heating,
    'rehau.heat_installations.pellets.label',
    'rehau.heat_installations.pellets.tooltip',
    new RangeValueModel(2, 0, 4),
    IconName.Pellet
  ),
  new HeatInstallationModel(
    HeatingAndCoolingTypeEnum.Wood,
    HeatInstallationTypeEnum.Heating,
    'rehau.heat_installations.wood.label',
    'rehau.heat_installations.wood.tooltip',
    new RangeValueModel(2, 0, 4),
    IconName.Wood
  ),
  new HeatInstallationModel(
    HeatingAndCoolingTypeEnum.Gas,
    HeatInstallationTypeEnum.Heating,
    'rehau.heat_installations.gas.label',
    'rehau.heat_installations.gas.tooltip',
    new RangeValueModel(3, 0, 4),
    IconName.Gas
  ),
  new HeatInstallationModel(
    HeatingAndCoolingTypeEnum.Oil,
    HeatInstallationTypeEnum.Heating,
    'rehau.heat_installations.oil.label',
    'rehau.heat_installations.oil.tooltip',
    new RangeValueModel(3, 0, 4),
    IconName.Oil
  ),
  new HeatInstallationModel(
    HeatingAndCoolingTypeEnum.HeatingAndCoolingAirPump,
    HeatInstallationTypeEnum.HeatingAndCooling,
    'rehau.heat_installations.heating_and_cooling_air_pump.label',
    'rehau.heat_installations.heating_and_cooling_air_pump.tooltip',
    new RangeValueModel(4, 0, 4),
    IconName.AirHeating
  ),
  new HeatInstallationModel(
    HeatingAndCoolingTypeEnum.HeatingAndCoolingGeothermalPump,
    HeatInstallationTypeEnum.HeatingAndCooling,
    'rehau.heat_installations.heating_and_cooling_geothermal_pump.label',
    'rehau.heat_installations.heating_and_cooling_geothermal_pump.tooltip',
    new RangeValueModel(4, 0, 4),
    IconName.GeothermalHeating
  ),
];

export const allowedRooms: CalculationFieldEnum[] = [
  CalculationFieldEnum.Bathroom,
  CalculationFieldEnum.Wc,
  CalculationFieldEnum.Kitchen,
  CalculationFieldEnum.DiningRoom,
  CalculationFieldEnum.LivingRoom,
  CalculationFieldEnum.Bedroom,
  CalculationFieldEnum.ChildRoom,
  CalculationFieldEnum.GuestRoom,
  CalculationFieldEnum.Corridors,
  CalculationFieldEnum.Office,
  CalculationFieldEnum.StorageRoom,
  CalculationFieldEnum.DressingRoom,
  CalculationFieldEnum.Garage,
];

export const coldWaterEquipment: CalculationFieldEnum[] = [
  CalculationFieldEnum.Toilet,
  CalculationFieldEnum.Urinal,
  CalculationFieldEnum.WashingMachine,
  CalculationFieldEnum.Dishwasher,
  CalculationFieldEnum.SmallWashbasinColdWater,
  CalculationFieldEnum.WashingToilet,
];

export const coldAndWarmWaterEquipment: CalculationFieldEnum[] = [
  CalculationFieldEnum.Bath,
  CalculationFieldEnum.Washbasin,
  CalculationFieldEnum.Shower,
  CalculationFieldEnum.KitchenSink,
  CalculationFieldEnum.Bidet,
];
