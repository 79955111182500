import React from 'react';

import { FullWidthImage } from '../../../products/components/fullWidthImage/fullWidthImage.component';
import { TextWithImage } from '../../../products/components/textWithImage/textWithImage.component';
import { ProductPopupContext } from '../../../../contexts/productPopup.context';

import { DefaultPromoContentProps } from './defaultPromoContent.types';

export const DefaultPromoContent: React.FC<DefaultPromoContentProps> = (
  props: DefaultPromoContentProps
): JSX.Element => {
  const { mainImg, sideImg, title, description }: DefaultPromoContentProps = props;

  return (
    <ProductPopupContext.Provider value={{ isInPopup: true }}>
      <FullWidthImage src={mainImg} alignTop />
      <TextWithImage
        sectionTitle={title}
        text={description}
        imageSrcList={[sideImg]}
      />
    </ProductPopupContext.Provider>
  );
};
