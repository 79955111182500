import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { StyledComponent } from '../../../../types/styledComponent.types';
import { Color } from '../../../../enums/color.enum';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { styleWhenTrue } from '../../../../utils/style.utils';

import { DataBoxHeaderProps, ItemsBoxProps, ItemsRowProps } from './dataBox.types';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DataBoxHeader: StyledComponent<'div', DataBoxHeaderProps> = styled.div<DataBoxHeaderProps>`
  color: ${Color.Black};
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 16px;
  display: flex;

  ${styleWhenTrue(prop('withBiggerFonts'), css`
    font-size: 20px;
  `)}

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const ItemsRow: StyledComponent<'div', ItemsRowProps> = styled.div<ItemsRowProps>`
  color: ${Color.Black};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.152381px;
  display: flex;
  justify-content: space-between;

  ${styleWhenTrue(prop('withBiggerFonts'), css`
    font-size: 20px;
  `)}

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ItemLabel: StyledComponent<'div'> = styled.div``;

export const ItemValue: StyledComponent<'div'> = styled.div`
  text-align: right;
  margin-left: 10px;

  @media ${Breakpoint.Mobile} {
    color: ${Color.Grey};
    margin-left: 0;
    text-align: left;
  }
`;

export const ItemsBox: StyledComponent<'div', ItemsBoxProps> = styled.div<ItemsBoxProps>`
  padding: 25px;
  height: 100%;
  background-color: ${Color.BgGrey};

  ${styleWhenTrue(prop('withCalculatedHeight'), css`
    height: calc(100% - 30px);
    padding: 25px 25px 0;
  `)}

  ${ItemsRow} + ${ItemsRow} {
    margin-top: 10px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 20px;
  }
`;

export const DateUpdate: StyledComponent<'div'> = styled.div`
  color: ${Color.Grey};
  margin-left: 5px;

  @media ${Breakpoint.Mobile} {
    margin-left: 0;
  }
`;
