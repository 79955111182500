import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Color } from '../../../../enums/color.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { InnerContainer, OuterContainer } from '../../../../theme/containers.styled';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { styleWhenTrue } from '../../../../utils/style.utils';
import { Title as TitleBase } from '../title/title.component';
import { Markdown } from '../../../markdown/markdown.component';

import { MarkdownProps } from './imageDescription.types';

export const Container: StyledComponent<typeof OuterContainer> = styled(OuterContainer)`
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: ${Color.BgGrey};

  @media ${Breakpoint.Tablet} {
    margin: 0;
    width: 100%;
    padding: 60px 40px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 60px 20px;
  }
`;

export const Wrapper: StyledComponent<typeof InnerContainer> = styled(InnerContainer)`
  flex-direction: column;
`;

export const Title: StyledComponent<typeof TitleBase> = styled(TitleBase)`
  margin-bottom: 68px;

  @media ${Breakpoint.Tablet} {
    margin-bottom: 60px;
  }

  @media ${Breakpoint.Mobile} {
    margin-bottom: 40px;
  }
`;

export const Content: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
  }
`;

export const ImageContainer: StyledComponent<'div'> = styled.div`
  padding-right: 130px;
  max-width: 620px;

  @media ${Breakpoint.Desktop} {
    padding-right: 100px;
  }

  @media ${Breakpoint.Tablet} {
    padding-right: 0;
    margin: 0 auto 85px;
    max-width: 500px;
  }

  @media ${Breakpoint.Mobile} {
    margin: 0 auto 40px;
  }
`;

export const Image: StyledComponent<'img'> = styled.img`
  width: 100%;
`;

export const Pointers: StyledComponent<'div'> = styled.div`
  width: 350px;
  flex-shrink: 0;

  @media ${Breakpoint.Tablet} {
    width: 100%;
  }
`;

export const Pointer: StyledComponent<'div'> = styled.div`
  background-color: ${Color.White};
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  position: relative;

  & + & {
    margin-top: 25px;
  }
`;

export const Counter: StyledComponent<'p'> = styled.p`
  width: 48px;
  height: 48px;
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: ${Color.Green};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.White};
  font-size: 24px;
  font-weight: ${FontWeight.Black};
`;

export const PointerImage: StyledComponent<'img'> = styled.img`
  display: block;
  margin: 30px auto;
  max-width: 100%;
`;

export const StyledMarkdown: StyledComponent<typeof Markdown, MarkdownProps> = styled(Markdown)`
  font-size: 18px;
  line-height: 22px;

  ${styleWhenTrue(prop('withoutImage'), css`
    margin-top: 80px;
  `)}

  strong {
    font-weight: ${FontWeight.Bold};
  }
`;
