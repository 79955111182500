import React, { RefObject } from 'react';

import { useReveal } from '@rehau-fe/ui/src';

import { SectionHeaderProps } from './sectionHeader.types';
import { Container, StyledSectionHeader } from './sectionHeader.styled';

export const SectionHeader: React.FC<SectionHeaderProps> = (props: SectionHeaderProps): JSX.Element => {
  const { children, className }: SectionHeaderProps = props;
  const textRef: RefObject<HTMLDivElement> = useReveal();

  return (
    <Container className={className}>
      <StyledSectionHeader ref={textRef}>{children}</StyledSectionHeader>
    </Container>
  );
};
