import { MouseEventHandler, SyntheticEvent, useCallback, useEffect, useState } from 'react';

import { ActiveItemsState, InitValues, UseMultiSelect, UseMultiSelectOutput } from './useMultiSelect.types';

export const useMultiSelect: UseMultiSelect = (initValues: InitValues): UseMultiSelectOutput => {
  const [activeItems, setActiveItems]: ActiveItemsState = useState<string[]>([...initValues]);

  const setActiveItem: MouseEventHandler = useCallback(
    (event: SyntheticEvent): void => {
      const prevActiveItems: string[] = [...activeItems];
      let currentAcitveItems: string[] = [];
      const clickedItem: string = event.currentTarget.id;
      if (prevActiveItems.includes(clickedItem)) {
        currentAcitveItems = prevActiveItems.filter((item: string): boolean => item !== clickedItem);
      } else {
        currentAcitveItems = [...prevActiveItems, clickedItem];
      }
      setActiveItems(currentAcitveItems);
    },
    [activeItems]
  );

  return [activeItems, setActiveItem];
};
