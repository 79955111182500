import { SlidesActions } from '../enums/slidesActions.enum';
import { SlideAction, SlidesReducer, SlidesState } from '../types/slidesReducer.types';

export const slidesReducer: SlidesReducer = (state: SlidesState, action: SlideAction): SlidesState => {
  switch (action.type) {
    case SlidesActions.SetSleftPosition:
      return { ...state, sPosition: action.payload };
    case SlidesActions.SetSmPositions:
      return { ...state, smPosition: action.payload };
    case SlidesActions.SetMlPositions:
      return { ...state, mlPosition: action.payload };
    case SlidesActions.SetLRightPosition:
      return { ...state, lPosition: action.payload };
    default:
      return state;
  }
};
