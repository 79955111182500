import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { Button } from '../../button/button.component';
import { InfoBase } from '../infoBase/infoBase.component';
import { Description, Title, Wrapper } from '../infoScreens.styled';
import EmailImage from '../img/emailImage.svg';

import { EmailScreenProps } from './emailScreen.types';

export const EmailScreen: React.FC<EmailScreenProps> = (props: EmailScreenProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    title,
    description,
    handleButtonClick,
    buttonLabel,
    hasBar = false,
    hasButton = false,
  }: EmailScreenProps = props;

  return (
    <InfoBase imgUrl={EmailImage} hasBar={hasBar}>
      <Wrapper data-testid='error-screen'>
        <Title>{title}</Title>
        <Description text={description} />
        {hasButton && (
          <Button text={buttonLabel} onClick={handleButtonClick}/>
        )}
      </Wrapper>
    </InfoBase>
  );
};
