import { IntervalEnum } from '@rehau/shared/enums';

export interface FiltersDropdownProps {
  onFilter: (period: string | null, startDate: number | null, endDate: number | null) => void;
  className?: string;
  initialSelectedOption?: string;
  initialSelectedStartDate?: number;
  initialSelectedEndDate?: number;
}

export interface OptionProps {
  $checked: boolean;
}

enum ExtendedPeriodOption { CustomPeriod = 'customPeriod' }

type PeriodOptionType = {
  [key in keyof typeof IntervalEnum]: IntervalEnum
} & {
  [key in keyof typeof ExtendedPeriodOption]: ExtendedPeriodOption
};

export const PeriodOption: PeriodOptionType = {
  ...IntervalEnum,
  ...ExtendedPeriodOption,
};

export interface CustomIntervalSettings {
  dateFrom: number;
  dateTo: number;
}
