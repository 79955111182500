import { AxiosResponse } from 'axios';

import { fieldValueType } from '@rehau/shared/forms/types';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { api } from '@rehau-fe/app/api/api.service';
import { ApiReject, ApiResolve } from '@rehau-fe/app/api/api.types';

export const registerNewInstaller: (data: ObjectIteratorInterface<fieldValueType>) => Promise<boolean> = (
  data: ObjectIteratorInterface<fieldValueType>
): Promise<boolean> =>
  new Promise(
    (resolve: ApiResolve<boolean>, reject: ApiReject<void>): Promise<void> =>
      api
        .post('/registration', data)
        .then((response: AxiosResponse<boolean>): void => {
          resolve(response?.data);
        })
        .catch((error: AxiosResponse<void>): void => reject(error))
  );

export const checkAccountActivationStatus: (token: string) => Promise<void> = (token: string): Promise<void> =>
  new Promise(
    (resolve: ApiResolve<void>, reject: ApiReject<void>): Promise<void> =>
      api
        .post(`/registration/activate/${token}`)
        .then((response: AxiosResponse<void>): void => {
          resolve(response?.data);
        })
        .catch((error: AxiosResponse<void>): void => reject(error))
  );

export const remindPassword: (data: ObjectIteratorInterface<fieldValueType>) => Promise<void> = (
  data: ObjectIteratorInterface<fieldValueType>
): Promise<void> =>
  new Promise(
    (resolve: ApiResolve<void>, reject: ApiReject<void>): Promise<void> =>
      api
        .post('/password/reset', data)
        .then((response: AxiosResponse<void>): void => {
          resolve(response?.data);
        })
        .catch((error: AxiosResponse<void>): void => reject(error))
  );

export const setNewPassword: (data: ObjectIteratorInterface<fieldValueType>) => Promise<void> = (
  data: ObjectIteratorInterface<fieldValueType>
): Promise<void> =>
  new Promise(
    (resolve: ApiResolve<void>, reject: ApiReject<void>): Promise<void> =>
      api
        .post('/password/set-new', data)
        .then((response: AxiosResponse<void>): void => {
          resolve(response?.data);
        })
        .catch((error: AxiosResponse<void>): void => reject(error))
  );
