import styled from 'styled-components';

import { StyledComponent } from '../../types/styledComponent.types';

import { Slide } from './slide/slide.component';

export const StyledSlide: StyledComponent<typeof Slide> = styled(Slide)``;

export const Wrapper: StyledComponent<'div'> = styled.div`
  width: fit-content;

  ${StyledSlide} + ${StyledSlide} {
    margin-top: 60px;
  }
`;
