import React, { RefObject } from 'react';

import { AdvantageElement } from '../advantageElement/advantageElement.component';
import { useReveal } from '../../hooks/animations/useReveal';

import { AdvantageSectionProps } from './advantageSection.types';
import { ElementsSection, Header, HeaderContainer, Wrapper } from './advantageSection.styled';

export const AdvantageSection: React.FC<AdvantageSectionProps> = (props: AdvantageSectionProps): JSX.Element => {
  const {
    title,
    options,
    hasHeaderSmallerMargin = false,
    hasSmallerMarginBottomWrapper = false,
    hasBiggerPaddingWrapper = false,
  }: AdvantageSectionProps = props;
  const textRef: RefObject<HTMLDivElement> = useReveal();

  return (
    <Wrapper
      hasSmallerMarginBottomWrapper={hasSmallerMarginBottomWrapper}
      hasBiggerPaddingWrapper={hasBiggerPaddingWrapper}
    >
      <HeaderContainer hasHeaderSmallerMargin={hasHeaderSmallerMargin}>
        <Header ref={textRef}>{title}</Header>
      </HeaderContainer>
        <ElementsSection>
          {options.map((option: string, index: number): JSX.Element => (
            <AdvantageElement content={option} key={index}/>
          ))}
        </ElementsSection>
    </Wrapper>
  );
};
