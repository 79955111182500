import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { FontWeight } from '../../../../enums/fontWeight.enum';
import { BoxShadow } from '../../../../enums/boxShadow.enum';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Color } from '../../../../enums/color.enum';
import { GridWidth } from '../../../../enums/gridWidth.enum';
import { ZIndex } from '../../../../enums/zIndex.enum';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { hexToRgba } from '../../../../utils/colors.utils';
import { styleWhenTrue } from '../../../../utils/style.utils';
import { IconWithTooltip } from '../../../iconWithTooltip/iconWithTooltip.component';

import { MenuContainerProps } from './fixedMenu.types';

export const MenuContainer: StyledComponent<'div', MenuContainerProps> = styled.div<MenuContainerProps>`
  background-color: ${Color.White};
  box-shadow: ${BoxShadow.BtnGreyShadow};
  transition: 0.3s;
  transform: translateY(-100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${ZIndex.ExpandedStepsNavigation};
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  ${styleWhenTrue(
    prop('isVisible'),
    css`
      transform: translateY(0);
    `
  )}
  @media ${Breakpoint.Desktop} {
    padding: 0 60px;
  }
  @media ${Breakpoint.Tablet} {
    padding: 0 40px;
  }
  @media ${Breakpoint.Mobile} {
    padding: 0 30px;
  }
`;

export const FixedMenuInnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${GridWidth.Desktop};
  margin: 0 auto;
  padding: 40px 0 80px;
  width: 100%;
`;

export const Header: StyledComponent<'div'> = styled.div`
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  color: ${Color.Black};
  padding-bottom: 25px;
  border-bottom: 1px solid ${Color.GreyStroke};
  display: flex;
  justify-content: space-between;
`;

export const HeaderSection: StyledComponent<'div'> = styled.div``;

export const MenuSection: StyledComponent<'div'> = styled.div`
  display: flex;
  margin-top: 40px;
  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const MenuColumn: StyledComponent<'div'> = styled.div`
  width: 50%;
  @media ${Breakpoint.Mobile} {
    width: 100%;
    & + & {
      margin-top: 15px;
    }
  }
`;

export const PositionIndex: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 22px;
  color: ${Color.Black};
  margin-right: 20px;
`;

export const PositionLabel: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 22px;
  color: ${Color.Grey};
  transition: 0.3s;
`;

export const MenuPosition: StyledComponent<'button'> = styled.button`
  display: flex;
  align-items: center;
  & + & {
    margin-top: 15px;
  }
  &:hover {
    ${PositionLabel} {
      color: ${Color.Black};
    }
  }
`;

export const CloseButton: StyledComponent<'button'> = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const StyledIconWithTooltip: StyledComponent<typeof IconWithTooltip> = styled(IconWithTooltip)`
  margin-left: 10px;
`;
