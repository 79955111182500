import styled from 'styled-components';

import { Color } from '../../enums/color.enum';
import { ZIndex } from '../../enums/zIndex.enum';
import { StyledComponent } from '../../types/styledComponent.types';

export const Container: StyledComponent<'div'> = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: ${ZIndex.Minimum};
  font-size: 10px;
  width: max-content;
  background-color: ${Color.White};
`;
