import React, { RefObject, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { UseState } from '../../../../types/useState.types';
import { useScrollLock } from '../../../../hooks/useScrollLock';
import { Overlay } from '../../../../theme/overlay/overlay.styled';
import { Breakpoint } from '../../../../enums/breakpoint.enum';

import { PhotoWithModalProps } from './photoWithModal.types';
import { Container, Image, ImageInsideModal, Modal, ModalContainer, ModalInnerContainer, StyledCloseButton } from './photoWithModal.styles';

export const PhotoWithModal: React.FC<PhotoWithModalProps> = (props: PhotoWithModalProps): JSX.Element => {
  const { imageSrc }: PhotoWithModalProps = props;
  const [showModal, setShowModal]: UseState<boolean> = useState<boolean>(false);
  const containerRef: RefObject<HTMLDivElement> = useRef(null);
  useScrollLock(containerRef, showModal);
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });

  const openModal: () => void = (): void => {
    setShowModal(true);
  };

  const closeModal: () => void = (): void => {
    setShowModal(false);
  };

  return (
    <Container>
        <ModalContainer isOpen={showModal}>
          <Overlay isActive={showModal} />
          <Modal ref={isMobile ? null : containerRef}>
            <ModalInnerContainer>
              <StyledCloseButton onClick={closeModal} data-testid='icon' />
              <ImageInsideModal src={imageSrc} data-testid='modal-image' />
            </ModalInnerContainer>
          </Modal>
        </ModalContainer>
        <Image src={imageSrc} onClick={openModal} data-testid='image' />
    </Container>
  );
};
