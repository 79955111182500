import styled, { css, StyledComponent } from 'styled-components';
import { prop } from 'ramda';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { styleWhenTrue } from '../../utils/style.utils';

import { LogoImgProps } from './logo.types';

export const LogoImg: StyledComponent<'img', {}, LogoImgProps, never> = styled.img`
  width: 87px;
  flex-shrink: 0;
  ${styleWhenTrue(prop('full'), css`
    width: 250px;
    height: 50px;
  `)}
  @media ${Breakpoint.Desktop} {
    width: 87px;
    height: 33px;
    ${styleWhenTrue(prop('full'), css`
      width: 183px;
      height: 33px;
    `)}
  }
`;
