import { prop } from 'ramda';
import styled, { css, StyledComponent } from 'styled-components';

import { ToggleSwitch } from '../toggleSwitch/toggleSwitch.component';
import { FontWeight } from '../../enums/fontWeight.enum';
import { BoxShadow } from '../../enums/boxShadow.enum';
import { Color } from '../../enums/color.enum';
import { hexToRgba } from '../../utils/colors.utils';
import { styleWhenTrue } from '../../utils/style.utils';
import { Icon } from './../icon/icon.component';
import { AdditionalInfo } from '../additionalInfo/additionalInfo.component';
import { Breakpoint } from '../../enums/breakpoint.enum';

import { ArrowIconProps, ContainerProps, HeaderBoxProps, LabelProps } from './conditionalExpandedBox.types';

export const Container: StyledComponent<'div', {}, ContainerProps, never> = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: ${BoxShadow.BtnGreyShadow};

  ${styleWhenTrue(
    prop('isValid'),
    css`
      border: 1px solid ${Color.Green};
      box-shadow: ${BoxShadow.BtnGreenShadow};
    `
  )}

  ${styleWhenTrue(
    prop('invalid'),
    css`
      border: 1px solid ${hexToRgba(Color.Pink, 0.35)};
      box-shadow: none;
    `
  )}
`;

export const StyledToggleSwitch: StyledComponent<typeof ToggleSwitch, {}, {}, never> = styled(ToggleSwitch)``;

export const HeaderBox: StyledComponent<'div', {}, HeaderBoxProps, never> = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 50px);
  height: 100px;
  padding-left: 20px;
  padding-right: 30px;
  background-color: ${Color.White};
  cursor: pointer;

  ${styleWhenTrue(prop('invalid'), css`
    background-color: ${Color.OpacityPink};
  `)}

  @media ${Breakpoint.Mobile} {
    flex-wrap: wrap;
    height: auto;
    padding-bottom: 15px;
  }
`;

export const SwitchContainer: StyledComponent<'div', {}, {}, never> = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderIcon: StyledComponent<typeof Icon, {}, {}, never> = styled(Icon)`
  margin-right: 12px;
`;

export const IconWithLabel: StyledComponent<'div', {}, {}, never> = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  @media ${Breakpoint.Mobile} {
    margin-left: -11px;
  }
`;

export const ExpandButton: StyledComponent<'button', {}, {}, never> = styled.button``;

export const ArrowIcon: StyledComponent<typeof Icon, {}, ArrowIconProps, never> = styled(Icon)`
  transition: 0.3s;
  display: block;
  ${styleWhenTrue(
    prop('isRotated'),
    css`
      transform: rotate(-180deg);
    `
  )};
`;

export const Label: StyledComponent<'div', {}, LabelProps, never> = styled.div<LabelProps>`
  font-size: 18px;
  line-height: 22px;
  color: ${Color.Grey};

  ${styleWhenTrue(prop('isActive'), css`
    color: ${Color.Black};
    font-weight: ${FontWeight.Bold};
  `)};
`;

export const Content: StyledComponent<'div', {}, {}, never> = styled.div`
  width: 100%;
  border-top: 1px solid ${Color.GreyStroke};
  background-color: ${Color.White};
`;

export const InnerContent: StyledComponent<'div', {}, {}, never> = styled.div`
  padding: 60px 80px 80px;
  width: calc(100% - 160px);

  @media ${Breakpoint.Mobile} {
    width: 100%;
    padding: 40px 20px;
    box-sizing: border-box;
  }
`;

export const StyledAdditionalInfo: StyledComponent<typeof AdditionalInfo, {}, {}, never> = styled(AdditionalInfo)`
  margin-bottom: 60px;
`;
