import React, { forwardRef, useRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';

import { fieldValueType } from '../../../../../../../../shared/forms/types';
import { ObjectIteratorInterface } from '../../../../../../../../shared/objectIterator.interface';
import { ProductTableNameEnum } from '../../../../../../../../shared/enums';
import { useCountryConfig } from '../../../../hooks/useCountryConfig.hook';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { ProductTable } from '../../../productTable/productTable.component';
import { ConfiguratorPreview } from '../../../configuratorPreview/configuratorPreview.component';
import { TextWithBox } from '../../components/textWithBox/textWithBox.component';
import { TextWithColumns } from '../../components/textWithColumns/textWithColumns.component';
import { ColumnType } from '../../components/textWithColumns/textWithColumns.enums';
import { TextWithVideo } from '../../components/textWithVideo/textWithVideo.component';
import { TextWithImage } from '../../components/textWithImage/textWithImage.component';
import { SimpleText } from '../../components/simpleText/simpleText.component';
import { FullWidthImage } from '../../components/fullWidthImage/fullWidthImage.component';
import { Tabs } from '../../components/tabs/tabs.component';
import EngineerImage from '../../images/engineer.jpg';
import WaterImage from '../../images/waterWithVegetables.jpg';
import GirlImage from '../../images/girlWithWater.jpg';
import { useActiveTab } from '../../hooks/useActiveTab.hooks';
import { UseActiveTab, UseCalculatorNavigate } from '../../products.types';
import { sectionVisibilityThreshold } from '../../products.constants';
import { UseCountryConfig } from '../../../../types/useCountryConfig.types';
import { OptionTab } from '../../components/tabs/tabs.types';
import { useCalculatorNavigate } from '../../hooks/useCalculatorNavigate.hook';
import { ProductPopupContext } from '../../../../contexts/productPopup.context';

import { Container, TableContainer, TableWrapper } from './waterDistribution.styled';
import { SectionId } from './waterDistribution.enums';
import { WaterDistributionProps } from './waterDistribution.types';

export const WaterDistribution: React.FC<WaterDistributionProps> = forwardRef((
  props: WaterDistributionProps, ref: React.ForwardedRef<{}>
): JSX.Element => {
  const { className, tabsOnTop, setTabsOnTop, isInPopup = false }: WaterDistributionProps = props;
  const { t }: TransProps<never> = useTranslation();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const isDesktop: boolean = useMediaQuery({ query: Breakpoint.Desktop });
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();
  const products: ObjectIteratorInterface<fieldValueType> = isConfigAvailable ? config.variables.products : {};
  const [activeTabId, setActiveTabId]: UseActiveTab = useActiveTab('');
  const { navigateToCalculator }: UseCalculatorNavigate = useCalculatorNavigate();
  const qualityRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const hygieneRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const safetyRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const energyRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const montageRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const calculatorRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);

  const getCurrentRefByActiveId: (activeTab: string) => React.RefObject<InView & HTMLDivElement> =
    (activeTab: string): React.RefObject<InView & HTMLDivElement> => {
      switch (activeTab) {
        case SectionId.Quality:
          return qualityRef;
        case SectionId.Hygiene:
          return hygieneRef;
        case SectionId.Safety:
          return safetyRef;
        case SectionId.Energy:
          return energyRef;
        case SectionId.Montage:
          return montageRef;
        default:
          return calculatorRef;
      }
    };

  const handleScroll: (tabId: string) => void = (tabId: string): void => {
    const activeRef: React.RefObject<InView & HTMLDivElement> = getCurrentRefByActiveId(tabId);
    activeRef.current?.node?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ProductPopupContext.Provider value={{ isInPopup }}>
      <Container className={className} data-testid='water-distribution' ref={ref as React.RefObject<HTMLDivElement>}>
        <FullWidthImage src={WaterImage} />
        <TextWithBox
          title={t('rehau.products.water_distribution.header.title')}
          text={t('rehau.products.water_distribution.header.content')}
          boxTitle={t('rehau.products.water_distribution.header.box_title')}
          boxText={t('rehau.products.water_distribution.header.box_content')}
          isTopSection
        />
        <Tabs
          options={[
            { label: t('rehau.products.water_distribution.tabs.quality'), id: SectionId.Quality },
            { label: t('rehau.products.water_distribution.tabs.hygiene'), id: SectionId.Hygiene },
            { label: t('rehau.products.water_distribution.tabs.safety'), id: SectionId.Safety },
            { label: t('rehau.products.water_distribution.tabs.energy'), id: SectionId.Energy },
            { label: t('rehau.products.water_distribution.tabs.montage'), id: SectionId.Montage },
            !isInPopup ? { label: t('rehau.products.water_distribution.tabs.calculator'), id: SectionId.Calculator } : {} as OptionTab,
          ]}
          activeId={activeTabId}
          isOnTop={tabsOnTop}
          setOnTop={setTabsOnTop}
          handleScroll={handleScroll}
        />
        <InView
          as='div'
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Quality)}
          id={SectionId.Quality}
          threshold={sectionVisibilityThreshold}
          ref={qualityRef}
        >
          <TextWithVideo
            title={t('rehau.products.water_distribution.quality.title')}
            text={t('rehau.products.water_distribution.quality.content')}
            videoId={String(products['water.sections.quality.videoHash'])}
            darkBackground
          />
        </InView>
        <FullWidthImage src={GirlImage} />
        <InView
          as='div'
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Hygiene)}
          id={SectionId.Hygiene}
          threshold={isMobile ? 0.4 : sectionVisibilityThreshold}
          ref={hygieneRef}
        >
          <TextWithColumns
            title={t('rehau.products.water_distribution.hygiene.title')}
            text={t('rehau.products.water_distribution.hygiene.content')}
            columnType={ColumnType.Description}
            columnsTitle={t('rehau.products.water_distribution.hygiene.columns_title')}
            columnsData={[
              {
                title: t('rehau.products.water_distribution.hygiene.movement_title'),
                text: t('rehau.products.water_distribution.hygiene.movement_content'),
              },
              {
                title: t('rehau.products.water_distribution.hygiene.isolation_title'),
                text: t('rehau.products.water_distribution.hygiene.isolation_content'),
              },
              {
                title: t('rehau.products.water_distribution.hygiene.tightness_title'),
                text: t('rehau.products.water_distribution.hygiene.tightness_content'),
              },
            ]}
          />
        </InView>
        <InView
          as='div'
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Safety)}
          id={SectionId.Safety}
          threshold={sectionVisibilityThreshold}
          ref={safetyRef}
        >
          <TextWithImage
            title={t('rehau.products.water_distribution.safety.title')}
            text={t('rehau.products.water_distribution.safety.content')}
            imageSrcList={[EngineerImage]}
            darkBackground
          />
        </InView>
        <InView
          as='div'
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Energy)}
          id={SectionId.Energy}
          threshold={isDesktop ? sectionVisibilityThreshold : 1}
          ref={energyRef}
        >
          <SimpleText
            title={t('rehau.products.water_distribution.energy.title')}
            text={t('rehau.products.water_distribution.energy.content')}
          />
        </InView>
        <InView
          as='div'
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Montage)}
          id={SectionId.Montage}
          threshold={isDesktop ? sectionVisibilityThreshold : 1}
          ref={montageRef}
        >
          <TableContainer>
            <TableWrapper>
              <ProductTable tableType={ProductTableNameEnum.Water} tableTitle={t('rehau.products.water_distribution.table.title')} />
            </TableWrapper>
          </TableContainer>
        </InView>
        {!isInPopup && (
          <InView
            as='div'
            onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Calculator)}
            id={SectionId.Calculator}
            threshold={sectionVisibilityThreshold}
            ref={calculatorRef}
          >
            <ConfiguratorPreview buttonOnClick={navigateToCalculator} />
          </InView>
        )}
      </Container>
    </ProductPopupContext.Provider>
  );
});
