export enum CalculationConfigAtHomeKeyEnum {
  // settings
  HouseSizeMinimum = 'settings_house_size_min',
  HouseSizeMaximum = 'settings_house_size_max',
  // heat installation
  HeatInstallationHeatingPrice = 'variant_heat_installation_heating_price',
  // heat installation : heat distribution
  HeatInstallationDistributionClassicActive = 'variant_heat_installation_distribution_classic_active',
  HeatInstallationDistributionClassicPrice = 'variant_heat_installation_distribution_classic_price',
  HeatInstallationDistributionSmartActive = 'variant_heat_installation_distribution_smart_active',
  HeatInstallationDistributionSmartPrice = 'variant_heat_installation_distribution_smart_price',
  HeatInstallationDistributionPremiumActive = 'variant_heat_installation_distribution_premium_active',
  HeatInstallationDistributionPremiumPrice = 'variant_heat_installation_distribution_premium_price',
  // heat installation : heat regulation
  HeatInstallationControllerClassicActive = 'variant_heat_installation_controller_classic_active',
  HeatInstallationControllerClassicPrice = 'variant_heat_installation_controller_classic_price',
  HeatInstallationControllerSmartActive = 'variant_heat_installation_controller_smart_active',
  HeatInstallationControllerSmartPrice = 'variant_heat_installation_controller_smart_price',
  HeatInstallationControllerPremiumActive = 'variant_heat_installation_controller_premium_active',
  HeatInstallationControllerPremiumPrice = 'variant_heat_installation_controller_premium_price',
  // heat installation : solar & photovoltaic
  HeatInstallationSolarSystemActive = 'variant_heat_installation_solar_system_active',
  HeatInstallationSolarSystemPrice = 'variant_heat_installation_solar_system_price',
  HeatInstallationPhotovoltaicActive = 'variant_heat_installation_photovoltaic_active',
  HeatInstallationPhotovoltaicPrice = 'variant_heat_installation_photovoltaic_price',
  // heat installation : ventilation system
  VentilationSystemClassicActive = 'variant_ventilation_system_classic_active',
  VentilationSystemClassicPrice = 'variant_ventilation_system_classic_price',
  VentilationSystemPremiumActive = 'variant_ventilation_system_premium_active',
  VentilationSystemPremiumPrice = 'variant_ventilation_system_premium_price',
  // water distribution
  WaterDistributionClassicActive = 'variant_water_distribution_classic_active',
  WaterDistributionClassicPrice = 'variant_water_distribution_classic_price',
  WaterDistributionSmartActive = 'variant_water_distribution_smart_active',
  WaterDistributionSmartPrice = 'variant_water_distribution_smart_price',
  WaterDistributionPremiumActive = 'variant_water_distribution_premium_active',
  WaterDistributionPremiumPrice = 'variant_water_distribution_premium_price',
  // water distribution :  additional products
  WaterDistributionFreshWaterModuleActive = 'variant_water_distribution_fresh_water_module_active',
  WaterDistributionFreshWaterModulePrice = 'variant_water_distribution_fresh_water_module_price',
  WaterDistributionDecalcificationActive = 'variant_water_distribution_decalcification_active',
  WaterDistributionDecalcificationPrice = 'variant_water_distribution_decalcification_price',
  WaterDistributionCirculationActive = 'variant_water_distribution_circulation_active',
  WaterDistributionCirculationPrice = 'variant_water_distribution_circulation_price',
  WaterReducerWithFilterActive = 'variant_water_distribution_water_reducer_active',
  WaterReducerWithFilterPrice = 'variant_water_distribution_water_reducer_price',
  // smart home : reguard
  SmartHomeReGuardActive = 'variant_smart_home_reguard_active',
  SmartHomeReGuardMontage1Price = 'variant_smart_home_reguard_montage_1_price',
  SmartHomeReGuardMontage2Price = 'variant_smart_home_reguard_montage_2_price',
  SmartHomeReGuardMontage3Price = 'variant_smart_home_reguard_montage_3_price',
  // smart home : resource
  SmartHomeReSourceClassicActive = 'variant_smart_home_resource_classic_active',
  SmartHomeReSourceSmartActive = 'variant_smart_home_resource_smart_active',
  SmartHomeReSourcePremiumActive = 'variant_smart_home_resource_premium_active',
  SmartHomeReSourceClassicPrice = 'variant_smart_home_resource_classic_price',
  SmartHomeReSourceSmartPrice = 'variant_smart_home_resource_smart_price',
  SmartHomeReSourcePremiumPrice = 'variant_smart_home_resource_premium_price',
  // sewage
  SewageClassicActive = 'variant_sewage_classic_active',
  SewageClassicPrice = 'variant_sewage_classic_price',
  SewagePremiumActive = 'variant_sewage_premium_active',
  SewagePremiumPrice = 'variant_sewage_premium_price',
  // custom overrides
  InstallerFieldHidden = 'installer_field_hidden',
}
