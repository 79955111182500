// https://www.iana.org/time-zones
export enum TimezoneEnum {
  Default = 'UTC',
  Vienna = 'Europe/Vienna',
  Sofia = 'Europe/Sofia',
  Prague = 'Europe/Prague',
  Berlin = 'Europe/Berlin',
  Athens = 'Europe/Athens',
  Budapest = 'Europe/Budapest',
  Chisinau = 'Europe/Chisinau',
  Warsaw = 'Europe/Warsaw',
  Bucharest = 'Europe/Bucharest',
}
