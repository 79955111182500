import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { fieldValueType } from '@rehau/shared/forms/types';
import {
  CustomRegexpValidationItem,
  DiscountValidation,
  NumberValidation,
  StringValidation,
  ValidationStatus
} from '@rehau/shared/forms/validations';
import { ErrorMessageEnum, FormValidationRuleEnum, OfferStatus } from '@rehau/shared/enums';

export abstract class ValidationAbstract {
  public rules: ObjectIteratorInterface<number | undefined>;
  public rulesMessage: ObjectIteratorInterface<ErrorMessageEnum | undefined>;
  public customRegexpItems: CustomRegexpValidationItem[];

  protected constructor(
    rules: ObjectIteratorInterface<number | undefined> = {},
    rulesMessage: ObjectIteratorInterface<ErrorMessageEnum | undefined> = {},
    customRegexpItems: CustomRegexpValidationItem[] = []
  ) {
    this.rules = rules;
    this.rulesMessage = rulesMessage;
    this.customRegexpItems = customRegexpItems;
  }

  public check(
    value: fieldValueType,
    required: boolean = false,
    isSetupField: boolean = false,
    previousValue?: fieldValueType
  ): ValidationStatus {
    const validationStatus: ValidationStatus = new ValidationStatus(true);
    if (!required && String(value).length === 0) {
      return validationStatus;
    }
    if (
      (this instanceof NumberValidation && (
        typeof Number(value) !== 'number' && value !== null
      )) ||
      (this instanceof StringValidation && (
        typeof String(value) !== 'string' && value !== null
      ))
    ) {
      validationStatus.failed(ErrorMessageEnum.fields_wrong_value);
    } else if (!value && value !== 0) {
      if (required) {
        if (this.rulesMessage[FormValidationRuleEnum.isRequired]) {
          validationStatus.failed(
            this.rulesMessage[FormValidationRuleEnum.isRequired] ?? (isSetupField && previousValue
              ? ErrorMessageEnum.fields_required_value_restored
              : ErrorMessageEnum.fields_required)
          );
        } else if (this instanceof DiscountValidation) {
          validationStatus.failed(
            isSetupField && previousValue
              ? ErrorMessageEnum.fields_required_value_restored
              : ErrorMessageEnum.fields_required
          );
        } else if (this instanceof NumberValidation) {
          validationStatus.failed(
            isSetupField && previousValue
              ? ErrorMessageEnum.number_fields_required_value_restored
              : ErrorMessageEnum.fields_required
          );
        } else {
          validationStatus.failed(
            isSetupField && previousValue
              ? ErrorMessageEnum.fields_required_value_restored
              : ErrorMessageEnum.fields_required
          );
        }
      }
    } else {
      if (this.rules) {
        for (const ruleType in this.rules) {
          if (ruleType in this.rules) {
            switch (ruleType) {
              case FormValidationRuleEnum.minLength:
                if (String(value).length < Number(this.rules[ruleType])) {
                  validationStatus.failed(
                    this.rulesMessage[ruleType] ?? (isSetupField && previousValue
                      ? ErrorMessageEnum.fields_value_too_short_and_value_restored
                      : ErrorMessageEnum.fields_value_too_short)
                  );
                }
                break;
              case FormValidationRuleEnum.maxLength:
                if (String(value).length > Number(this.rules[ruleType])) {
                  validationStatus.failed(
                    this.rulesMessage[ruleType] ?? (isSetupField && previousValue
                      ? ErrorMessageEnum.fields_value_too_long_and_value_restored
                      : ErrorMessageEnum.fields_value_too_long)
                  );
                }
                break;
              case FormValidationRuleEnum.minValue:
                if (Number(value) < Number(this.rules[ruleType])) {
                  validationStatus.failed(
                    this.rulesMessage[ruleType] ?? (isSetupField && previousValue
                      ? ErrorMessageEnum.numeric_fields_value_too_low_and_value_restored
                      : ErrorMessageEnum.fields_value_too_low)
                  );
                }
                break;
              case FormValidationRuleEnum.maxValue:
                if (Number(value) > Number(this.rules[ruleType])) {
                  validationStatus.failed(
                    this.rulesMessage[ruleType] ?? (isSetupField && previousValue
                      ? ErrorMessageEnum.numeric_fields_value_too_high_and_value_restored
                      : ErrorMessageEnum.fields_value_too_high)
                  );
                }
                break;
              case FormValidationRuleEnum.isOfferStatusEnum:
                if (!Object.values(OfferStatus).includes(String(value) as OfferStatus)) {
                  validationStatus.failed(
                    isSetupField && previousValue
                      ? ErrorMessageEnum.fields_wrong_value_and_value_restored
                      : ErrorMessageEnum.fields_wrong_value
                  );
                }
                break;
              case FormValidationRuleEnum.isTimestamp:
                if ((new Date(String(value))).getTime() === 0) {
                  validationStatus.failed(
                    isSetupField && previousValue
                      ? ErrorMessageEnum.fields_wrong_value_and_value_restored
                      : ErrorMessageEnum.fields_wrong_value
                  );
                }
                break;
            }
          }
        }
      }
      for (const customRegexpItem of this.customRegexpItems) {
        if (customRegexpItem.pattern) {
          if (!String(value).match(customRegexpItem.pattern)) {
            validationStatus.failed(
              customRegexpItem.message ||
                (!!(isSetupField && previousValue)
                  ? ErrorMessageEnum.fields_wrong_value_and_value_restored
                  : ErrorMessageEnum.fields_wrong_value)
            );
          }
        }
      }
    }

    return validationStatus;
  }
}
