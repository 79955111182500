export enum WebsocketEvents {
  LOGIN = 'LOGIN',
  SUCCESS = 'SUCCESS',
  DELETE_TOKEN = 'DELETE_TOKEN',
  SOCKET_LOGOUT = 'SOCKET_LOGOUT',
  REJECTED = 'Error:connectionRejected',
  AUTHORIZATION_FAILED = 'Error:authorizationFailed',
  DISCONNECT = 'disconnect',
  CONNECTION = 'connection',
}
