import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { StyledComponent } from '../../../../types/styledComponent.types';
import { Color } from '../../../../enums/color.enum';
import { Markdown } from '../../../markdown/markdown.component';
import { styleWhenTrue } from '../../../../utils/style.utils';

import { ContainerProps } from './paragraphWithHeader.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  font-size: 16px;
  line-height: 18px;

  & + & {
    margin-top: 32px;
  }

  ${styleWhenTrue(prop('withBiggerFonts'), css`
    font-size: 20px;
    line-height: 24px;
  `)}
`;

export const ParagraphHeader: StyledComponent<'div'> = styled.div`
  color: ${Color.Black};
`;

export const ParagraphContent: StyledComponent<typeof Markdown> = styled(Markdown)`
  color: ${Color.Grey};
  margin-top: 16px;

  p + p {
    margin-top: 10px;
  }
`;
