import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { MapContainer,  Tooltip as TooltipBase } from 'react-leaflet';

import { StyledComponent } from '../../types/styledComponent.types';
import { Color } from '../../enums/color.enum';
import { BoxShadow } from '../../enums/boxShadow.enum';
import { ZIndex } from '../../enums/zIndex.enum';

export const Container: StyledComponent<'div'> = styled.div``;

const MapStyle: FlattenSimpleInterpolation = css`
  height: 250px;
  margin-top: 10px;
  border: 1px solid ${Color.GreyStroke};
  z-index: ${ZIndex.Zero};
`;

export const Map: StyledComponent<typeof MapContainer> = styled(MapContainer)`
  ${MapStyle}
`;

export const MapCover: StyledComponent<'div'> = styled.div`
  ${MapStyle}
  background-color: ${Color.BgGrey};
`;

export const Tooltip: StyledComponent<typeof TooltipBase> = styled(TooltipBase)`
  border-radius: 0;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  padding: 11px 12px 9px;

  ::before {
    content: none;
  }
`;

export const Address: StyledComponent<'p'> = styled.p`
  font-size: 10px;
  line-height: 13px;
`;
