import { AbstractField } from '@rehau/shared/forms/elements/fields/abstract.field';
import { CustomRegexpValidation, StringValidation } from '@rehau/shared/forms/validations';
import { Autocomplete, FormFieldTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { InfoBox, Tooltip } from '@rehau/shared/forms/elements/';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { Config } from '@rehau/shared/models/countryConfig';

export class TextField extends AbstractField {
  public placeholder: string;
  public autocomplete?: Autocomplete;
  protected regExpString?: string;

  constructor(
      id: string,
      name: string,
      label: string | null,
      placeholder: string,
      required: boolean,
      validation: StringValidation = new StringValidation(),
      countryConfigMapping?: ObjectIteratorInterface<string>,
      informationBox?: InfoBox,
      tooltip?: Tooltip,
      autocomplete?: Autocomplete
  ) {
    super(
      FormFieldTypeEnum.TextField,
      FrontendComponentEnum.TextField,
      id,
      name,
      label,
      null,
      validation,
      required,
      false,
      informationBox,
      tooltip,
      countryConfigMapping
    );
    this.autocomplete = autocomplete;
    this.placeholder = placeholder;
  }

  public updateCountryConfig(countryConfig: Config): void {
    super.updateCountryConfig(countryConfig);
    if (this.regExpString) {
      this.validation = new CustomRegexpValidation([{ pattern: new RegExp(this.regExpString) }]);
    }
  }
}
