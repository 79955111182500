import React, { useCallback, useContext } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { CurrencyPositionEnum, IconName } from '../../../../../../shared/enums';
import { Config } from '../../../../../../shared/models/countryConfig';
import { CountryConfigContext } from '../../contexts/countryConfig.context';
import { Color } from '../../enums/color.enum';
import { Price } from '../../theme/price/price.component';
import { Icon } from '../icon/icon.component';
import { ValidationBar } from '../validationBar/validationBar.component';

import {
  ActionButton,
  ActionLabel,
  ArrowIcon,
  Container,
  IconPlug,
  IconWithLabel,
  Label,
  LabelContainer,
  LabelValue,
  MainIcon,
  MainIconContainer,
  OptionalLabel,
  SuccessIcon,
  ValuesContainer,
  Variant,
  Variants,
  VariantsContainer,
  VariantsLabel,
  VariantsSeparator,
  WarnLabel,
} from './flatBox.styled';
import { FlatBoxProps, VariantWithActiveFlag } from './flatBox.types';

export const FlatBox: React.FC<FlatBoxProps> = (props: FlatBoxProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const config: Config = useContext(CountryConfigContext);
  const {
    icon,
    label,
    labelValue = '',
    variants = [],
    variantsLabel,
    actionLabel,
    warnLabel,
    hasWarning = false,
    price,
    pricePrefix,
    isActive,
    currencySymbol = config?.currency?.symbol || '€',
    currencyPosition = config?.currency?.position || CurrencyPositionEnum.Right,
    onClick = (): void => undefined,
    className,
    optionalLabel,
    disabled = false,
    hasCircleIconContainer = false,
    errorMessage,
  }: FlatBoxProps = props;

  const isAnyVariantActive: () => boolean = useCallback(
    (): boolean => {
      if (!variants || (!!variants && !variants.length)) {
        return false;
      }

      return !!variants.filter((variant: VariantWithActiveFlag): boolean => variant.isActive).length;
    },
    [variants]
  );

  return (
    <>
      <Container
        isActive={!!price || isAnyVariantActive() || !!isActive}
        hasWarning={hasWarning}
        onClick={!disabled ? onClick : undefined}
        className={className}
        disabled={disabled}
        data-testid='flat-box'
      >
        <IconWithLabel>
          {!!icon ? (
            <MainIconContainer isCircle={hasCircleIconContainer}>
              <MainIcon name={icon} size={50} />
            </MainIconContainer>
          ) : (
            <IconPlug />
          )}
          <LabelContainer column={!!optionalLabel}>
            <Label>
              {label}
              {!!labelValue && ': '}
              {labelValue && (
                <>
                  <LabelValue>{labelValue}</LabelValue>
                </>
              )}
            </Label>
            {!!optionalLabel && <OptionalLabel>({optionalLabel})</OptionalLabel>}
          </LabelContainer>
        </IconWithLabel>
        {!hasWarning && (
          <>
            {isAnyVariantActive() && (
              <ValuesContainer>
                <VariantsContainer>
                  <VariantsLabel>{variantsLabel}</VariantsLabel>
                  <Variants>
                    {variants.map(
                      (variant: VariantWithActiveFlag, index: number): JSX.Element => (
                        <React.Fragment key={index}>
                          <Variant isActive={variant.isActive}>{t(variant.variantName)}</Variant>
                          {index !== variants.length - 1 && <VariantsSeparator>|</VariantsSeparator>}
                        </React.Fragment>
                      )
                    )}
                  </Variants>
                </VariantsContainer>
                <SuccessIcon>
                  <Icon name={IconName.Check} size={9} color={Color.White} />
                </SuccessIcon>
              </ValuesContainer>
            )}
            {!!price && (
              <ValuesContainer data-testid='flat-box-price'>
                <Price
                  price={price}
                  currencySymbol={currencySymbol}
                  position={currencyPosition}
                  pricePrefix={pricePrefix}
                  priceSize={17}
                />
                <SuccessIcon>
                  <Icon name={IconName.Check} size={9} color={Color.White} />
                </SuccessIcon>
              </ValuesContainer>
            )}
            {!!isActive && !price && (
              <ValuesContainer>
                <SuccessIcon>
                  <Icon name={IconName.Check} size={9} color={Color.White} />
                </SuccessIcon>
              </ValuesContainer>
            )}
            {!price && !isAnyVariantActive() && !isActive && (
              <ActionButton>
                <ActionLabel>{actionLabel}</ActionLabel>
                <ArrowIcon name={IconName.ArrowRight} size={11} />
              </ActionButton>
            )}
          </>
        )}
        {!!hasWarning && (
          <ValuesContainer>
            <WarnLabel>{warnLabel}</WarnLabel>
            <Icon name={IconName.ArrowRight} size={11} />
          </ValuesContainer>
        )}
      </Container>
      {!!hasWarning && <ValidationBar errorsArray={[{ message: errorMessage || '' }]} />}
    </>
  );
};
