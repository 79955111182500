import { useContext } from 'react';

import { FullScreenPopupContext } from '../contexts/fullScreenPopup.context';
import { FullScreenPopupContextType } from '../types/fullScreenPopupContextType.types';

export const useFullScreenPopupContext: () => FullScreenPopupContextType | null = (): FullScreenPopupContextType | null => {
  const context: FullScreenPopupContextType = useContext(FullScreenPopupContext);
  if (!context) {
    return null;
  }

  return context;
};
