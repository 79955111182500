import { useContext } from 'react';

import { RouteInfo } from '../../../../../shared/models/countryConfig';
import { RoutesContext } from '../contexts/routes.context';

export const useRoutesInfo: () => RouteInfo[] = (): RouteInfo[] => {
  const context: RouteInfo[] = useContext(RoutesContext);
  if (!context) {
    throw new Error('Routes info context error');
  }

  return context;
};
