import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Check } from '../../../../theme/check/check.component';
import { Rating } from '../../../rating/rating.component';
import { VideoPlayer } from '../videoPlayer/videoPayer.component';
import { ProductTableRowFormat } from '../../../../../../../../shared/enums';
import { AppConfigType } from '../../../../types/appConfigType.types';
import { useAppConfig } from '../../../../hooks/useAppConfig.hook';

import { CellContentProps } from './cellContent.types';

export const CellContent: React.FC<CellContentProps> = (props: CellContentProps): JSX.Element => {
  const { format, color, value }: CellContentProps = props;
  const appConfig: AppConfigType = useAppConfig();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });

  if (format === ProductTableRowFormat.Rating) {
    return <Rating rate={value as number} color={color} isNumeric={isMobile} />;
  }

  if (format === ProductTableRowFormat.Video) {
    if (value) {
      return <VideoPlayer src={`${appConfig.externalAssets}${value as string}`} color={color} />;
    }

    return <>-</>;
  }

  if (format === ProductTableRowFormat.Boolean) {
    return <Check color={color} disabled={!value} />;
  }

  return <>{format}</>;
};
