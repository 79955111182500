import React from 'react';

import {
  Container,
  Icon,
  IconContainer,
  StyledMarkdown,
  TextContent,
  Title,
  Wrapper
} from './textWithIcon.styled';
import { TextWithIconProps } from './textWithIcon.types';

export const TextWithIcon: React.FC<TextWithIconProps> = (props: TextWithIconProps): JSX.Element => {
  const {
    className,
    title,
    text,
    icon,
  }: TextWithIconProps = props;

  return (
    <Container className={className}>
      <Wrapper>
        <TextContent>
          {!!title && <Title data-testid='text-with-icon-title'>{title}</Title>}
          <StyledMarkdown text={text} />
        </TextContent>
        <IconContainer data-testid='text-with-icon-icon'>
          <Icon src={icon} />
        </IconContainer>
      </Wrapper>
    </Container>
  );
};
