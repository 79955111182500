import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { styleWhenTrue } from '../../../../utils/style.utils';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { StyledComponent } from '../../../../types/styledComponent.types';

import { FullWidthImageProps } from './fullWidthImage.types';

export const Image: StyledComponent<'div', FullWidthImageProps> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 514px;
  overflow: hidden;
  background: url(${({ src }: FullWidthImageProps): string => src});
  background-size: cover;
  background-position: center;

  ${styleWhenTrue(prop('alignTop'), css`
    background-position: top center;
  `)}

  @media ${Breakpoint.Desktop} {
    height: 365px;
  }

  @media ${Breakpoint.Tablet} {
    height: 274px;
  }
`;
