import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { FieldElement, FieldElementProps } from '../types';
import { Checkbox } from '../../checkbox/checkbox.component';
import { CheckboxField } from '../../../../../../../shared/forms/elements';

export const CheckboxFieldElement: FieldElement<CheckboxField> = (props: FieldElementProps<CheckboxField>): JSX.Element => {
  const { component, save, setValues }: FieldElementProps<CheckboxField> = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <Checkbox
      label={t(component.label || '')}
      checked={component.value as boolean}
      disabled={component.onlyForRead || component.disabled}
      required={component.required}
      additionalInfoContent={t(component.informationBox?.content || '')}
      additionalInfoContentInReadMore={t(component.informationBox?.readMoreContent || '')}
      additionalInfoPosition={component.informationBox?.position}
      component={component}
      handleSave={save}
      setValues={setValues}
    />
  );
};
