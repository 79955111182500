import React from 'react';

import { Title } from '../title/title.component';

import {
  Box,
  BoxMarkdown,
  BoxTitle,
  BoxWrapper,
  Container,
  Content,
  ContentTitle,
  SectionTitle,
  StyledMarkdown,
  TextContent,
  Wrapper,
} from './textWithBox.styled';
import { TextWithBoxProps } from './textWithBox.types';

export const TextWithBox: React.FC<TextWithBoxProps> = (props: TextWithBoxProps): JSX.Element => {
  const {
    className,
    title,
    sectionTitle,
    text,
    boxTitle,
    boxText,
    isTopSection = false,
    hasWideText = false,
  }: TextWithBoxProps = props;

  return (
    <Container className={className}>
      <Wrapper>
        {!!title && <SectionTitle large data-testid='text-with-box-title'>{title}</SectionTitle>}
        <Content>
          <TextContent hasWideText={hasWideText}>
            {!!sectionTitle && <ContentTitle data-testid='text-with-box-section-title'>{sectionTitle}</ContentTitle>}
            <StyledMarkdown
              isTopSection={isTopSection}
              text={text}
            />
          </TextContent>
          <BoxWrapper>
            <Box hasWideText={hasWideText}>
              <BoxTitle hasWideText={hasWideText}>{boxTitle}</BoxTitle>
              {!!boxText && (
                <BoxMarkdown
                  text={boxText}
                  data-testid='text-with-box-box-text'
                />
              )}
            </Box>
          </BoxWrapper>
        </Content>
      </Wrapper>
    </Container>
  );
};
