import { FrontendComponentEnum, WebsiteComponentEnum } from '@rehau/shared/enums';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { Config } from '@rehau/shared/models/countryConfig';
import { fieldValueType } from '@rehau/shared/forms/types';

export abstract class AbstractStatic {
  public type: string;
  public label: string | null;
  public frontendComponent: FrontendComponentEnum;
  public countryConfigMapping: ObjectIteratorInterface<string>;
  public valid: boolean = true;
  public available: boolean = true;

  protected constructor(
    type: string,
    frontendComponent: FrontendComponentEnum,
    label: string | null = null,
    variablesMap: ObjectIteratorInterface<string> = {}
  ) {
    this.type = type;
    this.label = label;
    this.frontendComponent = frontendComponent;
    this.countryConfigMapping = variablesMap;
  }

  public validate(): boolean {
    return true;
  }

  public updateCountryConfig(countryConfig: Config): void {
    if (this.countryConfigMapping) {
      for (const countryConfigMappingKey in this.countryConfigMapping) {
        if (this.countryConfigMapping[countryConfigMappingKey]) {
          // @ts-ignore
          this[countryConfigMappingKey] = this.getCountryConfigValue(
            this.countryConfigMapping[countryConfigMappingKey],
            countryConfig
          );
        }
      }
    }
  }

  private getCountryConfigValue(countryConfigKey: string, countryConfig: Config): fieldValueType {
    return  (countryConfig?.variables && countryConfig.variables[WebsiteComponentEnum.Setup])
      ? countryConfig.variables[WebsiteComponentEnum.Setup][countryConfigKey]
      : null;
  }
}
