import { History, Location, LocationState } from 'history';
import { useHistory, useLocation } from 'react-router-dom';

import { WebsitePathNameEnum } from '../../../../../shared/enums';
import { RouteInfo } from '../../../../../shared/models/countryConfig';
import { AppConfigType } from '../types/appConfigType.types';
import { UseRedirectOutput } from '../types/useRedirect.types';
import { findComponentByName } from '../utils/findComponentByName.utils';

import { useAppConfig } from './useAppConfig.hook';
import { useRoutesInfo } from './useRoutesInfo.hook';

export const useRedirect: () => UseRedirectOutput = (): UseRedirectOutput => {
  const history: History<LocationState> = useHistory();
  const { appType }: AppConfigType = useAppConfig();
  const routesArray: RouteInfo[] = useRoutesInfo();
  const location: Location = useLocation();

  const handleRedirect: (
    path: string, pathName?: WebsitePathNameEnum, openInNewTab?: boolean
  ) => void = (
    path: string, pathName?: WebsitePathNameEnum, openInNewTab?: boolean
  ): void => {
    const isInternalPath: boolean = pathName ? findComponentByName(pathName, routesArray) === appType : false;
    if (isInternalPath && !openInNewTab) {
      history.push(path, { from: location });
    } else if (openInNewTab) {
      window.open(path);
    } else {
      window.location.assign(path);
    }
  };

  return handleRedirect;
};
