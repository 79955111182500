import React from 'react';

import { ChoiceGroup } from '../../../components/choiceGroup/choiceGroup.component';
import { SelectField, SelectOption } from '../../../../../../../shared/forms/elements';
import { FieldElement, FieldElementProps } from '../types';
import { ChoiceGroupItem } from '../../../components/choiceGroup/components/choiceGroupItem/choiceGroupItem.component';
import { ValidationBar } from '../../../components/validationBar/validationBar.component';

export const ChooseOptionFromBoxesFieldElement: FieldElement<SelectField> = (props: FieldElementProps<SelectField>): JSX.Element => {
  const { component, save, setValues }: FieldElementProps<SelectField> = props;

  return (
    <>
      <ChoiceGroup
        component={component}
        handleSave={save}
        setValues={setValues}
      >
        {component.allowedOptions.map((option: SelectOption): JSX.Element => (
          <ChoiceGroupItem
            id={option.value}
            text={option.label}
            key={option.value}
          />
        ))}
      </ChoiceGroup>
      <ValidationBar errorsArray={component.validationMessages} />
    </>
  );
};
