import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { Breakpoint } from '../../../enums/breakpoint.enum';
import { Button } from '../../button/button.component';
import { InfoBase } from '../infoBase/infoBase.component';
import { Description, SmallTitle, Title, Wrapper } from '../infoScreens.styled';
import ErrorImage from '../img/errorImage.svg';

import { ErrorScreenProps } from './errorScreen.types';

export const ErrorScreen: React.FC<ErrorScreenProps> = (props: ErrorScreenProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    handleButtonClick,
    title = t('rehau.errors.404.title'),
    description = t('rehau.errors.404.description'),
    buttonLabel = t('rehau.errors.404.buttonLabel'),
    smallTitle = t('rehau.errors.smallTitle'),
    hasBar = true,
  }: ErrorScreenProps = props;
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Additional480 });

  return (
    <InfoBase imgUrl={ErrorImage} hasBar={hasBar}>
      <Wrapper data-testid='error-screen'>
        <SmallTitle>{smallTitle}</SmallTitle>
        <Title>{title}</Title>
        <Description text={description ?? ''} />
        <Button
          text={buttonLabel || ''}
          onClick={handleButtonClick}
        />
      </Wrapper>
    </InfoBase>
  );
};
