import React from 'react';

import { CircleLoader } from '../circleLoader/circleLoader.component';
import { Color } from '../../enums/color.enum';

import { Background } from './loader.styled';
import { LoaderProps } from './loader.types';

export const Loader: React.FC<LoaderProps> = (props: LoaderProps): JSX.Element => {
  const { isVisible = true }: LoaderProps = props;

  return (
    <Background isVisible={isVisible} data-testid='loader'>
      <CircleLoader hasBiggerSize color={Color.Pink}/>
    </Background>
  );
};
