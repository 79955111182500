import styled from 'styled-components';
import { HexColorPicker } from 'react-colorful';
import 'react-colorful/dist/index.css';

import { FontWeight } from '../../enums/fontWeight.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { Color } from '../../enums/color.enum';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { BaseLabel } from '../../theme';

import { PickerCircleInnerProps } from './colorPicker.types';

export const Label: StyledComponent<typeof BaseLabel> = styled(BaseLabel)`
  margin-bottom: 10px;
`;

export const Wrapper: StyledComponent<'div'> = styled.div`
  width: min-content;
`;

export const StyledInput: StyledComponent<'input'> = styled.input`
  font-weight: ${FontWeight.Bold};
  width: 100%;
  height: 100%;
  font-size: 18px;
  padding: 0 5px 0 0;
  display: flex;
  align-items: center;
  min-width: 0;
  &::placeholder {
    font-weight: ${FontWeight.Regular};
  }

  @media ${Breakpoint.Mobile} {
    width: fit-content;
  }
`;

export const PickerCircle: StyledComponent<'button'> = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  box-sizing: border-box;
  border: 1px solid ${Color.GreyStroke};
  border-radius: 50%;
  padding: 0;
  margin-left: 10px;
  flex-shrink: 0;
`;

export const PickerCircleInner: StyledComponent<'div', PickerCircleInnerProps> = styled.div<PickerCircleInnerProps>`
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: ${({ color }: PickerCircleInnerProps): string => color};
`;

export const Picker: StyledComponent<typeof HexColorPicker> = styled(HexColorPicker)`
  width: 340px;
  .react-colorful__saturation {
    margin-top: 20px;
    border-radius: 0;
  }
  .react-colorful__hue {
    margin-top: 10px;
    border-radius: 0;
  }
`;
