import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { useState } from 'react';

import { UseCountryConfig } from '../types/useCountryConfig.types';
import { UseFormatDate } from '../types/useFormatDate.types';
import { TimezoneEnum } from '@rehau/shared/enums';
import { UseState } from '../types/useState.types';

import { useCountryConfig } from './useCountryConfig.hook';

export const useFormatDate: () => UseFormatDate = (): UseFormatDate => {
  const { config }: UseCountryConfig = useCountryConfig();
  const [isReady, setIsReady]: UseState<boolean> = useState<boolean>(dayjs.locale() === config.dateLocale);
  if (!isReady) {
    import(`dayjs/locale/${config.dateLocale}.js`).then((): void => {
      dayjs.locale(config.dateLocale);
      setIsReady(true);
    });
  }
  dayjs.extend(timezone);
  dayjs.extend(utc);

  const formatDate: (timestamp: number, format?: string) => string = (timestamp: number, format: string = 'YY-MM-DD'): string => {
    const timestampValue: number = `${timestamp}`.length === 13 ? timestamp / 1000 : timestamp;

    return isReady
        ? dayjs.unix(timestampValue).tz(config.timezone || TimezoneEnum.Berlin).format(format)
        : '';
  };

  const getTimeFromNow: (date: string) => string = (date: string): string => {
    dayjs.extend(relativeTime);

    return dayjs(date).fromNow();
  };

  return { formatDate, getTimeFromNow };
};
