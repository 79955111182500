import React, { RefObject, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { IconName } from '../../../../../../shared/enums';
import { Color } from '../../enums/color.enum';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { UseState } from '../../types/useState.types';

import { MenuType, Theme } from './contextMenu.enums';
import { Container, Menu, MenuPosition, MenuTrigger, PositionIcon, PositionName, StyledIcon } from './contextMenu.styled';
import { ContextMenuPosition, ContextMenuProps } from './contextMenu.types';

export const ContextMenu: React.FC<ContextMenuProps> = (props: ContextMenuProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    positions,
    theme = Theme.Light,
    className,
    dotsColor = Color.Grey,
    menuType = MenuType.Default,
    withRightPosition = false,
  }: ContextMenuProps = props;
  const [isMenuVisible, setIsMenuVisible]: UseState<boolean> = useState<boolean>(false);
  const ref: RefObject<HTMLDivElement> = useRef(null);
  useOnClickOutside([ref], (): void => setIsMenuVisible(false));

  const handleTriggerClick: () => void = (): void => {
    setIsMenuVisible(!isMenuVisible);
  };

  return (
    <Container ref={ref} className={className}>
      <MenuTrigger
        onClick={handleTriggerClick}
        isActive={isMenuVisible}
        theme={theme}
        menuType={menuType}
      >
        <StyledIcon name={IconName.More} size={menuType === MenuType.Default ? 16 : 14} color={dotsColor} />
      </MenuTrigger>
      <Menu withRightPosition={withRightPosition} isVisible={isMenuVisible} theme={theme}>
        {positions.map(
          (position: ContextMenuPosition): JSX.Element => (
            <MenuPosition
              key={position.name}
              onClick={(): void => {
                if (position.onClick) {
                  position.onClick();
                }
                setIsMenuVisible(false);
              }}
            >
              <PositionIcon name={position.icon} size={20} color={Color.Grey} theme={theme} />
              <PositionName theme={theme}>{t(position.name)}</PositionName>
            </MenuPosition>
          )
        )}
      </Menu>
    </Container>
  );
};
