import styled from 'styled-components';

import { StyledComponent } from '../../types/styledComponent.types';
import { Color } from '../../enums/color.enum';
import { Icon as IconBase } from '../icon/icon.component';

export const Text: StyledComponent<'p'> = styled.p`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  transition: 0.3s;
  margin-left: 12.5px;
  color: ${Color.Grey};
`;

export const Icon: StyledComponent<typeof IconBase> = styled(IconBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: ${Color.Grey};
  flex-shrink: 0;
  transition: 0.3s;
`;

export const Container: StyledComponent<'button'> = styled.button`
  display: flex;
  align-items: center;
  background-color: ${Color.Transparent};
  padding: 0;
  margin: 0;
  transition: 0.3s;
  border: none;
  :hover {
    ${Text},
    ${Icon} {
      color: ${Color.Green};
    }
  }
`;
