import styled from 'styled-components';

import { Breakpoint, Color, FontWeight, Icon, StyledComponent, ZIndex } from '@rehau-fe/ui/src';
import { SectionHeader } from '../sectionHeader/sectionHeader.component';

import { IconBoxProps } from './iconBoxes.types';

export const Wrapper: StyledComponent<'div'> = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;

  @media ${Breakpoint.Additional1280} {
    padding: 0 40px;
  }

  @media ${Breakpoint.Tablet} {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  @media ${Breakpoint.Additional480} {
    padding: 0 20px;
  }
`;

export const BoxesWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const Box: StyledComponent<'div', IconBoxProps> = styled.div`
  display: flex;
  border: 1px solid ${Color.GreyStroke};
  box-sizing: border-box;
  padding: 35px 25px;
  flex-grow: 1;
  flex-basis: 33%;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  margin-right: 20px;

  & + & {
    margin-left: 20px;
  }
  @media ${Breakpoint.Mobile} {
    & + & {
      margin-left: 0;
    }
  }

  :after {
    position: absolute;
    transform: translateX(100%) translateY(-1px);
    top: 0;
    bottom: 0;
    right: -1px;
    content: ' ';
    width: 0;
    height: 0;
    border-width: ${({ height }: IconBoxProps): string => `${Number(height) / 2}px 0 ${Number(height) / 2}px 20px`};
    border-color: transparent transparent transparent ${Color.GreyStroke};
    border-style: solid;

    @media ${Breakpoint.Mobile} {
      transform: translateY(calc(100% + 1px));
      top: unset;
      right: 0;
      border-width: ${({ width }: IconBoxProps): string => `22px ${Number(width) / 2}px 0 ${Number(width) / 2}px`};
      border-color: ${Color.GreyStroke} transparent transparent transparent;
    }
  }

  :before {
    position: absolute;
    transform: translateX(100%) translateY(-1px);
    top: 0;
    bottom: 0;
    right: 0;
    content: ' ';
    width: 0;
    height: 0;
    border-width: ${({ height }: IconBoxProps): string => `${Number(height) / 2}px 0 ${Number(height) / 2}px 20px `};
    border-color: transparent transparent transparent ${Color.White};
    border-style: solid;
    pointer-events: none;
    z-index: ${ZIndex.Minimum};

    @media ${Breakpoint.Mobile} {
      transform: translateY(100%);
      top: unset;
      border-width: ${({ width }: IconBoxProps): string => `22px ${Number(width) / 2}px 0 ${Number(width) / 2}px`};
      border-color: ${Color.White} transparent transparent transparent;
      z-index: ${ZIndex.Minimum};
    }
  }

  @media ${Breakpoint.Mobile} {
    align-items: center;
    margin-right: unset;
    margin-bottom: 40px;
    min-height: 225px;
  }
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  position: relative;
  margin: auto;

  @media ${Breakpoint.Mobile} {
    margin: unset;
  }
`;

export const BoxContainer: StyledComponent<'div'> = styled.div`
  margin-top: 10px;
  min-height: 50px;
  height: 100%;
`;

export const BoxTitle: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: ${FontWeight.Regular};
  word-break: break-word;
  text-align: center;
`;

export const StyledSectionHeader: StyledComponent<typeof SectionHeader> = styled(SectionHeader)`
  padding-left: 0;
`;
