import React, { useEffect, useState } from 'react';

import { IconName } from '@rehau/shared/enums';
import { Color } from '../../enums/color.enum';
import { Icon } from '../icon/icon.component';
import { CircleLoader } from '../circleLoader/circleLoader.component';

import { ButtonType } from './button.enums';
import { ArrowIconContainer, StyledButton, TextContainer } from './button.styled';
import { ButtonProps } from './button.types';

export const Button: React.ForwardRefExoticComponent<ButtonProps> = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>): JSX.Element => {
    const {
      className,
      onClick,
      text,
      type = ButtonType.Primary,
      autoWidth = false,
      children,
      fluid = false,
      loading = false,
      tabIndex,
      active = false,
      minWidth = 0,
    }: ButtonProps = props;

    return (
      <StyledButton
        className={className}
        buttonType={type}
        onClick={onClick}
        data-testid='next'
        fluid={fluid}
        autoWidth={autoWidth}
        tabIndex={tabIndex}
        $loading={loading}
        active={active}
        minWidth={minWidth}
        ref={ref}
      >
        {!loading ? (
          <>
            <TextContainer>{children || text}</TextContainer>
            <ArrowIconContainer active={active}>
              <Icon
                name={active ? IconName.Remove : IconName.ArrowRight}
                size={18}
                color={type === ButtonType.CallToAction
                  ? Color.Pink
                  : active
                    ? Color.Black
                    : Color.White
                }
              />
            </ArrowIconContainer>
          </>
        ) : (
          <CircleLoader color={Color.Green} />
        )}
      </StyledButton>
    );
  }
);
