export enum SocketIOEventsEnum {
  // defaultowe eventy
  Connect = 'connect',
  Disconnect = 'disconnect',
  // customowe eventy
  Logout = 'socketIO:logout',
  FileGenerationCompleted = 'socketIO:fileGenerationCompleted',
  NotImplemented = 'socketIO:error:notImplemented',
  Success = 'socketIO:success',
  Rejected = 'socketIO:rejected',
  AuthorizationFailed = 'socketIO:error:authorizationFailed',
}
