import React from 'react';

import { CalculationSummaryCostModel } from '@rehau/shared/models/calculation/response';
import { Color } from '../../../../enums/color.enum';
import { ForcedLanguageTranslationContextType } from '../../../../types/forcedLanguageTranslationContextType.types';
import { useForcedLanguageTranslationContext } from '../../../../hooks/useForceLanguageTranslation.hook';

import { Dash, DashWithItem, ItemElement, ItemElements, StyledIncludedCheck } from './elementsWithDash.styled';
import { ElementsWithDashProps } from './elementsWithDash.types';

export const ElementsWithDash: React.FC<ElementsWithDashProps> = (props: ElementsWithDashProps): JSX.Element => {
  const { rows, className, withBiggerFonts = false }: ElementsWithDashProps = props;
  const { t }: ForcedLanguageTranslationContextType = useForcedLanguageTranslationContext();

  return (
    <ItemElements className={className} withBiggerPadding={withBiggerFonts}>
      {rows.map((itemElement: CalculationSummaryCostModel): JSX.Element => (
        <ItemElement>
          <DashWithItem withBiggerFonts={withBiggerFonts}>
            <Dash />
            {t(itemElement.translation)}
            {!!itemElement.description && `: ${t(itemElement.description)}`}
            {!itemElement.description && !!itemElement.value && `: ${itemElement.value} ${t('rehau.global.piece_short_label')}`}
          </DashWithItem>
          <StyledIncludedCheck
            includedLabel={t('rehau.global.included')}
            iconColor={Color.CompanyColor}
            isFinal
          />
        </ItemElement>
      ))}
    </ItemElements>
  );
};
