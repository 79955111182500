import { ProductTableAnnotation } from '../../../../../../../shared/models/countryConfig';

export const reduceAnnotations: (annotations: ProductTableAnnotation[], rowAnnotations: string[]) => number[] = (
  annotations: ProductTableAnnotation[],
  rowAnnotations: string[]
): number[] => {
  const reduceAnn: (accumulator: number[], currentValue: ProductTableAnnotation, index: number) => number[] = (
    accumulator: number[],
    currentValue: ProductTableAnnotation,
    index: number
  ): number[] => {
    if (rowAnnotations.includes(currentValue.name)) {
      return [...accumulator, index];
    }

    return [...accumulator];
  };

  return annotations.reduce(reduceAnn, []);
};
