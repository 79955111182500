import styled from 'styled-components';

import { Color } from '../../enums/color.enum';
import { StyledComponent } from '../../types/styledComponent.types';

export const ComponentWrapper: StyledComponent<'div'> = styled.div``;

export const IdContainer: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Green};
  color: ${Color.White};
  padding: 5px 10px;
  margin: 5px 0;
  font-size: 12px;
`;

export const SmallIdContainer: StyledComponent<'div'> = styled(IdContainer)`
  width: max-content;
`;
