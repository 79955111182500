import { Match, match, MatchFunction } from 'path-to-regexp';

import { WebsiteComponentEnum } from '../enums';
import { RouteInfo } from '../models';

export function getRouteDetailsByUrl(url: string, routes: RouteInfo[]): RouteInfo | undefined {
  return routes
    .filter((route: RouteInfo): boolean => route.component !== WebsiteComponentEnum.ExternalAddressing && route.url !== '*')
    .find((route: RouteInfo): boolean => {
      const urlMatch: MatchFunction = match(route.url);
      const isMatched: Match<object> | false = urlMatch(url);

      return !!isMatched;
    });
}
