import React, { SyntheticEvent, useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import useCollapse from 'react-collapsed';
import { UseCollapseOutput } from 'react-collapsed/dist/types';

import { IconName } from '../../../../../../shared/enums/iconName.enum';
import { UseState } from '../../types/useState.types';
import { Color } from '../../enums/color.enum';
import { ValidationBar } from '../validationBar/validationBar.component';
import { DecisionWithChildrenContainer, VariantContainer } from '../../../../../../shared/forms/components';
import { DecisionField } from '../../../../../../shared/forms/elements';
import { ToggleSwitch } from '../toggleSwitch/toggleSwitch.component';

import {
  ArrowIcon,
  Container,
  Content,
  ExpandButton,
  HeaderBox,
  HeaderIcon,
  IconWithLabel,
  InnerContent,
  Label,
  StyledAdditionalInfo,
  SwitchContainer,
} from './conditionalExpandedBox.styled';
import { ConditionalExpandedBoxProps } from './conditionalExpandedBox.types';

export const ConditionalExpandedBox: React.FC<ConditionalExpandedBoxProps> = (props: ConditionalExpandedBoxProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    onChange,
    icon,
    label,
    switchLabel,
    initialValue,
    children,
    setValues = (): void => undefined,
    handleSave = (): void => undefined,
    component = new VariantContainer('', '', [new DecisionField('', '', '', false, false, '', '')]),
    errorMessage = t(component.errorMessage ?? 'rehau.components.conditional_expand_box.warning'),
    infoBoxLabel,
  }: ConditionalExpandedBoxProps = props;
  const {
    isExpanded,
    getCollapseProps,
    setExpanded,
  }: UseCollapseOutput = useCollapse();
  const [isActive, setIsActive]: UseState<boolean> = useState((component.getDecisionField()?.value as boolean) || !!initialValue);
  const [isComponentValid, setIsComponentValid]: UseState<boolean> = useState<boolean>(false);

  const handleActiveButtonAction: () => void = (): void => {
    setValues({ [(component as DecisionWithChildrenContainer).getDecisionField()!.id]: !isActive });
    setIsActive(!isActive);
    const validationSuccess: boolean = component.getDecisionField()?.validate() || false;
    setIsComponentValid(component.validate());
    if (validationSuccess) {
      handleSave();
    }
  };

  useEffect(
    (): void => {
      onChange(isActive);
    },
    [isActive]
  );

  useEffect(
    (): void => {
      if (!isComponentValid) {
        setValues({ [(component as DecisionWithChildrenContainer).getDecisionField()!.id]: isActive });
      }
    },
    [isExpanded]
  );

  const handleOpenAction: () => void = (): void => {
    setExpanded(!isExpanded);
  };

  return (
    <>
      <Container isValid={component.valid === true && isActive} invalid={component.valid === false}>
        <HeaderBox onClick={handleOpenAction} invalid={component.valid === false}>
          <IconWithLabel>
            {!!icon && <HeaderIcon name={icon} size={60} />}
            <Label isActive={isActive}>{label}</Label>
            <ExpandButton>
              <ArrowIcon
                name={IconName.ArrowDown}
                size={12}
                isRotated={isExpanded}
                color={isActive ? Color.Black : Color.Grey}
              />
            </ExpandButton>
          </IconWithLabel>
          <SwitchContainer>
            <ToggleSwitch
              label={switchLabel}
              value={isActive}
              onClick={handleActiveButtonAction}
              withBorder={component.valid === false}
            />
          </SwitchContainer>
        </HeaderBox>
        <Content {...getCollapseProps()} data-testid='collapse'>
          <InnerContent>
            {!isActive && !!infoBoxLabel && <StyledAdditionalInfo content={t(infoBoxLabel)} />}
            {children}
          </InnerContent>
        </Content>
      </Container>
      {component.valid === false && <ValidationBar errorsArray={[{ message: errorMessage || '' }]} />}
    </>
  );
};
