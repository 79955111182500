import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Description, Title, Wrapper } from '../infoScreens.styled';
import { InfoBase } from '../infoBase/infoBase.component';
import { Button } from '../../button/button.component';
import { Breakpoint } from '../../../enums/breakpoint.enum';
import { IconName } from '@rehau/shared/enums';
import { ButtonWithIcon } from '../../../components/buttonWithIcon/buttonWithIcon.component';

import { SuccessOrFailScreenProps } from './successOrFailScreen.types';
import successImg from './img/success.svg';
import failImg from './img/fail.svg';

export const SuccessOrFailScreen: React.FC<SuccessOrFailScreenProps> = (props: SuccessOrFailScreenProps): JSX.Element => {
  const {
    title,
    description,
    buttonLabel,
    handleButtonClick,
    success = true,
    hasBar = false,
    secondaryButtonLabel,
    secondaryButtonClick,
  }: SuccessOrFailScreenProps = props;
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Additional480 });

  return (
    <InfoBase imgUrl={success ? successImg : failImg} hasBar={hasBar}>
      <Wrapper data-testid='error-screen'>
        <Title>{title}</Title>
        <Description text={description} />
        {!!buttonLabel && (
          <Button
            text={buttonLabel || ''}
            onClick={handleButtonClick}
          />
        )}
        {!!secondaryButtonLabel && (
          <ButtonWithIcon
            text={secondaryButtonLabel}
            icon={IconName.Mail}
            onClick={secondaryButtonClick}
            reversed
          />
        )}
      </Wrapper>
    </InfoBase>
  );
};
