import { RefObject, useLayoutEffect, useRef } from 'react';
import gsap, { Power1 } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const useReveal: (disabled?: boolean) => RefObject<HTMLDivElement> = (disabled?: boolean): RefObject<HTMLDivElement> => {
  const textRef: RefObject<HTMLDivElement> = useRef(null);

  const revealAnimation: (ref: HTMLElement) => void = (ref: HTMLElement): void => {
    const animation: gsap.core.Tween = gsap.to(ref, {
      scrollTrigger: {
        trigger: ref,
        start: 'center bottom',
      },
      yPercent: 0,
      duration: 0.6,
      delay: 0.4,
      ease: Power1.easeInOut,
    });

    ScrollTrigger.create({
      trigger: ref,
      start: 'top-=50px bottom',
      onLeaveBack: (): void => {
        animation.pause(0);
      },
    });
  };

  useLayoutEffect(
    (): void => {
      if (!disabled && textRef.current) {
        gsap.set(textRef.current, { yPercent: 100 });
        revealAnimation(textRef.current);
      }
    },
    [disabled]
  );

  return textRef;
};
