import styled from 'styled-components';

import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Color } from '../../../../enums/color.enum';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { Markdown } from '../../../../components/markdown/markdown.component';

export const ButtonsContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 25px;
  flex-wrap: wrap;

  @media ${Breakpoint.Mobile} {
    margin-bottom: 10px;
  }

  @media ${Breakpoint.Additional480} {
    display: block;
  }
`;

export const Wrapper: StyledComponent<'div'> = styled.div`
  border-top: 1px solid ${Color.GreyStroke};

  @media ${Breakpoint.Mobile} {
    border-top: unset;
    padding: 0 20px;
  }
`;

export const Button: StyledComponent<'button'> = styled.button`
  padding: 0 15px;
  height: 44px;
  border: 1px solid ${Color.GreyStroke};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  :first-child {
    margin-right: 5px;
  }
`;

export const DistanceText: StyledComponent<'span'> = styled.span`
  color: ${Color.Grey};
  margin-right: 5px;
`;

export const ButtonText: StyledComponent<'p'> = styled.p`
  font-size: 16px;
  line-height: 14px;
`;

export const AdditionalInfo: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 16px;
  line-height: 19px;
  color: ${Color.Grey};
  margin-top: 25px;

  p + p {
    margin-top: 10px;
  }
`;
