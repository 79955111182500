import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Color } from '../../../../enums/color.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { styleWhenTrue } from '../../../../utils/style.utils';

import { ContainerProps } from './services.types';

export const Wrapper: StyledComponent<'div'> = styled.div`
  padding: 20px 0;
  border-top: 1px solid ${Color.GreyStroke};

  @media ${Breakpoint.Additional480} {
    border-bottom: none;
    margin-top: 0;
  }
`;

export const Container: StyledComponent<'div', ContainerProps> = styled.div`
  width: 100%;
  background-color: ${Color.BgGrey};
  padding: 30px 20px;
  box-sizing: border-box;
  outline: 1px solid ${Color.BgGrey};

  ${styleWhenTrue(
    prop('isActive'),
    css`
      outline: none;
    `
  )}
`;

export const AdditionalServicesText: StyledComponent<'p'> = styled.p`
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 14px;
  color: ${Color.Grey};
`;
