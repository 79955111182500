import React from 'react';

import { LegendProps } from './legend.types';
import { LegendItem, LegendSection, SupNumber, Text, Wrapper } from './legend.styled';

export const Legend: React.FC<LegendProps> = (props: LegendProps): JSX.Element => {
  const { options }: LegendProps = props;

  return (
    <Wrapper>
      <LegendSection>
        {options.map((option: string, index: number): JSX.Element => (
          <LegendItem key={index}>
            <SupNumber>{index + 1}</SupNumber>
            <Text text={option} />
          </LegendItem>
        ))}
      </LegendSection>
    </Wrapper>
  );
};
