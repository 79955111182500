import { componentTypes, fieldTypes } from '@rehau/shared/forms/types';
import { ComponentsContainer, VariantContainer } from '@rehau/shared/forms/components';
import {
  ErrorMessageEnum,
  FormContainerTypeEnum,
  FrontendComponentEnum,
  IconName,
  SetupFieldEnum
} from '@rehau/shared/enums';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { AbstractField, DecisionField, InfoBox } from '@rehau/shared/forms/elements';
import { Config } from '@rehau/shared/models/countryConfig';
import { containerDefaultErrorMessagesConst } from '@rehau/shared/constants';

export class PopupContainer extends ComponentsContainer {
  public active: boolean = false;
  public actionLabel: string;
  public variantsLabel: string | null;
  public warnLabel?: string;
  public customPopupHeader?: string;
  public icon?: IconName;
  public required: boolean = false;

  public constructor(
      title: string,
      actionLabel: string,
      variantsLabel: string | null,
      elements: componentTypes[] = [],
      countryConfig?: Config,
      icon?: IconName,
      customPopupHeader?: string,
      required: boolean = false,
      informationBox?: InfoBox,
      subTitle?: string,
      warnLabel: string = ErrorMessageEnum.resolve_conflicts,
      id?: string,
      errorMessage?: ErrorMessageEnum
  ) {
    super(FrontendComponentEnum.PopupWithVariants, title, elements, countryConfig, informationBox, subTitle);
    this.type = FormContainerTypeEnum.PopupContainer;
    this.actionLabel = actionLabel;
    this.icon = icon;
    this.variantsLabel = variantsLabel;
    this.warnLabel = warnLabel;
    this.errorMessage = errorMessage;
    this.customPopupHeader = customPopupHeader;
    this.required = required;
    this.id = id;
  }

  public getVariantsShortLabel(): ObjectIteratorInterface<boolean> {
    const labels: ObjectIteratorInterface<boolean> = {};
    for (const element of this.elements) {
      if (element instanceof VariantContainer && element.shortTitle) {
        labels[element.shortTitle] = !!element.getDecisionField()?.value;
      }
    }

    return labels;
  }

  public setValues(values: ObjectIteratorInterface): void {
    super.setValues(values);
    for (const variant of this.elements) {
      if (
        variant instanceof VariantContainer
        && Boolean(variant.getDecisionField()?.value
        && Object.keys(values).length > 1
      )) {
        this.validate();

        break;
      }
    }
  }

  public validate(): boolean {
    const validateResult: boolean = super.validate();
    const checkRequirementResult: boolean = this.checkRequirement();

    if (!validateResult || !checkRequirementResult) {
      this.setErrorMessage();
    }
    if (!this.changesWasPublished && validateResult && !this.checkAnyVariantIsOn()) {
      this.valid = true;

      return this.valid;
    }
    this.valid = validateResult && checkRequirementResult;

    return this.valid;
  }

  public checkActiveValue(): void {
    if (this.elements.length > 1) {
      return;
    }

    this.active = !!(this.elements[0] as VariantContainer).getDecisionField()?.value;
  }

  public getVariants(): VariantContainer[] {
    const variants: VariantContainer[] = [];
    for (const element of this.elements) {
      if (element instanceof VariantContainer) {
        variants.push(element);
      }
    }

    return variants;
  }

  protected checkRequirement(): boolean {
    let turnedOnAllVariants: boolean = true;
    if (this.required) {
      for (const variant of this.elements) {
        if (variant instanceof VariantContainer) {
          const decisionField: DecisionField | null = variant.getDecisionField();
          if (decisionField && !decisionField.value) {
            decisionField.valid = false;
            turnedOnAllVariants = false;
          }
        }
      }
    }
    this.valid = turnedOnAllVariants;

    return this.valid;
  }

  protected checkAnyVariantIsOn(): boolean {
    if (this.required) {
      for (const variant of this.elements) {
        if (variant instanceof VariantContainer) {
          const decisionField: DecisionField | null = variant.getDecisionField();
          if (decisionField && !!decisionField.value) {
            return true;
          }
        }
      }
    }

    return false;
  }

  protected setErrorMessage(): this {
    let requirementsComplied: boolean = true;
    for (const element of this.elements) {
      if (
        element instanceof VariantContainer
        && element.getDecisionField()?.valid === false
      ) {
        requirementsComplied = false;
      }
    }
    if (requirementsComplied) {
      this.errorMessage = ErrorMessageEnum.form_values_error;
    } else if (
      !this.errorMessage
      || containerDefaultErrorMessagesConst.includes(this.errorMessage)
    ) {
      if (this.changesWasPublished) {
        this.errorMessage = this.elements.length > 1
          ? ErrorMessageEnum.all_or_null_variants_required
          : ErrorMessageEnum.variants_wrong_value;
      } else {
        this.errorMessage = this.elements.length > 1
          ? ErrorMessageEnum.all_or_null_variants_required_new_setup
          : ErrorMessageEnum.variants_wrong_value_new_setup;
      }
    }

    for (const variant of this.elements) {
      if (variant instanceof VariantContainer) {
        if (
          !variant.errorMessage
          || containerDefaultErrorMessagesConst.includes(this.errorMessage)
        ) {
          variant.errorMessage = this.errorMessage;
        }
      }
    }

    return this;
  }

  protected setFieldAvailable(fieldId: SetupFieldEnum, value: boolean): void {
    const field: fieldTypes | null = this.findElementByName(fieldId);
    if (field) {
      field.available = value;
    }
  }

  protected setFieldRequired(fieldId: SetupFieldEnum, value: boolean): void {
    const field: AbstractField | null = this.findElementByName(fieldId);
    if (field) {
      field.required = value;
    }
  }

  protected setFieldBlocked(fieldId: SetupFieldEnum, value: boolean): void {
    const field: AbstractField | null = this.findElementByName(fieldId);
    if (field) {
      field.disabled = value;
    }
  }
}
