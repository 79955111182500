import styled from 'styled-components';

import { StyledComponent } from '../../../types/styledComponent.types';
import { FontWeight } from '../../../enums/fontWeight.enum';
import { Color } from '../../../enums/color.enum';

export const StyledTextarea: StyledComponent<'textarea'> = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 15px 0;
  box-sizing: border-box;
  border: none;
  resize: none;
  font-weight: ${FontWeight.Bold};
  font-size: 18px;
  line-height: 24px;
  background-color: ${Color.Transparent};
  &::placeholder {
    font-weight: ${FontWeight.Regular};
    font-size: 15px;
  }
`;

export const LettersQuantity: StyledComponent<'span'> = styled.span`
  font-size: 11px;
  line-height: 14px;
  color: ${Color.Grey};
`;

export const LettersQuantityContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;
