import styled, { StyledComponent } from 'styled-components';

export const ChangeVisibilityButton: StyledComponent<'button', {}, {}, never> = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const IconWrapper: StyledComponent<'div', {}, {}, never> = styled.div`
  display: flex;
  align-items: center;
`;
