import styled, { StyledComponent } from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';

export const Container: StyledComponent<'div', {}, {}, never> = styled.div`
  width: calc(100% - 110px);
  max-width: 580px;
  padding: 55px;
  border: 1px solid ${Color.GreyStroke};
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${Breakpoint.Mobile} {
    width: calc(100% - 50px);
    padding: 25px;
  }
`;

export const Image: StyledComponent<'img', {}, {}, never> = styled.img`
  max-width: 100%;
  max-height: 310px;
`;
