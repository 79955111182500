import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { FieldElement, FieldElementProps } from '../types';
import { ChooseVariantFromPopupField, SelectBarOption } from '../../../../../../../shared/forms/elements/fields';
import { SpecialProducts } from '../../../components/specialProducts/specialProducts.component';

export const ChooseVariantFromPopupFieldElement: FieldElement<ChooseVariantFromPopupField> = (
  props: FieldElementProps<ChooseVariantFromPopupField>
): JSX.Element | null => {
  const { component, save, setValues, api }: FieldElementProps<ChooseVariantFromPopupField> = props;
  const { t }: TransProps<never> = useTranslation();
  const availableOptions: SelectBarOption[] = component.options.filter(
    (option: SelectBarOption): boolean => option.available
  );

  return !!availableOptions.length ? (
    <SpecialProducts
      icon={component.boxIcon}
      options={availableOptions}
      imageDescription={t(component.promoImageDescription || '')}
      imageTitle={t(component.promoImageTitle || '')}
      img={component.promoImage}
      position={component.promoImageDescriptionDirection}
      selectTitle={t(component.popupTitle || '')}
      selectSubTitle={t(component.popupSubtitle || '')}
      boxLabel={t(component.label || '')}
      isOptional={!component.required}
      component={component}
      handleSave={save}
      setValues={setValues}
      additionalInfoContent={t(component.informationBox?.content || '')}
    />
  ) : null;
};
