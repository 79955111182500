import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { InnerContainer, OuterContainer } from '../../../../theme/containers.styled';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { styleWhenTrue } from '../../../../utils/style.utils';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Color } from '../../../../enums/color.enum';
import { Markdown } from '../../../markdown/markdown.component';

import { ItemProps, ItemsProps, ItemTextProps, RowImageProps } from './iconItems.types';

export const Container: StyledComponent<typeof OuterContainer> = styled(OuterContainer)`
  padding-top: 80px;
  background-color: ${Color.BgGrey};

  @media ${Breakpoint.Tablet} {
    margin: 0;
    width: 100%;
    padding: 60px 40px 0;
  }

  @media ${Breakpoint.Mobile} {
    padding: 60px 20px 0;
  }
`;

export const Wrapper: StyledComponent<typeof InnerContainer> = styled(InnerContainer)`
  flex-direction: column;
`;

export const Main: StyledComponent<'div'> = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 100px;
  overflow: hidden;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
    margin-bottom: 60px;
  }
`;

export const TextContent: StyledComponent<'div'> = styled.div`
  flex: 2;
`;

export const StyledMarkdown: StyledComponent<typeof Markdown> = styled(Markdown)`
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
`;

export const Items: StyledComponent<'div', ItemsProps> = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -37.5px;

  ${styleWhenTrue(prop('withoutMainImage'), css`
    margin: 0;
    justify-content: space-between;
  `)}

  @media ${Breakpoint.Desktop} {
    margin: 0 -20px;
  }

  @media ${Breakpoint.Tablet} {
    width: calc(100% + 40px);
  }

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    width: 100%;
  }
`;

export const Item: StyledComponent<'div', ItemProps> = styled.div`
  width: calc(50% - 75px);
  height: 100%;
  margin: 0 37.5px 30px;

  ${styleWhenTrue(prop('withoutMainImage'), css`
    flex: 1;
    margin: 0;
  `)}

  @media ${Breakpoint.Desktop} {
    width: calc(50% - 40px);
    margin: 0 20px 30px;
  }

  @media ${Breakpoint.Tablet} {
    flex: auto;
    margin: 0 20px 30px;
  }

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const ItemImage: StyledComponent<'img'> = styled.img`
  margin-bottom: 20px;
`;

export const ItemTitle: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: ${FontWeight.Bold};
  margin-top: 20px;
  margin-bottom: 15px;
`;

export const ItemText: StyledComponent<typeof Markdown, ItemTextProps> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;

  ${styleWhenTrue(prop('withoutMainImage'), css`
    padding-right: 25px;
  `)}

  strong {
    font-weight: ${FontWeight.Bold};
  }
`;

export const ImageContainer: StyledComponent<'div'> = styled.div`
  width: 350px;
  flex-shrink: 0;

  @media ${Breakpoint.Tablet} {
    width: auto;
  }

  @media ${Breakpoint.Mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 600px;
    overflow: hidden;
  }
`;

export const Image: StyledComponent<'img'> = styled.img`
  width: 100%;

  @media ${Breakpoint.Tablet} {
    margin: 0 -40px;
    width: calc(100% + 80px);
  }

  @media ${Breakpoint.Mobile} {
    margin: 0 -20px;
    width: calc(100% + 40px);
  }
`;

export const Row: StyledComponent<'div'> = styled.div`
  position: relative;

  :last-of-type {
    margin-bottom: 100px;
  }

  @media ${Breakpoint.Tablet} {
    :last-of-type {
      margin-bottom: 60px;
    }
  }

  @media ${Breakpoint.Mobile} {
    margin-left: -20px;
    margin-right: -20px;
  }
`;

export const RowImage: StyledComponent<'div', RowImageProps> = styled.div`
  background-image: ${({ src }: RowImageProps): string => `url(${src})`};
  background-position: center;
  height: 350px;
`;

export const RowImageMobile: StyledComponent<'img'> = styled.img`
  width: 100%;
`;

export const TextBox: StyledComponent<'div'> = styled.div`
  position: absolute;
  box-sizing: border-box;
  bottom: 25px;
  left: 25px;
  padding: 25px;
  background-color: ${Color.White};
  width: 410px;

  @media ${Breakpoint.Mobile} {
    position: static;
    width: 100%;
  }
`;

export const RowTitle: StyledComponent<'p'> = styled.p`
  font-size: 26px;
  line-height: 30px;
  font-weight: ${FontWeight.Black};
  margin-bottom: 10px;
`;

export const RowText: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;

  strong {
    font-weight: ${FontWeight.Bold};
  }
`;
