import { AdditionalServicesEnum } from '@rehau/shared/enums';
import { Tooltip } from '@rehau/shared/forms/elements';
import { ServiceSimpleModel } from '@rehau/shared/models/calculation/common';

export const AdditionalServicesArray: ServiceSimpleModel[] = [
  {
    id: AdditionalServicesEnum.Resource,
    label: 'rehau.calculations.additional_services.resource.label',
    tooltip: new Tooltip(
      'rehau.calculations.additional_services.resource.tooltip',
      'rehau.calculations.additional_services.resource.label'
    ),
  },
  {
    id: AdditionalServicesEnum.Reguard,
    label: 'rehau.calculations.additional_services.reguard.label',
    tooltip: new Tooltip(
      'rehau.calculations.additional_services.reguard.tooltip',
      'rehau.calculations.additional_services.reguard.label'
    ),
  },
  {
    id: AdditionalServicesEnum.SolarSystem,
    label: 'rehau.calculations.additional_services.solar_system.label',
    tooltip: new Tooltip(
      'rehau.calculations.additional_services.solar_system.tooltip',
      'rehau.calculations.additional_services.solar_system.label'
    ),
  },
  {
    id: AdditionalServicesEnum.Photovoltaic,
    label: 'rehau.calculations.additional_services.photovoltaic.label',
    tooltip: new Tooltip(
      'rehau.calculations.additional_services.photovoltaic.tooltip',
      'rehau.calculations.additional_services.photovoltaic.label'
    ),
  },
  {
    id: AdditionalServicesEnum.Ventilation,
    label: 'rehau.calculations.additional_services.ventilation.label',
    tooltip: new Tooltip(
      'rehau.calculations.additional_services.ventilation.tooltip',
      'rehau.calculations.additional_services.ventilation.label'
    ),
  },
  {
    id: AdditionalServicesEnum.CirculationInstallation,
    label: 'rehau.calculations.additional_services.circulation_installation.label',
    tooltip: new Tooltip(
      'rehau.calculations.additional_services.circulation_installation.tooltip',
      'rehau.calculations.additional_services.circulation_installation.label'
    ),
  },
  {
    id: AdditionalServicesEnum.FreshWaterModule,
    label: 'rehau.calculations.additional_services.fresh_water_module.label',
    tooltip: new Tooltip(
      'rehau.calculations.additional_services.fresh_water_module.tooltip',
      'rehau.calculations.additional_services.fresh_water_module.label'
    ),
  },
  {
    id: AdditionalServicesEnum.DecalcificationModule,
    label: 'rehau.calculations.additional_services.decalcification_module.label',
    tooltip: new Tooltip(
      'rehau.calculations.additional_services.decalcification_module.tooltip',
      'rehau.calculations.additional_services.decalcification_module.label'
    ),
  },
];
