import React from 'react';

import { IconName } from '../../../../../../shared/enums';
import { Icon } from '../../components/icon/icon.component';
import { Color } from '../../enums/color.enum';

import { CheckContainer } from './check.styled';
import { CheckProps } from './check.types';

export const Check: React.FC<CheckProps> = (props: CheckProps): JSX.Element => {
  const { color = Color.Green , disabled = false }: CheckProps = props;

  return (
    <CheckContainer color={color} disabled={disabled} data-testid='check'>
      <Icon color={Color.White} name={disabled ? IconName.No : IconName.Check} size={disabled ? 10 : 12} />
    </CheckContainer>
  );
};
