import React, { useState } from 'react';
import useCollapse from 'react-collapsed';
import { UseCollapseOutput } from 'react-collapsed/dist/types';
import { TransProps, useTranslation } from 'react-i18next';

import { ChooseVariantField } from '@rehau/shared/forms/elements';
import { UseState } from '../../types/useState.types';
import { DecisionButtonType } from '../decision/components/decisionButton/decisionButton.enums';
import { SingleSelectBox } from '../select/singleSelectBox/singleSelectBox.component';

import { DecisionButtonsContainer, Label, StyledDecisionButton, VariantsContainer } from './chooseVariantFromDecisionSelect.styled';
import { ChooseVariantFromDecisionSelectProps } from './chooseVariantFromDecisionSelect.types';

export const ChooseVariantFromDecisionSelect: React.FC<ChooseVariantFromDecisionSelectProps> = (
  props: ChooseVariantFromDecisionSelectProps
): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    label,
    options,
    component = new ChooseVariantField('', '', '', false),
    handleSave = (): void => undefined,
    setValues = (): void => undefined,
    acceptLabel = t('rehau.global.yes'),
    cancelLabel = t('rehau.global.no'),
  }: ChooseVariantFromDecisionSelectProps = props;
  const [variant, setVariant]: UseState<string | null> = useState<string | null>(component.value as string);
  const { getCollapseProps, setExpanded }: UseCollapseOutput = useCollapse({ isExpanded: !!variant });

  const chooseValue: (value: string | null) => void = (value: string | null): void => {
    setValues({ [component.id]: value });
    setVariant(value);
    const validationSuccess: boolean = component.validate();
    if (validationSuccess) {
      handleSave();
    }
  };

  const handleClickYes: () => void = (): void => {
    setVariant(' ');
    setExpanded(true);
  };

  const handleClickNo: () => void = (): void => {
    setVariant(null);
    setExpanded(false);
    chooseValue(null);
  };

  return (
    <>
      <Label>{label}</Label>
      <DecisionButtonsContainer>
        <StyledDecisionButton
          text={acceptLabel || ''}
          onClick={handleClickYes}
          isActive={!!variant}
        />
        <StyledDecisionButton
          text={cancelLabel || ''}
          onClick={handleClickNo}
          isActive={variant === null}
          buttonType={DecisionButtonType.Decline}
        />
      </DecisionButtonsContainer>
      <VariantsContainer {...getCollapseProps()}>
        <SingleSelectBox
          items={options}
          activeId={variant?.toString()}
          onSelectOption={chooseValue}
        />
      </VariantsContainer>
   </>
  );
};
