import { prop } from 'ramda';
import styled, { css, StyledComponent } from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { BoxShadow } from '../../enums/boxShadow.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { hexToRgba } from '../../utils/colors.utils';
import { styleWhenTrue } from '../../utils/style.utils';
import { Price } from '../../theme/price/price.component';
import { Icon } from '../icon/icon.component';

import { ContainerProps, LabelContainerProps, MainIconContainerProps, PriceContainerProps, VariantProps } from './flatBox.types';

export const ArrowIcon: StyledComponent<typeof Icon, {}, {}, never> = styled(Icon)`
  color: ${Color.Green};
  transition: 0.3s;
`;

export const IconPlug: StyledComponent<'div', {}, MainIconContainerProps> = styled.div`
  width: 50px;
  height: 50px;
  flex-shrink: 0;
`;

export const MainIcon: StyledComponent<typeof Icon, {}, {}, never> = styled(Icon)``;

export const MainIconContainer: StyledComponent<'div', {}, MainIconContainerProps> = styled.div`
  ${styleWhenTrue(prop('isCircle'), css`
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Color.Green};
    width: 40px;
    height: 40px;
    margin-right: 10px;
    flex-shrink: 0;

    ${MainIcon} {
      color: ${Color.White};
      font-size: 18px;
    }
  `)}
`;

export const IconWithLabel: StyledComponent<'div', {}, {}, never> = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelContainer: StyledComponent<'div', {}, LabelContainerProps, never> = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 10px;
  width: 100%;
  flex-direction: column;
`;

export const Label: StyledComponent<'p', {}, {}, never> = styled.p`
  font-weight: ${FontWeight.Regular};
  font-size: 18px;
  line-height: 22px;
  color: ${Color.Grey};
  text-align: left;
`;

export const OptionalLabel: StyledComponent<'p', {}, {}, never> = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${Color.Grey};
`;

export const LabelValue: StyledComponent<'span', {}, {}, never> = styled.span`
  font-weight: ${FontWeight.Bold};
  font-size: 18px;
  line-height: 22px;
  color: ${Color.Green};
`;

export const ValuesContainer: StyledComponent<'div', {}, {}, never> = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionButton: StyledComponent<'div', {}, {}, never> = styled.div`
  display: flex;
  align-items: center;
`;

export const VariantsContainer: StyledComponent<'div', {}, {}, never> = styled.div`
  display: flex;
  align-items: center;
`;

export const Variant: StyledComponent<'div', {}, VariantProps, never> = styled.div<VariantProps>`
  color: ${hexToRgba(Color.Black, 0.25)};
  ${styleWhenTrue(prop('isActive'), css`
    color: ${Color.Black};
  `)};
`;

export const VariantsLabel: StyledComponent<'div', {}, {}, never> = styled.div`
  font-weight: ${FontWeight.Black};
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${Color.Grey};
  margin-right: 10px;
`;

export const Variants: StyledComponent<'div', {}, {}, never> = styled.div`
  font-weight: ${FontWeight.Black};
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${Color.Black};
  display: flex;
  align-items: center;
`;

export const VariantsSeparator: StyledComponent<'div', {}, {}, never> = styled.div`
  color: ${Color.GreyStroke};
  margin: 0 5px;
`;

export const SuccessIcon: StyledComponent<'div', {}, {}, never> = styled.div`
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.Green};
  box-shadow: ${BoxShadow.BtnGreenShadow};
  border-radius: 50%;
  margin-left: 10px;
  flex-shrink: 0;
`;

export const ActionLabel: StyledComponent<'div', {}, {}, never> = styled.div`
  font-weight: ${FontWeight.Bold};
  font-size: 16px;
  line-height: 24px;
  color: ${Color.Black};
  margin-right: 14px;
  margin-bottom: 2px;
`;

export const WarnLabel: StyledComponent<'div', {}, {}, never> = styled.div`
  font-weight: ${FontWeight.Bold};
  font-size: 16px;
  line-height: 24px;
  color: ${Color.ValidationFont};
  margin-right: 17px;
`;

export const Container: StyledComponent<'button', {}, ContainerProps, never> = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 74px;
  width: 100%;
  max-width: 610px;
  border: 1px solid ${Color.GreyStroke};
  padding-left: 12px;
  padding-right: 17px;
  transition: 0.3s;
  &:hover {
    border-color: ${Color.Grey};
    box-shadow: ${BoxShadow.BtnGreyShadow};
    ${ArrowIcon} {
      color: ${Color.Black};
    }
  }
  ${styleWhenTrue(prop('isActive'), css`
    border-color: ${Color.Green};
    box-shadow: ${BoxShadow.BtnGreyShadow};
    ${Label} {
      font-weight: ${FontWeight.Bold};
      color: ${Color.Black};
    }
  `)};
  ${styleWhenTrue(prop('hasWarning'), css`
    border-color: ${hexToRgba(Color.Pink, 0.35)};
    background-color: ${hexToRgba(Color.Pink, 0.05)};
    box-shadow: none;
    ${Label} {
      font-weight: ${FontWeight.Bold};
      color: ${Color.ValidationFont};
    }
  `)};
  ${styleWhenTrue(prop('disabled'), css`
    pointer-events: none;
    cursor: default;
  `)};
`;
