import gsap, { Power1 } from 'gsap';
import { RefObject, useLayoutEffect, useRef } from 'react';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import { UseImageAnimationOutput } from '../types/useImageAnimation.types';

export const useImageAnimation: (disabled?: boolean) => UseImageAnimationOutput = (disabled?: boolean): UseImageAnimationOutput => {
  const firstImageRef: RefObject<HTMLDivElement> = useRef(null);
  const secondImageRef: RefObject<HTMLDivElement> = useRef(null);

  const imageAnimation: (ref: HTMLElement) => void = (ref: HTMLElement): void => {
    const animation: gsap.core.Tween = gsap.to(ref, {
      scrollTrigger: {
        trigger: ref,
        start: 'center-=20px bottom',
      },
      xPercent: 0,
      duration: 0.6,
      delay: 0.1,
      ease: Power1.easeInOut,
    });

    ScrollTrigger.create({
      trigger: ref,
      start: 'top-=50px bottom',
      onLeaveBack: (): void => {
        animation.pause(0);
      },
    });
  };

  useLayoutEffect(
    (): void => {
      if (!disabled) {
        if (firstImageRef.current) {
          gsap.set(firstImageRef.current, { xPercent: 105 });
          imageAnimation(firstImageRef.current);
        }

        if (secondImageRef.current) {
          gsap.set(secondImageRef.current, { xPercent: 105 });
          imageAnimation(secondImageRef.current);
        }
      }
    },
    [disabled]
  );

  return {
    firstImageRef,
    secondImageRef,
  };
};
