import styled from 'styled-components';

import { BaseLabel } from '../../theme';
import { StyledComponent } from '../../types/styledComponent.types';

export const ChildrenWrapper: StyledComponent<'div'> = styled.div`
  margin-top: 30px;
`;

export const Label: StyledComponent<typeof BaseLabel> = styled(BaseLabel)`
  margin-bottom: 10px;
`;

export const ButtonsContainer: StyledComponent<'div'> = styled.div`
  display: flex;
`;
