import { BaseAbstractContainer } from '@rehau/shared/forms/baseAbstract.container';
import { componentTypes, fieldTypes, fieldValueType, staticElementTypes } from '@rehau/shared/forms/types';
import {
  ErrorMessageEnum,
  FormContainerTypeEnum,
  FrontendComponentEnum,
  HorizontalFieldlineThemeEnum,
  WebsiteComponentEnum
} from '@rehau/shared/enums';
import { AbstractField, InfoBox } from '@rehau/shared/forms/elements';
import { Config } from '@rehau/shared/models/countryConfig';

export abstract class AbstractContainer extends BaseAbstractContainer {
  public abstract elements: (fieldTypes | componentTypes | staticElementTypes)[];
  public type: FormContainerTypeEnum;
  public frontendComponent: FrontendComponentEnum;
  public title: string;
  public subTitle?: string;
  public informationBox?: InfoBox;
  public errorMessage?: ErrorMessageEnum;
  public changesWasPublished: boolean = true;
  protected countryConfig?: Config;
  public frontendTheme?: HorizontalFieldlineThemeEnum;

  protected constructor(
    type: FormContainerTypeEnum,
    frontendComponent: FrontendComponentEnum,
    title: string,
    countryConfig?: Config,
    informationBox?: InfoBox,
    subTitle?: string,
    id?: string,
    available: boolean = true,
    errorMessage?: ErrorMessageEnum,
    frontendTheme: HorizontalFieldlineThemeEnum = HorizontalFieldlineThemeEnum.FullSize
  ) {
    super();
    this.type = type;
    this.frontendComponent = frontendComponent;
    this.title = title;
    this.countryConfig = countryConfig;
    this.subTitle = subTitle;
    this.informationBox = informationBox;
    this.id = id;
    this.errorMessage = errorMessage;
    this.available = available;
    this.frontendTheme = frontendTheme;
  }

  public abstract addElement(
    element: fieldTypes | componentTypes | staticElementTypes,
    condition?: boolean,
    name?: string
  ): this;

  public setCountryConfig(countryConfig?: Config): this {
    if (countryConfig && Object.keys(countryConfig).length) {
      this.countryConfig = countryConfig;
    }

    return this;
  }

  public setChangesWasPublishedFlag(publishedBefore: boolean): this {
    this.changesWasPublished = publishedBefore;
    for (const element of this.elements) {
      if (element instanceof AbstractContainer) {
        element.setChangesWasPublishedFlag(publishedBefore);
      }
      if (element instanceof AbstractField) {
        element.setIsSetupFieldFlag(true);
      }
    }

    return this;
  }

  protected getCountryConfigValue(
    name: string,
    variablesSource: WebsiteComponentEnum = WebsiteComponentEnum.Setup
  ): fieldValueType | undefined {
    return (this.countryConfig?.variables && this.countryConfig.variables[variablesSource])
      ? this.countryConfig.variables[variablesSource][name]
      : undefined;
  }
}
