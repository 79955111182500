import { AbstractField } from '@rehau/shared/forms/elements/fields';
import { RequiredFieldValidation } from '@rehau/shared/forms/validations';
import { FormFieldTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { InfoBox, SelectBarOption } from '@rehau/shared/forms/elements';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';

export class ChooseVariantField extends AbstractField {
  public options: SelectBarOption[];

  constructor(
    id: string,
    name: string,
    label: string,
    required: boolean,
    frontendComponent: FrontendComponentEnum = FrontendComponentEnum.ChooseVariantFromSelectWithDecision,
    type: string = FormFieldTypeEnum.ChooseVariantField,
    configMapping?: ObjectIteratorInterface<string>,
    informationBox?: InfoBox
  ) {
    super(
      type,
      frontendComponent,
      id,
      name,
      label,
      null,
      new RequiredFieldValidation(),
      required,
      false,
      informationBox,
      undefined,
      configMapping
    );
    this.options = [];
  }

  public addOption(element: SelectBarOption, condition?: boolean): this {
    if (condition !== false && element instanceof SelectBarOption) {
      this.options.push(element);
    }

    return this;
  }

  private isAnyOptionAvailable(): boolean {
    return this.options.some((element: SelectBarOption): boolean => element.available);
  }

  public updateConfig(configObjectInterface: ObjectIteratorInterface): void {
    super.updateConfig(configObjectInterface);
    this.options.forEach((option: SelectBarOption): void => option.updateConfig(configObjectInterface));
    this.available = this.isAnyOptionAvailable();
  }
}
