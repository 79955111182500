export enum PermissionScopeEnum {
  UserProfile = 'userProfile',
  UserCreate = 'userCreate',
  UserRemove = 'userRemove',
  UserManagement = 'userManagement',
  InternalUserManagement = 'internalUserManagement',
  CompanyManage = 'companyManagement',
  CompanyManageByRegion = 'CompanyManageByRegion',
  CompanyManageByCountry = 'CompanyManageByCountry',
  AppStatus = 'AppStatus',
  SetupsEdit = 'SetupsEdit',
  Setups = 'Setups',
  OffersTestRunning = 'OffersTestRunning',
  OffersList = 'OffersList',
  OffersSummaryPreview = 'OffersSummaryPreview',
  OffersLeadEdit = 'OffersLeadEdit',
  OffersSettingsEdit = 'OffersSettingsEdit',
  OffersLeadQualificationEdit = 'OffersLeadQualificationEdit',
  CompaniesEdit = 'CompaniesEdit',
  CompaniesSettingsEdit = 'CompaniesSettingsEdit',
  UsersEdit = 'UsersEdit',
  ManageAllCountries = 'ManageAllCountries',
  ManageCountries = 'ManageCountries',
  ManageRegions = 'ManageRegions',
  ManageOwnResource = 'ManageOwnResource',
  AdminDashboardsAccess = 'AdminDashboardsAccess',
  AdminPanelsOffersAccess = 'AdminPanelsOffersAccess',
  AdminPanelsAccess = 'AdminPanelsAccess',
  AdminPanelsRead = 'AdminPanelsRead',
  AdminPanelsOffersList = 'AdminPanelsOffersList',
  OffersStateEdit = 'OffersStateEdit',
  RegistrationsList = 'RegistrationsList',
  RegistrationsVerify = 'RegistrationsVerify',
  RegistrationsDelete = 'RegistrationsDelete',
  CompaniesList = 'CompaniesList',
  CompaniesUsersList = 'CompaniesUsersList',
  CompaniesUsersEdit = 'CompaniesUsersEdit',
  UsersDelete = 'UsersDelete',
  CrmRefresh = 'CrmRefresh',
  CompaniesDelete = 'CompaniesDelete',
  InternalUsersList = 'InternalUsersList',
  InternalUsersEdit = 'InternalUsersEdit',
  InternalUsersDelete = 'InternalUsersDelete',
  InternalUsersAdd = 'InternalUsersAdd',
  AdminManagementAccess = 'AdminManagementAccess',
  ManagePermissions = 'ManagePermissions',
  CalculationTaskRead = 'CalculationTaskRead',
  CalculationTaskCreate = 'CalculationTaskCreate',
  CalculationTaskUpdate = 'CalculationTaskUpdate',
  CalculationTaskRemove = 'CalculationTaskRemove',
  CalculationTaskSetCompleted = 'CalculationTaskSetCompleted',
}
