import React from 'react';

import LogoIcon from './img/logo.svg';
import FullLogoIcon from './img/fullLogoIcon.svg';
import { LogoProps } from './logo.types';
import { LogoImg } from './logo.styled';
import SmallLogo from './img/small-logo.svg';

export const Logo: React.FC<LogoProps> = (props: LogoProps): JSX.Element => {
  const { className, full = false, withoutText }: LogoProps = props;
  const src: string = full ? FullLogoIcon : LogoIcon;

  if (withoutText) {
    return <LogoImg className={className} src={SmallLogo} />;
  }

  return <LogoImg className={className} src={src} full={full} data-testid='logo' />;
};
