import gsap, { Power1 } from 'gsap';
import { RefObject, useLayoutEffect, useRef } from 'react';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import { UseTimelineAnimationOutput } from '../types/useTimelineAnimation.types';
import { BoxRefName } from '../types/boxRefName.types';

gsap.registerPlugin(ScrollTrigger);

export const useTimelineAnimation: (disabled?: boolean) => UseTimelineAnimationOutput = (
  disabled?: boolean
): UseTimelineAnimationOutput => {
  const firstBoxRef: RefObject<HTMLDivElement> = useRef(null);
  const secondBoxRef: RefObject<HTMLDivElement> = useRef(null);
  const thirdBoxRef: RefObject<HTMLDivElement> = useRef(null);
  const fourthBoxRef: RefObject<HTMLDivElement> = useRef(null);
  const fifthBoxRef: RefObject<HTMLDivElement> = useRef(null);

  const timelineAnimation: (ref: HTMLElement, delay: number) => void = (ref: HTMLElement, delay: number): void => {
    const currentDuration: number = 0.6 + delay;
    const animation: gsap.core.Tween = gsap.to(ref, {
      scrollTrigger: {
        trigger: ref,
        start: 'top-=50% bottom',
      },
      yPercent: 0,
      duration: currentDuration,
      delay: 2,
      ease: Power1.easeInOut,
    });

    ScrollTrigger.create({
      trigger: ref,
      start: 'top-=50% bottom',
      onLeaveBack: (): void => {
        animation.pause(0);
      },
    });
  };

  const set: (ref: RefObject<HTMLDivElement>) => void = (ref: RefObject<HTMLDivElement>): void => {
    if (ref.current) {
      gsap.set(ref.current, { yPercent: 105 });
    }
  };

  const animate: (ref: RefObject<HTMLDivElement>, delay: number) => void = (ref: RefObject<HTMLDivElement>, delay: number): void => {
    if (ref.current) {
      timelineAnimation(ref.current, delay);
    }
  };

  useLayoutEffect(
    (): void => {
      if (!disabled) {
        set(firstBoxRef);
        set(secondBoxRef);
        set(thirdBoxRef);
        set(fourthBoxRef);
        set(fifthBoxRef);

        animate(firstBoxRef, 0);
        animate(secondBoxRef, 0.2);
        animate(thirdBoxRef, 0.4);
        animate(fourthBoxRef, 0.6);
        animate(fifthBoxRef, 0.8);
      }
    },
    [disabled, firstBoxRef, secondBoxRef, thirdBoxRef, fourthBoxRef, fifthBoxRef]
  );

  return {
    firstBoxRef,
    secondBoxRef,
    thirdBoxRef,
    fourthBoxRef,
    fifthBoxRef,
  };
};
