import { componentTypes, fieldTypes } from '@rehau/shared/forms/types';
import { FormContainerTypeEnum, FrontendComponentEnum, IconName } from '@rehau/shared/enums';
import { AbstractField, InfoBox } from '@rehau/shared/forms/elements';
import { Config } from '@rehau/shared/models/countryConfig';
import { DecisionWithChildrenContainer } from '@rehau/shared/forms/components';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';

export class VariantContainer extends DecisionWithChildrenContainer {
  public active: boolean = false;
  public shortTitle: string | null;
  public enableActionLabel?: string;
  public icon?: IconName;
  public shouldRestoreFromDatabase: boolean = true;
  private hasFieldsSetDisabledOnInit: boolean = false;

  public constructor(
    title: string,
    shortTitle: string | null,
    elements: (fieldTypes | componentTypes)[] = [],
    countryConfig?: Config,
    enableActionLabel?: string,
    icon?: IconName,
    informationBox: InfoBox = new InfoBox('rehau.global.common_infobox.content'),
    subTitle?: string
  ) {
    super(title, elements, countryConfig, informationBox, subTitle);
    this.frontendComponent = FrontendComponentEnum.ExpandedConditionalBox;
    this.type = FormContainerTypeEnum.VariantContainer;
    this.icon = icon;
    this.enableActionLabel = enableActionLabel;
    this.shortTitle = shortTitle;
  }

  public setValues(values: ObjectIteratorInterface): void {
    const decisionField: AbstractField | null = this.getDecisionField();
    if (decisionField) {
      // hasFieldsSetDisabledOnInit => additional field becase elements array is sometimes empty
      if (
        !this.hasFieldsSetDisabledOnInit
        || (values[decisionField.id] !== undefined && values[decisionField.id] !== null && decisionField.value !== values[decisionField.id])
      ) {
        this.setDisabledForAllFields(!values[decisionField.id]);
        this.hasFieldsSetDisabledOnInit = true;
      }
    }
    super.setValues(values);
  }

  private setDisabledForAllFields(value: boolean): void {
    this.getAllFields().forEach((element: fieldTypes): AbstractField => element.setDisabled(value));
  }
}
