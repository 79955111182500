import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';
import { prop } from 'ramda';

import { BoxShadow } from '../../../enums/boxShadow.enum';
import { Breakpoint } from '../../../enums/breakpoint.enum';
import { Color } from '../../../enums/color.enum';
import { GridWidth } from '../../../enums/gridWidth.enum';
import { StyledComponent } from '../../../types/styledComponent.types';
import { styleWhenTrue, ZIndex } from '../../..';

import { WrapperProps } from './infoBase.types';

export const Wrapper: StyledComponent<'div', WrapperProps> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 100px);
  height: 100%;
  background-color: ${Color.BgGrey};
  padding: 0;
  padding-top: 50px;
  z-index: ${ZIndex.ErrorScreen};
  position: relative;
  ${styleWhenTrue(prop('hasBar'), css`
    min-height: calc(100vh - 58px);
    padding: 0 0 50px;
  `)};

  @media ${Breakpoint.Mobile} {
    padding: 0;
  }
`;

export const Bar: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: 58px;
  background-color: ${Color.White};
  box-shadow: ${BoxShadow.BtnGreyShadow};
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  box-sizing: border-box;

  @media ${Breakpoint.Tablet} {
    margin-bottom: 0;
  }
`;

export const Img: StyledComponent<typeof SVG> = styled(SVG)`
  max-width: 300px;
  flex-shrink: 0;
  max-height: 188px;

  @media ${Breakpoint.Tablet} {
    padding: 52px 0;
  }

  @media ${Breakpoint.Additional480} {
    max-width: 180px;
    height: 100%;
  }
`;

export const ContentContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;

  @media ${Breakpoint.Mobile} {
    padding: 0 40px;
    flex-direction: column;
    align-items: center;
  }

  @media ${Breakpoint.Additional480} {
    padding: 0px 12px;
  }
`;

export const Content: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${GridWidth.Desktop};
  background-color: ${Color.White};
  box-shadow: ${BoxShadow.BtnGreyShadow};
  height: fit-content;
  padding: 102px 185px 103px 80px;
  box-sizing: border-box;
  word-break: break-word;

  @media ${Breakpoint.Tablet} {
    width: 100%;
    padding: 140px 30px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 60px 30px;
    margin-bottom: 20px;
  }

  @media ${Breakpoint.Additional480} {
    padding: 30px 20px;
    margin-bottom: 0;
  }
`;
