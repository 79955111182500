import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { Color } from '../../../../enums/color.enum';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { BoxShadow } from '../../../../enums/boxShadow.enum';
import { Icon } from '../../../../components/icon/icon.component';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { ZIndex } from '../../../../enums/zIndex.enum';
import { styleWhenTrue } from '../../../../utils/style.utils';

import { ContainerProps } from './fixedScrollTopArrow.types';

export const Container: StyledComponent<'button', ContainerProps> = styled.button`
  position: fixed;
  bottom: 25px;
  right: 25px;
  padding: 0;
  margin: 0;
  z-index: ${ZIndex.FixedScrollTopArrow};
  opacity: 1;
  transition: 0.2s;

  ${styleWhenTrue(
    prop('invisible'),
    css`
      opacity: 0;
      pointer-events: none;
    `
  )}
`;

export const Tooltip: StyledComponent<'div'> = styled.div`
  position: absolute;
  display: flex;
  left: -20px;
  top: 49%;
  transform: translate(-100%, -50%);
  background-color: ${Color.Black};
  color: ${Color.White};
  pointer-events: none;
  transition: 0.2s;
  padding: 10.5px 10px;
  width: max-content;
  font-size: 12px;
  line-height: 23px;
  font-weight: ${FontWeight.Medium};
  box-sizing: border-box;
  height: 45px;

  @media ${Breakpoint.Mobile} {
    display: none;
  }

  :after {
    content: ' ';
    position: absolute;
    top: 49%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent ${Color.Black};
  }
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  transform: rotate(-90deg);
`;

export const ArrowContainer: StyledComponent<'div'> = styled.div`
  background-color: ${Color.White};
  box-shadow: ${BoxShadow.BtnGreyShadow};
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
