import { prop, propEq } from 'ramda';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { BoxShadow } from '../../enums/boxShadow.enum';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { ZIndex } from '../../enums/zIndex.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { hexToRgba } from '../../utils/colors.utils';
import { styleWhenTrue } from '../../utils/style.utils';
import { Icon } from '../icon/icon.component';

import { MenuType, Theme } from './contextMenu.enums';
import { MenuProps, MenuTriggerProps, PositionIconProps, PositionNameProps } from './contextMenu.types';

export const Container: StyledComponent<'div'> = styled.div`
  position: relative;
  width: max-content;
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)``;

export const MenuTrigger: StyledComponent<'button', MenuTriggerProps> = styled.button<MenuTriggerProps>`
  transition: 0.3s;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ menuType }: MenuTriggerProps): string => menuType === MenuType.Default ? '40px' : '26px'};
  height: ${({ menuType }: MenuTriggerProps): string => menuType === MenuType.Default ? '40px' : '26px'};
  background-color: ${Color.Transparent};

  ${(props: MenuTriggerProps): FlattenSimpleInterpolation => css`
    ${props.isActive && props.theme === Theme.Light  && `background-color: ${hexToRgba(Color.Grey, 0.1)}`};
    ${props.isActive && props.theme === Theme.Green && `
      background: linear-gradient(0deg, ${hexToRgba(Color.Black, 0.2)}, ${hexToRgba(Color.Black, 0.2)}), ${Color.Green};
    `};
    ${!props.isActive && props.theme === Theme.Green && `
      background: ${Color.Green};
    `};
    ${StyledIcon} {
      ${props.isActive && props.theme === Theme.Dark && `color: ${Color.Black}`};
      ${props.theme === Theme.Green && `color: ${Color.White}`};
    }

    @media ${Breakpoint.Hover} {
      &:hover {
        background: ${props.theme === Theme.Green ? Color.Black : hexToRgba(Color.Grey, 0.1)};
        ${StyledIcon} {
          ${props.theme === Theme.Dark &&  `color: ${Color.Black}`};
          ${props.theme === Theme.Light && `color: ${Color.Grey}`};
          ${props.theme === Theme.Green && `color: ${Color.White}`};
        }
      }
    }
  `};
`;

export const Menu: StyledComponent<'div', MenuProps> = styled.div<MenuProps>`
  background-color: ${Color.White};
  box-shadow: ${BoxShadow.BtnGreyShadow};
  ${styleWhenTrue(
    propEq('theme', Theme.Dark),
    css`
      background-color: ${Color.Grey};
    `
  )}
  ${styleWhenTrue(
    propEq('theme', Theme.Green),
    css`
      background-color: ${Color.Grey};
    `
  )}
  transform: rotate(0);
  border-radius: 2px;
  z-index: ${ZIndex.ContextMenu};
  padding: 10px 0;
  width: 200px;
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  ${styleWhenTrue(
    prop('isVisible'),
    css`
      opacity: 1;
      pointer-events: initial;
    `
  )}

  @media ${Breakpoint.Mobile} {
    top: unset;
    margin-top: 5px;

    ${styleWhenTrue(
    prop('withRightPosition'),
    css`
      right: 22px;
    `
    )}
  }
`;

export const PositionIcon: StyledComponent<typeof Icon, PositionIconProps> = styled(Icon)`
  transition: 0.3s;
  color: ${({ theme }: PositionNameProps): Color => (theme === Theme.Light ? Color.Grey : Color.White)};
`;

export const PositionName: StyledComponent<'div', PositionNameProps> = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }: PositionNameProps): Color => (theme === Theme.Light ? Color.Grey : Color.White)};
  margin-left: 15px;
  word-break: break-word;
  text-align: left;
`;

export const MenuPosition: StyledComponent<'button'> = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 18px;
  width: 100%;

  @media ${Breakpoint.Hover} {
    &:hover {
      ${PositionIcon} {
        color: ${Color.Black};
      }
      ${PositionName} {
        color: ${Color.Black};
      }
    }
  }
`;
