import styled from 'styled-components';

import { Breakpoint, Color, FontWeight, GridWidth, Markdown, StyledComponent } from '@rehau-fe/ui/src';

import { StyledHeroProps } from './hero.types';

export const InfoBoxContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  max-width: ${GridWidth.Desktop};
  padding-bottom: 40px;
  margin: 0 auto;
  box-sizing: border-box;

  @media ${Breakpoint.Additional1280} {
    padding: 40px;
  }

  @media ${Breakpoint.Additional480} {
    display: none;
  }
`;

export const CalculationInfoBox: StyledComponent<'div'> = styled.div`
  width: 440px;
  box-sizing: border-box;
  background-color: ${Color.White};
  padding: 30px;
  padding-right: 45px;

  @media ${Breakpoint.Tablet} {
    width: 100%;
  }
`;

export const StyledHero: StyledComponent<'div', StyledHeroProps> = styled.div<StyledHeroProps>`
  position: relative;
  width: 100%;
  height: ${(props: StyledHeroProps): number => props.imageHeight}px;
  background-image: url(${(props: StyledHeroProps): string => props.desktopImage});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;

  &:hover ${CalculationInfoBox} {
    transform: none;
  }

  @media ${Breakpoint.Mobile} {
    height: 80vh;
    background-image: url(${(props: StyledHeroProps): string => props.image});
  }

  @media ${Breakpoint.Additional480} {
    position: static;
  }
`;

export const Title: StyledComponent<'h2'> = styled.h2`
  font-size: 28px;
  line-height: 30px;
  font-weight: ${FontWeight.Black};
  word-break: break-word;
  margin-bottom: 10px;
`;

export const Description: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 25px;
  word-break: break-word;
`;

export const ButtonContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
`;
