import { prop, propEq } from 'ramda';
import styled, { css, FlattenInterpolation } from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { BoxShadow } from '../../enums/boxShadow.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleIfElse, styleWhenTrue } from '../../utils/style.utils';
import { CustomLink } from '../customLink/customLink.component';

import { TabContainerProps } from './tabMenu.types';
import { TabTheme } from './tabMenu.enums';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
`;

export const TabText: StyledComponent<'p'> = styled.p`
  font-size: 15px;
  line-height: 18px;
  font-weight: ${FontWeight.Regular};
  color: ${Color.Black};
  word-break: break-word;

  ${styleWhenTrue(propEq('theme', TabTheme.Secondary), css`
    font-weight: ${FontWeight.Black};
    font-size: 13px;
    line-height: 16px;
  `)}

  @media ${Breakpoint.Tablet} {
    font-size: 20px;
    line-height: 24px;
    font-weight: ${FontWeight.Bold};
  }
`;

const tabStyles: FlattenInterpolation<TabContainerProps> = css`
  min-width: 140px;
  height: ${({ theme }: TabContainerProps): string => theme === TabTheme.Primary ? '48px' : '44px'};
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  background-color: ${({ theme }: TabContainerProps): Color => theme === TabTheme.Primary ? Color.GreyStroke : Color.BgGrey};
  text-transform: ${({ theme }: TabContainerProps): string => theme === TabTheme.Secondary ? 'uppercase' : 'default'};
  transition: 0.2s;
  ${({ isActive, theme }: TabContainerProps): string => (
    isActive ? `
      background-color: ${theme === TabTheme.Primary ? Color.Green : Color.Black};
      box-shadow: ${theme === TabTheme.Primary ? BoxShadow.LongGreenShadow : BoxShadow.LongGreenShadow};
      ${TabText} {
        color: ${Color.White};
      }
      ${theme === TabTheme.Primary ? `
        ${TabText} {
          font-weight: ${FontWeight.Bold};
        }
      ` : ''}
    ` : ''
  )}

  :hover {
    background-color: ${styleIfElse(propEq('theme', TabTheme.Primary), Color.Black, Color.Grey)};

    ${TabText} {
      color: ${Color.White};
    }
  }
`;

export const TabContainer: StyledComponent<typeof CustomLink, TabContainerProps> = styled(CustomLink)<TabContainerProps>`
  ${tabStyles}
`;

export const ButtonTabContainer: StyledComponent<'button', TabContainerProps> = styled.button<TabContainerProps>`
  ${tabStyles}
`;
