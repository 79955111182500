import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { InputController } from '../../input/inputController.component';
import { TextAreaField } from '../../../../../../../shared/forms/elements';
import { FieldElement, FieldElementProps } from '../types';
import { StandardInput } from '../../input/standardInput/standardInput.component';
import { FrontendComponentEnum, IconName } from '../../../../../../../shared/enums';

export const TextFieldElement: FieldElement<TextAreaField> = (props: FieldElementProps<TextAreaField>): JSX.Element => {
  const { component, save, setValues }: FieldElementProps<TextAreaField> = props;
  const { t }: TransProps<never> = useTranslation();
  let icon: IconName | null = null;
  switch (component.frontendComponent) {
    case FrontendComponentEnum.MailField:
      icon = IconName.Mail;
      break;
    case FrontendComponentEnum.PhoneField:
      icon = IconName.Phone;
      break;
  }

  return (
    <InputController
      label={t(component.label || '')}
      additionalInfoContent={t(component.informationBox?.content || '')}
      additionalInfoContentInReadMore={t(component.informationBox?.readMoreContent || '')}
      tooltipProps={component.tooltip}
      component={component}
      handleSave={save}
      setValues={setValues}
      disabled={component.onlyForRead || component.disabled}
    >
      <StandardInput
        placeholder={t(component.placeholder)}
        icon={icon as IconName}
        autocomplete={component.autocomplete}
      />
    </InputController>
  );
};
