import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { fieldTypes } from '@rehau/shared/forms';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { FieldsContainer } from '@rehau/shared/forms/components';
import { FrontendComponentEnum } from '@rehau/shared/enums';
import { NumberField } from '@rehau/shared/forms/elements';

import { getValues } from './reducer/slides.actions';
import { useSlides } from './hooks/useSlides';
import { UseSlidesOutput } from './types/useSlides.types';
import { SlideButton, SlidesType } from './slides.enums';
import { SlidesProps } from './slides.types';
import { SlidesValues } from './slide/slide.types';
import { StyledSlide, Wrapper } from './slides.styled';

export const Slides: React.FC<SlidesProps> = (props: SlidesProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    maxMeters,
    initSPosition,
    initMsPosition,
    initMlPosition,
    initLPosition,
    sLabel = t('rehau.components.slides.s_label'),
    mLabel = t('rehau.components.slides.m_label'),
    lLabel = t('rehau.components.slides.l_label'),
    component = new FieldsContainer(FrontendComponentEnum.Slides, '', []),
    setValues = (): void => undefined,
    saveMethod = (_: ObjectIteratorInterface): void => undefined,
  }: SlidesProps = props;
  const getPosition: (position: string) => fieldTypes = (position: string): fieldTypes => component.elements.find(
    (element: fieldTypes): boolean => element.name === position
  ) as fieldTypes;
  const getPositionValue: (position: string) => number = (position: string): number => getPosition(position)?.value as number;
  const getMaxMeters: () => number = (): number => (getPosition('houseSizeL') as NumberField).maxValue as number;

  const { handleDrag, handleDragStop, state, getBounds, handleSubmit }: UseSlidesOutput = useSlides({
    saveMethod,
    setValues,
    component,
    maxMeters: maxMeters ? maxMeters : getMaxMeters(),
    sPosition: initSPosition ? initSPosition : getPositionValue('houseSizeS'),
    smPosition: initMsPosition ? initMsPosition : getPositionValue('houseSizeSM'),
    mlPosition: initMlPosition ? initMlPosition : getPositionValue('houseSizeML'),
    lPosition: initLPosition ? initLPosition : getPositionValue('houseSizeL'),
  });
  const { s, m, l, mlFixed }: SlidesValues = getValues(
    state.sPosition, state.smPosition, state.mlPosition, state.lPosition, maxMeters ?? getMaxMeters()
  );

  return (
    <Wrapper>
      <StyledSlide
        right={state.smPosition}
        left={state.sPosition}
        onDrag={handleDrag}
        onDragStop={handleDragStop}
        leftId={SlideButton.S}
        rightId={SlideButton.Sm}
        bounds={getBounds(SlidesType.S)}
        label={sLabel || ''}
        value={s}
        onSubmit={handleSubmit}
        slideType={SlidesType.S}
        withFocusInLeftInput
      />
      <StyledSlide
        left={state.smPosition}
        right={state.mlPosition}
        onDrag={handleDrag}
        onDragStop={handleDragStop}
        leftId={SlideButton.Sm}
        rightId={SlideButton.Ml}
        bounds={getBounds(SlidesType.M)}
        label={mLabel || ''}
        value={m}
        onSubmit={handleSubmit}
        leftOnTop={mlFixed === maxMeters}
        slideType={SlidesType.M}
      />
      <StyledSlide
        left={state.mlPosition}
        right={state.lPosition}
        onDrag={handleDrag}
        onDragStop={handleDragStop}
        leftId={SlideButton.Ml}
        rightId={SlideButton.L}
        bounds={getBounds(SlidesType.L)}
        label={lLabel || ''}
        value={l}
        leftOnTop={mlFixed === maxMeters}
        onSubmit={handleSubmit}
        slideType={SlidesType.L}
      />
    </Wrapper>
  );
};
