import { TextField } from '@rehau/shared/forms/elements/fields';
import { StringValidation } from '@rehau/shared/forms/validations';
import { FormFieldTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { InfoBox, Tooltip } from '@rehau/shared/forms/elements';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';

export class ColorPickerField extends TextField {
  constructor(
    id: string,
    name: string,
    required: boolean,
    label: string | null,
    placeholder: string,
    informationBox?: InfoBox,
    tooltip?: Tooltip,
    validation?: StringValidation,
    countryConfigMapping: ObjectIteratorInterface<string> = {}
  ) {
    super(id, name, label, placeholder, required, validation, countryConfigMapping, informationBox, tooltip);
    this.type = FormFieldTypeEnum.ColorPickerField;
    this.frontendComponent = FrontendComponentEnum.ColorPickerField;
  }
}
