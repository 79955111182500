export enum CalculationStateEnum {
  Test = 'test',
  Empty = 'empty',
  InProgress = 'inProgress',
  Complete = 'complete',
  Rejected = 'rejected',
  ClosedCouldNotBeUpgraded = 'closedCouldNotBeUpgraded',
  ClosedFake = 'closedFake',
  ClosedInstallerNoProgress = 'closedInstallerNoProgress',
  ClosedConsumerNoProgress = 'closedConsumerNoProgress',
}
