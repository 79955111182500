import { useCountryConfig } from '../hooks/useCountryConfig.hook';
import { UseCountryConfig } from '../types/useCountryConfig.types';

export const useSupportEmailReplace: (text: string) => string = (text: string): string => {
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();

  if (text.includes('{{supportEmail}}')) {
    return text.replace(/{{supportEmail}}/g, isConfigAvailable ? config.variables.website['support_email'] as string : '');
  }

  return text;
};
