import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { IconName } from '@rehau/shared/enums';
import { Color } from '../../../../enums/color.enum';
import { useEventListener } from '../../../../hooks/useEventListener';
import { UseState } from '../../../../types/useState.types';

import { FixedScrollTopArrowProps } from './fixedScrollTopArrow.types';
import { ArrowContainer, Container, StyledIcon, Tooltip } from './fixedScrollTopArrow.styled';

export const FixedScrollTopArrow: React.FC<FixedScrollTopArrowProps> = (props: FixedScrollTopArrowProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [visible, setVisible]: UseState<boolean> = useState<boolean>(false);
  const { contentRef, tooltipContent }: FixedScrollTopArrowProps = props;
  const scrollToTop: () => void = (): void => {
    if (!!contentRef && !!contentRef.current) {
      contentRef.current.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }
  };

  useEventListener(
    'scroll',
    (): void => {
      const scrollPosition: number | undefined = contentRef?.current?.scrollTop;
      const screnHeight: number | undefined = contentRef?.current?.offsetHeight;

      if (screnHeight && scrollPosition) {
        if (screnHeight < scrollPosition + screnHeight - 200 && !visible) {
          setVisible(true);
        }

        if (screnHeight > scrollPosition + screnHeight - 200 && visible) {
          setVisible(false);
        }
      }
    },
    [contentRef?.current],
    contentRef?.current
  );

  return (
    <Container invisible={!visible}>
      {tooltipContent && <Tooltip>{tooltipContent}</Tooltip>}
      <ArrowContainer onClick={scrollToTop}>
        <StyledIcon name={IconName.ArrowBig} size={16} color={Color.Pink} />
      </ArrowContainer>
    </Container>
  );
};
