import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { FontWeight } from '../../enums/fontWeight.enum';
import { Color } from '../../enums/color.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { Icon } from '../icon/icon.component';
import { BoxShadow } from '../../enums/boxShadow.enum';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { styleWhenTrue } from '../../utils/style.utils';
import { BaseLabel } from '../../theme';

import { ContainerProps } from './flatBoxWithContextMenu.types';

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  margin-right: 10px;
`;

export const Container: StyledComponent<'div', ContainerProps> = styled.div`
  width: 100%;
  max-width: 580px;
  border: 1px solid ${Color.GreyStroke};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  min-height: 74px;
  box-shadow: ${BoxShadow.BtnGreyShadow};

  ${styleWhenTrue(
    prop('isActive'),
    css`
      border: 1px solid ${Color.Green};
    `
  )}

  ${styleWhenTrue(
    prop('readonly'),
    css`
      color: ${Color.Grey};
      background-color: ${Color.BgGrey};
      border: unset;
      box-shadow: unset;

      ${StyledIcon} {
        color: ${Color.Grey};
      }
    `
  )}
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
`;

export const Label: StyledComponent<typeof BaseLabel> = styled(BaseLabel)`
  margin-bottom: 10px;
`;

export const Title: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 22px;
  font-weight: ${FontWeight.Black};
  word-break: break-word;
`;

export const AdditionalTitle: StyledComponent<'span'> = styled.span`
  color: ${Color.Grey};
  font-weight: ${FontWeight.Regular};
  margin-left: 11px;

  @media ${Breakpoint.Mobile} {
    display: block;
    margin-left: 0;
  }
`;
