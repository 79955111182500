import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { MultiSelectItem } from '../../../components/select/multiSelectItem/multiSelectItem.component';
import { SelectItemField } from '../../../../../../../shared/forms/elements';
import { FieldElement, FieldElementProps } from '../types';

export const SelectItemFieldElement: FieldElement<SelectItemField> = (props: FieldElementProps<SelectItemField>): JSX.Element => {
  const { component, save, setValues }: FieldElementProps<SelectItemField> = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <MultiSelectItem
      label={t(component.label || '')}
      price={component.price}
      tooltip={component.tooltip}
      component={component}
      handleSave={save}
      setValues={setValues}
    />
  );
};
