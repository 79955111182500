import React, { useCallback } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { DecisionWithChildrenContainer } from '../../../../../../../shared/forms/components';
import { DecisionField } from '../../../../../../../shared/forms/elements';
import { componentTypes, fieldTypes, staticElementTypes } from '../../../../../../../shared/forms/types';
import { Decision } from '../../decision/decision.component';
import { FieldComponent, FieldComponentWrapper } from '../fieldComponent.component';
import { FieldElement, FieldElementProps } from '../types';
import { getFieldContainerType } from '../utils/getFieldContainerType';

export const DecisionWithChildrenFieldElement: FieldElement<DecisionWithChildrenContainer> = (
  props: FieldElementProps<DecisionWithChildrenContainer>
): JSX.Element => {
  const { component, saveMethod, setValues }: FieldElementProps<DecisionWithChildrenContainer> = props;
  const { t }: TransProps<never> = useTranslation();

  const decisionComponent: DecisionField = component.getDecisionField() as DecisionField;
  const decisionChildren: (fieldTypes | componentTypes | staticElementTypes)[] = component.getChildrenContainer();

  const handleSave: () => void = useCallback(
    (): void => {
      if (!!saveMethod && !!component.getDecisionField()) {
        saveMethod({ [component.getDecisionField()!.id]: component.getDecisionField()?.value || false });
      }
    },
    [saveMethod]
  );

  return (
    <Decision
      acceptText={t(decisionComponent.labelTrue)}
      declineText={t(decisionComponent.labelFalse)}
      label={t(decisionComponent.label || '')}
      component={component}
      handleSave={handleSave}
      setValues={setValues}
      disabled={decisionComponent.onlyForRead || decisionComponent.disabled}
    >
      {decisionChildren.map((innerSimpleComponent: fieldTypes | componentTypes | staticElementTypes, index: number): JSX.Element =>
        <FieldComponentWrapper className={getFieldContainerType(innerSimpleComponent)}  key={`${index}`}>
          <FieldComponent innerComponent={innerSimpleComponent} saveMethod={saveMethod} setValues={setValues} />
        </FieldComponentWrapper>
      )}
    </Decision>
  );
};
