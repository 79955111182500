import { WebsiteComponentEnum, WebsitePathNameEnum } from '../../../../../../shared/enums';
import { RouteInfo } from '../../../../../../shared/models/countryConfig';

export const routesArrayMock: RouteInfo[] = [
  {
    name: WebsitePathNameEnum.Calculator,
    url: '/calculator/:hash',
    component: WebsiteComponentEnum.Calculator,
    title: '',
  },
  {
    name: WebsitePathNameEnum.Offers,
    url: '/installer-zone',
    component: WebsiteComponentEnum.Setup,
    title: 'rehau.setup.header_menu.offers',
  },
  {
    name: WebsitePathNameEnum.Configurator,
    url: '/installer-zone/configurator',
    component: WebsiteComponentEnum.Setup,
    title: 'rehau.setup.header_menu.configurator',
  },
  {
    name: WebsitePathNameEnum.Profile,
    url: '/installer-zone/profile',
    component: WebsiteComponentEnum.Setup,
    title: 'rehau.setup.header_menu.profile',
  },
  {
    name: WebsitePathNameEnum.AdminDefault,
    url: '/panel',
    component: WebsiteComponentEnum.Management,
    title: '',
  },
  {
    name: WebsitePathNameEnum.SignIn,
    url: '/login',
    component: WebsiteComponentEnum.Setup,
    title: '',
  },
  {
    name: WebsitePathNameEnum.Default,
    url: '*',
    component: WebsiteComponentEnum.Calculator,
    title: '',
  },
  {
    name: WebsitePathNameEnum.ReSource,
    url: '/resource',
    component: WebsiteComponentEnum.Website,
    title: '',
  },
  {
    name: WebsitePathNameEnum.ReGuard,
    url: '/reguard',
    component: WebsiteComponentEnum.Website,
    title: '',
  },
];
