import React from 'react';

import { FieldElement, FieldElementProps } from '../types';
import { Slides } from '../../slides/slides.component';
import { FieldsContainer } from '../../../../../../../shared/forms/components';

export const SlidesFieldElement: FieldElement<FieldsContainer> = (props: FieldElementProps<FieldsContainer>): JSX.Element => {
  const { component, saveMethod, setValues }: FieldElementProps<FieldsContainer> = props;

  return (
    <Slides
      component={component}
      saveMethod={saveMethod}
      setValues={setValues}
    />
  );
};
