export enum CountryLocaleEnum {
  bg_BG = 'bg_BG',
  cs_CZ = 'cs_CZ',
  de_AT = 'de_AT',
  de_DE = 'de_DE',
  el_GR = 'el_GR',
  en_US = 'en_US',
  hu_HU = 'hu_HU',
  pl_PL = 'pl_PL',
  ro_RO = 'ro_RO',
  sk_SK = 'sk_SK',
}
