import React, { useContext } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { Config } from '../../../../../../../shared/models/countryConfig';
import { InputController } from '../../input/inputController.component';
import { CostField } from '../../../../../../../shared/forms/elements';
import { PriceUnitInput } from '../../input/priceUnitInput/priceUnitInput.component';
import { CountryConfigContext } from '../../../contexts/countryConfig.context';
import { FieldElement, FieldElementProps } from '../types';
import { CurrencyPositionEnum, DistanceUnitEnum, FrontendComponentEnum } from '../../../../../../../shared/enums';

export const CostFieldElement: FieldElement<CostField> = (props: FieldElementProps<CostField>): JSX.Element => {
  const { component, save, setValues }: FieldElementProps<CostField> = props;
  const { t }: TransProps<never> = useTranslation();
  const config: Config = useContext(CountryConfigContext);
  const regex: RegExp = new RegExp(/^-?\d*\.?\d*$/);

  return (
    <InputController
      label={t(component.label || '')}
      additionalInfoContent={t(component.informationBox?.content || '')}
      additionalInfoContentInReadMore={t(component.informationBox?.readMoreContent || '')}
      tooltipProps={component.tooltip}
      disabled={component.onlyForRead || component.disabled}
      handleSave={save}
      component={component}
      setValues={setValues}
      regex={regex}
    >
      <PriceUnitInput
        currencyPosition={config.currency.position}
        currencyText={config.currency?.symbol}
        areaUnit={component.frontendComponent === FrontendComponentEnum.CostAreaField ? DistanceUnitEnum.M : undefined}
        placeholder={t(component.placeholder)}
        unitWithSlash={component.unitHasSlash}
      />
    </InputController>
  );
};
