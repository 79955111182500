import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { RatingStatic } from '../../../../../../../shared/forms/elements';
import { FieldElement, FieldElementProps } from '../types';
import { LowBarWithRating } from '../../lowBarWithRating/lowBarWithRating.component';

export const RatingStaticElement: FieldElement<RatingStatic> = (props: FieldElementProps<RatingStatic>): JSX.Element => {
  const { component }: FieldElementProps<RatingStatic> = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <LowBarWithRating rate={component.amount} label={t(component.label)} />
  );
};
