import React from 'react';

import { IconName } from '@rehau/shared/enums';
import { Color } from '../../enums/color.enum';

import { AdvantageElementProps } from './advantageElement.types';
import { Content, ContentContainer, StyledIcon, Wrapper } from './advantageElement.styled';

export const AdvantageElement: React.FC<AdvantageElementProps> = (props: AdvantageElementProps): JSX.Element => {
  const { content }: AdvantageElementProps = props;

  return (
    <Wrapper>
      <ContentContainer>
        <StyledIcon name={IconName.CheckBenefit} color={Color.Green} size={40} />
        <Content text={content}>
          {content}
        </Content>
      </ContentContainer>
    </Wrapper>
  );
};
