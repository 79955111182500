import React, { RefObject, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { IconName } from '../../../../../../../../shared/enums';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';
import { UseState } from '../../../../types/useState.types';

import {
  Container,
  CustomButtonTab,
  CustomOptionTab,
  Dropdown,
  Icon,
  IconWrapper,
  List,
  SelectedOption,
  Wrapper,
} from './tabs.styled';
import { OptionTab, TabsProps } from './tabs.types';
import { tabOffset } from './tabs.constants';

export const Tabs: React.FC<TabsProps> = (props: TabsProps): JSX.Element => {
  const { className, options, activeId, isOnTop, setOnTop, handleScroll }: TabsProps = props;
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const containerRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [dropdownOpen, setDropdownOpen]: UseState<boolean> = useState<boolean>(false);
  const selectedOption: OptionTab | undefined = options.find(((option: OptionTab): boolean => option.id === activeId));

  const toggleDropdown: () => void = (): void => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside: () => void = (): void => {
    setDropdownOpen(false);
  };

  useOnClickOutside([ref], handleClickOutside);

  return (
    <Container className={className} data-testid='option-tabs' ref={containerRef}>
      <Wrapper>
        {isTablet ? (
          <Dropdown ref={ref}>
            <SelectedOption onClick={toggleDropdown} open={dropdownOpen}>
              {!!selectedOption ? selectedOption.label : options[0].label}
              <IconWrapper>
                <Icon
                  name={IconName.ArrowBig}
                  size={16}
                  open={dropdownOpen}
                />
              </IconWrapper>
            </SelectedOption>
            {dropdownOpen && (
              <List>
                {options.map((option: OptionTab): JSX.Element => (
                  <CustomOptionTab
                    active={(option.id === activeId).toString()}
                    data-testid='option-tab'
                    key={option.id}
                    onClick={(): void => handleScroll(option.id)}
                  >
                    {option.label}
                  </ CustomOptionTab>
                ))}
              </List>
            )}
          </Dropdown>
        ) : (
          options.map((option: OptionTab): JSX.Element => (
            <CustomButtonTab
              active={(option.id === activeId).toString()}
              data-testid='option-tab'
              key={option.id}
              onClick={(): void => handleScroll(option.id)}
            >
              {option.label}
            </ CustomButtonTab>
          ))
        )}
      </Wrapper>
    </Container>
  );
};
