import { HeatingAndCoolingTypeEnum, HeatInstallationTypeEnum, IconName } from '@rehau/shared/enums';
import { RangeValueModel } from '@rehau/shared/models/responses/api/common';

export class HeatInstallationModel {
  public code: HeatingAndCoolingTypeEnum;
  public type: HeatInstallationTypeEnum;
  public label: string;
  public tooltip: string;
  public efficiency: RangeValueModel;
  public icon: IconName;
  public price?: number;

  constructor(
    code: HeatingAndCoolingTypeEnum,
    type: HeatInstallationTypeEnum,
    label: string,
    tooltip: string,
    efficiency: RangeValueModel,
    icon: IconName,
    price?: number
  ) {
    this.code = code;
    this.type = type;
    this.label = label;
    this.tooltip = tooltip;
    this.efficiency = efficiency;
    this.icon = icon;
    this.price = price;
  }
}
