import { RefObject, useRef, useState } from 'react';

import { useEffectAfterMount } from '../../../../../hooks/useEffectAfterMount';
import { UseState } from '../../../../../types/useState.types';
import { UseVideoPlayerOutput } from '../videoPlayer.types';

export const useVideoPlayer: () => UseVideoPlayerOutput = (): UseVideoPlayerOutput => {
  const [isPaused, setIsPaused]: UseState<boolean> = useState<boolean>(true);
  const [isOpen, setIsOpen]: UseState<boolean> = useState<boolean>(false);
  const ref: RefObject<HTMLVideoElement> = useRef(null);
  const defaultMusicVolumeLevel: number = 0.5;

  const toggleIsPaused: () => void = (): void => {
    if (isPaused) {
      ref.current?.play();
    } else {
      ref.current?.pause();
    }

    setIsPaused(!isPaused);
  };

  const playVideo: () => void = (): void => {
    if (isPaused) {
      ref.current?.play();
      setIsPaused(!isPaused);
    }
  };

  const pauseVideo: () => void = (): void => {
    if (!isPaused) {
      ref.current?.pause();
      setIsPaused(!isPaused);
    }
  };

  const toggleFullscreen: () => void = (): void => {
    ref.current?.requestFullscreen();
  };

  const openVideo: () => void = (): void => {
    setIsOpen(true);
  };

  const closeVideo: () => void = (): void => {
    setIsOpen(false);
    pauseVideo();
    if (ref.current) {
      ref.current.currentTime = 0;
    }
  };

  useEffectAfterMount(
    (): void => {
      if (ref.current) {
        ref.current.volume = defaultMusicVolumeLevel;
      }
      if (isOpen) {
        ref.current?.play();
        setIsPaused(false);
      } else {
        setIsPaused(true);
      }
    },
    [isOpen]
  );

  return {
    ref,
    toggleFullscreen,
    toggleIsPaused,
    playVideo,
    pauseVideo,
    openVideo,
    closeVideo,
    isOpen,
    isPaused,
  };
};
