import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { MenuGroup, PathNameMenuPositionTuple, RouteInfo } from '@rehau/shared/models';
import { IconName, WebsitePathNameEnum } from '@rehau/shared/enums';
import { useRoutesInfo } from '../../../hooks/useRoutesInfo.hook';
import { findPathByName } from '../../../utils/findPathByName.utils';
import { sortItems } from '../../../utils/menuPositions/menuPositions.utils';

import { MobileMenuItemProps } from './mobileMenuItem.types';
import {
  Button,
  Container,
  Dot,
  Icon,
  ItemLabel,
  ItemLink,
  StyledIcon,
  Title,
  TitleLink,
} from './mobileMenuItem.styled';
import { sortGroups } from './mobileMenuItem.utils';

export const MobileMenuItem: React.FC<MobileMenuItemProps> = (props: MobileMenuItemProps): JSX.Element => {
  const {
    className,
    label,
    path,
    groups,
    onClick,
    action,
  }: MobileMenuItemProps = props;
  const { t }: TransProps<never> = useTranslation();
  const routesArray: RouteInfo[] = useRoutesInfo();
  const pathNames: WebsitePathNameEnum[] =  routesArray.map((route: RouteInfo): WebsitePathNameEnum => route.name);
  const sortedGroups: MenuGroup[] | null = !!groups ? Object.values(groups as MenuGroup).sort(sortGroups) : null;
  const items: PathNameMenuPositionTuple[][] | null = !!sortedGroups
    ? sortedGroups.map((group: MenuGroup): PathNameMenuPositionTuple[] => Object.entries(group.children).sort(sortItems))
    : null;
  const parsedItems: PathNameMenuPositionTuple[] | undefined = items?.reduce(
    (newItems: PathNameMenuPositionTuple[], item: PathNameMenuPositionTuple[]): PathNameMenuPositionTuple[] => {
      return [...newItems, ...item];
    });

  return (
    <Container data-testid='item' className={className}>
      {!!items ? (
        <>
          <Title>{label}</Title>
          {parsedItems?.map(([name, data]: PathNameMenuPositionTuple): JSX.Element | false => (
            !!data && (
              <ItemLink
                key={data.title}
                to={findPathByName(name, routesArray)}
                pathName={name}
                onClick={onClick}
                data-testid='mobile-item-link'
              >
                <Dot data-testid='dot' />
                <ItemLabel>{t(data.title)}</ItemLabel>
                <Icon name={IconName.ArrowBig} size={13} />
              </ItemLink>
            )
          ))}
        </>
      ) : (
        pathNames.includes(path as WebsitePathNameEnum) ? (
          <TitleLink to={findPathByName(path, routesArray)} onClick={onClick} pathName={path} >
            <Title>{label}</Title>
            <Icon name={IconName.ArrowBig} size={13} />
          </TitleLink>
        ) : (
          <Button key={label} onClick={action}>
            {label}
            <StyledIcon name={IconName.ArrowBig} size={13} />
          </Button>
        )
      )}
    </Container>
  );
};
