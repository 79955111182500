import styled from 'styled-components';
import { prop } from 'ramda';

import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { InnerContainer, OuterContainer } from '../../../../theme/containers.styled';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { styleIfElse } from '../../../../utils/style.utils';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { Markdown } from '../../../markdown/markdown.component';

export const Container: StyledComponent<typeof OuterContainer> = styled(OuterContainer)`
  padding-top: 100px;
  padding-bottom: 100px;

  @media ${Breakpoint.Tablet} {
    margin: 0;
    width: 100%;
    padding: 60px 40px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 60px 20px ${styleIfElse(prop('reversed'), '60px', '0')};
  }
`;

export const Wrapper: StyledComponent<typeof InnerContainer> = styled(InnerContainer)`
  flex-direction: column;
`;

export const Image: StyledComponent<'img'> = styled.img`
  width: 100%;
`;

export const Columns: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 25px -12.5px 0;

  @media ${Breakpoint.Tablet} {
    flex-wrap: wrap;
    margin: 25px -20px 0;
  }

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    margin: 25px 0 0;
  }
`;

export const Column: StyledComponent<'div'> = styled.div`
  flex: 1;
  margin: 0 12.5px;

  @media ${Breakpoint.Tablet} {
    min-width: calc(50% - 40px);
    width: calc(50% - 40px);
    margin: 0 20px 25px;

    :last-of-type {
      margin: 0 20px;
    }
  }

  @media ${Breakpoint.Mobile} {
    margin: 0 0 25px;
    width: 100%;

    :last-of-type {
      margin: 0 0 60px;
    }
  }
`;

export const ColumnImage: StyledComponent<'img'> = styled.img`
  width: 100%;
  margin-bottom: 25px;
`;

export const ColumnTitle: StyledComponent<'h6'> = styled.h6`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
  font-weight: ${FontWeight.Bold};
`;

export const StyledMarkdown: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;
`;
