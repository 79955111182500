import styled, { StyledComponent } from 'styled-components';

import { Color } from '../../../../enums/color.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { CustomLink } from '../../../customLink/customLink.component';

export const Container: StyledComponent<'div', {}, {}, never> = styled.div``;

export const Title: StyledComponent<'h6', {}, {}, never> = styled.h6`
  font-size: 24px;
  line-height: 29px;
  font-weight: ${FontWeight.Black};
  color: ${Color.Black};
  margin-bottom: 20px;
`;

export const LinkLabel: StyledComponent<'p', {}, {}, never> = styled.p`
  font-size: 18px;
  line-height: 21px;
  margin-left: 10px;
  color: ${Color.Black};
`;

export const ItemLink: StyledComponent<typeof CustomLink, {}, {}, never> = styled(CustomLink)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid ${Color.GreyStroke};
  border-left: 0;
  border-right: 0;
  padding: 12px;
  text-decoration: none;
  & + & {
    border-top: none;
  }
  :hover {
    ${LinkLabel} {
      color: ${Color.Green};
    }
  }
`;
