import styled from 'styled-components';

import { BoxShadow } from '../../enums/boxShadow.enum';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { GridWidth } from '../../enums/gridWidth.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { Icon } from '../icon/icon.component';
import { ToggleSwitch } from '../toggleSwitch/toggleSwitch.component';

export const Container: StyledComponent<'div'> = styled.div`
  padding: 5px 0;
  background-color: ${Color.White};
  width: 100%;
  box-shadow: ${BoxShadow.BtnGreyShadow};
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: ${GridWidth.Desktop};
  margin: 0 auto;
  padding: 9px 0;
  @media ${Breakpoint.Additional1280} {
    padding: 9px 80px;
  }
  @media ${Breakpoint.Tablet} {
    padding: 9px 40px;
  }
  @media ${Breakpoint.Mobile} {
    padding: 9px 20px;
    flex-direction: column;
  }
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  transition: 0.3s;
`;

export const Hamburger: StyledComponent<'button'> = styled.button`
  width: 40px;
  height: 40px;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  background-color: ${Color.White};
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  :hover {
    ${StyledIcon} {
      color: ${Color.Green};
    }
  }
`;

export const Counter: StyledComponent<'div'> = styled.div`
  position: absolute;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background-color: ${Color.Pink};
  font-weight: ${FontWeight.Black};
  font-size: 10px;
  line-height: 12px;
  color: ${Color.White};
  right: -5px;
  top: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const Title: StyledComponent<'div'> = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: ${Color.Black};
  margin-left: 15px;
`;

export const StyledToggleSwitch: StyledComponent<typeof ToggleSwitch> = styled(ToggleSwitch)`
  margin-right: 20px;
`;

export const ControlsPanel: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;

  @media ${Breakpoint.Mobile} {
    margin-top: 20px;
    width: 100%;
    justify-content: flex-end;
  }
`;
