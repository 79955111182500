import React, { RefObject } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { MobileBackground, MobileInfoBox } from '../../home.styled';
import { AppConfigType, Button, findPathByName, useRoutesInfo } from '@rehau-fe/ui/src';
import { useRedirect } from '@rehau-fe/ui/src/hooks/useRedirect';
import { UseRedirectOutput } from '@rehau-fe/ui/src/types/useRedirect.types';
import { RouteInfo } from '../../../../../../../../shared/models/countryConfig';
import { CalculationTypeEnum, ExternalPathNameEnum, WebsitePathNameEnum } from '../../../../../../../../shared/enums';
import { SectionHeader } from '../sectionHeader/sectionHeader.component';
import { useAppConfig } from '@rehau-fe/ui/src/hooks/useAppConfig.hook';

import {
  ContentWrapper,
  Img,
  InfoBox,
  InfoBoxDescription,
  InfoBoxTitle,
  LeftPhoto,
  LeftPhotoInnerWrapper,
  PhotoText,
  RightPhoto,
  SectionHeaderContainer,
  StyledPhotoOverlay,
  Wrapper,
} from './specialProducts.styled';
import reguard from './img/reguard.png';
import reguardPlug from './img/reguardPlug.png';
import resource from './img/resource.png';
import { SpecialProductsProps } from './specialProducts.types';

export const SpecialProducts: React.FC<SpecialProductsProps> = (props: SpecialProductsProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    reguardActive,
    resourceActive,
    title = t('rehau.website.home_view.special_products.title'),
    reguardTitle = t('rehau.website.home_view.special_products.reguard_title'),
    reguardDescription = t('rehau.website.home_view.special_products.reguard_description'),
    resourceTitle = t('rehau.website.home_view.special_products.resource_title'),
    resourceDescription = t('rehau.website.home_view.special_products.resource_description'),
    buttonLabel = t('rehau.website.home_view.special_products.button_label'),
  }: SpecialProductsProps = props;
  const handleRedirect: UseRedirectOutput = useRedirect();
  const routesArray: RouteInfo[] = useRoutesInfo();
  const appConfig: AppConfigType = useAppConfig();

  const goToReguard: () => void = (): void => {
    handleRedirect(findPathByName(ExternalPathNameEnum.ReguardProduct, routesArray), undefined, true);
  };

  const goToResource: () => void = (): void => {
    handleRedirect(findPathByName(WebsitePathNameEnum.ReSource, routesArray), WebsitePathNameEnum.ReSource);
  };

  return (
    <Wrapper>
      <SectionHeaderContainer>
        <SectionHeader>{title}</SectionHeader>
      </SectionHeaderContainer>
      <ContentWrapper>
        {!!reguardActive && (
          <>
            <LeftPhoto url={reguardPlug} fullWidth={!resourceActive} data-testid='reguard-photo'>
              {!!resourceActive ? (
                <LeftPhotoInnerWrapper>
                  <Img src={reguard} />
                  <PhotoText>{reguardTitle}</PhotoText>
                  <InfoBox>
                    <InfoBoxTitle>{reguardTitle}</InfoBoxTitle>
                    <InfoBoxDescription>{reguardDescription}</InfoBoxDescription>
                    <Button
                      text={buttonLabel || ''}
                      onClick={goToReguard}
                    />
                  </InfoBox>
                </LeftPhotoInnerWrapper>
              ) : (
                <StyledPhotoOverlay>
                  <PhotoText>{reguardTitle}</PhotoText>
                  <InfoBox>
                    <InfoBoxTitle>{reguardTitle}</InfoBoxTitle>
                    <InfoBoxDescription>{reguardDescription}</InfoBoxDescription>
                    <Button text={buttonLabel || ''} onClick={goToReguard} />
                  </InfoBox>
                </StyledPhotoOverlay>
              )}
            </LeftPhoto>
            <MobileBackground>
              <MobileInfoBox>
                <InfoBoxTitle>{reguardTitle}</InfoBoxTitle>
                <Button text={buttonLabel || ''} onClick={goToReguard} />
              </MobileInfoBox>
            </MobileBackground>
          </>
        )}

        {!!resourceActive && (
          <>
            <RightPhoto url={resource} fullWidth={!reguardActive} data-testid='resource-photo'>
              <StyledPhotoOverlay>
                <PhotoText>{resourceTitle}</PhotoText>
                <InfoBox>
                  <InfoBoxTitle>{resourceTitle}</InfoBoxTitle>
                  <InfoBoxDescription>{resourceDescription}</InfoBoxDescription>
                  <Button
                    text={buttonLabel || ''}
                    onClick={goToResource}
                  />
                </InfoBox>
              </StyledPhotoOverlay>
            </RightPhoto>
            <MobileBackground>
              <MobileInfoBox>
                <InfoBoxTitle>{resourceTitle}</InfoBoxTitle>
                <Button text={buttonLabel || ''} onClick={goToResource} />
              </MobileInfoBox>
            </MobileBackground>
          </>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};
