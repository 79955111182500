import React from 'react';

import { UseTimelineAnimationOutput } from '../../../../types/useTimelineAnimation.types';
import { useTimelineAnimation } from '../../../../hooks/useTimelineAnimation';
import { assignColumnRef } from '../../utils/columnRef.utils';
import { Title } from '../title/title.component';
import { useProductPopupContext } from '../../../../hooks/useProductPopupContext.hook';
import { ProductPopupContextType } from '../../../../types/productPopupContextType.types';
import { PhotoWithModal } from '../photoWithModal/photoWithModal.component';

import {
  Column,
  ColumnImage,
  Columns,
  ColumnTitle,
  Container,
  Image,
  StyledMarkdown,
  Wrapper,
} from './imageWithColumns.styled';
import { ColumnData, ImageWithColumnsProps } from './imageWithColumns.types';

export const ImageWithColumns: React.FC<ImageWithColumnsProps> = (props: ImageWithColumnsProps): JSX.Element => {
  const { className, title, imageSrc, columnsData }: ImageWithColumnsProps = props;
  const { isInPopup }: ProductPopupContextType = useProductPopupContext();
  const timelineAnimation: UseTimelineAnimationOutput = useTimelineAnimation(isInPopup);

  return (
    <Container className={className} data-testid='image-with-columns-section'>
      <Wrapper>
        {!!title && <Title data-testid='image-with-columns-title'>{title}</Title>}
        <PhotoWithModal imageSrc={imageSrc} />
        <Columns>
          {columnsData.map((column: ColumnData, index: number): JSX.Element => {
            const columnRef: React.RefObject<HTMLDivElement> = assignColumnRef(index, timelineAnimation);

            return (
              <Column key={column.title} ref={columnRef} data-testid='image-with-columns-section-column'>
                <ColumnImage src={column.imageSrc} alt='' />
                <ColumnTitle>{column.title}</ColumnTitle>
                <StyledMarkdown text={column.text} tagType='p' />
              </Column>
            );
          })}
        </Columns>
      </Wrapper>
    </Container>
  );
};
