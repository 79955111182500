import { NumberField } from '@rehau/shared/forms/elements/fields';
import { CostValidation } from '@rehau/shared/forms/validations';
import { FormFieldTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { InfoBox, Tooltip } from '@rehau/shared/forms/elements';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';

export class CostField extends NumberField {
  public unitHasSlash: boolean;

  constructor(
      id: string,
      name: string,
      label: string | null,
      required: boolean,
      validation: CostValidation = new CostValidation(),
      countryConfigMapping: ObjectIteratorInterface<string> = {},
      placeholder: string = '0',
      informationBox?: InfoBox,
      tooltip?: Tooltip,
      unitHasSlash: boolean = false
  ) {
    super(id, name, label, placeholder, required, validation, countryConfigMapping, informationBox, tooltip);
    this.type = FormFieldTypeEnum.CostField;
    this.frontendComponent = FrontendComponentEnum.CostField;
    this.unitHasSlash = unitHasSlash;
  }
}
