import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { GridWidth } from '../../enums/gridWidth.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';

import { ColumnHeaderProps, ColumnLabelProps, TdProps, ThProps, TrProps } from './productTable.types';

export const LoaderContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper: StyledComponent<'div'> = styled.div``;

export const TableBody: StyledComponent<'tbody'> = styled.tbody``;
export const TableHead: StyledComponent<'thead'> = styled.thead``;

export const Table: StyledComponent<'table'> = styled.table`
  border-spacing: 0;
  border: 1px solid ${Color.GreyStroke};
  border-top: none;
  border-left: none;
  width: 100%;
  max-width: ${GridWidth.Desktop};
`;

export const Th: StyledComponent<'th', ThProps> = styled.th`
  border-bottom: 1px solid ${Color.GreyStroke};
  border-right: 1px solid ${Color.GreyStroke};
  border-top: 1px solid ${Color.GreyStroke};
  height: 130px;
  box-sizing: border-box;
  padding-top: 0;
  width: ${({ columnsQuantity }: ThProps): string => `calc(50% / ${columnsQuantity})`};

  :first-child {
    width: 50%;
    text-align: left;
    border-top: none;
  }

  :last-child {
    border-right: 0;
  }

  @media ${Breakpoint.Mobile} {
    width: 60px;
    min-width: 60px;

    :first-child {
      width: 100%;
    }
  }
`;

export const Tr: StyledComponent<'tr', TrProps> = styled.tr`
  :last-child {
    td {
      border-bottom: 0;
    }
  }

  ${styleWhenTrue(
    prop('isFirst'),
    css`
      border-bottom: 2px solid ${Color.Black};

      & ${Th} {
        vertical-align: bottom;
      }
    `
  )}
`;

export const Td: StyledComponent<'td', TdProps> = styled.td`
  margin: 0;
  padding: ${({ videoContainer }: TdProps): string => videoContainer ? '11px 0' : '15px 0' };
  border-bottom: 1px solid ${Color.GreyStroke};
  border-right: 1px solid ${Color.GreyStroke};
  width: 60px;
  vertical-align: middle;

  :last-child {
    border-right: 0;
  }
`;

export const ColumnLabel: StyledComponent<'p', ColumnLabelProps> = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.8px;
  margin-top: 5px;
  width: 100%;
  word-break: break-word;
  text-align: ${({ isHeader }: ColumnLabelProps): string => isHeader ? 'start' : 'center'};

  @media ${Breakpoint.Mobile} {
    :not(:first-child) {
      width: 100%;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
    }
  }
`;

export const ColumnHeader: StyledComponent<'div', ColumnHeaderProps> = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;

  @media ${Breakpoint.Mobile} {
    flex-direction: column-reverse;
    justify-content: flex-start;
    padding: 10px 0;
  }

  ${styleWhenTrue(
    prop('isFirst'),
    css`
      padding-bottom: 25px;
      height: unset;

      & ${ColumnLabel} {
        font-size: 28px;
        line-height: 32px;
        text-transform: none;
      }
    `
  )}
`;

export const RowLabel: StyledComponent<'div'> = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: ${Color.Grey};
  word-break: break-word;
`;

export const ContentContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: center;
`;

export const AnnotationsContainer: StyledComponent<'div'> = styled.div`
  margin-top: 20px;
`;

export const AnnotationText: StyledComponent<'p'> = styled.p`
  font-size: 15px;
  line-height: 24px;
  margin-top: 5px;
  word-break: break-word;
`;

export const AnnotationIndex: StyledComponent<'sup'> = styled.sup`
  top: -0.5em;
  font-size: 60%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  margin-left: 3px;

  :first-child {
    margin-left: 5px;
  }
`;
