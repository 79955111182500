import React from 'react';

import { UseImageAnimationOutput } from '../../../../types/useImageAnimation.types';
import { useImageAnimation } from '../../../../hooks/useImageAnimation';
import { useProductPopupContext } from '../../../../hooks/useProductPopupContext.hook';
import { ProductPopupContextType } from '../../../../types/productPopupContextType.types';

import {
  Container,
  Content,
  Counter,
  Image,
  ImageContainer,
  Pointer,
  PointerImage,
  Pointers,
  StyledMarkdown,
  Title,
  Wrapper,
} from './imageDescription.styled';
import { ImageDescriptionProps, ImagePointer } from './imageDescription.types';

export const ImageDescription: React.FC<ImageDescriptionProps> = (props: ImageDescriptionProps): JSX.Element => {
  const { className, title, imageSrc, pointersData }: ImageDescriptionProps = props;
  const { isInPopup }: ProductPopupContextType = useProductPopupContext();
  const { firstImageRef }: UseImageAnimationOutput = useImageAnimation(isInPopup);

  return (
    <Container className={className}>
      <Wrapper>
        <Title>{title}</Title>
        <Content>
          <ImageContainer>
            <Image src={imageSrc} />
          </ImageContainer>
          <Pointers ref={firstImageRef}>
            {pointersData.map((pointer: ImagePointer, index: number): JSX.Element => (
              <Pointer key={index} data-testid='image-description-pointer'>
                <Counter>{index + 1}.</Counter>
                {!!pointer.imageSrc && <PointerImage src={pointer.imageSrc} />}
                {!!pointer.text && (
                  <StyledMarkdown
                    text={pointer.text}
                    withoutImage={!pointer.imageSrc}
                  />
                )}
              </Pointer>
            ))}
          </Pointers>
        </Content>
      </Wrapper>
    </Container>
  );
};
