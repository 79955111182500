import React from 'react';

import { IconName } from '../../../../../../../../shared/enums';

import { ButtonContainer, LocalIcon, Text } from './decisionButton.styled';
import { ButtonProps } from './decisionButton.types';
import { DecisionButtonType } from './decisionButton.enums';

export const DecisionButton: React.FC<ButtonProps> = (
  props: ButtonProps
): JSX.Element => {
  const {
    isUntouched,
    isActive,
    onClick,
    text,
    disabled,
    buttonType = DecisionButtonType.Accept,
  }: ButtonProps = props;

  return (
    <ButtonContainer
      isUntouched={isUntouched}
      onClick={onClick}
      isActive={isActive}
      buttonType={buttonType}
      disabled={disabled}
    >
      <Text isUntouched={isUntouched} isActive={isActive}>
        {text}
      </Text>
      <LocalIcon
        name={buttonType === DecisionButtonType.Accept ? IconName.Yes : IconName.No}
        size={13}
        isUntouched={isUntouched}
        isActive={isActive}
      />
    </ButtonContainer>
  );
};
