import styled from 'styled-components';

import { CurrencyPositionEnum } from '../../../../../../shared/enums';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { StyledComponent } from '../../types/styledComponent.types';

import { CurrencyProps, PricePrefixProps, PriceTextProps } from './price.types';

export const PriceContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media ${Breakpoint.Additional480} {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const PricePrefix: StyledComponent<'p', PricePrefixProps> = styled.p`
  line-height: 19px;
  color: ${Color.Grey};
  text-align: right;
  min-width: fit-content;
  font-size: ${({ pricePrefixSize }: PricePrefixProps): string => (pricePrefixSize ? `${pricePrefixSize}px` : '16px')};

  @media ${Breakpoint.Additional480} {
    font-size: 13px;
    line-height: 16px;
  }
`;

export const PriceText: StyledComponent<'p', PriceTextProps> = styled.p`
  font-weight: ${FontWeight.Bold};
  line-height: 24px;
  color: ${({ $color }: PriceTextProps): string => $color};
  white-space: nowrap;
  font-variant-numeric: tabular-nums;
  font-size: ${({ priceSize }: PriceTextProps): string => (priceSize ? `${priceSize}px` : '20px')};

  ${PricePrefix} + & {
    margin-left: 10px;
  }

  @media ${Breakpoint.Additional480} {
    line-height: 22px;
    margin-left: 0;
  }
`;

export const Currency: StyledComponent<'span', CurrencyProps> = styled.span`
  color: ${({ $color }: CurrencyProps): string => $color};
  ${({ position }: CurrencyProps): string => (position === CurrencyPositionEnum.Right ? 'margin-left: 3px' : 'margin-right: 3px')}
`;
