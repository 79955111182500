import React from 'react';

import { Color } from '../../enums/color.enum';
import { IconName } from '../../../../../../shared/enums';

import { ButtonWithIconProps } from './buttonWithIcon.types';
import { Container, Icon, Text } from './buttonWithIcon.styled';

export const ButtonWithIcon: React.FC<ButtonWithIconProps> = (props: ButtonWithIconProps): JSX.Element => {
  const {
    className,
    text,
    icon = IconName.ArrowBig,
    onClick,
    reversed,
    name,
    tabIndex,
  }: ButtonWithIconProps = props;

  return (
    <Container data-testid='button' className={className} onClick={onClick} aria-label={name} tabIndex={tabIndex}>
      {reversed && (<Icon name={icon} size={16} color={Color.Grey} />)}
      <Text data-testid='text'>{text}</Text>
      {!reversed && (<Icon name={icon} size={16} color={Color.Grey} />)}
    </Container>
  );
};
