export enum ExternalPathNameEnum {
  Contact = 'contact',
  Impressum = 'impressum',
  PrivacyPolicy = 'privacyPolicy',
  YoutubeChannel = 'youtubeChannel',
  OfferSupplement = 'offerSupplement',
  ConfirmEmail = 'confirmEmail',
  OfferCreator = 'offerCreator',
  ReguardProduct = 'reguardProduct',
  AddPhoneNumber = 'addPhoneNumber',
}
