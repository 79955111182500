import {
  componentTypes,
  fieldTypes,
  fieldValueType,
  formTypes,
  profileType,
  staticElementTypes
} from '@rehau/shared/forms/types';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import {
  AtHomeField,
  CalculationFieldEnum,
  FieldValueTypeEnum,
  FrontendComponentEnum,
  SetupFieldEnum
} from '@rehau/shared/enums';
import { AbstractField, AbstractStatic, NumberField, TextField } from '@rehau/shared/forms/elements';
import { Config } from '@rehau/shared/models/countryConfig';
import { FormApiErrorModel } from '@rehau/shared/models/responses';
import { ValidationMessages } from '@rehau/shared/forms/validations';

export abstract class BaseAbstractContainer {
  public abstract elements: (fieldTypes | componentTypes | staticElementTypes | profileType)[];
  public abstract type: formTypes;
  public abstract frontendComponent: FrontendComponentEnum;
  public name?: string;
  public id?: string;
  public valid?: boolean;
  public withConflict?: boolean;
  public available: boolean = true;

  public validate(): boolean {
    this.valid = true;
    for (const element of this.elements) {
      if (!element.validate()) {
        this.valid = false;
      }
    }

    return this.valid;
  }

  public isValid(): boolean {
    return (this.valid !== null && this.valid !== undefined)
       ? (this.valid)
      : this.validate();
  }

  public getValues(withOnlyForRead: boolean = false): ObjectIteratorInterface<fieldValueType> {
    let values: ObjectIteratorInterface<fieldValueType> = {};
    for (const element of this.elements) {
      if (element instanceof BaseAbstractContainer) {
        values = { ...values, ...element.getValues() };
      } else if (element instanceof AbstractField && (!element.onlyForRead || withOnlyForRead)) {
        values[element.id] = element.getValue();
      }
    }

    return values;
  }

  public setValues(values: ObjectIteratorInterface<fieldValueType>): void {
    this.withConflict = false;
    for (const element of this.elements) {
      if (element instanceof BaseAbstractContainer) {
        element.setValues(values);
      } else if (element instanceof AbstractField && (values[element.id] !== undefined)) {
        // @ts-ignore
        element.setValue(values[element.id]);
      }
    }
  }

  public setPreviousValidatedValues(values: ObjectIteratorInterface<fieldValueType>): void {
    for (const element of this.elements) {
      if (element instanceof BaseAbstractContainer) {
        element.setPreviousValidatedValues(values);
      } else if (element instanceof AbstractField && (values[element.id] !== undefined)) {
        element.setPreviousValidatedValue(values[element.id]);
      }
    }
  }

  public setError(error: FormApiErrorModel): boolean {
    for (const element of this.elements) {
      if (element instanceof BaseAbstractContainer) {
        const componentIsOnErrorPath: boolean = element.setError(error);
        if (componentIsOnErrorPath) {
          this.withConflict = true;

          return true;
        }
      }
      if (
        element instanceof AbstractField
        && element.id === error.fieldId
        && element.validate(true)
      ) {
        element.valid = false;
        element.validationMessages.push(new ValidationMessages(error.message, error.messageParams));

        return true;
      }
    }

    return false;
  }

  public clearValues(): void {
    for (const element of this.elements) {
      if (element instanceof BaseAbstractContainer) {
        element.clearValues();
      } else if (element instanceof AbstractField) {
        // @ts-ignore
        element.setValue(null);
        element.valid = undefined;
      }
    }
  }

  public updateConfig(config: ObjectIteratorInterface<fieldValueType>): void {
    for (const element of this.elements) {
      if (element instanceof BaseAbstractContainer) {
        element.updateConfig(config);
      } else if (element instanceof AbstractField) {
        element.updateConfig(config);
      }
    }
  }

  public updateCountryConfig(countryConfig: Config): void {
    for (const element of this.elements) {
      if (
        (element instanceof BaseAbstractContainer)
        || (element instanceof AbstractStatic)
        || (element instanceof AbstractField)
      ) {
        element.updateCountryConfig(countryConfig);
      }
    }
  }

  public setName(name: string): this {
    this.name = name;

    return this;
  }

  public getName(): string | undefined {
    return this.name;
  }

  public findFieldById(id: string): fieldTypes | null {
    for (const element of this.elements) {
      if ((element instanceof AbstractField) && (element?.id === id)) {
        return element;
      }
      if (element instanceof BaseAbstractContainer) {
        return element.findFieldById(id);
      }
    }

    return null;
  }

  public getAllFieldsIds(): SetupFieldEnum[] {
    let result: SetupFieldEnum[] = [];
    for (const element of this.elements) {
      if (element instanceof AbstractField) {
        result.push(element.id as SetupFieldEnum);
      }
      if (element instanceof BaseAbstractContainer) {
        result = result.concat(element.getAllFieldsIds());
      }
    }

    return result;
  }

  public getAllFields(): fieldTypes[] {
    let result: fieldTypes[] = [];
    for (const element of this.elements) {
      if (element instanceof AbstractField) {
        result.push(element);
      }
      if (element instanceof BaseAbstractContainer) {
        result = result.concat(element.getAllFields());
      }
    }

    return result;
  }

  public findContainerByName(name: string): BaseAbstractContainer | null {
    return this.findContainerByNameInContainer(name, this);
  }

  protected findContainerByNameInContainer(name: string, container: BaseAbstractContainer): BaseAbstractContainer | null {
    for (const element of container.elements) {
      if (element instanceof BaseAbstractContainer) {
        if (element.getName() === name) {
          return element;
        }
        const elem: BaseAbstractContainer | null = this.findContainerByNameInContainer(name, element);
        if (elem) {
          return elem;
        }
      }
    }

    return null;
  }

  public findContainerById(id: string): BaseAbstractContainer | null {
    for (const element of this.elements) {
      if (element instanceof BaseAbstractContainer) {
        if (element.id === id) {
          return element;
        }
        const wantedContainer: BaseAbstractContainer | null = element.findContainerById(id);
        if (wantedContainer) {
          return wantedContainer;
        }
      }
    }

    return null;
  }

  public findElementByName<T = AbstractField>(name: string): T | null {
    return this.findElementByNameInContainer<T>(name, this);
  }

  protected findElementByNameInContainer<T = AbstractField>(name: string, container: BaseAbstractContainer): T | null {
    for (const element of container.elements) {
      if (element instanceof AbstractField) {
        if (element.name === name) {
          return element as unknown as T;
        }
      } else if (element instanceof BaseAbstractContainer) {
        const elem: T | null = this.findElementByNameInContainer<T>(name, element);
        if (elem) {
          return elem;
        }
      }
    }

    return null;
  }

  public getValue<T>(name: CalculationFieldEnum | AtHomeField, type?: FieldValueTypeEnum): T | undefined {
    const value: fieldValueType | undefined = this.findElementByName(name)?.value;
    let typedValue: unknown = value;
    if ((value !== undefined) && (value !== null)) {
      switch (type) {
        case FieldValueTypeEnum.Number:
          typedValue = Number(value);
          break;
        case FieldValueTypeEnum.Boolean:
          typedValue = (['true', '1'].includes(String(value)));
          break;
        case FieldValueTypeEnum.String:
          typedValue = String(value);
          break;
      }

      return typedValue as T;
    }

    return;
  }

  public isAnyFieldAvailable(): boolean {
    return this.elements.some((element: fieldTypes | componentTypes | staticElementTypes | profileType): boolean => {
      if (element instanceof BaseAbstractContainer) {
        return element.isAnyFieldAvailable() && element.available;
      }
      if (element instanceof AbstractField) {
        return element.available;
      }

      return true;
    });
  }

  public getFirstInvalidFieldId(): string | undefined {
    for (const element of this.elements) {
      if (element instanceof AbstractField && element.valid === false) {
        return element.id;
      }
      if (element instanceof BaseAbstractContainer) {
        if (element.id && element.valid === false) {
          return element.id;
        }
        const fieldId: string | undefined = element.getFirstInvalidFieldId();
        if (fieldId) {
          return fieldId;
        }
      }
    }
  }

  public getFirstInvalidField(): fieldTypes | undefined {
    for (const element of this.elements) {
      if (element instanceof AbstractField && element.valid === false) {
        return element;
      }
      if (element instanceof BaseAbstractContainer) {
        const field: fieldTypes | undefined = element.getFirstInvalidField();
        if (field) {
          return field;
        }
      }
    }
  }

  public checkFieldsAvailable(values: ObjectIteratorInterface<fieldValueType> = {}): void {
    for (const element of this.elements) {
      if (element instanceof BaseAbstractContainer) {
        element.checkFieldsAvailable(values);
      }
    }
  }

  public getAllInvalidFieldIds(): string[] {
    let result: string[] = [];
    for (const element of this.elements) {
      if (element instanceof AbstractField && element.valid === false) {
        result.push(element.id);
      }
      if (element instanceof BaseAbstractContainer) {
        result = result.concat(element.getAllInvalidFieldIds());
      }
    }

    return result;
  }

  public getAllInvalidTextAndNumberFields(checkAllSteps: boolean = false): (TextField | NumberField)[] {
    let result: (TextField | NumberField)[] = [];
    for (const element of this.elements) {
      if ((element instanceof TextField || element instanceof NumberField) && element.valid === false) {
        result.push(element);
      }
      if (element instanceof BaseAbstractContainer && (element.valid === false || checkAllSteps)) {
        result = result.concat(element.getAllInvalidTextAndNumberFields(checkAllSteps));
      }
    }

    return result;
  }
}
