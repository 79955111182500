import React, { KeyboardEventHandler } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

import { Autocomplete, CurrencyPositionEnum } from '@rehau/shared/enums';
import { InputType } from '../../../enums/inputType.enum';
import { KeyboardKey } from '../../../enums/keyboardKey.enum';
import { StyledInput, Wrapper } from '../input.styled';

import { AreaUnit, Currency, Slash, Sup } from './priceUnitInput.styled';
import { PriceUnitInputProps } from './priceUnitInput.types';

export const PriceUnitInput: React.FC<PriceUnitInputProps> = (props: PriceUnitInputProps): JSX.Element => {
  const {
    currencyPosition,
    currencyText,
    areaUnit,
    placeholder,
    onChange,
    onBlur,
    onSubmit,
    value,
    autocomplete = Autocomplete.Off,
    areaUnitSquared = true,
    disabled,
    unitWithSlash = false,
  }: PriceUnitInputProps = props;

  const onKeyUp: KeyboardEventHandler = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === KeyboardKey.Enter && onSubmit) {
      onSubmit();
    }
  };

  const handleNumbersChange: (values: NumberFormatValues) => void = (values: NumberFormatValues): void => {
    if (onChange) {
      onChange(values.value);
    }
  };

  return (
    <Wrapper>
      {currencyPosition === CurrencyPositionEnum.Right ? (
        <>
          <NumberFormat
            placeholder={placeholder}
            onValueChange={handleNumbersChange}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            value={value}
            autoComplete={autocomplete}
            type={InputType.Tel}
            disabled={disabled}
            thousandSeparator=' '
            customInput={StyledInput}
          />
          <Currency>{currencyText}</Currency>
          {!!areaUnit && (
            <AreaUnit>
              {!!unitWithSlash && <Slash>/</Slash>}
              {areaUnit}
              {!!areaUnitSquared && <Sup>2</Sup>}
            </AreaUnit>
          )}
        </>
      ) : (
        <>
          <Currency>{currencyText}</Currency>
          <NumberFormat
            placeholder={placeholder}
            onValueChange={handleNumbersChange}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            value={value}
            disabled={disabled}
            thousandSeparator=' '
            customInput={StyledInput}
          />
          {!!areaUnit && (
            <AreaUnit>
              {!!unitWithSlash && <Slash>/</Slash>}
              {areaUnit}
              {!!areaUnitSquared && <Sup>2</Sup>}
            </AreaUnit>
          )}
        </>
      )}
    </Wrapper>
  );
};
