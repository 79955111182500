import { UserRoleEnum } from '@rehau/shared/enums';
import { UserRoleModel } from '../models';

export const isUserRoleHigher: (firstUserRole: UserRoleEnum, secondUserRole: UserRoleEnum, rolesHierarchy: UserRoleModel[]) => boolean =
  (firstUserRole: UserRoleEnum, secondUserRole: UserRoleEnum, rolesHierarchy: UserRoleModel[]): boolean => {
    const firstUserRoleHierarchy: number = rolesHierarchy
      .find((role: UserRoleModel): boolean => role.name === firstUserRole)?.hierarchy ?? 0;
    const secondUserRoleHierarchy: number = rolesHierarchy
      .find((role: UserRoleModel): boolean => role.name === secondUserRole)?.hierarchy ?? 0;

    return firstUserRoleHierarchy > secondUserRoleHierarchy;
  };
