import { useState } from 'react';

import { UseState } from '../../../types/useState.types';
import { UseActiveTab } from '../products.types';

export const useActiveTab: (initialState: string) => UseActiveTab = (initialState: string): UseActiveTab => {
  const [activeTab, setActiveTab]: UseState<string> = useState(initialState);

  const handleInView: (inView: boolean, optionId: string) => void = (inView: boolean, optionId: string): void => {
    if (inView) {
      setActiveTab(optionId);
    }
  };

  return [activeTab, handleInView];
};
