import styled from 'styled-components';

import { BoxShadow } from '../../enums/boxShadow.enum';
import { Color } from '../../enums/color.enum';
import { StyledComponent } from '../../types/styledComponent.types';

export const Tooltip: StyledComponent<'div'> = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  bottom: -12px;
  transform: translateY(100%);
  background-color: ${Color.Black};
  pointer-events: none;
  transition: 0.2s;
  padding: 5px 8px;
  opacity: 0;
  box-shadow: ${BoxShadow.BtnGreyShadow};

  :after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${Color.Black} transparent;
  }
`;

export const Container: StyledComponent<'button'> = styled.button`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid ${Color.GreyStroke};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  :hover {
    ${Tooltip} {
      pointer-events: initial;
      opacity: 1;
    }
  }
`;

export const TooltipText: StyledComponent<'p'> = styled.p`
  font-size: 14px;
  line-height: 100%;
  color: ${Color.White};
  white-space: nowrap;
`;
