import styled from 'styled-components';

import { Markdown } from '../../../markdown/markdown.component';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { InnerContainer, OuterContainer } from '../../../../theme/containers.styled';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { Button as ButtonBase } from '../../../button/button.component';

export const Container: StyledComponent<typeof OuterContainer> = styled(OuterContainer)`
  padding-top: 100px;
  padding-bottom: 100px;

  @media ${Breakpoint.Tablet} {
    margin: 0;
    width: 100%;
    padding: 60px 40px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 60px 20px;
  }
`;

export const Wrapper: StyledComponent<typeof InnerContainer> = styled(InnerContainer)`
  flex-direction: column;
`;

export const StyledMarkdown: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;

  strong {
    font-weight: ${FontWeight.Bold};
  }
`;

export const Link: StyledComponent<'a'> = styled.a`
  text-decoration: none;
`;

export const Button: StyledComponent<typeof ButtonBase> = styled(ButtonBase)`
  margin-top: 30px;
`;
