export enum FormFieldTypeEnum {
  AgreementField = 'AgreementField',
  AreaField = 'AreaField',
  CheckboxField = 'CheckboxField',
  ColorPickerField = 'ColorPickerField',
  DecisionField = 'DecisionField',
  FileField = 'FileField',
  MultiSelectField = 'MultiSelectField',
  NumberField = 'NumberField',
  CostField = 'CostField',
  CostAreaField = 'CostAreaField',
  SelectField = 'SelectField',
  TextField = 'TextField',
  TextAreaField = 'TextAreaField',
  PasswordField = 'PasswordField',
  RangeField = 'RangeField',
  ChooseHeatingField = 'ChooseHeatingField',
  ChooseHeatingTypeField = 'ChooseHeatingTypeField',
  DateField = 'DateField',
  ClickNumberWithIconField = 'ClickNumberWithIconField',
  ChooseVariantField = 'ChooseVariantField',
  SelectItemField = 'SelectItemField',
  OfferStatusChangeField = 'OfferStatusChangeField',
}
