import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import {
  AdvantageSection,
  AppConfigType,
  UseCountryConfig,
  useRedirect,
  UseRedirectOutput,
} from '@rehau-fe/ui/src';
import { CalculationTypeEnum } from '@rehau/shared/enums';
import { useCountryConfig } from '@rehau-fe/ui/src/hooks/useCountryConfig.hook';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { fieldValueType } from '@rehau/shared/forms/types';
import { useAppConfig } from '@rehau-fe/ui/src/hooks/useAppConfig.hook';

import { Container, InnerContainer } from './home.styled';
import { Hero } from './components/hero/hero.component';
import { IconBoxes } from './components/iconBoxes/iconBoxes.component';
import { SpecialProducts } from './components/specialProducts/specialProducts.component';
import FamilyImage from './images/family.jpg';
import FamilyDesktopImage from './images/familyDesktop.jpg';

export const Home: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const handleRedirect: UseRedirectOutput = useRedirect();
  const appConfig: AppConfigType = useAppConfig();
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();
  const products: ObjectIteratorInterface<fieldValueType> = isConfigAvailable ? config.variables.products : {};

  const goToCalculator: () => void = (): void => {
    handleRedirect(`${appConfig.creatorUrl}/${CalculationTypeEnum.Reguard}`, undefined, true);
  };

  return (
    <Container data-testid='home'>
      <Hero
        buttonOnClick={goToCalculator}
        title={t('rehau.website.home_view.start_section.title')}
        description={t('rehau.website.home_view.start_section.description')}
        buttonLabel={t('rehau.website.home_view.start_section.button_label')}
        image={FamilyImage}
        desktopImage={FamilyDesktopImage}
      />
      <InnerContainer>
        <IconBoxes />
        <AdvantageSection
          hasBiggerPaddingWrapper
          title={t('rehau.website.home_view.advantage_section.title')}
          options={
          [
            t('rehau.website.home_view.advantage_section.fast_description'),
            t('rehau.website.home_view.advantage_section.high_quality_description'),
            t('rehau.website.home_view.advantage_section.sure_description'),
            t('rehau.website.home_view.advantage_section.free_of_charge_description'),
          ]
          }
        />
        {(!!products?.['reguard.active'] || !!products?.['resource.active']) && (
          <SpecialProducts reguardActive={!!products?.['reguard.active']} resourceActive={!!products?.['resource.active']} />
        )}
      </InnerContainer>
    </Container>
  );
};
