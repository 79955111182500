import React, { forwardRef, useRef } from 'react';
import { InView } from 'react-intersection-observer';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { ProductTableNameEnum } from '../../../../../../../../shared/enums';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { ProductTable } from '../../../productTable/productTable.component';
import { FullWidthImage } from '../../components/fullWidthImage/fullWidthImage.component';
import { TextWithImage } from '../../components/textWithImage/textWithImage.component';
import { Tabs } from '../../components/tabs/tabs.component';
import { IconItems } from '../../components/iconItems/iconItems.component';
import { TextWithBox } from '../../components/textWithBox/textWithBox.component';
import { Legend } from '../../components/legend/legend.component';
import { ImageColumns } from '../../components/imageColumns/imageColumns.component';
import { TextWithColumns } from '../../components/textWithColumns/textWithColumns.component';
import { ColumnType } from '../../components/textWithColumns/textWithColumns.enums';
import { TextWithImageRow } from '../../components/textWithImageRow/textWithImageRow.component';
import { ImageDescription } from '../../components/imageDescription/imageDescription.component';
import { useActiveTab } from '../../hooks/useActiveTab.hooks';
import WomanImage from '../../images/womanWithSink.jpg';
import SinkImage from '../../images/sinkWithDisplay.jpg';
import RehauDeviceImage from '../../images/rehauDevice.png';
import SinkSwitchersImage from '../../images/sinkSwitchers.jpg';
import KitchenImage from '../../images/kitchen.jpg';
import SinkDrawImage from '../../images/sinkDraw.png';
import ClockImage from '../../images/clock2.png';
import LeafImage from '../../images/leaf.png';
import HandWithCoinsImage from '../../images/handWithCoins.png';
import CupsImage from '../../images/cups.png';
import ColdWaterImage from '../../images/coldWater.jpg';
import ColdWaterMobileImage from '../../images/coldWaterMobile.jpg';
import HotWaterImage from '../../images/hotWater.jpg';
import HotWaterMobileImage from '../../images/hotWaterMobile.jpg';
import SparklingWaterImage from '../../images/sparklingWater.jpg';
import UnfilteredWaterImage from '../../images/unfilteredWater.jpg';
import UnfilteredWaterMobileImage from '../../images/unfilteredWaterMobile.jpg';
import Display1Image from '../../images/display1.png';
import Display2Image from '../../images/display2.png';
import Display3Image from '../../images/display3.png';
import SinkBasicImage from '../../images/sinkBasic.png';
import SinkPremiumImage from '../../images/sinkPremium.png';
import SinkPremiumPlusImage from '../../images/sinkPremiumPlus.png';
import MatCSinkImage from '../../images/matCSink.png';
import MatLSinkImage from '../../images/matLSink.png';
import ChromeCSinkImage from '../../images/chromeCSink.png';
import ChromeLSinkImage from '../../images/chromeLSink.png';
import SinkPointersImage from '../../images/sinkPointers.png';
import SinkRotationImage from '../../images/sinkRotation.png';
import StreamsImage from '../../images/streams.png';
import { UseActiveTab, UseCalculatorNavigate } from '../../products.types';
import { sectionVisibilityThreshold } from '../../products.constants';
import { ConfiguratorPreview } from '../../../../components/configuratorPreview/configuratorPreview.component';
import { useCalculatorNavigate } from '../../hooks/useCalculatorNavigate.hook';
import { ProductPopupContext } from '../../../../contexts/productPopup.context';
import { OptionTab } from '../../components/tabs/tabs.types';

import { Container, TableContainer, TableWrapper } from './reSource.styled';
import { SectionId } from './reSource.enums';
import { ReSourceProps } from './reSource.types';

export const ReSource: React.FC<ReSourceProps> = forwardRef((props: ReSourceProps, ref: React.ForwardedRef<{}>): JSX.Element => {
  const { className, tabsOnTop, setTabsOnTop, isInPopup = false }: ReSourceProps = props;
  const { t }: TransProps<never> = useTranslation();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const [activeTabId, setActiveTabId]: UseActiveTab = useActiveTab('');
  const { navigateToCalculator }: UseCalculatorNavigate = useCalculatorNavigate();
  const advantagesRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const varietyRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const installationRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const savingsRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const actionRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const modelsRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const calculatorRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);

  const getCurrentRefByActiveId: (activeTab: string) => React.RefObject<InView & HTMLDivElement> =
    (activeTab: string): React.RefObject<InView & HTMLDivElement> => {
      switch (activeTab) {
        case SectionId.Advantages:
          return advantagesRef;
        case SectionId.Variety:
          return varietyRef;
        case SectionId.Installation:
          return installationRef;
        case SectionId.Savings:
          return savingsRef;
        case SectionId.Action:
          return actionRef;
        case SectionId.Models:
          return modelsRef;
        default:
          return calculatorRef;
      }
    };

  const handleScroll: (tabId: string) => void = (tabId: string): void => {
    const activeRef: React.RefObject<InView & HTMLDivElement> = getCurrentRefByActiveId(tabId);
    activeRef.current?.node?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ProductPopupContext.Provider value={{ isInPopup }}>
      <Container className={className} data-testid='resource-product' ref={ref as React.RefObject<HTMLDivElement>}>
        <FullWidthImage src={WomanImage} />
        <TextWithImage
          sectionTitle={t('rehau.products.resource.header.title')}
          text={t('rehau.products.resource.header.content')}
          imageSrcList={[SinkImage]}
        />
        <Tabs
          options={[
            { label: t('rehau.products.resource.tabs.advantages'), id: SectionId.Advantages },
            { label: t('rehau.products.resource.tabs.variety'), id: SectionId.Variety },
            { label: t('rehau.products.resource.tabs.installation'), id: SectionId.Installation },
            { label: t('rehau.products.resource.tabs.savings'), id: SectionId.Savings },
            { label: t('rehau.products.resource.tabs.action'), id: SectionId.Action },
            { label: t('rehau.products.resource.tabs.models'), id: SectionId.Models },
            !isInPopup ? { label: t('rehau.products.heat.tabs.calculator'), id: SectionId.Calculator } : {} as OptionTab,
          ]}
          activeId={activeTabId}
          isOnTop={tabsOnTop}
          setOnTop={setTabsOnTop}
          handleScroll={handleScroll}
        />
        <InView
          as='div'
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Advantages)}
          id={SectionId.Advantages}
          threshold={sectionVisibilityThreshold}
          ref={advantagesRef}
        >
          <IconItems
            title={t('rehau.products.resource.advantages.title')}
            text={t('rehau.products.resource.advantages.content')}
            itemsData={[
              {
                imageSrc: CupsImage,
                text: t('rehau.products.resource.advantages.device'),
              },
              {
                imageSrc: LeafImage,
                text: t('rehau.products.resource.advantages.comfort'),
              },
              {
                imageSrc: ClockImage,
                text: t('rehau.products.resource.advantages.time'),
              },
              {
                imageSrc: HandWithCoinsImage,
                text: t('rehau.products.resource.advantages.savings'),
              },
            ]}
          />
        </InView>
        <InView
          as='div'
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Variety)}
          id={SectionId.Variety}
          threshold={isMobile ? 0.2 : 0.3}
          ref={varietyRef}
        >
          <ImageColumns
            title={t('rehau.products.resource.variety.title')}
            columnsData={[
              {
                imageSrc: ColdWaterImage,
                imageSrcMobile: ColdWaterMobileImage,
                label: t('rehau.products.resource.variety.cold_water'),
                title: t('rehau.products.resource.variety.cold_water_title'),
                text: t('rehau.products.resource.variety.cold_water_content'),
              },
              {
                imageSrc: SparklingWaterImage,
                imageSrcMobile: ColdWaterMobileImage,
                label: t('rehau.products.resource.variety.sparkling_water'),
                title: t('rehau.products.resource.variety.sparkling_water_title'),
                text: t('rehau.products.resource.variety.sparkling_water_content'),
              },
              {
                imageSrc: HotWaterImage,
                imageSrcMobile: HotWaterMobileImage,
                label: t('rehau.products.resource.variety.hot_water'),
                title: t('rehau.products.resource.variety.hot_water_title'),
                text: t('rehau.products.resource.variety.hot_water_content'),
              },
              {
                imageSrc: UnfilteredWaterImage,
                imageSrcMobile: UnfilteredWaterMobileImage,
                label: t('rehau.products.resource.variety.unfiltered_water'),
                title: t('rehau.products.resource.variety.unfiltered_water_title'),
                text: t('rehau.products.resource.variety.unfiltered_water_content'),
              },
            ]}
          />
        </InView>
        <InView
          as='div'
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Installation)}
          id={SectionId.Installation}
          threshold={sectionVisibilityThreshold}
          ref={installationRef}
        >
          <TextWithImage
            title={t('rehau.products.resource.installation.title')}
            text={t('rehau.products.resource.installation.content')}
            hint={t('rehau.products.resource.installation.hint')}
            hintImageSrc={RehauDeviceImage}
            imageSrcList={[KitchenImage]}
            darkBackground
          />
        </InView>
        <InView
          as='div'
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Savings)}
          id={SectionId.Savings}
          threshold={sectionVisibilityThreshold}
          ref={savingsRef}
        >
          <TextWithBox
            sectionTitle={t('rehau.products.resource.savings.title')}
            text={t('rehau.products.resource.savings.content')}
            boxTitle={t('rehau.products.resource.savings.box')}
            hasWideText
          />
        </InView>
        <InView
          as='div'
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Action)}
          id={SectionId.Action}
          threshold={isMobile ? 0.05 : 0.1}
          ref={actionRef}
        >
          <ImageDescription
            title={t('rehau.products.resource.action.title')}
            imageSrc={SinkPointersImage}
            pointersData={[
              {
                text: t('rehau.products.resource.action.filtering'),
                imageSrc: StreamsImage,
              },
              {
                text: t('rehau.products.resource.action.rotation'),
                imageSrc: SinkRotationImage,
              },
              {
                text: t('rehau.products.resource.action.display'),
              },
            ]}
          />
          <TextWithColumns
            columnType={ColumnType.Image}
            columnsData={[
              {
                title: t('rehau.products.resource.action.screen_title'),
                text: t('rehau.products.resource.action.screen_content'),
                imageSrc: Display1Image,
              },
              {
                title: t('rehau.products.resource.action.hygienic_title'),
                text: t('rehau.products.resource.action.hygienic_content'),
                imageSrc: Display2Image,
              },
              {
                title: t('rehau.products.resource.action.maintenance_title'),
                text: t('rehau.products.resource.action.maintenance_content'),
                imageSrc: Display3Image,
              },
            ]}
          />
          <TextWithImage
            title={t('rehau.products.resource.models.title')}
            text={t('rehau.products.resource.models.content')}
            imageSrcList={[SinkSwitchersImage]}
            textImageSrc={SinkDrawImage}
            darkBackground
          />
        </InView>
        <InView
          as='div'
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Models)}
          id={SectionId.Models}
          threshold={isMobile ? 0.05 : 0.1}
          ref={modelsRef}
        >
          <TextWithColumns
            title={t('rehau.products.resource.models.subtitle')}
            text={t('rehau.products.resource.models.subcontent')}
            columnType={ColumnType.ImageWithBox}
            columnsData={[
              {
                title: t('rehau.products.resource.models.basic_title'),
                text: t('rehau.products.resource.models.basic_content'),
                imageSrc: SinkBasicImage,
              },
              {
                title: t('rehau.products.resource.models.premium_title'),
                text: t('rehau.products.resource.models.premium_content'),
                imageSrc: SinkPremiumImage,
              },
              {
                title: t('rehau.products.resource.models.premium_plus_title'),
                text: t('rehau.products.resource.models.premium_plus_content'),
                imageSrc: SinkPremiumPlusImage,
              },
            ]}
          />
          <TextWithImageRow
            title={t('rehau.products.resource.models.bottom_subtitle')}
            text={t('rehau.products.resource.models.bottom_subcontent')}
            rowData={[
              {
                text: t('rehau.products.resource.models.mat_c'),
                imageSrc: MatCSinkImage,
              },
              {
                text: t('rehau.products.resource.models.mat_l'),
                imageSrc: MatLSinkImage,
              },
              {
                text: t('rehau.products.resource.models.chrome_c'),
                imageSrc: ChromeCSinkImage,
              },
              {
                text: t('rehau.products.resource.models.chrome_l'),
                imageSrc: ChromeLSinkImage,
              },
            ]}
            darkBackground
          />
        </InView>
        <InView
          as='div'
          threshold={sectionVisibilityThreshold}
        >
          <TableContainer >
            <TableWrapper>
              <ProductTable tableType={ProductTableNameEnum.Resource} tableTitle={t('rehau.products.resource.table.title')} />
            </TableWrapper>
          </TableContainer>
          <Legend
            options={[
              t('rehau.products.resource.legend.hot_water_function'),
              t('rehau.products.resource.legend.display_and_energy_saving_mode'),
              t('rehau.products.resource.legend.sample_calculation'),
              t('rehau.products.resource.legend.web_app'),
            ]}
          />
        </InView>
        {!isInPopup && (
          <InView
            as='div'
            onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Calculator)}
            id={SectionId.Calculator}
            threshold={sectionVisibilityThreshold}
            ref={calculatorRef}
          >
            <ConfiguratorPreview buttonOnClick={navigateToCalculator} />
          </InView>
        )}
      </Container>
    </ProductPopupContext.Provider>
  );
});
