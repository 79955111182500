import React, { useEffect } from 'react';
import useCollapse from 'react-collapsed';
import { UseCollapseOutput } from 'react-collapsed/dist/types';
import { TransProps, useTranslation } from 'react-i18next';

import { IconName } from '../../../../../../shared/enums/iconName.enum';
import { Icon } from '../icon/icon.component';
import { Color } from '../../enums/color.enum';
import { InfoBoxPositionEnum, InfoBoxSizeEnum } from '@rehau/shared/enums';

import { AdditionalInfoProps } from './additionalinfo.types';
import {
  Collapse,
  ExtendButton,
  Header,
  IconContainer,
  MarkdownContent,
  SmileIcon,
  Text,
  TextButton,
  TextContent,
  Title,
  TitleContainer,
  Wrapper,
} from './additionalInfo.styled';

export const AdditionalInfo: React.FC<AdditionalInfoProps> = (
  props: AdditionalInfoProps
): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    content,
    contentInReadMore,
    className,
    expandButtonLabel = t('rehau.global.read_more'),
    position = InfoBoxPositionEnum.Top,
    warningTheme = false,
    withBiggerFonts = false,
    children,
    isExpandedContentVisible,
    isExtendButtonVisible = true,
    size = InfoBoxSizeEnum.Big,
    onExpandedStateChange,
  }: AdditionalInfoProps = props;
  const {
    isExpanded,
    getCollapseProps,
    getToggleProps,
  }: UseCollapseOutput = useCollapse({
    defaultExpanded: isExpandedContentVisible,
  });

  useEffect(
    (): void => {
      if (onExpandedStateChange) {
        onExpandedStateChange(isExpanded);
      }
    },
    [isExpanded]
  );

  return (
    <Wrapper className={className} tabIndex={-1} position={position} isWarning={warningTheme}>
      <Header size={size}>
        <TitleContainer>
          <SmileIcon
            name={warningTheme ? IconName.ConflictBig : IconName.InfoTooltip}
            color={warningTheme ? Color.Pink : Color.ValidationBorder}
            size={22}
          />
          <Title withBiggerFonts={withBiggerFonts}>
            <MarkdownContent text={content} />
            {(!!contentInReadMore || !!children) && (
              <TextButton withBiggerFonts={withBiggerFonts} {...getToggleProps()} data-testid='readMoreText' tabIndex={-1}>
                {isExpanded ? undefined : expandButtonLabel}
              </TextButton>
            )}
          </Title>
        </TitleContainer>
        {(!!contentInReadMore || !!children) && isExtendButtonVisible && (
          <ExtendButton
            {...getToggleProps()}
            data-testid='arrowButton'
            tabIndex={-1}
          >
            <IconContainer isExtended={isExpanded}>
              <Icon name={IconName.ArrowDown} color={Color.Black} size={14} />
            </IconContainer>
          </ExtendButton>
        )}
      </Header>

      {(!!contentInReadMore || !!children) && (
        <Collapse {...getCollapseProps()} data-testid='collapse'>
          <TextContent>
            {contentInReadMore ? (
              <Text withBiggerFonts={withBiggerFonts}>
                <MarkdownContent text={contentInReadMore} />
              </Text>
            ) : (
              children
            )}
          </TextContent>
        </Collapse>
      )}
    </Wrapper>
  );
};
