import { TooltipTheme } from '@rehau/shared/enums';

export class Tooltip {
  public title: string;
  public content: string;
  public theme?: TooltipTheme;

  constructor(content: string, title: string = 'rehau.global.infobox.title', theme: TooltipTheme = TooltipTheme.Standard) {
    this.content = content;
    this.title = title;
    this.theme = theme;
  }
}
