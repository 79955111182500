import styled, { css, StyledComponent } from 'styled-components';
import { prop } from 'ramda';

import { Color } from '../../../../enums/color.enum';
import { styleWhenTrue } from '../../../../utils/style.utils';
import { BoxShadow } from '../../../../enums/boxShadow.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';

import { ChosenItemProps } from './choiceGroupItem.types';

export const Wrapper: StyledComponent<
  'button',
  {},
  ChosenItemProps,
  never
> = styled.button`
  border: 1px solid ${Color.GreyStroke};
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  min-width: 110px;
  height: 44px;
  box-sizing: border-box;
  transition: 0.3s;
  margin-right: 5px;
  ${styleWhenTrue(
    prop('isActive'),
    css`
      border: 1px solid ${Color.Green};
      box-shadow: ${BoxShadow.BtnGreyShadow};
    `
  )}
`;

export const Text: StyledComponent<'p', {}, ChosenItemProps, never> = styled.p`
  font-size: 18px;
  color: ${Color.Grey};
  ${styleWhenTrue(
    prop('isUntouched'),
    css`
      color: ${Color.Black};
      font-weight: ${FontWeight.Bold};
    `
  )}
  ${styleWhenTrue(
    prop('isActive'),
    css`
      color: ${Color.Black};
      font-weight: ${FontWeight.Bold};
    `
  )}
`;
