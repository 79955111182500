export class RangeValueModel {
  public min: number;
  public max: number;
  public value: number;

  constructor(value: number, min: number = 0, max: number | undefined) {
    this.value = value;
    this.min = min;
    this.max = (max === undefined) ? value : max;
  }
}
