export enum FrontendComponentEnum {
  Slides = 'slides',
  Setup = 'setup',
  Profile = 'profile',
  ProfileSection = 'profileSection',
  FormStep = 'formStep',
  HorizontalFieldline = 'horizontalFieldline',
  AreaField = 'areaField',
  SlideAreaField = 'slideAreaField',
  TextField = 'textField',
  TextAreaField = 'textAreaField',
  NumberField = 'numberField',
  ButtonStatic = 'buttonStatic',
  ClickNumberField = 'clickNumberField',
  ClickNumberFieldWithIcon = 'clickNumberFieldWithIcon',
  CostField = 'costField',
  CostAreaField = 'costAreaField',
  ColorPickerField = 'colorPickerField',
  PopupWithVariants = 'popupWithVariants',
  PhoneField = 'phoneField',
  PasswordField = 'passwordField',
  PasswordContainer = 'passwordContainer',
  RadiusContainer = 'radiusContainer',
  Decision = 'decision',
  DecisionWithChildren = 'decisionWithChildren',
  Select = 'select',
  MultiSelect = 'multiSelect',
  MailField = 'mailField',
  Map = 'map',
  FileField = 'fileField',
  ExpandedConditionalBox = 'expandedConditionalBox',
  RangeField = 'rangeField',
  SelectBarWithType = 'selectBarWithType',
  SelectBarWithProgress = 'selectBarWithProgress',
  ChooseHeatingField = 'chooseHeatingField',
  ChooseHeatingTypeField = 'chooseHeatingTypeField',
  PopupWithClickNumberWithIcon = 'PopupWithClickNumberWithIcon',
  SimpleDescriptionBox = 'simpleDescriptionBox',
  RatingBox = 'ratingBox',
  StaticImageElement = 'staticImageElement',
  Checkbox = 'checkbox',
  CheckboxWithPrivacyPolicy = 'checkboxWithPrivacyPolicy',
  CheckboxWithCompanyName = 'checkboxWithCompanyName',
  DateField = 'dateField',
  ChooseInstaller = 'chooseInstaller',
  Summary = 'summary',
  SimulationSummary = 'simulationSummary',
  EmptyContainerForStatic = 'emptyContainerForStatic',
  ChooseLocation = 'chooseLocation',
  ChooseVariantFromPopup = 'chooseVariantFromPopup',
  ChooseVariantFromSelectWithDecision = 'chooseVariantFromSelectWithDecision',
  SelectItem = 'selectItem',
  SelectItemsBox = 'selectItemsBox',
  ChooseOptionFromBoxes = 'chooseOptionFromBoxes',
  CalculationChoicesBox = 'calculationChoicesBox',
  SaveContactData = 'saveContactData',
  ConditionalAvailable = 'conditionalAvailable',
  HorizontalFieldlineWithChooseInstaller = 'horizontalFieldlineWithChooseInstaller',
  OfferStatusChange = 'offerStatusChange',
}
