import React, { useEffect } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import {
  ErrorScreen,
  findPathByName,
  LocalStorageKey,
  useLocalStorage,
  UseLocalStorage,
  useRedirect,
  UseRedirectOutput,
  useRoutesInfo
} from '@rehau-fe/ui/src';
import { RouteInfo } from '@rehau/shared/models/countryConfig';
import { CountryLocaleEnum, UserTokenStatusEnum, WebsitePathNameEnum } from '@rehau/shared/enums';
import { AuthorizationModel } from '@rehau/shared/models';
import i18n from '@rehau-fe/ui/src/translations';

export const NotFound: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const routesArray: RouteInfo[] = useRoutesInfo();
  const handleRedirect: UseRedirectOutput = useRedirect();
  const [authData]: UseLocalStorage<AuthorizationModel | null> = useLocalStorage<AuthorizationModel | null>(LocalStorageKey.AuthData, null);
  const [adminToken]: UseLocalStorage<string> = useLocalStorage<string>(LocalStorageKey.AdminToken, '');

  const navigateToMain: () => void = (): void => {
    if (authData?.token) {
      handleRedirect(findPathByName(WebsitePathNameEnum.Offers, routesArray), WebsitePathNameEnum.Offers);
    } else if (adminToken) {
      handleRedirect(findPathByName(WebsitePathNameEnum.AdminDashboardOffers, routesArray), WebsitePathNameEnum.AdminDashboardOffers);
    } else {
      handleRedirect(findPathByName(WebsitePathNameEnum.Home, routesArray), WebsitePathNameEnum.Home);
    }
  };

  return (
    <ErrorScreen
      title={t('rehau.errors.404.title')}
      description={t('rehau.errors.404.description')}
      buttonLabel={t('rehau.errors.404.buttonLabel')}
      smallTitle={t('rehau.errors.smallTitle')}
      hasBar={false}
      handleButtonClick={navigateToMain}
    />
  );
};
