import { AtHomeCalcField } from '@rehau/shared/enums';

import { DefaultPromoContentProps } from './components/defaultPromoContent/defaultPromoContent.types';
import PhotovoltaicBig from './img/photovoltaicBig.jpg';
import PhotovoltaicSmall from './img/photovoltaicSmall.jpg';
import SolarPanelsBig from './img/solarPanelsBig.jpg';
import SolarPanelsSmall from './img/solarPanelsSmall.jpg';
import FreshWaterBig from './img/freshwaterBig.jpg';
import FreshWaterSmall from './img/freshwaterSmall.jpg';
import DescalingBig from './img/descalingBig.jpg';
import DescalingSmall from './img/descalingSmall.jpg';
import CirculationBig from './img/circulationBig.jpg';
import CirculationSmall from './img/circulationSmall.jpg';
import AirSystemBig from './img/airSystemBig.jpg';
import AirSystemSmall from './img/airSystemSmall.jpg';

export type PopupKey =
  | AtHomeCalcField.SolarSystem
  | AtHomeCalcField.Photovoltaic
  | AtHomeCalcField.FreshWaterModule
  | AtHomeCalcField.DecalcificationModule
  | AtHomeCalcField.CirculationInstallation
  | AtHomeCalcField.AirSystem;

export const defaultContentMap: { [k in PopupKey]: Omit<DefaultPromoContentProps, 'title' | 'description'> } = {
  [AtHomeCalcField.SolarSystem]: {
    mainImg: SolarPanelsBig,
    sideImg: SolarPanelsSmall,
  },
  [AtHomeCalcField.Photovoltaic]: {
    mainImg: PhotovoltaicBig,
    sideImg: PhotovoltaicSmall,
  },
  [AtHomeCalcField.FreshWaterModule]: {
    mainImg: FreshWaterBig,
    sideImg: FreshWaterSmall,
  },
  [AtHomeCalcField.DecalcificationModule]: {
    mainImg: DescalingBig,
    sideImg: DescalingSmall,
  },
  [AtHomeCalcField.CirculationInstallation]: {
    mainImg: CirculationBig,
    sideImg: CirculationSmall,
  },
  [AtHomeCalcField.AirSystem]: {
    mainImg: AirSystemBig,
    sideImg: AirSystemSmall,
  },
};
