import styled from 'styled-components';

import { Breakpoint, Color, CustomLink, FontWeight, GridWidth, Markdown, StyledComponent } from '@rehau-fe/ui/src';

export const Container: StyledComponent<'div'> = styled.div`
  padding: 140px 60px;
  margin: 0 auto;
  max-width: ${GridWidth.Desktop};

  @media ${Breakpoint.Tablet} {
    padding: 140px 40px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 100px 22px 80px;
  }
`;

export const Title: StyledComponent<'h1'> = styled.h1`
  font-weight: ${FontWeight.Black};
  font-size: 40px;
  margin-bottom: 20px;
`;

export const Section: StyledComponent<'div'> = styled.div`
  font-size: 18px;

  a {
    color: ${Color.Pink};
    text-decoration: none;
  }

  & + & {
    margin-top: 20px;
  }
`;

export const Text: StyledComponent<typeof Markdown> = styled(Markdown)`
  & + & {
    margin-top: 10px;
  }
`;

export const Link: StyledComponent<typeof CustomLink> = styled(CustomLink)`
  display: block;

  & + & {
    margin-top: 10px;
  }
`;
