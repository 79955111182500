import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { Breakpoint, Color, FontWeight, PhotoOverlay, StyledComponent, styleWhenTrue } from '@rehau-fe/ui/src';
import { SectionHeader } from '../sectionHeader/sectionHeader.component';

import { PhotoProps } from './specialProducts.types';

export const Wrapper: StyledComponent<'div'> = styled.div`
  margin-bottom: 100px;

  @media ${Breakpoint.Tablet} {
    margin-bottom: 0;
  }
`;

export const SectionHeaderContainer: StyledComponent<'div'> = styled.div`
  @media ${Breakpoint.Additional1280} {
    padding: 0 40px;
  }

  @media ${Breakpoint.Additional480} {
    padding: 0;
  }
`;

export const InfoBox: StyledComponent<'div'> = styled.div`
  position: absolute;
  display: block;
  opacity: 0;
  transition: 0.5s;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  top: 30px;
  left: 30px;
  background-color: ${Color.White};
  box-sizing: border-box;
  padding: 40px 30px;

  @media ${Breakpoint.Mobile} {
    padding: 20px 20px;
    top: 20px;
    left: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
  }
`;

export const InfoBoxTitle: StyledComponent<'h3'> = styled.h3`
  font-size: 28px;
  line-height: 32px;
  font-weight: ${FontWeight.Black};
  margin-bottom: 10px;
  word-break: break-word;
`;

export const InfoBoxDescription: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${Color.Grey};
  margin-bottom: 30px;
  word-break: break-word;
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  display: flex;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const LeftPhoto: StyledComponent<'div', PhotoProps> = styled.div`
  width: 388px;
  height: 500px;
  background-color: ${Color.Green};
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 120px 40px 30px;
  box-sizing: border-box;
  ${({ url, fullWidth }: PhotoProps): string | null => (fullWidth ? `background-image: url(${url})` : null)};

  :hover {
    & ${InfoBox} {
      opacity: 1;

      @media ${Breakpoint.Additional480} {
        display: none;
      }
    }
  }

  @media ${Breakpoint.Tablet} {
    width: 40%;

    ${styleWhenTrue(
      prop('fullWidth'),
      css`
        width: 100%;
        background-color: unset;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      `
    )}
  }

  @media ${Breakpoint.Mobile} {
    width: 100%;

    ${styleWhenTrue(
      prop('fullWidth'),
      css`
        width: 100%;
      `
    )}
  }

  @media ${Breakpoint.Additional480} {
    width: 100%;
    height: unset;
    padding: 80px 80px 120px;
  }

  ${styleWhenTrue(
    prop('fullWidth'),
    css`
      width: 100%;
      background-color: unset;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    `
  )}
`;

export const LeftPhotoInnerWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

export const Img: StyledComponent<'img'> = styled.img`
  width: 100%;
  max-width: 240px;
  margin: 0 auto;
`;

export const RightPhoto: StyledComponent<'div', PhotoProps> = styled.div`
  width: 70%;
  height: 500px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: flex-end;
  background-image: ${({ url }: PhotoProps): string => `url(${url})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 30px;

  :hover {
    & ${InfoBox} {
      opacity: 1;

      @media ${Breakpoint.Additional480} {
        display: none;
      }
    }
  }

  @media ${Breakpoint.Tablet} {
    width: 60%;

    ${styleWhenTrue(
      prop('fullWidth'),
      css`
        width: 100%;
      `
    )}
  }

  @media ${Breakpoint.Mobile} {
    width: 100%;
    padding: 40px;

    ${styleWhenTrue(
      prop('fullWidth'),
      css`
        width: 100%;
      `
    )}
  }

  @media ${Breakpoint.Additional480} {
    width: 100%;
    height: 350px;
  }

  ${styleWhenTrue(
    prop('fullWidth'),
    css`
      width: 100%;
    `
  )}
`;

export const PhotoText: StyledComponent<'p'> = styled.p`
  font-size: 26px;
  line-height: 31px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.White};
  word-break: break-word;
  max-width: 350px;

  @media ${Breakpoint.Mobile} {
    max-width: 300px;
  }

  @media ${Breakpoint.Additional480} {
    display: none;
  }
`;

export const StyledPhotoOverlay: StyledComponent<typeof PhotoOverlay> = styled(PhotoOverlay)`
  display: flex;
  align-items: flex-end;
  padding: 40px;
  box-sizing: border-box;
`;
