import { CheckboxField } from '@rehau/shared/forms/elements/fields';
import { FormFieldTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';

export class DecisionField extends CheckboxField {
  public labelTrue: string;
  public labelFalse: string;

  constructor(
    id: string,
    name: string,
    label: string | null,
    required: boolean,
    defaultValue: boolean | null,
    labelTrue: string = 'rehau.global.yes',
    labelFalse: string = 'rehau.global.no'
  ) {
    super(id, name, label, required, defaultValue);
    this.frontendComponent = FrontendComponentEnum.Decision;
    this.type = FormFieldTypeEnum.DecisionField;
    this.labelTrue = labelTrue;
    this.labelFalse = labelFalse;
  }
}
