import styled, { css } from 'styled-components';
import ShowMoreText from 'react-show-more-text';
import { prop } from 'ramda';

import { Color } from '../../enums/color.enum';
import { styleWhenTrue } from '../../utils/style.utils';
import { StyledComponent } from '../../types/styledComponent.types';

import { ButtonProps } from './checkbox.types';

export const Wrapper: StyledComponent<'div'> = styled.div`
  display: flex;
`;

export const Button: StyledComponent<'button', ButtonProps> = styled.button`
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: 1px solid ${Color.GreyStroke};
  display: flex;
  justify-content: center;
  padding: 0;
  align-items: center;
  position: relative;
  ${styleWhenTrue(
    prop('isChecked'),
    css`
      border: 1px solid ${Color.Green};
      background-color: ${Color.Green};
    `
  )}
  ${styleWhenTrue(
    prop('isDisabled'),
    css`
      border: 1px solid ${Color.Grey};
      background-color: ${Color.Grey};
    `
  )}
`;

export const ClickableField: StyledComponent<'div'> = styled.div`
  height: 35px;
  width: 35px;
  position: absolute;
`;

export const Label: StyledComponent<'p'> = styled.p`
  font-size: 15px;
  line-height: 18px;
  color: ${Color.Grey};
  margin-left: 10px;
  margin-top: 0;
  padding-top: 0;
  width: 100%;
  a {
    text-decoration: none;
    color: ${Color.Black};
  }
`;

export const ShowMoreTextStyled: StyledComponent<typeof ShowMoreText> = styled(ShowMoreText)`
  p {
    display: inline;
  }
`;

export const TransparentSpanElement: StyledComponent<'span'> = styled.span``;
