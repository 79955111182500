import styled from 'styled-components';

import { Breakpoint, Color, FontWeight, GridWidth, StyledComponent, ZIndex } from '@rehau-fe/ui/src';

export const Container: StyledComponent<'div'> = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: 200vh;
  overflow: hidden;
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  max-width: ${GridWidth.Desktop};
  margin: 0 auto;
`;

export const MobileInfoBox: StyledComponent<'div'> = styled.div`
  margin-top: -40px;
  padding: 30px 20px;
  width: calc(100% - 24px);
  background-color: ${Color.White};
  display: none;
  z-index: ${ZIndex.MobileInfoBox};
  box-sizing: border-box;
  margin-left: 12px;

  @media ${Breakpoint.Additional480} {
    display: block;
  }
`;

export const MobileBackground: StyledComponent<'div'> = styled.div`
  display: none;
  width: 100%;
  background-color: ${Color.BgGrey};

  @media ${Breakpoint.Additional480} {
    position: relative;
    display: inline-block;
  }
`;
