import React, { useContext } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { Config } from '../../../../../../../shared/models/countryConfig';
import { InputController } from '../../input/inputController.component';
import { RangeField } from '../../../../../../../shared/forms/elements';
import { PriceUnitInput } from '../../input/priceUnitInput/priceUnitInput.component';
import { FieldElement, FieldElementProps } from '../types';
import { CountryConfigContext } from '../../../contexts/countryConfig.context';

export const RangeFieldElement: FieldElement<RangeField> = (props: FieldElementProps<RangeField>): JSX.Element => {
  const { component, save, setValues }: FieldElementProps<RangeField> = props;
  const { t }: TransProps<never> = useTranslation();
  const config: Config = useContext(CountryConfigContext);

  return (
    <InputController
      label={t(component.label || '')}
      additionalInfoContent={t(component.informationBox?.content || '')}
      additionalInfoContentInReadMore={t(component.informationBox?.readMoreContent || '')}
      tooltipProps={component.tooltip}
      handleSave={save}
      component={component}
      setValues={setValues}
    >
      <PriceUnitInput
        currencyPosition={config.currency.position}
        areaUnit={component.rangeUnit}
        areaUnitSquared={false}
        placeholder={t(component.placeholder)}
      />
    </InputController>
  );
};
