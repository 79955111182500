import { prop } from 'ramda';
import styled, { css, StyledComponent } from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { styleWhenTrue } from '../../utils/style.utils';

import { WrapperProps } from './choiceGroup.types';

export const Wrapper: StyledComponent<'div', {}, WrapperProps, never> = styled.div`
  display: flex;
  ${styleWhenTrue(
    prop('isColumn'),
    css`
      flex-direction: column;
    `
  )}
`;
