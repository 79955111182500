import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { MarkdownContent, RouteInfo } from '@rehau/shared/models/countryConfig';
import { useCountryConfig } from '@rehau-fe/ui/src/hooks/useCountryConfig.hook';
import { AppConfigType, CustomLink, findPathByName, UseCountryConfig, useRoutesInfo } from '@rehau-fe/ui/src';
import { ExternalPathNameEnum } from '@rehau/shared/enums';
import { useAppConfig } from '@rehau-fe/ui/src/hooks/useAppConfig.hook';
import { useSupportEmailReplace } from '@rehau-fe/ui/src/hooks/useSupportEmailReplace.hook';

import { Container, StyledButton, StyledButtonContainer, TextWrapper, Title } from './customerRegulations.styled';
import { ContentProps } from './customerRegulations.types';

export const CustomerRegulations: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const appConfig: AppConfigType = useAppConfig();
  const routesArray: RouteInfo[] = useRoutesInfo();
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();
  const customerRegulations: MarkdownContent | null = isConfigAvailable ? config.websiteViewsConfigs.customerRegulations : null;
  const replaceVariablesInContent: (text: string) => string = (text: string): string => {
    if (text.includes('{{privacyPolicyUrl}}')) {
      return useSupportEmailReplace(text.replace(/{{privacyPolicyUrl}}/g, findPathByName(ExternalPathNameEnum.PrivacyPolicy, routesArray)));
    }

    return useSupportEmailReplace(text);
  };

  const Content: React.FC<ContentProps> = ({ children, noPadding = false }: ContentProps): JSX.Element => (
    <Container noPadding={noPadding}>
      <Title>{t('rehau.website.customer_regulations_view.header')}</Title>
      {!!customerRegulations &&
        customerRegulations.content.map(
          (sentences: string[], index: number): JSX.Element => (
            <React.Fragment key={index}>
              {sentences.map(
                (sentence: string, sentenceIndex: number): JSX.Element => (
                  <TextWrapper
                    key={sentenceIndex}
                    text={replaceVariablesInContent(sentence)}
                  />
                )
              )}
            </React.Fragment>
          )
        )}
        {children}
    </Container>
  );

  return (
    <Content>
      <StyledButtonContainer>
        <CustomLink to={`${appConfig.externalAssets}${config.variables.website['customer_regulations_relative_pdf_path']}`} openInNewTab>
          <StyledButton>{t('rehau.website.customer_regulations_view.download_button_label')}</StyledButton>
        </CustomLink>
      </StyledButtonContainer>
    </Content>
  );
};
