import styled from 'styled-components';

import { Color } from '../../../enums/color.enum';
import { StyledComponent } from '../../../types/styledComponent.types';

export const Row: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${Color.GreyStroke};
  padding: 20px 0 20px 22px;
  box-sizing: border-box;
  height: 80px;
`;

export const ButtonsContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80px;
`;

export const ArrowButton: StyledComponent<'button'> = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  transform: rotate(90deg);

  :last-child {
    transform: rotate(-90deg);
  }
`;
