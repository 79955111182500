export enum CountryCodeEnum {
  at = 'at',
  bg = 'bg',
  cz = 'cz',
  de = 'de',
  gr = 'gr',
  hu = 'hu',
  pl = 'pl',
  ro = 'ro',
  sk = 'sk',
}
