import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { UseFormatDate } from '../../../../types/useFormatDate.types';
import { useFormatDate } from '../../../../hooks/useFormatDate.hook';

import { DataBoxProps, LabelWithValue } from './dataBox.types';
import { Container, DataBoxHeader, DateUpdate, ItemLabel, ItemsBox, ItemsRow, ItemValue } from './dataBox.styled';

export const DataBox: React.FC<DataBoxProps> = (props: DataBoxProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { header, rowsData, className, withBiggerFonts = false, withCalculatedHeight = false, updatedDate }: DataBoxProps = props;
  const { formatDate }: UseFormatDate = useFormatDate();

  return (
    <Container className={className}>
      <DataBoxHeader withBiggerFonts={withBiggerFonts}>
        {t(header)}
        {!!updatedDate && (
          <DateUpdate>
            {`(${t('rehau.components.data_box.last_modification', { date: formatDate(updatedDate, 'DD.MM.YYYY HH:mm') })})`}
          </DateUpdate>
        )}
      </DataBoxHeader>
      <ItemsBox withCalculatedHeight={withCalculatedHeight}>
        {rowsData.map((item: LabelWithValue): JSX.Element => (
          <ItemsRow withBiggerFonts={withBiggerFonts} key={item.label}>
            <ItemLabel>{item.label}</ItemLabel>
            <ItemValue>{item.value}</ItemValue>
          </ItemsRow>
        ))}
      </ItemsBox>
    </Container>
  );
};
