export enum UseAlertsAction {
  SetDialog = 'setDialog',
  SetDialogAcceptButtonLoadingStatus = 'setDialogAcceptButtonLoadingStatus',
  SetDialogActiveStatus = 'setDialogActiveStatus',
  SetDialogWithIcon = 'setDialogWithIcon',
  SetSmallDialog = 'setSmallDialog',
  SetDialogWithForm = 'setDialogWithForm',
  SetAlert = 'setAlert',
  ClearAlerts = 'clearAlerts',
  CloseSmallDialog = 'closeSmallDialog',
}
