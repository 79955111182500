import styled, { css, FlattenSimpleInterpolation, StyledComponent } from 'styled-components';

import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Color } from '../../../../enums/color.enum';
import { Icon } from '../../../icon/icon.component';
import { CustomLink } from '../../../customLink/customLink.component';
import { HeaderHeight } from '../../../../enums/headerHeight.enum';

import { ItemLabelProps } from './navbarItem.types';

export const ArrowIcon: StyledComponent<typeof Icon, {}, {}, never> = styled(Icon)`
  margin-left: 15px;
`;

export const ItemUnderscore: StyledComponent<'div', {}, {}, never> = styled.div`
  height: 5px;
  width: 0;
  transition: width 0.2s;
  background-color: ${Color.Pink};
  margin-top: calc(${HeaderHeight.AboveDesktop} - 59px);
  @media ${Breakpoint.Desktop} {
    margin-top: calc(${HeaderHeight.Desktop} - 44px);
  }
`;

export const ItemStyles: FlattenSimpleInterpolation = css`
  font-size: 18px;
  line-height: 21px;
  color: ${Color.Black};
  letter-spacing: 0.2px;
  text-decoration: none;
  height: ${HeaderHeight.AboveDesktop};
  padding-top: 33px;
  padding-bottom: 0;
  :hover {
    color: ${Color.Pink};
    ${ArrowIcon} {
      color: ${Color.Pink};
    }
    ${ItemUnderscore} {
      width: 100%;
    }
  }

  @media ${Breakpoint.Desktop} {
    padding-top: 20px;
    font-size: 16px;
    line-height: 19px;
    height: ${HeaderHeight.Desktop};
  }
`;

export const ItemLabel: StyledComponent<'p', {}, ItemLabelProps, never> = styled.p<ItemLabelProps>`
  display: flex;
  align-items: center;

  ${({ isActive }: ItemLabelProps): false | FlattenSimpleInterpolation | undefined => isActive && css`
    color: ${Color.Pink};

    ${ArrowIcon} {
      color: ${Color.Pink};
    }
  `};
`;

export const Container: StyledComponent<'div', {}, {}, never> = styled.div``;

export const ItemButton: StyledComponent<'button', {}, {}, never> = styled.button`
  ${ItemStyles}
  padding-left: 0;
  padding-right: 0;
`;

export const ItemLink: StyledComponent<typeof CustomLink, {}, {}, never> = styled(CustomLink)`
  ${ItemStyles}
  display: block;
  box-sizing: border-box;
`;
