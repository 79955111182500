import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { Breakpoint, Color, StyledComponent, styleWhenTrue } from '@rehau-fe/ui/src';
import { ContainerProps } from '../../../../rehau-app/src/views/registration/registration.types';
import { LabelContainer } from '../../../../rehau-ui/src/components/fieldsContainer/fieldsContainer.styled';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  padding: 50px 0 5px;
  background-color: ${Color.BgGrey};
  min-height: calc(100vh - 240px);

  ${styleWhenTrue(
    prop('paddingTopDisabled'),
    css`
      padding: 0;
      min-height: unset;
    `
  )}

  @media ${Breakpoint.Mobile} {
    padding: 40px 0 5px;
    ${LabelContainer} {
      margin-bottom: 20px;
    }
  }
`;
