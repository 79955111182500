import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { ZIndex } from '../../enums/zIndex.enum';
import { BoxShadow } from '../../enums/boxShadow.enum';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';
import { Icon } from '../icon/icon.component';
import { InputContainer } from '../input/inputContainer/inputContainer.component';
import { BaseLabel } from '../../theme';

import { ArrowButtonProps, SelectBoxProps, SelectRowProps } from './multiselectDropdown.types';

export const SelectBox: StyledComponent<'div', SelectBoxProps> = styled.div`
  position: absolute;
  width: 100%;
  border: 1px solid ${Color.GreyStroke};
  max-height: 200px;
  top: 0;
  left: 0;
  transform: translateY(44px);
  border-top: unset;
  box-sizing: border-box;
  pointer-events: none;
  opacity: 0;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  background-color: ${Color.White};
  overflow-y: auto;
  transition: 0.3s;

  ${styleWhenTrue(
    prop('isOpen'),
    css`
      opacity: 1;
      pointer-events: initial;
      z-index: ${ZIndex.MultiSelectDropdown};
    `
  )}

  ${styleWhenTrue(
    prop('isValid'),
    css`
      border: 1px solid ${Color.Green};
      border-top: unset;
    `
  )}
`;

export const Label: StyledComponent<typeof BaseLabel> = styled(BaseLabel)`
  margin-bottom: 10px;
`;

export const CheckContainer: StyledComponent<'div'> = styled.div`
  border: 1px solid ${Color.GreyStroke};
  background-color: ${Color.White};
  width: 20px;
  height: 20px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RowText: StyledComponent<'p'> = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${Color.Grey};
  word-break: break-word;
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  margin: auto;
`;

export const SelectRow: StyledComponent<'button', SelectRowProps> = styled.button`
  padding: 0 18px;
  display: flex;
  align-items: center;
  margin-top: 18px;

  :last-child {
    margin-bottom: 18px;
  }

  ${styleWhenTrue(
    prop('isActive'),
    css`
      ${CheckContainer} {
        border: 1px solid ${Color.Green};
        background-color: ${Color.Green};
      }

      ${RowText} {
        color: ${Color.Black};
      }
    `
  )}
`;

export const DropdownContainer: StyledComponent<typeof InputContainer> = styled(InputContainer)`
  position: relative;
  width: fit-content;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const Value: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 22px;
  font-weight: ${FontWeight.Bold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10px;
`;

export const Option: StyledComponent<'span'> = styled.span``;

export const Wrapper: StyledComponent<'div'> = styled.div``;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
`;

export const ArrowButton: StyledComponent<'button', ArrowButtonProps> = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  height: 100%;
  padding: 0 15px;

  ${styleWhenTrue(
    prop('isOpen'),
    css`
      ${InnerContainer} {
        transform: rotate(180deg);
      }
    `
  )}
`;
