import React, { ChangeEvent, ChangeEventHandler, useState } from 'react';

import { ColorPickerField } from '../../../../../../shared/forms/elements';
import { ValidationStatus } from '../../enums/validationStatus.enum';
import { UseState } from '../../types/useState.types';
import { InputContainer } from '../input/inputContainer/inputContainer.component';
import { ValidationBar } from '../validationBar/validationBar.component';

import { Label, Picker, PickerCircle, PickerCircleInner, StyledInput, Wrapper } from './colorPicker.styled';
import { ColorPickerProps } from './colorPicker.types';

export const ColorPicker: React.FC<ColorPickerProps> = (props: ColorPickerProps): JSX.Element => {
  const {
    initValue,
    placeholder,
    label,
    component = new ColorPickerField('', '', true, '', ''),
    handleSave = (): void => undefined,
    setValues = (): void => undefined,
  }: ColorPickerProps = props;
  const [isPickerVisible, setIsPickerVisible]: UseState<boolean> = useState<boolean>(false);
  const [color, setColor]: UseState<string> = useState<string>(initValue || '');
  const [tempValue, setTempValue]: UseState<string> = useState<string>('');

  const togglePicker: () => void = (): void => {
    if (isPickerVisible) {
      handleSetValue();
    }
    setIsPickerVisible(!isPickerVisible);
  };

  const handleChange: ChangeEventHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    setTempValue(event.target.value);
    const hexRegex: RegExp = /^#(?:[0-9a-fA-F]{3}){1,2}$/i;
    const isHex: boolean = hexRegex.test(event.target.value);
    if (isHex) {
      setColor(event.target.value);
      setTempValue('');
    }
  };

  const handleBlur: () => void = (): void => {
    setTempValue('');
    setIsPickerVisible(false);
    handleSetValue();
  };

  const handleSetValue: () => void = (): void => {
    setValues({ [component.id]: color });
    const validationSuccess: boolean = component.validate();
    if (validationSuccess) {
      handleSave();
    }
  };

  return (
    <>
      {!!label && <Label>{label}</Label>}
      <Wrapper onBlur={handleBlur}>
        <InputContainer
          validationStatus={color ? ValidationStatus.Valid : ValidationStatus.Untouched}
          additionalContent={
            <PickerCircle onClick={togglePicker} data-testid='pickerCircle'>
              <PickerCircleInner color={color} />
            </PickerCircle>
          }
        >
          <StyledInput placeholder={placeholder} value={tempValue || color} onChange={handleChange} onBlur={handleBlur} />
        </InputContainer>
        {isPickerVisible && <Picker color={color} onChange={setColor} />}
      </Wrapper>
      <ValidationBar errorsArray={component?.validationMessages} />
    </>
  );
};
