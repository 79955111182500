import { Dispatch } from 'react';

import { StepsAction } from '../../../enums/stepsAction.enums';
import { CurrentStep } from '../../../types/currentStep.types';
import { SetCurrentStepAction } from '../../../types/setCurrentStepAction.types';

export const setCurrentStep: (
  dispatch: Dispatch<SetCurrentStepAction>, currentStep: CurrentStep
) => void = (
  dispatch: Dispatch<SetCurrentStepAction>, currentStep: CurrentStep
): void => {
  dispatch({ type: StepsAction.SetCurrentStep, payload: currentStep });
};
