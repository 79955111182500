import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { InputController } from '../../input/inputController.component';
import { PasswordField } from '../../../../../../../shared/forms/elements';
import { FieldElement, FieldElementProps } from '../types';
import { StandardInput } from '../../input/standardInput/standardInput.component';
import { Autocomplete, FrontendComponentEnum, IconName } from '../../../../../../../shared/enums';
import { PasswordInput } from '../../input/passwordInput/passwordInput.component';

export const PasswordFieldElement: FieldElement<PasswordField> = (props: FieldElementProps<PasswordField>): JSX.Element => {
  const { component, save, setValues }: FieldElementProps<PasswordField> = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <InputController
      label={t(component.label || '')}
      additionalInfoContent={t(component.informationBox?.content || '')}
      additionalInfoContentInReadMore={t(component.informationBox?.readMoreContent || '')}
      tooltipProps={component.tooltip}
      component={component}
      handleSave={save}
      setValues={setValues}
    >
      <PasswordInput placeholder={t(component.placeholder)} autocomplete={Autocomplete.NewPassword} />
    </InputController>
  );
};
