export enum CalculationSortEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  Price = 'price',
  Status = 'status',
  CompanyName = 'companyName',
  CountryLabel = 'countryLabel',
  LeadStatus = 'leadStatus',
  AssignedPrice = 'assignedPrice',
  MontageDate = 'montageDate',
  WarmLeadQualification = 'warmLeadQualification',
  RegionalManager = 'regionalManager',
  EntryPoint = 'entryPoint',
  SetupName = 'setupName',
}
