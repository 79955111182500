import React from 'react';

import { HorizontalFieldlineThemeEnum, InfoBoxPositionEnum } from '@rehau/shared/enums';

import {
  AboveEntireFieldAdditionalInfo,
  ComponentsContainer,
  ComponentWrapper,
  FieldWrapper,
  InfoContainer,
  LabelContainer,
  Subtitle,
  Title,
  TopAdditionalInfo,
} from './fieldsContainer.styled';
import { FieldsContainerProps } from './fieldsContainer.types';

export const FieldsContainer: React.FC<FieldsContainerProps> = (props: FieldsContainerProps): JSX.Element => {
  const {
    title,
    subTitle,
    children,
    infoBox,
    className,
    frontendTheme = HorizontalFieldlineThemeEnum.FullSize,
  }: FieldsContainerProps = props;

  return (
    <>
      {!!infoBox && infoBox.position === InfoBoxPositionEnum.AboveEntireField && (
        <InfoContainer>
          <AboveEntireFieldAdditionalInfo
            content={infoBox.content}
            contentInReadMore={infoBox.readMoreContent}
          />
        </InfoContainer>
      )}
      <FieldWrapper className={className}>
        {(!!title || !!subTitle) &&
          <LabelContainer $smallMargin={frontendTheme === HorizontalFieldlineThemeEnum.Inner}>
            {!!title && <Title text={title} />}
            {!!subTitle && <Subtitle>{subTitle}</Subtitle>}
          </LabelContainer>
        }
        <ComponentsContainer $fullSizeWidth={frontendTheme === HorizontalFieldlineThemeEnum.FullSize}>
          {!!infoBox && infoBox.position === InfoBoxPositionEnum.Top && (
            <TopAdditionalInfo
              content={infoBox.content}
              contentInReadMore={infoBox.readMoreContent}
            />
          )}
          <ComponentWrapper>{children}</ComponentWrapper>
        </ComponentsContainer>
      </FieldWrapper>
    </>
  );
};
