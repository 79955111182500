import React from 'react';
import { isEmpty } from 'ramda';
import { animateScroll } from 'react-scroll';

import { IconName } from '../../../../../../shared/enums';
import { Color } from '../../enums/color.enum';
import {
  MenuPositions,
  PathNameMenuPositionTuple,
  Socials,
} from '../../../../../../shared/models/countryConfig';
import { useCountryConfig } from '../../hooks/useCountryConfig.hook';
import { SocialNameEnum } from '../../../../../../shared/enums/socialName.enum';
import { Icon } from '../icon/icon.component';
import { sortItems } from '../../utils/menuPositions/menuPositions.utils';
import { UseCountryConfig } from '../../types/useCountryConfig.types';
import { FooterBottomBar } from '../footerBottomBar/footerBottomBar.component';

import {
  ArrowBackground,
  BlackLine,
  Container,
  ContentSeparatorContainer,
  GreenBar,
  GreenBarWrapper,
  Menu,
  MenuWrapper,
  ProgressBar,
  ProgressText,
  ProgressWrapper,
  SocialColumn,
  SocialLink,
  WhiteLine,
  WhiteProgressText,
} from './footer.styled';
import { FooterMenuColumns } from './components/footerMenuColumns/footerMenuColumns.component';
import { socialsConfig } from './footer.configs';
import { FooterProps } from './footer.types';

export const Footer: React.FC<FooterProps> = (props: FooterProps): JSX.Element => {
  const { withoutBottomBar = false }: FooterProps = props;
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();
  const menuConfig: MenuPositions | null = isConfigAvailable ? config.menuConfigs.footerMenu : null;
  const menuEntries: PathNameMenuPositionTuple[] = !!menuConfig ? Object.entries(menuConfig).sort(sortItems) : [];
  const contactDetails: string[] = !!config.footerDetails && config.footerDetails.contactDetails;
  const socials: Socials = !!config.footerDetails && config.footerDetails.socials;

  const scrollToTop: () => void = (): void => {
    animateScroll.scrollToTop();
  };

  return (
    <Container data-testid='footer'>
      <ArrowBackground>
        <ContentSeparatorContainer onClick={scrollToTop}>
          <Icon size={16} name={IconName.ArrowBig} color={Color.Pink} />
        </ContentSeparatorContainer>
      </ArrowBackground>
      <GreenBarWrapper>
        <GreenBar>
          <ProgressWrapper>
            <ProgressBar>
              <BlackLine />
              <WhiteLine />
            </ProgressBar>
            <ProgressText>Engineering progress</ProgressText>
            <WhiteProgressText>Enhancing lives</WhiteProgressText>
          </ProgressWrapper>
        </GreenBar>
      </GreenBarWrapper>
      <MenuWrapper>
        <Menu>
          <FooterMenuColumns menuEntries={menuEntries} contactDetails={contactDetails} />
          {!isEmpty(socials) && (
            <SocialColumn data-testid='social-column'>
              {(Object.keys(socials) as SocialNameEnum[]).map((key: SocialNameEnum): JSX.Element => (
                <SocialLink data-testid='social-link' key={key} href={socials[key]}>
                  <Icon name={socialsConfig[key].icon} size={socialsConfig[key].size} color={Color.White} />
                </SocialLink>
              ))}
            </SocialColumn>
          )}
        </Menu>
      </MenuWrapper>
      {!withoutBottomBar && (
        <FooterBottomBar />
      )}
    </Container>
  );
};
