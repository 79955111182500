import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { StyledComponent } from '../../types/styledComponent.types';
import { Color } from '../../enums/color.enum';
import { styleWhenTrue } from '../../utils/style.utils';
import { CustomLink } from '../customLink/customLink.component';
import { FontWeight } from '../../enums/fontWeight.enum';
import { Icon } from '../icon/icon.component';

import { LinkProps } from './breadcrumbs.types';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  margin: 20px 0;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const LinkContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
`;

export const Link: StyledComponent<typeof CustomLink, LinkProps> = styled(CustomLink)`
  color: ${Color.Grey};
  font-size: 15px;
  line-height: 15px;

  ${styleWhenTrue(
    prop('active'),
    css`
      color: ${Color.Black};
      font-weight: ${FontWeight.Bold}
    `
  )}
`;

export const StyledBreadcrumbIcon: StyledComponent<typeof Icon> = styled(Icon)`
  margin: 3px 5px 0;
`;
