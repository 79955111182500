import { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';

import { ClickNumberField } from '../../../../../../../shared/forms/elements';
import { fieldValueType } from '../../../../../../../shared/forms/types';
import { ObjectIteratorInterface } from '../../../../../../../shared/objectIterator.interface';
import { useEffectAfterMount } from '../../../hooks/useEffectAfterMount';
import { UseState } from '../../../types/useState.types';

import { UseQuantity, UseQuantityOutput } from './useQuantity.types';

export const useQuantity: UseQuantity = (
  min: number,
  max: number,
  handleSave: () => void = (): void => undefined,
  setValues: (values: ObjectIteratorInterface<fieldValueType>) => void = (): void => undefined,
  component: ClickNumberField,
  initValue?: number,
  internalComponentValidationEnabled?: boolean
): UseQuantityOutput => {
  const [value, setValue]: UseState<number> = useState<number>(initValue ?? min);
  const [valid, setIsValid]: UseState<boolean> = useState<boolean>(!!initValue);
  const [tempValue, setTempValue]: UseState<string> = useState<string>('');
  const [isTyping, setIsTyping]: UseState<boolean> = useState<boolean>(false);
  const [error, setError]: UseState<string> = useState<string>('');
  const dotCommaRegexp: RegExp = new RegExp(/[,\.]/g);
  const integersRegex: RegExp = new RegExp(/^(-*[0-9]+[0-9]*)$/);

  useEffectAfterMount(
    (): void => {
      setValues({ [component.id]: value });
      const validationSuccess: boolean = component.validate();
      setIsValid(validationSuccess);
      if (validationSuccess) {
        handleSave();
      }
    },
    [value]
  );

  useEffect(
    (): void => {
      if (component.value) {
        setValue(component.value);
      }
    },
    [component.value]
  );

  const handleChange: ChangeEventHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const targetValue: string = event.target.value;
    const inputValue: number = Number(targetValue);
    setTempValue(targetValue);

    if (!!targetValue.match(dotCommaRegexp)) {
      setError('rehau.components.quantity_input.fraction_value');
    } else if (!(inputValue <= max && inputValue >= min) && (!!targetValue.match(integersRegex))) {
      setError('rehau.components.quantity_input.value_outside_limit');
    } else if (inputValue <= max && inputValue >= min && !!targetValue.match(integersRegex)) {
      setError('');
    } else {
      setError('rehau.components.quantity_input.not_allowed_value');
    }
  };

  const handleFocus: () => void = (): void => {
    setIsTyping(true);
    setTempValue(String(value));
  };

  const handleSubmit: () => void = (): void => {
    setIsTyping(false);
    const inputValue: number = Number(tempValue);
    if (internalComponentValidationEnabled) {
      if (inputValue <= max && inputValue >= min && !!tempValue.match(integersRegex)) {
        setValue(inputValue);
      }
      setError('');
    } else {
      setValue(inputValue);
    }
  };

  const add: () => void = (): void => {
    if (value < max) {
      setValue(value + 1);
    }
  };

  const subtract: () => void = (): void => {
    if (value > min) {
      setValue(value - 1);
    }
  };

  return {
    value,
    tempValue,
    valid,
    isTyping,
    handleChange,
    handleFocus,
    handleSubmit,
    add,
    subtract,
    error,
  };
};
