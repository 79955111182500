import React from 'react';

import { FieldElement, FieldElementProps } from '../../../components/fieldComponent/types';
import { AreaField } from '@rehau/shared/forms/elements';
import { SliderInput } from '../../sliderInput/sliderInput.component';

export const SliderInputFieldElement: FieldElement<AreaField> = (props: FieldElementProps<AreaField>): JSX.Element => {
  const { component, setValues, save }: FieldElementProps<AreaField> = props;

  return (
    <SliderInput
      component={component}
      minValue={component.validation?.rules.minValue}
      maxValue={component.validation?.rules.maxValue}
      setValues={setValues}
      handleSave={save}
      tooltipProps={component.tooltip}
    />
  );
};
