import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { Color } from '../../enums/color.enum';
import { styleWhenTrue } from '../../utils/style.utils';

import { PageButtonProps } from './pagination.types';

export const Wrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media ${Breakpoint.Tablet} {
    justify-content: space-between;
  }
`;

export const MorePagesIndicatior: StyledComponent<'div'> = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  @media ${Breakpoint.Mobile} {
    margin-right: 10px;
  }
`;

export const ArrowButton: StyledComponent<'button'> = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  flex-shrink: 0;

  :first-child {
    margin-right: 20px;
  }

  :hover {
    background-color: ${Color.Green};
    i {
      color: ${Color.White};
    }
  }

  @media ${Breakpoint.Mobile} {
    :first-child {
      margin-right: 10px;
    }
  }
`;

export const PageButton: StyledComponent<'button', PageButtonProps> = styled.button<PageButtonProps>`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  transition: 0.3s;
  flex-shrink: 0;

  :hover {
    color: ${Color.White};
    background-color: ${Color.Green};
  }

  ${styleWhenTrue(
    prop('isActive'),
    css`
      background-color: ${Color.GreyStroke};
    `
  )}

  @media ${Breakpoint.Mobile} {
    margin-right: 10px;
  }
`;
