import { fieldValueType } from '@rehau/shared/forms/types';
import { CustomRegexpValidationItem, ValidationAbstract, ValidationMessages } from '@rehau/shared/forms/validations';
import { ValidationStatus } from '@rehau/shared/forms/validations/validationStatus';
import { CountryConfigTypeEnum, ErrorMessageEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { InfoBox, Tooltip } from '@rehau/shared/forms/elements';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { Config } from '@rehau/shared/models/countryConfig';
import { RegexpTypeEnum } from '@rehau/shared/enums/regexpType.enum';

export abstract class AbstractField {
  public type: string;
  public frontendComponent: FrontendComponentEnum;
  public id: string;
  public name: string;
  public label: string | null;
  public value: fieldValueType;
  public previousValidatedValue?: fieldValueType = undefined;
  public required: boolean;
  public blocked: boolean; // TODO: unused
  public tooltip?: Tooltip;
  public informationBox?: InfoBox;
  public validation?: ValidationAbstract;
  public validationMessages: ValidationMessages[];
  public valid?: boolean;
  public onlyForRead: boolean = false;
  public disabled: boolean = false;
  public configMapping?: ObjectIteratorInterface<string>;
  public available: boolean = true;
  public defaultValue: fieldValueType;
  private isSetupField: boolean = false;
  private readonly requiredByDefault: boolean;

  protected constructor(
    type: string,
    frontendComponent: FrontendComponentEnum,
    id: string,
    name: string,
    label: string | null,
    value: fieldValueType,
    validation?: ValidationAbstract,
    required: boolean = false,
    blocked: boolean = false,
    informationBox?: InfoBox,
    tooltip?: Tooltip,
    configMapping?: ObjectIteratorInterface<string>
  ) {
    this.type = type;
    this.frontendComponent = frontendComponent;
    this.id = id;
    this.name = name;
    this.label = label;
    this.value = value;
    this.validation = validation;
    this.required = required;
    this.blocked = blocked;
    this.tooltip = tooltip;
    this.informationBox = informationBox;
    this.validationMessages = [];
    this.requiredByDefault = required;
    this.configMapping = configMapping;
  }

  public validate(withDisabled: boolean = false): boolean {
    if (this.onlyForRead || (this.disabled && !withDisabled)) {
      this.valid = true;
      this.validationMessages = [];

      return true;
    }
    this.validationMessages = [];
    if (this.validation) {
      const validationStatus: ValidationStatus | undefined = this.validation?.check(
        this.value, this.required, this.isSetupField, this.previousValidatedValue
      );
      if (validationStatus) {
        this.validationMessages = validationStatus.errors.map(
          (error: ErrorMessageEnum): ValidationMessages => new ValidationMessages(error, { name: this.label })
        );
        this.valid = validationStatus.status;

        return validationStatus.status;
      }
    }

    return !!this.valid;
  }

  public setValue(value: fieldValueType): this {
    this.value = value;

    return this;
  }

  public setPreviousValidatedValue(value: fieldValueType | undefined): this {
    if (value !== undefined) {
      this.previousValidatedValue = value;
    }

    return this;
  }

  public getValue(): fieldValueType {
    return this.value;
  }

  public setDefaultRequirements(): void {
    this.required = this.requiredByDefault;
    this.validationMessages = [];
  }

  public getAllowedOptionsValues(): string[] {
    return [];
  }

  public setOnlyForRead(readonly: boolean): this {
    this.onlyForRead = readonly;

    return this;
  }

  public setAvailable(available: boolean): this {
    this.available = available;

    return this;
  }

  public setDisabled(disabled: boolean): this {
    this.valid = true;
    this.validationMessages = [];
    this.disabled = disabled;

    return this;
  }

  public getValidationRegex(type: RegexpTypeEnum): CustomRegexpValidationItem | undefined {
    return this.validation?.customRegexpItems
      .find((item: CustomRegexpValidationItem): boolean => item.type === type);
  }

  // TODO: move to metod body to utils?
  public updateConfig(configObjectInterface: ObjectIteratorInterface<fieldValueType>): void {
    if (this.configMapping) {
      for (const configMappingKey in this.configMapping) {
        if (configMappingKey in this.configMapping) {
          const configKey: string = this.configMapping[configMappingKey];
          const valueFromConfig: fieldValueType = configObjectInterface[configKey];
          // @ts-ignore
          if (this[configMappingKey] !== valueFromConfig) {
            // @ts-ignore
            this[configMappingKey] = valueFromConfig;
          }
        }
      }
    }
  }

  public updateCountryConfig(countryConfig: Config): void {
    if (this.configMapping) {
      for (const configMappingKey in this.configMapping) {
        if (this.configMapping[configMappingKey]) {
          // @ts-ignore
          this[configMappingKey] = this.getCountryConfigValue(
            this.configMapping[configMappingKey],
            countryConfig
          );
        }
      }
    }
  }

  public setIsSetupFieldFlag(isSetup: boolean = false): this {
    this.isSetupField = isSetup;

    return this;
  }

  private getCountryConfigValue(countryConfigKey: string, countryConfig: Config): fieldValueType {
    const matches: RegExpMatchArray | null = countryConfigKey.match(/^([a-z0-9]+)(\.([a-z0-9\._]+))*$/i);
    if (matches && [
      String(CountryConfigTypeEnum.Setup),
      String(CountryConfigTypeEnum.Website),
      String(CountryConfigTypeEnum.Settings),
      String(CountryConfigTypeEnum.Validators),
      String(CountryConfigTypeEnum.Calculator),
    ].includes(matches[1])) {
      const countryConfigSource: ObjectIteratorInterface<fieldValueType> =
        // @ts-ignore
        countryConfig.variables[matches[1]] ?? countryConfig[matches[1]];
      if (countryConfigSource) {
        return countryConfigSource[matches[3]] ?? null;
      }
    }

    return null;
  }
}
