import { TextField } from '@rehau/shared/forms/elements/fields';
import { EmailValidation, StringValidation } from '@rehau/shared/forms/validations';
import { Autocomplete, FrontendComponentEnum } from '@rehau/shared/enums';
import { InfoBox, Tooltip } from '@rehau/shared/forms/elements';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';

export class EmailField extends TextField {
  constructor(
      id: string,
      name: string,
      label: string | null,
      placeholder: string,
      required: boolean,
      validation: StringValidation = new EmailValidation(),
      countryConfigMapping: ObjectIteratorInterface<string> = {},
      informationBox?: InfoBox,
      tooltip?: Tooltip,
      autocomplete?: Autocomplete
  ) {
    super(
      id,
      name,
      label,
      placeholder,
      required,
      validation,
      countryConfigMapping,
      informationBox,
      tooltip,
      autocomplete
    );
    this.frontendComponent = FrontendComponentEnum.MailField;
  }
}
