import React from 'react';

import { ButtonWithIcon } from '../../../../components/buttonWithIcon/buttonWithIcon.component';
import { Button } from '../../../../components/button/button.component';
import { IconName } from '@rehau/shared/enums';

import { Content, Header, Text, TopSection, TopSectionWithContent, Wrapper } from './slidingPanel.styled';
import { SlidingPanelProps } from './slidingPanel.types';

export const SlidingPanel: React.FC<SlidingPanelProps> = (props: SlidingPanelProps): JSX.Element => {
  const {
    title,
    text,
    actionButtonLabel,
    backButtonLabel,
    isOpen,
    onBackButtonClick,
    children,
    isButtonAttachedToBottom,
  }: SlidingPanelProps = props;

  return (
    <Wrapper
      $isOpen={isOpen}
      $hasButtonOnBottom={isButtonAttachedToBottom}
      aria-label={`drawer-${isOpen ? 'open' : 'closed'}`}
    >
      <TopSectionWithContent>
        <TopSection>
          <ButtonWithIcon
              text={backButtonLabel}
              onClick={onBackButtonClick}
              reversed
              tabIndex={-1}
              icon={IconName.Back}
            />
        </TopSection>
        <Content>
          {!children ? (
            <>
              <Header text={title} tagType='h3' />
              <Text text={text} tagType='p' />
            </>
          ) : (
            children
          )}
        </Content>
      </TopSectionWithContent>
      <Button
        text={actionButtonLabel}
        onClick={onBackButtonClick}
        autoWidth={!isButtonAttachedToBottom}
        fluid={isButtonAttachedToBottom}
        tabIndex={-1}
      />
    </Wrapper>
  );
};
