import styled from 'styled-components';

import { StyledComponent } from '../../../../types/styledComponent.types';
import { Color } from '../../../../enums/color.enum';
import { Markdown } from '../../../../components/markdown/markdown.component';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Logo } from '../../../../components/logo/logo.component';

import { ContainerProps } from './offerHeader.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  --company-color: ${({ $companyColor }: ContainerProps): string => $companyColor};
  border-left: 10px solid ${Color.CompanyColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 45px 0;
  padding-left: 60px;
  padding-right: 70px;
  background-color: ${Color.White};
  border-bottom: 1px solid ${Color.GreyStroke};

  @media ${Breakpoint.Tablet} {
    padding-left: 30px;
    padding-right: 40px;
  }

  @media ${Breakpoint.Mobile} {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 25px 20px;
    border-left: 0;
  }
`;

export const Content: StyledComponent<'div'> = styled.div``;

export const Text: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;

  @media ${Breakpoint.Mobile} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const CompanyLogo: StyledComponent<'img'> = styled.img`
  height: 100px;

  @media ${Breakpoint.Mobile} {
    margin-bottom: 20px;
    height: 38px;
  }
`;

export const StyledLogo: StyledComponent<typeof Logo> = styled(Logo)`
  @media ${Breakpoint.Mobile} {
    margin-bottom: 20px;
    width: 125px;
  }
`;
