import React from 'react';

import { priceWithSeparatorRegexp } from '@rehau/shared/regexps';
import { CurrencyPositionEnum } from '../../../../../../shared/enums/currencyPosition.enum';
import { Config } from '../../../../../../shared/models/countryConfig';
import { useCountryConfig } from '../../hooks/useCountryConfig.hook';
import { UseCountryConfig } from '../../types/useCountryConfig.types';
import { Color } from '../../enums/color.enum';

import { Currency, PriceContainer, PricePrefix, PriceText } from './price.styled';
import { PriceProps } from './price.types';

export const Price: React.FC<PriceProps> = (props: PriceProps): JSX.Element => {
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();
  const { currency }: Config = isConfigAvailable ? config : ({} as Config);
  const {
    price,
    pricePrefix,
    className,
    currencySymbol = currency?.symbol,
    position = currency?.position,
    priceSize,
    pricePrefixSize,
    color = Color.Black,
  }: PriceProps = props;

  return (
    <PriceContainer className={className} data-testid='price'>
      {!!pricePrefix && <PricePrefix data-testid='price-prefix' pricePrefixSize={pricePrefixSize}>{pricePrefix}</PricePrefix>}
      <PriceText priceSize={priceSize} $color={color}>
        {position === CurrencyPositionEnum.Left && (
          <Currency data-testid='currency-left' position={position} $color={color}>
            {currencySymbol}
          </Currency>
        )}
        {String(price).replace(priceWithSeparatorRegexp, ' ')}
        {position === CurrencyPositionEnum.Right && (
          <Currency data-testid='currency-right' position={position} $color={color}>
            {currencySymbol}
          </Currency>
        )}
      </PriceText>
    </PriceContainer>
  );
};
