import React, { useEffect, useState } from 'react';
import useCollapse from 'react-collapsed';
import { UseCollapseOutput } from 'react-collapsed/dist/types';
import { TransProps, useTranslation } from 'react-i18next';

import { DecisionField } from '../../../../../../shared/forms/elements';
import { ValidationBar } from '../validationBar/validationBar.component';
import { UseState } from '../../types/useState.types';
import { ValidationMessages } from '../../../../../../shared/forms/validations';
import { DecisionWithChildrenContainer } from '../../../../../../shared/forms/components';
import { ValidationStatus } from '../../enums/validationStatus.enum';
import { fieldValueType } from '@rehau/shared/forms';

import { DecisionButtonType } from './components/decisionButton/decisionButton.enums';
import { ChildrenVisibilityState, DecisionProps } from './decision.types';
import { ButtonsContainer, ChildrenWrapper, Label } from './decision.styled';
import { DecisionButton } from './components/decisionButton/decisionButton.component';

export const Decision: React.FC<DecisionProps> = (props: DecisionProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    acceptText,
    declineText,
    label,
    children,
    component = new DecisionField('', '', '', false, false, '', ''),
    disabled = ((component as DecisionField).onlyForRead || (component as DecisionField).disabled) ?? false,
    validationMessage = t('rehau.global.resolve_conflicts'),
    setValues = (): void => undefined,
    handleSave = (): void => undefined,
  }: DecisionProps = props;
  const initialValue: fieldValueType = !!children
    ? (component as DecisionWithChildrenContainer).getDecisionField()!.value
    : (component as DecisionField).value;
  const initialActive: DecisionButtonType | null = initialValue !== undefined && initialValue !== null
      ? initialValue
        ? DecisionButtonType.Accept
        : DecisionButtonType.Decline
      : null;
  const [chosenItem, setChosenItem]: UseState<DecisionButtonType | null> = useState<DecisionButtonType | null>(initialActive);
  const [childrenVisibility, setChildrenVisibility]: ChildrenVisibilityState = useState<boolean>(!!initialValue);
  const { getCollapseProps }: UseCollapseOutput = useCollapse({ isExpanded: childrenVisibility });
  const [validationStatus, setValidationStatus]: UseState<ValidationStatus> = useState<ValidationStatus>(ValidationStatus.Untouched);
  const [isChildrenRendered, setIsChildrenRendered]: UseState<boolean> = useState<boolean>(initialValue as boolean || false);

  const handleChange: (localChosenItem: DecisionButtonType) => void = (localChosenItem: DecisionButtonType): void => {
    if (localChosenItem === DecisionButtonType.Accept) {
      setIsChildrenRendered(true);
      setTimeout((): void => setChildrenVisibility(localChosenItem === DecisionButtonType.Accept), 200);
    } else {
      setChildrenVisibility(false);
      setTimeout((): void => setIsChildrenRendered(false), 200);
    }
    let validationSuccess: boolean = false;
    if (!!children) {
      validationSuccess = (component as DecisionWithChildrenContainer)?.getDecisionField()?.validate() || false;
      setValues({ [(component as DecisionWithChildrenContainer).getDecisionField()!.id]: localChosenItem === DecisionButtonType.Accept });
    } else {
      validationSuccess = component.validate();
      setValues({ [(component as DecisionField).id]: localChosenItem === DecisionButtonType.Accept });
    }
    setValidationStatus(validationSuccess ? ValidationStatus.Valid : ValidationStatus.Invalid);
    if (validationSuccess) {
      handleSave();
    }
  };

  useEffect(
    (): void => {
      if (initialValue !== undefined && initialValue !== null) {
        if (initialValue) {
          setChosenItem(DecisionButtonType.Accept);
          setIsChildrenRendered(true);
          setTimeout((): void => setChildrenVisibility(true), 0);
        } else {
          setChosenItem(DecisionButtonType.Decline);
          setChildrenVisibility(false);
          setTimeout((): void => setIsChildrenRendered(false), 0);
        }
      } else {
        setChosenItem(null);
      }
    },
    [initialValue]
  );

  const setChosenItemToAccept: () => void = (): void => {
    setChosenItem(DecisionButtonType.Accept);
    handleChange(DecisionButtonType.Accept);
  };

  const setChosenItemToDecline: () => void = (): void => {
    setChosenItem(DecisionButtonType.Decline);
    handleChange(DecisionButtonType.Decline);
  };

  const validationMessages: ValidationMessages[] = validationStatus === ValidationStatus.Invalid
    ? [{ message: validationMessage as string }]
    : [];

  return (
    <>
      {!!label && <Label>{label}</Label>}
      <ButtonsContainer>
        <DecisionButton
          text={acceptText}
          isActive={chosenItem === DecisionButtonType.Accept}
          onClick={setChosenItemToAccept}
          isUntouched={chosenItem === null}
          disabled={disabled}
        />
        <DecisionButton
          text={declineText}
          buttonType={DecisionButtonType.Decline}
          isActive={chosenItem === DecisionButtonType.Decline}
          onClick={setChosenItemToDecline}
          isUntouched={chosenItem === null}
          disabled={disabled}
        />
      </ButtonsContainer>
      {!!children && (
        <ChildrenWrapper data-testid='childrenWrapper' {...getCollapseProps()}>
          {!!isChildrenRendered && children}
        </ChildrenWrapper>
      )}
      <ValidationBar errorsArray={validationMessages} />
    </>
  );
};
