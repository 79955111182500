import { prop } from 'ramda';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { BoxShadow } from '../../enums/boxShadow.enum';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { ZIndex } from '../../enums/zIndex.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';
import { CloseButton } from '../closeButton/closeButton.component';
import { Button } from '../button/button.component';
import { Markdown } from '../markdown/markdown.component';

import { DescriptionProps, WrapperProps } from './dialogWithIcon.types';

export const Wrapper: StyledComponent<'div', WrapperProps> = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;

  ${styleWhenTrue(
    prop('isOpen'),
    css`
      opacity: 1;
      pointer-events: initial;
    `
  )}
`;

export const Container: StyledComponent<'div'> = styled.div`
  max-width: 500px;
  width: 100%;
  background-color: ${Color.White};
  box-shadow: ${BoxShadow.BtnGreyShadow};
  position: relative;
  margin: 20px 0;
  padding: 50px 18px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: ${ZIndex.DialogWithIcon};

  @media ${Breakpoint.Additional480} {
    margin-left: 22px;
    margin-right: 22px;
    padding: 50px 27px;
  }
`;

export const StyledCloseButton: StyledComponent<typeof CloseButton> = styled(CloseButton)`
  top: 12px;
  right: 12px;
`;

export const IconContainer: StyledComponent<'div'> = styled.div`
  width: 78px;
  height: 78px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Color.GreyStroke};
  margin-bottom: 10px;
`;

export const Title: StyledComponent<'p'> = styled.p`
  font-size: 22px;
  line-height: 26px;
  font-weight: ${FontWeight.Bold};
  word-break: break-word;
  text-align: center;
  margin-bottom: 5px;
`;

export const Description: StyledComponent<typeof Markdown, DescriptionProps> = styled(Markdown)<DescriptionProps>`
  line-height: 24px;
  font-size: 20px;
  color: ${Color.Grey};
  margin-bottom: 20px;
  text-align: center;
  word-break: break-word;
  ${({ customDescriptionTextStyles }: DescriptionProps): FlattenSimpleInterpolation | false => customDescriptionTextStyles
    ? css`${customDescriptionTextStyles}`
    : false};

  strong {
    font-weight: ${FontWeight.Bold};
    color: ${Color.Black};
  }
`;

export const ButtonsContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${Breakpoint.Additional480} {
    flex-direction: column;
    width: 100%;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  :not(:last-child) {
    margin-right: 10px;
  }

  @media ${Breakpoint.Additional480} {
    width: 100%;
    :not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;
