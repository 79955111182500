import React, { useState } from 'react';

import { ClickNumberWithIconField } from '@rehau/shared/forms/elements';
import { IconName } from '../../../../../../shared/enums';
import { UseState } from '../../types/useState.types';
import { Icon } from '../icon/icon.component';
import { Tooltip } from '../tooltip/tooltip.component';
import { Color } from '../../enums/color.enum';

import { Button, Container, Counter, CounterContainer, CounterIcon, IconLabelContainer, Label, Wrapper } from './quantityBar.styled';
import { QuantityBarProps } from './quantityBar.types';

export const QuantityBar: React.FC<QuantityBarProps> = (props: QuantityBarProps): JSX.Element => {
  const {
    icon,
    label,
    initialQuantity,
    minValue = 0,
    maxValue = 10,
    tooltip,
    component = new ClickNumberWithIconField('', '', '', true, IconName.DefaultIcon),
    handleSave = (): void => undefined,
    setValues = (): void => undefined,
  }: QuantityBarProps = props;
  const [quantity, setQuantity]: UseState<number> = useState<number>(component.value
    ? component.value
    : initialQuantity ?? minValue
  );

  const add: () => void = (): void => {
    if (quantity < maxValue) {
      saveValue(quantity + 1);
      setQuantity(quantity + 1);
    }
  };

  const subtract: () => void = (): void => {
    if (quantity > minValue) {
      saveValue(quantity - 1);
      setQuantity(quantity - 1);
    }
  };

  const saveValue: (value: number) => void = (value: number): void => {
    setValues({ [component.id]: value });
    const validationSuccess: boolean = component.validate();
    if (validationSuccess) {
      handleSave();
    }
  };

  return (
    <Container>
      <Wrapper isActive={quantity > minValue} hasTooltip={!!tooltip}>
        <IconLabelContainer>
          <Icon name={icon} size={50} color={quantity > minValue ? Color.Black : Color.Grey} />
          <Label>{label}</Label>
        </IconLabelContainer>
        <CounterContainer>
          <Button onClick={subtract} data-testid='subtract'>
            <CounterIcon name={IconName.Minus} size={16} isActive={quantity !== minValue} />
          </Button>
          <Counter data-testid='counter'>{quantity}</Counter>
          <Button onClick={add} data-testid='add'>
            <CounterIcon name={IconName.Plus} size={16} isActive={quantity > minValue && quantity !== maxValue} />
          </Button>
        </CounterContainer>
      </Wrapper>
      {!!tooltip && <Tooltip tooltipSettings={tooltip} />}
    </Container>
  );
};
