import React from 'react';

import { Title } from '../title/title.component';

import {
  Button,
  Container,
  Link,
  StyledMarkdown,
  Wrapper,
} from './simpleText.styled';
import { SimpleTextProps } from './simpleText.types';

export const SimpleText: React.FC<SimpleTextProps> = (props: SimpleTextProps): JSX.Element => {
  const {
    className,
    title,
    text,
    buttonLink,
    buttonText = '',
  }: SimpleTextProps = props;

  return (
    <Container className={className}>
      <Wrapper>
        {!!title && <Title data-testid='simple-text-title'>{title}</Title>}
        <StyledMarkdown text={text} />
        {!!buttonLink && (
          <Link href={buttonLink} target='_blank' rel='noopener noreferrer' data-testid='external-link'>
            <Button text={buttonText} />
          </Link>
        )}
      </Wrapper>
    </Container>
  );
};
