import React, { useCallback, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { DecisionField, InfoBox } from '../../../../../../../shared/forms/elements';
import { FieldElement, FieldElementProps } from '../types';
import { componentTypes, fieldTypes, staticElementTypes } from '../../../../../../../shared/forms/types';
import { FieldComponent, FieldComponentWrapper } from '../fieldComponent.component';
import { ConditionalExpandedBox } from '../../conditionalExpandedBox/conditionalExpandedBox.component';
import { VariantContainer } from '../../../../../../../shared/forms/components';
import { SmallIdContainer } from '../fieldComponent.styled';
import { UseState } from '../../../types/useState.types';
import { getFieldContainerType } from '../utils/getFieldContainerType';

export const VariantContainerFieldElement: FieldElement<VariantContainer> = (props: FieldElementProps<VariantContainer>): JSX.Element => {
  const { component, saveMethod, setValues }: FieldElementProps<VariantContainer> = props;
  const { t }: TransProps<never> = useTranslation();
  const children: (fieldTypes | componentTypes | staticElementTypes)[] = component.getChildrenContainer();
  const decision: DecisionField = component.getDecisionField() as DecisionField;
  // temporary hack to rerender parent from child (VariantContainerFieldElement from ConditionalExpandedBox)
  const [isVariantActive, setIsVariantActive]: UseState<boolean> = useState<boolean>(!!component.getDecisionField()?.value || false);
  const handleSave: () => void = useCallback(
    (): void => {
      if (!!saveMethod && !!component.getDecisionField()) {
        saveMethod({ [component.getDecisionField()!.id]: component.getDecisionField()?.value || false });
      }
    },
    [saveMethod]
  );

  return (
    <>
      {!!JSON.parse(process.env.REACT_APP_SHOW_FIELDS_ID || 'false') && (
        <SmallIdContainer>DECISION ID: {decision.id}</SmallIdContainer>
      )}
      <ConditionalExpandedBox
        icon={component.icon}
        label={t(component.title)}
        switchLabel={t(component.enableActionLabel || '')}
        component={component}
        handleSave={handleSave}
        setValues={setValues}
        onChange={setIsVariantActive}
        infoBoxLabel={!isVariantActive ? component.informationBox?.content : undefined}
      >
        {children.map((innerSimpleComponent: fieldTypes | componentTypes | staticElementTypes, index: number): JSX.Element =>
          <FieldComponentWrapper className={getFieldContainerType(innerSimpleComponent)} key={`${innerSimpleComponent.frontendComponent}_${index}`}>
            <FieldComponent innerComponent={innerSimpleComponent} saveMethod={saveMethod} setValues={setValues} />
          </FieldComponentWrapper>
        )}
      </ConditionalExpandedBox>
    </>
  );
};
