import styled, { StyledComponent } from 'styled-components';

import { FontWeight } from '../../enums/fontWeight.enum';
import { InputContainer } from '../input/inputContainer/inputContainer.component';

import Drop from './images/drop.svg';

export const DropdownContainer: StyledComponent<typeof InputContainer, {}, {}, never> = styled(InputContainer)`
  position: relative;
`;

export const Select: StyledComponent<'select', {}, {}, never> = styled.select`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  padding: 0 15px;
  background-image: ${`url(${Drop})`};
  background-repeat: no-repeat;
  background-position-x: calc(100%);
  background-position-y: 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: 18px;
  font-weight: ${FontWeight.Bold};
  border-radius: 0;

  :focus {
    outline: none;
  }

  select::-ms-expand {
    display: none;
  }
`;

export const Option: StyledComponent<'option', {}, {}, never> = styled.option`
  font-weight: ${FontWeight.Bold};
  font-size: 15px;
  line-height: 21.5px;
  /* stylelint-disable declaration-no-important */
  font-family: Helvetica, sans-serif !important;
`;
