import { componentTypes, fieldTypes, staticElementTypes } from '@rehau/shared/forms/types';
import { AbstractContainer, MixedContainer } from '@rehau/shared/forms/components';
import { FormContainerTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { AbstractField, DecisionField, InfoBox } from '@rehau/shared/forms/elements';
import { Config } from '@rehau/shared/models/countryConfig';

export class DecisionWithChildrenContainer extends MixedContainer {
  public constructor(
    title: string,
    elements: (fieldTypes | componentTypes)[] = [],
    countryConfig?: Config,
    informationBox?: InfoBox,
    subTitle?: string,
    clearIfDecisionNegative?: boolean
  ) {
    super(
      FrontendComponentEnum.DecisionWithChildren,
      title,
      elements,
      countryConfig,
      informationBox,
      subTitle,
      clearIfDecisionNegative,
      false
    );
    this.type = FormContainerTypeEnum.DecisionWithChildrenContainer;
  }

  public validate(withInactive: boolean = false): boolean {
    if ((this.getDecisionField()?.value !== false) || withInactive) {
      this.shouldRunValidationOnDecisionTrue = true;
      if (this.getDecisionField()?.value === null) {
        this.valid = false;

        return this.valid;
      }

      return super.validate();
    }
    this.valid = true;

    return this.valid;
  }

  public getChildrenFields(): fieldTypes[] {
    return this.getFields(
      this.elements.filter((element: fieldTypes | componentTypes | staticElementTypes): boolean =>
        !(element instanceof DecisionField))
    );
  }

  protected getFields(elements: (fieldTypes | componentTypes | staticElementTypes)[]): fieldTypes[] {
    const fields: fieldTypes[] = [];
    for (const element of elements) {
      if (element instanceof AbstractField) {
        fields.push(element);
      }
      if (element instanceof AbstractContainer && !(element instanceof DecisionWithChildrenContainer)) {
        fields.push(...this.getFields(element.elements));
      }
      if (element instanceof DecisionWithChildrenContainer) {
        const decisionField: DecisionField | null = this.getDecisionField();
        if (decisionField) {
          fields.push(decisionField);
        }
      }
    }

    return fields;
  }
}
