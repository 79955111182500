import React from 'react';

import { Color } from '../../enums/color.enum';

import { Loader, LoaderContainer } from './circleLoader.styled';
import { CircleLoaderProps } from './circleLoader.types';

export const CircleLoader: React.FC<CircleLoaderProps> = (props: CircleLoaderProps): JSX.Element => {
  const { color = Color.Black, className, hasBiggerSize = false }: CircleLoaderProps = props;

  return (
    <LoaderContainer className={className} hasBiggerSize={hasBiggerSize}>
      <Loader color={color} data-testid='circle-loader' hasBiggerSize={hasBiggerSize} />
    </LoaderContainer>
  );
};
