import { useContext } from 'react';

import { AlertProps } from '../components/alert/alert.types';
import { DialogProps } from '../components/dialog/dialog.types';
import { SmallDialogProps } from '../components/smallDialog/smallDialog.types';
import { AlertsDispatchContext } from '../contexts/alerts.context';
import { UseAlertsAction } from '../enums/useAlertsAction.enums';
import { UseAlerts } from '../types/useAlerts.types';
import { UseAlertContext } from '../types/useAlertContext.types';
import { DialogWithIconProps } from '../components/dialogWithIcon/dialogWithIcon.types';
import { DialogWithFormProps } from '../components/dialogWithForm/dialogWithForm.types';

export const useAlerts: () => UseAlerts = (): UseAlerts => {
  const dispatch: UseAlertContext = useContext(AlertsDispatchContext);

  const showDialog: (dialogData: DialogProps, notCloseAfterAccept?: boolean) => void = (
    dialogData: DialogProps, notCloseAfterAccept: boolean = false
  ): void => {
    const accept: () => void = (): void => {
      if (dialogData.acceptButton?.handler) {
        dialogData.acceptButton.handler();
      }
      if (notCloseAfterAccept) {
        dispatch({ type: UseAlertsAction.SetDialogAcceptButtonLoadingStatus, payload: true });
      } else {
        dispatch({ type: UseAlertsAction.SetDialog, payload: { ...dialogData, isActive: false } });
      }
    };
    const cancel: () => void = (): void => {
      if (dialogData.cancelButton?.handler) {
        dialogData.cancelButton.handler();
      }
      dispatch({ type: UseAlertsAction.SetDialog, payload: { ...dialogData, isActive: false } });
    };

    const buttonWithIconAccept: () => void = (): void => {
      if (dialogData.buttonWithIcon?.handler) {
        dialogData.buttonWithIcon.handler();
      }
      dispatch({ type: UseAlertsAction.SetDialog, payload: { ...dialogData, isActive: false } });
    };

    const cancelIconAction: () => void = (): void => {
      if (dialogData.closeIconAction) {
        dialogData.closeIconAction();
      }

      dispatch({ type: UseAlertsAction.SetDialogActiveStatus, payload: false });
    };

    dispatch({ type: UseAlertsAction.SetDialog, payload: {
      ...dialogData,
      isActive: true,
      acceptButton: {
        ...dialogData.acceptButton,
        handler: accept,
      },
      cancelButton: {
        ...dialogData.cancelButton,
        handler: cancel,
      },
      buttonWithIcon: {
        ...dialogData.buttonWithIcon,
        handler: buttonWithIconAccept,
      },
      closeIconAction: dialogData.closeIconAction ? cancelIconAction : undefined,
    }});
  };

  const showDialogWithIcon: (dialogWithIconData: DialogWithIconProps) => void = (dialogWithIconData: DialogWithIconProps): void => {
    const accept: () => void = (): void => {
      if (dialogWithIconData.acceptHandler) {
        dialogWithIconData.acceptHandler();
      }
      dispatch({ type: UseAlertsAction.SetDialogWithIcon, payload: { ...dialogWithIconData, isOpen: false } });
    };
    const cancel: () => void = (): void => {
      if (dialogWithIconData.cancelHandler) {
        dialogWithIconData.cancelHandler();
      }
      dispatch({ type: UseAlertsAction.SetDialogWithIcon, payload: { ...dialogWithIconData, isOpen: false } });
    };

    dispatch({ type: UseAlertsAction.SetDialogWithIcon, payload: {
      ...dialogWithIconData,
      isOpen: true,
      acceptHandler: accept,
      cancelHandler: cancel,
    }});
  };

  const showDialogWithForm: (dialogWithFormData: DialogWithFormProps) => void = (dialogWithFormData: DialogWithFormProps): void => {
    const accept: () => void = (): void => {
      if (dialogWithFormData.acceptHandler) {
        dialogWithFormData.acceptHandler();
      }
      dispatch({ type: UseAlertsAction.SetDialogWithForm, payload: { ...dialogWithFormData, isActive: false, form: null } });
    };
    const cancel: () => void = (): void => {
      if (dialogWithFormData.cancelHandler) {
        dialogWithFormData.cancelHandler();
      }
      dispatch({ type: UseAlertsAction.SetDialogWithForm, payload: { ...dialogWithFormData, isActive: false, form: null } });
    };

    dispatch({ type: UseAlertsAction.SetDialogWithForm, payload: {
      ...dialogWithFormData,
      isActive: true,
      acceptHandler: accept,
      cancelHandler: cancel,
    }});
  };

  const showSmallDialog: (smallDialogData: SmallDialogProps) => void = (smallDialogData: SmallDialogProps): void => {
    const accept: () => void = (): void => {
      if (smallDialogData.acceptActionCallback) {
        smallDialogData.acceptActionCallback();
      }
      dispatch({ type: UseAlertsAction.SetSmallDialog, payload: { ...smallDialogData, isActive: false } });
    };
    const cancel: () => void = (): void => {
      if (smallDialogData.cancelActionCallback) {
        smallDialogData.cancelActionCallback();
      }
      dispatch({ type: UseAlertsAction.SetSmallDialog, payload: { ...smallDialogData, isActive: false } });
    };

    dispatch({ type: UseAlertsAction.SetSmallDialog, payload: {
      ...smallDialogData,
      isActive: true,
      acceptActionCallback: accept,
      cancelActionCallback: cancel,
    }});
  };

  const hideSmallDialog: () => void = (): void => {
    dispatch({ type: UseAlertsAction.CloseSmallDialog, payload: null });
  };

  const showAlert: (alertData: AlertProps) => void = (alertData: AlertProps): void => {
    dispatch({ type: UseAlertsAction.SetAlert, payload: alertData });
  };

  const clearAlerts: () => void = (): void => {
    dispatch({ type: UseAlertsAction.ClearAlerts, payload: {} });
  };

  const closeDialog: () => void = (): void => {
    dispatch({ type: UseAlertsAction.SetDialogAcceptButtonLoadingStatus, payload: false });
    dispatch({ type: UseAlertsAction.SetDialogActiveStatus, payload: false });
  };

  return {
    showDialog,
    showDialogWithIcon,
    showDialogWithForm,
    showSmallDialog,
    showAlert,
    clearAlerts,
    hideSmallDialog,
    closeDialog,
  };
};
