import { prop, propEq } from 'ramda';
import styled, { css, FlattenSimpleInterpolation, StyledComponent } from 'styled-components';

import { Icon } from '../../../icon/icon.component';
import { Color } from '../../../../enums/color.enum';
import { styleWhenTrue } from '../../../../utils/style.utils';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { BoxShadow } from '../../../../enums/boxShadow.enum';
import { Breakpoint } from '../../../../enums/breakpoint.enum';

import { DecisionButtonType } from './decisionButton.enums';
import { ButtonTypes, ConfirmationStatusType } from './decisionButton.types';

export const ButtonContainer: StyledComponent<'button', {}, ButtonTypes, never> = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  outline: none;
  border: 1px solid ${Color.GreyStroke};
  min-width: 165px;
  transition: 0.3s;
  margin-right: 10px;
  height: 44px;
  ${styleWhenTrue(
    propEq('isUntouched', false),
    css`
      border: 1px solid ${Color.BgGrey};
      background-color: ${Color.BgGrey};
    `
  )}
  ${styleWhenTrue(
    prop('isActive'),
    css`
      border: 1px solid ${Color.Green};
      background-color: ${Color.Green};
      box-shadow: ${BoxShadow.BtnGreenShadow};
    `
  )}
  ${({ buttonType, isActive }: ButtonTypes): FlattenSimpleInterpolation | null =>
    buttonType === DecisionButtonType.Decline && isActive
      ? css`
          border: 1px solid ${Color.Black};
          background-color: ${Color.Black};
          box-shadow: ${BoxShadow.BtnGreyShadow};
        `
      : null}

  @media ${Breakpoint.Additional480} {
    min-width: 145px;
  }
`;

export const Text: StyledComponent<'p', {}, ConfirmationStatusType, never> = styled.p`
  color: ${Color.Grey};
  font-weight: ${FontWeight.Black};
  font-size: 15px;
  transition: 0.3s;
  letter-spacing: 0.4px;
  ${styleWhenTrue(
    prop('isActive'),
    css`
      color: ${Color.White};
    `
  )}
`;

export const LocalIcon: StyledComponent<typeof Icon, {}, ConfirmationStatusType, never> = styled(Icon)`
  color: ${Color.Grey};
  ${styleWhenTrue(
    prop('isActive'),
    css`
      color: ${Color.White};
    `
  )}
`;
