import styled, { css, StyledComponent } from 'styled-components';
import { prop } from 'ramda';

import { styleWhenTrue } from '../../../../utils/style.utils';
import { Color } from '../../../../enums/color.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { ZIndex } from '../../../../enums/zIndex.enum';
import { Icon } from '../../../icon/icon.component';
import { Button as ButtonBase } from '../../../button/button.component';
import { ItemGroup as ItemGroupBase } from '../itemGroup/itemGroup.component';
import { FooterHeight } from '../../../../enums/footerHeight.enum';
import { GridWidth } from '../../../../enums/gridWidth.enum';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { FooterBottomBar } from '../../../../components/footerBottomBar/footerBottomBar.component';

import { ContainerProps } from './itemModal.types';

export const Container: StyledComponent<'div', {}, ContainerProps, never> = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  transition: 0.3s;
  opacity: 0;
  z-index: ${ZIndex.Hidden};
  pointer-events: none;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Color.White};
  ${styleWhenTrue(prop('open'), css`
    opacity: 1;
    z-index: ${ZIndex.MainNavbar};
    pointer-events: auto;
  `)}
`;

export const CloseIcon: StyledComponent<typeof Icon, {}, {}, never> = styled(Icon)`
  transition: 0.3s;
`;

export const CloseButton: StyledComponent<'button', {}, {}, never> = styled.button`
  width: 58px;
  height: 58px;
  background-color: ${Color.Green};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
`;

export const Positions: StyledComponent<'div', {}, {}, never> = styled.div`
  flex: 1;
  padding: 80px 0;
  flex-shrink: 0;
  background-color: ${Color.White};
  overflow-y: auto;
  width: 50%;
  max-width: 450px;
`;

export const Content: StyledComponent<'div', {}, {}, never> = styled.div`
  display: flex;
  height: calc(100% - ${FooterHeight.FooterBottomBarDesktop});
  max-width: ${GridWidth.Desktop};
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;

  @media ${Breakpoint.Desktop} {
    padding: 0 60px;
    width: calc(100% - 120px);
  }
  @media ${Breakpoint.Tablet} {
    padding: 0 40px;
    width: calc(100% - 80px);
  }
  @media ${Breakpoint.Mobile} {
    padding: 25px 22px;
    width: calc(100% - 44px);
    box-sizing: border-box;
  }
`;

export const ImageWrapper: StyledComponent<'div', {}, {}, never> = styled.div`
  background-color: ${Color.White};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 50%;
`;

export const Image: StyledComponent<'img', {}, {}, never> = styled.img`
  width: 100%;
  max-width: 455px;
`;

export const Header: StyledComponent<'h3', {}, {}, never> = styled.h3`
  font-size: 24px;
  line-height: 29px;
  font-weight: ${FontWeight.Black};
`;

export const ItemGroup: StyledComponent<typeof ItemGroupBase, {}, {}, never> = styled(ItemGroupBase)`
  margin-top: 25px;
  & + & {
    margin-top: 40px;
  }
`;

export const Button: StyledComponent<typeof ButtonBase, {}, {}, never> = styled(ButtonBase)`
  position: absolute;
  right: 40px;
  bottom: 40px;
`;

export const StyledFooterBottomBar: StyledComponent<typeof FooterBottomBar, {}, {}, never> = styled(FooterBottomBar)`
  bottom: 0;
`;
