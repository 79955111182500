import styled, { StyledComponent } from 'styled-components';

import { Icon } from '../../icon/icon.component';
import { Color } from '../../..';

export const StyledIcon: StyledComponent<typeof Icon, {}, {}, never> = styled(Icon)`
  margin-right: 12px;
  color: ${Color.Green};
`;

export const IconWrapper: StyledComponent<'div', {}, {}, never> = styled.div`
  display: flex;
  align-items: center;
`;
