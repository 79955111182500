import React from 'react';

import { Container, Label } from './simpleDescriptionBoxInHorizontal.styled';
import { SimpleDescriptionBoxInHorizontalProps } from './simpleDescriptionBoxInHorizontal.types';

export const SimpleDescriptionBoxInHorizontal: React.FC<SimpleDescriptionBoxInHorizontalProps> = (
  props: SimpleDescriptionBoxInHorizontalProps
): JSX.Element => {
  const { text, label }: SimpleDescriptionBoxInHorizontalProps = props;

  return (
    <>
      <Label>{label}</Label>
      <Container text={text} />
    </>
  );
};
