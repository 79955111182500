import styled from 'styled-components';

import { StyledComponent } from '../../types/styledComponent.types';
import { DecisionButton } from '../decision/components/decisionButton/decisionButton.component';
import { BaseLabel } from '../../theme';

export const StyledDecisionButton: StyledComponent<typeof DecisionButton> = styled(DecisionButton)``;

export const Label: StyledComponent<typeof BaseLabel> = styled(BaseLabel)`
  margin-bottom: 12px;
`;

export const DecisionButtonsContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  ${StyledDecisionButton} + ${StyledDecisionButton} {
    margin-left: 10px;
  }
`;

export const VariantsContainer: StyledComponent<'div'> = styled.div`
  margin-top: 10px;
`;
