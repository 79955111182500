export enum VariantCategoryEnum {
  // heat installation
  HeatDistribution = 'heatDistribution',
  Regulation = 'regulation',
  VentilationSystems = 'ventilationSystems',
  // water distribution
  DrinkingWaterDistribution = 'drinkingWaterDistribution',
  // smart home
  Resource = 'resource',
  Reguard = 'reguard',
  // sewage
  Sewage = 'sewage',
}
