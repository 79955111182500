import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { IconName } from '../../../../../../shared/enums/iconName.enum';
import { Color } from '../../enums/color.enum';
import { Icon } from '../icon/icon.component';
import { CheckboxField } from '../../../../../../shared/forms/elements';
import { ValidationBar } from '../validationBar/validationBar.component';
import { Markdown } from '../markdown/markdown.component';
import { AdditionalInfo } from '../additionalInfo/additionalInfo.component';
import { useEffectAfterMount } from '../../hooks/useEffectAfterMount';
import { InfoBoxPositionEnum } from '@rehau/shared/enums';

import { CheckboxProps, IsCheckedState } from './checkbox.types';
import { Button, ClickableField, Label, TransparentSpanElement, Wrapper } from './checkbox.styled';

export const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    label,
    checked,
    additionalInfoContent,
    additionalInfoContentInReadMore,
    additionalInfoPosition = InfoBoxPositionEnum.Top,
    childrenParts,
    disabled = false,
    component = new CheckboxField('', '', '', true, false),
    setValues = (): void => undefined,
    handleSave = (): void => undefined,
    required = false,
  }: CheckboxProps = props;
  const [isChecked, setIsChecked]: IsCheckedState = useState<boolean>(checked);
  const handleClick: () => void = (): void => {
    if (disabled) {
      return;
    }

    setValues({ [component.id]: !isChecked });
    setIsChecked(!isChecked);
    const validationSuccess: boolean = component.validate();
    if (validationSuccess) {
      handleSave();
    }
  };

  useEffectAfterMount(
    (): void => {
      if (component.valid === undefined) {
        setIsChecked(false);
      }
    },
    [component.valid]
  );

  return (
    <>
      {!!additionalInfoContent && additionalInfoPosition === InfoBoxPositionEnum.Top && (
        <AdditionalInfo
          content={additionalInfoContent}
          contentInReadMore={additionalInfoContentInReadMore}
          position={additionalInfoPosition}
        />
      )}
      <Wrapper>
        <Button isDisabled={disabled} isChecked={isChecked} onClick={handleClick}>
          <Icon size={10} name={IconName.Check} color={Color.White} />
          <ClickableField />
        </Button>
        <Label>
          {!!childrenParts ? (
            <>
              <TransparentSpanElement>{childrenParts.begin}</TransparentSpanElement>
              {childrenParts.middle}
              <TransparentSpanElement>{childrenParts.end}{required && '*'}</TransparentSpanElement>
            </>
          ) : (
            <Markdown text={`${label}${required ? ' *' : ''}`} tagType='span' />
          )}
        </Label>
      </Wrapper>
      <ValidationBar errorsArray={component.validationMessages} />
      {!!additionalInfoContent && additionalInfoPosition === InfoBoxPositionEnum.Bottom && (
        <AdditionalInfo
          content={additionalInfoContent}
          contentInReadMore={additionalInfoContentInReadMore}
          position={additionalInfoPosition}
        />
      )}
    </>
  );
};
