import React from 'react';

import { Color } from '../../enums/color.enum';

import { StyledIcon } from './icon.styled';
import { IconProps } from './icon.types';

export const Icon: React.FC<IconProps> = (props: IconProps): JSX.Element => {
  const {
    name,
    size,
    color = Color.Black,
    className,
  }: IconProps = props;

  return (
    <StyledIcon
      className={`${name} ${className}`}
      size={size}
      color={color}
      data-testid='icon'
    />
  );
};
