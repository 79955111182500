import { NumberValidation } from '@rehau/shared/forms/validations/number.validation';
import { ErrorMessageEnum } from '@rehau/shared/enums';

export class AreaValidation extends NumberValidation {
  constructor(
    minValue?: number,
    maxValue?: number,
    minValueMessage?: ErrorMessageEnum,
    maxValueMessage?: ErrorMessageEnum,
    isRequiredMessage?: ErrorMessageEnum
  ) {
    super(minValue ?? 0, maxValue ?? 1000, minValueMessage, maxValueMessage, isRequiredMessage);
  }
}
