import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { TransProps, useTranslation } from 'react-i18next';

import { Icon } from '../icon/icon.component';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { useCountryConfig } from '../../hooks/useCountryConfig.hook';
import {
  ProductTable as ProductTableType,
  ProductTableAnnotation,
  ProductTableColumn,
  ProductTableRow,
  ProductTableRowData,
} from '../../../../../../shared/models/countryConfig';
import { ProductTableRowFormat } from '../../../../../../shared/enums';
import { UseCountryConfig } from '../../types/useCountryConfig.types';
import { Color } from '../../enums/color.enum';
import { CircleLoader } from '../circleLoader/circleLoader.component';

import { getColor } from './utils/getColor';
import { ProductTableProps } from './productTable.types';
import {
  AnnotationIndex,
  AnnotationsContainer,
  AnnotationText,
  ColumnHeader,
  ColumnLabel,
  ContentContainer,
  LoaderContainer,
  RowLabel,
  Table,
  TableBody,
  TableHead,
  Td,
  Th,
  Tr,
  Wrapper,
} from './productTable.styled';
import { CellContent } from './components/cellContent/cellContent.component';
import { reduceAnnotations } from './utils/reduceAnnotations';

export const ProductTable: React.FC<ProductTableProps> = (props: ProductTableProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { tableType, productData, tableTitle }: ProductTableProps = props;
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();
  const productTable: ProductTableType = isConfigAvailable ? config.productTables[tableType] : {} as ProductTableType;
  const { columns, rows, annotations }: ProductTableType = productData || productTable;

  if (!columns || !rows) {
    return (
      <LoaderContainer data-testid='product-table-loader'>
        <CircleLoader />
      </LoaderContainer>
    );
  }

  return (
    <Wrapper>
      <Table>
        <TableHead>
          <Tr isFirst={true}>
            <Th columnsQuantity={columns.length} data-testid='column'>
              <ColumnHeader isFirst>
                <ColumnLabel isHeader>{tableTitle}</ColumnLabel>
              </ColumnHeader>
            </Th>
            {columns.map(
              ({ label, icon }: ProductTableColumn): JSX.Element => (
                <Th columnsQuantity={columns.length} data-testid='column' key={label}>
                  <ColumnHeader>
                    {!!icon && <Icon name={icon} size={isMobile ? 35 : 60} />}
                    <ColumnLabel>{t(label)}</ColumnLabel>
                  </ColumnHeader>
                </Th>
              )
            )}
          </Tr>
        </TableHead>
        <TableBody>
          {rows.map(
            (row: ProductTableRow): JSX.Element => (
              <Tr key={row.name}>
                <Td data-testid='row'>
                  <RowLabel>
                    {t(row.label)}
                    {!!row.annotations &&
                      reduceAnnotations(annotations, row.annotations).map(
                        (value: number, itemIndex: number, arr: number[]): JSX.Element => (
                          <AnnotationIndex key={value}>
                            {value + 1}
                            {itemIndex + 1 === arr.length ? '' : ','}
                          </AnnotationIndex>
                        )
                      )}
                  </RowLabel>
                </Td>
                {row.data.map(
                  ({ value, name }: ProductTableRowData): JSX.Element => (
                    <Td key={name} videoContainer={row.format === ProductTableRowFormat.Video}>
                      <ContentContainer>
                        <CellContent color={getColor(columns, name)} format={row.format} value={value} />
                      </ContentContainer>
                    </Td>
                  )
                )}
              </Tr>
            )
          )}
        </TableBody>
      </Table>
      <AnnotationsContainer>
        {annotations?.map(
          (annotation: ProductTableAnnotation, index: number): JSX.Element => (
            <AnnotationText key={annotation.name} data-testid='annotation'>
              {index + 1}. {t(annotation.value)}
            </AnnotationText>
          )
        )}
      </AnnotationsContainer>
    </Wrapper>
  );
};
