import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { ClickNumberField } from '../../../../../../../shared/forms/elements';
import { FieldElement, FieldElementProps } from '../types';
import { QuantityInput } from '../../quantityInput/quantityInput.component';

export const ClickNumberFieldElement: FieldElement<ClickNumberField> = (props: FieldElementProps<ClickNumberField>): JSX.Element => {
  const { component, save, setValues }: FieldElementProps<ClickNumberField> = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <QuantityInput
      label={t(component.label || '')}
      max={component.unlimitedMaxValue ? undefined : component.validation?.rules.maxValue}
      initValue={component.value as number}
      sign={component.valueSuffix}
      handleSave={save}
      component={component}
      setValues={setValues}
      tooltipProps={component.tooltip}
   />
  );
};
