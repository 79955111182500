import styled from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { GridWidth } from '../../enums/gridWidth.enum';
import { CustomLink } from '../customLink/customLink.component';
import { BoxShadow } from '../../enums/boxShadow.enum';
import { FooterHeight } from '../../enums/footerHeight.enum';
import { ZIndex } from '../../enums/zIndex.enum';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${FooterHeight.FooterBottomBarDesktop};
  box-shadow: ${BoxShadow.BtnGreyShadow};
  width: 100%;
  z-index: ${ZIndex.Minimum};
  background-color: ${Color.White};
  position: absolute;
  flex-shrink: 0;
`;

export const BottomBar: StyledComponent<'div'> = styled.div`
  display: flex;
  max-width: ${GridWidth.Desktop};
  width: 100%;

  @media ${Breakpoint.Desktop} {
    padding: 0 60px;
  }
  @media ${Breakpoint.Tablet} {
    padding: 0 40px;
  }
  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 25px 22px;
    box-sizing: border-box;
  }
`;

export const BottomLink: StyledComponent<typeof CustomLink> = styled(CustomLink)`
  text-decoration: none;
  letter-spacing: 0.228571px;
  font-size: 16px;
  line-height: 28px;
  color: ${Color.Black};
  transition: color .2s ease-in-out 0s;

  & + & {
    margin-left: 26px;

    @media ${Breakpoint.Mobile} {
      margin-top: 20px;
      margin-left: 0;
    }
  }

  :hover {
    color: ${Color.Pink};
  }
`;
