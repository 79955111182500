import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { IconName } from '../../../../../../../shared/enums/iconName.enum';
import { Tooltip } from '../../tooltip/tooltip.component';
import { StyledPrice } from '../select.styled';
import { SelectItemField } from '@rehau/shared/forms/elements';
import { UseState } from '../../../types/useState.types';
import { Color } from '../../../enums/color.enum';

import { MultiSelectItemProps } from './multiSelectItem.types';
import { Button, CheckIcon, CheckIconContainer, Container, Description, IncludedPriceLabel, Label, StyledTooltipContainer } from './multiSelectItem.styled';

export const MultiSelectItem: React.FC<MultiSelectItemProps> = (props: MultiSelectItemProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    label,
    tooltip,
    price,
    component = new SelectItemField('', '', '', 0, false),
    handleSave = (): void => undefined,
    setValues = (): void => undefined,
    includedLabel = t('rehau.global.included'),
  }: MultiSelectItemProps = props;
  const [isSelected, setIsSelected]: UseState<boolean> = useState<boolean>(component.value as boolean);

  const onSelectOption: () => void = (): void => {
    setValues({ [component.id]: !isSelected });
    setIsSelected(!isSelected);
    const validationSuccess: boolean = component.validate();
    if (validationSuccess) {
      handleSave();
    }
  };

  return (
    <Container>
      <Button onClick={onSelectOption} isActive={isSelected}>
        <CheckIconContainer>
          <CheckIcon name={IconName.Check} size={12} color={Color.White} />
        </CheckIconContainer>
        <Label>{t(label)}</Label>
      </Button>
      <Description>
        {!!price && <StyledPrice price={price} priceSize={13} />}
        {price === 0 && <IncludedPriceLabel>{includedLabel}</IncludedPriceLabel>}
        <StyledTooltipContainer>{!!tooltip && <Tooltip tooltipSettings={tooltip} />}</StyledTooltipContainer>
      </Description>
    </Container>
  );
};
