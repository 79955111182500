import { BaseAbstractContainer } from '@rehau/shared/forms/baseAbstract.container';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { componentTypes } from '@rehau/shared/forms/types';
import { Autocomplete, FormRegisterTypeEnum, FrontendComponentEnum, InstallerAgreementsTypeEnum } from '@rehau/shared/enums';
import { FieldsContainer, MixedContainer, PasswordContainer } from '@rehau/shared/forms/components';
import { CheckboxField, EmailField, PhoneField, TextField } from '@rehau/shared/forms/elements';
import { Config } from '@rehau/shared/models/countryConfig';

export class RegisterForm extends BaseAbstractContainer {
  public elements: componentTypes[];
  public type: FormRegisterTypeEnum;
  public frontendComponent: FrontendComponentEnum = FrontendComponentEnum.Setup;

  constructor(
    values: ObjectIteratorInterface,
    countryConfig?: Config
  ) {
    super();
    this.elements = [
      new MixedContainer(
        FrontendComponentEnum.HorizontalFieldline,
        'rehau.website.register.data_for_registration',
        [
          new TextField(
            'firstName',
            'firstName',
            'rehau.website.register.first_name',
            '',
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            Autocomplete.GivenName
          ),
          new TextField(
            'lastName',
            'lastName',
            'rehau.website.register.last_name',
            '',
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            Autocomplete.FamilyName
          ),
          new PhoneField(
            'phone',
            'phone',
            'rehau.website.register.phone',
            '',
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            Autocomplete.Phone
          ),
          new EmailField(
            'email',
            'email',
            'rehau.website.register.email',
            '',
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            Autocomplete.Email
          ),
          new PasswordContainer(
            'password',
            'repeatPassword'
          ),
        ]
      ),
      new FieldsContainer(
        FrontendComponentEnum.HorizontalFieldline,
        'rehau.website.register.company_data',
        [
          new TextField(
            'companyName',
            'companyName',
            'rehau.website.register.company_name',
            '',
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            Autocomplete.Organization
          ),
          new TextField(
            'companyAddress',
            'companyAddress',
            'rehau.website.register.company_address',
            '',
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            Autocomplete.StreetAddress
          ),
          new TextField(
            'postalCode',
            'postalCode',
            'rehau.website.register.postal_code',
            '',
            true,
            undefined,
            { regExpString: 'validators.postalCode' },
            undefined,
            undefined,
            Autocomplete.PostalCode
          ),
          new TextField(
            'city',
            'city',
            'rehau.website.register.city',
            '',
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            Autocomplete.AddressLevel2
          ),
          new EmailField(
            'companyEmail',
            'companyEmail',
            'rehau.website.register.company_email',
            '',
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            Autocomplete.Email
          ),
          new CheckboxField(
            InstallerAgreementsTypeEnum.RegulationAgreement,
            InstallerAgreementsTypeEnum.RegulationAgreement,
            'rehau.website.register.regulation_agreement',
            true,
            false
          ),
        ]
      ),
    ];
    this.type = FormRegisterTypeEnum.Register;
    this.setValues(values);
    if (countryConfig && !!Object.keys(countryConfig).length) {
      this.updateCountryConfig(countryConfig);
    }
  }
}
