import React from 'react';

import { LettersQuantity, LettersQuantityContainer } from './lettersCounter.styled';
import { LettersCounterProps } from './lettersCounter.types';

export const LettersCounter: React.FC<LettersCounterProps> = (props: LettersCounterProps): JSX.Element => {
  const { value, maxValue, className }: LettersCounterProps = props;

  return (
    <LettersQuantityContainer className={className}>
      <LettersQuantity>
        {value} {!!maxValue && `/ ${maxValue}`}
      </LettersQuantity>
    </LettersQuantityContainer>
  );
};
