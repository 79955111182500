export enum Breakpoint {
  Mobile = '(max-width: 768px)',
  Tablet = '(max-width: 1024px)',
  Desktop = '(max-width: 1440px)',
  Hover = '(hover: hover) and (pointer: fine)',
  // additionals: use only in special cases
  Additional375 = '(max-width: 375px)',
  Additional480 = '(max-width: 480px)',
  Additional1280 = '(max-width: 1280px)',
  Additional1366 = '(max-width: 1366px)',
  Additional1600 = '(max-width: 1600px)',
  ChangeError = '(max-width: 1023px)',
  LandscapeMobile = '(max-height: 414px) and (max-width: 812px)',
}
