import { ErrorMessageEnum } from '@rehau/shared/enums';
import { RegexpTypeEnum } from '@rehau/shared/enums/regexpType.enum';
import { CustomRegexpValidation } from '@rehau/shared/forms/validations';
import {
  atLeastFiveCharsRegexp,
  maxTwentyCharsRegexp,
  onlyDigitsWithSpacesRegexp,
  withoutTwoSpacesInRowRegexp
} from '@rehau/shared/regexps';

export class PhoneValidation extends CustomRegexpValidation {
  constructor() {
    super([
      { pattern: onlyDigitsWithSpacesRegexp, message: ErrorMessageEnum.phone_number_not_valid },
      { pattern: withoutTwoSpacesInRowRegexp, message: ErrorMessageEnum.phone_number_has_two_spaces_in_row },
      { pattern: atLeastFiveCharsRegexp, message: ErrorMessageEnum.phone_number_too_short },
      { pattern: maxTwentyCharsRegexp, message: ErrorMessageEnum.phone_number_too_long, type: RegexpTypeEnum.MaxLength },
    ]);
  }
}
