import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  FieldComponent,
  findPathByName,
  FormStatus,
  KeyValueString,
  Step,
  SuccessOrFailScreen,
  useCountryConfig,
  UseCountryConfig,
  useRedirect,
  UseRedirectOutput,
  useRoutesInfo,
  UseState,
} from '@rehau-fe/ui/src';
import { RemindPasswordConfirmationForm } from '@rehau/shared/forms/password/remindPasswordConfirmation.form';
import { BaseAbstractContainer, componentTypes, fieldValueType } from '@rehau/shared/forms';
import { ValidationBar } from '@rehau-fe/ui/src/components/validationBar/validationBar.component';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { setNewPassword } from '@rehau-fe/app/api/requests/registration.request';
import { ApiErrorModel } from '@rehau/shared/models/responses';
import { WebsitePathNameEnum } from '@rehau/shared/enums';
import { RouteInfo } from '@rehau/shared/models/countryConfig';

import { Container } from './remindPasswordConfirmation.styled';

export const RemindPasswordConfirmation: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { config }: UseCountryConfig = useCountryConfig();
  const remindPasswordConfirmationForm: RemindPasswordConfirmationForm = useMemo(
    (): RemindPasswordConfirmationForm => new RemindPasswordConfirmationForm({}, config),
    []
  );
  const [status, setStatus]: UseState<FormStatus> = useState<FormStatus>(FormStatus.Idle);
  const routesArray: RouteInfo[] = useRoutesInfo();
  const [requestError, setRequestError]: UseState<string> = useState<string>('');
  const handleRedirect: UseRedirectOutput = useRedirect();
  const { token }: KeyValueString = useParams();

  const redirect: () => void = (): void => {
    if (status === FormStatus.Success) {
      handleRedirect(findPathByName(WebsitePathNameEnum.Home, routesArray), WebsitePathNameEnum.Home);
    } else {
      handleRedirect(findPathByName(WebsitePathNameEnum.RemindPassword, routesArray), WebsitePathNameEnum.RemindPassword);
    }
  };

  const submitForm: () => void = (): void => {
    if (status === FormStatus.Loading) {
      return;
    }
    const validationSuccess: boolean = (remindPasswordConfirmationForm as BaseAbstractContainer).validate();
    if (validationSuccess) {
      const data: ObjectIteratorInterface<fieldValueType> = (remindPasswordConfirmationForm as BaseAbstractContainer).getValues();
      setStatus(FormStatus.Loading);
      setNewPassword({ ...data, token })
        .then((): void => {
          setStatus(FormStatus.Success);
        })
        .catch((error: ApiErrorModel): void => {
          setStatus(FormStatus.Error);
          setRequestError(error.message);
        });
    } else {
      setRequestError('rehau.website.set_new_password_view.error_text');
    }
  };

  return (
    <>
      {status === FormStatus.Idle || status === FormStatus.Loading ? (
        <Container>
          <Step
            title={t('rehau.website.set_new_password_view.title')}
            nextButtonLabel={t('rehau.website.set_new_password_view.button_label')}
            handleNextClick={submitForm}
            loading={status === FormStatus.Loading}
          >
            {remindPasswordConfirmationForm.elements.map(
              (component: componentTypes, index: number): JSX.Element => (
                <FieldComponent
                  innerComponent={component}
                  setValues={(remindPasswordConfirmationForm as BaseAbstractContainer).setValues.bind(component)}
                  key={index}
                />
              )
            )}
            <ValidationBar errorsArray={requestError ? [{ message: requestError }] : []} />
          </Step>
        </Container>
      ) : (
        <SuccessOrFailScreen
          title={t(`rehau.website.set_new_password_view.${status === FormStatus.Success ? 'success' : 'fail'}_title`)}
          description={t(`rehau.website.set_new_password_view.${status === FormStatus.Success ? 'success' : 'fail'}_description`)}
          buttonLabel={t(`rehau.website.set_new_password_view.${status === FormStatus.Success ? 'home' : 'fail'}_button_label`)}
          success={status === FormStatus.Success}
          handleButtonClick={redirect}
        />
      )}
    </>
  );
};
