import styled from 'styled-components';

import { StyledComponent } from '../types/styledComponent.types';
import { Color } from '../enums/color.enum';

export const BaseLabel: StyledComponent<'p'> = styled.p`
  font-size: 15px;
  line-height: 18px;
  color: ${Color.Black};
  margin-bottom: 8px;
`;
