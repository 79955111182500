import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { InputController } from '../../input/inputController.component';
import { AreaField } from '../../../../../../../shared/forms/elements';
import { PriceUnitInput } from '../../input/priceUnitInput/priceUnitInput.component';
import { FieldElement, FieldElementProps } from '../types';

export const AreaFieldElement: FieldElement<AreaField> = (props: FieldElementProps<AreaField>): JSX.Element => {
  const { component, save, setValues }: FieldElementProps<AreaField> = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <InputController
      label={t(component.label || '')}
      additionalInfoContent={t(component.informationBox?.content || '')}
      additionalInfoContentInReadMore={t(component.informationBox?.readMoreContent || '')}
      tooltipProps={component.tooltip}
      disabled={component.onlyForRead || component.disabled}
      handleSave={save}
      component={component}
      setValues={setValues}
    >
      <PriceUnitInput areaUnit={component.areaUnit} placeholder={t(component.placeholder)} />
    </InputController>
  );
};
