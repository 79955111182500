import { useCallback, useReducer } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Bounds, DraggableBounds } from '../slide/slide.types';
import { SlideButton, SlideSize, SlidesType } from '../slides.enums';
import { setSlidesPositions } from '../reducer/slides.actions';
import { slidesReducer } from '../reducer/slidesReducer';
import { SlidesReducerOutput, SlidesState } from '../types/slidesReducer.types';
import {
  GetBounds,
  HandleDrag,
  HandleSubmit,
  InitValues,
  UseSlides,
  UseSlidesOutput,
} from '../types/useSlides.types';
import { FieldsContainer } from '../../../../../../../shared/forms/components';
import { FrontendComponentEnum } from '../../../../../../../shared/enums';
import { Breakpoint } from '../../../enums/breakpoint.enum';

const initState: SlidesState = {
  sPosition: 0,
  smPosition: 33,
  mlPosition: 66,
  lPosition: 100,
};

const convertBoundToPx: (value: number, maxMeters: number, slideWidth: number) => number = (
  value: number, maxMeters: number, slideWidth: number
): number => (value / slideWidth) * 100 * (maxMeters / 100);

const getBound: (value: number, bounds: DraggableBounds, maxMeters: number, slideWidth: number) => number = (
  value: number,
  bounds: DraggableBounds,
  maxMeters: number,
  slideWidth: number
): number => {
  if (value >= convertBoundToPx(bounds.right, maxMeters, slideWidth)) {
    return convertBoundToPx(bounds.right, maxMeters, slideWidth);
  }

  if (value <= convertBoundToPx(bounds.left, maxMeters, slideWidth)) {
    return convertBoundToPx(bounds.left, maxMeters, slideWidth);
  }

  return value;
};

export const useSlides: UseSlides = (initValues: InitValues): UseSlidesOutput => {
  const {
    maxMeters,
    sPosition,
    smPosition,
    mlPosition,
    lPosition,
    saveMethod = (): void => undefined,
    setValues = (): void => undefined,
    component = new FieldsContainer(FrontendComponentEnum.Slides, '', []),
  }: InitValues = initValues;
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const isSmallMobile: boolean = useMediaQuery({ query: Breakpoint.Additional480 });
  const slideWidth: SlideSize = isSmallMobile
    ? SlideSize.SlideWidthSmallMobile
    : isMobile
      ? SlideSize.SlideWidthMobile
      : SlideSize.SlideWidth;
  const sPositionConverted: number | null = sPosition ? (sPosition / maxMeters) * 100 : null;
  const smPositionConverted: number | null = smPosition ? (smPosition / maxMeters) * 100 : null;
  const mlPositionConverted: number | null = mlPosition ? (mlPosition / maxMeters) * 100 : null;
  const lPositionConverted: number | null = lPosition ? (lPosition / maxMeters) * 100 : null;
  const [state, dispatch]: SlidesReducerOutput = useReducer(slidesReducer, {
    ...initState,
    ...((!!sPositionConverted || sPositionConverted === 0) && !!smPositionConverted && !!mlPositionConverted && !!lPositionConverted
      ? {
        sPosition: sPositionConverted,
        smPosition: smPositionConverted,
        mlPosition: mlPositionConverted,
        lPosition: lPositionConverted,
      }
      : undefined
    ),
  });

  const handleDrag: HandleDrag = (position: number, buttonType: SlideButton): void => {
    setSlidesPositions(dispatch, buttonType, position, setValues, maxMeters);
  };

  const handleDragStop: () => void = (): void => {
    saveMethod(component.getValues());
  };

  const handleSubmit: HandleSubmit = (position: number, buttonType: SlideButton, bounds: DraggableBounds): void => {
    const positionOrbound: number = getBound(position, bounds, maxMeters, slideWidth);
    setSlidesPositions(dispatch, buttonType, (positionOrbound / maxMeters) * 100, setValues, maxMeters);
    saveMethod(component.getValues());
  };

  const getBounds: GetBounds = useCallback(
    (slideType: SlidesType): Bounds => {
      if (slideType === SlidesType.S) {
        return {
          smBound: 1,
          mlBound: (state.mlPosition * slideWidth) / 100,
        };
      }

      if (slideType === SlidesType.M) {
        return {
          smBound: (state.sPosition * slideWidth) / 100,
          mlBound: (state.lPosition * slideWidth) / 100,
        };
      }

      return {
        smBound: (state.smPosition * slideWidth) / 100,
        mlBound: slideWidth,
      };
    },
    [state]
  );

  return {
    handleDrag,
    handleDragStop,
    state,
    getBounds,
    handleSubmit,
  };
};
