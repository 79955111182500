import styled from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { GridWidth } from '../../enums/gridWidth.enum';
import { CustomLink } from '../customLink/customLink.component';

export const Container: StyledComponent<'div'> = styled.div``;

export const ArrowBackground: StyledComponent<'div'> = styled.div`
  height: 170px;
  background: ${Color.BgGrey};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
  box-sizing: border-box;

  @media ${Breakpoint.Additional480} {
    height: 120px;
  }
`;

export const ContentSeparatorContainer: StyledComponent<'button'> = styled.button`
  display: flex;
  border: 0;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background: ${Color.White};
  overflow: hidden;
  position: relative;
  i {
    transform: rotate(-90deg);
  }
  @media ${Breakpoint.Additional480} {
    margin: 50px calc(50% - 22px) 20px;
  }
`;

export const GreenBarWrapper: StyledComponent<'div'> = styled.div`
  background: ${Color.Green};
  display: flex;
  justify-content: center;
  padding: 0 172px;

  @media ${Breakpoint.Desktop} {
    padding: 0 60px;
  }
  @media ${Breakpoint.Tablet} {
    padding: 0 40px;
  }
  @media ${Breakpoint.Mobile} {
    padding: 0 30px;
  }
`;

export const GreenBar: StyledComponent<'div'> = styled.div`
  max-width: ${GridWidth.Desktop};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 120px;
  width: 100%;
  box-sizing: border-box;
`;

export const ProgressWrapper: StyledComponent<'div'> = styled.div``;

export const ProgressBar: StyledComponent<'div'> = styled.div`
  display: flex;
  height: 8px;
  margin-bottom: 2px;
`;

export const BlackLine: StyledComponent<'div'> = styled.div`
  width: 92px;
  background: ${Color.Black};
`;

export const WhiteLine: StyledComponent<'div'> = styled.div`
  width: 66px;
  background: ${Color.White};
`;

export const ProgressText: StyledComponent<'h1'> = styled.h1`
  font-size: 18px;
  font-weight: ${FontWeight.Black};
  line-height: 21px;
  letter-spacing: 0.171429px;
`;

export const WhiteProgressText: StyledComponent<'h1'> = styled(ProgressText)`
  color: ${Color.White};
`;

export const MenuWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: center;
  background: ${Color.Black};
  padding: 56px 170px 94px;

  @media ${Breakpoint.Desktop} {
    padding: 60px;
  }
  @media ${Breakpoint.Tablet} {
    padding: 60px 30px 30px 40px;
    margin-left: 0;
  }
  @media ${Breakpoint.Mobile} {
    padding: 55px 90px 40px 22px;
  }
`;

export const Menu: StyledComponent<'div'> = styled.div`
  display: flex;
  max-width: ${GridWidth.Desktop};
  width: 100%;
  justify-content: center;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
  }
`;

export const SocialColumn: StyledComponent<'ul'> = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  color: ${Color.White};

  @media ${Breakpoint.Tablet} {
    padding-left: 0;
    margin-top: 40px;
    flex-direction: row;
    align-items: center;
  }
`;

export const SocialLink: StyledComponent<'a'> = styled.a`
  margin-bottom: 26px;

  @media ${Breakpoint.Tablet} {
    margin-bottom: 0;
    margin-right: 27px;
  }

  i {
    transition: color .2s ease-in-out 0s;

    :hover {
      color: ${Color.Pink};
    }
  }
`;
