import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { hexToRgba } from '../../utils/colors.utils';

export const Wrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 40px;
  box-sizing: border-box;
  background-color: ${hexToRgba(Color.GreyStroke, 0.25)};

  @media ${Breakpoint.Additional480} {
    padding: 40px 20px;
  }
`;

export const ContentContainer: StyledComponent<'div'> = styled.div``;

export const CalculationsWrapper: StyledComponent<'div'> = styled.div``;

export const Title: StyledComponent<'h3'> = styled.h3`
  width: 100%;
  font-weight: ${FontWeight.Black};
  color: ${Color.Black};
  font-size: 18px;
  line-height: 18px;
  padding-bottom: 15px;
  border-bottom: 2px solid ${Color.Green};
  margin-bottom: 40px;
`;

export const Subtitle: StyledComponent<'h4'> = styled.h4`
  font-size: 20px;
  line-height: 24px;
  font-weight: ${FontWeight.Bold};
  margin-bottom: 8px;
`;

export const AdditionalInfo: StyledComponent<'p'> = styled.p`
  font-size: 20px;
  line-height: 24px;
`;

export const TotalPriceContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 40px 0;
  border-top: 1px solid ${Color.GreyStroke};
`;

export const TotalPriceInnerContainer: StyledComponent<'div'> = styled.div``;

export const TotalPricePrefix: StyledComponent<'p'> = styled.p`
  color: ${Color.Grey};
  font-size: 18px;
  line-height: 24px;
  margin-right: 15px;
`;

export const TotalPrice: StyledComponent<'p'> = styled.p`
  font-size: 28px;
  line-height: 24px;
  font-weight: ${FontWeight.Black};
`;

export const PriceTextContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
`;

export const TaxText: StyledComponent<'p'> = styled.p`
  font-weight: ${FontWeight.Bold};
  color: ${Color.Grey};
  font-size: 12px;
  line-height: 14px;
  text-align: end;
  letter-spacing: 0.7;
`;

export const Currency: StyledComponent<'span'> = styled.span`
  margin-left: 2px;
  margin-right: 2px;
`;
