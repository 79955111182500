import { Icon } from 'leaflet';

import PinSvg from './images/pin.svg';

export const mapSource: string = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';

export const mapCopyright: string = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>';

export const mapZoom: number = 16;

export const mapOffset: number = 0.0002;

export const marker: Icon = new Icon({
  iconUrl: PinSvg,
  iconSize: [40, 59],
});
