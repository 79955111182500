import { prop } from 'ramda';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { BoxShadow } from '../../enums/boxShadow.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { ZIndex } from '../../enums/zIndex.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';
import { Button } from '../button/button.component';
import { ButtonWithIcon } from '../buttonWithIcon/buttonWithIcon.component';
import { CustomLink } from '../customLink/customLink.component';
import { Markdown } from '../markdown/markdown.component';

import { ButtonsContainerProps, ContainerProps, CoverProps, TextProps, TitleProps } from './dialog.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div<ContainerProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 580px;
  z-index: ${ZIndex.Dialog};
  opacity: 0;
  pointer-events: none;
  ${styleWhenTrue(
    prop('isVisible'),
    css`
      opacity: 1;
      pointer-events: initial;
    `
  )}

  @media ${Breakpoint.Mobile} {
    top: 0;
    left: 0;
    max-width: 100%;
    transform: none;
  }
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  padding: 30px;
  background-color: ${Color.White};
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  width: calc(100% - 60px);

  @media ${Breakpoint.Mobile} {
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
  }
`;

export const Title: StyledComponent<'div', TitleProps> = styled.div<TitleProps>`
  font-weight: ${FontWeight.Bold};
  font-size: 24px;
  line-height: 24px;
  color: ${Color.Black};
  margin-bottom: 15px;

  ${styleWhenTrue(
    prop('withMarginTop'),
    css`
      margin-top: 13px;
    `
  )}

  @media ${Breakpoint.Mobile} {
    ${styleWhenTrue(
      prop('withMarginTop'),
      css`
        margin-top: 33px;
      `
    )}
  }
`;

export const Text: StyledComponent<typeof Markdown, TextProps> = styled(Markdown)<TextProps>`
  font-size: 18px;
  line-height: 22px;
  color: ${Color.Grey};
  margin-bottom: 25px;
  a {
    text-decoration: none;
    color: ${Color.Black};
  }
  strong {
    color: ${Color.Black};
  }
  ${({ customTextStyles }: TextProps): FlattenSimpleInterpolation | false => customTextStyles ? css`${customTextStyles}` : false};
`;

export const TextContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.2s linear;
  background-size: 226% 100%;
  background-position-x: 100%;
  padding: 10px 12px;
  font-size: 20px;
  line-height: 24px;
  transform-origin: 100% 100%;
  background-image: linear-gradient(to right, ${Color.Black}, ${Color.Black} 50%, ${Color.Green} 50%);
  background-color: ${Color.Green};
  color: ${Color.White};
`;

export const StyledButtonWithIcon: StyledComponent<typeof ButtonWithIcon> = styled(ButtonWithIcon)``;

export const AcceptButton: StyledComponent<typeof Button> = styled(Button)``;

export const CancelButton: StyledComponent<typeof AcceptButton> = styled(AcceptButton)``;

export const Link: StyledComponent<'a'> = styled.a`
  outline: none;
  border: none;
  padding: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  text-decoration: none;
  margin-right: 15px;

  :hover {
    ${TextContainer} {
      background-position-x: 0;
    }
  }
`;

export const ButtonsContainer: StyledComponent<'div', ButtonsContainerProps> = styled.div<ButtonsContainerProps>`
  display: flex;
  align-items: center;
  ${AcceptButton} + ${CancelButton} {
    margin-left: 15px;
  }

  ${styleWhenTrue(prop('verticalButtonsAlignment'), css`
    flex-direction: column;
    align-items: flex-start;

    ${Link} + ${CancelButton},
    ${CancelButton} + ${StyledButtonWithIcon} {
      margin-top: 10px;
    }
  `)};

  @media ${Breakpoint.Additional480} {
    flex-direction: column;
    align-items: flex-start;

    ${AcceptButton} + ${CancelButton} {
      margin-left: 0;
      margin-top: 10px;
    }
  }
`;

export const BoxWithIcon: StyledComponent<typeof CustomLink> = styled(CustomLink)`
  min-height: 84px;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: ${BoxShadow.BtnGreyShadow};
`;

export const Cover: StyledComponent<'div', CoverProps> = styled.div<CoverProps>`
  background: url(${(props: CoverProps): string => props.image});
  background-size: cover;
  height: 84px;
  width: 84px;
  overflow: hidden;
`;

export const BoxContent: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  width: calc(100% - 124px);
`;

export const CodeBox: StyledComponent<'div'> = styled.div`
  padding: 10px;
  border: 1px solid ${Color.GreyStroke};
  margin-bottom: 30px;
  color: ${Color.Grey};
  overflow-wrap: break-word;
`;

export const Content: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Bold};
  color: ${Color.Black};
  font-size: 18px;
  line-height: 22px;
`;

export const CloseButton: StyledComponent<'button'> = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
`;

export const ArrowIconContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 44px;
  flex-shrink: 0;
  background-color: ${Color.Green};
  box-sizing: border-box;
`;
