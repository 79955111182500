import { useContext } from 'react';

import { Config } from '../../../../../shared/models/countryConfig';
import { CountryConfigContext } from '../contexts/countryConfig.context';
import { UseCountryConfig } from '../types/useCountryConfig.types';

export const useCountryConfig: () => UseCountryConfig = (): UseCountryConfig => {
  const config: Config = useContext(CountryConfigContext);
  if (!config) {
    throw new Error('Country config context error');
  }

  const isConfigAvailable: boolean = !!config && !!Object.keys(config).length;

  return { config, isConfigAvailable };
};
