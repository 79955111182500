import { FieldsContainer } from '@rehau/shared/forms/components';
import { PasswordField, RangeField, Tooltip } from '@rehau/shared/forms/elements';
import {
  DistanceUnitEnum,
  ErrorMessageEnum,
  FrontendComponentEnum,
  SetupFieldEnum,
  TooltipTheme,
} from '@rehau/shared/enums';
import { AreaValidation } from '@rehau/shared/forms/validations';

export class RadiusContainer extends FieldsContainer {
  constructor() {
    const elements: PasswordField[] = [
      new RangeField(
        SetupFieldEnum.NormalRadius,
        SetupFieldEnum.NormalRadius,
        'rehau.radius_container.standard_range.title',
        true,
        new AreaValidation(
          1,
          1000,
          ErrorMessageEnum.fields_value_too_low_and_value_restored,
          ErrorMessageEnum.fields_value_too_high_and_value_restored,
          ErrorMessageEnum.fields_required_value_restored
        ),
        undefined,
        undefined,
        DistanceUnitEnum.Km,
        undefined,
        new Tooltip('rehau.radius_container.standard_range.tooltip', '', TooltipTheme.Yellow)
      ),
      // FIXME: Uncomment when another setup will be active
      // new RangeField(
      //   'maximumRadius',
      //   'maximumRadius',
      //   'rehau.radius_container.maximum_range.title',
      //   true,
      //   new AreaValidation(
      //     1,
      //     1000,
      //     ErrorMessageEnum.fields_value_too_low_and_value_restored,
      //     ErrorMessageEnum.fields_value_too_high_and_value_restored,
      //     ErrorMessageEnum.fields_required_value_restored
      //   ),
      //   undefined,
      //   undefined,
      //   DistanceUnitEnum.Km,
      //   undefined,
      //   new Tooltip('rehau.radius_container.maximum_range.tooltip', '', TooltipTheme.Yellow)
      // ),
    ];
    super(
      FrontendComponentEnum.RadiusContainer,
      '',
      elements
    );
  }

  public getNormalRadiusField(): RangeField | null {
    return this.findFieldById('normalRadius') as RangeField | null;
  }

  public getMaximumRadiusField(): RangeField | null {
    return this.findFieldById('maximumRadius') as RangeField | null;
  }

  public validate(): boolean {
    const validationResult: boolean = super.validate();
    const requirementsResult: boolean = this.checkRequirements();

    return validationResult && requirementsResult;
  }

  private checkRequirements(): boolean {
    // FIXME: Uncomment when another setup will be active
    // const normalRadiusField: RangeField | null = this.getNormalRadiusField();
    // const maximumRadiusField: RangeField | null = this.getMaximumRadiusField();
    // if (normalRadiusField && maximumRadiusField) {
    //   const normalRadiusFieldValue: number | undefined = normalRadiusField.value
    //     ? Number(normalRadiusField.value)
    //     : 0;
    //   const maximumRadiusFieldValue: number | undefined = maximumRadiusField.value
    //     ? Number(maximumRadiusField.value)
    //     : 0;
    //
    //   if (normalRadiusFieldValue > maximumRadiusFieldValue) {
    //     normalRadiusField.valid = false;
    //     maximumRadiusField.valid = false;
    //     normalRadiusField.validationMessages.push({
    //       message: ErrorMessageEnum.offer_range_incorrect_value, params: {},
    //     });
    //     maximumRadiusField.validationMessages.push({
    //       message: ErrorMessageEnum.offer_range_incorrect_value, params: {},
    //     });
    //
    //     return false;
    //   }
    // }
    //
    return true;
  }
}
