import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { SelectField, SelectOption } from '../../../../../../../shared/forms/elements';
import { DropdownController } from '../../dropdown/dropdownController/dropdownController.component';
import { Dropdown } from '../../dropdown/dropdown.component';
import { FieldElement, FieldElementProps } from '../types';

export const SelectFieldElement: FieldElement<SelectField> = (props: FieldElementProps<SelectField>): JSX.Element => {
  const { component, save, setValues }: FieldElementProps<SelectField> = props;
  const { t }: TransProps<never> = useTranslation();
  const allowedOptions: SelectOption[] = component.allowedOptions.map(
    (option: SelectOption): SelectOption => {
      option.label = t(option.label);

      return option;
    }
  );

  return (
    <DropdownController
      items={allowedOptions}
      label={t(component.label || '')}
      additionalInfoContent={t(component.informationBox?.content || '')}
      additionalInfoContentInReadMore={t(component.informationBox?.readMoreContent || '')}
      component={component}
      handleSave={save}
      setValues={setValues}
      placeholder={component.placeholder}
    >
      <Dropdown />
    </DropdownController>
  );
};
