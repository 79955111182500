import styled, { StyledComponent } from 'styled-components';

import { Color } from '../../enums/color.enum';

export const Container: StyledComponent<'div', {}, {}, never> = styled.div`
  display: flex;
  align-items: center;
  height: 46px;
  width: calc(100% - 30px);
  max-width: 580px;
  background-color: ${Color.BgGrey};
  padding: 0 15px;
`;

export const Label: StyledComponent<'div', {}, {}, never> = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${Color.Grey};
  padding-bottom: 1px;
`;
