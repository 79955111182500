import { AbstractField } from '@rehau/shared/forms/elements/fields';
import { Tooltip } from '@rehau/shared/forms/elements/';
import { FormFieldTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { RequiredFieldValidation } from '@rehau/shared/forms/validations';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';

export class SelectItemField extends AbstractField {
  public id: string;
  public label: string;
  public price: number;
  public tooltip?: Tooltip;

  constructor(
    id: string,
    name: string,
    label: string,
    price: number,
    required: boolean,
    tooltip?: Tooltip,
    configMapping?: ObjectIteratorInterface<string>
  ) {
    super(
      FormFieldTypeEnum.SelectItemField,
      FrontendComponentEnum.SelectItem,
      id,
      name,
      label,
      null,
      new RequiredFieldValidation(),
      required,
      false,
      undefined,
      undefined,
      configMapping
    );
    this.id = id;
    this.label = label;
    this.price = price;
    this.tooltip = tooltip;
  }
}
