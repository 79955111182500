import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { Color } from '../../../../enums/color.enum';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { InnerContainer, OuterContainer } from '../../../../theme/containers.styled';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { styleIfElse, styleWhenTrue } from '../../../../utils/style.utils';
import { Title as TitleBase } from '../title/title.component';
import { Markdown } from '../../../markdown/markdown.component';

import {
  ColumnProps,
  ColumnsProps,
  ColumnTextWrapperProps,
  ContainerProps,
} from './textWithVideo.types';

export const Container: StyledComponent<typeof OuterContainer, ContainerProps> = styled(OuterContainer)`
  padding-top: 80px;
  padding-bottom: 100px;

  @media ${Breakpoint.Tablet} {
    margin: 0;
    width: 100%;
    padding: 60px 40px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 60px 20px;
  }

  ${styleWhenTrue(prop('darkBackground'), css`
    background-color: ${Color.BgGrey};
  `)}
`;

export const Wrapper: StyledComponent<typeof InnerContainer> = styled(InnerContainer)`
  flex-direction: column;
  overflow: hidden;
`;

export const SectionTitle: StyledComponent<typeof TitleBase> = styled(TitleBase)`
  margin-bottom: 40px;
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 380px;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
    min-width: 0;
  }
`;

export const Content: StyledComponent<'div'> = styled.div`
  flex: 1;
  margin-right: 80px;
  flex-shrink: 0;

  @media ${Breakpoint.Tablet} {
    margin-right: 0;
  }
`;

export const StyledMarkdown: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;

  @media ${Breakpoint.Tablet} {
    margin-bottom: 38px;
  }

  strong {
    font-weight: ${FontWeight.Bold};
  }
`;

export const YoutubeContainer: StyledComponent<'div'> = styled.div`
  flex: 1.55;

  div {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export const Subtitle: StyledComponent<typeof TitleBase> = styled(TitleBase)`
  margin-top: 40px;

  @media ${Breakpoint.Tablet} {
    margin-top: 0;
  }
`;

export const ColumnsTitle: StyledComponent<typeof TitleBase> = styled(TitleBase)`
  margin: 40px 0 25px;

  @media ${Breakpoint.Tablet} {
    max-width: 490px;
  }
`;

export const Columns: StyledComponent<'div', ColumnsProps> = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${styleIfElse(prop('withTitle'), '0', '40px')} -12.5px 0;

  @media ${Breakpoint.Tablet} {
    flex-direction: ${styleIfElse(prop('imageColumns'), 'row', 'column')};
    margin: ${styleIfElse(prop('withTitle'), '0', '40px')} 0 0;

    ${styleWhenTrue(prop('imageColumns'), css`
      flex-wrap: wrap;
      margin: 0 -12.5px 0;
    `)}
  }

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    margin: ${styleIfElse(prop('withTitle'), '0', '40px')} 0 0;
  }
`;

export const Column: StyledComponent<'div', ColumnProps> = styled.div`
  flex: 1;
  margin: 0 12.5px;

  @media ${Breakpoint.Tablet} {
    margin: ${styleIfElse(prop('withImage'), '0 12.5px 40px', '0 0 12px')};

    :last-of-type {
      margin: ${styleIfElse(prop('withImage'), '0 12.5px', '0')};
    }

    ${styleWhenTrue(prop('withImage'), css`
      flex: 0 1 auto;
      flex-wrap: wrap;
      width: calc(50% - 25px);
    `)}
  }

  @media ${Breakpoint.Mobile} {
    margin: 0 0 ${styleIfElse(prop('withImage'), '40px', '12px')};
    width: 100%;

    :last-of-type {
      margin: 0;
    }
  }
`;

export const ColumnImageContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.BgGrey};
  width: 100%;
  height: 120px;
`;

export const ColumnImage: StyledComponent<'img'> = styled.img``;

export const ColumnTextWrapper: StyledComponent<'div', ColumnTextWrapperProps> = styled.div`
  background-color: ${styleIfElse(prop('withImage'), Color.Transparent, Color.Black)};
  box-sizing: border-box;
  padding: ${styleIfElse(prop('withImage'), '20px 0 0', '25px')};
  color: ${styleIfElse(prop('withImage'), Color.Black, Color.White)};
  height: 100%;
`;

export const ColumnTitle: StyledComponent<'h6'> = styled.h6`
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.27px;
  margin-bottom: 10px;
  font-weight: ${FontWeight.Bold};
`;

export const ColumnText: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;
  word-break: break-word;
`;

export const Hints: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -30px;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
    margin: 0;
    align-items: flex-start;
  }
`;

export const HintContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin: 0 30px;

  @media ${Breakpoint.Tablet} {
    margin: 0 0 20px;
  }

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 40px;
  }
`;

export const HintImage: StyledComponent<'img'> = styled.img`
  margin-right: 20px;

  @media ${Breakpoint.Mobile} {
    margin-right: 0;
    margin-bottom: 25px;
  }
`;

export const HintText: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 24px;
  word-break: break-word;
`;
