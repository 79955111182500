import React from 'react';

import { UseTimelineAnimationOutput } from '../../../types/useTimelineAnimation.types';

export const assignColumnRef: (
  index: number,
  animation: UseTimelineAnimationOutput
) => React.RefObject<HTMLDivElement> = (
  index: number,
  animation: UseTimelineAnimationOutput
): React.RefObject<HTMLDivElement> => {
  switch (index) {
    case 0: return animation.firstBoxRef;
    case 1: return animation.secondBoxRef;
    case 2: return animation.thirdBoxRef;
    case 3: return animation.fourthBoxRef;
    default: return animation.fifthBoxRef;
  }
};
