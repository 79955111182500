import styled, { StyledComponent } from 'styled-components';

import { Breakpoint } from '../enums/breakpoint.enum';
import { Color } from '../enums/color.enum';
import { GridWidth } from '../enums/gridWidth.enum';

import { OuterContainerProps } from './theme.types';

export const OuterContainer: StyledComponent<'div', {}, OuterContainerProps, never> = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 25px;
  background-color: ${(data: OuterContainerProps): Color => data.background ? data.background : Color.White};
  @media ${Breakpoint.Desktop} {
    padding: 0 40px;
  }
  @media ${Breakpoint.Tablet} {
    margin: 0 40px;
    width: calc(100% - 80px);
    padding: 0 30px;
    flex-direction: column;
  }
  @media ${Breakpoint.Mobile} {
    margin: 0 12px;
    width: calc(100% - 24px);
    padding: 0 20px;
  }
`;

export const InnerContainer: StyledComponent<'div', {}, {}, never> = styled.div`
  width: 100%;
  box-sizing: border-box;
  max-width: ${GridWidth.Desktop};
  margin: 0 auto;
  display: flex;
`;
