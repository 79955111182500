export enum ErrorMessageEnum {
  default = 'rehau.api.error.default',
  fields_option_not_found = 'rehau.api.error.fields_option_not_found',
  fields_options_not_found = 'rehau.api.error.fields_options_not_found',
  fields_options_not_only_one_selected = 'rehau.api.error.fields_options_not_only_one_selected',
  fields_required = 'rehau.api.error.fields_required',
  fields_required_value_restored = 'rehau.api.error.fields_required_value_restored',
  fields_value_too_high = 'rehau.api.error.fields_value_too_high',
  fields_value_too_high_and_value_restored = 'rehau.api.error.fields_value_too_high_and_value_restored',
  fields_value_too_long = 'rehau.api.error.fields_value_too_long',
  fields_value_too_long_and_value_restored = 'rehau.api.error.fields_value_too_long_and_value_restored',
  fields_value_too_low = 'rehau.api.error.fields_value_too_low',
  fields_value_too_low_and_value_restored = 'rehau.api.error.fields_value_too_low_and_value_restored',
  fields_value_too_short = 'rehau.api.error.fields_value_too_short',
  fields_value_too_short_and_value_restored = 'rehau.api.error.fields_value_too_short_and_value_restored',
  fields_wrong_value = 'rehau.api.error.fields_wrong_value',
  fields_wrong_value_and_value_restored = 'rehau.api.error.fields_wrong_value_and_value_restored',
  number_fields_required_value_restored = 'rehau.api.error.number_fields_required_value_restored',
  numeric_fields_value_too_low_and_value_restored = 'rehau.api.error.numeric_fields_value_too_low_and_value_restored',
  numeric_fields_value_too_high_and_value_restored = 'rehau.api.error.numeric_fields_value_too_high_and_value_restored',
  at_least_one_required = 'rehau.api.error.at_least_one_required',
  at_least_one_re_source_variant_required = 'rehau.api.error.at_least_one_re_source_variant_required',
  at_least_one_heating_type_required = 'rehau.api.error.at_least_one_heating_type_required',
  at_least_one_heating_type_variant_required = 'rehau.api.error.at_least_one_heating_type_variant_required',
  all_or_null_variants_required = 'rehau.api.error.all_or_null_variants_required',
  all_or_null_variants_required_new_setup = 'rehau.api.error.all_or_null_variants_required_new_setup',
  all_or_null_optional_variants_required = 'rehau.api.error.all_or_null_optional_variants_required',
  all_or_null_optional_variants_required_new_setup = 'rehau.api.error.all_or_null_optional_variants_required_new_setup',
  variants_wrong_value = 'rehau.api.error.variants_wrong_value',
  variants_wrong_value_new_setup = 'rehau.api.error.variants_wrong_value_new_setup',
  resolve_conflicts = 'rehau.api.error.resolve_conflicts',
  password_not_identical = 'rehau.api.error.password_not_identical',
  email_wrong_format = 'rehau.api.error.email_wrong_format',
  database_connection_error = 'rehau.api.error.database_connection_error',
  database_save_error = 'rehau.api.error.database_save_error',
  database_duplicate_entry = 'rehau.api.error.database_duplicate_entry',
  access_denied = 'rehau.api.error.access_denied',
  bad_request = 'rehau.api.error.bad_request',
  login_required = 'rehau.api.error.login_required',
  wrong_login_or_password = 'rehau.api.error.wrong_login_or_password',
  wrong_password = 'rehau.api.error.wrong_password',
  wrong_token = 'rehau.api.error.wrong_token',
  wrong_hash = 'rehau.api.error.wrong_hash',
  wrong_id = 'rehau.api.error.wrong_id',
  wrong_email = 'rehau.api.error.wrong_email',
  email_already_in_use = 'rehau.api.error.email_already_in_use',
  registered_user = 'rehau.api.error.registered_user',
  page_is_required = 'rehau.api.error.page_is_required',
  limit_is_required = 'rehau.api.error.limit_is_required',
  crm_id_already_exist = 'rehau.api.error.crm_id_already_exist',
  crm_id_not_found = 'rehau.api.error.crm_id_not_found',
  crm_update_error = 'rehau.api.error.crm_update_error',
  account_already_active = 'rehau.api.error.account_already_active',
  token_not_found = 'rehau.api.error.token_not_found',
  token_expired = 'rehau.api.error.token_expired',
  token_suspended = 'rehau.api.error.token_suspended',
  token_inactive_after_logout = 'rehau.api.error.token_inactive_after_logout',
  token_inactive_after_take_session = 'rehau.api.error.token_inactive_after_take_session',
  token_inactive_after_company_take_session = 'rehau.api.error.token_inactive_after_company_take_session',
  company_not_found = 'rehau.api.error.company_not_found',
  company_address_not_found = 'rehau.api.error.company_address_not_found',
  calculation_not_found = 'rehau.api.error.calculation_not_found',
  calculation_type_not_found = 'rehau.api.error.calculation_type_not_found',
  installer_not_found = 'rehau.api.error.installer_not_found',
  user_is_blocked = 'rehau.api.error.user_is_blocked',
  summary_not_found = 'rehau.api.error.summary_not_found',
  get_summary_error = 'rehau.api.error.get_summary_error',
  set_company_error = 'rehau.api.error.set_company_error',
  save_location_error = 'rehau.api.error.save_location_error',
  wrong_calculation_location = 'rehau.api.error.wrong_calculation_location',
  installer_update_error = 'rehau.api.error.installer_update_error',
  company_update_error = 'rehau.api.error.company_update_error',
  country_not_found = 'rehau.api.error.country_not_found',
  setup_not_found = 'rehau.api.error.setup_not_found',
  setup_not_valid = 'rehau.api.error.setup_not_valid',
  setups_not_valid = 'rehau.api.error.setups_not_valid',
  phone_number_not_valid = 'rehau.api.error.phone_number_not_valid',
  phone_number_too_short = 'rehau.api.error.phone_number_too_short',
  phone_number_too_long = 'rehau.api.error.phone_number_too_long',
  phone_number_has_two_spaces_in_row = 'rehau.api.error.phone_number_has_two_spaces_in_row',
  company_has_unsave_values = 'rehau.api.error.company_has_unsave_values',
  form_field_conflict = 'rehau.api.error.form_field_conflict',
  form_values_error = 'rehau.api.error.form_values_error',
  // Profile
  profile_save_failed = 'rehau.api.error.profile_save_failed',
  password_not_changed = 'rehau.api.error.profile_password_not_changed',
  password_requirements_not_provided  = 'rehau.api.error.password_requirements_not_provided',
  old_password_verification_failed  = 'rehau.setup.profile.alerts.old.password.verification.failed',
  offer_range_save_failed = 'rehau.api.error.offer_range_save_failed',
  offer_range_incorrect_value = 'rehau.api.error.offer_range_incorrect_value',
  init_service_failed = 'rehau.api.error.init_service_failed',
  // Offers
  serial_number_not_valid = 'rehau.api.error.serial_number_not_valid',
}
