import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';

import { useMultiSelect } from '../select/hooks/useMultiSelect';
import { UseMultiSelectOutput } from '../select/hooks/useMultiSelect.types';
import { ValidationStatus } from '../../enums/validationStatus.enum';
import { UseState } from '../../types/useState.types';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { Icon } from '../icon/icon.component';
import { IconName } from '../../../../../../shared/enums';
import { Color } from '../../enums/color.enum';
import { useEffectAfterMount } from '../../hooks/useEffectAfterMount';
import { ContainerType } from '../input/inputContainer/containerType.enum';
import { MultiSelectField, SelectOption } from '@rehau/shared/forms/elements';
import { ValidationBar } from '../validationBar/validationBar.component';

import {
  ArrowButton,
  CheckContainer,
  Container,
  DropdownContainer,
  InnerContainer,
  Label,
  Option,
  RowText,
  SelectBox,
  SelectRow,
  Value,
  Wrapper,
} from './multiselectDropdown.styled';
import { MultiselectDropdownProps } from './multiselectDropdown.types';

export const MultiselectDropdown: React.FC<MultiselectDropdownProps> = (props: MultiselectDropdownProps): JSX.Element => {
  const {
    className,
    items,
    onChange,
    label,
    component = new MultiSelectField('', '', '', false),
    setValues = (): void => undefined,
    handleSave = (): void => undefined,
  }: MultiselectDropdownProps = props;
  const initValues: string[] = useMemo(
    (): string[] => items.filter((item: SelectOption): boolean => item.selected).map((item: SelectOption): string => item.value),
    [JSON.stringify(items)]
  );
  const [activeItems, setActiveItem]: UseMultiSelectOutput = useMultiSelect([...initValues]);
  const [isOpen, setIsOpen]: UseState<boolean> = useState<boolean>(false);
  const [status, setStatus]: UseState<ValidationStatus> = useState<ValidationStatus>(
    activeItems.length ? ValidationStatus.Valid : ValidationStatus.Untouched
  );
  const ref: RefObject<HTMLDivElement> = useRef(null);
  useOnClickOutside([ref], (): void => setIsOpen(false));

  const toggleIsOpen: () => void = (): void => {
    setIsOpen(!isOpen);
  };

  useEffect(
    (): void => {
      if (status !== ValidationStatus.Untouched) {
        setValues({ [component.id]: activeItems });
        const validationSuccess: boolean = component.validate();
        if (validationSuccess) {
          handleSave();
        }
      }
    },
    [activeItems, status]
  );

  useEffectAfterMount(
    (): void => {
      if (!activeItems.length) {
        setStatus(ValidationStatus.Untouched);
      }

      if (activeItems.length && status !== ValidationStatus.Valid) {
        setStatus(ValidationStatus.Valid);
      }

      if (onChange) {
        onChange();
      }
    },
    [JSON.stringify(activeItems)]
  );

  return (
    <>
    {!!label && <Label>{label}</Label>}
    <Wrapper ref={ref}>
      <DropdownContainer validationStatus={status} containerType={ContainerType.MultiselectDropdowm}>
        <Container>
          <Value data-testid='value'>
            {activeItems.map(
              (name: string, index: number): JSX.Element => (
                <Option key={name}>
                  {items.find((item: SelectOption): boolean => item.value === name)?.label}
                  {index + 1 !== activeItems.length ? ', ' : ''}
                </Option>
              )
            )}
          </Value>
          <ArrowButton isOpen={isOpen} onClick={toggleIsOpen} data-testid='arrow-button'>
            <InnerContainer>
              <Icon name={IconName.ArrowDown} size={14} />
            </InnerContainer>
          </ArrowButton>
        </Container>
        <SelectBox className={className} isOpen={isOpen} isValid={status === ValidationStatus.Valid} data-testid='select-box'>
          {items.map(
            (item: SelectOption): JSX.Element => (
              <SelectRow
                isActive={activeItems.includes(item.value)}
                id={item.value}
                onClick={setActiveItem}
                data-testid={`select-item-${item.value}`}
                key={item.value}
              >
                <CheckContainer>
                  <Icon name={IconName.Check} size={10} color={Color.White} />
                </CheckContainer>
                <RowText>{item.label}</RowText>
              </SelectRow>
            )
          )}
        </SelectBox>
      </DropdownContainer>
      <ValidationBar errorsArray={component.validationMessages} />
    </Wrapper>
    </>
  );
};
