import { AxiosResponse } from 'axios';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { IconName, TooltipTheme } from '../../../../../../shared/enums';
import { Color } from '../../enums/color.enum';
import { useAlerts } from '../../hooks/useAlerts.hook';
import { useEffectAfterMount } from '../../hooks/useEffectAfterMount';
import { UseAlerts } from '../../types/useAlerts.types';
import { UseState } from '../../types/useState.types';
import { CircleLoader } from '../circleLoader/circleLoader.component';
import { Icon } from '../icon/icon.component';

import { DeleteButton, FileInput, Label, Row, StyledIcon, StyledTooltip, UploadButton, Wrapper } from './imageUpload.styled';
import { ImageUploadProps } from './imageUpload.types';

export const ImageUpload: React.FC<ImageUploadProps> = (props: ImageUploadProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    description,
    label,
    imgUrl = '',
    component,
    api,
    successMessage = t('rehau.components.image_upload.success_message'),
    errorMessage = t('rehau.components.image_upload.error_message'),
  }: ImageUploadProps = props;
  const [file, setFile]: UseState<File | undefined> = useState<File>();
  const [isLoading, setIsLoading]: UseState<boolean> = useState<boolean>(false);
  const [image, setImage]: UseState<string> = useState<string>(component?.value as string || imgUrl);
  const { showAlert }: UseAlerts = useAlerts();

  useEffectAfterMount((): void => setImage((component?.value as string) || ''), [component?.value]);

  const onFileChange: (event: ChangeEvent<HTMLInputElement>) => void = (event: ChangeEvent<HTMLInputElement>): void => {
    const tempFile: File = event.target.files?.[0] as File;
    setFile(tempFile);
    if (!!api && component) {
      const formData: FormData = new FormData();
      formData.append(component.name, tempFile, tempFile.name);
      setIsLoading(true);
      api.post(component.apiPath, formData).then((response: AxiosResponse<string>): void => {
        setImage(response.data);
        setFile(undefined);
        showAlert({ content: successMessage || '' });
        setTimeout((): void => setIsLoading(false), 500);
      }).catch((): void => {
        showAlert({ content: errorMessage || '', background: Color.Black });
        setTimeout((): void => setIsLoading(false), 500);
      });
    }
  };

  const handleRemove: () => void = (): void => {
    if (!!api && component) {
      setIsLoading(true);
      api.delete(component.apiPath).then((response: AxiosResponse<boolean>): void => {
        if (response.data) {
          setImage('');
          showAlert({ content: successMessage || '' });
        } else {
          showAlert({ content: errorMessage || '', background: Color.Black });
        }
        setTimeout((): void => setIsLoading(false), 500);
      }).catch((): void => {
        showAlert({ content: errorMessage || '', background: Color.Black });
        setTimeout((): void => setIsLoading(false), 500);
      });
    }
  };

  return (
    <>
      {!!label && <Label>{label}</Label>}
      <Row>
        <Wrapper imgUrl={image} data-testid='container'>
          {(!!image && !isLoading) && (
            <DeleteButton aria-label='remove' onClick={handleRemove}>
              <StyledIcon name={IconName.Delete} size={14} color={Color.White} />
            </DeleteButton>
          )}
          {(!image && !isLoading) && (
            <>
              <UploadButton aria-label='upload'>
                <Icon name={IconName.LoadFile} size={14} color={Color.White} />
              </UploadButton>
              <FileInput type='file' onChange={onFileChange} />
            </>
          )}
          {isLoading && (
            <CircleLoader />
          )}
        </Wrapper>
        <StyledTooltip
          tooltipSettings={{
            title: '',
            content: t('rehau.components.image_upload.tooltip'),
            theme: TooltipTheme.Yellow,
          }}
        />
      </Row>
    </>
  );
};
