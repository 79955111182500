import React, { forwardRef, useRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';

import { fieldValueType } from '../../../../../../../../shared/forms/types';
import { ProductTableNameEnum } from '../../../../../../../../shared/enums';
import { ObjectIteratorInterface } from '../../../../../../../../shared/objectIterator.interface';
import { useCountryConfig } from '../../../../hooks/useCountryConfig.hook';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { ConfiguratorPreview } from '../../../configuratorPreview/configuratorPreview.component';
import { ProductTable } from '../../../productTable/productTable.component';
import { FullWidthImage } from '../../components/fullWidthImage/fullWidthImage.component';
import { Tabs } from '../../components/tabs/tabs.component';
import { TextWithBox } from '../../components/textWithBox/textWithBox.component';
import { TextWithVideo } from '../../components/textWithVideo/textWithVideo.component';
import { TextWithImage } from '../../components/textWithImage/textWithImage.component';
import { SimpleText } from '../../components/simpleText/simpleText.component';
import CoupleImage from '../../images/coupleAtHome.jpg';
import ManWithDogImage from '../../images/manWithDog.jpg';
import FamilyImage from '../../images/family.jpg';
import SensorImage from '../../images/sensor.jpg';
import { useActiveTab } from '../../hooks/useActiveTab.hooks';
import { sectionVisibilityThreshold } from '../../products.constants';
import { UseActiveTab, UseCalculatorNavigate } from '../../products.types';
import { UseCountryConfig } from '../../../../types/useCountryConfig.types';
import { OptionTab } from '../../components/tabs/tabs.types';
import { useCalculatorNavigate } from '../../hooks/useCalculatorNavigate.hook';
import { ProductPopupContext } from '../../../../contexts/productPopup.context';

import { Container, TableContainer, TableWrapper } from './heatInstallation.styled';
import { SectionId } from './heatInstallation.enums';
import { HeatInstallationProps } from './heatInstallation.types';

export const HeatInstallation: React.FC<HeatInstallationProps> = forwardRef((
  props: HeatInstallationProps, ref: React.ForwardedRef<{}>
): JSX.Element => {
  const { className, tabsOnTop, setTabsOnTop, isInPopup = false }: HeatInstallationProps = props;
  const { t }: TransProps<never> = useTranslation();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();
  const products: ObjectIteratorInterface<fieldValueType> = isConfigAvailable ? config.variables.products : {};
  const [activeTabId, setActiveTabId]: UseActiveTab = useActiveTab('');
  const { navigateToCalculator }: UseCalculatorNavigate = useCalculatorNavigate();
  const freedomRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const comfortRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const developmentRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const montageRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const calculatorRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);

  const getCurrentRefByActiveId: (activeTab: string) => React.RefObject<InView & HTMLDivElement> =
    (activeTab: string): React.RefObject<InView & HTMLDivElement> => {
      switch (activeTab) {
        case SectionId.Freedom:
          return freedomRef;
        case SectionId.Comfort:
          return comfortRef;
        case SectionId.Development:
          return developmentRef;
        case SectionId.Montage:
          return montageRef;
        default:
          return calculatorRef;
      }
    };

  const handleScroll: (tabId: string) => void = (tabId: string): void => {
    const activeRef: React.RefObject<InView & HTMLDivElement> = getCurrentRefByActiveId(tabId);
    activeRef.current?.node?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ProductPopupContext.Provider value={{ isInPopup }}>
      <Container className={className} data-testid='heat-installation' ref={ref as React.RefObject<HTMLDivElement>}>
        <FullWidthImage src={CoupleImage} />
        <TextWithBox
          title={t('rehau.products.heat.header.title')}
          text={t('rehau.products.heat.header.content')}
          boxTitle={t('rehau.products.heat.header.box_title')}
          boxText={t('rehau.products.heat.header.box_content')}
          isTopSection
        />
        <Tabs
          activeId={activeTabId}
          options={[
            { label: t('rehau.products.heat.tabs.freedom'), id: SectionId.Freedom },
            { label: t('rehau.products.heat.tabs.comfort'), id: SectionId.Comfort },
            { label: t('rehau.products.heat.tabs.development'), id: SectionId.Development },
            { label: t('rehau.products.heat.tabs.montage'), id: SectionId.Montage },
            !isInPopup ? { label: t('rehau.products.heat.tabs.calculator'), id: SectionId.Calculator } : {} as OptionTab,
          ]}
          isOnTop={tabsOnTop}
          setOnTop={setTabsOnTop}
          handleScroll={handleScroll}
        />
        <InView
          as='div'
          threshold={isMobile ? 0.3 : sectionVisibilityThreshold}
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Freedom)}
          id={SectionId.Freedom}
          ref={freedomRef}
        >
          <TextWithVideo
            title={t('rehau.products.heat.freedom.title')}
            text={t('rehau.products.heat.freedom.content')}
            videoId={String(products['heating.sections.freedom.videoHash'])}
            columnsTitle={t('rehau.products.heat.freedom.columns_title')}
            columnsData={[
              {
                title: t('rehau.products.heat.freedom.free_time_title'),
                text: t('rehau.products.heat.freedom.free_time_content'),
              },
              {
                title: t('rehau.products.heat.freedom.floor_title'),
                text: t('rehau.products.heat.freedom.floor_content'),
              },
              {
                title: t('rehau.products.heat.freedom.construction_title'),
                text: t('rehau.products.heat.freedom.construction_content'),
              },
            ]}
            darkBackground
          />
        </InView>
        <FullWidthImage src={ManWithDogImage} />
        <InView
          as='div'
          threshold={isMobile ? 0.15 : 0.3}
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Comfort)}
          id={SectionId.Comfort}
          ref={comfortRef}
        >
          <TextWithImage
            title={t('rehau.products.heat.comfort.title')}
            text={t('rehau.products.heat.comfort.content')}
            imageSrcList={[FamilyImage, SensorImage]}
            darkBackground
          />
        </InView>
        <InView
          as='div'
          threshold={sectionVisibilityThreshold}
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Development)}
          id={SectionId.Development}
          ref={developmentRef}
        >
          <SimpleText
            title={t('rehau.products.heat.development.title')}
            text={t('rehau.products.heat.development.content')}
          />
        </InView>
        <InView
          as='div'
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Montage)}
          id={SectionId.Montage}
          threshold={isMobile ? 0.3 : sectionVisibilityThreshold}
          ref={montageRef}
        >
          <TableContainer>
            <TableWrapper>
              <ProductTable tableType={ProductTableNameEnum.Heating} tableTitle={t('rehau.products.heat.table.title')} />
            </TableWrapper>
          </TableContainer>
        </InView>
        {!isInPopup && (
          <InView
            as='div'
            onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Calculator)}
            id={SectionId.Calculator}
            threshold={sectionVisibilityThreshold}
            ref={calculatorRef}
          >
            <ConfiguratorPreview buttonOnClick={navigateToCalculator} />
          </InView>
        )}
      </Container>
    </ProductPopupContext.Provider>
  );
});
