export enum BathroomFittingsRackType {
    FrameWc = 'frame_wc',
    FrameUrinal = 'frame_urinal',
    FrameBasin = 'frame_basin',
    FrameBidet = 'frame_bidet',
}

export enum BathroomFittingsType {
    Wc = 'wc',
    WashingWc = 'washing_wc',
    Urinal = 'urinal',
    Bath = 'bath',
    RainShower = 'rain_shower',
    Shower = 'shower',
    KitchenSink = 'kitchen_sink',
    SmallBasin = 'small_basin',
    Basin = 'basin',
    Bidet = 'bidet',
}
