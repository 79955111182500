import React, { RefObject, useRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { useInstallerContext } from '../../hooks/useInstallerContext.hook';
import { InstallerContextType } from '../../types/installerContext.types';
import { IconName } from '../../../../../../shared/enums';
import { useScrollLock } from '../../hooks/useScrollLock';
import { ButtonWithIcon } from '../buttonWithIcon/buttonWithIcon.component';
import { FooterBottomBar } from '../footerBottomBar/footerBottomBar.component';

import { FullscreenPopupClosingType } from './fullscreenPopup.enums';
import {
  CloseBar,
  CloseBarInner,
  CloseButton,
  CloseIcon,
  Container,
  Content,
  CustomAdditionalInfo,
  Header,
  HeadersContainer,
  InnerContainer,
  Subheader,
  TopSection,
} from './fullscreenPopup.styled';
import { FullscreenPopupProps } from './fullscreenPopup.types';

export const FullscreenPopup: React.FC<FullscreenPopupProps> = (props: FullscreenPopupProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    header,
    subheader,
    additionalInfoContent,
    additionalInfoContentInReadMore,
    children,
    isVisible = false,
    contentPaddingDisabled = false,
    closePopupAction,
    className,
    hasFixedCloseButton = false,
    externalRef,
    closeButtonLabel = t('rehau.global.back'),
  }: FullscreenPopupProps = props;
  const containerRef: RefObject<HTMLDivElement> = externalRef || useRef<HTMLDivElement>(null);
  const context: InstallerContextType | null = useInstallerContext();
  const closingType: FullscreenPopupClosingType =
    context && context.installerId ? FullscreenPopupClosingType.BarWithBackButton : FullscreenPopupClosingType.CrossIcon;
  useScrollLock(containerRef, isVisible);

  return (
    <Container id='popup-container' isVisible={isVisible} data-testid='popup-container' ref={containerRef} className={className}>
      {closingType === FullscreenPopupClosingType.CrossIcon && (
        <CloseButton onClick={closePopupAction} isFixed={hasFixedCloseButton}>
          <CloseIcon name={IconName.No} size={18} />
        </CloseButton>
      )}
      <InnerContainer
        paddingDisabled={contentPaddingDisabled}
        topPaddingDisabled={closingType === FullscreenPopupClosingType.BarWithBackButton}
      >
        {closingType === FullscreenPopupClosingType.BarWithBackButton && (
          <CloseBar>
            <CloseBarInner>
              <ButtonWithIcon
                text={closeButtonLabel || ''}
                icon={IconName.Back}
                onClick={closePopupAction}
                reversed
              />
            </CloseBarInner>
          </CloseBar>
        )}
        {(!!header || !!subheader || !!additionalInfoContent) && (
          <TopSection withMarginBottom={!!subheader || !!additionalInfoContent}>
            <HeadersContainer>
              <Header>{header}</Header>
              {!!subheader && <Subheader>{subheader}</Subheader>}
            </HeadersContainer>
            {additionalInfoContent && (
              <CustomAdditionalInfo content={additionalInfoContent} contentInReadMore={additionalInfoContentInReadMore} />
            )}
          </TopSection>
        )}
        {!!isVisible && <Content>{children}</Content>}
      </InnerContainer>
      <FooterBottomBar />
    </Container>
  );
};
