export enum IconName {
  ReguardSensor = 'icon-reguard-sensor',
  HeatDistributionFloorCeiling = 'icon-heat-distribution-floor-ceiling',
  Pin = 'icon-pin',
  Car = 'icon-car',
  FrameBidet = 'icon-frame-bidet',
  FrameUrinal = 'icon-frame-urinal',
  CloseOffer = 'icon-close-offer',
  SaveList = 'icon-save-list',
  PipeHorizontal = 'icon-pipe-horizontal',
  PipeVertical = 'icon-pipe-vertical',
  AddNote = 'icon-add-note',
  AddTask = 'icon-add-task',
  CommentSmall = 'icon-comment-small',
  DateTime = 'icon-date-time',
  Delete = 'icon-delete',
  MoreIconAddPhone = 'icon-more-icon-add-phone',
  MoreIconCallCustomer = 'icon-more-icon-call-customer',
  MoreIconCloseOffer = 'icon-more-icon-close-offer',
  MoreIconMailCustomer = 'icon-more-icon-mail-customer',
  MoreIconMove = 'icon-more-icon-move',
  MoreIconView = 'icon-more-icon-view',
  Rehau = 'icon-rehau',
  ToDoEdit = 'icon-to-do-edit',
  ToDoEmpty1 = 'icon-to-do-empty-1',
  ToDoEmpty = 'icon-to-do-empty',
  Union = 'icon-Union',
  RefreshBig = 'icon-refresh-big',
  RefreshList = 'icon-refresh-list',
  CheckList = 'icon-check-list',
  Instructions = 'icon-instructions',
  Refresh = 'icon-refresh',
  AdminInstallerBig = 'icon-admin-installer-big',
  AdminLeadCold = 'icon-admin-lead-cold',
  AdminLeadHot = 'icon-admin-lead-hot',
  AdminLeadWarm = 'icon-admin-lead-warm',
  AdminReleasedNo = 'icon-admin-released-no',
  AdminReleasedYes = 'icon-admin-released-yes',
  MontageSummary = 'icon-montage-summary',
  Wind = 'icon-wind',
  CheckBenefit = 'icon-check-benefit',
  House = 'icon-house',
  CheckCircle = 'icon-check-circle',
  InfoTooltip = 'icon-info-tooltip',
  VisibilityOff = 'icon-visibility-off',
  Visibility = 'icon-visibility',
  AdminForward = 'icon-admin-forward',
  CalendarBig = 'icon-calendar-big',
  Dishwasher = 'icon-dishwasher',
  Garage = 'icon-garage',
  GardenHose = 'icon-garden-hose',
  WashingMachine = 'icon-washing-machine',
  AdminUnblock = 'icon-admin-unblock',
  AdminRefresh = 'icon-admin-refresh',
  AdminFilters = 'icon-admin-filters',
  Sort = 'icon-sort',
  Calendar = 'icon-calendar',
  Income = 'icon-income',
  Offers = 'icon-offers',
  AdminBlock = 'icon-admin-block',
  AdminCheck = 'icon-admin-check',
  AdminClose = 'icon-admin-close',
  AdminDashboard = 'icon-admin-dashboard',
  AdminDelete = 'icon-admin-delete',
  AdminDownload = 'icon-admin-download',
  AdminDropdownBig = 'icon-admin-dropdown-big',
  AdminDropdownSmall = 'icon-admin-dropdown-small',
  AdminEditProfile = 'icon-admin-edit-profile',
  AdminEditSetup = 'icon-admin-edit-setup',
  AdminInstaller = 'icon-admin-installer',
  AdminLogout = 'icon-admin-logout',
  AdminManagement = 'icon-admin-management',
  AdminMap = 'icon-admin-map',
  AdminMore = 'icon-admin-more',
  AdminOffers = 'icon-admin-offers',
  AdminPlus = 'icon-admin-plus',
  AdminResend = 'icon-admin-resend',
  AdminSave = 'icon-admin-save',
  AdminSearch = 'icon-admin-search',
  AdminSort = 'icon-admin-sort',
  AdminUser = 'icon-admin-user',
  AdminVerify = 'icon-admin-verify',
  AdminChangeStatus = 'icon-admin-change-status',
  AdminOverview = 'icon-admin-overview',
  Fullscreen = 'icon-fullscreen',
  Pause = 'icon-pause',
  Play = 'icon-play',
  Configure = 'icon-configure',
  Installer = 'icon-installer',
  Facebook = 'icon-facebook',
  Linkedin = 'icon-linkedin',
  Twitter = 'icon-twitter',
  Youtube = 'icon-youtube',
  Edit = 'icon-edit',
  Embed = 'icon-embed',
  SitePreview = 'icon-site-preview',
  HamburgerSmall = 'icon-hamburger-small',
  More = 'icon-more',
  Sync = 'icon-sync',
  ConflictBig = 'icon-conflict-big',
  Visilibity = 'icon-visilibity',
  WaterCirculationSystem = 'icon-water-circulation-system',
  WaterFreshModule = 'icon-water-fresh-module',
  WaterSoftener = 'icon-water-softener',
  WaterWarmModule = 'icon-water-warm-module',
  HeatDistributionAll = 'icon-heat-distribution-all',
  HeatDistributionWallUnderflow = 'icon-heat-distribution-wall-underflow',
  Remove = 'icon-remove',
  ArrowBig = 'icon-arrow-big',
  ArrowSmall = 'icon-arrow-small',
  Electric = 'icon-electric',
  Hamburger = 'icon-hamburger',
  FrameSink = 'icon-frame-sink',
  FrameWc = 'icon-frame-wc',
  ValvePressure = 'icon-valve-pressure',
  ValveVertical = 'icon-valve-vertical',
  VentilationSystem = 'icon-ventilation-system',
  VentilationUnit = 'icon-ventilation-unit',
  WaterDistribution = 'icon-water-distribution',
  DefaultIcon = 'icon-default-icon',
  HotUtilityWaterTank = 'icon-hot-utility-water-tank',
  House19711999 = 'icon-house-1971-1999',
  HouseFrom2000 = 'icon-house-from-2000',
  HouseTill1970 = 'icon-house-till-1970',
  LinearInstallation = 'icon-linear-installation',
  RaupianoLight = 'icon-raupiano-light',
  RaupianoPlus = 'icon-raupiano-plus',
  RegulationNeaHt = 'icon-regulation-nea-ht',
  RegulationNeaHtc = 'icon-regulation-nea-htc',
  RingInstallation = 'icon-ring-installation',
  ServiceShowerHosesAerators = 'icon-service-shower-hoses-aerators',
  TeeInstallation = 'icon-tee-installation',
  WaterReducerWithFilter = 'icon-water-reducer-with-filter',
  WaterSystemCheck = 'icon-water-system-check',
  Star = 'icon-star',
  AirHeating = 'icon-air-heating',
  ArrowDown = 'icon-arrow-down',
  ArrowLeft = 'icon-arrow-left',
  ArrowRight = 'icon-arrow-right',
  ArrowUp = 'icon-arrow-up',
  Back = 'icon-back',
  Bath = 'icon-bath',
  Bathroom = 'icon-bathroom',
  Bedroom = 'icon-bedroom',
  Bidet = 'icon-bidet',
  Check = 'icon-check',
  ChildRoom = 'icon-child-room',
  Conflict = 'icon-conflict',
  ControlSystem = 'icon-control-system',
  Corridors = 'icon-corridors',
  DiningRoom = 'icon-dining-room',
  DistrictHeating = 'icon-district-heating',
  DressingRoom = 'icon-dressing-room',
  DrinkingWaterInstallation = 'icon-drinking-water-installation',
  Emoji = 'icon-emoji',
  Minus = 'icon-minus',
  Gas = 'icon-gas',
  GeothermalHeating = 'icon-geothermal-heating',
  GuestRoom = 'icon-guest-room',
  HeatDistribution = 'icon-heat-distribution',
  HeatDistributionCeiling = 'icon-heat-distribution-ceiling',
  HeatDistributionUnderflow = 'icon-heat-distribution-underflow',
  HeatDistributionWall = 'icon-heat-distribution-wall',
  Heating = 'icon-heating',
  HeatingCooling = 'icon-heating-cooling',
  Info = 'icon-info',
  Kitchen = 'icon-kitchen',
  KitchenSink = 'icon-kitchen-sink',
  LivingRoom = 'icon-living-room',
  Loading = 'icon-loading',
  Logout = 'icon-logout',
  Mail = 'icon-mail',
  Map = 'icon-map',
  No = 'icon-no',
  Office = 'icon-office',
  Oil = 'icon-oil',
  Pellet = 'icon-pellet',
  Phone = 'icon-phone',
  Photovoltaic = 'icon-photovoltaic',
  Pipes = 'icon-pipes',
  Plus = 'icon-plus',
  RainShower = 'icon-rain-shower',
  Reguard = 'icon-reguard',
  Regulation = 'icon-regulation',
  Resource = 'icon-resource',
  Save = 'icon-save',
  Search = 'icon-search',
  Shower = 'icon-shower',
  Sink = 'icon-sink',
  SmallSink = 'icon-small-sink',
  SolarPanels = 'icon-solar-panels',
  StorageRoom = 'icon-storage-room',
  LoadFile = 'icon-load-file',
  Urinal = 'icon-urinal',
  WashingWc = 'icon-washing-wc',
  Wc = 'icon-wc',
  Wood = 'icon-wood',
  Yes = 'icon-yes',
}
