import styled from 'styled-components';

import { Color } from '../../../enums/color.enum';
import { StyledComponent } from '../../../types/styledComponent.types';
import { CustomLink } from '../../customLink/customLink.component';
import { Button } from '../../button/button.component';

export const ButtonsWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-wrap: wrap;
  & a {
    margin-right: 10px;
    margin-bottom: 10px;

    :last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
`;

export const Link: StyledComponent<typeof CustomLink> = styled(CustomLink)`
  color: ${Color.White};
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 5px;
`;
