import React, { ChangeEvent, FC, RefObject, useEffect, useRef, useState } from 'react';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { TransProps, useTranslation } from 'react-i18next';

import { RouteInfo } from '@rehau/shared/models';
import { AreaField } from '@rehau/shared/forms/elements';
import { UseState } from '../../types/useState.types';
import { useCountryConfig } from '../../hooks/useCountryConfig.hook';
import { UseCountryConfig } from '../../types/useCountryConfig.types';
import { useRoutesInfo } from '../../hooks/useRoutesInfo.hook';
import { findPathByName } from '../../utils/findPathByName.utils';
import { Tooltip } from '../tooltip/tooltip.component';
import { Icon } from '../icon/icon.component';
import { ExternalPathNameEnum, IconName } from '@rehau/shared/enums';
import { Color } from '../../enums/color.enum';

import { SliderInputProps } from './sliderInput.types';
import {
  AreaText,
  AreaTextContainer,
  Button,
  Container,
  FormContainer,
  GreyAreaText,
  SliderContainer,
  StyledRangeInput,
  StyledValidationBar,
  Sup,
  TooltipContainer,
  ValueContainer,
  ValueInput
} from './sliderInput.styled';

export const SliderInput: FC<SliderInputProps> = (props: SliderInputProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    minValue = 1,
    maxValue = 350,
    component = new AreaField('', '', '', true),
    handleSave = (): void => undefined,
    tooltipProps,
    validationError = t('rehau.calculator.components.slider_input.validation_error'),
  }: SliderInputProps = props;
  const ref: RefObject<HTMLInputElement> = useRef(null);
  const routesArray: RouteInfo[] = useRoutesInfo();
  const [value, setValue]: UseState<number> = useState(Number(component.value) || minValue);
  const [isTyping, setIsTyping]: UseState<boolean> = useState<boolean>(false);
  const { config }: UseCountryConfig = useCountryConfig();
  const saveValue: () => void = (): void => {
    component.setValue(value);
    component.validate();
    handleSave();
  };

  const debouncedSaveValue: () => void = useDebouncedCallback((): void => saveValue(), 300);

  const handleChange: (e: ChangeEvent<HTMLInputElement>) => void = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue: number = Number(e.target.value);
    setCSSProperty(newValue);
    setValue(newValue);
    debouncedSaveValue();
  };

  const handleButtonClick: (newValue: number) => void = (newValue: number): void => {
    if (newValue > maxValue || newValue < minValue) {
      return;
    }
    setCSSProperty(newValue);
    setValue(newValue);
    debouncedSaveValue();
  };

  // sets progress bar lenght
  const setCSSProperty: (currentValue: number) => void = (currentValue: number): void => {
    const percent: number = ((Number(currentValue) - minValue) / (maxValue - minValue)) * 100;

    if (ref.current) {
      ref.current.style.setProperty('--webkitProgressPercent', `${percent}%`);
    }
  };

  const submitHandler: (values: FormikValues, helpers: FormikHelpers<{ meters: number }>) => void = (
    values: FormikValues,
    helpers: FormikHelpers<{ meters: number }>
  ): void => {
    let newValue: number = Number(values.meters);
    if (values.meters > maxValue) {
      newValue = maxValue;
    }

    if (values.meters < minValue) {
      newValue = minValue;
    }

    setCSSProperty(newValue);

    component.setValue(newValue);
    setValue(newValue);
    handleSave();
    helpers.resetForm();
  };

  const handleFocus: () => void = (): void => {
    setIsTyping(true);
  };

  useEffect(
    (): void => {
      setCSSProperty(value);
    },
    []
  );

  return (
    <>
      <Container>
        <FormContainer>
          <Button onClick={(): void => handleButtonClick(value - 1)}>
            <Icon size={16} name={IconName.Minus} color={Color.Green} />
          </Button>
          <Formik initialValues={{ meters: value }} onSubmit={submitHandler} enableReinitialize={!!isTyping}>
            {({ handleSubmit, values, setFieldValue, resetForm }: FormikProps<FormikValues>): JSX.Element => (
              <form onSubmit={handleSubmit}>
                <ValueContainer>
                  <ValueInput
                    value={isTyping ? values.meters : value}
                    onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                      e.preventDefault();
                      const currentValue: string = e.target.value;
                      const regex: RegExp = /^(0*[0-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                      if (regex.test(currentValue.toString()) || currentValue === '') {
                        setFieldValue('meters', currentValue);
                      }
                    }}
                    valueLength={isTyping ? String(values.meters).length : String(value).length}
                    onFocus={handleFocus}
                    onBlur={(): void => {
                      handleSubmit();
                      setIsTyping(false);
                    }}
                    name='meters'
                    aria-label='meters'
                  />
                  <AreaText>
                    m<Sup>2</Sup>
                  </AreaText>
                  <button data-testid='submit-button' type='submit' />
                </ValueContainer>
              </form>
            )}
          </Formik>
          <SliderContainer>
            <StyledRangeInput
              id='rangeInput'
              name='rangeInput'
              aria-label='rangeInput'
              type='range'
              min={minValue}
              max={maxValue}
              value={value}
              onChange={handleChange}
              onBlur={saveValue}
              step='1'
              ref={ref}
            />
            <AreaTextContainer data-testid='limit-values'>
              <GreyAreaText>
                {minValue} m<Sup>2</Sup>
              </GreyAreaText>
              <GreyAreaText>
                {maxValue} m<Sup>2</Sup>
              </GreyAreaText>
            </AreaTextContainer>
          </SliderContainer>
          <Button isLast onClick={(): void => handleButtonClick(value + 1)}>
            <Icon size={16} name={IconName.Plus} color={Color.Green} />
          </Button>
        </FormContainer>
        {!!tooltipProps &&
          <TooltipContainer>
            <Tooltip
              tooltipSettings={{
                title: tooltipProps.title,
                content: t(tooltipProps.content).replace(/\[contactUrl\]/g, findPathByName(ExternalPathNameEnum.Contact, routesArray)),
              }}
            />
          </TooltipContainer>
        }
        </Container>
        <StyledValidationBar
          errorsArray={component.valid === false ? [{ message: String(validationError) }] : []}
        />
    </>
  );
};
