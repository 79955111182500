export enum EmailNumberEnum {
  WarmLeadNotificationForInstaller = 1,
  HotLeadEmailVerified = 2,
  OrderCallToInstaller = 3,
  OptionalFieldsFulfilled = 4,
  InstallerContactNotification = 5,
  NoContactFromInstaller = 6,
  QuickSummary = 7,
  Summary = 8,
  HotLeadSummary = 9,
  OrderCallToCustomer = 10,
  ExternalInstaller = 11,
  ExternalInstallerSummary = 12,
  InstallerRegistration = 13,
  InstallerRegistrationAccept = 14,
  ChangePassword = 15,
  ChangePasswordConfirmation = 16,
  UnverifiedColdNotification = 17,
  VerifiedWarmNotification = 18,
  UnverifiedHotFirstNotification = 19,
  UnverifiedHotSecondNotification = 20,
  UnverifiedWarmFirstNotification = 21,
  UnverifiedWarmSecondNotification = 22,
  WarmLeadNotificationForAdministration = 23,
  VerifiedWarmSecondNotification = 24,
  DeclineAgreementNotification = 25,
  NoApprovalAgreementNotificationForInstaller = 26,
  NoApprovalAgreementNotificationForAdministration = 27,
  InstallerRegistrationReject = 28,
  EmailChangeByAdmin = 29,
  PasswordChangeByAdmin = 30,
  EmailAndPasswordChangeByAdmin = 31,
  EmailChange = 32,
  InstallerRegistrationAcceptPromoSetups = 34,
  BeforeDeployDayNotification = 35,
  DeployDayNotification = 36,
  AcceptedHotLeadOffer = 37,
  AcceptedWarmLeadOffer = 38,
  RejectedOffer = 39,
  RejectedOfferReminder = 40,
  ConfirmEmail = 41,
  WarmLeadEmailVerified = 42,
  CompanyFirstSetupPublished = 43,
}
