import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { MapContainer } from '../../../../../../../shared/forms/components';
import { FieldElement, FieldElementProps } from '../types';
import { LocationInput } from '../../locationInput/locationInput.component';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';

export const MapFieldElement: FieldElement<MapContainer> = (props: FieldElementProps<MapContainer>): JSX.Element => {
  const { component }: FieldElementProps<MapContainer> = props;
  const { t }: TransProps<never> = useTranslation();
  const values: ObjectIteratorInterface = component.getValues(true);

  return (
    <LocationInput
      latitude={values.latitude as number}
      longitude={values.longitude as number}
      inputLabel={t(component.title || '')}
      address={values.address as string}
      postalCode={values.postalCode as string}
      city={values.city as string}
      country={values.country as string}
    />
  );
};
