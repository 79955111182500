import styled from 'styled-components';

import { StyledComponent } from '../../types/styledComponent.types';
import { Color } from '../../enums/color.enum';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { CustomLink } from '../customLink/customLink.component';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;

  @media ${Breakpoint.Mobile} {
    flex-direction: column-reverse;
  }
`;

export const Image: StyledComponent<'img'> = styled.img`
  max-width: 282px;
  aspect-ratio: 4 / 3;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media ${Breakpoint.Mobile} {
    max-width: unset;
  }
`;

export const Title: StyledComponent<'h2'> = styled.h2`
  font-size: 18px;
  font-weight: ${FontWeight.Bold};
  line-height: 24px;
  margin-bottom: 8px;
  margin-left: 12px;
`;

export const Description: StyledComponent<'p'> = styled.p`
  line-height: 22px;
  margin-bottom: auto;
  margin-left: 12px;
`;

export const Wrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 32px 40px 24px 28px;
  background-color: ${Color.Tint4};
  width: 100%;

  @media ${Breakpoint.Mobile} {
    padding: 20px 20px 16px 8px;
    width: auto;
  }
`;

export const IconContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
`;

export const StyledCommonLink: StyledComponent<typeof CustomLink> = styled(CustomLink)`
  height: 44px;
  color: ${Color.ActiveRed};
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  display: flex;
  margin-left: 12px;

  @media ${Breakpoint.Mobile} {
    margin-top: 12px;
  }
`;
