import React, { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';

import { fieldValueType } from '@rehau/shared/forms/types';
import { TextField } from '../../../../../../shared/forms/elements';
import { ValidationStatus } from '../../enums/validationStatus.enum';
import { UseState } from '../../types/useState.types';
import { useEffectAfterMount } from '../../hooks/useEffectAfterMount';
import { LettersCounter } from '../lettersCounter/lettersCounter.component';
import { FrontendComponentEnum, RegexpTypeEnum } from '@rehau/shared/enums';
import { CustomRegexpValidationItem } from '@rehau/shared/forms/validations';

import { InputControllerProps } from './inputController.types';
import { InputContainer } from './inputContainer/inputContainer.component';
import { ContainerType } from './inputContainer/containerType.enum';
import { LettersQuantity, LettersQuantityContainer } from './textarea/textarea.styled';

export const InputController: React.FC<InputControllerProps> = (props: InputControllerProps): JSX.Element => {
  const {
    children,
    label,
    additionalInfoContent,
    additionalInfoContentInReadMore,
    tooltipProps,
    containerType,
    component = new TextField('', '', '', '', true),
    handleSave = (): void => undefined,
    setValues = (): void => undefined,
    regex,
    disabled = false,
    hasLettersQuantityCounter = false,
  }: InputControllerProps = props;
  const [localValue, setLocalValue]: UseState<string> = useState<string>(component.value as string ?? '');
  const [validationStatus, setValidationStatus]: UseState<ValidationStatus> = useState<ValidationStatus>(
    component.valid ? ValidationStatus.Valid : ValidationStatus.Untouched
  );
  const [focused, setFocused]: UseState<boolean> = useState<boolean>(false);

  const isSaveOnChangeAvailable: boolean = [
    FrontendComponentEnum.CostField,
    FrontendComponentEnum.AreaField,
    FrontendComponentEnum.NumberField,
    FrontendComponentEnum.CostAreaField,
  ].includes(component.frontendComponent);

  const validate: (value: string) => boolean = (value: string): boolean => {
    setValues({ [component.id]: value });
    const validationSuccess: boolean = component.validate();
    setValidationStatus(validationSuccess ? ValidationStatus.Valid : ValidationStatus.Invalid);

    return validationSuccess;
  };

  const onFocus: () => void = (): void => {
    setFocused(true);
  };

  const onChange: (value: string) => void = (value: string): void => {
    const inputValue: string = value;
    if (regex) {
      const isValid: boolean = regex.test(value);
      if (!isValid) {
        return;
      }
    }

    const maxLengthRegex: CustomRegexpValidationItem | undefined = component.getValidationRegex(RegexpTypeEnum.MaxLength);
    if (maxLengthRegex?.pattern.test(value)) {
      setLocalValue(inputValue);
    } else if (!maxLengthRegex) {
      setLocalValue(inputValue);
    }

    if (validationStatus !== ValidationStatus.Untouched || isSaveOnChangeAvailable || (inputValue && !focused)) {
      const oldValue: fieldValueType = !!component.value ? JSON.parse(JSON.stringify(component.value)) : '';
      const valueChanged: boolean = !!oldValue ? oldValue.toString() !== inputValue : inputValue !== '';
      if (valueChanged) {
        const validationSuccess: boolean = validate(inputValue);
        if (isSaveOnChangeAvailable && validationSuccess) {
          handleSave();
        }
      }
    }
  };

  const handleBlur: () => void = (): void => {
    setFocused(false);
    if (validate(localValue) && !isSaveOnChangeAvailable) {
      handleSave();
    }
  };

  useEffectAfterMount(
    (): void => {
      if (component.valid === undefined) {
        setValidationStatus(ValidationStatus.Untouched);
      }
    },
    [component.valid]
  );

  useEffectAfterMount((): void => setLocalValue((component.value as string) || ''), [component.value]);

  return (
    <>
      <InputContainer
        validationStatus={validationStatus || ValidationStatus.Untouched}
        label={label}
        additionalInfoContent={additionalInfoContent}
        additionalInfoContentInReadMore={additionalInfoContentInReadMore}
        tooltipProps={tooltipProps}
        containerType={!!containerType ? containerType : ContainerType.Standard}
        validationMessages={component.validationMessages}
        disabled={disabled}
      >
        {React.cloneElement(children, { disabled, onChange, onFocus, onBlur: handleBlur, value: localValue })}
      </InputContainer>
      {hasLettersQuantityCounter && (
        <LettersCounter value={localValue.length} maxValue={component.validation?.rules['maxLength']} />
      )}
    </>
  );
};
