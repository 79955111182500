import { NumberField } from '@rehau/shared/forms/elements/fields/number.field';
import { InfoBox, Tooltip } from '@rehau/shared/forms/elements';
import { NumberValidation } from '@rehau/shared/forms/validations';
import { FrontendComponentEnum } from '@rehau/shared/enums';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';

export class ClickNumberField extends NumberField {
  public valueSuffix?: string;
  public unlimitedMaxValue?: boolean;

  constructor(
      id: string,
      name: string,
      label: string | null,
      placeholder: string,
      required: boolean,
      validation: NumberValidation = new NumberValidation(),
      countryConfigMapping: ObjectIteratorInterface<string> = {},
      informationBox?: InfoBox,
      tooltip?: Tooltip,
      valueSuffix?: string,
      value?: number,
      unlimitedMaxValue?: boolean
  ) {
    super(
      id,
      name,
      label,
      placeholder,
      required,
      validation,
      countryConfigMapping,
      informationBox,
      tooltip,
      value
    );
    this.frontendComponent = FrontendComponentEnum.ClickNumberField;
    this.valueSuffix = valueSuffix;
    this.unlimitedMaxValue = unlimitedMaxValue;
  }
}
