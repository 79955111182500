import { propEq } from 'ramda';
import styled, { css } from 'styled-components';

import { BoxShadow } from '../../enums/boxShadow.enum';
import { Color } from '../../enums/color.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';

import { RoundButtonVariants } from './roundButton.enums';
import { ContainerProps } from './roundButton.types';

export const Text: StyledComponent<'p'> = styled.p`
  color: ${Color.White};
  font-size: 15px;
  line-height: 18px;
  text-align: center;
`;

export const Container: StyledComponent<'button', ContainerProps> = styled.button`
  height: 42px;
  border-radius: 21px;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  background-color: ${Color.Black};
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  box-sizing: border-box;
  transition: 0.3s;

  ${styleWhenTrue(
    propEq('variant', RoundButtonVariants.Secondary),
    css`
      background-color: ${Color.White};
      border-radius: 1px solid ${Color.GreyStroke};

      ${Text} {
        color: ${Color.Black};
      }
    `
  )}

  :hover {
    background-color: ${Color.Green};

    ${Text} {
      color: ${Color.White};
    }
  }
`;
