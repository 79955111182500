import { useContext } from 'react';

import { ProductPopupContext } from '../contexts/productPopup.context';
import { ProductPopupContextType } from '../types/productPopupContextType.types';

export const useProductPopupContext: () => ProductPopupContextType = (): ProductPopupContextType => {
  const context: ProductPopupContextType = useContext(ProductPopupContext);
  if (!context) {
    return { isInPopup: false };
  }

  return context;
};
