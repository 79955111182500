import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';

export class ValidationMessages {
  public message: string;
  public params?: ObjectIteratorInterface;

  constructor(
    message: string,
    params?: ObjectIteratorInterface
    ) {
    this.message = message;
    this.params = params;
  }
}
