import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { StyledComponent } from '../../../../types/styledComponent.types';
import { styleWhenTrue } from '../../../../utils/style.utils';
import { Color } from '../../../../enums/color.enum';
import { Price } from '../../../../theme';
import { Breakpoint } from '../../../../enums/breakpoint.enum';

import { PriceLabelProps, PricesSectionProps, TaxInfoProps } from './priceSimulations.types';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${Color.GreyStroke};
  border-bottom: 1px solid ${Color.GreyStroke};
  padding-top: 32px;
  padding-bottom: 32px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column-reverse;
    background-color: ${Color.BgGrey};
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -20px;
    margin-right: -20px;
    border: 0;
  }
`;

export const BarsSection: StyledComponent<'div'> = styled.div`
  display: flex;
  max-width: 620px;
  width: 50%;

  @media ${Breakpoint.Mobile} {
    padding-top: 64px;
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid ${Color.GreyStroke};
  }
`;

export const PricesSection: StyledComponent<'div', PricesSectionProps> = styled.div<PricesSectionProps>`
  text-align: right;
  padding-left: 20px;
  width: ${({ $fullWidth }: PricesSectionProps): string => $fullWidth ? '100%' : 'calc(50% - 20px)'};

  @media ${Breakpoint.Mobile} {
    width: 100%;
    padding-left: 0;
    text-align: left;
  }
`;

export const TotalPrice: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 34px;

  @media ${Breakpoint.Mobile} {
    justify-content: space-between;
  }
`;

export const TotalPriceValue: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TaxInfo: StyledComponent<'div', TaxInfoProps> = styled.div<TaxInfoProps>`
  font-size: 18px;
  line-height: 22px;
  color: ${Color.Grey};
  margin-top: 8px;

  ${styleWhenTrue(prop('withBiggerFonts'), css`
    font-size: 22px;
  `)}
`;

export const SimulationPriceRow: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  & + & {
    margin-top: 8px;
  }

  @media ${Breakpoint.Mobile} {
    justify-content: space-between;
  }
`;

export const PriceLabel: StyledComponent<'div', PriceLabelProps> = styled.div<PriceLabelProps>`
  font-size: 18px;
  line-height: 22px;
  color: ${Color.Grey};
  margin-right: 20px;

  ${styleWhenTrue(prop('withBiggerFonts'), css`
    font-size: 22px;
  `)}

  @media ${Breakpoint.Mobile} {
    width: auto;
  }
`;

export const StyledPrice: StyledComponent<typeof Price> = styled(Price)``;
