import { PhoneValidation } from '@rehau/shared/forms/validations';
import { Autocomplete, FrontendComponentEnum } from '@rehau/shared/enums';
import { InfoBox, Tooltip } from '@rehau/shared/forms/elements';
import { TextField } from '@rehau/shared/forms/elements/fields';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { Config } from '@rehau/shared/models/countryConfig';

export class PhoneField extends TextField {
  constructor(
    id: string,
    name: string,
    label: string | null,
    placeholder: string,
    required: boolean,
    validation: PhoneValidation = new PhoneValidation(),
    countryConfigMapping?: ObjectIteratorInterface<string>,
    informationBox?: InfoBox,
    tooltip?: Tooltip,
    autocomplete?: Autocomplete
  ) {
    super(
      id,
      name,
      label,
      placeholder,
      required,
      validation,
      countryConfigMapping,
      informationBox,
      tooltip,
      autocomplete
    );
    this.frontendComponent = FrontendComponentEnum.PhoneField;
  }

  public updateCountryConfig(countryConfig: Config): void {
    super.updateCountryConfig(countryConfig);
  }
}
