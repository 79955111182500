export enum LocalStorageKey {
  Token = 'rehauSetup_token',
  AuthData = 'rehauSetup_authData',
  AdminToken = 'rehauAdmin_token',
  AdminAuthData = 'rehauAdmin_authData',
  ActiveTabIsExpandedValue = 'activeTabIsExpandedValue',
  InactiveTabIsExpandedValue = 'inactiveTabIsExpandedValue',
  ArchiveTabIsExpandedValue = 'archiveTabIsExpandedValue',
  SetupTestCalculationEntry = 'setupTestCalculationEntry',
  Reports = 'reports',
}
