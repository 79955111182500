import styled from 'styled-components';

import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Color } from '../../../../enums/color.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { StyledComponent } from '../../../../types/styledComponent.types';

export const Wrapper: StyledComponent<'div'> = styled.div``;

export const Content: StyledComponent<'div'> = styled.div`
  width: 100%;
`;

export const Row: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  :first-child {
    margin-top: 20px;
  }
`;

export const Column: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
`;

export const RowStartAlign: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 20px;
  }

  :last-child {
    margin-bottom: 40px;
  }
`;

export const ContentInnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Index: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${Color.Grey};
  min-width: 43px;
  padding-right: 10px;
  box-sizing: border-box;

  @media ${Breakpoint.Additional480} {
    min-width: 22px;
    padding-right: 7px;
  }
`;

export const ServiceTitle: StyledComponent<'p'> = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 18px;
  line-height: 24px;
  color: ${Color.Black};
  word-break: break-word;

  @media ${Breakpoint.Additional480} {
    max-width: 250px;
  }

  @media ${Breakpoint.Additional375} {
    max-width: 180px;
  }
`;

export const ServiceType: StyledComponent<'span'> = styled.span`
  color: ${Color.Green};
  display: inline-block;
`;

export const Dash: StyledComponent<'div'> = styled.div`
  width: 12px;
  height: 3px;
  background-color: ${Color.Black};
  margin-right: 12px;
  flex-shrink: 0;
`;

export const ServicePartText: StyledComponent<'p'> = styled.p`
  font-size: 16px;
  line-height: 24px;
  word-break: break-word;

  @media ${Breakpoint.Tablet} {
    max-width: 350px;
  }

  @media ${Breakpoint.Additional480} {
    max-width: 170px;
  }

  @media ${Breakpoint.Additional375} {
    max-width: 160px;
  }
`;

export const DescriptionBox: StyledComponent<'div'> = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${Color.Grey};
  max-width: calc(100% - 140px);
  margin-top: 10px;

  @media ${Breakpoint.Mobile} {
    max-width: 100%;
  }
`;
