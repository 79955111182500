import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { animateScroll } from 'react-scroll';

import {
  CircleLoader,
  EmailScreen,
  FieldComponent,
  findPathByName,
  Step,
  UseCountryConfig,
  useRedirect,
  UseRedirectOutput,
  useRoutesInfo,
  UseState } from '@rehau-fe/ui/src';
import { ValidationBar } from '@rehau-fe/ui/src/components/validationBar/validationBar.component';
import { componentTypes, fieldValueType } from '@rehau/shared/forms/types';
import { RegisterForm } from '@rehau/shared/forms/register';
import { BaseAbstractContainer } from '@rehau/shared/forms/baseAbstract.container';
import { useCountryConfig } from '@rehau-fe/ui/src/hooks/useCountryConfig.hook';
import { registerNewInstaller } from '@rehau-fe/app/api';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { ApiErrorModel } from '@rehau/shared/models/responses';
import { FieldComponentWrapper } from '@rehau-fe/ui/src/components/fieldComponent/fieldComponent.component';
import { getFieldContainerType } from '@rehau-fe/ui/src/components/fieldComponent/utils/getFieldContainerType';
import { WebsitePathNameEnum } from '@rehau/shared/enums';
import { RouteInfo } from '@rehau/shared/models';

import { Container } from './registration.styled';
import { RegisterFormStatus } from './registration.enums';

export const Registration: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();
  const [registrationForm, setRegistrationForm]: UseState<RegisterForm | null> = useState<RegisterForm | null>(null);
  const [sendStatus, setSendStatus]: UseState<RegisterFormStatus> = useState<RegisterFormStatus>(RegisterFormStatus.Initial);
  const [email, setEmail]: UseState<string> = useState<string>('');
  const [requestError, setRequestError]: UseState<string> = useState<string>('');
  const handleRedirect: UseRedirectOutput = useRedirect();
  const routesArray: RouteInfo[] = useRoutesInfo();

  useEffect(
    (): void => {
      if (isConfigAvailable && !registrationForm) {
        setRegistrationForm(new RegisterForm({}, config));
      }
    },
    [config, isConfigAvailable]
  );

  const setValues: (
    values: ObjectIteratorInterface<fieldValueType>
  ) => void = (values: ObjectIteratorInterface<fieldValueType>): void => {
    registrationForm?.setValues(values);
    if (requestError) {
      setRequestError('');
    }
  };

  const submitForm: () => void = (): void => {
    const validationSuccess: boolean = (registrationForm as BaseAbstractContainer).validate();
    if (validationSuccess) {
      const data: ObjectIteratorInterface<fieldValueType> = (registrationForm as BaseAbstractContainer).getValues();
      setEmail(data['email'] as string);
      setSendStatus(RegisterFormStatus.Loading);
      registerNewInstaller(data)
        .then((): void => {
          setSendStatus(RegisterFormStatus.Success);
          animateScroll.scrollToTop();
        })
        .catch((error: ApiErrorModel): void => {
          setSendStatus(RegisterFormStatus.Initial);
          setRequestError(t(error.message, error.messageParams)
            .replace(/\[supportEmail\]/g, config.variables.website['support_email'] as string));
        });
    } else {
      setRequestError('rehau.api.error.form_has_errors');
    }
  };

  const goToMain: () => void = (): void => {
    handleRedirect(findPathByName(WebsitePathNameEnum.Home, routesArray), WebsitePathNameEnum.Home);
  };

  return (
    <Container paddingTopDisabled={sendStatus === RegisterFormStatus.Success } >
      {(sendStatus === RegisterFormStatus.Initial || sendStatus === RegisterFormStatus.Loading) && (
        <Step
          title={t('rehau.website.registration_view.title')}
          nextButtonLabel={t('rehau.website.registration_view.register_button')}
          handleNextClick={submitForm}
          loading={sendStatus === RegisterFormStatus.Loading}
          withBottomPadding={false}
        >
          {(!!registrationForm && !!registrationForm.elements)
            ? registrationForm.elements.map(
              (component: componentTypes, index: number): JSX.Element => (
                <FieldComponentWrapper
                  key={`${component.title}_${index}`}
                  className={getFieldContainerType(component)}
                >
                  <FieldComponent
                    innerComponent={component}
                    setValues={setValues}
                  />
                </FieldComponentWrapper>
              )
            )
            : <CircleLoader />
          }
          <ValidationBar errorsArray={requestError ? [{ message: requestError }] : []} />
        </Step>
       )}
      {sendStatus === RegisterFormStatus.Success && (
        <EmailScreen
          hasButton
          title={t('rehau.website.registration_view.success_title')}
          description={t('rehau.website.registration_view.success_description', { email })}
          buttonLabel={t('rehau.website.registration_view.go_to_main_button_label')}
          handleButtonClick={goToMain}
        />
      )}
    </Container>
  );
};
