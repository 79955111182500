import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { Color } from '../../../../enums/color.enum';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { ZIndex } from '../../../../enums/zIndex.enum';
import { CloseButton } from '../../../closeButton/closeButton.component';
import { styleWhenTrue } from '../../../../utils/style.utils';
import { Breakpoint } from '../../../../enums/breakpoint.enum';

import { ModalContainerProps } from './photoWithModal.types';

export const Container: StyledComponent<'div'> = styled.div``;

export const Image: StyledComponent<'img'> = styled.img`
  width: 100%;
  cursor: zoom-in;
`;

export const ModalContainer: StyledComponent<'div', ModalContainerProps> = styled.div`
  opacity: 0;
  pointer-events: none;

  ${styleWhenTrue(
    prop('isOpen'),
    css`
      opacity: 1;
      pointer-events: initial;
    `
    )}

  @media ${Breakpoint.Mobile} {
    display: none;
  }
`;

export const Modal: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: 100%;
  z-index: ${ZIndex.PhotoWithModal};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalInnerContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  max-width: 1625px;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const ImageInsideModal: StyledComponent<'img'> = styled.img`
  width: 90%;
  max-width: 1525px;
`;

export const StyledCloseButton: StyledComponent<typeof CloseButton> = styled(CloseButton)`
  right: 5%;
  top: 1px;
  padding: 12px;
`;
