import React from 'react';

import { Rating } from '../rating/rating.component';

import { Container, Label } from './lowBarWithRating.styled';
import { LowBarWithRatingProps } from './lowBarWithRating.types';

export const LowBarWithRating: React.FC<LowBarWithRatingProps> = (props: LowBarWithRatingProps): JSX.Element => {
  const { label, rate = 0, maxRate = 5 }: LowBarWithRatingProps = props;

  return (
    <Container>
      <Label>{label}</Label>
      <Rating rate={rate} maxRate={maxRate}/>
    </Container>
  );
};
