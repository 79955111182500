import { FrontendComponentEnum } from '@rehau/shared/enums';
import { componentTypes, fieldTypes, staticElementTypes } from '@rehau/shared/forms';
import { ContainerType } from '../fieldComponent.enums';

export const getFieldContainerType: (element: componentTypes | fieldTypes | staticElementTypes) => ContainerType = (
  element: componentTypes | fieldTypes | staticElementTypes
): ContainerType => {
  if (
    element.frontendComponent === FrontendComponentEnum.Checkbox ||
    element.frontendComponent === FrontendComponentEnum.CheckboxWithCompanyName ||
    element.frontendComponent === FrontendComponentEnum.CheckboxWithPrivacyPolicy
  ) {
    return ContainerType.Checkbox;
  }

  if (element.frontendComponent === FrontendComponentEnum.PopupWithVariants) {
    return ContainerType.PopupWithVariants;
  }

  if (element.frontendComponent === FrontendComponentEnum.ExpandedConditionalBox) {
    return ContainerType.ExpandedConditionalBox;
  }

  if (element.frontendComponent === FrontendComponentEnum.HorizontalFieldline
  ) {
    return ContainerType.HorizontalFieldline;
  }

  if (element.frontendComponent === FrontendComponentEnum.ConditionalAvailable) {
    return ContainerType.ConditionalAvailable;
  }

  if (element.frontendComponent === FrontendComponentEnum.HorizontalFieldlineWithChooseInstaller) {
    return ContainerType.ChooseInstallerContainer;
  }

  if (element.frontendComponent === FrontendComponentEnum.PasswordContainer) {
    return ContainerType.PasswordContainer;
  }

  return ContainerType.Standard;
};
