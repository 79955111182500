import React, { MouseEventHandler, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { Color } from '../../enums/color.enum';
import { IconName } from '../../../../../../shared/enums/iconName.enum';
import { Icon } from '../icon/icon.component';
import { UseState } from '../../types/useState.types';
import { Overlay } from '../../theme/overlay/overlay.styled';
import { TooltipTheme } from '@rehau/shared/enums';

import { SlidingPanel } from './components/slidingPanel/slidingPanel.component';
import { Container, ToolTipButton, TooltipContainer, TooltipText } from './tooltip.styled';
import { TooltipProps } from './tooltip.types';
import { TooltipType } from './tooltipType.enum';

export const Tooltip: React.FC<TooltipProps> = (props: TooltipProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    backButtonLabel = t('rehau.tooltip.back_button_label'),
    actionButtonLabel = t('rehau.tooltip.action_button_label'),
    type = TooltipType.Standard,
    text = t('rehau.tooltip.additional_text'),
    tooltipSettings,
    children,
    isButtonAttachedToBottom = false,
    className,
  }: TooltipProps = props;
  const [isPanel, setIsPanelOpen]: UseState<boolean> = useState<boolean>(false);

  const openPanel: MouseEventHandler = (): void => {
    setIsPanelOpen(true);
  };

  const closePanel: MouseEventHandler = (): void => {
    setIsPanelOpen(false);
  };

  return (
    <Container className={className} >
      {type === TooltipType.Standard ? (
        <ToolTipButton onClick={openPanel} data-testid='tooltip' tabIndex={-1} theme={tooltipSettings?.theme ?? TooltipTheme.Standard}>
          <Icon name={IconName.Info} color={Color.Black} size={10} />
        </ToolTipButton>
      ) : (
        <TooltipContainer onClick={openPanel}>
          <TooltipText tabIndex={-1}>{text}</TooltipText>
          <button tabIndex={-1}>
            <Icon name={IconName.ArrowRight} color={Color.Black} size={14} />
          </button>
        </TooltipContainer>
      )}
      <SlidingPanel
        title={t(tooltipSettings?.title || '')}
        text={t(tooltipSettings?.content || '')}
        backButtonLabel={backButtonLabel || ''}
        actionButtonLabel={actionButtonLabel || ''}
        isOpen={isPanel}
        onBackButtonClick={closePanel}
        children={children}
        isButtonAttachedToBottom={isButtonAttachedToBottom}
      />
      <Overlay isActive={isPanel} onClick={closePanel} data-testid='overlay' />
    </Container>
  );
};
