export enum Autocomplete {
  Off = 'off',
  CurrentPassword = 'current-password',
  Email = 'email',
  Username = 'username',
  NewPassword = 'new-password',
  Phone = 'phone',
  GivenName = 'given-name',
  FamilyName = 'family-name',
  Organization = 'organization',
  StreetAddress = 'street-address',
  PostalCode = 'postal-code',
  AddressLevel2 = 'address-level2',
}
