import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { BoxShadow } from '../../enums/boxShadow.enum';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { GridWidth } from '../../enums/gridWidth.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';
import { Button } from '../button/button.component';
import { Icon } from '../icon/icon.component';

import { ArrowButtonProps, CompanyImageProps, ProgressTextProps, RowProps, WrapperProps } from './chooseInstaller.types';

export const Wrapper: StyledComponent<'div', WrapperProps> = styled.div`
  width: 100%;
  max-width: ${GridWidth.Desktop};
  min-height: 170px;
  padding: 30px 28px 16px 72px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  border: 1px solid ${Color.Transparent};
  background-color: ${Color.White};

  ${styleWhenTrue(
    prop('active'),
    css`
      border: 1px solid ${Color.Green};
    `
  )};

  @media ${Breakpoint.Mobile} {
    padding: 20px;
  }

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    padding: 0;
  }
`;

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CompanyInfoContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  margin-bottom: 25px;
`;

export const CompanyName: StyledComponent<'p'> = styled.p`
  font-size: 20px;
  font-weight: ${FontWeight.Bold};
  line-height: 24px;
  margin-bottom: 5px;
  word-break: break-word;
`;

export const MainServicesLabel: StyledComponent<'span'> = styled.span`
  color: ${Color.Black};
  margin-right: 5px;
`;

export const MainServices: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 22px;
  color: ${Color.Grey};
  word-break: break-word;
`;

export const CompanyImg: StyledComponent<'div', CompanyImageProps> = styled.div`
  width: 130px;
  flex-shrink: 0;
  min-height: 100%;
  max-height: 128px;
  background-image: ${({ url }: CompanyImageProps): string => `url(${url})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @media ${Breakpoint.Mobile} {
    width: 150px;
  }
`;

export const AdditionalServices: StyledComponent<'div'> = styled.div`
  display: flex;
  padding: 20px 0;
  border-top: 1px solid ${Color.GreyStroke};

  @media ${Breakpoint.Mobile} {
    padding: 21px 0;
    border-top: 1px solid ${Color.GreyStroke};
    align-items: center;
    justify-content: space-between;
  }
`;

export const Content: StyledComponent<'div'> = styled.div`
  width: 100%;
  margin-left: 72px;
  height: 100%;

  @media ${Breakpoint.Mobile} {
    margin-left: 30px;
  }

  @media ${Breakpoint.Mobile} {
    margin-left: 0;
  }
`;

export const StyledChooseButton: StyledComponent<typeof Button> = styled(Button)`
  min-width: 130px;
`;

export const ButtonContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 250px;
  width: 100%;
  margin-left: 15px;

  @media ${Breakpoint.Mobile} {
    box-sizing: border-box;
    max-width: unset;
    margin-left: 0;
    padding: 20px 20px 35px;
  }
`;

export const ArrowButton: StyledComponent<'button', ArrowButtonProps> = styled.button`
  padding: 0;
  margin-left: 15px;
  ${styleWhenTrue(
    prop('active'),
    css`
      transform: rotate(180deg);
    `
  )}
`;

export const Collapse: StyledComponent<'div'> = styled.div`
  width: 100%;
`;

export const AdditionalServicesLabel: StyledComponent<'p'> = styled.p`
  font-weight: ${FontWeight.Black};
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  word-break: break-word;
  margin-right: 10px;
`;

export const InnerWrapper: StyledComponent<'div'> = styled.div`
  padding: 35px 20px 0;
`;

export const ImgContainer: StyledComponent<'div'> = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const ProgressContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Separator: StyledComponent<'div'> = styled.div`
  height: 20px;
  width: 1px;
  background-color: ${Color.GreyStroke};
  margin-left: 20px;
  margin-right: 20px;
`;

export const ProgressText: StyledComponent<'p', ProgressTextProps> = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 15px;
  line-height: 18px;
  color: ${Color.Grey};

  ${styleWhenTrue(
    prop('activeColor'),
    css`
      color: ${Color.Green};
    `
  )};
`;

export const ReadMoreText: StyledComponent<'p'> = styled.p`
  font-weight: ${FontWeight.Black};
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  word-break: break-word;
  margin-right: 10px;
  cursor: pointer;
`;

export const Row: StyledComponent<'div', RowProps> = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 0;
  border-top: 1px solid ${Color.GreyStroke};
  border-bottom: 1px solid ${Color.GreyStroke};

  ${styleWhenTrue(
    prop('expanded'),
    css`
      border-bottom: none;
    `
  )}
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  margin-left: -25px;
`;
