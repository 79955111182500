import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { ZIndex } from '../../enums/zIndex.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';
import { Icon } from '../icon/icon.component';

import { ContainerProps } from './smallDialog.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.Validation};
  border-radius: 2px;
  padding: 18px;
  border: 1px solid ${Color.GreyStroke};
  z-index: ${ZIndex.SmallDialog};
  width: 280px;
  box-sizing: border-box;
  transform: translateY(calc(100% + 20px));
  transition: 0.3s;
  ${styleWhenTrue(
    prop('isVisible'),
    css`
      transform: translateY(0);
    `
  )}
`;

export const TitleContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  margin-right: 10px;
`;

export const Title: StyledComponent<'div'> = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${Color.Black};
`;

export const Content: StyledComponent<'div'> = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${Color.Grey};
  margin: 5px 0 10px;
`;

export const ButtonsContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
`;

export const AcceptButton: StyledComponent<'button'> = styled.button`
  font-weight: ${FontWeight.Bold};
  font-size: 14px;
  line-height: 17px;
  color: ${Color.Green};
  padding: 0;
`;

export const CancelButton: StyledComponent<typeof AcceptButton> = styled(AcceptButton)`
  margin-left: 35px;
  color: ${Color.Grey};
`;
