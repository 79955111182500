import styled from 'styled-components';

import { BoxShadow } from '../enums/boxShadow.enum';
import { Color } from '../enums/color.enum';
import { FontWeight } from '../enums/fontWeight.enum';
import { StyledComponent } from '../types/styledComponent.types';

export const ActiveLabel: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Green};
  box-shadow: ${BoxShadow.BtnGreyShadow};
  color: ${Color.White};
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 10px;
  font-weight: ${FontWeight.Bold};
  line-height: 12px;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  border: 1px solid ${Color.Transparent};
  width: fit-content;
  height: fit-content;
`;

export const InProgressLabel: StyledComponent<typeof ActiveLabel> = styled(ActiveLabel)`
  border-color: ${Color.GreyStroke};
  background-color: ${Color.White};
  color: ${Color.Grey};
`;
