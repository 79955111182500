import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { TransProps, useTranslation } from 'react-i18next';

import { UseTimelineAnimationOutput } from '../../../../types/useTimelineAnimation.types';
import { useTimelineAnimation } from '../../../../hooks/useTimelineAnimation';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { assignColumnRef } from '../../utils/columnRef.utils';
import { Title } from '../title/title.component';
import { useProductPopupContext } from '../../../../hooks/useProductPopupContext.hook';
import { ProductPopupContextType } from '../../../../types/productPopupContextType.types';

import {
  Box,
  Column,
  Columns,
  Container,
  Image,
  ImageOverlay,
  ItemTitle,
  LabelMarkdown,
  StyledMarkdown,
  Wrapper,
} from './imageColumns.styled';
import { ColumnData, ImageColumnsProps } from './imageColumns.types';

export const ImageColumns: React.FC<ImageColumnsProps> = (props: ImageColumnsProps): JSX.Element => {
  const { className, title, columnsData = [] }: ImageColumnsProps = props;
  const { t }: TransProps<never> = useTranslation();
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const { isInPopup }: ProductPopupContextType = useProductPopupContext();
  const timelineAnimation: UseTimelineAnimationOutput = useTimelineAnimation(isInPopup);

  return (
    <Container className={className} data-testid='image-columns-section'>
      <Wrapper>
        {!!title && <Title>{title}</Title>}
        <Columns>
          {columnsData.map((item: ColumnData, index: number): JSX.Element => {
            const columnRef: React.RefObject<HTMLDivElement> = assignColumnRef(index, timelineAnimation);

            return (
              <Column key={index} ref={columnRef} data-testid='image-column'>
                <Image src={isTablet ? item.imageSrcMobile : item.imageSrc} />
                <ImageOverlay />
                <LabelMarkdown text={item.label} />
                <Box>
                  <ItemTitle>{item.title}</ItemTitle>
                  <StyledMarkdown text={item.text} />
                </Box>
              </Column>
            );
          })}
        </Columns>
      </Wrapper>
    </Container>
  );
};
