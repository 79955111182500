export enum SectionId {
  Advantages = 'advantages',
  System = 'system',
  Action = 'action',
  App = 'app',
  Sensor = 'sensor',
  Safety = 'safety',
  Installation = 'installation',
  Calculator = 'calculator',
}
