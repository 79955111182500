export enum ReGuardFieldEnum {
  ExactAddress = 'exactAddress',
  Montage = 'montage',
  ReguardSensorsActive = 'reguard_sensors_active',
  ReguardSensorsCount = 'reguard_sensors_count',
  // values from creator
  Object = 'object',
  YearOfConstruction = 'yearOfConstruction',
  Renovation = 'renovation',
  Material = 'material',
  ShutOffValve = 'shutOffValve',
  PlaceAfterNoWaterMeter = 'placeAfterNoWaterMeter',
  SocketAvailable = 'socketAvailable',
  WlanAvailable = 'wlanAvailable',
  PhotoOfInstallation = 'photoOfInstallation',
  DetailedPhotoOfInstallation = 'detailedPhotoOfInstallation',
  PipeSystemManufacturer = 'pipeSystemManufacturer',
  // Insurance Agent
  InsuranceAgentCode = 'insuranceAgentCode',
}
