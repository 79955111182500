import React from 'react';

import { SelectBarOption } from '@rehau/shared/forms/elements';
import { RadioButton } from '../radioButton/radioButton.component';
import { BoxWrapper, Label, Wrapper } from '../select.styled';

import { SingleSelectProps } from './singleSelectBox.types';

export const SingleSelectBox: React.FC<SingleSelectProps> = (props: SingleSelectProps): JSX.Element => {
  const { items, label, activeId, onSelectOption = (): void => undefined }: SingleSelectProps = props;

  return (
    <Wrapper>
      {!!label && <Label>{label}</Label>}
      <BoxWrapper>
        {items.map(
          (item: SelectBarOption): JSX.Element => (
            <RadioButton
              key={item.id}
              price={item.price}
              rowLabel={item.label}
              tooltipProps={item.tooltip}
              isActive={activeId === item.id}
              onClick={(): void => onSelectOption(item.id)}
            />
          )
        )}
      </BoxWrapper>
    </Wrapper>
  );
};
