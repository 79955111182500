import React, { useMemo } from 'react';

import { Bar, Container, InnerBar, Label, PriceWithTriangle, PriceWithTriangleInner, StyledPrice, Triangle } from './priceSimulationBar.styled';
import { PricePosition, PriceSimulationBarProps } from './priceSimulationBar.types';

export const PriceSimulationBar: React.FC<PriceSimulationBarProps> = (props: PriceSimulationBarProps): JSX.Element => {
  const { leftPrice, rightPrice, label, price, isLast = false, withBiggerFonts = false }: PriceSimulationBarProps = props;

  const widthValue: number = useMemo(
    (): number => {
      if (price > rightPrice) {
        return 100;
      }
      if (price < leftPrice) {
        return 0;
      }

      return ((price - leftPrice) / (rightPrice - leftPrice)) * 100;
    },
    [leftPrice, rightPrice, price]
  );

  const pricePosition: PricePosition = useMemo(
    (): PricePosition => {
      if (widthValue < 25) { return 'left'; }
      if (widthValue > 75) { return 'right'; }

      return 'center';
    },
    [leftPrice, rightPrice, price]
  );

  return (
    <Container>
      {((widthValue > 0 && widthValue < 100) || (price === rightPrice) || (widthValue >= 100 && isLast))  && (
        <PriceWithTriangle $position={widthValue}>
          <PriceWithTriangleInner>
            <StyledPrice price={price} $position={pricePosition} priceSize={18} />
            <Triangle />
          </PriceWithTriangleInner>
        </PriceWithTriangle>
      )}
      <Bar>
        <InnerBar $width={widthValue} />
      </Bar>
      <Label withBiggerFonts={withBiggerFonts}>{label}</Label>
    </Container>
  );
};
