import { NumberField } from '@rehau/shared/forms/elements/fields';
import { AreaValidation } from '@rehau/shared/forms/validations';
import { DistanceUnitEnum, FormFieldTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { InfoBox, Tooltip } from '@rehau/shared/forms/elements';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';

export class AreaField extends NumberField {
  public areaUnit: DistanceUnitEnum;

  constructor(
    id: string,
    name: string,
    label: string | null,
    required: boolean,
    validation: AreaValidation = new AreaValidation(),
    countryConfigMapping: ObjectIteratorInterface<string> = {},
    frontendComponent: FrontendComponentEnum = FrontendComponentEnum.AreaField,
    placeholder: string = '0',
    areaUnit: DistanceUnitEnum = DistanceUnitEnum.M,
    informationBox?: InfoBox,
    tooltip?: Tooltip
  ) {
    super(id, name, label, placeholder, required, validation, countryConfigMapping, informationBox, tooltip);
    this.type = FormFieldTypeEnum.AreaField;
    this.frontendComponent = frontendComponent;
    this.areaUnit = areaUnit;
  }
}
