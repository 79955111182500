import { FormContainerTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { AbstractContainer, ComponentInterface } from '@rehau/shared/forms/components';
import { fieldTypes } from '@rehau/shared/forms/types';
import { InfoBox } from '@rehau/shared/forms/elements/additional/infoBox';
import { Config } from '@rehau/shared/models/countryConfig';

export class FieldsContainer extends AbstractContainer implements ComponentInterface {
  public elements: fieldTypes[];

  public constructor(
      frontendComponent: FrontendComponentEnum,
      title: string,
      elements: fieldTypes[] = [],
      countryConfig?: Config,
      subTitle?: string,
      informationBox?: InfoBox,
      id?: string
  ) {
    super(FormContainerTypeEnum.FieldsContainer, frontendComponent, title, countryConfig, informationBox, subTitle, id);
    this.elements = elements;
  }

  public addElement(element: fieldTypes, condition?: boolean): this {
    if (condition !== false) {
      this.elements.push(element);
    }

    return this;
  }
}
