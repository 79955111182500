import { AbstractField } from '@rehau/shared/forms/elements/fields/abstract.field';
import { FormFieldTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { RequiredFieldValidation } from '@rehau/shared/forms/validations';
import { InfoBox } from '@rehau/shared/forms/elements/additional';

export class CheckboxField extends AbstractField {

  constructor(
    id: string,
    name: string,
    label: string | null,
    required: boolean,
    defaultValue: boolean | null,
    informationBox?: InfoBox,
    frontendComponent: FrontendComponentEnum = FrontendComponentEnum.Checkbox
  ) {
    super(
      FormFieldTypeEnum.CheckboxField,
      frontendComponent,
      id,
      name,
      label,
      defaultValue,
      new RequiredFieldValidation(),
      required,
      false,
      informationBox
    );
  }
}
