import { AbstractField } from '@rehau/shared/forms/elements/fields';
import { InfoBox, Tooltip } from '@rehau/shared/forms/elements';
import { FormFieldTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { TimestampValidation } from '@rehau/shared/forms/validations';

export class DateField extends AbstractField {
  public placeholder: string;

  constructor(
    id: string,
    name: string,
    label: string | null,
    placeholder: string,
    required: boolean,
    validation: TimestampValidation = new TimestampValidation(),
    informationBox?: InfoBox,
    tooltip?: Tooltip,
    value: number | null = null
  ) {
    super(
      FormFieldTypeEnum.DateField,
      FrontendComponentEnum.DateField,
      id,
      name,
      label,
      value,
      validation,
      required,
      false,
      informationBox,
      tooltip
    );
    this.placeholder = placeholder;
  }
}
