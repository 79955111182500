import i18next from 'i18next';
import { Dispatch, SetStateAction } from 'react';

import i18n from '../translations';
import countryCodesConfigFile from '../../../../../../build/autogenerate/configs.map.json';
import { Config, RouteInfo } from '../../../../../shared/models/countryConfig';
import { CountryCodeTuple } from '../types/countryCodeTuple.types';
import { CountryLocaleEnum } from '@rehau/shared/enums';

const countryCodesConfig: { [code: string]: string } = countryCodesConfigFile;

export const translationPromise: Promise<void> = new Promise((resolve: (value: void) => void): void => {
  i18next.on('loaded', (): void => resolve());
});

export const configsPromises: (
  mainLanguage: string,
  forceEnglishLanguage: boolean,
  setCountryConfig: Dispatch<SetStateAction<Config>>,
  setRoutesArray: Dispatch<SetStateAction<RouteInfo[]>>,
  storageObjectsBaseUrl: string
) => Promise<void>[] = (
  mainLanguage: string,
  forceEnglishLanguage: boolean,
  setCountryConfig: Dispatch<SetStateAction<Config>>,
  setRoutesArray: Dispatch<SetStateAction<RouteInfo[]>>,
  storageObjectsBaseUrl: string
): Promise<void>[] => {

  return Object.entries(countryCodesConfig).map((countryCodeTuple: CountryCodeTuple): Promise<void> =>
    new Promise((mainResolve: (value: void) => void): void => {
      const shortCountryCode: string = countryCodeTuple[0];
      const longCountryCode: string = countryCodeTuple[1];
      const configPromise: Promise<void> = new Promise((resolve: (value: void) => void): void => {
        import(
          `../../../../../../build/autogenerate/config/${shortCountryCode}.json`
        ).then((config: { default: Config }): void => {
          if (longCountryCode === mainLanguage) {
            if (!!storageObjectsBaseUrl) {
              config.default.storageObjectsBaseUrl = storageObjectsBaseUrl;
            }
            setCountryConfig(config.default);
          }
          resolve();
        });
      });

      const routesPromise: Promise<void> = new Promise((resolve: (value: void) => void): void => {
        // FIXME: przemyśleć, czy trzymać razem czy w osobnych miejscach
        const routesArray: RouteInfo[] = [];
        import(
          `../../../../../../build/autogenerate/routing/${shortCountryCode}.json`
        ).then((routes: { default: RouteInfo[] }): void => {
          if (longCountryCode === mainLanguage) {
            routesArray.push(...routes.default);
          }
        });
        import(
          `../../../../../../build/autogenerate/externalRouting/${shortCountryCode}.json`
          ).then((externalRoutes: { default: RouteInfo[] }): void => {
          if (longCountryCode === mainLanguage) {
            routesArray.push(...externalRoutes.default);
          }
          if (routesArray.length) {
            setRoutesArray(routesArray);
          }
          resolve();
        });
      });

      Promise.all([configPromise, routesPromise]).then((): void => {
        i18n.changeLanguage(
          forceEnglishLanguage
            ? CountryLocaleEnum.en_US
            : mainLanguage
        );
        mainResolve();
      });
    })
  );
};
