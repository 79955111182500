import React from 'react';

import { UseTimelineAnimationOutput } from '../../../../types/useTimelineAnimation.types';
import { useTimelineAnimation } from '../../../../hooks/useTimelineAnimation';
import { assignColumnRef } from '../../utils/columnRef.utils';
import { Title } from '../title/title.component';
import { useProductPopupContext } from '../../../../hooks/useProductPopupContext.hook';
import { ProductPopupContextType } from '../../../../types/productPopupContextType.types';

import {
  Container,
  Content,
  Image,
  ImageRow,
  ItemText,
  RowItem,
  StyledMarkdown,
  Wrapper,
} from './textWithImageRow.styled';
import { ImageRowItem, TextWithImageRowProps } from './textWithImageRow.types';

export const TextWithImageRow: React.FC<TextWithImageRowProps> = (props: TextWithImageRowProps): JSX.Element => {
  const {
    className,
    title,
    text,
    rowData,
    darkBackground = false,
  }: TextWithImageRowProps = props;
  const { isInPopup }: ProductPopupContextType = useProductPopupContext();
  const timelineAnimation: UseTimelineAnimationOutput = useTimelineAnimation(isInPopup);

  return (
    <Container className={className} darkBackground={darkBackground}>
      <Wrapper>
        <Content>
          {!!title && <Title data-testid='image-row-section-title'>{title}</Title>}
          <StyledMarkdown text={text} />
        </Content>
        <ImageRow>
          {rowData.map((item: ImageRowItem, index: number): JSX.Element => {
            const itemRef: React.RefObject<HTMLDivElement> = assignColumnRef(index, timelineAnimation);

            return (
              <RowItem key={item.text} ref={itemRef} data-testid='image-row-item'>
                <Image src={item.imageSrc} />
                <ItemText>{item.text}</ItemText>
              </RowItem>
            );
          })}
        </ImageRow>
      </Wrapper>
    </Container>
  );
};
