import { HeatInstallationTypeEnum, IconName, ProductType } from '@rehau/shared/enums';
import { Tooltip } from '@rehau/shared/forms/elements/';
import { RangeValueModel } from '@rehau/shared/models/responses/api/common';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { fieldValueType } from '@rehau/shared/forms/types';

export class SelectBarOption {
  public id: string;
  public label: string;
  public price: number;
  public icon: IconName;
  public available: boolean = true;
  public tooltip?: Tooltip;
  public efficiency?: RangeValueModel;
  public productType?: ProductType;
  public productTypeTranslationKey?: string;
  public heatingType?: HeatInstallationTypeEnum;
  public configMapping?: ObjectIteratorInterface<string>;
  public hideTypeName?: boolean;

  constructor(
    id: string,
    label: string,
    price: number,
    icon: IconName,
    productType?: ProductType,
    efficiency?: RangeValueModel,
    heatingType?: HeatInstallationTypeEnum,
    tooltip?: Tooltip,
    configMapping?: ObjectIteratorInterface<string>,
    hideTypeName: boolean = false
  ) {
    this.id = id;
    this.label = label;
    this.price = price;
    this.icon = icon;
    this.tooltip = tooltip;
    this.configMapping = configMapping;
    this.hideTypeName = hideTypeName;
    if (!!efficiency) {
      this.efficiency = efficiency;
      this.heatingType = heatingType;
    }
    if (!!productType) {
      this.productType = productType;
      this.productTypeTranslationKey = `rehau.global.${this.productType}`;
    }
  }

  // TODO: move to metod body to utils?
  public updateConfig(configObjectInterface: ObjectIteratorInterface<fieldValueType>): void {
    if (this.configMapping) {
      for (const configMappingKey in this.configMapping) {
        if (configMappingKey in this.configMapping) {
          const configKey: string = this.configMapping[configMappingKey];
          const valueFromConfig: fieldValueType = configObjectInterface[configKey];
          // @ts-ignore
          if (this[configMappingKey] !== valueFromConfig) {
            // @ts-ignore
            this[configMappingKey] = valueFromConfig;
          }
        }
      }
    }
  }
}
