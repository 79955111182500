import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { GridWidth } from '../../enums/gridWidth.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';
import { Button } from '../button/button.component';

import { WrapperProps } from './configuratorPreview.types';

export const Wrapper: StyledComponent<'div', WrapperProps> = styled.div`
  width: 100%;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  background-color: ${Color.BgGrey};
  box-sizing: border-box;

  @media ${Breakpoint.Additional1280} {
    padding: 100px 40px 60px;
  }

  @media ${Breakpoint.Tablet} {
    padding: 60px 40px;
  }

  @media ${Breakpoint.Mobile} {
    ${styleWhenTrue(
      prop('narrowPadding'),
      css`
        padding: 60px 20px;
      `
    )}
  }

  @media ${Breakpoint.Additional480} {
    display: block;
  }
`;

export const GridWrapper: StyledComponent<'div'> = styled.div`
  max-width: ${GridWidth.Desktop};
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ${Breakpoint.Additional480} {
    display: none;
  }
`;

export const InnerWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
  @media ${Breakpoint.Additional480} {
    display: none;
  }
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div``;

export const TitleContainer: StyledComponent<'div'> = styled.div`
  overflow: hidden;
  width: 100%;
`;

export const Title: StyledComponent<'h2'> = styled.h2`
  font-size: 28px;
  line-height: 32px;
  font-weight: ${FontWeight.Black};
  margin-bottom: 10px;
  max-width: 380px;

  @media ${Breakpoint.Additional480} {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const Description: StyledComponent<'div'> = styled.div`
  font-size: 18px;
  line-height: 24px;
  max-width: 420px;
  margin-bottom: 25px;
`;

export const Tooltip: StyledComponent<'div'> = styled.div`
  word-break: break-word;
  background-color: ${Color.GreyStroke};
  font-size: 12px;
  color: ${Color.Black};
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  padding: 3px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  position: absolute;
  max-width: 120px;
  width: 100%;
  top: 80px;
  min-height: 25px;

  :after {
    border-left: solid ${Color.Transparent} 6px;
    border-right: solid ${Color.Transparent} 6px;
    border-bottom: solid ${Color.GreyStroke} 6px;
    top: -6px;
    content: ' ';
    height: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    width: 0;
  }

  @media ${Breakpoint.Tablet} {
    display: none;
  }
`;

export const IconsContainer: StyledComponent<'div'> = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 5px;
  max-width: 300px;

  @media ${Breakpoint.Tablet} {
    max-width: 500px;
    grid-template-columns: repeat(auto-fill, 70px);
  }

  @media ${Breakpoint.Mobile} {
    display: flex;
  }

`;

export const IconContainer: StyledComponent<'div'> = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Color.GreyStroke};

  :hover {
    ${Tooltip} {
      opacity: 1;
    }
  }
`;

export const Video: StyledComponent<'video'> = styled.video`
  width: 100%;
  max-width: 450px;
  padding: 0 110px 0 20px;

  @media ${Breakpoint.Additional1280} {
    padding: 0 10px 0 20px;
    width: 50%;
    box-sizing: border-box;
  }

  @media ${Breakpoint.Mobile} {
    margin: 80px auto;
    padding: 0;
    width: 100%;
  }

  @media ${Breakpoint.Additional480} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const Row: StyledComponent<'div'> = styled.div`
  margin-top: 7px;
  display: flex;
  align-items: center;
`;

export const Dot: StyledComponent<'div'> = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${Color.Green};
  margin-right: 20px;
`;

export const Label: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 28px;
  word-break: break-word;
`;

export const StyledTopButton: StyledComponent<typeof Button> = styled(Button)`
  margin-bottom: 60px;

  @media ${Breakpoint.Mobile} {
    margin-bottom: 0;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 35px;
  margin-bottom: 20px;
`;
