import { ProductTableColumn } from '../../../../../../../shared/models/countryConfig';
import { Color } from '../../../enums/color.enum';
import { findColorByName } from '../../../utils/colors.utils';

export const getColor: (colums: ProductTableColumn[], name: string) => Color = (columns: ProductTableColumn[], name: string): Color => {
  const foundColumn: ProductTableColumn | undefined = columns.find((column: ProductTableColumn): boolean => column.name === name);

  if (!foundColumn) {
    return Color.Green;
  }

  return findColorByName(foundColumn.color);
};
