import React from 'react';

import { SelectOption } from '../../../../../../shared/forms/elements';

import { Option, Select } from './dropdown.styled';
import { DropdownProps } from './dropdown.types';

export const Dropdown: React.FC<DropdownProps> = (props: DropdownProps): JSX.Element => {
  const {
    items = [],
    selectedItem,
    placeholder,
    onChange,
    className,
  }: DropdownProps = props;

  return (
    <Select onChange={onChange} value={selectedItem} className={className}>
      <Option hidden>{placeholder}</Option>
      {items.map(
        (item: SelectOption, key: number): JSX.Element => (
          <Option value={item.value} key={`${item.value}_${key}`} selected={item.selected}>
            {item.label}
          </Option>
        )
      )}
    </Select>
  );
};
