import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Icon } from '../../../../components/icon/icon.component';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Color } from '../../../../enums/color.enum';
import { IncludedCheck } from '../../../../components/includedCheck/includedCheck.component';
import { ForcedLanguageTranslationContextType } from '../../../../types/forcedLanguageTranslationContextType.types';
import { useForcedLanguageTranslationContext } from '../../../../hooks/useForceLanguageTranslation.hook';

import { IconContainer, IconWithTitle, Id, Name, NameWithTypeOrAmount, Producer, ProducerSeparator, RowName, StyledPrice, Title, Type } from './offerItemRowHeader.styled';
import { OfferItemRowHeaderProps } from './offerItemRowHeader.types';

export const OfferItemRowHeader: React.FC<OfferItemRowHeaderProps> = (props: OfferItemRowHeaderProps): JSX.Element => {
  const { icon, id, name, typeLabel, producer, model, price, amount, className, withBiggerFonts = false }: OfferItemRowHeaderProps = props;
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const { t }: ForcedLanguageTranslationContextType = useForcedLanguageTranslationContext();

  return (
    <IconWithTitle withMarginBottom={!price && withBiggerFonts} withBiggerFonts={withBiggerFonts} className={className}>
      <IconContainer>
        {!!icon && <Icon name={icon} size={60} />}
      </IconContainer>
      <Title>
        <RowName>
          <Id>{id}</Id>
          <NameWithTypeOrAmount>
            <Name>{name}{(!!typeLabel || !!amount) && ':'}</Name>
            {!!typeLabel && <Type>{typeLabel}</Type>}
            {!!amount && <Type>{amount}{' '}{t('rehau.global.piece_short_label')}</Type>}
          </NameWithTypeOrAmount>
          {(!!producer || !!model) && !isMobile && (
            <Producer>
              <ProducerSeparator />
              {producer}
              {!!producer && !!model && (
                <ProducerSeparator hasMarginLeftAndRight />
              )}
              {model}
            </Producer>
          )}
        </RowName>
        {(price !== undefined && price !== null) && (
          <>
            {price !== 0 ? (
              <StyledPrice price={price} priceSize={withBiggerFonts ? 24 : 18} />
            ) : (
              <IncludedCheck includedLabel={t('rehau.global.included')} iconColor={Color.CompanyColor} isFinal />
            )}
          </>
        )}
      </Title>
    </IconWithTitle>
  );
};
