import { NumberField } from '@rehau/shared/forms/elements/fields/number.field';
import { InfoBox, Tooltip } from '@rehau/shared/forms/elements';
import { NumberValidation } from '@rehau/shared/forms/validations';
import { FrontendComponentEnum, IconName } from '@rehau/shared/enums';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';

export class ClickNumberWithIconField extends NumberField {
  public icon: IconName;

  constructor(
      id: string,
      name: string,
      label: string | null,
      required: boolean,
      icon: IconName,
      value: number = 0,
      tooltip?: Tooltip,
      validation: NumberValidation = new NumberValidation(),
      countryConfigMapping: ObjectIteratorInterface<string> = {},
      informationBox?: InfoBox
  ) {
    super(
      id,
      name,
      label,
      '',
      required,
      validation,
      countryConfigMapping,
      informationBox,
      tooltip,
      value
    );
    this.frontendComponent = FrontendComponentEnum.ClickNumberFieldWithIcon;
    this.icon = icon;
  }
}
