import { useContext } from 'react';

import { AppConfigContext } from '../contexts/appConfig.context';
import { AppConfigType } from '../types/appConfigType.types';

export const useAppConfig: () => AppConfigType = (): AppConfigType => {
  const context: AppConfigType = useContext(AppConfigContext);
  if (!context) {
    throw new Error('Cannot use app config context outside app.provider');
  }

  return context;
};
