export enum SetupConfigKeyEnum {
  Code = 'code',
  HeatingAndCoolingGasEnable = 'heating_and_cooling.gas.enable',
  HeatingAndCoolingOilEnable = 'heating_and_cooling.oil.enable',
  HeatingAndCoolingWoodEnable = 'heating_and_cooling.wood.enable',
  HeatingAndCoolingAirPumpEnable = 'heating_and_cooling.air_pump.enable',
  HeatingAndCoolingHeatingAndCoolingAirPumpEnable = 'heating_and_cooling.heating_and_cooling_air_pump.enable',
  HeatingAndCoolingGeothermalPumpEnable = 'heating_and_cooling.geothermal_pump.enable',
  HeatingAndCoolingHeatingAndCoolingGeothermalPumpEnable = 'heating_and_cooling.heating_and_cooling_geothermal_pump.enable',
  HeatingAndCoolingDistrictHeatingEnable = 'heating_and_cooling.district_heating.enable',
  HeatingAndCoolingPelletsEnable = 'heating_and_cooling.pellets.enable',
  HeatingAndCoolingElectricEnable = 'heating_and_cooling.electric.enable',
  HeatingAndCoolingPhotovoltaicEnable = 'heating_and_cooling.photovoltaic.enable',
  HeatingAndCoolingSolarPanelsEnable = 'heating_and_cooling.solar_panels.enable',
  HeatingAndCoolingHotWaterTankEnable = 'heating_and_cooling.hot_water_tank.enable',
  HeatingAndCoolingGasTankIncludedEnable = 'heating_and_cooling.gas.tank_included.enable',
  HeatingAndCoolingOilTankIncludedEnable = 'heating_and_cooling.oil.tank_included.enable',
  HeatingAndCoolingWoodTankIncludedEnable = 'heating_and_cooling.wood.tank_included.enable',
  HeatingAndCoolingAirPumpTankIncludedEnable = 'heating_and_cooling.air_pump.tank_included.enable',
  HeatingAndCoolingGeothermalPumpTankIncludedEnable = 'heating_and_cooling.geothermal_pump.tank_included.enable',
  HeatingAndCoolingHeatingAndCoolingAirPumpTankIncludedEnable = 'heating_and_cooling.heating_and_cooling_air_pump.tank_included.enable',
  HeatingAndCoolingHeatingAndCoolingGeothermalPumpTankIncludedEnable = 'heating_and_cooling.heating_and_cooling_geothermal_pump.tank_included.enable',
  HeatingAndCoolingDistrictHeatingTankIncludedEnable = 'heating_and_cooling.district_heating.tank_included.enable',
  HeatingAndCoolingPelletsTankIncludedEnable = 'heating_and_cooling.pellets.tank_included.enable',
  HeatingAndCoolingElectricTankIncludedEnable = 'heating_and_cooling.electric.tank_included.enable',
  HeatingAndCoolingPhotovoltaicTankIncludedEnable = 'heating_and_cooling.photovoltaic.tank_included.enable',
  HeatingAndCoolingSolarPanelsTankIncludedEnable = 'heating_and_cooling.solar_panels.tank_included.enable',
  HeatingAndCoolingHotWaterTankTankIncludedEnable = 'heating_and_cooling.hot_water_tank.tank_included.enable',
  HeatingAndCoolingGasProducerNameEnable = 'heating_and_cooling.gas.producer_name.enable',
  HeatingAndCoolingOilProducerNameEnable = 'heating_and_cooling.oil.producer_name.enable',
  HeatingAndCoolingWoodProducerNameEnable = 'heating_and_cooling.wood.producer_name.enable',
  HeatingAndCoolingAirPumpProducerNameEnable = 'heating_and_cooling.air_pump.producer_name.enable',
  HeatingAndCoolingGeothermalPumpProducerNameEnable = 'heating_and_cooling.geothermal_pump.producer_name.enable',
  HeatingAndCoolingHeatingAndCoolingAirPumpProducerNameEnable = 'heating_and_cooling.heating_and_cooling_air_pump.producer_name.enable',
  HeatingAndCoolingHeatingAndCoolingGeothermalPumpProducerNameEnable = 'heating_and_cooling.heating_and_cooling_geothermal_pump.producer_name.enable',
  HeatingAndCoolingDistrictHeatingProducerNameEnable = 'heating_and_cooling.district_heating.producer_name.enable',
  HeatingAndCoolingPelletsProducerNameEnable = 'heating_and_cooling.pellets.producer_name.enable',
  HeatingAndCoolingElectricProducerNameEnable = 'heating_and_cooling.electric.producer_name.enable',
  HeatingAndCoolingPhotovoltaicProducerNameEnable = 'heating_and_cooling.photovoltaic.producer_name.enable',
  HeatingAndCoolingSolarPanelsProducerNameEnable = 'heating_and_cooling.solar_panels.producer_name.enable',
  HeatingAndCoolingHotWaterTankProducerNameEnable = 'heating_and_cooling.hot_water_tank.producer_name.enable',
  HeatingAndCoolingGasTradeNameEnable = 'heating_and_cooling.gas.trade_name.enable',
  HeatingAndCoolingOilTradeNameEnable = 'heating_and_cooling.oil.trade_name.enable',
  HeatingAndCoolingWoodTradeNameEnable = 'heating_and_cooling.wood.trade_name.enable',
  HeatingAndCoolingAirPumpTradeNameEnable = 'heating_and_cooling.air_pump.trade_name.enable',
  HeatingAndCoolingGeothermalPumpTradeNameEnable = 'heating_and_cooling.geothermal_pump.trade_name.enable',
  HeatingAndCoolingHeatingAndCoolingAirPumpTradeNameEnable = 'heating_and_cooling.heating_and_cooling_air_pump.trade_name.enable',
  HeatingAndCoolingHeatingAndCoolingGeothermalPumpTradeNameEnable = 'heating_and_cooling.heating_and_cooling_geothermal_pump.trade_name.enable',
  HeatingAndCoolingDistrictHeatingTradeNameEnable = 'heating_and_cooling.district_heating.trade_name.enable',
  HeatingAndCoolingPelletsTradeNameEnable = 'heating_and_cooling.pellets.trade_name.enable',
  HeatingAndCoolingElectricTradeNameEnable = 'heating_and_cooling.electric.trade_name.enable',
  HeatingAndCoolingPhotovoltaicTradeNameEnable = 'heating_and_cooling.photovoltaic.trade_name.enable',
  HeatingAndCoolingSolarPanelsTradeNameEnable = 'heating_and_cooling.solar_panels.trade_name.enable',
  HeatingAndCoolingHotWaterTankTradeNameEnable = 'heating_and_cooling.hot_water_tank.trade_name.enable',
  HeatingAndCoolingGasHotWaterIntegrationEnable = 'heating_and_cooling.gas.hot_water_integration.enable',
  HeatingAndCoolingOilHotWaterIntegrationEnable = 'heating_and_cooling.oil.hot_water_integration.enable',
  HeatingAndCoolingWoodHotWaterIntegrationEnable = 'heating_and_cooling.wood.hot_water_integration.enable',
  HeatingAndCoolingAirPumpHotWaterIntegrationEnable = 'heating_and_cooling.air_pump.hot_water_integration.enable',
  HeatingAndCoolingGeothermalPumpHotWaterIntegrationEnable = 'heating_and_cooling.geothermal_pump.hot_water_integration.enable',
  HeatingAndCoolingHeatingAndCoolingAirPumpHotWaterIntegrationEnable = 'heating_and_cooling.heating_and_cooling_air_pump.hot_water_integration.enable',
  HeatingAndCoolingHeatingAndCoolingGeothermalPumpHotWaterIntegrationEnable = 'heating_and_cooling.heating_and_cooling_geothermal_pump.hot_water_integration.enable',
  HeatingAndCoolingDistrictHeatingHotWaterIntegrationEnable = 'heating_and_cooling.district_heating.hot_water_integration.enable',
  HeatingAndCoolingPelletsHotWaterIntegrationEnable = 'heating_and_cooling.pellets.hot_water_integration.enable',
  HeatingAndCoolingElectricHotWaterIntegrationEnable = 'heating_and_cooling.electric.hot_water_integration.enable',
  HeatingAndCoolingPhotovoltaicHotWaterIntegrationEnable = 'heating_and_cooling.photovoltaic.hot_water_integration.enable',
  HeatingAndCoolingSolarPanelsHotWaterIntegrationEnable = 'heating_and_cooling.solar_panels.hot_water_integration.enable',
  HeatingAndCoolingHotWaterTankHotWaterIntegrationEnable = 'heating_and_cooling.hot_water_tank.hot_water_integration.enable',
  HeatingAndCoolingGasHydraulicSwitchEnable = 'heating_and_cooling.gas.hydraulic_switch.enable',
  HeatingAndCoolingOilHydraulicSwitchEnable = 'heating_and_cooling.oil.hydraulic_switch.enable',
  HeatingAndCoolingWoodHydraulicSwitchEnable = 'heating_and_cooling.wood.hydraulic_switch.enable',
  HeatingAndCoolingAirPumpHydraulicSwitchEnable = 'heating_and_cooling.air_pump.hydraulic_switch.enable',
  HeatingAndCoolingGeothermalPumpHydraulicSwitchEnable = 'heating_and_cooling.geothermal_pump.hydraulic_switch.enable',
  HeatingAndCoolingHeatingAndCoolingAirPumpHydraulicSwitchEnable = 'heating_and_cooling.heating_and_cooling_air_pump.hydraulic_switch.enable',
  HeatingAndCoolingHeatingAndCoolingGeothermalPumpHydraulicSwitchEnable = 'heating_and_cooling.heating_and_cooling_geothermal_pump.hydraulic_switch.enable',
  HeatingAndCoolingDistrictHeatingHydraulicSwitchEnable = 'heating_and_cooling.district_heating.hydraulic_switch.enable',
  HeatingAndCoolingPelletsHydraulicSwitchEnable = 'heating_and_cooling.pellets.hydraulic_switch.enable',
  HeatingAndCoolingElectricHydraulicSwitchEnable = 'heating_and_cooling.electric.hydraulic_switch.enable',
  HeatingAndCoolingPhotovoltaicHydraulicSwitchEnable = 'heating_and_cooling.photovoltaic.hydraulic_switch.enable',
  HeatingAndCoolingSolarPanelsHydraulicSwitchEnable = 'heating_and_cooling.solar_panels.hydraulic_switch.enable',
  HeatingAndCoolingHotWaterTankHydraulicSwitchEnable = 'heating_and_cooling.hot_water_tank.hydraulic_switch.enable',
  HeatingAndCoolingGasChimneyEnable = 'heating_and_cooling.gas.chimney.enable',
  HeatingAndCoolingOilChimneyEnable = 'heating_and_cooling.oil.chimney.enable',
  HeatingAndCoolingWoodChimneyEnable = 'heating_and_cooling.wood.chimney.enable',
  HeatingAndCoolingAirPumpChimneyEnable = 'heating_and_cooling.air_pump.chimney.enable',
  HeatingAndCoolingGeothermalPumpChimneyEnable = 'heating_and_cooling.geothermal_pump.chimney.enable',
  HeatingAndCoolingHeatingAndCoolingAirPumpChimneyEnable = 'heating_and_cooling.heating_and_cooling_air_pump.chimney.enable',
  HeatingAndCoolingHeatingAndCoolingGeothermalPumpChimneyEnable = 'heating_and_cooling.heating_and_cooling_geothermal_pump.chimney.enable',
  HeatingAndCoolingDistrictHeatingChimneyEnable = 'heating_and_cooling.district_heating.chimney.enable',
  HeatingAndCoolingPelletsChimneyEnable = 'heating_and_cooling.pellets.chimney.enable',
  HeatingAndCoolingElectricChimneyEnable = 'heating_and_cooling.electric.chimney.enable',
  HeatingAndCoolingPhotovoltaicChimneyEnable = 'heating_and_cooling.photovoltaic.chimney.enable',
  HeatingAndCoolingSolarPanelsChimneyEnable = 'heating_and_cooling.solar_panels.chimney.enable',
  HeatingAndCoolingHotWaterTankChimneyEnable = 'heating_and_cooling.hot_water_tank.chimney.enable',
  HeatingAndCoolingGasMaintenanceEnable = 'heating_and_cooling.gas.maintenance.enable',
  HeatingAndCoolingOilMaintenanceEnable = 'heating_and_cooling.oil.maintenance.enable',
  HeatingAndCoolingWoodMaintenanceEnable = 'heating_and_cooling.wood.maintenance.enable',
  HeatingAndCoolingAirPumpMaintenanceEnable = 'heating_and_cooling.air_pump.maintenance.enable',
  HeatingAndCoolingGeothermalPumpMaintenanceEnable = 'heating_and_cooling.geothermal_pump.maintenance.enable',
  HeatingAndCoolingHeatingAndCoolingAirPumpMaintenanceEnable = 'heating_and_cooling.heating_and_cooling_air_pump.maintenance.enable',
  HeatingAndCoolingHeatingAndCoolingGeothermalPumpMaintenanceEnable = 'heating_and_cooling.heating_and_cooling_geothermal_pump.maintenance.enable',
  HeatingAndCoolingDistrictHeatingMaintenanceEnable = 'heating_and_cooling.district_heating.maintenance.enable',
  HeatingAndCoolingPelletsMaintenanceEnable = 'heating_and_cooling.pellets.maintenance.enable',
  HeatingAndCoolingElectricMaintenanceEnable = 'heating_and_cooling.electric.maintenance.enable',
  HeatingAndCoolingPhotovoltaicMaintenanceEnable = 'heating_and_cooling.photovoltaic.maintenance.enable',
  HeatingAndCoolingSolarPanelsMaintenanceEnable = 'heating_and_cooling.solar_panels.maintenance.enable',
  HeatingAndCoolingHotWaterTankMaintenanceEnable = 'heating_and_cooling.hot_water_tank.maintenance.enable',
  HeatDistributionFloorHeatingEnable = 'heat_distribution.floor_heating.enable',
  HeatDistributionFloorHeatingRequired = 'heat_distribution.floor_heating.required',
  HeatDistributionFloorHeatingSparseEnable = 'heat_distribution.floor_heating_sparse.enable',
  HeatDistributionFloorHeatingSparseRequired = 'heat_distribution.floor_heating_sparse.required',
  HeatDistributionFloorHeatingAndCoolingEnable = 'heat_distribution.floor_heating_and_cooling.enable',
  HeatDistributionFloorHeatingAndCoolingRequired = 'heat_distribution.floor_heating_and_cooling.required',
  HeatDistributionFloorHeatingAndCoolingSparseEnable = 'heat_distribution.floor_heating_and_cooling_sparse.enable',
  HeatDistributionFloorHeatingAndCoolingSparseRequired = 'heat_distribution.floor_heating_and_cooling_sparse.required',
  HeatDistributionWallHeatingAndCoolingEnable = 'heat_distribution.wall_heating_and_cooling.enable',
  HeatDistributionWallHeatingAndCoolingRequired = 'heat_distribution.wall_heating_and_cooling.required',
  HeatDistributionCeilingHeatingAndCoolingEnable = 'heat_distribution.ceiling_heating_and_cooling.enable',
  HeatDistributionCeilingHeatingAndCoolingRequired = 'heat_distribution.ceiling_heating_and_cooling.required',
  HeatDistributionHeatingCircuitManifoldEnable = 'heat_distribution.heating_circuit_manifold.enable',
  HeatDistributionHeaterByBathroomEnable = 'heat_distribution.heater_by_bathroom.enable',
  HeatDistributionBathroomRadiatorCostEnable = 'heat_distribution.bathroom_radiator_cost.enable',
  HeatDistributionDiscountContainerEnable = 'heat_distribution.discount_container.enable',
  AutomationHeatingControlBasicEnable = 'automation.heating_control_basic.enable',
  AutomationHeatingControlBasicRequired = 'automation.heating_control_basic.required',
  AutomationHeatingControlSmartEnable = 'automation.heating_control_smart.enable',
  AutomationHeatingControlSmartRequired = 'automation.heating_control_smart.required',
  AutomationHeatingAndCoolingControlSmartEnable = 'automation.heating_and_cooling_control_smart.enable',
  AutomationHeatingAndCoolingControlSmartRequired = 'automation.heating_and_cooling_control_smart.required',
  AutomationHeatingAndCoolingControlPremiumEnable = 'automation.heating_and_cooling_control_premium.enable',
  AutomationHeatingAndCoolingControlPremiumRequired = 'automation.heating_and_cooling_control_premium.required',
  WaterDistributionTeeInstallationEnable = 'water_distribution.tee_installation.enable',
  WaterDistributionTeeInstallationRequired = 'water_distribution.tee_installation.required',
  WaterDistributionLinearInstallationEnable = 'water_distribution.linear_installation.enable',
  WaterDistributionLinearInstallationRequired = 'water_distribution.linear_installation.required',
  WaterDistributionRingInstallationEnable = 'water_distribution.ring_installation.enable',
  WaterDistributionRingInstallationRequired = 'water_distribution.ring_installation.required',
  WaterDistributionOnTheFloorVerticalEnable = 'water_distribution.on_the_floor_vertical.enable',
  WaterDistributionOnTheFloorVerticalRequired = 'water_distribution.on_the_floor_vertical.required',
  WaterDistributionOnTheFloorHorizontalEnable = 'water_distribution.on_the_floor_horizontal.enable',
  WaterDistributionOnTheFloorHorizontalRequired = 'water_distribution.on_the_floor_horizontal.required',
  WaterDistributionOnTheFiveFloorEnable = 'water_distribution.on_the_five_floor.enable',
  WaterDistributionReducerWithFilterEnable = 'water_distribution.reducer_with_filter.enable',
  WaterDistributionReducerWithFilterRequired = 'water_distribution.reducer_with_filter.required',
  WaterDistributionOnTheFloorFourFloorEnable = 'water_distribution.on_the_floor.four_floor.enable',
  WaterDistributionOnTheFloorFiveFloorEnable = 'water_distribution.on_the_floor.five_floor.enable',
  WaterDistributionDiscountEnable = 'water_distribution.discount.enable',
  WaterForDrinkingInstallationWaterTankEnable = 'water_for_drinking_installation.water_tank.enable',
  WaterForDrinkingInstallationWaterTankRequired = 'water_for_drinking_installation.water_tank.required',
  WaterForDrinkingInstallationFreshWaterEnable = 'water_for_drinking_installation.fresh_water.enable',
  WaterForDrinkingInstallationCirculationInstallationEnable = 'water_for_drinking_installation.circulation_installation.enable',
  WaterForDrinkingInstallationWaterSoftenerEnable = 'water_for_drinking_installation.water_softener.enable',
  BathroomFittingFrameWcEnable = 'bathroom_fitting.frame_wc.enable',
  BathroomFittingFrameWcRequired = 'bathroom_fitting.frame_wc.required',
  BathroomFittingFrameUrinalEnable = 'bathroom_fitting.frame_urinal.enable',
  BathroomFittingFrameUrinalRequired = 'bathroom_fitting.frame_urinal.required',
  BathroomFittingFrameBasinEnable = 'bathroom_fitting.frame_basin.enable',
  BathroomFittingFrameBasinRequired = 'bathroom_fitting.frame_basin.required',
  BathroomFittingFrameBidetEnable = 'bathroom_fitting.frame_bidet.enable',
  BathroomFittingFrameBidetRequired = 'bathroom_fitting.frame_bidet.required',
  BathroomFittingWashingWcEnable = 'bathroom_fitting.washing_wc.enable',
  BathroomFittingWashingWcRequired = 'bathroom_fitting.washing_wc.required',
  BathroomFittingUrinalEnable = 'bathroom_fitting.urinal.enable',
  BathroomFittingUrinalRequired = 'bathroom_fitting.urinal.required',
  BathroomFittingBathEnable = 'bathroom_fitting.bath.enable',
  BathroomFittingBathRequired = 'bathroom_fitting.bath.required',
  BathroomFittingRainShowerEnable = 'bathroom_fitting.rain_shower.enable',
  BathroomFittingRainShowerRequired = 'bathroom_fitting.rain_shower.required',
  BathroomFittingShowerEnable = 'bathroom_fitting.shower.enable',
  BathroomFittingShowerRequired = 'bathroom_fitting.shower.required',
  BathroomFittingKitchenSinkEnable = 'bathroom_fitting.kitchen_sink.enable',
  BathroomFittingKitchenSinkRequired = 'bathroom_fitting.kitchen_sink.required',
  BathroomFittingSmallBasinEnable = 'bathroom_fitting.small_basin.enable',
  BathroomFittingSmallBasinRequired = 'bathroom_fitting.small_basin.required',
  BathroomFittingBasinEnable = 'bathroom_fitting.basin.enable',
  BathroomFittingBasinRequired = 'bathroom_fitting.basin.required',
  BathroomFittingBidetEnable = 'bathroom_fitting.bidet.enable',
  BathroomFittingBidetRequired = 'bathroom_fitting.bidet.required',
  SewagePipesClassicEnable = 'sewage.pipes_classic.enable',
  SewagePipesClassicRequired = 'sewage.pipes_classic.required',
  SewagePipesPremiumEnable = 'sewage.pipes_premium.enable',
  SewagePipesPremiumRequired = 'sewage.pipes_premium.required',
  SewageFloorInstallationClassicEnable = 'sewage.floor_installation_classic.enable',
  SewageFloorInstallationPremiumEnable = 'sewage.floor_installation_premium.enable',
  SewageDiscountEnable = 'sewage.discount.enable',
  VentilationFanExhaustEnable = 'ventilation.fan_exhaust.enable',
  VentilationMechanicalEnable = 'ventilation.mechanical.enable',
  ReGuardEnable = 're_guard.enable',
  ReGuardEditable = 're_guard.editable',
  ReSourceEnable = 're_source.enable',
  AutomationHeatingControlBasicRatingAmount = 'automation.heating_control_basic.rating.amount',
  AutomationHeatingControlSmartRatingAmount = 'automation.heating_control_smart.rating.amount',
  AutomationHeatingAndCoolingControlSmartRatingAmount = 'automation.heating_and_cooling_control_smart.rating.amount',
  AutomationHeatingAndCoolingControlPremiumRatingAmount = 'automation.heating_and_cooling_control_premium.rating.amount',
  WaterSectionLinearInstallationRatingAmount = 'water_section.linear_installation.rating.amount',
  WaterSectionRingInstallationRatingAmount = 'water_section.ring_installation.rating.amount',
  WaterSectionTeeInstallationRatingAmount = 'water_section.tee_installation.rating.amount',
  SewageSewageClassicRatingAmount = 'sewage.sewage_classic.rating.amount',
  SewageSewagePremiumRatingAmount = 'sewage.sewage_premium.rating.amount',
  VentilationMechanicalRatingAmount = 'ventilation.mechanical.rating.amount',
  VentilationExhaustFanRatingAmount = 'ventilation.exhaust_fan.rating.amount',
}
