import { ChooseVariantField } from '@rehau/shared/forms/elements/fields';
import { Direction, FrontendComponentEnum, FrontendImagesEnum, IconName, PromoPopupContentTypeEnum } from '@rehau/shared/enums';
import { InfoBox, SelectBarOption } from '@rehau/shared/forms/elements';

export class ChooseVariantFromPopupField extends ChooseVariantField {
  public popupTitle: string;
  public popupSubtitle: string;
  public boxIcon?: IconName;
  public shouldShowSelectedValue: boolean;
  public promoPopupContentType?: PromoPopupContentTypeEnum;
  public promoImage?: FrontendImagesEnum | string;
  public promoImageDescription?: string;
  public promoImageTitle?: string;
  public promoImageDescriptionDirection?: Direction;

  constructor(
    id: string,
    name: string,
    label: string,
    popupTitle: string,
    popupSubtitle: string,
    required: boolean,
    shouldShowSelectedValue: boolean = true,
    boxIcon?: IconName,
    promoPopupContentType?: PromoPopupContentTypeEnum,
    promoImage?: FrontendImagesEnum | string,
    promoImageDescription?: string,
    promoImageTitle?: string,
    promoImageDescriptionDirection: Direction = Direction.Left,
    informationBox?: InfoBox
  ) {
    super(
      id,
      name,
      label,
      required,
      FrontendComponentEnum.ChooseVariantFromPopup,
      undefined,
      undefined,
      informationBox
    );
    this.options = [];
    this.popupTitle = popupTitle;
    this.popupSubtitle = popupSubtitle;
    this.shouldShowSelectedValue = shouldShowSelectedValue;
    this.boxIcon = boxIcon;
    this.promoPopupContentType = promoPopupContentType;
    this.promoImage = promoImage;
    this.promoImageDescription = promoImageDescription;
    this.promoImageTitle = promoImageTitle;
    this.promoImageDescriptionDirection = promoImageDescriptionDirection;
  }

  public addOption(element: SelectBarOption, condition?: boolean): this {
    if (condition !== false && element instanceof SelectBarOption) {
      this.options.push(element);
    }

    return this;
  }
}
