import React, { SyntheticEvent, useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { UseState } from '../../types/useState.types';

import { ActiveLabel, Ball, Container, InactiveLabel, Label, SwitchContainer, TooltipArrow, TooltipContainer, TooltipContent } from './toggleSwitch.styled';
import { ToggleSwitchProps } from './toggleSwitch.types';

export const ToggleSwitch: React.FC<ToggleSwitchProps> = (props: ToggleSwitchProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    label,
    className,
    value = false,
    activeLabel = t('rehau.global.yes'),
    inactiveLabel = t('rehau.global.no'),
    tooltipContentForTrue,
    tooltipContentForFalse,
    withBorder = false,
    onClick = (): void => undefined,
  }: ToggleSwitchProps = props;
  const [isActive, setIsActive]: UseState<boolean> = useState<boolean>(value);

  useEffect(
    (): void => {
      setIsActive(value);
    },
    [value]
  );

  const handleClick: (e: SyntheticEvent) => void = (e: SyntheticEvent): void => {
    e.stopPropagation();
    onClick(!isActive);
    setIsActive(!isActive);
  };

  return (
    <Container className={className}>
      <Label>{label}</Label>
      <SwitchContainer onClick={handleClick} isActive={isActive} withBorder={withBorder}>
        <ActiveLabel>{activeLabel}</ActiveLabel>
        <Ball/>
        <InactiveLabel>{inactiveLabel}</InactiveLabel>
      </SwitchContainer>
      {(!!tooltipContentForTrue && !!tooltipContentForFalse) && (
        <TooltipContainer>
          <TooltipArrow />
          <TooltipContent>{isActive ? tooltipContentForTrue : tooltipContentForFalse}</TooltipContent>
        </TooltipContainer>
      )}
    </Container>
  );
};
