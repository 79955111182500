import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { Tooltip } from '../../tooltip/tooltip.component';
import { RowLabel, StyledPrice } from '../select.styled';

import { Button, Container, PriceContainer, StyledTooltipContainer, Wrapper } from './radioButton.styled';
import { RadioButtonProps } from './radioButton.types';

export const RadioButton: React.FC<RadioButtonProps> = (
  props: RadioButtonProps
): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { isActive = false, onClick, id, rowLabel, price, tooltipProps }: RadioButtonProps = props;

  return (
    <Wrapper data-testid='radio-container'>
      <Container>
        <Button isActive={isActive} onClick={onClick} id={id} aria-label={id}/>
        <RowLabel isActive={isActive} onClick={onClick} id={id}>{t(rowLabel)}</RowLabel>
      </Container>
      <PriceContainer>
        {!!price && <StyledPrice price={price} priceSize={13} />}
        <StyledTooltipContainer>
          {!!tooltipProps && <Tooltip tooltipSettings={tooltipProps} />}
        </StyledTooltipContainer>
      </PriceContainer>
    </Wrapper>
  );
};
