import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { ColorPickerField } from '../../../../../../../shared/forms/elements';
import { ColorPicker } from '../../colorPicker/colorPicker.component';
import { FieldElement, FieldElementProps } from '../types';

export const ColorPickerFieldElement: FieldElement<ColorPickerField> = (props: FieldElementProps<ColorPickerField>): JSX.Element => {
  const { component, save, setValues }: FieldElementProps<ColorPickerField> = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <ColorPicker
      label={t(component.label || '')}
      placeholder={t(component.placeholder || '')}
      component={component}
      initValue={component.value as string}
      handleSave={save}
      setValues={setValues}
    />
  );
};
