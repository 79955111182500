import { prop } from 'ramda';
import styled, { css, StyledComponent } from 'styled-components';

import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { BoxShadow } from '../../../../enums/boxShadow.enum';
import { Color } from '../../../../enums/color.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { styleWhenTrue } from '../../../../utils/style.utils';
import { Icon } from '../../../../components/icon/icon.component';
import { ZIndex } from '../../../../enums/zIndex.enum';

import { MenuContainerProps, PositionProps } from './sideMenu.types';

export const Container: StyledComponent<'div', {}> = styled.div`
  box-shadow: ${BoxShadow.BtnGreyShadow};
  background-color: ${Color.White};
  width: 100%;
  & + & {
    margin-top: 22px;
  }
`;

export const Header: StyledComponent<'div', {}> = styled.div`
  font-weight: ${FontWeight.Bold};
  font-size: 18px;
  line-height: 22px;
  color: ${Color.Black};
  padding: 20px;
  border-top: 2px solid ${Color.Black};
`;

export const PositionsContainer: StyledComponent<'div', {}> = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Position: StyledComponent<'button', {}, PositionProps, never> = styled.button<PositionProps>`
  text-align: left;
  font-size: 16px;
  line-height: 19px;
  color: ${Color.Grey};
  border-top: 1px solid ${Color.GreyStroke};
  padding: 14px 20px;
  transition: 0.3s;
  &:hover {
    color: ${Color.Black};
  }
  ${styleWhenTrue(prop('isActive'), css`
    color: ${Color.Green};
    border-left: 2px solid ${Color.Green};
    &:hover {
      color: ${Color.Green};
    }
  `)}
`;

export const InnerContainer: StyledComponent<'div', {}> = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 9px 40px;

  @media ${Breakpoint.Mobile} {
    padding: 9px 20px;
    flex-direction: column;
  }
`;

export const StyledIcon: StyledComponent<typeof Icon, {}, {}, never> = styled(Icon)`
  transition: 0.3s;
`;

export const Hamburger: StyledComponent<'button', {}> = styled.button`
  width: 40px;
  height: 40px;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  background-color: ${Color.White};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  :hover {
    ${StyledIcon} {
      color: ${Color.Green};
    }
  }
`;

export const TitleContainer: StyledComponent<'div', {}> = styled.div`
  display: flex;
  align-items: center;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const Title: StyledComponent<'div', {}> = styled.div`
  font-weight: ${FontWeight.Bold};
  font-size: 16px;
  line-height: 19px;
  color: ${Color.Black};
  margin-left: 15px;
`;

export const ColumnHeader: StyledComponent<'div', {}> = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: ${FontWeight.Bold};
  padding-bottom: 20px;
`;

export const MenuContainer: StyledComponent<'div', {}, MenuContainerProps, never> = styled.div<MenuContainerProps>`
  background-color: ${Color.White};
  box-shadow: ${BoxShadow.BtnGreyShadow};
  transition: 0.3s;
  transform: translateY(-100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${ZIndex.SideMenu};
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 40px;
  ${styleWhenTrue(
    prop('isVisible'),
    css`
      transform: translateY(0);
    `
  )}

  @media ${Breakpoint.Mobile} {
    padding: 0 30px;
  }
`;

export const FixedMenuInnerContainer: StyledComponent<'div', {}> = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 40px 0 80px;
  width: 100%;
`;

export const FixedMenuHeader: StyledComponent<'div', {}> = styled.div`
  font-weight: ${FontWeight.Bold};
  font-size: 26px;
  line-height: 31px;
  color: ${Color.Black};
  padding-bottom: 25px;
  border-bottom: 1px solid ${Color.GreyStroke};
  display: flex;
  justify-content: space-between;
`;

export const MenuSection: StyledComponent<'div', {}> = styled.div`
  display: flex;
  margin-top: 40px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const MenuColumn: StyledComponent<'div', {}> = styled.div`
  width: 50%;

  @media ${Breakpoint.Mobile} {
    width: 100%;
    & + & {
      margin-top: 15px;
    }
  }
`;

export const PositionIndex: StyledComponent<'p', {}> = styled.p`
  font-size: 18px;
  line-height: 22px;
  color: ${Color.Black};
  margin-right: 20px;
`;

export const PositionLabel: StyledComponent<'p', {}> = styled.p`
  font-size: 18px;
  line-height: 22px;
  color: ${Color.Grey};
  transition: 0.3s;
`;

export const MenuPosition: StyledComponent<'button', {}> = styled.button`
  display: flex;
  align-items: center;
  & + & {
    margin-top: 15px;
  }
  &:hover {
    ${PositionLabel} {
      color: ${Color.Black};
    }
  }
`;

export const CloseButton: StyledComponent<'button', {}> = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
`;
