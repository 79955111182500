import { MenuGroup, MenuGroupTuple } from '../../../../../../shared/models/countryConfig';

import { SortGroupTuples } from './mainNavbar.types';

export const sortGroupTuples: SortGroupTuples = (group1: MenuGroupTuple, group2: MenuGroupTuple): number => {
  const pathData1: MenuGroup = group1[1] as MenuGroup;
  const pathData2: MenuGroup = group2[1] as MenuGroup;

  return pathData1.order - pathData2.order;
};
