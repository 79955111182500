import React, { useEffect, useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { FlatBox } from '../flatBox/flatBox.component';
import { FullscreenPopup } from '../fullscreenPopup/fullscreenPopup.component';
import { UseState } from '../../types/useState.types';
import { useEffectAfterMount } from '../../hooks/useEffectAfterMount';
import { VariantWithActiveFlag } from '../flatBox/flatBox.types';
import { ObjectIteratorInterface } from '../../../../../../shared/objectIterator.interface';
import { PopupContainer, VariantContainer } from '../../../../../../shared/forms/components';
import { ErrorMessageEnum, FrontendComponentEnum } from '../../../../../../shared/enums';
import { componentTypes } from '@rehau/shared/forms/types';
import { ValidationMessages } from '@rehau/shared/forms/validations';
import { AbstractField, DecisionField } from '../../../../../../shared/forms/elements';
import { FullScreenPopupContextType } from '../../types/fullScreenPopupContextType.types';
import { useFullScreenPopupContext } from '../../hooks/useFullScreenPopupContext.hook';

import { Container } from './popupVariantsContainer.styled';
import { PopupVariantsContainerProps } from './popupVariantsContainer.types';

export const PopupVariantsContainer: React.FC<PopupVariantsContainerProps> = (props: PopupVariantsContainerProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const [isPopupVisible, setIsPopupVisible]: UseState<boolean> = useState<boolean>(false);
  const {
    icon,
    boxLabel,
    variantsLabel,
    actionLabel,
    warnLabel,
    popupHeader,
    popupSubheader,
    additionalInfoContent,
    additionalInfoContentInReadMore,
    children,
    component = new PopupContainer(
      FrontendComponentEnum.HorizontalFieldline,
      '',
      '',
      [new VariantContainer('',  '', [new DecisionField('', '', '', false, false, '', '')])]
    ),
  }: PopupVariantsContainerProps = props;
  const fullScreenPopupContext: FullScreenPopupContextType | null = useFullScreenPopupContext();
  const variantsWithShortLabel: ObjectIteratorInterface<boolean> = component?.getVariantsShortLabel() || {};
  const variantsWithFlag: VariantWithActiveFlag[]  = [];
  const [error, setError]: UseState<string | undefined> = useState<string | undefined>(component?.errorMessage);
  Object.keys(variantsWithShortLabel).forEach((key: string): number => variantsWithFlag.push(
    { variantName: key, isActive : variantsWithShortLabel[key] }
  ));
  component.checkActiveValue();
  const componentValues: ObjectIteratorInterface = useMemo(
    (): ObjectIteratorInterface => {
      let values: ObjectIteratorInterface = {};
      (component.elements as VariantContainer[]).forEach((variant: VariantContainer): void => {
        variant.getChildrenFields().forEach((field: AbstractField): void => {
          values = { ...values, [field.name]: field.value };
        });
      });

      return values;
    },
    []
  );

  const closePopupHandler: () => void = (): void => {
    const validationSuccess: boolean = component.validate();
    if (!validationSuccess && !!componentValues) {
      (component.elements as VariantContainer[]).forEach((variant: VariantContainer): void => {
        variant.getChildrenFields().forEach((field: AbstractField): void => {
          if (!field.valid) {
            if (variant.shouldRestoreFromDatabase && !field.previousValidatedValue) {
              field.value = componentValues[field.name] ?? null;
            } else {
              field.value = field.previousValidatedValue ?? null;
            }
            field.validate();
          }
        });
      });
    }
    component.validate();
    if (fullScreenPopupContext?.onClose) {
      fullScreenPopupContext.onClose();
    }
    component.checkActiveValue();
    setIsPopupVisible(false);
  };

  const openPopupHandler: () => void = (): void => {
    setIsPopupVisible(true);
  };

  useEffect(
    (): void => {
      component.elements.forEach((element: componentTypes): void => {
        const decisionField: DecisionField | null = (element as VariantContainer).getDecisionField();
        if (!!decisionField && !!decisionField.validationMessages.length) {
          decisionField.validationMessages.forEach((validationMessage: ValidationMessages): void => {
            if (validationMessage.message === ErrorMessageEnum.form_field_conflict) {
              setError(t(ErrorMessageEnum.form_field_conflict, validationMessage.params));
            }
          });
        }
      });
    },
    [children]
  );

  useEffectAfterMount(
    (): void => {
      if (isPopupVisible) {
        component.validate();
      }
      if (!component?.withConflict) {
        setError(t(component?.errorMessage ?? ''));
      }
    },
    [children, component?.errorMessage]
  );

  return (
    <Container>
      <FlatBox
        icon={icon}
        label={boxLabel}
        variants={variantsWithFlag}
        variantsLabel={variantsLabel}
        actionLabel={actionLabel}
        warnLabel={warnLabel}
        hasWarning={(component.valid === false) || (component.withConflict === true)}
        isActive={component.active}
        errorMessage={error}
        onClick={openPopupHandler}
      />
      <FullscreenPopup
        header={popupHeader || boxLabel}
        subheader={popupSubheader}
        additionalInfoContent={additionalInfoContent}
        additionalInfoContentInReadMore={additionalInfoContentInReadMore}
        isVisible={isPopupVisible}
        closePopupAction={closePopupHandler}
      >
        {children}
      </FullscreenPopup>
    </Container>
  );
};
