import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Color } from '../../../../enums/color.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { ZIndex } from '../../../../enums/zIndex.enum';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { hexToRgba } from '../../../../utils/colors.utils';
import { styleWhenTrue } from '../../../../utils/style.utils';
import { Icon } from '../../../icon/icon.component';

import { PlayPauseButtonProps, WrapperProps } from './videoPlayer.types';

export const PlayPauseButton: StyledComponent<'button', PlayPauseButtonProps> = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: ${ZIndex.ProductVideoControls};
  width: 90px;
  height: 62px;
  border-radius: 12px;
  background-color: ${({ isPaused }: PlayPauseButtonProps): Color | string => (isPaused ? Color.Pink : hexToRgba(Color.Grey, 0.3))};
  opacity: 0;
  transition: 0.2s;
`;

export const CloseIcon: StyledComponent<typeof Icon> = styled(Icon)`
  transition: 0.3s;
`;

export const CloseButton: StyledComponent<'button'> = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  background-color: ${Color.Transparent};
  z-index: ${ZIndex.ProductVideoControls};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FullScreenButton: StyledComponent<'button'> = styled.button`
  position: absolute;
  bottom: 1px;
  right: 0;
  width: 36px;
  height: 36px;
  background-color: ${Color.Grey};
  z-index: ${ZIndex.ProductVideoControls};
  opacity: 1;
  transition: 0.2s;
`;

export const Wrapper: StyledComponent<'div', WrapperProps> = styled.div`
  width: 100%;
  max-width: 700px;
  max-height: 100%;
  position: fixed;
  z-index: ${ZIndex.ProductVideo};
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: none;

  :hover {
    ${PlayPauseButton} {
      opacity: 1;
    }

    ${FullScreenButton} {
      background-color: ${Color.Pink};
    }

    ${CloseButton} {
      background-color: ${Color.Pink};
    }

    ${CloseIcon} {
      color: ${Color.White};
    }
  }

  ${styleWhenTrue(
    prop('isOpen'),
    css`
      display: block;
    `
  )}
`;

export const Video: StyledComponent<'video'> = styled.video`
  width: 100%;
`;

export const VideoIcon: StyledComponent<typeof Icon> = styled(Icon)`
  transition: 0.2s;
  :hover {
    transform: scale(1.1);
  }
`;

export const VideoLabelIconContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const VideoLabel: StyledComponent<'p'> = styled.p`
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.8px;
  font-weight: ${FontWeight.Bold};
  margin-right: 10px;

  @media ${Breakpoint.Mobile} {
    display: none;
  }
`;
