import styled from 'styled-components';

import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { Color } from '../../../../enums/color.enum';
import { Markdown } from '../../../markdown/markdown.component';
import { GridWidth } from '../../../../enums/gridWidth.enum';

export const Wrapper: StyledComponent<'div'> = styled.div`
  padding: 0 35px 35px;
  background-color: ${Color.White};
  display: flex;
  justify-content: center;

  @media ${Breakpoint.Mobile} {
    padding: 0 20px 35px;
  }
`;

export const LegendSection: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${GridWidth.Desktop};
  width: 100%;
  justify-content: flex-start;
`;

export const LegendItem: StyledComponent<'div'> = styled.div`
  margin-bottom: 10px;
  display: flex;
`;

export const SupNumber: StyledComponent<'sup'> = styled.sup`
  vertical-align: super;
  font-size: 10px;
  letter-spacing: 2px;
  margin: -3px;
  margin-left: 3px;

  @media ${Breakpoint.Mobile} {
    margin-left: 2px;
  }
`;

export const Text: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 13.5px;
  margin-left: 4px;

  a {
    color: ${Color.Pink};
  }
`;
