import { FormContainerTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { AbstractContainer, ComponentInterface } from '@rehau/shared/forms/components';
import { componentTypes, fieldTypes, fieldValueType, staticElementTypes } from '@rehau/shared/forms/types';
import { AbstractField, DecisionField, InfoBox } from '@rehau/shared/forms/elements';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { Config } from '@rehau/shared/models/countryConfig';

export class MixedContainer extends AbstractContainer implements ComponentInterface {
  public elements: (fieldTypes | componentTypes | staticElementTypes)[];
  protected shouldRunValidationOnDecisionTrue?: boolean;
  private clearIfDecisionNegative: boolean;

  public constructor(
    frontendComponent: FrontendComponentEnum,
    title: string,
    elements: (fieldTypes | componentTypes | staticElementTypes)[] = [],
    countryConfig?: Config,
    informationBox?: InfoBox,
    subTitle?: string,
    clearIfDecisionNegative: boolean = false,
    shouldRunValidationOnDecisionTrue?: boolean
  ) {
    super(FormContainerTypeEnum.MixedContainer, frontendComponent, title, countryConfig, informationBox, subTitle);
    this.elements = elements;
    this.clearIfDecisionNegative = clearIfDecisionNegative;
    this.shouldRunValidationOnDecisionTrue = shouldRunValidationOnDecisionTrue;
  }

  public addElement(
    element: componentTypes | DecisionField,
    condition?: boolean,
    name?: string
  ): this {
    if (condition !== false) {
      if (name) {
        if (element instanceof DecisionField) {
          element.name = name;
        } else {
          element.setName(name);
        }
      }

      this.elements.push(element);
    }

    return this;
  }

  public setValues(values: ObjectIteratorInterface<fieldValueType>): void {
    super.setValues(values);
    const decisionField: fieldTypes | null = this.getDecisionField();
    if (decisionField && (values[decisionField.id] !== undefined)) {
      this.getChildrenFields().forEach((field: fieldTypes): void => {
        if (decisionField.value) {
          field.setDefaultRequirements();
        } else {
          field.required = false;
          if (this.clearIfDecisionNegative) {
            field.value = field.defaultValue || null;
            field.valid = undefined;
          }
        }
      });
    }
    if (decisionField?.value && this.shouldRunValidationOnDecisionTrue !== false) {
      super.validate();
    }
  }

  public getChildrenContainer(): (fieldTypes | componentTypes | staticElementTypes)[] {
    return this.elements.filter(
      (element: fieldTypes | componentTypes | staticElementTypes): boolean => !(element instanceof DecisionField)
    );
  }

  public getChildrenFields(): fieldTypes[] {
    return this.getFields(
      this.elements.filter((element: fieldTypes | componentTypes | staticElementTypes): boolean => !(element instanceof DecisionField))
    );
  }

  public getDecisionField(): DecisionField | null {
    return this.elements.find(
      (element: fieldTypes | componentTypes | staticElementTypes): boolean => (element instanceof DecisionField)
    ) as DecisionField ?? null;
  }

  protected getFields(elements: (fieldTypes | componentTypes | staticElementTypes)[]): fieldTypes[] {
    let fields: fieldTypes[] = [];
    for (const element of elements) {
      if (element instanceof AbstractField) {
        fields.push(element);
      }
      if (element instanceof AbstractContainer) {
        fields = [...fields, ...this.getFields(element.elements)];
      }
    }

    return fields;
  }
}
