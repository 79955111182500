export enum Color {
  Green = '#38A58C',
  White = '#FFFFFF',
  Black = '#000000',
  BgGrey = '#F6F6F6',
  GreyStroke = '#DEDEDE',
  Grey = '#7F7F7F',
  GreyUltraLight = '#FAFAFA',
  GreyInput = '#F1F1F1',
  Tint4 = '#F2F2F2',
  Pink = '#DD0060',
  ActiveRed = '#CB2B61',
  Validation = '#F5EFC8',
  ValidationBorder = '#E3A008',
  ValidationFont = '#403822',
  Transparent = 'transparent',
  OpacityPink = '#DD00600D',
  ErrorBg = '#FCE6EF',
  Violet = '#8C76E1',
  Blue = '#7CB3E2',
  Yellow = '#FAC075',
  CompanyColor = 'var(--company-color)',
}
