import { AppConfigType, InjectedWindowValues } from '@rehau-fe/ui/src';
import { WebsiteComponentEnum } from '@rehau/shared/enums';

declare const window: InjectedWindowValues;

const mainLanguageFromWindow: string = (typeof window !== 'undefined') ? window.MAIN_LANGUAGE : '';
const externalAssetsFromWindow: string = (typeof window !== 'undefined') ? window.EXTERNAL_ASSETS_PATH : '';
const forceEnglishLanguageFromWindow: string = (typeof window !== 'undefined') ? window.FORCE_ENGLISH_LANGUAGE : '';
const locationObject: Location = (typeof window !== 'undefined') ? window.location : {} as Location;
const domain: string = locationObject.hostname ? locationObject.hostname.replace('www.', '') : '';
const offerCreatorUrl: string = (typeof window !== 'undefined') ? window.CREATOR_URL : '';

export const AppConfig: AppConfigType = {
  version: process.env.REACT_APP_VERSION ?? 'unknown',
  forceEnglishLanguage: !!forceEnglishLanguageFromWindow,
  mainLanguage: process.env.REACT_APP_MAIN_LANGUAGE
    ? process.env.REACT_APP_MAIN_LANGUAGE
    : !!mainLanguageFromWindow
      ? mainLanguageFromWindow
      : 'pl_PL',
  api: process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : '/api/v1',
  webservice: process.env.REACT_APP_WEBSERVICE
    ? process.env.REACT_APP_WEBSERVICE
    : `ws://${domain}`,
  externalAssets: process.env.REACT_APP_EXTERNAL_ASSETS
    ? process.env.REACT_APP_EXTERNAL_ASSETS
    : !!externalAssetsFromWindow
      ? externalAssetsFromWindow
      : 'https://s3.docker.mug.pl/rehau',
  appType: WebsiteComponentEnum.Website,
  googleMapsApiKey: '',
  creatorUrl: process.env.REACT_APP_CREATOR_URL ?? offerCreatorUrl,
};
