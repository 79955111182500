import styled from 'styled-components';

import { Icon } from '../icon/icon.component';
import { Color } from '../../enums/color.enum';
import { ZIndex } from '../../enums/zIndex.enum';
import { BoxShadow } from '../../enums/boxShadow.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { Logo } from '../logo/logo.component';
import { Breakpoint } from '../../enums/breakpoint.enum';

export const Wrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  height: 58px;
  width: 100%;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  z-index: ${ZIndex.Navigation};
  background-color: ${Color.White};
  box-shadow: ${BoxShadow.BtnGreyShadow};
  padding-left: 15px;

  @media ${Breakpoint.Tablet} {
    box-sizing: border-box;
    padding-right: 15px;
  }
`;

export const InterfaceWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 31px;
  z-index: ${ZIndex.Overlay};
`;

export const StyledLogo: StyledComponent<typeof Logo> = styled(Logo)`
  width: 164px;
`;

export const MenuButton: StyledComponent<'button'> = styled.button``;
