import React from 'react';

import { UseTimelineAnimationOutput } from '../../../../types/useTimelineAnimation.types';
import { useTimelineAnimation } from '../../../../hooks/useTimelineAnimation';
import { assignColumnRef } from '../../utils/columnRef.utils';
import { Title } from '../title/title.component';
import { useProductPopupContext } from '../../../../hooks/useProductPopupContext.hook';
import { ProductPopupContextType } from '../../../../types/productPopupContextType.types';

import { ColumnData, TextWithColumnsProps } from './textWithColumns.types';
import { ColumnType } from './textWithColumns.enums';
import {
  Box,
  Column,
  Columns,
  ColumnsTitle,
  ColumnText,
  ColumnTitle,
  Container,
  Hint,
  IconContainer,
  Image,
  StyledMarkdown,
  TextWrapper,
  Wrapper,
} from './textWithColumns.styled';

export const TextWithColumns: React.FC<TextWithColumnsProps> = (props: TextWithColumnsProps): JSX.Element => {
  const {
    className,
    title,
    text,
    columnType,
    columnsTitle,
    columnsData,
    darkBackground,
    hint,
    withFullWidthImage = false,
  }: TextWithColumnsProps = props;
  const isDescriptionColumn: boolean = columnType === ColumnType.Description;
  const withImage: boolean = columnType === ColumnType.Image || columnType === ColumnType.ImageWithBox;
  const { isInPopup }: ProductPopupContextType = useProductPopupContext();
  const timelineAnimation: UseTimelineAnimationOutput = useTimelineAnimation(isInPopup);

  return (
    <Container className={className} darkBackground={darkBackground} data-testid='columns-section'>
      <Wrapper>
        {!!title && <Title data-testid='columns-section-title'>{title}</Title>}
        {!!text && (
          <StyledMarkdown text={text} data-testid='columns-section-text' />
        )}
        {!!columnsTitle && <ColumnsTitle small data-testid='columns-section-columns-title'>{columnsTitle}</ColumnsTitle>}
        <Columns>
          {columnsData.map((column: ColumnData, index: number): JSX.Element => {
            const columnRef: React.RefObject<HTMLDivElement> = assignColumnRef(index, timelineAnimation);

            return (
              <Column
                key={index}
                isDescriptionColumn={isDescriptionColumn}
                isIconBoxColumn={columnType === ColumnType.IconBox}
                data-testid='product-column'
                ref={columnRef}
              >
                {columnType === ColumnType.Simple && <Box data-testid='simple-column-item'>{column.value}</Box>}
                {withImage && (
                  <Image
                    data-testid='image-column-item'
                    src={column.imageSrc}
                    alt=''
                    fullWidth={withFullWidthImage}
                  />
                )}
                <TextWrapper
                  isDescriptionColumn={isDescriptionColumn}
                  isIconBoxColumn={columnType === ColumnType.IconBox}
                  isImageWithBoxColumn={columnType === ColumnType.ImageWithBox}
                >
                  {columnType === ColumnType.IconBox && (
                    <IconContainer data-testid='icon-box-column-item'>
                      {column.value}
                    </IconContainer>
                  )}
                  {!!column.title && <ColumnTitle isDescriptionColumn={isDescriptionColumn}>{column.title}</ColumnTitle>}
                  <ColumnText text={column.text} tagType='p' />
                </TextWrapper>
              </Column>
            );
          })}
        </Columns>
        {!!hint && <Hint data-testid='columns-section-hint'>{hint}</Hint>}
      </Wrapper>
    </Container>
  );
};
