import styled from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { Markdown } from '../markdown/markdown.component';

export const Wrapper: StyledComponent<'div'> = styled.div`
  margin-right: 15px;
`;

export const SmallTitle: StyledComponent<'p'> = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-weight: ${FontWeight.Black};
  color: ${Color.Green};
  margin-bottom: 15px;
  text-transform: uppercase;
`;

export const Title: StyledComponent<'h2'> = styled.h2`
  font-size: 28px;
  font-weight: ${FontWeight.Black};
  line-height: 32px;
  margin-bottom: 10px;
  max-width: 480px;
`;

export const Description: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 25px;
  max-width: 480px;
`;
