import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Logo } from '../../logo/logo.component';
import { Breakpoint } from '../../../enums/breakpoint.enum';
import { CustomLink } from '../../customLink/customLink.component';
import { WebsitePathNameEnum } from '../../../../../../../shared/enums';

import { InfoBaseProps } from './infoBase.types';
import { Bar, Content, ContentContainer, Img, Wrapper } from './infoBase.styled';

export const InfoBase: React.FC<InfoBaseProps> = (props: InfoBaseProps): JSX.Element => {
  const { children, imgUrl, hasBar = true }: InfoBaseProps = props;
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });

  return (
    <Wrapper data-testid='info-base' hasBar={hasBar}>
      {!!hasBar && (
        <Bar data-testid='info-base-bar'>
          <CustomLink to='/' pathName={WebsitePathNameEnum.Home}>
            <Logo />
          </CustomLink>
        </Bar>
      )}
      <ContentContainer data-testid='info-base-content'>
        {isMobile && <Img src={imgUrl} />}
        <Content>
          {children}
          {!isMobile && <Img src={imgUrl} />}
        </Content>
      </ContentContainer>
    </Wrapper>
  );
};
