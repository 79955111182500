import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { UseImageAnimationOutput } from '../../../../types/useImageAnimation.types';
import { useImageAnimation } from '../../../../hooks/useImageAnimation';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Title } from '../title/title.component';
import { useProductPopupContext } from '../../../../hooks/useProductPopupContext.hook';
import { ProductPopupContextType } from '../../../../types/productPopupContextType.types';

import {
  Container,
  Image,
  ImageContainer,
  Item,
  ItemImage,
  Items,
  ItemText,
  ItemTitle,
  Main,
  Row,
  RowImage,
  RowImageMobile,
  RowText,
  RowTitle,
  StyledMarkdown,
  TextBox,
  TextContent,
  Wrapper,
} from './iconItems.styled';
import { IconItem, IconItemsProps, RowItem } from './iconItems.types';

export const IconItems: React.FC<IconItemsProps> = (props: IconItemsProps): JSX.Element => {
  const {
    className,
    title,
    text,
    itemsData,
    imageSrc,
    imageSrcMobile,
    rowsTitle,
    rowsData = [],
  }: IconItemsProps = props;
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const { isInPopup }: ProductPopupContextType = useProductPopupContext();
  const { firstImageRef }: UseImageAnimationOutput = useImageAnimation(isInPopup);

  return (
    <Container className={className} data-testid='icon-items'>
      <Wrapper>
        <Main>
          <TextContent>
            {!!title && <Title data-testid='icon-items-title'>{title}</Title>}
            {!!text && (
              <StyledMarkdown text={text} data-testid='icon-items-text' />
            )}
            <Items withoutMainImage={!imageSrc}>
              {itemsData.map((item: IconItem, index: number): JSX.Element => (
                <Item key={index} data-testid='icon-item' withoutMainImage={!imageSrc}>
                  <ItemImage src={item.imageSrc} />
                  {!!item.title && <ItemTitle>{item.title}</ItemTitle>}
                  <ItemText
                    withoutMainImage={!imageSrc}
                    text={item.text}
                  />
                </Item>
              ))}
            </Items>
          </TextContent>
          {(!!imageSrc && !!imageSrcMobile) && (
            <ImageContainer ref={firstImageRef}>
              {!isTablet && <Image src={imageSrc} />}
              {isTablet && <Image src={isMobile ? imageSrc : imageSrcMobile} />}
            </ImageContainer>
          )}
        </Main>
        {!!rowsTitle && <Title data-testid='icon-items-rows-title'>{rowsTitle}</Title>}
        {rowsData.map((row: RowItem): JSX.Element => (
          <Row key={row.title} data-testid='icon-items-section-row'>
            {isMobile ? (
              <RowImageMobile src={row.imageSrcMobile} />
            ) : (
              <RowImage src={row.imageSrc} />
            )}
            <TextBox>
              <RowTitle>{row.title}</RowTitle>
              <RowText text={row.text} />
            </TextBox>
          </Row>
        ))}
      </Wrapper>
    </Container>
  );
};
