import styled from 'styled-components';

import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Color } from '../../../../enums/color.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { StyledComponent } from '../../../../types/styledComponent.types';

export const Column: StyledComponent<'ul'> = styled.ul`
  color: ${Color.White};
  font-size: 16px;
  width: 100%;

  & + & {
    margin-left: 100px;
  }

  @media ${Breakpoint.Desktop} {
    & + & {
      margin-left: 75px;
    }
  }
  @media ${Breakpoint.Tablet} {
    & + & {
      margin-left: 30px;
    }
  }
  @media ${Breakpoint.Mobile} {
    & + & {
      margin-left: 0;
      margin-top: 35px;
    }
  }
`;

export const RowItem: StyledComponent<'li'> = styled.li`
  margin-bottom: 15px;
  line-height: 20px;

  a {
    text-decoration: none;
    color: ${Color.White};
    transition: color .2s ease-in-out 0s;

    :hover {
      color: ${Color.Pink};
    }
  }
`;

export const ColumnTitle: StyledComponent<'p'> = styled.p`
  font-weight: ${FontWeight.Black};
  margin-bottom: 25px;
`;

export const FooterColumns: StyledComponent<'div'> = styled.div`
  display: flex;
  width: 100%;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;
