import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Color } from '../../enums/color.enum';
import { StyledComponent } from '../../types/styledComponent.types';

export const StyledA: StyledComponent<'a'> = styled.a`
  text-decoration: none;
  color: ${Color.Black};
`;

export const StyledLink: StyledComponent<typeof Link> = styled(Link)`
  text-decoration: none;
  color: ${Color.Black};
`;
