import React from 'react';
import { Marker, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { LatLngTuple } from 'leaflet';

import { Autocomplete } from '@rehau/shared/enums';
import { ValidationStatus } from '../../enums/validationStatus.enum';
import { InputContainer } from '../input/inputContainer/inputContainer.component';
import { StandardInput } from '../input/standardInput/standardInput.component';

import { Address, Container, Map, MapCover, Tooltip } from './locationInput.styled';
import { LocationInputProps } from './locationInput.types';
import { mapCopyright, mapOffset, mapSource, mapZoom, marker } from './locationInput.constants';

export const LocationInput: React.FC<LocationInputProps> = (props: LocationInputProps): JSX.Element => {
  const {
    className,
    latitude,
    longitude,
    inputLabel,
    address,
    postalCode,
    city,
    country,
    validationStatus = ValidationStatus.Untouched,
  }: LocationInputProps = props;

  return (
    <Container className={className}>
      <InputContainer validationStatus={validationStatus} label={inputLabel} disabled>
        <StandardInput value={(!!latitude && !!longitude) ? `${latitude},${longitude}` : '-'} disabled autocomplete={Autocomplete.Off} />
      </InputContainer>
      {(!!latitude && !!longitude) ? (
        <Map
          center={[latitude + mapOffset, longitude]}
          zoom={mapZoom}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution={mapCopyright}
            url={mapSource}
          />
          {!!latitude && !!longitude && (
            <Marker position={[latitude, longitude]} icon={marker}>
              <Tooltip permanent direction='top' opacity={1} offset={[1, -40]}>
                <Address>{address}</Address>
                <Address>{`${postalCode} ${city}, ${country}`}</Address>
              </Tooltip>
            </Marker>
          )}
        </Map>
      ) : (
        <MapCover />
      )}
    </Container>
  );
};
