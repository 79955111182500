import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { DecisionField } from '../../../../../../../shared/forms/elements';
import { Decision } from '../../decision/decision.component';
import { FieldElement, FieldElementProps } from '../types';

export const DecisionFieldElement: FieldElement<DecisionField> = (props: FieldElementProps<DecisionField>): JSX.Element => {
  const { component, save, setValues }: FieldElementProps<DecisionField> = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <Decision
      acceptText={t(component.labelTrue)}
      declineText={t(component.labelFalse)}
      label={t(component.label || '')}
      component={component}
      handleSave={save}
      setValues={setValues}
    />
  );
};
