import { componentTypes } from '@rehau/shared/forms';
import { SelectItemField } from '@rehau/shared/forms/elements';

export const isSharedElementAvailable: (
  component: componentTypes | SelectItemField
) => boolean = (
  component: componentTypes | SelectItemField
): boolean => {
  if (!component.available) {
    return false;
  }
  if (!!(component as componentTypes).isAnyFieldAvailable) {
    return (component as componentTypes).isAnyFieldAvailable();
  }

  return true;
};
