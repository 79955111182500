import styled from 'styled-components';

import { BoxShadow } from '../../enums/boxShadow.enum';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { GridWidth } from '../../enums/gridWidth.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { Icon } from '../icon/icon.component';

export const Wrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: ${GridWidth.Desktop};
  min-height: 100px;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  background-color: ${Color.White};
  padding: 0 28px 0 12px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    align-items: flex-start;
    padding: 35px 20px;
  }
`;

export const CompanyInfoContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  margin-left: 12px;
  margin-right: 10px;
  @media ${Breakpoint.Mobile} {
    padding-bottom: 25px;
    border-bottom: 1px solid ${Color.GreyStroke};
    margin: 20px 0 20px;
  }
`;

export const CompanyName: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 22px;
  font-weight: ${FontWeight.Bold};
  margin-bottom: 3px;
`;

export const CompanyAddress: StyledComponent<'p'> = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${Color.Grey};
`;

export const IconContainer: StyledComponent<'div'> = styled.div`
  flex-shrink: 1;
  @media ${Breakpoint.Mobile} {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
  }
`;

export const ButtonContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media ${Breakpoint.Mobile} {
    max-width: unset;
  }
`;

export const DistanceBox: StyledComponent<'div'> = styled.div`
  padding: 0 15px;
  height: 44px;
  border: 1px solid ${Color.GreyStroke};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 3px;
  max-width: 220px;
  width: 100%;
  box-sizing: border-box;

  @media ${Breakpoint.Mobile} {
    margin-top: 10px;
  }
`;

export const DistanceText: StyledComponent<'span'> = styled.span`
  color: ${Color.Grey};
  margin-right: 5px;
`;

export const DistanceBoxText: StyledComponent<'p'> = styled.p`
  font-size: 16px;
  line-height: 18px;
  word-break: break-all;
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  @media ${Breakpoint.Mobile} {
    margin-left: -25px;
  }
`;
