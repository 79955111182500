import { RouteInfo } from '@rehau/shared/models/countryConfig';
import { UseRedirectOutput } from '../../../types/useRedirect.types';
import { useRoutesInfo } from '../../../hooks/useRoutesInfo.hook';
import { useRedirect } from '../../../hooks/useRedirect';
import { findPathByName } from '../../../utils/findPathByName.utils';
import { WebsitePathNameEnum } from '@rehau/shared/enums';
import { UseCalculatorNavigate } from '../products.types';

export const useCalculatorNavigate: () => UseCalculatorNavigate = (): UseCalculatorNavigate => {
  const routesArray: RouteInfo[] = useRoutesInfo();
  const handleRedirect: UseRedirectOutput = useRedirect();

  const navigateToCalculator: () => void = (): void => {
    handleRedirect(findPathByName(WebsitePathNameEnum.Calculator, routesArray), WebsitePathNameEnum.Calculator);
  };

  return { navigateToCalculator };
};
