import React, { MouseEventHandler, ReactElement, useCallback, useState } from 'react';

import { SelectField } from '@rehau/shared/forms/elements';
import { UseState } from '../../types/useState.types';
import { useEffectAfterMount } from '../../hooks/useEffectAfterMount';

import { Wrapper } from './choiceGroup.styled';
import { ChoiceGroupProps } from './choiceGroup.types';

export const ChoiceGroup: React.FC<ChoiceGroupProps> = (
  props: ChoiceGroupProps
): JSX.Element => {
  const {
    children,
    isColumn = false,
    onChange,
    initialActive,
    reset,
    className,
    component = new SelectField('', '', '', true),
    handleSave = (): void => undefined,
    setValues = (): void => undefined,
  }: ChoiceGroupProps = props;
  const [chosenItem, setChosenItem]: UseState<string> = useState(initialActive
    || (component.value && Array.isArray(component.value) && component.value[0].toString())
    || ''
  );

  useEffectAfterMount(
    (): void => {
      if (!!onChange) {
        onChange(chosenItem);
        if (!!setValues && !!handleSave) {
          setValues({ [component.id]: chosenItem });
          const validationSuccess: boolean = component.validate();
          if (validationSuccess) {
            handleSave();
          }
        }
      }
    },
    [chosenItem]
  );

  useEffectAfterMount(
    (): void => {
      if (!!reset) {
        setChosenItem('');
      }
    },
    [reset]
  );

  useEffectAfterMount(
    (): void => {
      if (initialActive && chosenItem !== initialActive) {
        setChosenItem(initialActive);
      }
    },
    [initialActive]
  );

  const handleClick: MouseEventHandler = useCallback(
    (event: { currentTarget: { id: string } }): void => {
      setChosenItem(event.currentTarget.id);
      if (!!setValues && !!handleSave) {
        setValues({ [component.id]: event.currentTarget.id });
        const validationSuccess: boolean = component.validate();
        if (validationSuccess) {
          handleSave();
        }
      }
    },
    [chosenItem]
  );

  const childrenClone: ReactElement[] = React.Children.map(
    children,
    (child: ReactElement): ReactElement => {
      return React.cloneElement(child, {
        onClick: handleClick,
        isActive: child.props.id === chosenItem,
        isUntouched: !chosenItem && !child.props.isDisabled,
        isDisabled: child.props.isDisabled,
      });
    }
  );

  return <Wrapper isColumn={isColumn} className={className} data-testid='choice-group'>{childrenClone}</Wrapper>;
};
