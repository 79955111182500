import styled from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { Icon } from '../icon/icon.component';
import { Markdown } from '../markdown/markdown.component';
import { Color } from '../../enums/color.enum';
import { StyledComponent } from '../../types/styledComponent.types';

export const Wrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  max-width: 580px;
  min-height: 120px;
  padding: 36px 25px;
  box-sizing: border-box;
  background-color: ${Color.BgGrey};

  @media ${Breakpoint.Mobile} {
    width: 100%;
    max-width: 100%;
    padding: 30px 20px;

    & + & {
      margin-left: 0px;
    }
  }
`;

export const ContentContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Content: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;
  font-weight: ${FontWeight.Regular};
  word-break: break-word;
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  margin-right: 25px;
  flex-shrink: 0;

  @media ${Breakpoint.Mobile} {
    margin-bottom: 20px;
  }
`;
