export enum UserRegistrationSortEnum {
  Id = 'id',
  CreatedAt = 'createdAt',
  CompanyName = 'companyName',
  UserName = 'userName',
  CountryName = 'countryName',
  CompanyCity = 'companyCity',
  CompanyPostalCode = 'companyPostalCode',
  CrmId = 'crmid',
}
