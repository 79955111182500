import styled, { StyledComponent } from 'styled-components';

import { FontWeight } from '../../enums/fontWeight.enum';

export const Wrapper: StyledComponent<'div', {}, {}, never> = styled.div`
  display: flex;
  align-content: center;
  height: 100%;
  position: relative;
`;

export const StyledInput: StyledComponent<'input', {}, {}, never> = styled.input`
  font-weight: ${FontWeight.Bold};
  width: 100%;
  font-size: 18px;
  padding: 0 5px 0 0;
  display: flex;
  align-items: center;
  min-width: 0;
  &::placeholder {
    font-size: 18px;
    font-weight: ${FontWeight.Regular};
  }
`;
