import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { StyledComponent } from '../../../../types/styledComponent.types';
import { InnerContainer, OuterContainer } from '../../../../theme/containers.styled';
import { Color } from '../../../../enums/color.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { styleIfElse, styleWhenTrue } from '../../../../utils/style.utils';
import { Title as TitleBase } from '../title/title.component';
import { Markdown } from '../../../markdown/markdown.component';

import { BoxProps, BoxTitleProps, MarkdownProps, TextContentProps } from './textWithBox.types';

export const Container: StyledComponent<typeof OuterContainer> = styled(OuterContainer)`
  padding-top: 80px;
  padding-bottom: 100px;

  @media ${Breakpoint.Tablet} {
    margin: 0;
    width: 100%;
    padding: 60px 40px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 60px 20px;
  }
`;

export const Wrapper: StyledComponent<typeof InnerContainer> = styled(InnerContainer)`
  flex-direction: column;
`;

export const SectionTitle: StyledComponent<typeof TitleBase> = styled(TitleBase)`
  margin-bottom: 40px;

  @media ${Breakpoint.Tablet} {
    margin-bottom: 25px;
  }
`;

export const Content: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
  }
`;

export const TextContent: StyledComponent<'div', TextContentProps> = styled.div`
  margin-right: ${styleIfElse(prop('hasWideText'), '0', '80px')};
  flex: ${styleIfElse(prop('hasWideText'), '2', '1')};
  min-width: 380px;

  @media ${Breakpoint.Desktop} {
    margin-right: ${styleIfElse(prop('hasWideText'), '54px', '80px')};
  }

  @media ${Breakpoint.Tablet} {
    margin-right: 0;
    margin-bottom: 40px;
    min-width: 0;
  }
`;

export const ContentTitle: StyledComponent<typeof TitleBase> = styled(TitleBase)`
  max-width: 540px;

  @media ${Breakpoint.Tablet} {
    max-width: none;
  }
`;

export const StyledMarkdown: StyledComponent<typeof Markdown, MarkdownProps> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.27px;

  strong {
    font-weight: ${FontWeight.Bold};
  }

  ${styleWhenTrue(prop('isTopSection'), css`
    letter-spacing: unset;
  `)}
`;

export const BoxWrapper: StyledComponent<'div'> = styled.div`
  margin-top: 5px;
  flex: 1.38;
`;

export const Box: StyledComponent<'div', BoxProps> = styled.div`
  background-color: ${Color.Green};
  padding: 30px 39px 30px 30px;
  margin-left: auto;

  ${styleWhenTrue(prop('hasWideText'), css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    padding: 33px 50px;
    width: 350px;
    min-height: 0;
  `)}

  @media ${Breakpoint.Tablet} {
    margin-left: 0;

    ${styleWhenTrue(prop('hasWideText'), css`
      width: 100%;
      max-width: 335px;
      padding: 33px 43px;
  `)}
  }
`;

export const BoxTitle: StyledComponent<'h5', BoxTitleProps> = styled.h5`
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.27px;
  font-weight: ${FontWeight.Bold};
  margin-bottom: 10px;
  color: ${Color.White};

  ${styleWhenTrue(prop('hasWideText'), css`
    margin-bottom: 0;
    font-size: 35px;
    line-height: 42px;
  `)}
`;

export const BoxMarkdown: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;
  color: ${Color.White};
`;
