import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { BoxShadow } from '../../enums/boxShadow.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';
import { hexToRgba } from '../../utils/colors.utils';

import { SwitchContainerProps } from './toggleSwitch.types';

export const Label: StyledComponent<'div'> = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${Color.Black};
  margin-right: 15px;
`;

export const Ball: StyledComponent<'div'> = styled.div`
  transform: translate(-100%, -50%);
  width: 20px;
  height: 20px;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  background-color: ${Color.White};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 23px;
  transition: 0.3s;
`;

export const ActiveLabel: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Black};
  color: ${Color.White};
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 0 0 0 5px;
  opacity: 0;
  transition: 0.3s;
`;

export const InactiveLabel: StyledComponent<typeof ActiveLabel> = styled(ActiveLabel)`
  color: ${Color.ValidationFont};
  padding: 0 5px 0 0;
  opacity: 1;
`;

export const SwitchContainer: StyledComponent<'button', SwitchContainerProps> = styled.button<SwitchContainerProps>`
  display: flex;
  align-items: center;
  border-radius: 50px;
  background-color: ${Color.Validation};
  transition: 0.3s;
  height: 26px;
  position: relative;
  padding: 0 7px;
  box-sizing: border-box;

  ${styleWhenTrue(prop('withBorder'), css`
    border: 1px solid ${hexToRgba(Color.Black, 0.15)};
  `)}

  ${styleWhenTrue(
    prop('isActive'),
    css`
      border: none;
      background-color: ${Color.Green};
      ${Ball} {
        left: calc(100% - 3px);
      }
      ${ActiveLabel} {
        opacity: 1;
      }
      ${InactiveLabel} {
        opacity: 0;
      }
    `
  )};
`;

export const TooltipContainer: StyledComponent<'div'> = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 6px);
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
`;

export const TooltipArrow: StyledComponent<'div'> = styled.div`
  border-left: 6px solid ${Color.Transparent};
  border-right: 6px solid ${Color.Transparent};
  border-bottom: 6px solid ${Color.Black};
  width: 0;
  height: 0;
  margin-left: calc(100% - 25px);
`;

export const TooltipContent: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Black};
  color: ${Color.White};
  box-shadow: ${BoxShadow.BtnGreyShadow};
  padding: 10px 13px;
  font-size: 14px;
  line-height: 17px;
  border-radius: 2px;
`;

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: max-content;
  &:hover {
    ${TooltipContainer} {
      opacity: 1;
    }
  }
`;
