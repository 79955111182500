import React, { ChangeEvent, ChangeEventHandler, KeyboardEventHandler, useState } from 'react';

import { IconName } from '../../../../../../../shared/enums';
import { Color } from '../../../enums/color.enum';
import { InputType } from '../../../enums/inputType.enum';
import { KeyboardKey } from '../../../enums/keyboardKey.enum';
import { UseState } from '../../../types/useState.types';
import { Icon } from '../../icon/icon.component';
import { StyledInput, Wrapper } from '../input.styled';

import { ChangeVisibilityButton } from './passwordInput.styled';
import { PasswordInputProps } from './passwordInput.types';

export const PasswordInput: React.FC<PasswordInputProps> = (
  props: PasswordInputProps
): JSX.Element => {
  const {
    placeholder,
    onChange,
    onBlur,
    onSubmit,
    onFocus,
    value,
    className,
    autocomplete,
    name,
  }: PasswordInputProps = props;
  const [inputType, setInputType]: UseState<InputType> = useState<InputType>(InputType.Password);

  const changePasswordVisibility: () => void = (): void => {
    setInputType(inputType === InputType.Password ? InputType.Text : InputType.Password);
  };

  const onKeyUp: KeyboardEventHandler = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === KeyboardKey.Enter && onSubmit) {
      onSubmit();
    }
  };

  const handleOnChange: ChangeEventHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <Wrapper className={className}>
      <StyledInput
        placeholder={placeholder}
        onChange={handleOnChange}
        onBlur={onBlur}
        type={inputType}
        value={value}
        data-testid='input'
        autoComplete={autocomplete}
        name={name}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
      />
      <ChangeVisibilityButton onClick={changePasswordVisibility}>
        <Icon name={inputType === InputType.Password ? IconName.VisibilityOff : IconName.Visibility} size={20} color={Color.Green} />
      </ChangeVisibilityButton>
    </Wrapper>
  );
};
