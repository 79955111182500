import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { Price } from '../../../theme/price/price.component';
import { Color } from '../../../enums/color.enum';
import { styleWhenTrue } from '../../../utils/style.utils';
import { ActiveItemProps } from '../select.types';
import { StyledComponent } from '../../../types/styledComponent.types';
import { TooltipContainer } from '../select.styled';

export const Wrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  margin-top: 21px;
  align-items: center;
  justify-content: space-between;
  &:nth-child(1) {
    margin-top: 0;
  }
`;

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
`;

export const PriceContainer: StyledComponent<typeof Container> = styled(Container)`
  margin-right: -22px;
`;

export const Button: StyledComponent<'button', ActiveItemProps> = styled.button`
  border: 1px solid ${Color.GreyStroke};
  width: 22px;
  height: 22px;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 1px;
  margin-right: 15px;
  ${styleWhenTrue(
    prop('isActive'),
    css`
      border: 6px solid ${Color.Green};
      padding: 0;
    `
  )}
`;

export const StyledTooltipContainer: StyledComponent<typeof TooltipContainer> = styled(TooltipContainer)`
  position: relative;
  left: 30px;
`;
