import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { FieldElement, FieldElementProps } from '../types';
import { DateField } from '../../../../../../../shared/forms/elements';
import { DatePicker } from '../../datePicker/datePicker.component';

export const DatepickerFieldElement: FieldElement<DateField> = (props: FieldElementProps<DateField>): JSX.Element => {
  const { component, save, setValues }: FieldElementProps<DateField> = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <DatePicker
      label={t(component.label || '')}
      component={component}
      handleSave={save}
      id={component.id}
      initialDate={component.value as number}
      disabled={component.onlyForRead || component.disabled}
      onChoose={save}
      setValues={setValues}
    />
  );
};
