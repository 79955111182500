import { BasicServicesEnum } from '@rehau/shared/enums';
import { ServiceSimpleModel } from '@rehau/shared/models/calculation/common';

export const BasicServicesArray: ServiceSimpleModel[] = [
  {
    id: BasicServicesEnum.HeatingAndCooling,
    label: 'rehau.calculations.basic_services.heating_and_cooling.label',
  },
  {
    id: BasicServicesEnum.FloorHeating,
    label: 'rehau.calculations.basic_services.floor_heating.label',
  },
  {
    id: BasicServicesEnum.DrinkingWaterInstallation,
    label: 'rehau.calculations.basic_services.drinking_water_installation.label',
  },
  {
    id: BasicServicesEnum.Sewage,
    label: 'rehau.calculations.basic_services.sewage.label',
  },
];
