import { prop } from 'ramda';
import styled, { css, StyledComponent } from 'styled-components';

import { Color } from '../../enums/color.enum';
import { ZIndex } from '../../enums/zIndex.enum';
import { styleWhenTrue } from '../../utils/style.utils';
import { hexToRgba } from '../../utils/colors.utils';

import { OverlayProps } from './overlay.types';

export const Overlay: StyledComponent<'div', {}, OverlayProps, never> = styled.div<OverlayProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: ${hexToRgba(Color.Grey, 0)};
  opacity: 0;
  z-index: ${ZIndex.Hidden};
  transition: 0.2s ease;
  pointer-events: none;
  ${styleWhenTrue(
    prop('isActive'),
    css`
      background-color: ${hexToRgba(Color.Grey, 0.6)};
      opacity: 1;
      z-index: ${ZIndex.Overlay};
      pointer-events: initial;
    `
  )};
`;
