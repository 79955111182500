import { MultiSelectField, SelectOption } from '@rehau/shared/forms/elements/fields';
import { FormFieldTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { fieldValueType } from '@rehau/shared/forms';

export class SelectField extends MultiSelectField {
  public placeholder?: string;

  constructor(
    id: string,
    name: string,
    label: string | null,
    required: boolean,
    allowedOptions: SelectOption[] = [],
    defaultValue?: string[]
  ) {
    super(id, name, label, required, allowedOptions, defaultValue);
    this.type = FormFieldTypeEnum.SelectField;
    this.frontendComponent = FrontendComponentEnum.Select;
  }

  public setValue(value: string | string[] | null): this {
    const selectedValue: string | null = value
      ? Array.isArray(value)
        ? value[0] ?? null
        : value
      : null;
    super.setValue(selectedValue);

    return this;
  }

  public getValue(): fieldValueType {
    return Array.isArray(this.value) ? this.value[0] : this.value;
  }
}
