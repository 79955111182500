import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { BaseLabel } from '../../theme';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { Tooltip } from '../tooltip/tooltip.component';
import { Icon } from '../icon/icon.component';

import { WrapperProps } from './imageUpload.types';

export const Row: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
`;

export const Wrapper: StyledComponent<'div', WrapperProps> = styled.div<WrapperProps>`
  position: relative;
  width: 225px;
  height: 110px;
  border: 1px solid ${Color.GreyStroke};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ imgUrl }: WrapperProps): FlattenSimpleInterpolation | null =>
    !!imgUrl
      ? css`
          background-image: url(${imgUrl});
          border: 1px solid ${Color.Green};
          background-repeat: no-repeat, repeat;
          background-position: center;
          background-size: contain;
        `
      : null}
`;

export const DeleteButton: StyledComponent<'button'> = styled.button`
  width: 26px;
  height: 26px;
  position: absolute;
  transition: 0.3s;
  border-radius: 50%;
  top: 0;
  right: 0;
  transform: translateX(50%) translateY(-50%);
  background-color: ${Color.Green};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label: StyledComponent<typeof BaseLabel> = styled(BaseLabel)`
  margin-bottom: 10px;
`;

export const UploadButton: StyledComponent<'button'> = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${Color.Green};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  margin-bottom: 1px;
`;

export const FileInput: StyledComponent<'input'> = styled.input`
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export const StyledTooltip: StyledComponent<typeof Tooltip> = styled(Tooltip)`
  margin-left: 20px;
`;
