import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { Breakpoint } from '../../../enums/breakpoint.enum';
import { UseState } from '../../../types/useState.types';
import { Icon } from '../../icon/icon.component';
import { useEffectAfterMount } from '../../../hooks/useEffectAfterMount';
import { Button } from '../../../components/button/button.component';
import { SelectBarButton } from '../selectBarButton/selectBarButton.component';

import {
  ButtonContainer,
  IconLabelContainer,
  InnerWrapper,
  Label,
  LeftSection,
  RightSection,
  StyledBar,
  StyledPrice,
  Type,
  TypePriceContainer,
  TypeText,
} from './selectBarWithType.styled';
import { SelectBarWithTypeProps } from './selectBarWithType.types';

export const SelectBarWithType: React.FC<SelectBarWithTypeProps> = (props: SelectBarWithTypeProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    title,
    icon,
    type,
    price,
    handleButtonClick,
    active,
    chooseText = t('rehau.components.selectbar_with_type.choose_label'),
    removeText = t('rehau.components.selectbar_with_type.remove_label'),
    hideTypeName = false,
  }: SelectBarWithTypeProps = props;
  const [isActive, setIsActive]: UseState<boolean> = useState<boolean>(active);
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });

  const toggleIsActive: () => void = (): void => {
    if (!!handleButtonClick) {
      handleButtonClick();
    }
  };

  useEffectAfterMount(
    (): void => setIsActive(active),
    [active]
  );

  return (
    <StyledBar active={isActive} data-testid='select-bar-type'>
      <InnerWrapper>
        <LeftSection>
          <IconLabelContainer>
            <Icon name={icon} size={60} />
            <Label>{t(title)}</Label>
          </IconLabelContainer>
          {!isTablet && !hideTypeName && (
            <TypeText>
              <Type>{t(type)}</Type>
            </TypeText>
          )}
        </LeftSection>
        <RightSection>
          <TypePriceContainer>
            {isTablet && !hideTypeName && (
              <TypeText>
                <Type>{t(type)}</Type>
              </TypeText>
            )}
            {!!price && <StyledPrice price={price} />}
          </TypePriceContainer>

          {!isMobile && (
            <ButtonContainer>
              <SelectBarButton
                onClick={toggleIsActive}
                chooseText={chooseText ?? ''}
                removeText={removeText ?? ''}
                isSelected={isActive}
              />
            </ButtonContainer>
          )}
        </RightSection>
      </InnerWrapper>
      {isMobile && (
        <ButtonContainer>
          <Button
            onClick={toggleIsActive}
            text={isActive ? removeText : chooseText}
            active={isActive}
            fluid
          />
        </ButtonContainer>
      )}
    </StyledBar>
  );
};
