import styled, { css, FlattenSimpleInterpolation, StyledComponent } from 'styled-components';

import { FontWeight } from '../../../enums/fontWeight.enum';
import { Color } from '../../../enums/color.enum';
import { Icon as IconBase } from '../../icon/icon.component';
import { CustomLink } from '../../customLink/customLink.component';

export const Container: StyledComponent<'div', {}, {}, never> = styled.div`
  width: 100%;
`;

export const Title: StyledComponent<'p', {}, {}, never> = styled.p`
  font-size: 16px;
  letter-spacing: 0.15px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.Black};
  margin-right: 10px;
  transition: 0.3s;
`;

export const Icon: StyledComponent<typeof IconBase, {}, {}, never> = styled(IconBase)`
  margin-left: auto;
`;

export const TitleLink: StyledComponent<typeof CustomLink, {}, {}, never> = styled(CustomLink)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  margin-bottom: 23px;
  :hover {
    ${Title} {
      color: ${Color.Pink};
    }
    ${Icon} {
      color: ${Color.Pink};
    }
  }
`;

export const ItemLabel: StyledComponent<'p', {}, {}, never> = styled.p`
  font-size: 16px;
  letter-spacing: 0.15px;
  color: ${Color.Black};
  margin-right: 10px;
  transition: 0.3s;
`;

export const Dot: StyledComponent<'div', {}, {}, never> = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${Color.Pink};
  margin-right: 16px;
`;

export const ItemLink: StyledComponent<typeof CustomLink, {}, {}, never> = styled(CustomLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 23px;
  :first-of-type {
    margin-top: 23px;
  }
  :hover {
    ${ItemLabel} {
      color: ${Color.Pink};
    }
    ${Icon} {
      color: ${Color.Pink};
    }
  }
`;

export const StyledIcon: StyledComponent<typeof Icon, {}, {}, never> = styled(Icon)`
  margin-left: auto;
`;

export const Button: StyledComponent<'button', {}, {}, never> = styled.button`
  font-size: 16px;
  letter-spacing: 0.15px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.Black};
  margin-right: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  width: 100%;
  transition: 0.3s;

  :hover {
    color: ${Color.Pink};

    ${StyledIcon} {
      color: ${Color.Pink};
    }
  }
`;
