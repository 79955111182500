import { Tooltip } from '@rehau/shared/forms/elements';

export class SelectOption {
  public label: string;
  public value: string;
  public selected: boolean;
  public tooltip?: Tooltip;

  constructor(label: string, value: string, selected: boolean = false, tooltip?: Tooltip) {
    this.label = label;
    this.value = value;
    this.selected = selected;
    this.tooltip = tooltip;
  }
}
