import styled from 'styled-components';

import { Price } from '../../../theme/price/price.component';
import { Breakpoint } from '../../../enums/breakpoint.enum';
import { Color } from '../../../enums/color.enum';
import { FontWeight } from '../../../enums/fontWeight.enum';
import { StyledComponent } from '../../../types/styledComponent.types';
import { Bar, BarLabel } from '../selectBar.styled';

export const StyledBar: StyledComponent<typeof Bar> = styled(Bar)`
  min-height: 100px;
  padding: 10px 20px 10px 28px;

  & + & {
    margin-top: 20px;
  }

  @media ${Breakpoint.Mobile} {
    display: block;
  }
`;

export const InnerWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media ${Breakpoint.Mobile} {
    border-bottom: 1px solid ${Color.GreyStroke};
    padding-bottom: 20px;
  }

  @media ${Breakpoint.Additional480} {
    flex-direction: column;
  }
`;

export const IconLabelContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;

  @media ${Breakpoint.Additional480} {
    padding-bottom: 10px;
  }
`;

export const Label: StyledComponent<typeof BarLabel> = styled(BarLabel)`
  margin-left: 30px;
  max-width: 300px;
  margin-right: 15px;

  @media ${Breakpoint.Mobile} {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const RightSection: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1.9;
  flex-shrink: 0;

  @media ${Breakpoint.Additional480} {
    width: 100%;
  }
`;

export const LeftSection: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 3;

  @media ${Breakpoint.Additional1366} {
    flex: 2.8;
  }
`;

export const StyledPrice: StyledComponent<typeof Price> = styled(Price)`
  margin-left: 20px;
`;

export const TypePriceContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
    align-items: flex-end;
  }

  @media ${Breakpoint.Additional480} {
    flex-direction: row;
    width: 100%;
    padding-top: 20px;
    border-top: 1px solid ${Color.GreyStroke};
  }
`;

export const TypeText: StyledComponent<'p'> = styled.p`
  font-size: 16px;
  line-height: 21px;
  color: ${Color.Grey};
  word-break: break-word;
  max-width: 200px;

  @media ${Breakpoint.Tablet} {
    margin-right: 0;
    text-align: end;
  }
  @media ${Breakpoint.Additional480} {
    width: 100%;
    max-width: unset;
    text-align: start;
  }
`;

export const Type: StyledComponent<'span'> = styled.span`
  color: ${Color.Green};
  font-weight: ${FontWeight.Medium};
`;

export const ButtonContainer: StyledComponent<'div'> = styled.div`
  margin-left: 60px;
  display: flex;
  justify-content: flex-end;
  min-width: 130px;
  max-width: 180px;

  @media ${Breakpoint.Mobile} {
    width: 100%;
    max-width: 200px;
    padding: 20px 0;
    margin: 0 0 0 auto;
  }

  @media ${Breakpoint.Additional480} {
    max-width: unset;
  }
`;
