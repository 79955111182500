import { AbstractField } from '@rehau/shared/forms/elements/fields/abstract.field';
import { NumberValidation } from '@rehau/shared/forms/validations';
import { FormFieldTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { InfoBox, Tooltip } from '@rehau/shared/forms/elements';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { Config } from '@rehau/shared/models/countryConfig';

export class NumberField extends AbstractField {
  public placeholder: string;
  public value: number | null;
  public maxValue?: number;
  public minValue?: number;

  constructor(
      id: string,
      name: string,
      label: string | null,
      placeholder: string,
      required: boolean,
      validation: NumberValidation = new NumberValidation(),
      countryConfigMapping: ObjectIteratorInterface<string> = {},
      informationBox?: InfoBox,
      tooltip?: Tooltip,
      value: number | null = null
  ) {
    super(
      FormFieldTypeEnum.NumberField,
      FrontendComponentEnum.NumberField,
      id,
      name,
      label,
      value,
      validation,
      required,
      false,
      informationBox,
      tooltip
    );
    this.value = value;
    this.placeholder = placeholder;
    this.configMapping = countryConfigMapping;
  }

  public updateCountryConfig(countryConfig: Config): void {
    super.updateCountryConfig(countryConfig);
    if (!this.validation?.rules) {
      this.validation = new NumberValidation();
    }
    if (this.minValue !== undefined) {
      this.validation.rules = { ...this.validation.rules, ...{ minValue: this.minValue } };
    }
    if (this.maxValue !== undefined) {
      this.validation.rules = { ...this.validation.rules, ...{ maxValue: this.maxValue } };
    }
  }
}
