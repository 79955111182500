export enum AtHomeContainerEnum {
  AutomationHeatingControlBasicPopup = 'automationHeatingControlBasicPopup',
  AutomationHeatingControlSmartPopup = 'automationHeatingControlSmartPopup',
  AutomationHeatingAndCoolingControlSmartPopup = 'automationHeatingAndCoolingControlSmartPopup',
  AutomationHeatingAndCoolingControlPremiumPopup = 'automationHeatingAndCoolingControlPremiumPopup',
  VentilationFanExhaustPopup = 'ventilationFanExhaustPopup',
  VentilationMechanicalPopup = 'ventilationMechanicalPopup',
  WaterDistributionVerticalPopup = 'waterDistributionVerticalPopup',
  WaterDistributionHorizontalPopup = 'waterDistributionHorizontalPopup',
  WaterDistributionTeeInstalationPopup = 'waterDistributionTeeInstalationPopup',
  WaterDistributionLinearInstalationPopup = 'waterDistributionLinearInstalationPopup',
  WaterDistributionRingInstalationPopup = 'waterDistributionRingInstalationPopup',
  WaterReducerWithFilterPopup = 'waterReducerWithFilterPopup',
  CirculationInstallationPopup = 'circulationInstallationPopup',
  ReguardPopup = 'reguardPopup',
  ResourcePopup = 'resourcePopup',
  SewageClassicPopup = 'sewageClassicPopup',
  SewagePremiumPopup = 'sewagePremiumPopup',
  FreshWaterPopup = 'freshWaterPopup',
  WaterSoftenerPopup = 'waterSoftenerPopup',
  WaterTankPopup = 'waterTankPopup',
  HeatingGasPopup = 'heatingGasPopup',
  HeatingOilPopup = 'heatingOilPopup',
  HeatingWoodPopup = 'heatingWoodPopup',
  HeatingAndCoolingAirPumpPopup = 'heatingAndCoolingAirPumpPopup',
  HeatingAirPumpPopup = 'heatingAirPumpPopup',
  HeatingAndCoolingGeothermalPumpPopup = 'heatingAndCoolingGeothermalPumpPopup',
  HeatingGeothermalPumpPopup = 'heatingGeothermalPumpPopup',
  HeatingDistrictHeatingPopup = 'heatingDistrictHeatingPopup',
  HeatingPelletsPopup = 'heatingPelletsPopup',
  HeatingPhotovoltaicPopup = 'heatingPhotovoltaicPopup',
  HeatingSolarPanelsPopup = 'heatingSolarPanelsPopup',
  HeatingHotWaterTankPopup = 'heatingHotWaterTankPopup',
  HeatingElectricPopup = 'heatingElectricPopup',
  FloorHeatingPopup = 'floorHeatingPopup',
  FloorHeatingSparsePopup = 'floorHeatingSparsePopup',
  FloorHeatingAndCoolingPopup = 'floorHeatingAndCoolingPopup',
  FloorHeatingAndCoolingSparsePopup = 'floorHeatingAndCoolingSparsePopup',
  WallHeatingAndCoolingPopup = 'wallHeatingAndCoolingPopup',
  CeilingHeatingAndCoolingPopup = 'ceilingHeatingAndCoolingPopup',
  FloorWallAndCeilingHeatingPopup = 'floorWallAndCeilingHeatingPopup',
  FrameWcPopup = 'frameWcPopup',
  FrameUrinalPopup = 'frameUrinalPopup',
  FrameBasinPopup = 'frameBasinPopup',
  FrameBidetPopup = 'frameBidetPopup',
  WcPopup = 'wcPopup',
  WashingWcPopup = 'washingWcPopup',
  UrinalPopup = 'urinalPopup',
  BathPopup = 'bathPopup',
  RainShowerPopup = 'rainShowerPopup',
  ShowerPopup = 'showerPopup',
  KitchenSinkPopup = 'kitchenSinkPopup',
  SmallBasinPopup = 'smallBasinPopup',
  BasinPopup = 'basinPopup',
  BidetPopup = 'bidetPopup',
  SanitaryPopup = 'bidetPopup',
  RoomsPopup = 'roomsPopup',
  InstallerPopup = 'installerPopup',
  ExternalInstallerPopup = 'externalInstallerPopup',
  LocationContainer = 'locationContainer',
}
