import React from 'react';

import { IconName } from '../../../../../../shared/enums';
import { Icon } from '../../components/icon/icon.component';
import { Color } from '../../enums/color.enum';

import { Container } from './closeButton.styled';
import { CloseButtonProps } from './closeButton.types';

export const CloseButton: React.FC<CloseButtonProps> = (props: CloseButtonProps): JSX.Element => {
  const { color = Color.Black, size = 20, className, onClick }: CloseButtonProps = props;

  return (
    <Container className={className} onClick={onClick} aria-label='close-button'>
      <Icon name={IconName.No} color={color} size={size} />
    </Container>
  );
};
