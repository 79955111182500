import { FieldsContainer } from '@rehau/shared/forms/components';
import { InfoBox, PasswordField } from '@rehau/shared/forms/elements';
import { ErrorMessageEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { ValidationMessages } from '@rehau/shared/forms/validations';
import { fieldValueType } from '@rehau/shared/forms/types';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';

export class PasswordContainer extends FieldsContainer {
  constructor(
    passwordName: string,
    repeatPasswordName: string,
    subtitle?: string,
    infoBox?: InfoBox
  ) {
    const elements: PasswordField[] = [
      new PasswordField(
        passwordName,
        'rehau.global.password',
        '',
        true
      ),
      new PasswordField(
        repeatPasswordName,
        'rehau.global.repeat_password',
        '',
        true
      ),
    ];
    super(
      FrontendComponentEnum.PasswordContainer,
      '',
      elements,
      undefined,
      subtitle,
      infoBox
    );
  }

  public validate(): boolean {
    return this.checkPasswordsIdentical()
      ? super.validate()
      : false;
  }

  private checkPasswordsIdentical(): boolean {
    const passwordField: PasswordField = this.getPasswordField();
    const passwordRepeatedField: PasswordField = this.getRepeatPasswordField();
    if (!!(passwordField.value && passwordRepeatedField.value) && passwordField.value !== passwordRepeatedField.value) {
      passwordRepeatedField.validationMessages.push(
        new ValidationMessages(ErrorMessageEnum.password_not_identical, { name: passwordRepeatedField.label })
      );

      return false;
    }

    return true;
  }

  public getValues(): ObjectIteratorInterface<fieldValueType> {
    const values: ObjectIteratorInterface<fieldValueType> = {};
    const passwordField: PasswordField = this.getPasswordField();
    if (passwordField) {
      values[passwordField.name] = passwordField.value;
    }

    return values;
  }

  public setValues(values: ObjectIteratorInterface<fieldValueType>): void {
    const valuesAmount: number = values ? Object.keys(values).length : 0;
    const passwordField: PasswordField = this.getPasswordField();
    const repeatPasswordField: PasswordField = this.getRepeatPasswordField();
    // gdy aktualizujemy pola pojedynczo lub celowo przesylamy repeatPasswordField
    // pozwalamy na swobodne ustawianie wartości pól
    if ((valuesAmount === 1) || (values[repeatPasswordField.name] !== undefined)) {
      super.setValues(values);
    } else {
      // obu hasłom ustawiamy taką samą wartość - w komunikacji FE-BE przesyłamy tylko 1 wartość
      if (passwordField && repeatPasswordField && values[passwordField.name]) {
        passwordField.setValue(values[passwordField.name]);
        repeatPasswordField.setValue(values[passwordField.name]);
      }
    }
  }

  public getPasswordField(): PasswordField {
    return this.elements[0] as PasswordField;
  }

  public getRepeatPasswordField(): PasswordField {
    return this.elements[1] as PasswordField;
  }
}
