import React from 'react';

import { IconName } from '../../../../../../shared/enums/iconName.enum';
import { Color } from '../../enums/color.enum';
import { Icon } from '../icon/icon.component';

import { CheckContainer, IncludedText, Wrapper } from './includedCheck.styled';
import { IncludedCheckProps } from './includedCheck.types';

export const IncludedCheck: React.FC<IncludedCheckProps> = (props: IncludedCheckProps): JSX.Element => {
  const { includedLabel, isFinal, iconColor = Color.Green, className, withBiggerFonts = false }: IncludedCheckProps = props;

  return (
    <Wrapper className={className}>
      <IncludedText withBiggerFonts={withBiggerFonts}>{includedLabel}</IncludedText>
      <CheckContainer isFinal={isFinal} data-testid='check'>
        <Icon name={IconName.Check} color={iconColor} size={12} />
      </CheckContainer>
    </Wrapper>
  );
};
