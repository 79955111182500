import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { isSharedElementAvailable } from '../../../utils/isSharedElementAvailable.utils';
import { PopupContainer } from '../../../../../../../shared/forms/components';
import { componentTypes } from '../../../../../../../shared/forms/types';
import { PopupVariantsContainer } from '../../popupVariantsContainer/popupVariantsContainer.component';
import { FieldComponent, FieldComponentWrapper } from '../fieldComponent.component';
import { FieldElement, FieldElementProps } from '../types';
import { getFieldContainerType } from '../../../components/fieldComponent/utils/getFieldContainerType';

export const PopupContainerFieldElement: FieldElement<PopupContainer> = (props: FieldElementProps<PopupContainer>): JSX.Element => {
  const { component, save, saveMethod, setValues }: FieldElementProps<PopupContainer> = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <PopupVariantsContainer
      icon={component.icon}
      boxLabel={t(component.title)}
      variantsLabel={t(component.variantsLabel || '')}
      actionLabel={t(component.actionLabel)}
      warnLabel={t(component.warnLabel || '')}
      popupHeader={t(component.customPopupHeader || '')}
      popupSubheader={t(component.subTitle || '')}
      additionalInfoContent={t(component.informationBox?.content || '')}
      additionalInfoContentInReadMore={t(component.informationBox?.readMoreContent || '')}
      component={component}
      handleSave={save}
      setValues={setValues}
    >
      {component.elements
        .filter((innerComponent: componentTypes): boolean => isSharedElementAvailable(innerComponent))
        .map((innerComponent: componentTypes, index: number): JSX.Element => (
        <FieldComponentWrapper className={getFieldContainerType(innerComponent)} key={`${innerComponent.title}_${index}`}>
          <FieldComponent innerComponent={innerComponent} saveMethod={saveMethod} setValues={setValues} />
        </FieldComponentWrapper>
      ))}
    </PopupVariantsContainer>
  );
};
