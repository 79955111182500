import React from 'react';

import { Color } from '../../../../enums/color.enum';
import { Icon } from '../../../../components/icon/icon.component';
import { IconName } from '@rehau/shared/enums';

import { CircleItemContainer, Container, IconContainer, ItemAmount } from './circleItemsGrid.styled';
import { CircleItem, CircleItemsGridProps } from './circleItemsGrid.types';

export const CircleItemsGrid: React.FC<CircleItemsGridProps> = (props: CircleItemsGridProps): JSX.Element => {
  const { circleItems, className }: CircleItemsGridProps = props;

  return (
    <Container className={className}>
      {circleItems
        .filter((circleItem: CircleItem): boolean => circleItem.amount > 0 && !!circleItem.icon)
        .map((circleItem: CircleItem, index: number): JSX.Element => (
        <CircleItemContainer key={index}>
          <IconContainer $isActive={circleItem.active}>
            <Icon name={circleItem.icon || IconName.DefaultIcon} size={24} color={circleItem.active ? Color.CompanyColor : Color.Black} />
          </IconContainer>
          <ItemAmount>{circleItem.amount}</ItemAmount>
        </CircleItemContainer>
      ))}
    </Container>
  );
};
