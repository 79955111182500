import styled from 'styled-components';

import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { StyledComponent } from '../../types/styledComponent.types';

export const Value: StyledComponent<'div'> = styled.div`
  strong {
    color: ${Color.Black};
    font-weight: ${FontWeight.Bold};
  }

  a {
    color: ${Color.Grey};
    text-decoration: none;
  }

  span {
    color: ${Color.Black};
  }

  .marginBottom {
    display: block;
    margin-bottom: 8px;
  }
`;
