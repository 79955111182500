import { useContext } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { ForcedLanguageTranslationContext } from '../contexts/forcedLanguageTranslation.context';
import { ForcedLanguageTranslationContextType } from '../types/forcedLanguageTranslationContextType.types';

export const useForcedLanguageTranslationContext: () => ForcedLanguageTranslationContextType = (): ForcedLanguageTranslationContextType => {
  const { t }: TransProps<never> = useTranslation();
  const context: ForcedLanguageTranslationContextType = useContext(ForcedLanguageTranslationContext);
  if (!context) {
    throw new Error('Cannot use forced translation context context outside provider');
  } else if (!context.t) {
    return { t };
  }

  return context;
};
