import styled from 'styled-components';

import { StyledComponent } from '../../types/styledComponent.types';
import { Color } from '../../enums/color.enum';
import { hexToRgba } from '../../utils/colors.utils';

export const SuccessMessage: StyledComponent<'div'> = styled.div`
  background-color: ${hexToRgba(Color.Green, 0.05)};
  box-sizing: border-box;
  margin: 10px 0 0;
`;

export const SuccessText: StyledComponent<'div'> = styled.div`
  margin-left: 8px;
  font-size: 14px;
  line-height: 17px;
  color: ${Color.Grey};
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 14px;
  height: 46px;
`;
