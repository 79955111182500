import { WebsitePathNameEnum } from '../../../../../shared/enums';
import { RouteInfo } from '../../../../../shared/models/countryConfig';
import { KeyValueString } from '../types/keyValueString.types';

import { getPathWithParams } from './getPathWithParams.utils';

export const findPathByName: (
  name: WebsitePathNameEnum | string, routes: RouteInfo[], params?: KeyValueString, domain?: string
) => string = (
  name: WebsitePathNameEnum | string, routes: RouteInfo[], params: KeyValueString = {}, domain?: string
): string => {
  const path: string = routes.find((route: RouteInfo): boolean => route.name === name)?.url || '/';
  const pathWithDomain: string = path.includes('http') || !domain
    ? path
    : `${domain}${path}`;
  if (!Object.keys(params).length) {
    return pathWithDomain;
  }

  return getPathWithParams(pathWithDomain, params);
};
