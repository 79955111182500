import { WebsiteComponentEnum } from '../../../../../../shared/enums';
import { AppConfigType } from '../../types/appConfigType.types';

export const appConfig: AppConfigType = {
  forceEnglishLanguage: false,
  version: 'local-test',
  mainLanguage: 'pl_PL',
  api: 'mock',
  webservice: 'mock',
  externalAssets: 'https://s3.docker.mug.pl/rehau',
  appType: WebsiteComponentEnum.Website,
  googleMapsApiKey: 'key',
};
