import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { hexToRgba } from '../../utils/colors.utils';
import { styleWhenTrue } from '../../utils/style.utils';

import { CheckContainerProps, IncludedTextProps } from './includedCheck.types';

export const Wrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IncludedText: StyledComponent<'p', IncludedTextProps> = styled.p<IncludedTextProps>`
  font-size: 14px;
  line-height: 17px;
  color: ${Color.Grey};
  margin-right: 10px;
  word-break: break-word;

  ${styleWhenTrue(
    prop('withBiggerFonts'),
    css`
      font-size: 18px;
    `
  )}

  @media ${Breakpoint.Additional480} {
    max-width: 130px;
  }

  @media ${Breakpoint.Additional375} {
    max-width: 100px;
  }
`;

export const CheckContainer: StyledComponent<'div', CheckContainerProps> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: ${Color.White};

  ${styleWhenTrue(
    prop('isFinal'),
    css`
      background-color: ${hexToRgba(Color.Green, 0.08)};
    `
  )}
`;
