import styled from 'styled-components';

import { StyledComponent } from '../../types/styledComponent.types';
import { Color } from '../../enums/color.enum';

import { CenterContainerProps, CircleSVGProps, LabelSymbolProps, WrapperProps } from './donutCircleChart.types';

export const Wrapper: StyledComponent<'div', WrapperProps> = styled.div<WrapperProps>`
  position: relative;
  width: ${(props: WrapperProps): string => `${props.size}px`};
  height: ${(props: WrapperProps): string => `${props.size}px`};
  flex-shrink: 0;
`;

export const ChartSVG: StyledComponent<'svg'> = styled.svg``;

export const CircleSVG: StyledComponent<'circle', CircleSVGProps> = styled.circle<CircleSVGProps>`
  @keyframes circle-chart-fill {
    to { stroke-dasharray: 0 100; }
  }

  ${(props: CircleSVGProps): string => props.isFrontCircle ? `
    animation: circle-chart-fill 2s reverse;
    transform: rotate(-90deg);
    transform-origin: center;
  ` : ''}
`;

export const CenterContainer: StyledComponent<'div', CenterContainerProps> = styled.div<CenterContainerProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.Black};
  font-size: ${(props: CenterContainerProps): string => `${props.size}px`};
`;

export const LabelContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const LabelValue: StyledComponent<'div'> = styled.div``;

export const LabelSymbol: StyledComponent<'div', LabelSymbolProps> = styled.div<LabelSymbolProps>`
  font-size: ${(props: CenterContainerProps): string => `${props.size}px`};
  position: absolute;
  top: 0;
  right: -10px;
  color: ${Color.Grey};
  line-height: 12px;
`;
