import { ErrorMessageEnum } from '@rehau/shared/enums';

export class ValidationStatus {
  public status: boolean;
  public errors: ErrorMessageEnum[];

  public constructor(status: boolean = true, errors: ErrorMessageEnum[] = []) {
    this.status = status;
    this.errors = errors;
  }

  public failed(message: ErrorMessageEnum): void {
    this.status = false;
    this.errors.push(message);
  }
}
