import React, { RefObject } from 'react';
import { useMediaQuery } from 'react-responsive';
import { TransProps, useTranslation } from 'react-i18next';

import { ProductsConfigKeyEnum, WebsitePathNameEnum } from '@rehau/shared/enums';
import { RouteInfo } from '@rehau/shared/models/countryConfig';
import { IconName } from '../../../../../../shared/enums/iconName.enum';
import { findPathByName } from '../../utils/findPathByName.utils';
import { MobileBackground, MobileInfoBox } from '../../../../rehau-app/src/views/home/home.styled';
import { useRoutesInfo } from '../../hooks/useRoutesInfo.hook';
import { Button } from '../button/button.component';
import { CustomLink } from '../customLink/customLink.component';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { Icon } from '../icon/icon.component';
import { useReveal } from '../../hooks/animations/useReveal';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { fieldValueType } from '@rehau/shared/forms/types';
import { UseCountryConfig } from '../../types/useCountryConfig.types';
import { useCountryConfig } from '../../hooks/useCountryConfig.hook';

import homeVideo from './asset/home.mp4';
import {
  ContentWrapper,
  Description,
  Dot,
  GridWrapper,
  IconContainer,
  IconsContainer,
  InnerWrapper,
  Label,
  Row,
  StyledButton,
  StyledTopButton,
  Title,
  TitleContainer,
  Tooltip,
  Video,
  Wrapper,
} from './configuratorPreview.styled';
import { ConfiguratorPreviewProps } from './configuratorPreview.types';

export const ConfiguratorPreview: React.FC<ConfiguratorPreviewProps> = (props: ConfiguratorPreviewProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    buttonOnClick,
    narrowPadding = true,
    title = t('rehau.website.home_view.configurator_preview.title'),
    description = t('rehau.website.home_view.configurator_preview.description'),
    buttonLabel = t('rehau.website.home_view.configurator_preview.button_label'),
    heatingTooltip = t('rehau.website.home_view.configurator_preview.heating_tooltip'),
    waterTooltip = t('rehau.website.home_view.configurator_preview.water_distribution_tooltip'),
    sewageTooltip = t('rehau.website.home_view.configurator_preview.sewage_tooltip'),
    regulationTooltip = t('rehau.website.home_view.configurator_preview.regulation_tooltip'),
    reguardTooltip = t('rehau.website.home_view.configurator_preview.reguard_tooltip'),
    resourceTooltip = t('rehau.website.home_view.configurator_preview.resource_tooltip'),
    heatingLabel = t('rehau.website.home_view.configurator_preview.heating_label'),
    waterLabel = t('rehau.website.home_view.configurator_preview.water_label'),
    smartHomeLabel = t('rehau.website.home_view.configurator_preview.smart_home_label'),
    sewageLabel = t('rehau.website.home_view.configurator_preview.sewage_label'),
  }: ConfiguratorPreviewProps = props;
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Additional480 });
  const titleRef: RefObject<HTMLDivElement> = useReveal();
  const descriptionRef: RefObject<HTMLDivElement> = useReveal();
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();
  const products: ObjectIteratorInterface<fieldValueType> = isConfigAvailable ? config.variables.products : {};
  const routesArray: RouteInfo[] = useRoutesInfo();

  return (
    <>
      <Wrapper narrowPadding={narrowPadding}>
        <GridWrapper>
          <InnerWrapper>
            <ContentWrapper>
              <TitleContainer>
                <Title ref={titleRef}>{title}</Title>
                <Description ref={descriptionRef}>{description}</Description>
              </TitleContainer>
              <StyledTopButton
                text={buttonLabel || ''}
                onClick={buttonOnClick}
              />
            </ContentWrapper>
            {isTablet && !isMobile && (
              <Video autoPlay loop playsInline muted>
                <source src={homeVideo} type='video/mp4' />
              </Video>
            )}
          </InnerWrapper>
          {!isTablet && (
            <Video autoPlay loop playsInline muted>
              <source src={homeVideo} type='video/mp4' />
            </Video>
          )}
        </GridWrapper>
        {isMobile && (
          <Video autoPlay loop playsInline muted>
            <source src={homeVideo} type='video/mp4' />
          </Video>
        )}
      </Wrapper>
      <MobileBackground>
        <MobileInfoBox>
          <Title>{title}</Title>
          <Row>
            <Dot />
            <Label>{heatingLabel}</Label>
          </Row>
          <Row>
            <Dot />
            <Label>{waterLabel}</Label>
          </Row>
          <Row>
            <Dot />
            <Label>{smartHomeLabel}</Label>
          </Row>
          <Row>
            <Dot />
            <Label>{sewageLabel}</Label>
          </Row>
          <StyledButton text={buttonLabel || ''} onClick={buttonOnClick} fluid />
        </MobileInfoBox>
      </MobileBackground>
    </>
  );
};
