import styled from 'styled-components';

import { StyledComponent } from '../../types/styledComponent.types';

export const Container: StyledComponent<'button'> = styled.button`
  position: absolute;
  top: 13px;
  right: 20px;
  padding: 0;
  margin: 0;
`;
