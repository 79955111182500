import React from 'react';

import { IconName } from '../../../../../../shared/enums';
import { Color } from '../../enums/color.enum';

import { CustomIcon, NumericValue, NumericValuesContainer, StarsContainer } from './rating.styled';
import { RatingProps } from './rating.types';

export const Rating: React.FC<RatingProps> = (props: RatingProps): JSX.Element => {
  const { rate = 0, maxRate = 5, color = Color.Green, isNumeric = false }: RatingProps = props;
  const localRate: number = rate > maxRate
    ? maxRate
    : rate < 0
      ? 0
      : Number(rate);

  return (
    <>
      {!isNumeric ? (
        <StarsContainer data-testid='rating'>
          {[...Array(localRate)].map(
            (_: undefined, index: number): JSX.Element => (
              <CustomIcon key={`active-${index}`} name={IconName.Star} size={14} color={color} />
            )
          )}
          {[...Array(maxRate - localRate)].map(
            (_: undefined, index: number): JSX.Element => (
              <CustomIcon key={`inactive-${index}`} name={IconName.Star} size={14} color={Color.GreyStroke} />
            )
          )}
        </StarsContainer>
      ) : (
        <NumericValuesContainer data-testid='rating'>
          <NumericValue color={color} isFirst={true}>{rate}</NumericValue>
          /
          <NumericValue>{maxRate}</NumericValue>
        </NumericValuesContainer>
      )}
    </>
  );
};
