// tslint:disable:max-file-line-count
export enum SetupFieldEnum {
  RandomValue = 'randomValue',
  GasSActive = 'gas_s_active',
  GasSTradeName = 'gas_s_trade_name',
  GasSCostWithAssembly = 'gas_s_cost_with_assembly',
  GasMActive = 'gas_m_active',
  GasMTradeName = 'gas_m_trade_name',
  GasMCostWithAssembly = 'gas_m_cost_with_assembly',
  GasLActive = 'gas_l_active',
  GasLTradeName = 'gas_l_trade_name',
  GasLCostWithAssembly = 'gas_l_cost_with_assembly',
  GasSMaintenanceActive = 'gas_s_maintenance_active',
  GasSMaintenancePeriod = 'gas_s_maintenance_period',
  GasSMaintenanceCost = 'gas_s_maintenance_cost',
  GasMMaintenanceActive = 'gas_m_maintenance_active',
  GasMMaintenancePeriod = 'gas_m_maintenance_period',
  GasMMaintenanceCost = 'gas_m_maintenance_cost',
  GasLMaintenanceActive = 'gas_l_maintenance_active',
  GasLMaintenancePeriod = 'gas_l_maintenance_period',
  GasLMaintenanceCost = 'gas_l_maintenance_cost',
  GasSHydraulicSwitchCost = 'gas_s_hydraulic_switch_cost',
  GasMHydraulicSwitchCost = 'gas_m_hydraulic_switch_cost',
  GasLHydraulicSwitchCost = 'gas_l_hydraulic_switch_cost',
  GasSChimneyCost = 'gas_s_chimney_cost',
  GasMChimneyCost = 'gas_m_chimney_cost',
  GasLChimneyCost = 'gas_l_chimney_cost',
  GasSHotWaterIntegrationActive = 'gas_s_hot_water_integration_active',
  GasMHotWaterIntegrationActive = 'gas_m_hot_water_integration_active',
  GasLHotWaterIntegrationActive = 'gas_l_hot_water_integration_active',
  OilSActive = 'oil_s_active',
  OilSTradeName = 'oil_s_trade_name',
  OilSCostWithAssembly = 'oil_s_cost_with_assembly',
  OilMActive = 'oil_m_active',
  OilMTradeName = 'oil_m_trade_name',
  OilMCostWithAssembly = 'oil_m_cost_with_assembly',
  OilLActive = 'oil_l_active',
  OilLTradeName = 'oil_l_trade_name',
  OilLCostWithAssembly = 'oil_l_cost_with_assembly',
  OilSMaintenanceActive = 'oil_s_maintenance_active',
  OilSMaintenancePeriod = 'oil_s_maintenance_period',
  OilSMaintenanceCost = 'oil_s_maintenance_cost',
  OilMMaintenanceActive = 'oil_m_maintenance_active',
  OilMMaintenancePeriod = 'oil_m_maintenance_period',
  OilMMaintenanceCost = 'oil_m_maintenance_cost',
  OilLMaintenanceActive = 'oil_l_maintenance_active',
  OilLMaintenancePeriod = 'oil_l_maintenance_period',
  OilLMaintenanceCost = 'oil_l_maintenance_cost',
  OilSChimneyCost = 'oil_s_chimney_cost',
  OilMChimneyCost = 'oil_m_chimney_cost',
  OilLChimneyCost = 'oil_l_chimney_cost',
  OilSHotWaterIntegrationActive = 'oil_s_hot_water_integration_active',
  OilMHotWaterIntegrationActive = 'oil_m_hot_water_integration_active',
  OilLHotWaterIntegrationActive = 'oil_l_hot_water_integration_active',
  OilSHydraulicSwitchCost = 'oil_s_hydraulic_switch_cost',
  OilMHydraulicSwitchCost = 'oil_m_hydraulic_switch_cost',
  OilLHydraulicSwitchCost = 'oil_l_hydraulic_switch_cost',
  WoodSActive = 'wood_s_active',
  WoodSTradeName = 'wood_s_trade_name',
  WoodSCostWithAssembly = 'wood_s_cost_with_assembly',
  WoodMActive = 'wood_m_active',
  WoodMTradeName = 'wood_m_trade_name',
  WoodMCostWithAssembly = 'wood_m_cost_with_assembly',
  WoodLActive = 'wood_l_active',
  WoodLTradeName = 'wood_l_trade_name',
  WoodLCostWithAssembly = 'wood_l_cost_with_assembly',
  WoodSMaintenanceActive = 'wood_s_maintenance_active',
  WoodSMaintenancePeriod = 'wood_s_maintenance_period',
  WoodSMaintenanceCost = 'wood_s_maintenance_cost',
  WoodMMaintenanceActive = 'wood_m_maintenance_active',
  WoodMMaintenancePeriod = 'wood_m_maintenance_period',
  WoodMMaintenanceCost = 'wood_m_maintenance_cost',
  WoodLMaintenanceActive = 'wood_l_maintenance_active',
  WoodLMaintenancePeriod = 'wood_l_maintenance_period',
  WoodLMaintenanceCost = 'wood_l_maintenance_cost',
  WoodSTankIncludedActive = 'wood_s_tank_included_active',
  WoodMTankIncludedActive = 'wood_m_tank_included_active',
  WoodLTankIncludedActive = 'wood_l_tank_included_active',
  HeatingAndCoolingAirPumpSActive = 'heating_and_cooling_air_pump_s_active',
  HeatingAndCoolingAirPumpSTradeName = 'heating_and_cooling_air_pump_s_trade_name',
  HeatingAndCoolingAirPumpSCostWithAssembly = 'heating_and_cooling_air_pump_s_cost_with_assembly',
  HeatingAndCoolingAirPumpMActive = 'heating_and_cooling_air_pump_m_active',
  HeatingAndCoolingAirPumpMTradeName = 'heating_and_cooling_air_pump_m_trade_name',
  HeatingAndCoolingAirPumpMCostWithAssembly = 'heating_and_cooling_air_pump_m_cost_with_assembly',
  HeatingAndCoolingAirPumpLActive = 'heating_and_cooling_air_pump_l_active',
  HeatingAndCoolingAirPumpLTradeName = 'heating_and_cooling_air_pump_l_trade_name',
  HeatingAndCoolingAirPumpLCostWithAssembly = 'heating_and_cooling_air_pump_l_cost_with_assembly',
  HeatingAndCoolingAirPumpSMaintenanceActive = 'heating_and_cooling_air_pump_s_maintenance_active',
  HeatingAndCoolingAirPumpSMaintenancePeriod = 'heating_and_cooling_air_pump_s_maintenance_period',
  HeatingAndCoolingAirPumpSMaintenanceCost = 'heating_and_cooling_air_pump_s_maintenance_cost',
  HeatingAndCoolingAirPumpMMaintenanceActive = 'heating_and_cooling_air_pump_m_maintenance_active',
  HeatingAndCoolingAirPumpMMaintenancePeriod = 'heating_and_cooling_air_pump_m_maintenance_period',
  HeatingAndCoolingAirPumpMMaintenanceCost = 'heating_and_cooling_air_pump_m_maintenance_cost',
  HeatingAndCoolingAirPumpLMaintenanceActive = 'heating_and_cooling_air_pump_l_maintenance_active',
  HeatingAndCoolingAirPumpLMaintenancePeriod = 'heating_and_cooling_air_pump_l_maintenance_period',
  HeatingAndCoolingAirPumpLMaintenanceCost = 'heating_and_cooling_air_pump_l_maintenance_cost',
  HeatingAndCoolingAirPumpSTankIncludedActive = 'heating_and_cooling_air_pump_s_tank_included_active',
  HeatingAndCoolingAirPumpMTankIncludedActive = 'heating_and_cooling_air_pump_m_tank_included_active',
  HeatingAndCoolingAirPumpLTankIncludedActive = 'heating_and_cooling_air_pump_l_tank_included_active',
  HeatingAndCoolingAirPumpSHotWaterIntegrationActive = 'heating_and_cooling_air_pump_s_hot_water_integration_active',
  HeatingAndCoolingAirPumpMHotWaterIntegrationActive = 'heating_and_cooling_air_pump_m_hot_water_integration_active',
  HeatingAndCoolingAirPumpLHotWaterIntegrationActive = 'heating_and_cooling_air_pump_l_hot_water_integration_active',
  HeatingAndCoolingGeothermalPumpSActive = 'heating_and_cooling_geothermal_pump_s_active',
  HeatingAndCoolingGeothermalPumpSTradeName = 'heating_and_cooling_geothermal_pump_s_trade_name',
  HeatingAndCoolingGeothermalPumpSCostWithAssembly = 'heating_and_cooling_geothermal_pump_s_cost_with_assembly',
  HeatingAndCoolingGeothermalPumpMActive = 'heating_and_cooling_geothermal_pump_m_active',
  HeatingAndCoolingGeothermalPumpMTradeName = 'heating_and_cooling_geothermal_pump_m_trade_name',
  HeatingAndCoolingGeothermalPumpMCostWithAssembly = 'heating_and_cooling_geothermal_pump_m_cost_with_assembly',
  HeatingAndCoolingGeothermalPumpLActive = 'heating_and_cooling_geothermal_pump_l_active',
  HeatingAndCoolingGeothermalPumpLTradeName = 'heating_and_cooling_geothermal_pump_l_trade_name',
  HeatingAndCoolingGeothermalPumpLCostWithAssembly = 'heating_and_cooling_geothermal_pump_l_cost_with_assembly',
  HeatingAndCoolingGeothermalPumpSMaintenanceActive = 'heating_and_cooling_geothermal_pump_s_maintenance_active',
  HeatingAndCoolingGeothermalPumpSMaintenancePeriod = 'heating_and_cooling_geothermal_pump_s_maintenance_period',
  HeatingAndCoolingGeothermalPumpSMaintenanceCost = 'heating_and_cooling_geothermal_pump_s_maintenance_cost',
  HeatingAndCoolingGeothermalPumpMMaintenanceActive = 'heating_and_cooling_geothermal_pump_m_maintenance_active',
  HeatingAndCoolingGeothermalPumpMMaintenancePeriod = 'heating_and_cooling_geothermal_pump_m_maintenance_period',
  HeatingAndCoolingGeothermalPumpMMaintenanceCost = 'heating_and_cooling_geothermal_pump_m_maintenance_cost',
  HeatingAndCoolingGeothermalPumpLMaintenanceActive = 'heating_and_cooling_geothermal_pump_l_maintenance_active',
  HeatingAndCoolingGeothermalPumpLMaintenancePeriod = 'heating_and_cooling_geothermal_pump_l_maintenance_period',
  HeatingAndCoolingGeothermalPumpLMaintenanceCost = 'heating_and_cooling_geothermal_pump_l_maintenance_cost',
  HeatingAndCoolingGeothermalPumpSTankIncludedActive = 'heating_and_cooling_geothermal_pump_s_tank_included_active',
  HeatingAndCoolingGeothermalPumpMTankIncludedActive = 'heating_and_cooling_geothermal_pump_m_tank_included_active',
  HeatingAndCoolingGeothermalPumpLTankIncludedActive = 'heating_and_cooling_geothermal_pump_l_tank_included_active',
  HeatingAndCoolingGeothermalPumpSHotWaterIntegrationActive = 'heating_and_cooling_geothermal_pump_s_hot_water_integration_active',
  HeatingAndCoolingGeothermalPumpMHotWaterIntegrationActive = 'heating_and_cooling_geothermal_pump_m_hot_water_integration_active',
  HeatingAndCoolingGeothermalPumpLHotWaterIntegrationActive = 'heating_and_cooling_geothermal_pump_l_hot_water_integration_active',
  DistrictHeatingSActive = 'district_heating_s_active',
  DistrictHeatingSTradeName = 'district_heating_s_trade_name',
  DistrictHeatingSCostWithAssembly = 'district_heating_s_cost_with_assembly',
  DistrictHeatingMActive = 'district_heating_m_active',
  DistrictHeatingMTradeName = 'district_heating_m_trade_name',
  DistrictHeatingMCostWithAssembly = 'district_heating_m_cost_with_assembly',
  DistrictHeatingLActive = 'district_heating_l_active',
  DistrictHeatingLTradeName = 'district_heating_l_trade_name',
  DistrictHeatingLCostWithAssembly = 'district_heating_l_cost_with_assembly',
  DistrictHeatingSMaintenanceActive = 'district_heating_s_maintenance_active',
  DistrictHeatingSMaintenancePeriod = 'district_heating_s_maintenance_period',
  DistrictHeatingSMaintenanceCost = 'district_heating_s_maintenance_cost',
  DistrictHeatingMMaintenanceActive = 'district_heating_m_maintenance_active',
  DistrictHeatingMMaintenancePeriod = 'district_heating_m_maintenance_period',
  DistrictHeatingMMaintenanceCost = 'district_heating_m_maintenance_cost',
  DistrictHeatingLMaintenanceActive = 'district_heating_l_maintenance_active',
  DistrictHeatingLMaintenancePeriod = 'district_heating_l_maintenance_period',
  DistrictHeatingLMaintenanceCost = 'district_heating_l_maintenance_cost',
  DistrictHeatingSTankIncludedActive = 'district_heating_s_tank_included_active',
  DistrictHeatingMTankIncludedActive = 'district_heating_m_tank_included_active',
  DistrictHeatingLTankIncludedActive = 'district_heating_l_tank_included_active',
  PelletsSActive = 'pellets_s_active',
  PelletsSTradeName = 'pellets_s_trade_name',
  PelletsSCostWithAssembly = 'pellets_s_cost_with_assembly',
  PelletsMActive = 'pellets_m_active',
  PelletsMTradeName = 'pellets_m_trade_name',
  PelletsMCostWithAssembly = 'pellets_m_cost_with_assembly',
  PelletsLActive = 'pellets_l_active',
  PelletsLTradeName = 'pellets_l_trade_name',
  PelletsLCostWithAssembly = 'pellets_l_cost_with_assembly',
  PelletsSMaintenanceActive = 'pellets_s_maintenance_active',
  PelletsSMaintenancePeriod = 'pellets_s_maintenance_period',
  PelletsSMaintenanceCost = 'pellets_s_maintenance_cost',
  PelletsMMaintenanceActive = 'pellets_m_maintenance_active',
  PelletsMMaintenancePeriod = 'pellets_m_maintenance_period',
  PelletsMMaintenanceCost = 'pellets_m_maintenance_cost',
  PelletsLMaintenanceActive = 'pellets_l_maintenance_active',
  PelletsLMaintenancePeriod = 'pellets_l_maintenance_period',
  PelletsLMaintenanceCost = 'pellets_l_maintenance_cost',
  PelletsSTankIncludedActive = 'pellets_s_tank_included_active',
  PelletsMTankIncludedActive = 'pellets_m_tank_included_active',
  PelletsLTankIncludedActive = 'pellets_l_tank_included_active',
  SolarPanelsSActive = 'solar_panels_s_active',
  SolarPanelsSTradeName = 'solar_panels_s_trade_name',
  SolarPanelsSCostWithAssembly = 'solar_panels_s_cost_with_assembly',
  SolarPanelsMActive = 'solar_panels_m_active',
  SolarPanelsMTradeName = 'solar_panels_m_trade_name',
  SolarPanelsMCostWithAssembly = 'solar_panels_m_cost_with_assembly',
  SolarPanelsLActive = 'solar_panels_l_active',
  SolarPanelsLTradeName = 'solar_panels_l_trade_name',
  SolarPanelsLCostWithAssembly = 'solar_panels_l_cost_with_assembly',
  AirPumpSActive = 'air_pump_s_active',
  AirPumpSTradeName = 'air_pump_s_trade_name',
  AirPumpSCostWithAssembly = 'air_pump_s_cost_with_assembly',
  AirPumpMActive = 'air_pump_m_active',
  AirPumpMTradeName = 'air_pump_m_trade_name',
  AirPumpMCostWithAssembly = 'air_pump_m_cost_with_assembly',
  AirPumpLActive = 'air_pump_l_active',
  AirPumpLTradeName = 'air_pump_l_trade_name',
  AirPumpLCostWithAssembly = 'air_pump_l_cost_with_assembly',
  AirPumpSMaintenanceActive = 'air_pump_s_maintenance_active',
  AirPumpSMaintenancePeriod = 'air_pump_s_maintenance_period',
  AirPumpSMaintenanceCost = 'air_pump_s_maintenance_cost',
  AirPumpMMaintenanceActive = 'air_pump_m_maintenance_active',
  AirPumpMMaintenancePeriod = 'air_pump_m_maintenance_period',
  AirPumpMMaintenanceCost = 'air_pump_m_maintenance_cost',
  AirPumpLMaintenanceActive = 'air_pump_l_maintenance_active',
  AirPumpLMaintenancePeriod = 'air_pump_l_maintenance_period',
  AirPumpLMaintenanceCost = 'air_pump_l_maintenance_cost',
  AirPumpSTankIncludedActive = 'air_pump_s_tank_included_active',
  AirPumpMTankIncludedActive = 'air_pump_m_tank_included_active',
  AirPumpLTankIncludedActive = 'air_pump_l_tank_included_active',
  AirPumpSHotWaterIntegrationActive = 'air_pump_s_hot_water_integration_active',
  AirPumpMHotWaterIntegrationActive = 'air_pump_m_hot_water_integration_active',
  AirPumpLHotWaterIntegrationActive = 'air_pump_l_hot_water_integration_active',
  GeothermalPumpSActive = 'geothermal_pump_s_active',
  GeothermalPumpSTradeName = 'geothermal_pump_s_trade_name',
  GeothermalPumpSCostWithAssembly = 'geothermal_pump_s_cost_with_assembly',
  GeothermalPumpMActive = 'geothermal_pump_m_active',
  GeothermalPumpMTradeName = 'geothermal_pump_m_trade_name',
  GeothermalPumpMCostWithAssembly = 'geothermal_pump_m_cost_with_assembly',
  GeothermalPumpLActive = 'geothermal_pump_l_active',
  GeothermalPumpLTradeName = 'geothermal_pump_l_trade_name',
  GeothermalPumpLCostWithAssembly = 'geothermal_pump_l_cost_with_assembly',
  GeothermalPumpSMaintenanceActive = 'geothermal_pump_s_maintenance_active',
  GeothermalPumpSMaintenancePeriod = 'geothermal_pump_s_maintenance_period',
  GeothermalPumpMMaintenanceActive = 'geothermal_pump_m_maintenance_active',
  GeothermalPumpMMaintenancePeriod = 'geothermal_pump_m_maintenance_period',
  GeothermalPumpLMaintenanceActive = 'geothermal_pump_l_maintenance_active',
  GeothermalPumpLMaintenancePeriod = 'geothermal_pump_l_maintenance_period',
  GeothermalPumpSTankIncludedActive = 'geothermal_pump_s_tank_included_active',
  GeothermalPumpMTankIncludedActive = 'geothermal_pump_m_tank_included_active',
  GeothermalPumpLTankIncludedActive = 'geothermal_pump_l_tank_included_active',
  GeothermalPumpSHotWaterIntegrationActive = 'geothermal_pump_s_hot_water_integration_active',
  GeothermalPumpMHotWaterIntegrationActive = 'geothermal_pump_m_hot_water_integration_active',
  GeothermalPumpLHotWaterIntegrationActive = 'geothermal_pump_l_hot_water_integration_active',
  HotWaterTankSActive = 'hot_water_tank_s_active',
  HotWaterTankSTradeName = 'hot_water_tank_s_trade_name',
  HotWaterTankSCostWithAssembly = 'hot_water_tank_s_cost_with_assembly',
  HotWaterTankMActive = 'hot_water_tank_m_active',
  HotWaterTankMTradeName = 'hot_water_tank_m_trade_name',
  HotWaterTankMCostWithAssembly = 'hot_water_tank_m_cost_with_assembly',
  HotWaterTankLActive = 'hot_water_tank_l_active',
  HotWaterTankLTradeName = 'hot_water_tank_l_trade_name',
  HotWaterTankLCostWithAssembly = 'hot_water_tank_l_cost_with_assembly',
  PhotovoltaicSActive = 'photovoltaic_s_active',
  PhotovoltaicSTradeName = 'photovoltaic_s_trade_name',
  PhotovoltaicSCostWithAssembly = 'photovoltaic_s_cost_with_assembly',
  PhotovoltaicMActive = 'photovoltaic_m_active',
  PhotovoltaicMTradeName = 'photovoltaic_m_trade_name',
  PhotovoltaicMCostWithAssembly = 'photovoltaic_m_cost_with_assembly',
  PhotovoltaicLActive = 'photovoltaic_l_active',
  PhotovoltaicLTradeName = 'photovoltaic_l_trade_name',
  PhotovoltaicLCostWithAssembly = 'photovoltaic_l_cost_with_assembly',
  ElectricSActive = 'electric_s_active',
  ElectricSTradeName = 'electric_s_trade_name',
  ElectricSCostWithAssembly = 'electric_s_cost_with_assembly',
  ElectricSTankIncludedActive = 'electric_s_tank_included_active',
  ElectricSHotWaterIntegrationActive = 'electric_s_hot_water_integration_active',
  ElectricSMaintenanceActive = 'electric_s_maintenance_active',
  ElectricSMaintenancePeriod = 'electric_s_maintenance_period',
  ElectricSMaintenanceCost = 'electric_s_maintenance_cost',
  ElectricMActive = 'electric_m_active',
  ElectricMTradeName = 'electric_m_trade_name',
  ElectricMCostWithAssembly = 'electric_m_cost_with_assembly',
  ElectricMTankIncludedActive = 'electric_m_tank_included_active',
  ElectricMHotWaterIntegrationActive = 'electric_m_hot_water_integration_active',
  ElectricMMaintenanceActive = 'electric_m_maintenance_active',
  ElectricMMaintenancePeriod = 'electric_m_maintenance_period',
  ElectricMMaintenanceCost = 'electric_m_maintenance_cost',
  ElectricLActive = 'electric_l_active',
  ElectricLTradeName = 'electric_l_trade_name',
  ElectricLTankIncludedActive = 'electric_l_tank_included_active',
  ElectricLHotWaterIntegrationActive = 'electric_l_hot_water_integration_active',
  ElectricLCostWithAssembly = 'electric_l_cost_with_assembly',
  ElectricLMaintenanceActive = 'electric_l_maintenance_active',
  ElectricLMaintenancePeriod = 'electric_l_maintenance_period',
  ElectricLMaintenanceCost = 'electric_l_maintenance_cost',
  FloorHeatingSSquareMeterCost = 'floor_heating_s_square_meter_cost',
  FloorHeatingAndCoolingSSquareMeterCost = 'floor_heating_and_cooling_s_square_meter_cost',
  FloorHeatingMSquareMeterCost = 'floor_heating_m_square_meter_cost',
  FloorHeatingLSquareMeterCost = 'floor_heating_l_square_meter_cost',
  FloorHeatingAndCoolingMSquareMeterCost = 'floor_heating_and_cooling_m_square_meter_cost',
  FloorHeatingAndCoolingLSquareMeterCost = 'floor_heating_and_cooling_l_square_meter_cost',
  WallHeatingAndCoolingFixedCost = 'wall_heating_and_cooling_fixed_cost',
  CeilingHeatingAndCoolingFixedCost = 'ceiling_heating_and_cooling_fixed_cost',
  HeatDistributionAdditionalFloorCost = 'heat_distribution_additional_floor_cost',
  BathroomRadiatorActive = 'bathroom_radiator_active',
  BathroomRadiatorCost = 'bathroom_radiator_cost',
  FloorHeatingAndCoolingSparseLSquareMeterCost = 'floor_heating_and_cooling_sparse_l_square_meter_cost',
  FloorHeatingAndCoolingSparseMSquareMeterCost = 'floor_heating_and_cooling_sparse_m_square_meter_cost',
  FloorHeatingAndCoolingSparseSSquareMeterCost = 'floor_heating_and_cooling_sparse_s_square_meter_cost',
  FloorHeatingSparseLSquareMeterCost = 'floor_heating_sparse_l_square_meter_cost',
  FloorHeatingSparseMSquareMeterCost = 'floor_heating_sparse_m_square_meter_cost',
  FloorHeatingSparseSSquareMeterCost = 'floor_heating_sparse_s_square_meter_cost',
  HeatingControlBasicThermostatCost = 'heating_control_basic_thermostat_cost',
  HeatingControlBasicRegularBasisCost = 'heating_control_basic_regular_basis_cost',
  HeatingControlBasicSettingDriveCost = 'heating_control_basic_setting_drive_cost',
  HeatingControlSmartThermostatCost = 'heating_control_smart_thermostat_cost',
  HeatingControlSmartRegularBasisCost = 'heating_control_smart_regular_basis_cost',
  HeatingControlSmartSettingDriveCost = 'heating_control_smart_setting_drive_cost',
  HeatingAndCoolingControlSmartThermostatCost = 'heating_and_cooling_control_smart_thermostat_cost',
  HeatingAndCoolingControlSmartRegularBasisCost = 'heating_and_cooling_control_smart_regular_basis_cost',
  HeatingAndCoolingControlSmartSettingDriveCost = 'heating_and_cooling_control_smart_setting_drive_cost',
  HeatingAndCoolingControlPremiumThermostatCost = 'heating_and_cooling_control_premium_thermostat_cost',
  HeatingAndCoolingControlPremiumRegularBasisCost = 'heating_and_cooling_control_premium_regular_basis_cost',
  HeatingAndCoolingControlPremiumSettingDriveCost = 'heating_and_cooling_control_premium_setting_drive_cost',
  CirculationInstallationSCost = 'circulation_installation_s_cost',
  CirculationInstallationMCost = 'circulation_installation_m_cost',
  CirculationInstallationLCost = 'circulation_installation_l_cost',
  WaterSoftenerSName = 'water_softener_s_name',
  WaterSoftenerMName = 'water_softener_m_name',
  WaterSoftenerLName = 'water_softener_l_name',
  WaterSoftenerSCost = 'water_softener_s_cost',
  WaterReducerWithFilterName = 'water_reducer_with_filter_name',
  WaterReducerWithFilterCost = 'water_reducer_with_filter_cost',
  FreshWaterModuleSName = 'fresh_water_module_s_name',
  FreshWaterModuleMName = 'fresh_water_module_m_name',
  FreshWaterModuleLName = 'fresh_water_module_l_name',
  BathroomFittingsFrameWcProductNote = 'bathroom_fittings_frame_wc_product_note',
  HotUtilityWaterTankInhabitantsStandardName = 'hot_utility_water_tank_inhabitants_standard_name',
  HotUtilityWaterTankSInhabitantsStandardCost = 'hot_utility_water_tank_s_inhabitants_standard_cost',
  HotUtilityWaterTankMInhabitantsStandardCost = 'hot_utility_water_tank_m_inhabitants_standard_cost',
  HotUtilityWaterTankLInhabitantsStandardCost = 'hot_utility_water_tank_l_inhabitants_standard_cost',
  FreshWaterModuleSCost = 'fresh_water_module_s_cost',
  FreshWaterModuleMCost = 'fresh_water_module_m_cost',
  FreshWaterModuleLCost = 'fresh_water_module_l_cost',
  BathroomFittingsFrameUrinalProductNote = 'bathroom_fittings_frame_urinal_product_note',
  BathroomFittingsFrameUrinalProductCost = 'bathroom_fittings_frame_urinal_product_cost',
  HotUtilityWaterTankInhabitantsHeatPumpName = 'hot_utility_water_tank_inhabitants_heat_pump_name',
  HotUtilityWaterTankSInhabitantsHeatPumpCost = 'hot_utility_water_tank_s_inhabitants_heat_pump_cost',
  HotUtilityWaterTankMInhabitantsHeatPumpCost = 'hot_utility_water_tank_m_inhabitants_heat_pump_cost',
  HotUtilityWaterTankLInhabitantsHeatPumpCost = 'hot_utility_water_tank_l_inhabitants_heat_pump_cost',
  CirculationInstallationLActive = 'circulation_installation_l_active',
  CirculationInstallationMActive = 'circulation_installation_m_active',
  CirculationInstallationSActive = 'circulation_installation_s_active',
  WaterSoftenerSActive = 'water_softener_s_active',
  WaterSoftenerMActive = 'water_softener_m_active',
  WaterSoftenerLActive = 'water_softener_l_active',
  FreshWaterModuleSActive = 'fresh_water_module_s_active',
  FreshWaterModuleMActive = 'fresh_water_module_m_active',
  FreshWaterModuleLActive = 'fresh_water_module_l_active',
  WaterSoftenerMCost = 'water_softener_m_cost',
  WaterSoftenerLCost = 'water_softener_l_cost',
  ReSourceActive = 're_source_active',
  ReSourceClassicCost = 're_source_classic_cost',
  ReSourceSmartCost = 're_source_smart_cost',
  ReSourcePremiumCost = 're_source_premium_cost',
  ReSourceName = 're_source_name',
  ReSourceProducerName = 're_source_producer_name',
  ReGuardActive = 're_guard_active',
  ReGuardName = 're_guard_name',
  ReGuardCost1 = 're_guard_cost_1',
  ReGuardCost2 = 're_guard_cost_2',
  ReGuardCost3 = 're_guard_cost_3',
  ReGuardSensorCost = 're_guard_sensor_cost',
  ReGuardProducerName = 're_guard_producer_name',
  BathroomFittingsFrameBasinProductNote = 'bathroom_fittings_frame_basin_product_note',
  BathroomFittingsFrameBasinCost = 'bathroom_fittings_frame_basin_cost',
  BathroomFittingsFrameBidetProductNote = 'bathroom_fittings_frame_bidet_product_note',
  TeeInstallationColdWaterSCost = 'tee_installation_cold_water_s_cost',
  TeeInstallationColdWaterMCost = 'tee_installation_cold_water_m_cost',
  TeeInstallationColdWaterLCost = 'tee_installation_cold_water_l_cost',
  TeeInstallationColdAndWarmWaterSCost = 'tee_installation_cold_and_warm_water_s_cost',
  TeeInstallationColdAndWarmWaterMCost = 'tee_installation_cold_and_warm_water_m_cost',
  TeeInstallationColdAndWarmWaterLCost = 'tee_installation_cold_and_warm_water_l_cost',
  TeeInstallationGardenValveSCost = 'tee_installation_garden_valve_s_cost',
  TeeInstallationGardenValveMCost = 'tee_installation_garden_valve_m_cost',
  TeeInstallationGardenValveLCost = 'tee_installation_garden_valve_l_cost',
  LinearInstallationColdWaterSCost = 'linear_installation_cold_water_s_cost',
  LinearInstallationColdWaterMCost = 'linear_installation_cold_water_m_cost',
  LinearInstallationColdWaterLCost = 'linear_installation_cold_water_l_cost',
  LinearInstallationColdAndWarmWaterSCost = 'linear_installation_cold_and_warm_water_s_cost',
  LinearInstallationColdAndWarmWaterMCost = 'linear_installation_cold_and_warm_water_m_cost',
  LinearInstallationColdAndWarmWaterLCost = 'linear_installation_cold_and_warm_water_l_cost',
  LinearInstallationGardenValveSCost = 'linear_installation_garden_valve_s_cost',
  LinearInstallationGardenValveMCost = 'linear_installation_garden_valve_m_cost',
  LinearInstallationGardenValveLCost = 'linear_installation_garden_valve_l_cost',
  RingInstallationColdWaterSCost = 'ring_installation_cold_water_s_cost',
  RingInstallationColdWaterMCost = 'ring_installation_cold_water_m_cost',
  RingInstallationColdWaterLCost = 'ring_installation_cold_water_l_cost',
  RingInstallationColdAndWarmWaterSCost = 'ring_installation_cold_and_warm_water_s_cost',
  RingInstallationColdAndWarmWaterMCost = 'ring_installation_cold_and_warm_water_m_cost',
  RingInstallationColdAndWarmWaterLCost = 'ring_installation_cold_and_warm_water_l_cost',
  RingInstallationGardenValveSCost = 'ring_installation_garden_valve_s_cost',
  RingInstallationGardenValveMCost = 'ring_installation_garden_valve_m_cost',
  RingInstallationGardenValveLCost = 'ring_installation_garden_valve_l_cost',
  TeeInstallationRainShowerSCost = 'tee_installation_rain_shower_s_cost',
  TeeInstallationRainShowerMCost = 'tee_installation_rain_shower_m_cost',
  TeeInstallationRainShowerLCost = 'tee_installation_rain_shower_l_cost',
  LinearInstallationRainShowerSCost = 'linear_installation_rain_shower_s_cost',
  LinearInstallationRainShowerMCost = 'linear_installation_rain_shower_m_cost',
  LinearInstallationRainShowerLCost = 'linear_installation_rain_shower_l_cost',
  RingInstallationRainShowerSCost = 'ring_installation_rain_shower_s_cost',
  RingInstallationRainShowerMCost = 'ring_installation_rain_shower_m_cost',
  RingInstallationRainShowerLCost = 'ring_installation_rain_shower_l_cost',
  WaterDistributionHorizontalSF1Cost = 'water_distribution_horizontal_s_f1_cost',
  WaterDistributionHorizontalMF1Cost = 'water_distribution_horizontal_m_f1_cost',
  WaterDistributionHorizontalLF1Cost = 'water_distribution_horizontal_l_f1_cost',
  WaterDistributionHorizontalSF2Cost = 'water_distribution_horizontal_s_f2_cost',
  WaterDistributionHorizontalMF2Cost = 'water_distribution_horizontal_m_f2_cost',
  WaterDistributionHorizontalLF2Cost = 'water_distribution_horizontal_l_f2_cost',
  WaterDistributionHorizontalSF3Cost = 'water_distribution_horizontal_s_f3_cost',
  WaterDistributionHorizontalMF3Cost = 'water_distribution_horizontal_m_f3_cost',
  WaterDistributionHorizontalLF3Cost = 'water_distribution_horizontal_l_f3_cost',
  WaterDistributionVerticalSF1Cost = 'water_distribution_vertical_s_f1_cost',
  WaterDistributionVerticalMF1Cost = 'water_distribution_vertical_m_f1_cost',
  WaterDistributionVerticalLF1Cost = 'water_distribution_vertical_l_f1_cost',
  WaterDistributionVerticalSF2Cost = 'water_distribution_vertical_s_f2_cost',
  WaterDistributionVerticalMF2Cost = 'water_distribution_vertical_m_f2_cost',
  WaterDistributionVerticalLF2Cost = 'water_distribution_vertical_l_f2_cost',
  WaterDistributionVerticalSF3Cost = 'water_distribution_vertical_s_f3_cost',
  WaterDistributionVerticalMF3Cost = 'water_distribution_vertical_m_f3_cost',
  WaterDistributionVerticalLF3Cost = 'water_distribution_vertical_l_f3_cost',
  WaterDistributionVerticalSF4Cost = 'water_distribution_vertical_s_f4_cost',
  WaterDistributionVerticalMF4Cost = 'water_distribution_vertical_m_f4_cost',
  WaterDistributionVerticalLF4Cost = 'water_distribution_vertical_l_f4_cost',
  WaterDistributionVerticalSF5Cost = 'water_distribution_vertical_s_f5_cost',
  WaterDistributionVerticalMF5Cost = 'water_distribution_vertical_m_f5_cost',
  WaterDistributionVerticalLF5Cost = 'water_distribution_vertical_l_f5_cost',
  WaterDistributionHorizontalSF4Cost = 'water_distribution_horizontal_s_f4_cost',
  WaterDistributionHorizontalMF4Cost = 'water_distribution_horizontal_m_f4_cost',
  WaterDistributionHorizontalLF4Cost = 'water_distribution_horizontal_l_f4_cost',
  WaterDistributionHorizontalSF5Cost = 'water_distribution_horizontal_s_f5_cost',
  WaterDistributionHorizontalMF5Cost = 'water_distribution_horizontal_m_f5_cost',
  WaterDistributionHorizontalLF5Cost = 'water_distribution_horizontal_l_f5_cost',
  BathroomFittingsWcCost = 'bathroom_fittings_wc_cost',
  BathroomFittingsBathCost = 'bathroom_fittings_bath_cost',
  BathroomFittingsShowerCost = 'bathroom_fittings_shower_cost',
  BathroomFittingsBasinCost = 'bathroom_fittings_basin_cost',
  BathroomFittingsBidetCost = 'bathroom_fittings_bidet_cost',
  BathroomFittingsSmallBasinCost = 'bathroom_fittings_small_basin_cost',
  BathroomFittingsRainShowerCost = 'bathroom_fittings_rain_shower_cost',
  BathroomFittingsKitchenSinkCost = 'bathroom_fittings_kitchen_sink_cost',
  BathroomFittingsUrinalCost = 'bathroom_fittings_urinal_cost',
  BathroomFittingsWcProductNote = 'bathroom_fittings_wc_product_note',
  BathroomFittingsBathProductNote = 'bathroom_fittings_bath_product_note',
  BathroomFittingsShowerProductNote = 'bathroom_fittings_shower_product_note',
  BathroomFittingsBidetProductNote = 'bathroom_fittings_bidet_product_note',
  BathroomFittingsRainShowerProductNote = 'bathroom_fittings_rain_shower_product_note',
  BathroomFittingsKitchenSinkProductNote = 'bathroom_fittings_kitchen_sink_product_note',
  BathroomFittingsUrinalProductNote = 'bathroom_fittings_urinal_product_note',
  BathroomFittingsWashingWcCost = 'bathroom_fittings_washing_wc_cost',
  BathroomFittingsWashingWcProductNote = 'bathroom_fittings_washing_wc_product_note',
  SewageClassicSCost = 'sewage_classic_s_cost',
  SewageClassicMCost = 'sewage_classic_m_cost',
  SewageClassicLCost = 'sewage_classic_l_cost',
  SewageClassicSProducerName = 'sewage_classic_s_producer_name',
  SewageClassicMProducerName = 'sewage_classic_m_producer_name',
  SewageClassicLProducerName = 'sewage_classic_l_producer_name',
  SewagePremiumSCost = 'sewage_premium_s_cost',
  SewagePremiumMCost = 'sewage_premium_m_cost',
  SewagePremiumLCost = 'sewage_premium_l_cost',
  SewagePremiumSProducerName = 'sewage_premium_s_producer_name',
  SewagePremiumMProducerName = 'sewage_premium_m_producer_name',
  SewagePremiumLProducerName = 'sewage_premium_l_producer_name',
  SewageClassicVerticalDistributionCost = 'sewage_classic_vertical_distribution_cost',
  SewagePremiumVerticalDistributionCost = 'sewage_premium_vertical_distribution_cost',
  VentilationExhaustFanFixedCost = 'ventilation_exhaust_fan_fixed_cost',
  VentilationMechanicalSCost = 'ventilation_mechanical_s_cost',
  VentilationMechanicalMCost = 'ventilation_mechanical_m_cost',
  VentilationMechanicalLCost = 'ventilation_mechanical_l_cost',
  VentilationMechanicalSProducerName = 'ventilation_mechanical_s_producer_name',
  VentilationMechanicalMProducerName = 'ventilation_mechanical_m_producer_name',
  VentilationMechanicalLProducerName = 'ventilation_mechanical_l_producer_name',
  VentilationExhaustFanFixedActive = 'ventilation_exhaust_fan_fixed_active',
  VentilationExhaustFanFixedProducerName = 'ventilation_exhaust_fan_fixed_producer_name',
  VentilationMechanicalSActive = 'ventilation_mechanical_s_active',
  VentilationMechanicalMActive = 'ventilation_mechanical_m_active',
  VentilationMechanicalLActive = 'ventilation_mechanical_l_active',
  DiscountHeatingDistributionValue = 'discount_heating_distribution_value',
  DiscountWaterDistributionValue = 'discount_water_distribution_value',
  DiscountSewageValue = 'discount_sewage_value',
  GasSProducerName = 'gas_s_producer_name',
  GasMProducerName = 'gas_m_producer_name',
  GasLProducerName = 'gas_l_producer_name',
  OilSProducerName = 'oil_s_producer_name',
  OilMProducerName = 'oil_m_producer_name',
  OilLProducerName = 'oil_l_producer_name',
  WoodSProducerName = 'wood_s_producer_name',
  WoodMProducerName = 'wood_m_producer_name',
  WoodLProducerName = 'wood_l_producer_name',
  HeatingAndCoolingAirPumpSProducerName = 'heating_and_cooling_air_pump_s_producer_name',
  HeatingAndCoolingAirPumpMProducerName = 'heating_and_cooling_air_pump_m_producer_name',
  HeatingAndCoolingAirPumpLProducerName = 'heating_and_cooling_air_pump_l_producer_name',
  HeatingAndCoolingGeothermalPumpSProducerName = 'heating_and_cooling_geothermal_pump_s_producer_name',
  HeatingAndCoolingGeothermalPumpMProducerName = 'heating_and_cooling_geothermal_pump_m_producer_name',
  HeatingAndCoolingGeothermalPumpLProducerName = 'heating_and_cooling_geothermal_pump_l_producer_name',
  DistrictHeatingSProducerName = 'district_heating_s_producer_name',
  DistrictHeatingMProducerName = 'district_heating_m_producer_name',
  DistrictHeatingLProducerName = 'district_heating_l_producer_name',
  PelletsSProducerName = 'pellets_s_producer_name',
  PelletsMProducerName = 'pellets_m_producer_name',
  PelletsLProducerName = 'pellets_l_producer_name',
  AirPumpSProducerName = 'air_pump_s_producer_name',
  AirPumpMProducerName = 'air_pump_m_producer_name',
  AirPumpLProducerName = 'air_pump_l_producer_name',
  GeothermalPumpSProducerName = 'geothermal_pump_s_producer_name',
  GeothermalPumpMProducerName = 'geothermal_pump_m_producer_name',
  GeothermalPumpLProducerName = 'geothermal_pump_l_producer_name',
  HeatingControlBasicActive = 'heating_control_basic_active',
  HeatingControlSmartActive = 'heating_control_smart_active',
  HeatingAndCoolingControlSmartActive = 'heating_and_cooling_control_smart_active',
  HeatingAndCoolingControlPremiumActive = 'heating_and_cooling_control_premium_active',
  HeatingControlBasicProducerName = 'heating_control_basic_producer_name',
  HeatingControlSmartProducerName = 'heating_control_smart_producer_name',
  HeatingAndCoolingControlSmartProducerName = 'heating_and_cooling_control_smart_producer_name',
  HeatingAndCoolingControlPremiumProducerName = 'heating_and_cooling_control_premium_producer_name',
  BathroomFittingsFrameBasinActive = 'bathroom_fittings_frame_basin_active',
  BathroomFittingsWcActive = 'bathroom_fittings_wc_active',
  BathroomFittingsWashingWcActive = 'bathroom_fittings_washing_wc_active',
  BathroomFittingsUrinalActive = 'bathroom_fittings_urinal_active',
  BathroomFittingsBathActive = 'bathroom_fittings_bath_active',
  BathroomFittingsRainShowerActive = 'bathroom_fittings_rain_shower_active',
  BathroomFittingsShowerActive = 'bathroom_fittings_shower_active',
  BathroomFittingsKitchenSinkActive = 'bathroom_fittings_kitchen_sink_active',
  BathroomFittingsSmallBasinActive = 'bathroom_fittings_small_basin_active',
  BathroomFittingsBasinActive = 'bathroom_fittings_basin_active',
  HotUtilityWaterTankActive = 'hot_utility_water_tank_active',
  SewageClassicSActive = 'sewage_classic_s_active',
  SewageClassicMActive = 'sewage_classic_m_active',
  SewageClassicLActive = 'sewage_classic_l_active',
  SewagePremiumSActive = 'sewage_premium_s_active',
  SewagePremiumMActive = 'sewage_premium_m_active',
  SewagePremiumLActive = 'sewage_premium_l_active',
  DiscountHeatingDistributionDescription = 'discount_heating_distribution_description',
  DiscountWaterDistributionDescription = 'discount_water_distribution_description',
  DiscountSewageDescription = 'discount_sewage_description',
  DiscountHeatingDistributionActive = 'discount_heating_distribution_active',
  DiscountWaterDistributionActive = 'discount_water_distribution_active',
  DiscountSewageActive = 'discount_sewage_active',
  FloorHeatingSSquareMeterActive = 'floor_heating_s_square_meter_active',
  FloorHeatingMSquareMeterActive = 'floor_heating_m_square_meter_active',
  FloorHeatingLSquareMeterActive = 'floor_heating_l_square_meter_active',
  FloorHeatingSProducerName = 'floor_heating_s_producer_name',
  FloorHeatingMProducerName = 'floor_heating_m_producer_name',
  FloorHeatingLProducerName = 'floor_heating_l_producer_name',
  FloorHeatingSparseSSquareMeterActive = 'floor_heating_sparse_s_square_meter_active',
  FloorHeatingSparseMSquareMeterActive = 'floor_heating_sparse_m_square_meter_active',
  FloorHeatingSparseLSquareMeterActive = 'floor_heating_sparse_l_square_meter_active',
  FloorHeatingSparseSProducerName = 'floor_heating_sparse_s_producer_name',
  FloorHeatingSparseMProducerName = 'floor_heating_sparse_m_producer_name',
  FloorHeatingSparseLProducerName = 'floor_heating_sparse_l_producer_name',
  FloorHeatingAndCoolingSSquareMeterActive = 'floor_heating_and_cooling_s_square_meter_active',
  FloorHeatingAndCoolingMSquareMeterActive = 'floor_heating_and_cooling_m_square_meter_active',
  FloorHeatingAndCoolingLSquareMeterActive = 'floor_heating_and_cooling_l_square_meter_active',
  FloorHeatingAndCoolingSProducerName = 'floor_heating_and_cooling_s_producer_name',
  FloorHeatingAndCoolingMProducerName = 'floor_heating_and_cooling_m_producer_name',
  FloorHeatingAndCoolingLProducerName = 'floor_heating_and_cooling_l_producer_name',
  FloorHeatingAndCoolingSparseSSquareMeterActive = 'floor_heating_and_cooling_sparse_s_square_meter_active',
  FloorHeatingAndCoolingSparseMSquareMeterActive = 'floor_heating_and_cooling_sparse_m_square_meter_active',
  FloorHeatingAndCoolingSparseLSquareMeterActive = 'floor_heating_and_cooling_sparse_l_square_meter_active',
  FloorHeatingAndCoolingSparseSProducerName = 'floor_heating_and_cooling_sparse_s_producer_name',
  FloorHeatingAndCoolingSparseMProducerName = 'floor_heating_and_cooling_sparse_m_producer_name',
  FloorHeatingAndCoolingSparseLProducerName = 'floor_heating_and_cooling_sparse_l_producer_name',
  CeilingHeatingAndCoolingActive = 'ceiling_heating_and_cooling_active',
  CeilingHeatingAndCoolingProducerName = 'ceiling_heating_and_cooling_producer_name',
  HouseSizeS = 'houseSizeS',
  HouseSizeSM = 'houseSizeSM',
  HouseSizeML = 'houseSizeML',
  HouseSizeL = 'houseSizeL',
  NormalRadius = 'normalRadius',
  MaximumRadius = 'maximumRadius',
  ExtraCost = 'extraDistanceCost',
  WaterReducerWithFilterActive = 'water_reducer_with_filter_active',
  TeeInstallationSActive = 'tee_installation_s_active',
  TeeInstallationMActive = 'tee_installation_m_active',
  TeeInstallationLActive = 'tee_installation_l_active',
  TeeInstallationSProducerName = 'tee_installation_s_producer_name',
  TeeInstallationMProducerName = 'tee_installation_m_producer_name',
  TeeInstallationLProducerName = 'tee_installation_l_producer_name',
  LinearInstallationSActive = 'linear_installation_s_active',
  LinearInstallationMActive = 'linear_installation_m_active',
  LinearInstallationLActive = 'linear_installation_l_active',
  LinearInstallationSProducerName = 'linear_installation_s_producer_name',
  LinearInstallationMProducerName = 'linear_installation_m_producer_name',
  LinearInstallationLProducerName = 'linear_installation_l_producer_name',
  RingInstallationSActive = 'ring_installation_s_active',
  RingInstallationMActive = 'ring_installation_m_active',
  RingInstallationLActive = 'ring_installation_l_active',
  RingInstallationSProducerName = 'ring_installation_s_producer_name',
  RingInstallationMProducerName = 'ring_installation_m_producer_name',
  RingInstallationLProducerName = 'ring_installation_l_producer_name',
  WaterDistributionVerticalSActive = 'water_distribution_vertical_s_active',
  WaterDistributionVerticalMActive = 'water_distribution_vertical_m_active',
  WaterDistributionVerticalLActive = 'water_distribution_vertical_l_active',
  WaterDistributionHorizontalSActive = 'water_distribution_horizontal_s_active',
  WaterDistributionHorizontalMActive = 'water_distribution_horizontal_m_active',
  WaterDistributionHorizontalLActive = 'water_distribution_horizontal_l_active',
  BathroomFittingsFrameWcActive = 'bathroom_fittings_frame_wc_active',
  BathroomFittingsFrameWcCost = 'bathroom_fittings_frame_wc_cost',
  BathroomFittingsFrameUrinalActive = 'bathroom_fittings_frame_urinal_active',
  BathroomFittingsFrameUrinalCost = 'bathroom_fittings_frame_urinal_cost',
  BathroomFittingsFrameBidetActive = 'bathroom_fittings_frame_bidet_active',
  BathroomFittingsFrameBidetCost = 'bathroom_fittings_frame_bidet_cost',
  BathroomFittingsSmallBasinProductNote = 'bathroom_fittings_small_basin_product_note',
  BathroomFittingsBasinProductNote = 'bathroom_fittings_basin_product_note',
  BathroomFittingsBidetActive = 'bathroom_fittings_bidet_active',
  GeothermalPumpSMaintenanceCost = 'geothermal_pump_s_maintenance_cost',
  GeothermalPumpMMaintenanceCost = 'geothermal_pump_m_maintenance_cost',
  GeothermalPumpLMaintenanceCost = 'geothermal_pump_l_maintenance_cost',
  ElectricSProducerName = 'electric_s_producer_name',
  ElectricMProducerName = 'electric_m_producer_name',
  ElectricLProducerName = 'electric_l_producer_name',
  WallHeatingAndCoolingActive = 'wall_heating_and_cooling_active',
  WallHeatingAndCoolingProducerName = 'wall_heating_and_cooling_producer_name',
  PelletsSChimneyCost = 'pellets_s_chimney_cost',
  PelletsMChimneyCost = 'pellets_m_chimney_cost',
  PelletsLChimneyCost = 'pellets_l_chimney_cost',
  WoodSChimneyCost = 'wood_s_chimney_cost',
  WoodMChimneyCost = 'wood_m_chimney_cost',
  WoodLChimneyCost = 'wood_l_chimney_cost',
  GasSTankIncludedActive = 'gas_s_tank_included_active',
  GasMTankIncludedActive = 'gas_m_tank_included_active',
  GasLTankIncludedActive = 'gas_l_tank_included_active',
  PelletsSHotWaterIntegrationActive = 'pellets_s_hot_water_integration_active',
  PelletsMHotWaterIntegrationActive = 'pellets_m_hot_water_integration_active',
  PelletsLHotWaterIntegrationActive = 'pellets_l_hot_water_integration_active',
}
