import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';

export const ScrollToTop: React.FC = (): JSX.Element => {
  const { pathname }: Location = useLocation();

  useEffect(
    (): void => {
      window.scrollTo(0, 0);
    },
    [pathname]
  );

  return <></>;
};
