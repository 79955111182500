import { AbstractForm } from '@rehau/shared/forms';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { Config } from '@rehau/shared/models/countryConfig';
import { FormsTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { EmailField } from '@rehau/shared/forms/elements';
import { MixedContainer } from '@rehau/shared/forms/components';

export class RemindPasswordForm extends AbstractForm {
  constructor(values: ObjectIteratorInterface, countryConfig?: Config) {
    super(
      FormsTypeEnum.RemindPassword,
      '',
      values,
      countryConfig,
      [
        new MixedContainer(
          FrontendComponentEnum.HorizontalFieldline,
          'rehau.website.remind_password.title',
          [
            new EmailField(
              'email',
              'email',
              '',
              '',
              true
            ),
          ]
        ),
      ]
    );
  }
}
