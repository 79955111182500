import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { StyledComponent } from '../../../../types/styledComponent.types';
import { Color } from '../../../../enums/color.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { Price } from '../../../../theme';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { styleWhenTrue } from '../../../../utils/style.utils';

import { IconWithTitleProps, ProducerSeparatorProps } from './offerItemRowHeader.types';

export const IconWithTitle: StyledComponent<'div', IconWithTitleProps> = styled.div<IconWithTitleProps>`
  display: flex;
  font-size: 18px;
  line-height: 24px;

  ${styleWhenTrue(prop('withBiggerFonts'), css`
    font-size: 22px;
  `)}
  ${styleWhenTrue(prop('withMarginBottom'), css`
    margin-bottom: 15px;
  `)}

`;

export const IconContainer: StyledComponent<'div'> = styled.div`
  width: 60px;

  @media ${Breakpoint.Mobile} {
    display: none;
  }
`;

export const Title: StyledComponent<'div'> = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media ${Breakpoint.Mobile} {
    margin-left: 0;
    align-items: flex-start;
  }
`;

export const RowName: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;

  @media ${Breakpoint.Mobile} {
    align-items: flex-start;
  }
`;

export const NameWithTypeOrAmount: StyledComponent<'div'> = styled.div`
  display: flex;

  @media ${Breakpoint.Mobile} {
    flex-wrap: wrap;
  }
`;

export const Id: StyledComponent<'div'> = styled.div`
  margin-right: 5px;
`;

export const Name: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Bold};
  color: ${Color.Black};
  margin-right: 5px;
`;

export const Type: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Bold};
  color: ${Color.CompanyColor};
`;

export const Producer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  color: ${Color.Grey};
  overflow-wrap: break-word;
  margin-right: 20px;
`;

export const ProducerSeparator: StyledComponent<'span', ProducerSeparatorProps> = styled.span<ProducerSeparatorProps>`
  margin-right: 15px;
  background-color: ${Color.Grey};
  height: 12.5px;
  width: 1px;
  margin-top: 1.5px;

  ${styleWhenTrue(prop('hasMarginLeftAndRight'), css`
    margin: 1.5px 15px 0;
  `)}
`;

export const StyledPrice: StyledComponent<typeof Price> = styled(Price)``;
