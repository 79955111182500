import { PathParamsTuple } from '../types/pathParamsTuple.types';
import { KeyValueString } from '../types/keyValueString.types';

export const getPathWithParams: (
  path: string, params: KeyValueString
) => string = (
  path: string, params: KeyValueString
): string => {
  const paramsEntries: PathParamsTuple[] = Object.entries(params);
  let modifiedPath: string = path;

  if (!paramsEntries.length) {
    return path;
  }

  paramsEntries.forEach(([key, value]: PathParamsTuple): void => {
    modifiedPath = modifiedPath.replace(`:${key}`, value);
  });

  return modifiedPath;
};
