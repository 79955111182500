import styled, { css, StyledComponent } from 'styled-components';
import { prop } from 'ramda';

import { styleWhenTrue } from '../../../utils/style.utils';
import { Breakpoint } from '../../../enums/breakpoint.enum';
import { Color } from '../../../enums/color.enum';
import { ZIndex } from '../../../enums/zIndex.enum';
import { MobileMenuItem } from '../mobileMenuItem/mobileMenuItem.component';
import { HeaderHeight } from '../../../enums/headerHeight.enum';
import { Icon } from '../../icon/icon.component';
import { FontWeight } from '../../../enums/fontWeight.enum';

import { ContainerProps } from './mobileMenu.types';

export const Container: StyledComponent<'div', {}, ContainerProps, never> = styled.div`
  display: none;
  position: fixed;
  top: ${HeaderHeight.Desktop};
  left: 0;
  width: 100%;
  min-height: calc(100vh - ${HeaderHeight.Desktop});
  background-color: ${Color.White};
  padding: 30px 20px;
  box-sizing: border-box;
  transition: 0.3s;
  opacity: 0;
  z-index: ${ZIndex.Hidden};
  pointer-events: none;

  ${styleWhenTrue(prop('open'), css`
    opacity: 1;
    z-index: ${ZIndex.MainNavbarModal};
    pointer-events: auto;
  `)}

  @media ${Breakpoint.Tablet} {
    display: block;
    overflow-y: auto;
  }
`;

export const StyledIcon: StyledComponent<typeof Icon, {}, {}, never> = styled(Icon)`
  margin-left: auto;
`;

export const Item: StyledComponent<typeof MobileMenuItem, {}, {}, never> = styled(MobileMenuItem)``;

export const Button: StyledComponent<'button', {}, {}, never> = styled.button`
  font-size: 16px;
  letter-spacing: 0.15px;
  font-weight: ${FontWeight.Bold};
  color: ${Color.Black};
  margin-right: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  width: 100%;
  transition: 0.3s;

  :hover {
    color: ${Color.Pink};

    ${StyledIcon} {
      color: ${Color.Pink};
    }
  }
`;
