import React from 'react';

import { ImageStatic } from '../../../../../../../shared/forms/elements';
import { FieldElement, FieldElementProps } from '../types';
import { SimpleImage } from '../../simpleImage/simpleImage.component';

export const ImageStaticElement: FieldElement<ImageStatic> = (props: FieldElementProps<ImageStatic>): JSX.Element => {
  const { component }: FieldElementProps<ImageStatic> = props;

  return (
    <SimpleImage imagePath={component.path} />
  );
};
