import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { StyledComponent } from '../../../../types/styledComponent.types';
import { InnerContainer, OuterContainer } from '../../../../theme/containers.styled';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Color } from '../../../../enums/color.enum';
import { Markdown } from '../../../markdown/markdown.component';

export const Container: StyledComponent<typeof OuterContainer> = styled(OuterContainer)`
  padding-top: 100px;
  padding-bottom: 100px;

  @media ${Breakpoint.Tablet} {
    margin: 0;
    width: 100%;
    padding: 60px 40px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 60px 20px;
  }
`;

export const Wrapper: StyledComponent<typeof InnerContainer> = styled(InnerContainer)`
  flex-direction: column;
`;

export const Columns: StyledComponent<'div'> = styled.div`
  display: flex;
  margin: 0 -12.5px;
  overflow: hidden;

  @media ${Breakpoint.Tablet} {
    flex-wrap: wrap;
    margin: 0 -5px;
  }

  @media ${Breakpoint.Mobile} {
    margin: 0;
  }
`;

export const Image: StyledComponent<'img'> = styled.img`
  width: 100%;
`;

export const ImageOverlay: StyledComponent<'div'> = styled.div`
  position: absolute;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;

  @media ${Breakpoint.Tablet} {
    height: 30%;
  }
`;

export const LabelMarkdown: StyledComponent<typeof Markdown> = styled(Markdown)`
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  color: ${Color.White};
  font-size: 22px;
  line-height: 28px;
  font-weight: ${FontWeight.Bold};
`;

export const Box: StyledComponent<'div'> = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background-color: ${Color.White};
  padding: 20px;
  opacity: 0;
  transition: 0.2s;
`;

export const Column: StyledComponent<'div'> = styled.div`
  flex: 1;
  margin: 0 12.5px;
  position: relative;

  :hover {
    ${LabelMarkdown} {
      display: none;
    }

    ${Box} {
      opacity: 1;
    }
  }

  @media ${Breakpoint.Tablet} {
    width: calc(50% - 10px);
    min-width: calc(50% - 10px);
    margin: 0 5px 10px;
  }

  @media ${Breakpoint.Mobile} {
    width: 100%;
    min-width: 100%;
    margin: 0 0 12px;
  }
`;

export const ItemTitle: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 30px;
  font-weight: ${FontWeight.Black};
  margin-bottom: 7px;
`;

export const StyledMarkdown: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 22px;

  strong {
    font-weight: ${FontWeight.Bold};
  }
`;
