import React, { useMemo, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { GlobalStyle } from '@rehau-fe/app/theme/global.styled';
import {
  CommonProvidersWrapper,
  findPathByName,
  Footer,
  Loader,
  MainNavbar,
  ScrollToTop,
  StickyTeaser,
  UseState,
} from '@rehau-fe/ui/src';
import { Config, RouteInfo } from '@rehau/shared/models/countryConfig';
import { configsPromises as configPromisesMethod, translationPromise } from '@rehau-fe/ui/src/utils/initMethods.utils';
import { WebsitePathNameEnum } from '@rehau/shared/enums';
import {
  ActivateAccount,
  CustomerRegulations,
  Home,
  InstallerRegulations,
  NotFound,
  Registration,
  ReGuard,
  RemindPassword,
  ReSource,
} from '@rehau-fe/app/views';
import { AppConfig } from '../appConfig.config';
import { RemindPasswordConfirmation } from '../views/setNewPassword/remindPasswordConfirmation.view';

const App: React.FC = (): JSX.Element => {
  const [isLoading, setIsLoading]: UseState<boolean> = useState<boolean>(true);
  const [productTabsOnTop, setProductTabsOnTop]: UseState<boolean> = useState<boolean>(false);
  const [routesArray, setRoutesArray]: UseState<RouteInfo[]> = useState<RouteInfo[]>([]);
  const [countryConfig, setCountryConfig]: UseState<Config> = useState<Config>({} as Config);

  const configsPromises: Promise<void>[] = useMemo(
    (): Promise<void>[] => configPromisesMethod(
      AppConfig.mainLanguage,
      AppConfig.forceEnglishLanguage,
      setCountryConfig,
      setRoutesArray,
      AppConfig.externalAssets
    ),
    []
  );

  Promise.all([translationPromise, ...configsPromises]).then((): void => {
    setIsLoading(false);
  });

  return !!routesArray.length && !!Object.keys(countryConfig).length && !isLoading ? (
    <Router>
      <CommonProvidersWrapper
        countryConfig={countryConfig}
        routesArray={routesArray}
        appConfig={AppConfig}
      >
        <GlobalStyle />
        <Loader isVisible={isLoading || !routesArray.length || !Object.keys(countryConfig).length} />
        <ScrollToTop />
        <MainNavbar fixed hiddenByTabs={productTabsOnTop} />
        <Switch>
          <Route exact path={findPathByName(WebsitePathNameEnum.Home, routesArray)} component={Home} />
          <Route exact path={findPathByName(WebsitePathNameEnum.Registration, routesArray)} component={Registration} />
          <Route exact path={findPathByName(WebsitePathNameEnum.ActivateAccount, routesArray)} component={ActivateAccount} />
          <Route exact path={findPathByName(WebsitePathNameEnum.RemindPassword, routesArray)} component={RemindPassword} />
          <Route
            exact
            path={findPathByName(WebsitePathNameEnum.RemindPasswordConfirmation, routesArray)}
            component={RemindPasswordConfirmation}
          />
          <Route
            exact
            path={findPathByName(WebsitePathNameEnum.InstallerRegulations, routesArray)}
            component={InstallerRegulations}
          />
            <Route
            exact
            path={findPathByName(WebsitePathNameEnum.CustomerRegulations, routesArray)}
            component={CustomerRegulations}
          />
          <Route exact path='*' component={NotFound} />
        </Switch>
        <Footer />
        <StickyTeaser />
      </CommonProvidersWrapper>
    </Router>
  ) : (
    <>
      <GlobalStyle />
      <Loader isVisible={isLoading || !routesArray.length || !Object.keys(countryConfig).length} />
    </>
  );
};

export default App;
