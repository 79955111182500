import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { FileField } from '../../../../../../../shared/forms/elements';
import { FieldElement, FieldElementProps } from '../types';
import { ImageUpload } from '../../imageUpload/imageUpload.component';

export const ImageUploadFieldElement: FieldElement<FileField> = (props: FieldElementProps<FileField>): JSX.Element => {
  const { component, save, setValues, api }: FieldElementProps<FileField> = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <ImageUpload
      label={t(component.label || '')}
      description={t(component.label || '')}
      component={component}
      handleSave={save}
      setValues={setValues}
      api={api}
    />
  );
};
