import React from 'react';

import { UseImageAnimationOutput } from '../../../../types/useImageAnimation.types';
import { useImageAnimation } from '../../../../hooks/useImageAnimation';
import { useProductPopupContext } from '../../../../hooks/useProductPopupContext.hook';
import { ProductPopupContextType } from '../../../../types/productPopupContextType.types';

import {
  Button,
  Container,
  Content,
  Hint,
  HintImage,
  HintText,
  Image,
  ImageBox,
  ImageContainer,
  Link,
  SectionBody,
  SectionTitle,
  StyledMarkdown,
  TextImage,
  Title,
  Wrapper,
} from './textWithImage.styled';
import { TextWithImageProps } from './textWithImage.types';

export const TextWithImage: React.FC<TextWithImageProps> = (props: TextWithImageProps): JSX.Element => {
  const {
    className,
    sectionTitle,
    title,
    text,
    textImageSrc,
    imageSrcList,
    buttonLink,
    buttonText = '',
    secondButtonLink,
    secondButtonText = '',
    darkBackground = false,
    reversed = false,
    wideImage = false,
    hasWideText = false,
    hint,
    hintImageSrc,
    secondHintImageSrc,
    withoutTopPadding = false,
    imageOnTop = false,
  }: TextWithImageProps = props;
  const { isInPopup }: ProductPopupContextType = useProductPopupContext();
  const { firstImageRef }: UseImageAnimationOutput = useImageAnimation(isInPopup);

  return (
    <Container className={className} darkBackground={darkBackground} reversed={reversed} withoutTopPadding={withoutTopPadding}>
      <Wrapper>
        {!!sectionTitle && <SectionTitle large data-testid='text-with-image-header-title'>{sectionTitle}</SectionTitle>}
        <SectionBody reversed={reversed} imageOnTop={imageOnTop}>
          <Content reversed={reversed} wideImage={wideImage} hasWideText={hasWideText}>
            {!!title && <Title data-testid='text-with-image-title'>{title}</Title>}
            {!!textImageSrc && <TextImage src={textImageSrc} />}
            {!!text && (
              <StyledMarkdown
                text={text}
                hasWideText={hasWideText}
              />
            )}
            {!!buttonLink && (
              <Link href={buttonLink} target='_blank' rel='noopener noreferrer' data-testid='external-link'>
                <Button text={buttonText} />
              </Link>
            )}
            {!!secondButtonLink && (
              <Link href={secondButtonLink} target='_blank' rel='noopener noreferrer' data-testid='additional-link'>
                <Button
                  text={secondButtonText}
                  darkBackground
                />
              </Link>
            )}
            {(!!hint || !!hintImageSrc) && (
              <Hint data-testid='text-with-image-hint'>
                {!!hintImageSrc && <HintImage src={hintImageSrc} withSecondImage={!!secondHintImageSrc} />}
                {!!secondHintImageSrc && <HintImage withSecondImage src={secondHintImageSrc} />}
                {!!hint && <HintText>{hint}</HintText>}
              </Hint>
            )}
          </Content>
          <ImageContainer
            reversed={reversed}
            wideImage={wideImage}
            hasWideText={hasWideText}
            imageOnTop={imageOnTop}
          >
            <ImageBox ref={firstImageRef}>
              {imageSrcList.map((imageSrc: string, index: number): JSX.Element => (
                <Image key={index} src={imageSrc} alt='' reversed={reversed} />
              ))}
            </ImageBox>
          </ImageContainer>
        </SectionBody>
      </Wrapper>
    </Container>
  );
};
