import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { BaseLabel } from '../../theme';
import { BoxShadow } from '../../enums/boxShadow.enum';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';
import { Tooltip } from '../tooltip/tooltip.component';

import { WrapperProps } from './quantityInput.types';

export const Container: StyledComponent<'div'> = styled.div``;

export const Wrapper: StyledComponent<'div', WrapperProps> = styled.div<WrapperProps>`
  width: 225px;
  box-sizing: border-box;
  border: 1px solid ${Color.GreyStroke};
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 21px;
  transition: 0.2s;
  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
  ${styleWhenTrue(prop('valid'), css`
    border: 1px solid ${Color.Green};
    box-shadow: ${BoxShadow.BtnGreyShadow};
  `)}
`;

export const Label: StyledComponent<typeof BaseLabel> = styled(BaseLabel)`
  margin-bottom: 10px;
`;

export const Row: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
`;

export const Button: StyledComponent<'button'> = styled.button``;

export const ValueContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Value: StyledComponent<'input'> = styled.input`
  font-weight: ${FontWeight.Bold};
  line-height: 22px;
  font-size: 18px;
  min-width: 0;
  width: 40px;
  text-align: center;
`;

export const Sign: StyledComponent<'p'> = styled.p`
  font-weight: ${FontWeight.Bold};
  line-height: 22px;
  font-size: 18px;
  pointer-events: none;
`;

export const StyledTooltip: StyledComponent<typeof Tooltip> = styled(Tooltip)`
  margin-left: 15px;
`;
