import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { FieldElement, FieldElementProps } from '../types';
import { componentTypes, fieldValueType } from '../../../../../../../shared/forms/types';
import { FieldComponent, FieldComponentWrapper } from '../fieldComponent.component';
import { ComponentsContainer } from '../../../../../../../shared/forms/components';
import { FieldsContainer as FieldsContainerField } from '../../fieldsContainer/fieldsContainer.component';
import { isSharedElementAvailable } from '../../../utils/isSharedElementAvailable.utils';
import { getFieldContainerType } from '../utils/getFieldContainerType';
import { InfoBox } from '@rehau/shared/forms/elements';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { UseState } from '../../../types/useState.types';

export const FieldsContainerFieldElement: FieldElement<ComponentsContainer> = (
  props: FieldElementProps<ComponentsContainer>
): JSX.Element | null => {
  const { component, saveMethod, setValues, api }: FieldElementProps<ComponentsContainer> = props;
  const { t }: TransProps<never> = useTranslation();
  const childElementsAvailable: boolean = component.isAnyFieldAvailable();
  const [childElements, setChildElements]: UseState<componentTypes[]> = useState<componentTypes[]>([]);

  const setValuesMethod: (values: ObjectIteratorInterface<fieldValueType>) => void = (
    values: ObjectIteratorInterface<fieldValueType>
  ): void => {
    if (setValues) {
      setValues(values);
    }
    setChildElements(component.elements.filter((innerComponent: componentTypes): boolean => isSharedElementAvailable(innerComponent)));
  };

  useEffect(
    (): void => {
      setChildElements(component.elements);
    },
    [component.elements]
  );

  return childElementsAvailable ? (
    <FieldsContainerField
      title={t(component.title)}
      subTitle={t(component.subTitle || '')}
      infoBox={component.informationBox
        ? new InfoBox(
          t(component.informationBox?.content || ''),
          t(component.informationBox?.readMoreContent || ''),
          component.informationBox?.position
        )
        : undefined
      }
      key={component.title}
      frontendTheme={component.frontendTheme}
    >
        {childElements.map((innerComponent: componentTypes, index: number): JSX.Element => (
          <FieldComponentWrapper className={getFieldContainerType(innerComponent)} key={`${innerComponent.title}_${index}`}>
            <FieldComponent innerComponent={innerComponent} saveMethod={saveMethod} setValues={setValuesMethod} api={api} />
          </FieldComponentWrapper>
        ))}
    </FieldsContainerField>
  ) : null;
};
