import React, { useCallback, useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { StyledComponent } from 'styled-components';

import {
  AbstractField,
  AreaField,
  ButtonStatic,
  CheckboxField,
  ChooseVariantField,
  ChooseVariantFromPopupField,
  ClickNumberField,
  ClickNumberWithIconField,
  CostField,
  DateField,
  DecisionField,
  DescriptionStatic,
  ImageStatic,
  MultiSelectField,
  RangeField,
  RatingStatic,
  SelectField,
  SelectItemField,
  TextAreaField,
  TextField,
} from '../../../../../../shared/forms/elements';
import {
  ComponentsContainer,
  DecisionWithChildrenContainer,
  FieldsContainer,
  MapContainer,
  PasswordContainer,
  PopupContainer,
  RadiusContainer,
  SelectItemsContainer,
  VariantContainer,
} from '../../../../../../shared/forms/components';
import { FrontendComponentEnum } from '../../../../../../shared/enums';
import { FileField } from './../../../../../../shared/forms/elements/fields/file.field';
import { ColorPickerField } from './../../../../../../shared/forms/elements/fields/colorPicker.field';
import { Button } from '../button/button.component';
import { SliderInput } from '../sliderInput/sliderInput.component';

import { ComponentWrapper, IdContainer } from './fieldComponent.styled';
import { FieldComponentProps, SetValueProps } from './fieldComponent.types';
import {
  AreaFieldElement,
  ChooseOptionFromBoxesFieldElement,
  ChooseVariantFromPopupFieldElement,
  ChooseVariantFromSelectWithDecisionFieldElement,
  ClickNumberWithIconFieldElement,
  CostFieldElement,
  DecisionFieldElement,
  DecisionWithChildrenFieldElement,
  FieldsContainerFieldElement,
  ImageUploadFieldElement,
  MapFieldElement,
  MultiselectFieldElement,
  PasswordFieldElement,
  RangeFieldElement,
  SelectFieldElement,
  SelectItemFieldElement,
  SelectItemsBoxFieldElement,
  SlidesFieldElement,
  TextareaFieldElement,
  TextFieldElement,
  VariantContainerFieldElement,
} from './fields';
import {
  DescriptionStaticElement,
  ImageStaticElement,
  RatingStaticElement,
} from './statics';
import { PopupContainerFieldElement } from './fields/popupContainer.field';
import { ColorPickerFieldElement } from './fields/colorPicker.field';
import { ClickNumberFieldElement } from './fields/clickNumber.field';
import { PasswordContainerFieldElement } from './fields/passwordContainer.field';
import { CheckboxFieldElement } from './fields/checkbox.field';
import { DatepickerFieldElement } from './fields/datepicker.field';
import { SliderInputFieldElement } from './fields/sliderInput.field';
import { RadiusContainerFieldElement } from './fields/radiusContainer.field';

export const FieldComponentWrapper: StyledComponent<'div', {}, {}, never> = ComponentWrapper;

export const FieldComponent: React.FC<FieldComponentProps> = (props: FieldComponentProps): JSX.Element | null => {
  const { innerComponent, saveMethod, setValues, api }: FieldComponentProps = props;
  const { t }: TransProps<never> = useTranslation();

  const handleSave: () => void = useCallback(
    (): void => {
      if (!!saveMethod) {
        saveMethod({ [(innerComponent as AbstractField).id]: (innerComponent as AbstractField).value });
      }
    },
    [saveMethod]
  );

  const setValueProps: SetValueProps = useMemo(
    (): SetValueProps => ({
      saveMethod,
      setValues,
      save: handleSave,
    }),
    [saveMethod, setValues, handleSave]
  );

  const getField: () => JSX.Element = useCallback(
    (): JSX.Element => {
      switch (innerComponent.frontendComponent) {
        case FrontendComponentEnum.HorizontalFieldline:
        case FrontendComponentEnum.ProfileSection:
          return (
            <FieldsContainerFieldElement component={innerComponent as ComponentsContainer} {...setValueProps} api={api} />
          );
        case FrontendComponentEnum.TextAreaField:
          return (
            <TextareaFieldElement component={innerComponent as TextAreaField} {...setValueProps} />
          );
        case FrontendComponentEnum.TextField:
        case FrontendComponentEnum.MailField:
        case FrontendComponentEnum.PhoneField:
          return (
            <TextFieldElement component={innerComponent as TextField} {...setValueProps} />
          );
        case FrontendComponentEnum.PasswordField:
          return (
            <PasswordFieldElement component={innerComponent as TextField} {...setValueProps} />
          );
        case FrontendComponentEnum.DateField:
          return (
            <DatepickerFieldElement component={innerComponent as DateField} {...setValueProps} />
          );
        case FrontendComponentEnum.CostField:
        case FrontendComponentEnum.CostAreaField:
          return (
            <CostFieldElement component={innerComponent as CostField} {...setValueProps} />
          );
        case FrontendComponentEnum.SlideAreaField:
          return (
            <SliderInputFieldElement component={innerComponent as AreaField} {...setValueProps } />
          );
        case FrontendComponentEnum.AreaField:
          return (
            <AreaFieldElement component={innerComponent as AreaField} {...setValueProps} />
          );
        case FrontendComponentEnum.RangeField:
          return (
            <RangeFieldElement component={innerComponent as RangeField} {...setValueProps} />
          );
        case FrontendComponentEnum.DecisionWithChildren:
          return (
            <DecisionWithChildrenFieldElement
              component={innerComponent as DecisionWithChildrenContainer}
              {...setValueProps}
            />
          );
        case FrontendComponentEnum.PopupWithVariants:
          return (
            <PopupContainerFieldElement component={innerComponent as PopupContainer} {...setValueProps} />
          );
        case FrontendComponentEnum.ExpandedConditionalBox:
          return (
            <VariantContainerFieldElement component={innerComponent as VariantContainer} {...setValueProps} />
          );
        case FrontendComponentEnum.RatingBox:
          return (
            <RatingStaticElement component={innerComponent as RatingStatic} />
          );
        case FrontendComponentEnum.SimpleDescriptionBox:
          return (
            <DescriptionStaticElement component={innerComponent as DescriptionStatic} />
          );
        case FrontendComponentEnum.StaticImageElement:
          return (
            <ImageStaticElement component={innerComponent as ImageStatic} />
          );
        case FrontendComponentEnum.Decision:
          return (
            <DecisionFieldElement component={innerComponent as DecisionField} {...setValueProps} />
          );
        case FrontendComponentEnum.Select:
          return (
            <SelectFieldElement component={innerComponent as SelectField} {...setValueProps} />
          );
        case FrontendComponentEnum.ClickNumberField:
          return (
            <ClickNumberFieldElement component={innerComponent as ClickNumberField} {...setValueProps} />
          );
        case FrontendComponentEnum.PasswordContainer:
          return (
            <PasswordContainerFieldElement component={innerComponent as PasswordContainer} {...setValueProps} />
          );
        case FrontendComponentEnum.RadiusContainer:
          return (
            <RadiusContainerFieldElement component={innerComponent as RadiusContainer} {...setValueProps} />
          );
        case FrontendComponentEnum.FileField:
          return (
            <ImageUploadFieldElement component={innerComponent as FileField} {...setValueProps} api={api} />
          );
        case FrontendComponentEnum.ColorPickerField:
          return (
            <ColorPickerFieldElement component={innerComponent as ColorPickerField} {...setValueProps} />
          );
        case FrontendComponentEnum.ButtonStatic:
          const buttonStatic: ButtonStatic = innerComponent as ButtonStatic;

          return (
            <Button
              text={t(buttonStatic.text || '')}
            />
          );
        case FrontendComponentEnum.Slides:
          return (
            <SlidesFieldElement component={innerComponent as FieldsContainer} {...setValueProps} />
          );
        case FrontendComponentEnum.Checkbox:
          return (
            <CheckboxFieldElement component={innerComponent as CheckboxField} {...setValueProps} />
          );
        case FrontendComponentEnum.Map:
          return (
            <MapFieldElement component={innerComponent as MapContainer} {...setValueProps} />
          );
        case FrontendComponentEnum.MultiSelect:
          return (
            <MultiselectFieldElement component={innerComponent as MultiSelectField} {...setValueProps} />
          );
        case FrontendComponentEnum.ClickNumberFieldWithIcon:
          return (
            <ClickNumberWithIconFieldElement component={innerComponent as ClickNumberWithIconField} {...setValueProps} />
          );
        case FrontendComponentEnum.ChooseVariantFromPopup:
          return (
            <ChooseVariantFromPopupFieldElement component={innerComponent as ChooseVariantFromPopupField} {...setValueProps} />
          );
        case FrontendComponentEnum.ChooseVariantFromSelectWithDecision:
          return (
            <ChooseVariantFromSelectWithDecisionFieldElement component={innerComponent as ChooseVariantField} {...setValueProps} />
          );
        case FrontendComponentEnum.SelectItemsBox:
          return (
            <SelectItemsBoxFieldElement component={innerComponent as SelectItemsContainer} {...setValueProps} />
          );
        case FrontendComponentEnum.SelectItem:
          return (
            <SelectItemFieldElement component={innerComponent as SelectItemField} {...setValueProps} />
          );
        case FrontendComponentEnum.ChooseOptionFromBoxes:
          return (
            <ChooseOptionFromBoxesFieldElement component={innerComponent as SelectField} {...setValueProps} />
          );
        default:
          return (
            <div>{`Component: ${innerComponent.frontendComponent}`}</div>
          );
      }
    },
    [innerComponent.valid, innerComponent.available]
  );

  return (
    <>
      {getField()}
      {!!JSON.parse(process.env.REACT_APP_SHOW_FIELDS_ID || 'false') && (
        <IdContainer>ID: {(innerComponent as AbstractField).id} | Component: {innerComponent.frontendComponent}</IdContainer>
      )}
    </>
  );
};
