import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { StyledComponent } from '../../../../types/styledComponent.types';
import { Color } from '../../../../enums/color.enum';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { IncludedCheck } from '../../../../components/includedCheck/includedCheck.component';
import { styleWhenTrue } from '../../../../utils/style.utils';

import { DashWithItemProps, ItemElementsProps } from './elementsWithDash.types';

export const ItemElements: StyledComponent<'div', ItemElementsProps> = styled.div<ItemElementsProps>`
  display: flex;
  flex-direction: column;
  padding-left: 70px;

  @media ${Breakpoint.Mobile} {
    padding-left: 27px;
  }

  ${styleWhenTrue(prop('withBiggerPadding'), css`
    padding-left: 75px;
  `)}

`;

export const ItemElement: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 8px;
  }
`;

export const DashWithItem: StyledComponent<'div', DashWithItemProps> = styled.div<DashWithItemProps>`
  display: flex;
  align-items: center;
  font-size: 16px;

  ${styleWhenTrue(prop('withBiggerFonts'), css`
    font-size: 20px;
  `)}
`;

export const Dash: StyledComponent<'span'> = styled.span`
  width: 12px;
  height: 3px;
  background-color: ${Color.Black};
  margin-right: 12px;
  display: block;
  flex-shrink: 0;
`;

export const StyledIncludedCheck: StyledComponent<typeof IncludedCheck> = styled(IncludedCheck)`
  flex-shrink: 0;
  margin-left: 10px;
`;
