import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { hexToRgba } from '../../utils/colors.utils';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';
import { Markdown } from '../markdown/markdown.component';

import { ErrorContentProps } from './validationBar.types';

export const Container: StyledComponent<'div'> = styled.div`
  background-color: ${hexToRgba(Color.Pink, 0.05)};
  margin-top: 10px;
  overflow: hidden;
  transition: height 0.1s ease-in-out;
`;

export const ErrorList: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid ${hexToRgba(Color.Pink, 0.35)};
`;

export const ErrorItem: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${Color.ValidationFont};
  font-size: 14px;
  line-height: 17px;
  position: relative;
  & + & {
    margin-top: 5px;
  }
`;

export const ErrorContent: StyledComponent<typeof Markdown, ErrorContentProps> = styled(Markdown)<ErrorContentProps>`
  ${styleWhenTrue(
    prop('hasIndent'),
    css`
      padding-left: 10px;
    `
  )}

  a {
    color: ${Color.Black};
    font-weight: ${FontWeight.Bold};
  }
`;

export const Dot: StyledComponent<'span'> = styled.span`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${Color.ValidationFont};
  margin-right: 5px;
  position: absolute;
  top: 7px;
`;
