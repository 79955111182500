import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { StyledComponent } from '../../types/styledComponent.types';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { GlobalPadding } from '../../enums/globalPadding.enum';
import { GridWidth } from '../../enums/gridWidth.enum';
import { ZIndex } from '../../enums/zIndex.enum';
import { styleWhenTrue } from '../../utils/style.utils';
import { AdditionalInfo } from '../additionalInfo/additionalInfo.component';
import { Icon } from '../icon/icon.component';

import { CloseButtonProps, ContainerProps, InnerContainerProps, TopSectionProps } from './fullscreenPopup.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  max-height: 100%;
  width: 100%;
  z-index: ${ZIndex.FullscreenPopup};
  background-color: ${Color.BgGrey};
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  overflow-y: auto;
  ${styleWhenTrue(
    prop('isVisible'),
    css`
      opacity: 1;
      pointer-events: initial;
    `
  )};
`;

export const CloseButton: StyledComponent<'button', CloseButtonProps> = styled.button`
  position: ${({ isFixed }: CloseButtonProps): string => isFixed ? 'fixed' : 'absolute'};
  top: 13px;
  right: 20px;
  z-index: ${({ isFixed }: CloseButtonProps): string => isFixed ? ZIndex.FixedX : ZIndex.Minimum};
`;

export const CloseIcon: StyledComponent<typeof Icon> = styled(Icon)``;

export const TopSection: StyledComponent<'div', TopSectionProps> = styled.div<TopSectionProps>`
  display: flex;
  flex-direction: column;

  ${styleWhenTrue(
    prop('withMarginBottom'),
    css`
      margin-bottom: 40px;
    `
  )}
`;

export const CloseBarInner: StyledComponent<'div'> = styled.div`
  border-bottom: 1px solid ${Color.GreyStroke};
  padding: 20px 0;
`;

export const CloseBar: StyledComponent<'div'> = styled.div`
  z-index: ${ZIndex.Minimum};
  margin-bottom: 50px;
  max-width: ${GridWidth.Desktop};
`;

export const InnerContainer: StyledComponent<'div', InnerContainerProps> = styled.div`
  position: relative;
  max-width: ${GridWidth.Desktop};
  min-height: calc(100vh - 56px);
  padding: 50px ${GlobalPadding.Desktop} 105px;
  margin: 0 auto;

  @media ${Breakpoint.Additional1280} {
    padding: 50px 40px 105px;
  }

  @media ${Breakpoint.Tablet} {
    padding: 50px 30px 105px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 50px 20px 105px;
  }

  ${styleWhenTrue(
    prop('topPaddingDisabled'),
    css`
      padding-top: 0 !important;
      min-height: calc(100% - 161px);
      ${CloseBar} {
        padding-top: 0 !important;
      }
    `
  )}

  ${styleWhenTrue(
    prop('paddingDisabled'),
    css`
      padding: 0;
      max-width: none;

      ${TopSection},
      ${CloseBar} {
        max-width: ${GridWidth.Desktop};
        padding: 50px 170px 0px;
        margin: 0 auto;
      }

      @media ${Breakpoint.Additional1280} {
        padding: 0;

        ${TopSection},
        ${CloseBar} {
          padding: 50px 40px 0;
        }
      }

      @media ${Breakpoint.Tablet} {
        padding: 0;

        ${TopSection},
        ${CloseBar} {
          padding: 50px 30px 0;
        }
      }

      @media ${Breakpoint.Mobile} {
        padding: 0;

        ${TopSection},
        ${CloseBar} {
          padding: 50px 20px 0;
        }
      }
    `
  )};
`;

export const CustomAdditionalInfo: StyledComponent<typeof AdditionalInfo> = styled(AdditionalInfo)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const HeadersContainer: StyledComponent<'div'> = styled.div`
  margin-bottom: 25px;
`;

export const Header: StyledComponent<'h3'> = styled.h3`
  font-weight: ${FontWeight.Bold};
  font-size: 28px;
  line-height: 33px;
  color: ${Color.Black};
`;

export const Subheader: StyledComponent<'h4'> = styled.h4`
  font-weight: ${FontWeight.Light};
  font-size: 18px;
  line-height: 22px;
  color: ${Color.Grey};
  margin-top: 5px;
`;

export const Content: StyledComponent<'div'> = styled.div``;
