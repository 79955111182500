import { MutableRefObject, useEffect, useRef } from 'react';

export const useEffectAfterMount: (
  effect: () => void,
  deps: unknown[]
) => void = (effect: () => void, deps: unknown[]): void => {
  const justMounted: MutableRefObject<boolean> = useRef<boolean>(true);

  useEffect(
    (): void => {
      if (justMounted.current) {
        justMounted.current = false;

        return;
      }

      return effect();
    },
    deps
  );
};
