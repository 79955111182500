import React, { useMemo } from 'react';

import { CalculationSimulationModel } from '@rehau/shared/models/calculation/response';
import { PriceSimulationBar } from '../priceSimulationBar/priceSimulationBar.component';
import { ForcedLanguageTranslationContextType } from '../../../../types/forcedLanguageTranslationContextType.types';
import { useForcedLanguageTranslationContext } from '../../../../hooks/useForceLanguageTranslation.hook';

import {
  BarsSection,
  Container,
  PriceLabel,
  PricesSection,
  SimulationPriceRow,
  StyledPrice,
  TaxInfo,
  TotalPrice,
  TotalPriceValue
} from './priceSimulations.styled';
import { PriceSimulationsProps } from './priceSimulations.types';

export const PriceSimulations: React.FC<PriceSimulationsProps> = (props: PriceSimulationsProps): JSX.Element => {
  const { totalPrice, simulations, className, withBiggerFonts = false }: PriceSimulationsProps = props;
  const { t }: ForcedLanguageTranslationContextType = useForcedLanguageTranslationContext();
  const priceSimulationSectionVisible: boolean = useMemo(
    (): boolean => (
      simulations.length === 3 && !(
        (simulations[1].price <= simulations[0].price)
        || (simulations[2].price <= simulations[1].price)
        || (simulations[2].price <= simulations[0].price)
      )
    ),
    [simulations]
  );

  return (
    <Container className={className}>
      {priceSimulationSectionVisible && (
        <BarsSection>
          <PriceSimulationBar
            leftPrice={0}
            rightPrice={simulations[0].price}
            label={t('rehau.global.classic')}
            price={totalPrice}
          />
          <PriceSimulationBar
            leftPrice={simulations[0].price + 1}
            rightPrice={simulations[1].price}
            label={t('rehau.global.smart')}
            price={totalPrice}
          />
          <PriceSimulationBar
            leftPrice={simulations[1].price + 1}
            rightPrice={simulations[2].price}
            label={t('rehau.global.premium')}
            price={totalPrice}
            isLast
          />
        </BarsSection>
      )}
      <PricesSection $fullWidth={!priceSimulationSectionVisible}>
        <TotalPrice>
          <PriceLabel withBiggerFonts={withBiggerFonts}>{t('rehau.components.offer_summary.total_price')}</PriceLabel>
          <TotalPriceValue>
            <StyledPrice price={totalPrice} priceSize={withBiggerFonts ? 24 : 18} />
            <TaxInfo withBiggerFonts={withBiggerFonts}>{t('rehau.components.offer_summary.tax_included')}</TaxInfo>
          </TotalPriceValue>
        </TotalPrice>
        {priceSimulationSectionVisible && simulations.map((simulation: CalculationSimulationModel, index: number): JSX.Element => (
          <SimulationPriceRow key={index}>
            <PriceLabel withBiggerFonts={withBiggerFonts}>{t(simulation.variantTranslation)}:</PriceLabel>
            <StyledPrice price={simulation.price} priceSize={withBiggerFonts ? 24 : 18} />
          </SimulationPriceRow>
        ))}
      </PricesSection>
    </Container>
  );
};
