import styled from 'styled-components';

import { StyledComponent } from '../../types/styledComponent.types';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { ValidationBar } from '../validationBar/validationBar.component';
import { Breakpoint } from '../../enums/breakpoint.enum';

import { ButtonProps, ValueInputProps } from './sliderInput.types';

export const AreaText: StyledComponent<'p'> = styled.p`
  position: relative;
  font-weight: ${FontWeight.Black};
  color: ${Color.Black};
  font-size: 16px;
  line-height: 19px;
  width: fit-content;
`;

export const Sup: StyledComponent<'sup'> = styled.sup`
  position: absolute;
  font-size: 8px;
  top: -4px;
  right: -4px;
`;

export const GreyAreaText: StyledComponent<typeof AreaText> = styled(AreaText)`
  color: ${Color.Grey};
  font-size: 12px;
  line-height: 14px;
  font-weight: ${FontWeight.Regular};
`;

export const AreaTextContainer: StyledComponent<'div'> = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: -20px;
`;

export const ValueInput: StyledComponent<'input', ValueInputProps> = styled.input`
  font-weight: ${FontWeight.Black};
  color: ${Color.Black};
  font-size: 16px;
  line-height: 19px;
  min-width: 0;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;

  width: ${({ valueLength }: ValueInputProps): string =>
    valueLength === 1 || valueLength === 0 ? '12px' : valueLength === 2 ? '20px' : '29px'};
`;

export const SliderContainer: StyledComponent<'div'> = styled.div`
  position: relative;
  width: 100%;
`;

export const ValueContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  top: -15px;
  transform: translateX(-50%);
`;

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  @media ${Breakpoint.Mobile} {
    margin-top: 15px;
  }
`;

export const FormContainer: StyledComponent<'div'> = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  max-width: 340px;
  width: 100%;
`;

export const TooltipContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
`;

export const StyledRangeInput: StyledComponent<'input'> = styled.input`
  --webkitProgressPercent: 0%;
  --progressBg: ${Color.Green};
  --trackBg: ${Color.GreyStroke};
  width: 100%;
  max-width: 340px;

  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  background: transparent; /* Otherwise white in Chrome */

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    background: ${Color.White};
    cursor: pointer;
    border: 4px solid ${Color.Green};
    background-color: ${Color.White};
    margin-top: -5px;
  }

  ::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    height: 16px;
    width: 16px;
    background: ${Color.White};
    cursor: pointer;
    border-radius: 0;
    border: 4px solid ${Color.Green};
    background-color: ${Color.White};
    box-sizing: border-box;
    margin-top: -5px;
  }

  ::-ms-thumb {
    -ms-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    background: ${Color.White};
    cursor: pointer;
    border: 4px solid ${Color.Green};
    background-color: ${Color.White};
    margin-top: -5px;
  }

  :focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  ::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    background-image: linear-gradient(90deg, var(--progressBg) var(--webkitProgressPercent), var(--trackBg) var(--webkitProgressPercent));
  }

  ::-ms-track {
    width: 100%;
    cursor: pointer;
    height: 6px;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
    background-color: var(--trackBg);
  }

  ::-moz-range-track {
    height: 6px;
    background-color: var(--trackBg);
  }

  /* Progress */
  ::-moz-range-progress {
    height: 6px;
    background-color: var(--progressBg);
  }
  ::-ms-fill-lower {
    height: 6px;
    background-color: var(--progressBg);
  }
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  width: 100%;
`;

export const Button: StyledComponent<'button', ButtonProps> = styled.button<ButtonProps>`
  width: 44px;
  height: 44px;
  border: 1px solid ${Color.GreyStroke};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  ${({ isLast }: ButtonProps): string => isLast ? 'margin-left: 15px;' : 'margin-right: 15px;'}
`;
