import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { FontWeight } from '../../../../enums/fontWeight.enum';
import { Color } from '../../../../enums/color.enum';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { InnerContainer, OuterContainer } from '../../../../theme/containers.styled';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { styleIfElse, styleWhenTrue } from '../../../../utils/style.utils';
import { Title as TitleBase } from '../title/title.component';
import { Markdown } from '../../../markdown/markdown.component';

import {
  ColumnProps,
  ColumnTitleProps,
  ContainerProps,
  ImageProps,
  TextWrapperProps,
} from './textWithColumns.types';

export const Container: StyledComponent<typeof OuterContainer, ContainerProps> = styled(OuterContainer)`
  padding-top: 80px;
  padding-bottom: 100px;

  @media ${Breakpoint.Tablet} {
    margin: 0;
    width: 100%;
    padding: 60px 40px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 60px 20px;
  }

  ${styleWhenTrue(prop('darkBackground'), css`
    background-color: ${Color.BgGrey};
  `)}
`;

export const Wrapper: StyledComponent<typeof InnerContainer> = styled(InnerContainer)`
  flex-direction: column;
`;

export const StyledMarkdown: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 40px;

  strong {
    font-weight: ${FontWeight.Bold};
  }
`;

export const ColumnsTitle: StyledComponent<typeof TitleBase> = styled(TitleBase)`
  @media ${Breakpoint.Tablet} {
    max-width: 490px;
  }
`;

export const Columns: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -12.5px;
  overflow: hidden;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
    margin: 0;
  }
`;

export const Column: StyledComponent<'div', ColumnProps> = styled.div`
  flex: 1;
  margin: 0 12.5px;

  @media ${Breakpoint.Tablet} {
    margin: 0 0 40px;

    ${styleWhenTrue(prop('isDescriptionColumn'), css`
      margin: 0 0 12px;
    `)}

    ${styleWhenTrue(prop('isIconBoxColumn'), css`
      margin: 0 0 12px;
    `)}

    :last-of-type {
      margin: 0;
    }
  }
`;

export const Box: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  line-height: 42px;
  font-weight: ${FontWeight.Black};
  color: ${Color.White};
  background-color: ${Color.Green};
  margin-bottom: 25px;
`;

export const Image: StyledComponent<'img', ImageProps> = styled.img`
  width: 100%;
  margin-bottom: 25px;

  @media ${Breakpoint.Tablet} {
    display: block;
    margin: 0 auto 25px;
    max-width: ${styleIfElse(prop('fullWidth'), '100%', '298px')};
  }

  @media ${Breakpoint.Mobile} {
    max-width: ${styleIfElse(prop('fullWidth'), '100%', '335px')};
  }
`;

export const TextWrapper: StyledComponent<'div', TextWrapperProps> = styled.div`
  ${styleWhenTrue(prop('isDescriptionColumn'), css`
    background-color: ${Color.Black};
    box-sizing: border-box;
    padding: 25px;
    color: ${Color.White};
    height: 100%;
  `)}

  ${styleWhenTrue(prop('isIconBoxColumn'), css`
    background-color: ${Color.White};
    box-sizing: border-box;
    padding: 25px;
    height: 100%;
  `)}

  ${styleWhenTrue(prop('isImageWithBoxColumn'), css`
    background-color: ${Color.Green};
    color: ${Color.White};
    box-sizing: border-box;
    padding: 25px;
    height: 100%;
  `)}
`;

export const IconContainer: StyledComponent<'div'> = styled.div`
  height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ColumnTitle: StyledComponent<'h6', ColumnTitleProps> = styled.h6`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
  font-weight: ${FontWeight.Bold};

  ${styleWhenTrue(prop('isDescriptionColumn'), css`
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.27px;
  `)}
`;

export const ColumnText: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  line-height: 24px;
`;

export const Hint: StyledComponent<'p'> = styled.p`
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.14px;
  margin-top: 40px;
  color: ${Color.Grey};
`;
