import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { IconName } from '../../../../../../../../shared/enums';
import { Color } from '../../../../enums/color.enum';
import { Overlay } from '../../../../theme/overlay/overlay.styled';
import { Icon } from '../../../icon/icon.component';

import { useVideoPlayer } from './hooks/useVideoPlayer';
import {
  CloseButton,
  CloseIcon,
  FullScreenButton,
  PlayPauseButton,
  Video,
  VideoIcon,
  VideoLabel,
  VideoLabelIconContainer,
  Wrapper
} from './videoPlayer.styled';
import { UseVideoPlayerOutput, VideoPlayerProps } from './videoPlayer.types';

export const VideoPlayer: React.FC<VideoPlayerProps> = (props: VideoPlayerProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    src,
    color,
    videoLabel = t('rehau.components.video_player.label'),
  }: VideoPlayerProps = props;
  const fullscreenEnabled: boolean = document.fullscreenEnabled;
  const {
    ref,
    openVideo,
    closeVideo,
    toggleFullscreen,
    toggleIsPaused,
    isOpen,
    isPaused,
  }: UseVideoPlayerOutput = useVideoPlayer();

  return (
    <>
      <VideoLabelIconContainer data-testid='video-icon' onClick={openVideo}>
        <VideoLabel>{videoLabel}</VideoLabel>
        <VideoIcon name={IconName.Youtube} size={43} color={color} />
      </VideoLabelIconContainer>
      <Wrapper isOpen={isOpen} data-testid='video-wrapper'>
        <CloseButton onClick={closeVideo} aria-label='close'>
          <CloseIcon name={IconName.No} size={15} color={Color.Black} />
        </CloseButton>
        <PlayPauseButton onClick={toggleIsPaused} isPaused={isPaused} aria-label='play' data-testid='play'>
          <Icon name={isPaused ? IconName.Play : IconName.Pause} size={24} color={Color.White} />
        </PlayPauseButton>

        {fullscreenEnabled && (
          <FullScreenButton onClick={toggleFullscreen} data-testid='fullscreen'>
            <Icon name={IconName.Fullscreen} size={15} color={Color.White} />
          </FullScreenButton>
        )}

        <Video ref={ref} data-testid='video'>
          <source src={src} type='Video/mp4' />
        </Video>
      </Wrapper>
      <Overlay isActive={isOpen} data-testid='overlay' />
    </>
  );
};
