import { InfoBoxPositionEnum } from '@rehau/shared/enums';

export class InfoBox {
  public content: string;
  public readMoreContent?: string;
  public position: InfoBoxPositionEnum;

  constructor(
    content: string,
    readMoreContent?: string,
    position: InfoBoxPositionEnum = InfoBoxPositionEnum.Top
  ) {
    this.content = content;
    this.readMoreContent = readMoreContent;
    this.position = position;
  }
}
