import styled, { css, FlattenInterpolation, FlattenSimpleInterpolation, ThemeProps } from 'styled-components';
import { prop, propEq } from 'ramda';

import { Icon as IconBase } from '../../../icon/icon.component';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { Color } from '../../../../enums/color.enum';
import { ZIndex } from '../../../../enums/zIndex.enum';
import { BoxShadow } from '../../../../enums/boxShadow.enum';
import { InnerContainer, OuterContainer } from '../../../../theme/containers.styled';
import { StyledComponent } from '../../../../types/styledComponent.types';
import { styleWhenTrue } from '../../../../utils/style.utils';

import { IconProps, SelectedOptionProps, TabProps } from './tabs.types';

export const Container: StyledComponent<typeof OuterContainer> = styled(OuterContainer)`
  position: sticky;
  top: 0;
  z-index: ${ZIndex.Minimum};

  @media ${Breakpoint.Tablet} {
    margin: 0;
    width: 100%;
    padding: 0 40px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 0 20px;
  }
`;

export const Wrapper: StyledComponent<typeof InnerContainer> = styled(InnerContainer)`
  flex-wrap: wrap;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
  }
`;

export const Dropdown: StyledComponent<'div'> = styled.div`
  position: relative;
  width: 100%;
`;

const optionStyles: FlattenSimpleInterpolation = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  width: 100%;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 24px;
  box-sizing: border-box;
  cursor: pointer;
`;

const commonOptionStyles: FlattenSimpleInterpolation = css`
  background-color: ${Color.White};
  ${optionStyles}

  :hover {
    background-color: ${Color.Green};
    color: ${Color.White};
  }
`;

export const SelectedOption: StyledComponent<'button', SelectedOptionProps> = styled.button`
  background-color: ${Color.Black};
  color: ${Color.White};
  ${optionStyles}
  transition: 0.2s;

  ${styleWhenTrue(prop('open'), css`
    background-color: ${Color.GreyStroke};
    color: ${Color.Black};
  `)}
`;

export const IconWrapper: StyledComponent<'div'> = styled.div`
  transform: rotate(90deg);
`;

export const Icon: StyledComponent<typeof IconBase, IconProps> = styled(IconBase)`
  transition: 0.2s;
  color: ${Color.White};

  ${styleWhenTrue(prop('open'), css`
    transform: rotateY(180deg);
    color: ${Color.Black};
  `)}
`;

export const List: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 44px;
  box-shadow: ${BoxShadow.BtnGreyShadow};
`;

const commonTabsStyles: FlattenInterpolation<ThemeProps<TabProps>> = css`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 24px;
  transition: 0.2s;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;

  ${styleWhenTrue(propEq('active', 'true'), css`
    background-color: ${Color.Black};
    color: ${Color.White};
  `)}

  :hover {
    color: ${Color.Pink};

    ${styleWhenTrue(propEq('active', 'true'), css`
      color: ${Color.White};
    `)}
  }
`;

export const CustomOptionTab: StyledComponent<'button', TabProps> = styled.button`
  ${commonOptionStyles};
`;

export const CustomButtonTab: StyledComponent<'button', TabProps> = styled.button`
  ${commonTabsStyles};
`;
