export enum ContainerType {
  Checkbox = 'checkbox',
  PopupWithVariants = 'popupWithVariants',
  Standard = 'standard',
  ExpandedConditionalBox = 'expandedConditionalBox',
  HorizontalFieldline = 'horizontalFieldline',
  ConditionalAvailable = 'conditionalAvailable',
  PasswordContainer = 'passwordContainer',
  ChooseInstallerContainer = 'chooseInstallerContainer',
}
