import styled, { StyledComponent } from 'styled-components';
import SVG from 'react-inlinesvg';

import { FontWeight } from '../../../enums/fontWeight.enum';
import { Color } from '../../../enums/color.enum';

export const Icon: StyledComponent<typeof SVG, {}, {}, never> = styled(SVG)`
  margin-right: 12px;
  fill: {
    color: ${Color.Pink};
  }
`;

export const Currency: StyledComponent<'p', {}, {}, never> = styled.p`
  font-size: 18px;
  font-weight: ${FontWeight.Bold};
  margin-right: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
`;

export const Slash: StyledComponent<'span', {}, {}, never> = styled.span`
  margin-right: 5px;
`;

export const AreaUnit: StyledComponent<'p', {}, {}, never> = styled.p`
  position: relative;
  font-size: 17px;
  color: ${Color.Grey};
  display: flex;
  align-items: center;
`;

export const Sup: StyledComponent<'sup', {}, {}, never> = styled.sup`
  font-size: 8px;
  position: absolute;
  top: 30%;
  right: -25%;
`;
