import React, { forwardRef, useRef } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';

import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { fieldValueType } from '@rehau/shared/forms';
import { ProductTableNameEnum } from '@rehau/shared/enums';
import { ProductTable } from '../../../../components/productTable/productTable.component';
import { ConfiguratorPreview } from '../../../../components/configuratorPreview/configuratorPreview.component';
import { UseCountryConfig } from '../../../../types/useCountryConfig.types';
import { useCountryConfig } from '../../../../hooks/useCountryConfig.hook';
import { Breakpoint } from '../../../../enums/breakpoint.enum';
import { FullWidthImage } from '../../components/fullWidthImage/fullWidthImage.component';
import { TextWithImage } from '../../components/textWithImage/textWithImage.component';
import { Tabs } from '../../components/tabs/tabs.component';
import { TextWithVideo } from '../../components/textWithVideo/textWithVideo.component';
import WomanWithDogImage from '../../images/womanWithDog.jpg';
import WomanOnStairsImage from '../../images/womanOnStairs.jpg';
import ManualRegulatorImage from '../../images/manualRegulator.jpg';
import WomanWithHeadphonesImage from '../../images/womanWithHeadphones.jpg';
import ManWithPhoneImage from '../../images/manWithPhone.jpg';
import ChartImage from '../../images/temperatureChart.png';
import GentlemanImage from '../../images/gentleman.jpg';
import RegulatorBlackImage from '../../images/regulatorBlack.png';
import RegulatorWhiteImage from '../../images/regulatorWhite.png';
import TemperatureInImage from '../../images/temperatureIn.png';
import TemperatureOutImage from '../../images/temperatureOut.png';
import WifiIcon from '../../images/wifiIcon.png';
import TemperatureIcon from '../../images/temperatureIcon.png';
import SavingIcon from '../../images/savingIcon.png';
import DiamondIcon from '../../images/diamondIcon.png';
import PhoneIcon from '../../images/phoneIcon.png';
import { UseActiveTab, UseCalculatorNavigate } from '../../products.types';
import { useActiveTab } from '../../hooks/useActiveTab.hooks';
import { sectionVisibilityThreshold } from '../../products.constants';
import { ProductPopupContext } from '../../../../contexts/productPopup.context';
import { OptionTab } from '../../components/tabs/tabs.types';
import { useCalculatorNavigate } from '../../hooks/useCalculatorNavigate.hook';

import { Container, TableContainer, TableWrapper } from './temperatureRegulator.styled';
import { TemperatureRegulatorProps } from './temperatureRegulator.types';
import { SectionId } from './temperatureRegulator.enums';

export const TemperatureRegulator: React.FC<TemperatureRegulatorProps> = forwardRef((
  props: TemperatureRegulatorProps, ref: React.ForwardedRef<{}>
): JSX.Element => {
  const { className, tabsOnTop, setTabsOnTop, isInPopup = false }: TemperatureRegulatorProps = props;
  const { t }: TransProps<never> = useTranslation();
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();
  const products: ObjectIteratorInterface<fieldValueType> = isConfigAvailable ? config.variables.products : {};
  const [activeTabId, setActiveTabId]: UseActiveTab = useActiveTab('');
  const { navigateToCalculator }: UseCalculatorNavigate = useCalculatorNavigate();
  const manualRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const smartRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);
  const calculatorRef: React.RefObject<InView & HTMLDivElement> = useRef<InView & HTMLDivElement>(null);

  const getCurrentRefByActiveId: (activeTab: string) => React.RefObject<InView & HTMLDivElement> =
    (activeTab: string): React.RefObject<InView & HTMLDivElement> => {
      switch (activeTab) {
        case SectionId.Manual:
          return manualRef;
        case SectionId.Smart:
          return smartRef;
        default:
          return calculatorRef;
      }
    };

  const handleScroll: (tabId: string) => void = (tabId: string): void => {
    const activeRef: React.RefObject<InView & HTMLDivElement> = getCurrentRefByActiveId(tabId);
    activeRef.current?.node?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ProductPopupContext.Provider value={{ isInPopup }}>
      <Container className={className} data-testid='temperature-regulator' ref={ref as React.RefObject<HTMLDivElement>}>
        <FullWidthImage src={WomanWithDogImage} alignTop />
        <TextWithImage
          sectionTitle={t('rehau.products.temperature_regulator.header.title')}
          text={t('rehau.products.temperature_regulator.header.content')}
          imageSrcList={[WomanOnStairsImage]}
        />
        <Tabs
          activeId={activeTabId}
          options={[
            { label: t('rehau.products.temperature_regulator.tabs.manual_regulation'), id: SectionId.Manual },
            { label: t('rehau.products.temperature_regulator.tabs.smart_regulation'), id: SectionId.Smart },
            !isInPopup ? { label: t('rehau.products.heat.tabs.calculator'), id: SectionId.Calculator } : {} as OptionTab,
          ]}
          isOnTop={tabsOnTop}
          setOnTop={setTabsOnTop}
          handleScroll={handleScroll}
        />
        <InView
          as='div'
          threshold={sectionVisibilityThreshold}
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Manual)}
          id={SectionId.Manual}
          ref={manualRef}
        >
          <TextWithImage
            title={t('rehau.products.temperature_regulator.manual.title')}
            text={t('rehau.products.temperature_regulator.manual.content')}
            imageSrcList={[ManualRegulatorImage]}
            darkBackground
          />
        </InView>
        <InView
          as='div'
          threshold={0.1}
          onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Smart)}
          id={SectionId.Smart}
          ref={smartRef}
        >
          <TextWithVideo
            videoId={String(products['temperature_regulator.sections.smart.video.videoHash'])}
            text={t('rehau.products.temperature_regulator.smart.video.content')}
            title={t('rehau.products.temperature_regulator.smart.video.title')}
            columnsTitle={t('rehau.products.temperature_regulator.smart.video.advantages')}
            columnsData={[
              { text: t('rehau.products.temperature_regulator.smart.video.comfort'), imageSrc: TemperatureIcon },
              { text: t('rehau.products.temperature_regulator.smart.video.pattern'), imageSrc: DiamondIcon },
              { text: t('rehau.products.temperature_regulator.smart.video.app'), imageSrc: PhoneIcon },
              { text: t('rehau.products.temperature_regulator.smart.video.savings'), imageSrc: SavingIcon },
              { text: t('rehau.products.temperature_regulator.smart.video.future'), imageSrc: WifiIcon },
            ]}
          />
          <TextWithImage
            title={t('rehau.products.temperature_regulator.smart.comfort.title')}
            text={t('rehau.products.temperature_regulator.smart.comfort.content')}
            imageSrcList={[WomanWithHeadphonesImage]}
            reversed={!isTablet}
            wideImage
            hintImageSrc={ChartImage}
            withoutTopPadding
            imageOnTop
          />
          <FullWidthImage src={ManWithPhoneImage} />
          <TextWithVideo
            videoId={String(products['temperature_regulator.sections.smart.geofencing.videoHash'])}
            title={t('rehau.products.temperature_regulator.smart.geofencing.title')}
            text={t('rehau.products.temperature_regulator.smart.geofencing.content')}
            hintTitle={t('rehau.products.temperature_regulator.smart.geofencing.hint_title')}
            hints={[
              {
                imageSrc: TemperatureInImage,
                text: t('rehau.products.temperature_regulator.smart.geofencing.temperature_in'),
              },
              {
                imageSrc: TemperatureOutImage,
                text: t('rehau.products.temperature_regulator.smart.geofencing.temperature_out'),
              },
            ]}
          />
          <TextWithImage
            title={t('rehau.products.temperature_regulator.smart.design.title')}
            text={t('rehau.products.temperature_regulator.smart.design.content')}
            reversed={!isTablet}
            wideImage
            imageSrcList={[GentlemanImage]}
            darkBackground
            hintImageSrc={RegulatorBlackImage}
            secondHintImageSrc={RegulatorWhiteImage}
            imageOnTop
          />
          <TableContainer>
            <TableWrapper>
              <ProductTable tableType={ProductTableNameEnum.TemperatureRegulator} tableTitle={t('rehau.products.temperature_regulator.table.title')} />
            </TableWrapper>
          </TableContainer>
        </InView>
        {!isInPopup && (
          <InView
            as='div'
            onChange={(inView: boolean): void => setActiveTabId(inView, SectionId.Calculator)}
            id={SectionId.Calculator}
            threshold={sectionVisibilityThreshold}
            ref={calculatorRef}
          >
            <ConfiguratorPreview buttonOnClick={navigateToCalculator} />
          </InView>
        )}
      </Container>
    </ProductPopupContext.Provider>
  );
});
