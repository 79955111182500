import { prop } from 'ramda';
import styled, { css, StyledComponent } from 'styled-components';

import { Color } from '../../../../enums/color.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { styleWhenTrue } from '../../../../utils/style.utils';

import { ItemTextProps } from './navigationItem.types';

export const Container: StyledComponent<'div', {}, {}, never> = styled.div`
  height: 100%;
  a {
    text-decoration: none;
  }
`;

export const Button: StyledComponent<'button', {}, {}, never> = styled.button`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  width: max-content;
  cursor: pointer;
  outline: none;
`;

export const ItemText: StyledComponent<'p', {}, ItemTextProps, never> = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${Color.Black};
  border-bottom: 3px solid ${Color.Transparent};
  padding: 19px 0 20px;

  ${styleWhenTrue(prop('isActive'), css`
    border-bottom: 3px solid ${Color.Green};
    color: ${Color.Black};
  `)};
`;

export const ActivityDot: StyledComponent<'div', {}, {}, never> = styled.div`
  background-color: ${Color.Green};
  width: 5px;
  height: 5px;
  position: absolute;
  top: 25%;
  right: 12px;
  border-radius: 50%;
`;
