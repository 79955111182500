import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { StyledComponent } from '../../types/styledComponent.types';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { styleWhenTrue } from '../../utils/style.utils';
import { Price } from '../../theme/price/price.component';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { BaseLabel } from '../../theme';

import { ActiveItemProps } from './select.types';

export const Label: StyledComponent<typeof BaseLabel> = styled(BaseLabel)`
  margin-bottom: 10px;
`;

export const RowLabel: StyledComponent<'p', ActiveItemProps> = styled.p`
  color: ${Color.Grey};
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
  ${styleWhenTrue(
    prop('isActive'),
    css`
      color: ${Color.Green};
      font-weight: ${FontWeight.Bold};
    `
  )}
`;

export const Wrapper: StyledComponent<'div'> = styled.div``;

export const BoxWrapper: StyledComponent<'div'> = styled.div`
  border: 1px solid ${Color.GreyStroke};
  padding: 23px 15px 23px 20px;
  box-sizing: border-box;
  width: 335px;

  @media ${Breakpoint.Additional480} {
    width: 300px;
  }

  @media ${Breakpoint.Additional375} {
    width: calc(100% - 35px);
  }
`;

export const TooltipContainer: StyledComponent<'div'> = styled.div`
  width: 22px;
  height: 22px;
`;

export const StyledPrice: StyledComponent<typeof Price> = styled(Price)`
  margin-right: 10px;
`;
