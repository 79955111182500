import styled from 'styled-components';

import { StyledComponent } from '../../types/styledComponent.types';
import { Icon } from '../icon/icon.component';
import { ZIndex } from '../../enums/zIndex.enum';
import { Color } from '../../enums/color.enum';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { FontWeight } from '../../enums/fontWeight.enum';

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)``;

export const IconInnerContainer: StyledComponent<'div'> = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: ${Color.Green};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transform: scale(0.9);
`;

export const IconContainer: StyledComponent<'button'> = styled.button`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${Color.White};
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  z-index: ${ZIndex.IconTrigger};
`;

export const Tooltip: StyledComponent<'div'> = styled.div`
  position: absolute;
  display: flex;
  left: -10px;
  transform: translateX(-100%);
  background-color: ${Color.Black};
  pointer-events: none;
  transition: 0.2s;
  padding: 9px 10px;

  :after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent ${Color.Black};
  }

  @media ${Breakpoint.Mobile} {
    display: none;
  }
`;

export const TooltipText: StyledComponent<'p'> = styled.p`
  position: relative;
  font-size: 12px;
  line-height: 16px;
  font-weight: ${FontWeight.Medium};
  color: ${Color.White};
  width: max-content;
`;
