import styled from 'styled-components';

import { GridWidth } from '../../enums/gridWidth.enum';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { PhotoOverlay } from '../../theme/photoOverlay.styled';
import { StyledComponent } from '../../types/styledComponent.types';
import { FlatBox } from '../flatBox/flatBox.component';
import { GlobalPadding } from '../../enums/globalPadding.enum';
import { Icon } from '../icon/icon.component';
import { Markdown } from '../markdown/markdown.component';

import { ImageContainerProps, InfoBoxProps } from './specialProducts.types';

export const Wrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  max-width: 610px;
`;

export const ImageContainer: StyledComponent<'div', ImageContainerProps> = styled.div`
  width: 100%;
  height: 260px;
  background-image: ${({ url }: ImageContainerProps): string => `url(${url})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 10px;

  @media ${Breakpoint.Additional480} {
    display: flex;
    align-items: flex-end;
  }
`;

export const InfoBox: StyledComponent<'div', InfoBoxProps> = styled.div`
  position: absolute;
  padding: 20px;
  bottom: 20px;
  width: 260px;
  min-height: 120px;
  background-color: ${Color.White};
  box-sizing: border-box;
  ${({ boxPosition }: InfoBoxProps): string => boxPosition === 'right' ? 'right: 20px' : 'left: 20px'};
  @media ${Breakpoint.Additional480} {
    display: none;
  }
`;

export const MobileInfoBox: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 20px;
  display: none;
  @media ${Breakpoint.Additional480} {
    display: block;
  }
`;

export const Title: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-weight: ${FontWeight.Black};
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 5px;
  word-break: break-word;

  @media ${Breakpoint.Additional480} {
    color: ${Color.White};
    font-size: 15px;
  }
`;

export const Description: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 22px;
  word-break: break-word;
  @media ${Breakpoint.Additional480} {
    color: ${Color.White};
  }
`;

export const StyledFlatBox: StyledComponent<typeof FlatBox> = styled(FlatBox)`
  max-width: 100%;
`;

export const StyledPhotoOverlay: StyledComponent<typeof PhotoOverlay> = styled(PhotoOverlay)`
  display: flex;
  align-items: flex-end;
`;

export const VariantsContainer: StyledComponent<'div'> = styled.div`
  max-width: ${GridWidth.Desktop};
  padding: 50px ${GlobalPadding.Desktop} 60px;
  margin: 0 auto;

  @media ${Breakpoint.Additional1280} {
    padding: 50px 40px 60px;
  }

  @media ${Breakpoint.Tablet} {
    padding: 50px 30px 60px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 50px 20px 60px;
  }
`;

export const ScrollButtonContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

export const ScrollText: StyledComponent<'p'> = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${Color.Grey};
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  transform: rotate(90deg);
`;

export const DownIcon: StyledComponent<typeof Icon> = styled(Icon)`
  transform: rotate(-90deg);
`;

export const StyledScrollText: StyledComponent<typeof ScrollText> = styled(ScrollText)`
  margin-top: 15px;
`;

export const ScrollButton: StyledComponent<'button'> = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  margin-top: 15px;
  width: 45px;
  height: 45px;
  background: ${Color.White};
  overflow: hidden;
  position: relative;
`;

export const ContentWrapper: StyledComponent<'div'> = styled.div``;
