import DOMPurify from 'dompurify';
import marked from 'marked';

import { WebsitePathNameEnum } from '@rehau/shared/enums';
import { RouteInfo } from '@rehau/shared/models/countryConfig';
import { useRoutesInfo } from '../hooks/useRoutesInfo.hook';

import { findPathByName } from './findPathByName.utils';

export const trimMarkdownText: (text: string) => string = (text: string): string => {
  const result: RegExpMatchArray | null = text.match(/^(\*\*[\ ]*)(([^\ ]+)(.*)([^\ ]+))([\ ]*\*\*)$/);

  if (result) {
    return `**${result[2]}**`;
  }

  return text;
};

const renderer: marked.Renderer = new marked.Renderer();
renderer.link = (href: string, _: string, text: string): string => {
  if (!href.includes('/rehau-internal-route_')) {
    const isExternalUrl: boolean = href.indexOf('http') > -1;

    return `
      <a href="${href}" ${isExternalUrl ? 'target="_blank"' : ''}>
        ${text}
      </a>
    `;
  }
  const splitedHref: string[] = href.split('/rehau-internal-route_');
  const route: WebsitePathNameEnum = splitedHref.length === 2 ? splitedHref[1] as WebsitePathNameEnum : WebsitePathNameEnum.Home;
  const routesArray: RouteInfo[] = useRoutesInfo();

  return `
    <a href="${findPathByName(route, routesArray)}" target="blank">
      ${text}
    </a>
  `;
};

export const toMarkdown: (text: string) => string = (text: string): string => {
  return DOMPurify.sanitize(marked(trimMarkdownText(text), { renderer, breaks: true }), { ADD_ATTR: ['target'] });
};
