import React from 'react';

import { Text, Wrapper } from './choiceGroupItem.styled';
import { ChoiceGroupItemProps } from './choiceGroupItem.types';

export const ChoiceGroupItem: React.FC<ChoiceGroupItemProps> = (
  props: ChoiceGroupItemProps
): JSX.Element => {
  const {
    text,
    isActive = false,
    onClick,
    id,
    isUntouched,
  }: ChoiceGroupItemProps = props;

  return (
    <Wrapper isActive={isActive} onClick={onClick} id={id}>
      <Text isActive={isActive} isUntouched={isUntouched}>
        {text}
      </Text>
    </Wrapper>
  );
};
