import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { BoxShadow } from '../../enums/boxShadow.enum';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';
import { Icon } from '../icon/icon.component';

import { CounterIconProps, WrapperProps } from './quantityBar.types';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 580px;
`;

export const IconLabelContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
`;

export const Label: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 22px;
  margin-left: 10px;
  word-break: break-word;
  color: ${Color.Grey};
`;

export const CounterContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  margin-top: 1px;
`;

export const CounterIcon: StyledComponent<typeof Icon, CounterIconProps> = styled(Icon)`
  color: ${Color.Grey};
  ${styleWhenTrue(
    prop('isActive'),
    css`
      color: ${Color.Green};
    `
  )};
`;

export const Button: StyledComponent<'button'> = styled.button``;

export const Counter: StyledComponent<'p'> = styled.p`
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  width: 88px;
  color: ${Color.Grey};

  @media ${Breakpoint.Additional480} {
    width: 66px;
  }
`;

export const Wrapper: StyledComponent<'div', WrapperProps> = styled.div`
  width: 100%;
  min-height: 74px;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  border: 1px solid ${Color.Transparent};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 30px 12px 12px;
  box-sizing: border-box;
  transition: 0.3s;

  ${styleWhenTrue(
    prop('isActive'),
    css`
      border: 1px solid ${Color.Green};
      ${Counter},
      ${Label} {
        font-weight: ${FontWeight.Bold};
        color: ${Color.Black};
      }
    `
  )}

  ${styleWhenTrue(
    prop('hasTooltip'),
    css`
      margin-right: 20px;
    `
  )}

  @media ${Breakpoint.Additional480} {
    padding: 0 20px 0 10px;
  }
`;
