import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { BoxShadow, Breakpoint, GridWidth, Icon, StyledComponent, styleWhenTrue } from '../..';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { AdditionalInfo } from '../additionalInfo/additionalInfo.component';
import { Button } from '../button/button.component';
import { ButtonWithIcon } from '../buttonWithIcon/buttonWithIcon.component';

import { ArrowContainerProps, ContainerProps, ContentProps, InterfaceProps, StepNumberProps, TitleProps } from './step.types';

export const Container: StyledComponent<'div', ContainerProps> = styled.div`
  max-width: ${GridWidth.Desktop};
  width: 100%;
  margin: 0 auto;
  padding: ${({ withBottomPadding }: ContainerProps): string => (withBottomPadding ? '0 0 100px' : '0')};
  @media ${Breakpoint.Mobile} {
    padding: ${({ withBottomPadding }: ContainerProps): string => (withBottomPadding ? '0 0 60px' : '0')};
  }
`;

export const HeaderContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  @media ${Breakpoint.Additional1280} {
    padding: 0 80px;
  }
  @media ${Breakpoint.Tablet} {
    padding: 0 40px;
    align-items: center;
  }
  @media ${Breakpoint.Mobile} {
    padding: 0 20px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledIcon: StyledComponent<typeof Icon> = styled(Icon)`
  transition: 0.3s;
`;

export const ControlsContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  margin-right: 25px;
`;

export const ArrowContainer: StyledComponent<'button', ArrowContainerProps> = styled.button<ArrowContainerProps>`
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid ${Color.GreyStroke};
  :hover {
    ${StyledIcon} {
      color: ${Color.Green};
    }
  }

  ${styleWhenTrue(
    prop('disabled'),
    css`
      background-color: ${Color.GreyStroke};
      ${StyledIcon} {
        color: ${Color.Grey};
      }
      :hover {
        ${StyledIcon} {
          color: ${Color.Grey};
        }
      }
    `
  )}
`;

export const Counter: StyledComponent<'div'> = styled.div`
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: ${FontWeight.Bold};
  line-height: 20px;
  color: ${Color.Grey};
`;

export const TitleContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title: StyledComponent<'div', TitleProps> = styled.div`
  font-size: 26px;
  line-height: 31px;
  color: ${Color.Black};
  font-weight: ${FontWeight.Bold};
  ${styleWhenTrue(
    prop('hasTopPadding'),
    css`
      padding-top: 50px;
    `
  )}

  @media ${Breakpoint.Mobile} {
    margin-top: 15px;
  }
`;

export const Subtitle: StyledComponent<'div'> = styled.div`
  font-weight: ${FontWeight.Light};
  font-size: 18px;
  line-height: 22px;
  color: ${Color.Grey};
  margin-top: 5px;
`;

export const BoxShadowContainer: StyledComponent<'div'> = styled.div`
  box-shadow: ${BoxShadow.BtnGreyShadow};
`;

export const Content: StyledComponent<'div', ContentProps> = styled.div`
  background-color: ${Color.White};
  padding: 80px;

  ${styleWhenTrue(
    prop('withTopBorder'),
    css`
      border-top: 2px solid ${Color.Black};
    `
  )}

  @media ${Breakpoint.Tablet} {
    padding: 80px 40px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 40px 20px;
    ${styleWhenTrue(
      prop('withSmallTopPadding'),
      css`
        padding: 28px 20px 80px;
      `
    )}

    ${styleWhenTrue(
        prop('withSmallPadding'),
        css`
          padding: 30px 20px;
        `
    )}
  }
`;

export const ButtonsContainer: StyledComponent<'div'> = styled.div`
  padding: 0 80px 80px;
  background-color: ${Color.White};
  @media ${Breakpoint.Tablet} {
    padding: 0 40px 80px;
  }
  @media ${Breakpoint.Mobile} {
    padding: 0 20px 40px;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)``;

export const GoBackButtonContainer: StyledComponent<'div'> = styled.div`
  @media ${Breakpoint.Additional480} {
    margin-top: 30px;
  }
`;

export const NextButtonContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: flex-end;

  ${StyledButton} + ${StyledButton} {
    margin-left: 20px;
  }

  @media ${Breakpoint.Additional480} {
    width: 100%;
    flex-direction: column-reverse;

    ${StyledButton} + ${StyledButton},
    ${GoBackButtonContainer} + & {
      margin: 0;
    }
  }
`;

export const InterfaceContainer: StyledComponent<'div', InterfaceProps> = styled.div`
  padding-top: ${({ hasTopPadding }: InterfaceProps): string => hasTopPadding ? '10px' : '' };
  border-top: 1px solid ${Color.GreyStroke};
`;

export const InnerButtonsContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;

  @media ${Breakpoint.Additional480} {
    padding-top: 25px;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

export const StepBreadcrumbs: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  color: ${Color.Grey};
  margin: 20px 0;

  @media ${Breakpoint.Mobile} {
    flex-wrap: wrap;
  }
`;

export const StepNumber: StyledComponent<'button', StepNumberProps> = styled.button`
  ${styleWhenTrue(
    prop('isActive'),
    css`
      color: ${Color.Black};
      font-weight: ${FontWeight.Bold};
      cursor: default;
    `
  )}

  ${styleWhenTrue(
    prop('isBlocked'),
    css`
      cursor: default;
      pointer-events: none;
    `
  )}

`;

export const StyledBreadcrumbIcon: StyledComponent<typeof Icon> = styled(Icon)`
  margin: 3px 5px 0;
`;

export const StyledAdditionalInfo: StyledComponent<typeof AdditionalInfo> = styled(AdditionalInfo)`
  margin-bottom: 20px;
  @media ${Breakpoint.Additional1280} {
    margin: 0 80px 20px;
  }
  @media ${Breakpoint.Tablet} {
    margin: 0 40px 20px;
  }
  @media ${Breakpoint.Mobile} {
    margin: 0 20px 20px;
  }
`;

export const TopButtonContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;

  button + button {
    margin-left: 10px;
  }

  @media ${Breakpoint.Tablet} {
    margin-left: 0;
    margin-top: 20px;
  }

  @media ${Breakpoint.Mobile} {
    width: 100%;
    flex-direction: column;
    margin-left: 0;

    button + button {
      margin-left: 0;
      margin-top: 10px;
    }
  }
`;

export const StepWithIcon: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledButtonWithIcon: StyledComponent<typeof ButtonWithIcon> = styled(ButtonWithIcon)`
  margin-bottom: 40px;
  margin-top: -20px;

  @media ${Breakpoint.Additional1280} {
    padding-left: 80px;
  }

  @media ${Breakpoint.Mobile} {
    padding-left: 20px;
  }
`;
