import styled from 'styled-components';

import { Color } from '../enums/color.enum';
import { BoxShadow } from '../enums/boxShadow.enum';
import { ZIndex } from '../enums/zIndex.enum';
import { StyledComponent } from '../types/styledComponent.types';

export const BlackTooltip: StyledComponent<'div'> = styled.div`
  background-color: ${Color.Black};
  font-size: 12px;
  line-height: 17px;
  color: ${Color.White};
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  padding: 5px 8px;
  min-height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  box-shadow: ${BoxShadow.BtnGreyShadow};
  position: absolute;
  top: 40px;
  left: 50%;
  z-index: ${ZIndex.Tooltip};
  transform: translateX(-50%);
  min-width: 120px;
  word-break: break-word;

  :after {
    border-left: solid ${Color.Transparent} 6px;
    border-right: solid ${Color.Transparent} 6px;
    border-bottom: solid ${Color.Black} 6px;
    top: -6px;
    content: ' ';
    height: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    width: 0;
  }
`;
