import { MenuPosition, PathNameMenuPositionTuple } from '../../../../../../shared/models/countryConfig';

import { SortItems } from './menuPositions.types';

export const sortItems: SortItems = (item1: PathNameMenuPositionTuple, item2: PathNameMenuPositionTuple): number => {
  const pathData1: MenuPosition = item1[1] as MenuPosition;
  const pathData2: MenuPosition = item2[1] as MenuPosition;

  return pathData1.order - pathData2.order;
};
