import styled from 'styled-components';

import {
  Breakpoint,
  Color,
  FontWeight,
  GridWidth,
  Markdown,
  StyledComponent,
} from '@rehau-fe/ui/src';

export const Container: StyledComponent<'div'> = styled.div`
  max-width: ${GridWidth.Desktop};
  margin: 0 auto;
  padding: 165px 0 80px;
  background-color: ${Color.White};

  @media ${Breakpoint.Desktop} {
    padding: 130px 60px 80px;
  }

  @media ${Breakpoint.Tablet} {
    padding: 130px 40px 80px;
  }

  @media ${Breakpoint.Mobile} {
    padding: 110px 22px 60px;
  }
`;

export const Title: StyledComponent<'h1'> = styled.h1`
  font-size: 40px;
  font-weight: ${FontWeight.Black};
  margin-bottom: 40px;
`;

export const TextWrapper: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 18px;
  margin: 0 auto;

  a {
    color: ${Color.Pink};
    text-decoration: none;
  }

  h2 {
    padding-bottom: 63px;
    width: 100%;
    border-bottom: 1px solid ${Color.GreyStroke};
    margin-bottom: 60px;
    font-size: 30px;
    font-weight: ${FontWeight.Black};
    letter-spacing: .27px;
  }

  :not(:first-of-type) {
    h2 {
      margin-top: 75px;
    }
  }

  h3 {
    font-size: 24px;
    padding: 40px 0;
    font-weight: ${FontWeight.Bold};
  }

  h4 {
    padding: 10px 0 30px;
    font-weight: ${FontWeight.Bold};
  }

  p {
    line-height: 24px;
    padding-bottom: 20px;
  }

  ol li {
    list-style-type: decimal;
    list-style-position: inside;
    line-height: 24px;
  }

  ul {
    margin-bottom: 40px;

    li {
      list-style-position: inside;
      line-height: 24px;

      :not(:first-of-type) {
        margin-top: 13px;
      }

      :before {
        display: inline-block;
        content: "—";
        width: 35px;
        font-weight: ${FontWeight.Bold};
        font-size: 25px;
      }
    }
  }

  :last-of-type {
    * {
      padding-bottom: 0;
    }
  }
`;
