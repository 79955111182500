import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { StyledComponent } from '../../../types/styledComponent.types';
import { Icon } from '../../../components/icon/icon.component';
import { Color } from '../../../enums/color.enum';
import { styleWhenTrue } from '../../../utils/style.utils';
import { FontWeight } from '../../../enums/fontWeight.enum';
import { TooltipContainer } from '../select.styled';

import { ButtonProps } from './multiSelectItem.types';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label: StyledComponent<'p'> = styled.p`
  color: ${Color.Grey};
  font-size: 15px;
  line-height: 18px;
  transition: 0.2s;
`;

export const CheckIconContainer: StyledComponent<'div'> = styled.div`
  width: 22px;
  height: 22px;
  border: 1px solid ${Color.GreyStroke};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  transition: 0.2s;
  flex-shrink: 0;
`;

export const Button: StyledComponent<'button', ButtonProps> = styled.button`
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0;

  ${styleWhenTrue(
    prop('isActive'),
    css`
      ${CheckIconContainer} {
        border: 1px solid ${Color.Green};
        background-color: ${Color.Green};
      }

      ${Label} {
        font-weight: ${FontWeight.Bold};
        color: ${Color.Green};
      }
    `
  )};
`;

export const CheckIcon: StyledComponent<typeof Icon> = styled(Icon)``;

export const Description: StyledComponent<'div'> = styled.div`
  margin-right: -22px;
  display: flex;
  align-items: center;
`;

export const IncludedPriceLabel: StyledComponent<'div'> = styled.div`
  margin-right: 10px;
  font-size: 13px;
  color: ${Color.Black};
  line-height: 24px;
  font-weight: ${FontWeight.Bold};
`;

export const StyledTooltipContainer: StyledComponent<typeof TooltipContainer> = styled(TooltipContainer)`
  position: relative;
  left: 30px;
`;
