import { prop, propEq } from 'ramda';
import styled, { css, StyledComponent } from 'styled-components';

import { Icon } from '../../icon/icon.component';
import { Breakpoint } from '../../../enums/breakpoint.enum';
import { Color } from '../../../enums/color.enum';
import { FontWeight } from '../../../enums/fontWeight.enum';
import { styleWhenTrue } from '../../../utils/style.utils';
import { BoxShadow } from '../../../enums/boxShadow.enum';

import { ItemStatusProps } from './cell.types';

export const Button: StyledComponent<'button', {}, ItemStatusProps, never> = styled.button`
  position: relative;
  width: 200px;
  height: 140px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Color.GreyStroke};
  background-color: ${Color.BgGrey};
  transition: 0.3s;
  padding: 10px;
  & + & {
    margin-left: 20px;
  }

  ${styleWhenTrue(
    prop('withWhiteBackground'),
    css`
      background-color: ${Color.White};
    `
  )}

  ${styleWhenTrue(
    prop('isDisabled'),
    css`
      background-color: ${Color.BgGrey};
      border: 1px solid ${Color.GreyStroke};
      pointer-events: none;
    `
  )}

  ${styleWhenTrue(
    prop('isUntouched'),
    css`
      box-shadow: ${BoxShadow.BtnGreyShadow};
      border: 1px solid ${Color.Transparent};
      background-color: ${Color.White};
    `
  )}

  ${styleWhenTrue(
    prop('isActive'),
    css`
      box-shadow: ${BoxShadow.BtnGreenShadow};
      border: 1px solid ${Color.Green};
      background-color: ${Color.White};
    `
  )};
  @media ${Breakpoint.Mobile} {
    width: 160px;
    &:nth-child(even) {
      margin-right: 0;
    }
  }
`;

export const Label: StyledComponent<'p', {}, ItemStatusProps, never> = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${Color.Grey};
  font-weight: ${FontWeight.Light};
  transition: 0.3s;
  ${styleWhenTrue(
    prop('isUntouched'),
    css`
      color: ${Color.Grey};
    `
  )}
  ${styleWhenTrue(
    prop('isActive'),
    css`
      color: ${Color.Black};
      font-weight: ${FontWeight.Bold};
    `
  )}

  ${styleWhenTrue(
    prop('isDisabled'),
    css`
      color: ${Color.Grey};
    `
  )}
`;

export const CellIcon: StyledComponent<typeof Icon, {}, ItemStatusProps, never> = styled(Icon)`
  color: ${Color.Grey};
  transition: 0.3s;
  ${styleWhenTrue(
    prop('isUntouched'),
    css`
      color: ${Color.Black};
    `
  )}
  ${styleWhenTrue(
    prop('isActive'),
    css`
      color: ${Color.Black};
    `
  )}

  ${styleWhenTrue(
    prop('isDisabled'),
    css`
      color: ${Color.Grey};
    `
  )}
`;

export const ActiveCircle: StyledComponent<'div', {}, {}, never> = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: ${Color.Green};
  display: flex;
  justify-content: center;
  align-items: center;
`;
