export enum Theme {
  Light = 'light',
  Dark = 'dark',
  Green = 'green',
}

export enum MenuType {
  Default = 'default',
  Small = 'small',
}
