import { useEffect, useState } from 'react';

import { LocalStorageKey } from '../enums/localStorageKey.enum';
import { UseLocalStorage } from '../types/useLocalStorage.types';
import { UseState } from '../types/useState.types';

export const useLocalStorage: <T = string>(key: LocalStorageKey, initialValue: T) => UseLocalStorage<T> = <T = string>(
  key: string, initialValue: T
): UseLocalStorage<T> => {
  const readValue: () => T = (): T => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item: string = window.localStorage.getItem(key) || '';

      return item ? JSON.parse(item) as T : initialValue;
    } catch (_) {
      return initialValue;
    }
  };
  const [storedValue, setStoredValue]: UseState<T> = useState<T>(readValue);

  const setValue: (value: T) => void = (value: T): void => {
    try {
      const newValue: T = value instanceof Function ? value(storedValue) as T : value;
      if (value === null) {
        window.localStorage.removeItem(key);
      } else {
        window.localStorage.setItem(key, JSON.stringify(newValue));
      }
      setStoredValue(newValue);
      window.dispatchEvent(new Event('local-storage'));
    } catch (_) { return; }
  };

  useEffect(
    (): () => void => {
      const handleStorageChange: () => void = (): void => setStoredValue(readValue());
      window.addEventListener('storage', handleStorageChange);
      window.addEventListener('local-storage', handleStorageChange);

      return (): void => {
        window.removeEventListener('storage', handleStorageChange);
      };
    },
    []
  );

  return [storedValue, setValue];
};
