export enum FormsTypeEnum {
  AtHomeExternalInstaller = 'AtHomeExternalInstallerForm',
  EditCompany = 'EditCompanyForm',
  EditCompanyUser = 'EditCompanyUserForm',
  RegisterVerify = 'RegisterVerifyForm',
  AddInternalUser = 'AddInternalUserForm',
  EditInternalUser = 'EditInternalUserForm',
  RemindPassword = 'RemindPasswordForm',
  RemindPasswordConfirmation = 'RemindPasswordConfirmationForm',
  OfferEdit = 'OfferEditForm',
  OrderAnOffer = 'OrderAnOfferForm',
  PhoneNumber = 'PhoneNumberForm',
  CloseOfferForm = 'CloseOfferForm',
}
