import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { StyledComponent } from '../../../../types/styledComponent.types';
import { Color } from '../../../../enums/color.enum';
import { FontWeight } from '../../../../enums/fontWeight.enum';
import { Price } from '../../../../theme';
import { styleWhenTrue } from '../../../../utils/style.utils';
import { Breakpoint } from '../../../../enums/breakpoint.enum';

import { InnerBarProps, LabelProps, PriceWithTriangleProps, StyledPriceProps } from './priceSimulationBar.types';

export const Container: StyledComponent<'div'> = styled.div`
  flex: 1;
  position: relative;

  & + & {
    margin-left: 10px;
  }

  @media ${Breakpoint.Mobile} {
    width: 100px;
  }
`;

export const Bar: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: 8px;
  border: 1px solid ${Color.CompanyColor};
`;

export const InnerBar: StyledComponent<'div', InnerBarProps> = styled.div<InnerBarProps>`
  width: ${({ $width }: InnerBarProps): number => $width}%;
  height: 100%;
  background-color: ${Color.CompanyColor};
`;

export const Label: StyledComponent<'div', LabelProps> = styled.div<LabelProps>`
  font-weight: ${FontWeight.Bold};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${Color.Black};
  padding-top: 10px;

  ${styleWhenTrue(prop('withBiggerFonts'), css`
    font-size: 20px;
  `)}
`;

export const PriceWithTriangle: StyledComponent<'div', PriceWithTriangleProps> = styled.div<PriceWithTriangleProps>`
  position: absolute;
  bottom: calc(100% + 10px);
  left: ${({ $position }: PriceWithTriangleProps): string => `calc(${$position}% - 6px)`};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PriceWithTriangleInner: StyledComponent<'div'> = styled.div`
  position: relative;
`;

export const StyledPrice: StyledComponent<typeof Price, StyledPriceProps> = styled(Price)<StyledPriceProps>`
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.825px;
  color: ${Color.Black};
  margin-bottom: 3px;
  position: absolute;
  bottom: 8px;
  ${({ $position }: StyledPriceProps): string => $position === 'right'
    ? 'right: 0;'
    : $position === 'center'
      ? `
        right: 50%;
        transform: translateX(50%);
      `
      : ''
  }
`;

export const Triangle: StyledComponent<'div'> = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid ${Color.Transparent};
  border-right: 6px solid ${Color.Transparent};
  border-top: 6px solid ${Color.Black};
`;
