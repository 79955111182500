import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { MarkdownContent } from '@rehau/shared/models/countryConfig';
import { useSupportEmailReplace } from '@rehau-fe/ui/src/hooks/useSupportEmailReplace.hook';
import { useCountryConfig } from '@rehau-fe/ui/src/hooks/useCountryConfig.hook';
import { AppConfigType, CustomLink, UseCountryConfig } from '@rehau-fe/ui/src';
import { useAppConfig } from '@rehau-fe/ui/src/hooks/useAppConfig.hook';

import { Container, StyledButton, StyledButtonContainer, TextWrapper, Title } from './installerRegulations.styled';
import { ContentProps } from './installerRegulations.types';

export const InstallerRegulations: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const appConfig: AppConfigType = useAppConfig();
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();
  const installerRegulations: MarkdownContent | null = isConfigAvailable ? config.websiteViewsConfigs.installerRegulations : null;

  const Content: React.FC<ContentProps> = ({ children, noPadding = false }: ContentProps): JSX.Element => (
    <Container noPadding={noPadding}>
      <Title text={t('rehau.website.installer_regulations_view.header')} />
      {!!installerRegulations &&
        installerRegulations.content.map(
          (sentences: string[], index: number): JSX.Element => (
            <React.Fragment key={index}>
              {sentences.map(
                (sentence: string, sentenceIndex: number): JSX.Element => (
                  <TextWrapper
                    key={sentenceIndex}
                    text={useSupportEmailReplace(sentence)}
                  />
                )
              )}
            </React.Fragment>
          )
        )
      }
      {children}
    </Container>
  );

  return (
    <Content>
      <StyledButtonContainer>
        <CustomLink to={`${appConfig.externalAssets}${config.variables.website['installer_regulations_relative_pdf_path']}`} openInNewTab>
          <StyledButton>{t('rehau.website.installer_regulations_view.download_button_label')}</StyledButton>
        </CustomLink>
      </StyledButtonContainer>
    </Content>
  );
};
