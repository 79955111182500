import React, { useEffect, useState } from 'react';

import { ValidationStatus } from '../../../enums/validationStatus.enum';
import { UseState } from '../../../types/useState.types';
import { PasswordInput } from '../passwordInput/passwordInput.component';
import { PasswordContainer as PasswordContainerClass } from '../../../../../../../shared/forms/components';
import { getFieldContainerType } from '../../../components/fieldComponent/utils/getFieldContainerType';
import { useEffectAfterMount } from '../../../hooks/useEffectAfterMount';
import { ValidationMessages } from '@rehau/shared/forms/validations';
import { Autocomplete } from '@rehau/shared/enums';

import { Container, StyledInputContainer } from './passwordContainer.styled';
import { PasswordContainerProps } from './passwordContainer.types';

export const PasswordContainer: React.FC<PasswordContainerProps> = (props: PasswordContainerProps): JSX.Element => {
  const {
    passwordLabel,
    passwordPlaceholder,
    repeatPasswordLabel,
    repeatPasswordPlaceholder,
    component = new PasswordContainerClass('', ''),
    setValues = (): void => undefined,
  }: PasswordContainerProps = props;
  const [passwordValue, setPasswordValue]: UseState<string> = useState('');
  const [repeatPasswordValue, setRepeatPasswordValue]: UseState<string> = useState('');
  const [passwordValidationStatus, setPasswordValidationStatus]: UseState<ValidationStatus> = useState<ValidationStatus>(
    ValidationStatus.Untouched
  );
  const [repeatPasswordValidationStatus, setRepeatPasswordValidationStatus]: UseState<ValidationStatus> = useState<ValidationStatus>(
    ValidationStatus.Untouched
  );
  const [passwordValidationMessages, setPasswordValidationMessages]: UseState<ValidationMessages[] | undefined>
    = useState<ValidationMessages[] | undefined>();
  const [repeatPasswordValidationMessages, setRepeatPasswordValidationMessages]: UseState<ValidationMessages[] | undefined>
    = useState<ValidationMessages[] | undefined>();
  const [focused, setFocused]: UseState<boolean> = useState<boolean>(false);

  const onFocus: () => void = (): void => {
    setFocused(true);
  };

  const handleChangePassword: (value: string) => void = (value: string): void => {
    if (!!value && !focused) {
      setValues({ [component.getPasswordField().id]: value });
      const validationSuccess: boolean = component.getPasswordField().validate();
      setPasswordValidationStatus(validationSuccess ? ValidationStatus.Valid : ValidationStatus.Invalid);
    }
    setPasswordValue(value);
  };

  const handleChangeRepeatPassword: (value: string) => void = (value: string): void => {
    setRepeatPasswordValue(value);
  };

  useEffectAfterMount(
    (): void => {
      setPasswordValidationMessages(component.getPasswordField().validationMessages);
      setRepeatPasswordValidationMessages(component.getRepeatPasswordField().validationMessages);
    },
    [component.getPasswordField().valid, component.getRepeatPasswordField().valid]
  );

  const validatePasswordFields: () => void = (): void => {
    const repeatPasswordValidationSuccess: boolean = component.getRepeatPasswordField().validate();
    const containerValidationSuccess: boolean = component.validate();
    setRepeatPasswordValidationStatus((repeatPasswordValidationSuccess && containerValidationSuccess)
    ? ValidationStatus.Valid
    : ValidationStatus.Invalid);
    setRepeatPasswordValidationMessages(component.getRepeatPasswordField().validationMessages);
    setPasswordValidationMessages(component.getPasswordField().validationMessages);
  };

  const handleBlurPassword: () => void = (): void => {
    setFocused(false);
    setValues({ [component.getPasswordField().id]: passwordValue });
    const validationSuccess: boolean = component.getPasswordField().validate();
    if (!!repeatPasswordValue) {
      validatePasswordFields();
    }
    setPasswordValidationStatus(validationSuccess ? ValidationStatus.Valid : ValidationStatus.Invalid);
    setPasswordValidationMessages(component.getPasswordField().validationMessages);
  };

  const handleBlurRepeatPassword: () => void = (): void => {
    setValues({ [component.getRepeatPasswordField().id]: repeatPasswordValue });
    validatePasswordFields();
    setRepeatPasswordValidationMessages(component.getRepeatPasswordField().validationMessages);
  };

  return (
    <Container>
      <StyledInputContainer
        className={getFieldContainerType(component)}
        label={passwordLabel}
        validationStatus={passwordValidationStatus || ValidationStatus.Untouched}
        validationMessages={passwordValidationMessages}
      >
        <PasswordInput
          placeholder={passwordPlaceholder}
          onChange={handleChangePassword}
          onBlur={handleBlurPassword}
          value={passwordValue}
          autocomplete={Autocomplete.NewPassword}
          onFocus={onFocus}
        />
      </StyledInputContainer>
      <StyledInputContainer
        className={getFieldContainerType(component)}
        label={repeatPasswordLabel}
        validationStatus={repeatPasswordValidationStatus || ValidationStatus.Untouched}
        validationMessages={repeatPasswordValidationMessages}
      >
        <PasswordInput
          placeholder={repeatPasswordPlaceholder}
          onChange={handleChangeRepeatPassword}
          onBlur={handleBlurRepeatPassword}
          value={repeatPasswordValue}
          autocomplete={Autocomplete.NewPassword}
        />
      </StyledInputContainer>
    </Container>
  );
};
