import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { MenuGroupTuple, RouteInfo } from '../../../../../../../../shared/models/countryConfig';
import { IconName } from '../../../../../../../../shared/enums';
import { useRoutesInfo } from '../../../../hooks/useRoutesInfo.hook';
import { findPathByName } from '../../../../utils/findPathByName.utils';
import { UseRedirectOutput } from '../../../../types/useRedirect.types';
import { useRedirect } from '../../../../hooks/useRedirect';
import { Color } from '../../../../enums/color.enum';

import HouseImg from './images/house.png';
import { ItemModalProps } from './itemModal.types';
import {
  CloseButton,
  CloseIcon,
  Container,
  Content,
  Header,
  Image,
  ImageWrapper,
  ItemGroup,
  Positions,
  StyledFooterBottomBar,
} from './itemModal.styled';

export const ItemModal: React.FC<ItemModalProps> = (props: ItemModalProps): JSX.Element => {
  const {
    open,
    title,
    groups,
    buttonLabel,
    buttonPathName,
    onClose = (): void => undefined,
  }: ItemModalProps = props;
  const { t }: TransProps<never> = useTranslation();
  const routesArray: RouteInfo[] = useRoutesInfo();
  const handleRedirect: UseRedirectOutput = useRedirect();

  const handleButtonClick: () => void = (): void => {
    handleRedirect(findPathByName(buttonPathName, routesArray), buttonPathName);
    onClose();
  };

  return (
    <Container data-testid='modal' open={open}>
      <CloseButton data-testid='modal-close-button' onClick={onClose}>
        <CloseIcon name={IconName.No} size={18} color={Color.White} />
      </CloseButton>
      <Content>
        <Positions>
          <Header>{title}</Header>
          {groups.map(([name, data]: MenuGroupTuple): JSX.Element | false => (
            !!data && (
              <ItemGroup
                data-testid='item-group'
                key={name}
                title={t(data.header)}
                items={data.children}
                onChoose={onClose}
              />
            )
          ))}
        </Positions>
        <ImageWrapper>
          <Image src={HouseImg} alt='Open house' />
        </ImageWrapper>
      </Content>
      <StyledFooterBottomBar />
    </Container>
  );
};
