import React, { ChangeEvent, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent, DraggableEventHandler } from 'react-draggable';
import { Formik, FormikProps, FormikValues } from 'formik';
import { useMediaQuery } from 'react-responsive';

import { SlideButton, SlideSize, SlidesType } from '../slides.enums';
import { UseState } from '../../../types/useState.types';
import { Breakpoint } from '../../../enums/breakpoint.enum';

import { DraggableBounds, SlideProps, SubmitHandler } from './slide.types';
import {
  AreaText,
  Bar,
  InnerBar,
  InnerBarBoundaryLeft,
  InnerBarBoundaryRight,
  Label,
  Separtor,
  Sup,
  ValueContainer,
  ValueInput,
  Wrapper,
} from './slide.styled';

const getValue: (
  value: string, slideType: SlidesType, slideButton: SlideButton
) => number = (value: string, slideType: SlidesType, slideButton: SlideButton): number => {
  const currentValue: number = Number(value);
  if (slideType === SlidesType.M && slideButton !== SlideButton.Ml) {
    return currentValue - 1;
  }
  if (slideType === SlidesType.L && slideButton === SlideButton.Ml) {
    return currentValue - 1;
  }

  return currentValue;
};

export const Slide: React.FC<SlideProps> = (props: SlideProps): JSX.Element => {
  const {
    left,
    right,
    leftId,
    rightId,
    onDrag,
    onDragStop,
    bounds,
    value,
    label,
    leftOnTop,
    onSubmit,
    slideType,
    className,
    withFocusInLeftInput,
  }: SlideProps = props;
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const isSmallMobile: boolean = useMediaQuery({ query: Breakpoint.Additional480 });
  const slideWidth: SlideSize = isSmallMobile
    ? SlideSize.SlideWidthSmallMobile
    : isMobile
     ? SlideSize.SlideWidthMobile
     : SlideSize.SlideWidth;
  const leftPosition: number = useMemo((): number => (left * slideWidth) / 100, [left, isMobile, isSmallMobile]);
  const rightPosition: number = useMemo((): number => (right * slideWidth) / 100, [right, isMobile, isSmallMobile]);
  const [isTyping, setIsTyping]: UseState<boolean> = useState<boolean>(false);
  const ref: RefObject<HTMLInputElement> = useRef(null);

  const initValues: FormikValues = {
    [String(leftId)]: value.left,
    [String(rightId)]: value.right,
  };

  const leftBounds: DraggableBounds = useMemo(
    (): DraggableBounds => ({
      left: bounds.smBound,
      right: rightPosition,
    }),
    [rightPosition, JSON.stringify(bounds)]
  );

  const rightBounds: DraggableBounds = useMemo(
    (): DraggableBounds => ({
      left: leftPosition,
      right: bounds.mlBound,
    }),
    [leftPosition, JSON.stringify(bounds)]
  );

  const handleDrag: DraggableEventHandler = useCallback(
    (_: DraggableEvent, data: DraggableData): void => {
      onDrag((data.x / slideWidth) * 100, data.node.id as SlideButton);
    },
    []
  );

  const handleDragStop: DraggableEventHandler = useCallback(
    (): void => {
      onDragStop();
    },
    []
  );

  useEffect(
    (): void => {
      if (withFocusInLeftInput && ref.current) {
        ref.current.focus();
      }
    },
    []
  );

  const handleFocus: () => void = (): void => {
    setIsTyping(true);
  };

  const handleBlur: () => void = (): void => {
    setIsTyping(false);
  };

  const submitHandler: SubmitHandler = (values: FormikValues): void => {
    const leftValue: string = values[String(leftId)];
    const rightValue: string = values[String(rightId)];

    if (leftValue !== initValues[String(leftId)]) {
      onSubmit(getValue(leftValue, slideType, leftId), leftId, leftBounds);
    }

    if (rightValue !== initValues[String(rightId)]) {
      onSubmit(getValue(rightValue, slideType, rightId), rightId, rightBounds);
    }
  };

  return (
    <Wrapper data-testid={`slide-${slideType}`} className={className}>
      <Label>{label}</Label>
      <Bar>
        <InnerBar left={left} right={100 - right}>
          <Formik initialValues={initValues} onSubmit={submitHandler} enableReinitialize={!!isTyping}>
            {({ handleSubmit, values, setFieldValue }: FormikProps<FormikValues>): JSX.Element => (
              <form onSubmit={handleSubmit} data-testid='form'>
                <ValueContainer>
                  <ValueInput
                    ref={ref}
                    value={isTyping ? values[String(leftId)] : value.left}
                    onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                      e.preventDefault();
                      const currentValue: string = e.target.value;
                      const regex: RegExp = /^(0*[0-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                      if (regex.test(currentValue.toString()) || currentValue === '') {
                        setFieldValue(String(leftId), currentValue);
                      }
                    }}
                    name={leftId}
                    data-testid={`${leftId}-${slideType}`}
                    valueLength={isTyping ? String(values[leftId]).length : String(value.left).length}
                    onFocus={handleFocus}
                    onBlur={(): void => {
                      handleSubmit();
                      handleBlur();
                    }}
                  />
                  <Separtor>-</Separtor>
                  <ValueInput
                    value={isTyping ? values[String(rightId)] : value.right}
                    name={rightId}
                    onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                      e.preventDefault();
                      const currentValue: string = e.target.value;
                      const regex: RegExp = /^(0*[0-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                      if (regex.test(currentValue.toString()) || currentValue === '') {
                        setFieldValue(String(rightId), currentValue);
                      }
                    }}
                    onFocus={handleFocus}
                    data-testid={`${rightId}-${slideType}`}
                    valueLength={isTyping ? String(values[rightId]).length : String(value.right).length}
                    maxLength={3}
                    minLength={1}
                    onBlur={(): void => {
                      handleSubmit();
                      handleBlur();
                    }}
                  />
                  <AreaText>
                    m<Sup>2</Sup>
                  </AreaText>
                </ValueContainer>
                <button type='submit' />
              </form>
            )}
          </Formik>
        </InnerBar>
        <Draggable
          bounds={leftBounds}
          axis='x'
          grid={[1, 100]}
          scale={1}
          onDrag={handleDrag}
          onStop={handleDragStop}
          position={{ x: leftPosition, y: -8 }}
        >
          <InnerBarBoundaryLeft id={leftId} isOnTop={leftOnTop} data-testid={`left-bound-${slideType}`} />
        </Draggable>
        <Draggable
          bounds={rightBounds}
          axis='x'
          grid={[1, 100]}
          scale={1}
          onDrag={handleDrag}
          onStop={handleDragStop}
          position={{ x: rightPosition, y: -8 }}
          data-testid='right-bound'
        >
          <InnerBarBoundaryRight id={rightId} data-testid={`right-bound-${slideType}`} />
        </Draggable>
      </Bar>
    </Wrapper>
  );
};
