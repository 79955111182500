import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';
import { ZIndex } from '../../enums/zIndex.enum';

export const Container: StyledComponent<'div'> = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: ${ZIndex.Alerts};
  max-width: calc(100% - 40px);
`;

export const AlertsItem: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: flex-end;

  ${styleWhenTrue(prop('hidden'), css`
    display: none;
  `)}

  > div {
    margin-top: 10px;
  }
`;
