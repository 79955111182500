import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { MenuPosition, PathNameMenuPositionTuple, RouteInfo } from '../../../../../../../../shared/models/countryConfig';
import { useSupportEmailReplace } from '../../../../hooks/useSupportEmailReplace.hook';
import { sortItems } from '../../../../utils/menuPositions/menuPositions.utils';
import { CustomLink } from '../../../customLink/customLink.component';
import { toMarkdown } from '../../../../utils/toMarkdown';
import { AppConfigType } from '../../../../types/appConfigType.types';
import { useAppConfig } from '../../../../hooks/useAppConfig.hook';
import { CalculationTypeEnum, ExternalPathNameEnum } from '@rehau/shared/enums';
import { useRoutesInfo } from '../../../../hooks/useRoutesInfo.hook';
import { findPathByName } from '../../../../utils/findPathByName.utils';

import { Column, ColumnTitle, FooterColumns, RowItem } from './footerMenuColumns.styled';
import { FooterMenuColumnsProps } from './footerMenuColumns.types';

export const FooterMenuColumns: React.FC<FooterMenuColumnsProps> = (props: FooterMenuColumnsProps): JSX.Element => {
  const { menuEntries, contactDetails }: FooterMenuColumnsProps = props;
  const { t }: TransProps<never> = useTranslation();
  const appConfig: AppConfigType = useAppConfig();
  const routesArray: RouteInfo[] = useRoutesInfo();

  return (
    <FooterColumns data-testid='footer-menu-columns'>
      {menuEntries.map(([name, menuPosition]: PathNameMenuPositionTuple): JSX.Element => (
        <React.Fragment key={name}>
          {!!menuPosition && (
            <Column data-testid='footer-column'>
              <ColumnTitle>{t(menuPosition.title)}</ColumnTitle>
              {
                Object.entries(Object.entries(menuPosition.groups as MenuPosition)[0][1].children as MenuPosition)
                  .sort(sortItems)
                  .map(
                    ([childName, childData]: PathNameMenuPositionTuple): JSX.Element =>
                      !!childData ? (
                        <RowItem data-testid='column-item' key={childName}>
                          <CustomLink
                            to={childName === ExternalPathNameEnum.OfferCreator
                              ? `${appConfig.creatorUrl}/${CalculationTypeEnum.Reguard}`
                              : findPathByName(childName, routesArray)
                            }
                            pathName={childName === ExternalPathNameEnum.OfferCreator
                              ? undefined
                              : childName
                            }
                            openInNewTab={childName === ExternalPathNameEnum.OfferCreator}
                          >
                            {t(childData.title)}
                          </CustomLink>
                        </RowItem>
                      ) : (
                        <></>
                      )
                  )
              }
            </Column>
          )}
        </React.Fragment>
      ))}
      {!!contactDetails && (
        <Column data-testid='contact-column'>
          <ColumnTitle>{t('rehau.components.footer.contact_details_header')}</ColumnTitle>
          {contactDetails.map((text: string, index: number): JSX.Element => (
            <RowItem
              key={index}
              data-testid='contact-column-item'
              dangerouslySetInnerHTML={{ __html: toMarkdown(useSupportEmailReplace(text)) }}
            />
          ))}
        </Column>
      )}
    </FooterColumns>
  );
};
