import React, { useMemo } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';

import { CalculationTypeEnum, IconName, WebsitePathNameEnum } from '@rehau/shared/enums';
import { Color } from '../../enums/color.enum';
import { useRoutesInfo } from '../../hooks/useRoutesInfo.hook';
import { RouteInfo } from '@rehau/shared/models/countryConfig';
import { getRouteDetailsByUrl } from '@rehau/shared/utils';
import { AppConfigType } from '../../types/appConfigType.types';
import { useAppConfig } from '../../hooks/useAppConfig.hook';
import { findPathByName } from '../../utils/findPathByName.utils';

import { StickyTeaserProps } from './stickyTeaser.types';
import { Container, Label, StyledCustomLink, StyledIcon } from './stickyTeaser.styled';

export const StickyTeaser: React.FC<StickyTeaserProps> = (props: StickyTeaserProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const routesArray: RouteInfo[] = useRoutesInfo();
  const {
    label = t('rehau.website.sticky_teaser.configurator'),
    icon = IconName.House,
    targetPathname,
    pathnamesWhereRender = [
      WebsitePathNameEnum.Home,
      WebsitePathNameEnum.ReGuard,
      WebsitePathNameEnum.ReSource,
    ],
  }: StickyTeaserProps = props;
  const location: Location = useLocation();
  const pathObject: RouteInfo | undefined = getRouteDetailsByUrl(location.pathname, routesArray);
  const shouldRender: boolean = useMemo(
    (): boolean => pathObject?.name
      ? pathnamesWhereRender.includes(pathObject.name)
      : false,
    [location.pathname, routesArray]
  );
  const appConfig: AppConfigType = useAppConfig();

  return shouldRender ? (
      <Container>
        <StyledCustomLink
          to={targetPathname
            ? findPathByName(targetPathname, routesArray)
            : `${appConfig.creatorUrl}/${CalculationTypeEnum.Reguard}`
          }
          pathName={targetPathname}
          openInNewTab={!targetPathname}
        >
          <Label>{label}</Label>
          <StyledIcon name={icon} size={21} color={Color.White} />
        </StyledCustomLink>
      </Container>
    ) : <></>;
};
