import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { useAlerts } from '../../hooks/useAlerts.hook';
import { useRedirect } from '../../hooks/useRedirect';
import { UseAlerts } from '../../types/useAlerts.types';
import { UseRedirectOutput } from '../../types/useRedirect.types';
import { IconName } from '../../../../../../shared/enums';
import { Color } from '../../enums/color.enum';
import { UseState } from '../../types/useState.types';
import { ContextMenu } from '../contextMenu/contextMenu.component';
import { UseCountryConfig } from '../../types/useCountryConfig.types';
import { useCountryConfig } from '../../hooks/useCountryConfig.hook';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';
import { fieldValueType } from '@rehau/shared/forms/types';

import { FixedMenu } from './components/fixedMenu/fixedMenu.component';
import { Container, ControlsPanel, Counter, Hamburger, InnerContainer, StyledIcon, StyledToggleSwitch, Title, TitleContainer } from './expandedStepsMenu.styled';
import { ExpandedStepsMenuPosition, ExpandedStepsMenuProps } from './expandedStepsMenu.types';

export const ExpandedStepsMenu: React.FC<ExpandedStepsMenuProps> = (props: ExpandedStepsMenuProps): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const {
    title,
    menuPositions,
    className,
    switchValue,
    contextMenuPositions,
    switchLabel = t('rehau.components.expanded_step_menu.toggle_switch_label'),
    switchTrueTooltip = t('rehau.components.expanded_step_menu.toggle_switch_true_tooltip'),
    switchFalseTooltip = t('rehau.components.expanded_step_menu.toggle_switch_false_tooltip'),
    handleMenuPositionClick = (): void => undefined,
    handleActiveSwitchClick = (): void => undefined,
    isContextMenuAvailable,
  }: ExpandedStepsMenuProps = props;
  const [isMenuOpen, setIsMenuOpen]: UseState<boolean> = useState<boolean>(false);
  const { showDialog }: UseAlerts = useAlerts();
  const handleRedirect: UseRedirectOutput = useRedirect();
  const { config, isConfigAvailable }: UseCountryConfig = useCountryConfig();
  const website: ObjectIteratorInterface<fieldValueType> = isConfigAvailable ? config.variables.website : {};
  const email: string = website['support_email'] as string;
  const warningsCounter: number = menuPositions
    .filter((position: ExpandedStepsMenuPosition): boolean => position.isValid === false)
    .length;

  const handleMenuOpen: () => void = (): void => {
    setIsMenuOpen(true);
  };

  const handleMenuClose: () => void = (): void => {
    setIsMenuOpen(false);
  };

  const handlePositionClick: (positionIndex: number) => void = (positionIndex: number): void => {
    handleMenuPositionClick(positionIndex);
    setIsMenuOpen(false);
  };

  return (
    <Container className={className}>
      <InnerContainer>
        <TitleContainer>
          <Hamburger onClick={handleMenuOpen}>
            <StyledIcon name={IconName.Hamburger} size={12} color={Color.Black} />
            {!!warningsCounter && (<Counter>{warningsCounter}</Counter>)}
          </Hamburger>
          <Title>{t(title)}</Title>
        </TitleContainer>
        <ControlsPanel>
          <StyledToggleSwitch
            value={switchValue}
            label={switchLabel || ''}
            tooltipContentForTrue={switchTrueTooltip}
            tooltipContentForFalse={switchFalseTooltip}
            onClick={handleActiveSwitchClick}
          />
          {isContextMenuAvailable && <ContextMenu positions={contextMenuPositions} />}
        </ControlsPanel>
      </InnerContainer>
      <FixedMenu
        title={title}
        onClose={handleMenuClose}
        onPositionClick={handlePositionClick}
        isVisible={isMenuOpen}
        positions={menuPositions}
        isSetupActive={switchValue}
      />
    </Container>
  );
};
