import React, { RefObject, useLayoutEffect, useRef, useState } from 'react';

import { Button } from '../../../components/button/button.component';
import { UseState } from '../../../types/useState.types';

import { InvisibleWrapper } from './selectBarButton.styled';
import { ButtonName, SelectBarButtonProps } from './selectBarButton.types';

export const SelectBarButton: React.FC<SelectBarButtonProps> = (props: SelectBarButtonProps): JSX.Element => {
  const { isSelected, removeText, chooseText, onClick }: SelectBarButtonProps = props;
  const [buttonMinWidth, setButtonMinWidth]: UseState<number> = useState<number>(0);
  const [smallerButton, setSmallerButton]: UseState<ButtonName> = useState<ButtonName>('');
  const chooseButtonRef: RefObject<HTMLButtonElement> = useRef(null);
  const removeButtonRef: RefObject<HTMLButtonElement> = useRef(null);

  useLayoutEffect(
    (): void => {
      const chooseButtonWidth: number = Number(chooseButtonRef.current?.getBoundingClientRect().width);
      const removeButtonWidth: number = Number(removeButtonRef.current?.getBoundingClientRect().width);
      const currentWidth: number = chooseButtonWidth > removeButtonWidth ? chooseButtonWidth : removeButtonWidth;
      const button: ButtonName = chooseButtonWidth > removeButtonWidth ? 'choose' : 'remove';
      if (currentWidth) {
        setSmallerButton(button);
        setButtonMinWidth(currentWidth);
      }
    },
    []
  );

  return (
    <>
      <InvisibleWrapper isActive={!isSelected}>
        <Button
          text={chooseText}
          onClick={onClick}
          active={isSelected}
          minWidth={smallerButton === 'remove' ? buttonMinWidth : undefined}
          ref={chooseButtonRef}
        />
      </InvisibleWrapper>
      <InvisibleWrapper isActive={isSelected}>
        <Button
          text={removeText}
          onClick={onClick}
          active={isSelected}
          minWidth={smallerButton === 'choose' ? buttonMinWidth : undefined}
          ref={removeButtonRef}
        />
      </InvisibleWrapper>
    </>
  );
};
