import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { BoxShadow } from '../../enums/boxShadow.enum';
import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { FontWeight } from '../../enums/fontWeight.enum';
import { GridWidth } from '../../enums/gridWidth.enum';
import { StyledComponent } from '../../types/styledComponent.types';
import { styleWhenTrue } from '../../utils/style.utils';

import { BarProps } from './selectBar.types';

export const Bar: StyledComponent<'div', BarProps> = styled.div`
  width: 100%;
  max-width: ${GridWidth.Desktop};
  min-height: 100px;
  background-color: ${Color.White};
  box-shadow: ${BoxShadow.BtnGreyShadow};
  border: 1px solid ${Color.Transparent};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  transition: 0.3s;

  ${styleWhenTrue(
    prop('active'),
    css`
      border: 1px solid ${Color.Green};
    `
  )}
`;

export const BarLabel: StyledComponent<'p'> = styled.p`
  font-weight: ${FontWeight.Bold};
  font-size: 18px;
  line-height: 22px;
  color: ${Color.Black};
  word-break: break-word;
`;

export const PriceContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;

  @media ${Breakpoint.Additional480} {
    flex-direction: column;
    align-items: flex-end;
  }
`;
