import React, { ChangeEvent, ChangeEventHandler } from 'react';

import { StyledTextarea } from './textarea.styled';
import { TextareaProps } from './textarea.types';

export const Textarea: React.FC<TextareaProps> = (props: TextareaProps): JSX.Element => {
  const { onChange, onBlur, value, placeholder, autocomplete }: TextareaProps = props;

  const handleOnChange: ChangeEventHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <StyledTextarea placeholder={placeholder} value={value} onBlur={onBlur} onChange={handleOnChange} autoComplete={autocomplete} />
  );
};
