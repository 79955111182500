import styled, { css } from 'styled-components';
import { prop } from 'ramda';

import { styleWhenTrue } from '../../utils/style.utils';
import { Color } from '../../enums/color.enum';
import { StyledComponent } from '../../types/styledComponent.types';

import { LoaderContainerProps, LoaderProps } from './circleLoader.types';

export const Loader: StyledComponent<'div', LoaderProps> = styled.div<LoaderProps>`
  @keyframes spin {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }
  position: relative;

  :after {
    animation: spin 0.6s infinite linear;
    box-sizing: border-box;
    animation-play-state: inherit;
    border: solid 5px ${Color.Transparent};
    border-bottom-color: ${({ color }: LoaderProps): Color => color};
    border-radius: 50%;
    content: '';
    height: 21px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    width: 21px;
    will-change: transform;
    margin-top: 10.5px;

    ${styleWhenTrue(prop('hasBiggerSize'), css`
      width: 35px;
      height: 35px;
    `)};
  }
`;

export const LoaderContainer: StyledComponent<'div', LoaderContainerProps> = styled.div`
  position: relative;
  width: 21px;
  height: 21px;
  min-width: 21px;

  ${styleWhenTrue(prop('hasBiggerSize'), css`
    height: 35px;
    width: 35px;
    min-width: 35px;
  `)};

`;
