import React, { PropsWithChildren } from 'react';

import { AdditionalInfo } from '../../additionalInfo/additionalInfo.component';
import { Tooltip } from '../../tooltip/tooltip.component';
import { ValidationBar } from '../../validationBar/validationBar.component';

import { ContainerType } from './containerType.enum';
import {
  InputWrapper,
  Label,
  Row,
  Wrapper,
} from './inputContainer.styled';
import { InputContainerProps } from './inputContainer.types';

export const InputContainer: React.FC<
  PropsWithChildren<InputContainerProps>
> = (props: InputContainerProps): JSX.Element => {
  const {
    label,
    children,
    validationStatus,
    additionalInfoContent,
    tooltipProps,
    className,
    additionalInfoContentInReadMore = '',
    containerType = ContainerType.Standard,
    additionalContent,
    hasBackground,
    validationMessages,
    disabled = false,
    inputWrapperBorderColor,
    inputPadding,
  }: PropsWithChildren<InputContainerProps> = props;

  return (
    <Wrapper className={className}>
      {!!additionalInfoContent &&
        <AdditionalInfo
          content={additionalInfoContent}
          contentInReadMore={additionalInfoContentInReadMore}
        />
      }
      {!!label && <Label>{label}</Label>}
      <Row>
        <InputWrapper
          isValidated={validationStatus}
          containerType={containerType}
          hasTooltip={!!tooltipProps}
          data-testid='inputContainer'
          hasBackground={hasBackground}
          disabled={disabled}
          borderColor={inputWrapperBorderColor}
          padding={inputPadding}
        >
          {children}
        </InputWrapper>
        {additionalContent}
        {!!tooltipProps && <Tooltip tooltipSettings={tooltipProps} />}
      </Row>
      <ValidationBar errorsArray={validationMessages} />
    </Wrapper>
  );
};
