import { componentTypes } from '@rehau/shared/forms/types';
import { AbstractContainer, ComponentInterface } from '@rehau/shared/forms/components';
import { FormContainerTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { InfoBox } from '@rehau/shared/forms/elements';
import { Config } from '@rehau/shared/models/countryConfig';

export class ComponentsContainer extends AbstractContainer implements ComponentInterface {
  public elements: componentTypes[];

  public constructor(
    frontendComponent: FrontendComponentEnum,
    title: string,
    elements: componentTypes[] = [],
    countryConfig?: Config,
    informationBox?: InfoBox,
    subTitle?: string
  ) {
    super(FormContainerTypeEnum.ComponentsContainer, frontendComponent, title, countryConfig, informationBox, subTitle);
    this.frontendComponent = frontendComponent;
    this.elements = elements;
  }

  public addElement(element: componentTypes, condition?: boolean, name?: string): this {
    if (condition !== false) {
      if (name) {
        element.setName(name);
      }
      this.elements.push(element);
    }

    return this;
  }
}
