export enum CalculationInstallerSummaryKeyEnum {
  CustomerDataName = 'customerDataName',
  CustomerDataSurname = 'customerDataSurname',
  CustomerDataEmail = 'customerDataEmail',
  CustomerDataPhone = 'customerDataPhone',
  CustomerDataMontageDate = 'customerDataMontageDate',
  CustomerDataPreferredTime = 'customerDataPreferredTime',
  BuildingDetailsLocationName = 'buildingDetailsLocationName',
  BuildingDetailsSize = 'buildingDetailsSize',
  BuildingDetailsFloors = 'buildingDetailsNumberOfFloors',
  BuildingDetailsResidents = 'buildingDetailsResidents',
  BuildingDetailsRooms = 'buildingDetailsRooms',
  InstallationsChimney = 'installationsChimney',
  InstallationsHydraulicSwitch = 'installationsHydraulicSwitch',
  InstallationsHeatingTank = 'installationsHeatingTank',
  InstallationsAdditionalBathroomRadiator = 'installationsAdditionalBathroomRadiator',
  InstallationsIntegratedDrinkingWaterHeating = 'installationsIntegratedDrinkingWaterHeating',
  SanitaryToilet = 'sanitaryToilet',
  SanitaryWashingToilet = 'sanitaryWashingToilet',
  SanitaryUrinal = 'sanitaryUrinal',
  SanitaryBath = 'sanitaryBath',
  SanitaryRainShower = 'sanitaryRainShower',
  SanitaryShower = 'sanitaryShower',
  SanitaryKitchenSink = 'sanitaryKitchenSink',
  SanitarySmallWashbasinColdWater = 'sanitarySmallWashbasinColdWater',
  SanitaryWashbasin = 'sanitaryWashbasin',
  SanitaryBidet = 'sanitaryBidet',
  SanitaryDishwasher = 'sanitaryDishwasher',
  SanitaryGardenHose = 'sanitaryGardenHose',
  SanitaryWashingMachine = 'sanitaryWashingMachine',
}
