export enum ErrorTypeEnum {
  validation = 'VALIDATION',
  login_required = 'LOGIN_REQUIRED',
  access_denied = 'ACCESS_DENIED',
  authorization_failed = 'AUTHORIZATION_FAILED',
  not_found = 'NOT_FOUND',
  not_implemented = 'NOT_IMPLEMENTED',
  conflict = 'CONFLICT',
  input_error = 'INPUT_ERROR',
  update_failed = 'UPDATE_DATA_FAILED',
  internal_error = 'INTERNAL_ERROR',
  context_error = 'CONTEXT_ERROR',
  database_connection_error = 'DATABASE_CONNECTION_ERROR',
  database_save_error = 'DATABASE_SAVE_ERROR',
  database_duplicate_entry = 'DATABASE_DUPLICATE_ENTRY_ERROR',
  bad_request = 'BAD_REQUEST',
  wrong_login_or_password = 'WRONG_LOGIN_OR_PASSWORD',
  wrong_token = 'WRONG_TOKEN',
  wrong_hash = 'WRONG_HASH',
  email_already_in_use = 'EMAIL_ALREADY_IN_USE',
  wrong_id = 'WRONG_ID',
  wrong_email = 'WRONG_EMAIL',
  wrong_password = 'WRONG_PASSWORD',
  invalid_email = 'INVALID_EMAIL',
  invalid_password = 'INVALID_PASSWORD',
  locked_token = 'LOCKED_TOKEN',
}
