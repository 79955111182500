import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { PasswordField } from '../../../../../../../shared/forms/elements';
import { FieldElement, FieldElementProps } from '../types';
import { PasswordContainer as PasswordContainerType } from '../../../../../../../shared/forms/components';
import { PasswordContainer } from '../../input/passwordContainer/passwordContainer.component';

export const PasswordContainerFieldElement: FieldElement<PasswordContainerType> = (
  props: FieldElementProps<PasswordContainerType>
): JSX.Element => {
  const { component, setValues }: FieldElementProps<PasswordContainerType> = props;
  const { t }: TransProps<never> = useTranslation();
  const passwordField: PasswordField = component.getPasswordField();
  const repeatPasswordField: PasswordField = component.getRepeatPasswordField();

  return (
    <PasswordContainer
      passwordLabel={t(passwordField.label || '')}
      passwordPlaceholder={t(passwordField.placeholder)}
      repeatPasswordLabel={t(repeatPasswordField.label || '')}
      repeatPasswordPlaceholder={t(repeatPasswordField.placeholder)}
      component={component}
      setValues={setValues}
    />
  );
};
