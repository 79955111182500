import React from 'react';

import { toMarkdown } from '../../utils/toMarkdown';

import { Value } from './markdown.styled';
import { MarkdownProps } from './markdown.types';

export const Markdown: React.FC<MarkdownProps> = (props: MarkdownProps): JSX.Element => {
  const { className, text, tagType = 'div', onClick = (): void => undefined }: MarkdownProps = props;

  return (
    <Value
      dangerouslySetInnerHTML={{ __html: toMarkdown(text) }}
      className={className}
      as={tagType}
      onClick={onClick}
    />
  );
};
