import { TextField } from '@rehau/shared/forms/elements/fields';
import { NewPasswordValidation, StringValidation } from '@rehau/shared/forms/validations';
import { FormFieldTypeEnum, FrontendComponentEnum } from '@rehau/shared/enums';
import { InfoBox, Tooltip } from '@rehau/shared/forms/elements';
import { ObjectIteratorInterface } from '@rehau/shared/objectIterator.interface';

export class PasswordField extends TextField {
  constructor(
      id: string,
      label: string | null,
      placeholder: string,
      required: boolean,
      validation: StringValidation = new NewPasswordValidation(),
      countryConfigMapping: ObjectIteratorInterface<string> = {},
      informationBox?: InfoBox,
      tooltip?: Tooltip
  ) {
    super(
      id,
      id,
      label,
      placeholder,
      required,
      validation,
      countryConfigMapping,
      informationBox,
      tooltip
    );
    this.frontendComponent = FrontendComponentEnum.PasswordField;
    this.type = FormFieldTypeEnum.PasswordField;
  }
}
