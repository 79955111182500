import React from 'react';

import { Button } from '@rehau-fe/ui/src';
import { MobileBackground, MobileInfoBox } from '../../home.styled';

import { CalculationInfoBox, Description, InfoBoxContainer, StyledHero, Title } from './hero.styled';
import { HeroProps } from './hero.types';

export const Hero: React.FC<HeroProps> = (props: HeroProps): JSX.Element => {
  const {
    image,
    desktopImage,
    buttonOnClick,
    title,
    description,
    buttonLabel,
    imageHeight = 650,
  }: HeroProps = props;

  return (
    <>
      <StyledHero data-testid='hero' image={image} desktopImage={desktopImage} imageHeight={imageHeight}>
        <InfoBoxContainer>
          <CalculationInfoBox>
            <Title>{title}</Title>
            <Description text={description} />
            <Button text={buttonLabel || ''}  onClick={buttonOnClick} />
          </CalculationInfoBox>
        </InfoBoxContainer>
      </StyledHero>
      <MobileBackground>
        <MobileInfoBox>
          <Title>{title}</Title>
          <Description text={description} />
          <Button text={buttonLabel || ''}  onClick={buttonOnClick} />
        </MobileInfoBox>
      </MobileBackground>
    </>
  );
};
