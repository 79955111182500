import styled from 'styled-components';

import { Breakpoint, FontWeight, StyledComponent } from '@rehau-fe/ui/src';

export const Container: StyledComponent<'div'> = styled.div`
  overflow: hidden;
  width: 100%;
  margin-bottom: 25px;

  @media ${Breakpoint.Mobile} {
    margin-bottom: 40px;
  }

  @media ${Breakpoint.Additional480} {
    padding-left: 20px;
  }
`;

export const StyledSectionHeader: StyledComponent<'h2'> = styled.h2`
  font-size: 28px;
  line-height: 33px;
  font-weight: ${FontWeight.Black};
  width: 100%;
  word-break: break-word;
  box-sizing: border-box;

  @media ${Breakpoint.Mobile} {
    text-align: left;
    line-height: 32px;
    width: calc(100% - 30px);
  }
`;
