import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { PathNameMenuPositionTuple, RouteInfo } from '../../../../../../../../shared/models/countryConfig';
import { findPathByName } from '../../../../utils/findPathByName.utils';
import { Color } from '../../../../enums/color.enum';
import { useRoutesInfo } from '../../../../hooks/useRoutesInfo.hook';
import { Icon } from '../../../icon/icon.component';
import { sortItems } from '../../../../utils/menuPositions/menuPositions.utils';

import { ItemGroupProps } from './itemGroup.types';
import {
  Container,
  ItemLink,
  LinkLabel,
  Title,
} from './itemGroup.styled';

export const ItemGroup: React.FC<ItemGroupProps> = (props: ItemGroupProps): JSX.Element => {
  const { className, title, items, onChoose }: ItemGroupProps = props;
  const { t }: TransProps<never> = useTranslation();
  const routesArray: RouteInfo[] = useRoutesInfo();
  const sortedItems: PathNameMenuPositionTuple[] = Object.entries(items).sort(sortItems);

  return (
    <Container data-testid='item-group' className={className}>
      {!!title && <Title data-testid='item-title'>{title}</Title>}
      {sortedItems.map(([name, data]: PathNameMenuPositionTuple): JSX.Element | false => (
        !!data && (
          <ItemLink
            data-testid='link'
            key={data.title}
            to={findPathByName(name, routesArray)}
            onClick={onChoose}
            pathName={name}
          >
            {!!data.icon && <Icon name={data.icon} size={50} color={Color.Green} />}
            <LinkLabel>{t(data.title)}</LinkLabel>
          </ItemLink>
        )
      ))}
    </Container>
  );
};
