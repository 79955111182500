import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { propEq } from 'ramda';

import { styleWhenTrue } from '../../../utils/style.utils';
import { Color } from '../../../enums/color.enum';
import { ValidationStatus } from '../../../enums/validationStatus.enum';
import { BoxShadow } from '../../../enums/boxShadow.enum';
import { Breakpoint } from '../../../enums/breakpoint.enum';
import { BaseLabel } from '../../../theme';
import { StyledComponent } from '../../../types/styledComponent.types';

import { InputWrapperProps } from './inputContainer.types';
import { ContainerType } from './containerType.enum';

export const Wrapper: StyledComponent<'div'> = styled.div`
  @media ${Breakpoint.Tablet} {
    width: 100%;
  }
`;

export const InputWrapper: StyledComponent<'div', InputWrapperProps> = styled.div<InputWrapperProps>`
  width: 341px;
  height: 44px;
  border: 1px solid ${({ borderColor }: InputWrapperProps): Color => borderColor ?? Color.GreyStroke };
  padding: ${({ padding }: InputWrapperProps): string => padding || '0 15px'};
  box-sizing: border-box;
  ${styleWhenTrue(
    propEq('hasBackground', true),
    css`
      background: ${Color.White};
    `
  )}
  ${styleWhenTrue(
    propEq('hasTooltip', true),
    css`
      margin-right: 20px;
    `
  )}
  ${styleWhenTrue(
    propEq('isValidated', ValidationStatus.Valid),
    css`
      border: 1px solid ${Color.Green};
    `
  )}
  ${styleWhenTrue(
    propEq('containerType', ContainerType.Big),
    css`
      width: 100%;
      max-width: 610px;
      height: 146px;
    `
  )}
  ${styleWhenTrue(
    propEq('containerType', ContainerType.Dropdown),
    css`
      padding: 0;
    `
  )}
  ${styleWhenTrue(
    propEq('containerType', ContainerType.Max),
    css`
      width: 100%;
    `
  )}
  ${styleWhenTrue(
    propEq('containerType', ContainerType.MultiselectDropdowm),
    css`
      padding-right: 0;
    `
  )}
  transition: 0.3s;
  :hover {
    box-shadow: ${BoxShadow.BtnGreyShadow};
  }

  ${({ disabled }: InputWrapperProps): FlattenSimpleInterpolation | null =>
    disabled
      ? css`
          background-color: ${Color.BgGrey};
          pointer-events: none;
          :hover {
            box-shadow: unset;
          }
        `
      : null}
  @media ${Breakpoint.Tablet} {
    width: 100%;
    min-width: 340px;
  }
  @media ${Breakpoint.Mobile} {
    min-width: unset;
    max-width: 100%;
  }
`;

export const Row: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
`;

export const Label: StyledComponent<typeof BaseLabel> = styled(BaseLabel)`
  margin-bottom: 8px;
  word-break: break-word;
  max-width: 610px;
  line-height: 1;
`;
