import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { PathNameMenuPositionTuple, RouteInfo } from '../../../../../../../shared/models/countryConfig';
import { useRoutesInfo } from '../../../hooks/useRoutesInfo.hook';
import { IconName } from '@rehau/shared/enums';

import { AdditionalMenuItem, MobileMenuProps } from './mobileMenu.types';
import { Button, Container, Item, StyledIcon } from './mobileMenu.styled';

export const MobileMenu: React.FC<MobileMenuProps> = (props: MobileMenuProps): JSX.Element => {
  const { className, open, items, onChoose, additionalItems }: MobileMenuProps = props;
  const { t }: TransProps<never> = useTranslation();
  const routesArray: RouteInfo[] = useRoutesInfo();

  const sortMobileItems: ([name1, data1]: PathNameMenuPositionTuple, [name2, data2]: PathNameMenuPositionTuple) => number = (
    [name1, data1]: PathNameMenuPositionTuple,
    [name2, data2]: PathNameMenuPositionTuple
  ): number => {
    if (data2 && data1 && data2.mobileOrder !== undefined && data1.mobileOrder !== undefined) {
      return data1.mobileOrder > data2.mobileOrder ? 1 : -1;
    }

    return 1;
  };

  return (
    <Container data-testid='mobile-menu' className={className} open={open}>
      {items
        .sort(sortMobileItems)
        .map(
          ([name, data]: PathNameMenuPositionTuple): JSX.Element | false =>
            !!data && (
              <Item key={name} label={t(data.title)} path={name} groups={data.groups} onClick={onChoose} action={data.action} />
            )
        )}
        {additionalItems?.map((item: AdditionalMenuItem): JSX.Element => (
          <Button key={item.label} onClick={item.onClick}>
            {item.label}
            <StyledIcon name={IconName.ArrowBig} size={13} />
          </Button>
        ))}
    </Container>
  );
};
