import styled, { css, StyledComponent } from 'styled-components';
import { prop } from 'ramda';

import { Breakpoint } from '../../enums/breakpoint.enum';
import { Color } from '../../enums/color.enum';
import { ZIndex } from '../../enums/zIndex.enum';
import { Icon } from '../icon/icon.component';
import { styleWhenTrue } from '../../utils/style.utils';

import { BackgroundProps } from './alert.types';

export const Background: StyledComponent<'div', {}, BackgroundProps, never> = styled.div`
  opacity: 0;
  pointer-events: none;
  padding: 15px 17px 16px;
  box-sizing: border-box;
  max-width: 350px;
  min-height: 48px;
  background-color: ${(props: BackgroundProps): Color => props.background};
  color: ${Color.White};
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 2px;
  line-height: 20px;
  z-index: ${ZIndex.Alert};
  transition: 0.3s;

  ${styleWhenTrue(prop('isVisible'), css`
    opacity: 1;
    pointer-events: initial;
  `)}

  ${styleWhenTrue(prop('hidden'), css`
    display: none;
  `)}
`;

export const Content: StyledComponent<'h4', {}, {}, never> = styled.h4``;

export const LeftIcon: StyledComponent<typeof Icon, {}, {}, never> = styled(Icon)`
  margin-right: 10px;
`;
