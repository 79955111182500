import { createGlobalStyle, DefaultTheme, GlobalStyleComponent } from 'styled-components';

import { GlobalStyleContent, GridWidthCssVariable, GridWidthType } from '@rehau-fe/ui/src';
import { ContainerType } from '@rehau-fe/ui/src/components/fieldComponent/fieldComponent.enums';

const fieldWrapperStyles: string = `
  .${ContainerType.Standard} + .${ContainerType.Standard} {
    margin-top: 15px;
  }
  div.${ContainerType.HorizontalFieldline} + .${ContainerType.HorizontalFieldline} {
    margin-top: 45px;
  }
  div.${ContainerType.PasswordContainer} {
    margin-top: 15px;
  }
`;

export const GlobalStyle: GlobalStyleComponent<object, DefaultTheme> = createGlobalStyle`
  ${GlobalStyleContent}
  :root {
    ${GridWidthCssVariable.Desktop}: ${GridWidthType.DesktopStandard};
  }
  ${fieldWrapperStyles}
`;
