import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import { StyledComponent } from '../../../types/styledComponent.types';
import { styleWhenTrue } from '../../../utils/style.utils';
import { ZIndex } from '../../../enums/zIndex.enum';

import { InvisibleWrapperProps } from './selectBarButton.types';

export const InvisibleWrapper: StyledComponent<'div', InvisibleWrapperProps> = styled.div`
  display: flex;
  z-index: ${ZIndex.Hidden};
  pointer-events: none;
  position: absolute;
  opacity: 0;

  ${styleWhenTrue(
    prop('isActive'),
    css`
      position: static;
      pointer-events: initial;
      z-index: ${ZIndex.Minimum};
      display: flex;
      justify-content: flex-end;
      opacity: 1;
    `
  )}
`;
